import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, StyleSheet } from 'react-native';

import { getStorageScale } from '../utils';
import SliderDynamic from '../../General/Slider/SliderDynamic';
import SwitcherCheckbox from '../../General/Form/SwitcherCheckbox';
import { createVMUpdateParams } from '../../../actions/cvds/actions';

function StorageBlock(props) {
    const {
        backup,
        currentTariff,
        createVMUpdateParams
    } = props;
    const [tariff, setTariff] = React.useState(currentTariff);
    const [storageScale, setStorageScale] = React.useState(getStorageScale(tariff));
    const [storageSize, setStorageSize] = React.useState(tariff.features.storage);
    React.useEffect(() => {
        if (tariff.id !== currentTariff.id) {
            setTariff(currentTariff);
            setStorageScale(getStorageScale(currentTariff));
            setStorageSize(currentTariff.features.storage);
        }
    }, [currentTariff]);
    return <View style={styles.wrapper}>
        <View style={styles.storageBlock}>
            <SliderDynamic
                title={`Размер диска (${tariff.disk.display_type})`}
                items={storageScale}
                value={storageSize}
                unit='ГБ'
                onChange={(storage) => {
                    let newStorage = storage;
                    if (tariff.minStorageSize
                        && storage < tariff.minStorageSize) {
                        newStorage = tariff.minStorageSize;
                    }
                    createVMUpdateParams({
                        currentTariff: {
                            ...tariff,
                            features: {
                                ...tariff.features,
                                storage: newStorage
                            }
                        }
                    }, () => setStorageSize(newStorage));
                }}/>
        </View>
        <View style={styles.backupBlock}>
            <Text style={styles.backupText}>Резервное копирование</Text>
            <SwitcherCheckbox value={backup} onChange={(value) => createVMUpdateParams({
                backup: value
            })} />
        </View>
    </View>;
}

StorageBlock.propTypes = {
    backup: PropTypes.bool,
    currentTariff: PropTypes.shape({}),
    createVMUpdateParams: PropTypes.func
};

const mapStateToProps = (state) => ({
    backup: state.cvdsCreate.backup,
    currentTariff: state.cvdsCreate.currentTariff
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    createVMUpdateParams
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 9,
        marginBottom: 8,
        backgroundColor: '#ffffff'
    },
    storageBlock: {
        flex: 1,
        paddingTop: 16,
        paddingRight: 15,
        paddingBottom: 16,
        paddingLeft: 14,
        minHeight: 90
    },
    backupBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderTopWidth: 1,
        borderTopColor: '#e4e4e4',
        paddingTop: 16,
        paddingRight: 15,
        paddingBottom: 17,
        paddingLeft: 16,
        maxHeight: 58
    },
    backupText: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 18,
        flex: 1
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageBlock);
