const BaseResource = require('../resource');
const GatewayClient = require('../../gateway-client');
const { makePaginateParams } = require('../../utils');

class MailDomainsResource extends BaseResource {
    constructor({ url, token }) {
        super({ url, token });
        this.client = new GatewayClient({
            url,
            token,
            baseUrl: '/api/v2',
        });
    }

    async userMailInfo() {
        return this.makeRequest('get', null);
    }

    async list(limit = 25, offset = 0) {
        return this.makeRequest('get', null, {
            ...makePaginateParams(limit, offset),
        });
    }

    async searchDomains(filterObject, limit, offset) {
        return this.makeRequest('get', null, {
            ...makePaginateParams(limit, offset),
            order: 'name',
            ...filterObject,
        });
    }

    async createDomain(name) {
        return this.makeRequest('post', null, { name });
    }

    async domainInfo(domainId) {
        return this.makeRequest('get', `${domainId}/`);
    }

    async changeQuota(domainId, quota) {
        return this.makeRequest('put', `${domainId}/quota/`, { quota });
    }

    async deleteDomain(domainId) {
        return this.makeRequest('delete', `${domainId}/`);
    }

    async mailboxesList(domainId, limit = 20, offset = 0) {
        return this.makeRequest('get', `${domainId}/mailboxes/`, {
            ...makePaginateParams(limit, offset),
            order: 'name',
        });
    }

    async searchMailboxes(domainId, filterObject, limit, offset) {
        return this.makeRequest('get', `${domainId}/mailboxes/`, {
            ...filterObject,
            limit,
            offset,
            order: 'name',
        });
    }

    async deleteMailboxes(domainId, mailboxesIds) {
        return this.makeRequest('post', `${domainId}/mailboxes/batch/delete/`, {
            ids: mailboxesIds,
        });
    }

    async domainStatisticInfo(domainId) {
        return this.makeRequest(
            'get',
            `${domainId}/mailboxes/statistics/top10/`,
        );
    }
}

MailDomainsResource.resource = 'domains';
MailDomainsResource.service = 'mail';

module.exports = MailDomainsResource;
