import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';


function ImagesList(props) {
    const {
        group,
        currentDistribution,
        onChange
    } = props;
    return <View style={styles.groupVersions}>
        {group.versions.map((versionObj, j) => <View key={`images-group-${j}`}
            style={styles.imagesGroup}>
            {versionObj.images.map((image, k) => <TouchableOpacity key={`image-${k}`}
                style={[styles.imageBlock, currentDistribution.id !== image.id ? {}
                    : styles.currentImageBlock]} onPress={() => {
                        onChange(image, group);
                    }}>
                <View style={currentDistribution.id === image.id ? styles.activeOuterCircle
                    : styles.outerCircle}>
                    {currentDistribution.id === image.id && <View style={styles.innerCircle}/>}
                </View>
                <Text style={[styles.textBlock, {
                    marginLeft: 16,
                    color: currentDistribution.id === image.id ? '#0f79d5' : '#455562'
                }]}>{image.name}</Text>
            </TouchableOpacity>)}
        </View>)}
    </View>
}

ImagesList.propTypes = {
    group: PropTypes.shape({}),
    currentDistribution: PropTypes.shape({}),
    onChange: PropTypes.func
};

const styles = StyleSheet.create({
    textBlock: {
        flex: 1,
        color: '#455562',
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 16
    },
    groupVersions: {
        marginBottom: 8
    },
    imagesGroup: {
        marginBottom: 4
    },
    imageBlock: {
        flex: 1,
        backgroundColor: '#ffffff',
        borderTopWidth: 1,
        borderTopColor: '#ffffff',
        marginBottom: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 14,
        paddingRight: 20,
        paddingBottom: 16,
        paddingLeft: 27
    },
    currentImageBlock: {
        borderTopColor: '#0f79d5',
        borderTopWidth: 1,
        borderBottomColor: '#0f79d5',
        borderBottomWidth: 1,
        backgroundColor: 'rgba(15, 121, 213, 0.07)'
    },
    outerCircle: {
        height: 18,
        width: 18,
        borderTopWidth: 1,
        borderTopColor: 'rgba(161, 170, 177, 0.5)',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(161, 170, 177, 0.5)',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(161, 170, 177, 0.5)',
        borderRightWidth: 1,
        borderRightColor: 'rgba(161, 170, 177, 0.5)',
        borderRadius: 9,
        backgroundColor: '#f5f5f5'
    },
    activeOuterCircle: {
        height: 18,
        width: 18,
        borderTopWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderLeftWidth: 1,
        borderLeftColor: '#0f79d5',
        borderRightWidth: 1,
        borderRightColor: '#0f79d5',
        borderRadius: 9,
        backgroundColor: '#ffffff',
        paddingTop: 4,
        paddingLeft: 4
    },
    innerCircle: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0f79d5'
    }
});

export default ImagesList;
