import React from 'react';
import PropTypes from 'prop-types';
import {
    TouchableOpacity, StyleSheet, Image, View
} from 'react-native';

import { checkedIcon } from '../../../icons';

const styles = StyleSheet.create({
    checkboxBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    icon: {
        height: 18,
        minWidth: 18,
        borderWidth: 1,
        marginRight: 14,
        borderRadius: 2,
        borderStyle: 'solid',
        backgroundColor: '#F5F5F5',
        borderColor: 'rgba(161,170,177,0.5)',
    },
    activeIcon: {
        flex: 1,
        height: 18,
        minWidth: 18,
        marginRight: 14,
        borderRadius: 2,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0f79d5'
    },
    checked: {
        height: 9,
        width: 11
    }
});

function Checkbox(props) {
    const {
        value = false,
        onValueChange = () => {},
        children = null,
        additionalIconStyles = [],
        additionalLabelStyles = []
    } = props;

    const [isActive, setIsActive] = React.useState(value);
    return <TouchableOpacity onPress={() => {
        setIsActive(!isActive);
        onValueChange(!isActive);
    }}>
        <View style={styles.checkboxBlock}>
            {isActive ? <View style={[styles.activeIcon, ...additionalIconStyles]}>
                <Image source={checkedIcon} style={styles.checked}/>
            </View> : <View style={[styles.icon, ...additionalIconStyles]}/>}
            <View style={[styles.label, ...additionalLabelStyles]}>
                {children}
            </View>
        </View>
    </TouchableOpacity>;
}

Checkbox.propTypes = {
    id: PropTypes.string,
    value: PropTypes.bool,
    name: PropTypes.string,
    onValueChange: PropTypes.func,
    children: PropTypes.any,
    additionalIconStyles: PropTypes.arrayOf(PropTypes.shape({})),
    additionalLabelStyles: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Checkbox;
