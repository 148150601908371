import React from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, View } from 'react-native';

import { paymentsTypeMap } from '../constants';

const styles = StyleSheet.create({
    button: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    buttonIcon: {
        width: 16,
        height: 16,
        marginRight: 11
    },
    buttonText: {
        fontSize: 13,
        lineHeight: 16,
        color: '#455562',
        fontWeight: '500'
    }
});

function PaymentSystemButton(props) {
    const { type, customIconStyle = {} } = props;
    return <View style={styles.button}>
        <Image
            source={paymentsTypeMap[type].icon}
            style={[styles.buttonIcon, customIconStyle]}
        />
        <Text style={styles.buttonText}>{paymentsTypeMap[type].title}</Text>
    </View>;
}

PaymentSystemButton.propTypes = {
    type: PropTypes.string,
    customIconStyle: PropTypes.string,
};

export default PaymentSystemButton;
