import React from 'react';
import PropTypes from 'prop-types';
import {
    TextInput, StyleSheet, Text, View, Image, TouchableOpacity
} from 'react-native';

import Errors from './Errors';
import { openEyeIcon, closedEyeIcon } from '../../../icons';

function Input(props) {
    const {
        label,
        textInputAttrs = {},
        errors = [],
        needBorder = false,
        needHideText = false,
        additionalErrorStyles = [],
        additionalInputStyles = [],
        additionalWrapperStyles = []
    } = props;
    const { value, defaultValue, autoFocus } = textInputAttrs;
    const [focused, setFocused] = React.useState(autoFocus || false);

    const [showText, setShowText] = React.useState(!needHideText);
    const inputAttrs = {
        ...textInputAttrs
    };
    const focusOrEmpty = focused || (value && value.length > 0)
        || (defaultValue && defaultValue.length > 0);
    const labelStyle = focusOrEmpty
        ? {
            fontSize: 12,
            lineHeight: 14,
            top: 9,
        }
        : {
            fontSize: 16,
            lineHeight: 19,
            top: 16,
        };

    let { placeholder } = inputAttrs;
    if (placeholder != null && label != null) {
        if (!focusOrEmpty) {
            placeholder = undefined;
        }
    }

    const eyeIconStyle = label ? {
        height: 58,
    } : {
        height: 24
    };
    const iconStyle = label ? {
        marginRight: 18,
    } : {
        marginRight: 13,
    };
    const styles = StyleSheet.create({
        wrapper: {
            position: 'relative',
            marginBottom: 5
        },
        input: {
            borderColor: '#e4e4e4',
            borderStyle: 'solid',
            borderBottomWidth: 1,
            fontSize: 14,
            lineHeight: 19,
            color: '#455562',
            paddingBottom: 5,
            backgroundColor: '#ffffff',
        },
        label: {
            fontSize: 11,
            fontWeight: '400',
            lineHeight: 14,
            color: '#455562',
            marginBottom: 4,
        },
        error: {
            borderColor: '#d06565',
        },
        eyeIcon: {
            width: 20,
            height: 15,
            opacity: 0.4,
            ...iconStyle,
        },
        showText: {
            textAlign: 'end',
            position: 'absolute',
            right: 0,
            width: 40,
            paddingLeft: 10,
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            ...eyeIconStyle,
        }
    });
    const borderStyles = StyleSheet.create({
        input: {
            borderColor: 'rgba(69,85,98,0.3)',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            fontSize: 14,
            lineHeight: 19,
            color: '#455562',
            paddingTop: label ? 26 : 11,
            paddingRight: 12,
            paddingBottom: 11,
            paddingLeft: 12,
            backgroundColor: focusOrEmpty ? '#ffffff' : '#F2F6FA'
        },
        label: {
            position: 'absolute',
            left: 12,
            color: 'rgba(69,85,98,0.5)',
            ...labelStyle
        },
    });
    const additionalInputClasses = [];
    if (errors != null && errors.length > 0) {
        additionalInputClasses.push('error');
    }
    const currentInputStyle = needBorder ? borderStyles.input : styles.input;
    const currentLabelStyle = needBorder ? borderStyles.label : styles.label;
    return <View style={[styles.wrapper, ...additionalWrapperStyles]}>
        {label && <Text style={currentLabelStyle}>{label}</Text>}
        <TextInput style={[currentInputStyle, ...additionalInputStyles,
            ...additionalInputClasses.map((cls) => styles[cls])]}
        autoCompleteType='off'
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        onSubmitEditing={() => setFocused(false)}
        secureTextEntry={!showText}
        {...inputAttrs}
        placeholder={placeholder}
        >
        </TextInput>
        {needHideText && <TouchableOpacity
            style={styles.showText}
            onPress={() => {
                setShowText(!showText);
            }}>
            <Image source={showText ? openEyeIcon : closedEyeIcon}
                resizeMode='contain'
                style={styles.eyeIcon} />
        </TouchableOpacity>}
        <View style={additionalErrorStyles}>
            {errors && <Errors errors={errors} />}
        </View>
    </View>;
}

Input.propTypes = {
    label: PropTypes.string,
    errors: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string
    ]),
    needBorder: PropTypes.bool,
    needHideText: PropTypes.bool,
    textInputAttrs: PropTypes.shape({}),
    additionalErrorStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ])),
    additionalInputStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ])),
    additionalWrapperStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ]))
};

export default Input;
