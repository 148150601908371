import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';
import { bindActionCreators } from 'redux';

import { calculateIPPrice } from '../utils';
import { IPv4 } from '../../../constants/cvds';
import Errors from '../../General/Form/Errors';
import { flushErrors } from '../../../actions/forms/actions';
import { vmProtectedIPAdd } from '../../../actions/cvds/actions';
import WarningCancelPacketBlock from '../WarningCancelPacketBlock';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'AddProtectedIPForm';

function AddProtectedIPModal(props) {
    const {
        vm,
        errors,
        onClose,
        inProgress,
        flushErrors,
        awaitingPacket,
        vmProtectedIPAdd
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);
    const targetBandwidth = parseInt(vm.bandwidth, 10);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Новый защищенный IP-адрес</Text>
        <Text style={styles.text}>
            IP-адрес с полной фильтрацией входящего трафика с выбранной полосой
        </Text>
        <Text style={styles.price}>
            Стоимость: {calculateIPPrice(1, IPv4).toFixed(0)} ₽/мес
        </Text>
        {awaitingPacket && <View style={styles.packetWarning}>
            <WarningCancelPacketBlock mainText='У вас имеется незавершенный заказ
                на годовой пакет для виртуальной машины. Подключение платного IP-адреса'
            warningText='отменит заказ годового пакета'/>
        </View>}
        {errors.__all__ && <Errors errors={errors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => vmProtectedIPAdd(formId, vm.id, targetBandwidth, () => onClose())}
                title="Подключить"
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

AddProtectedIPModal.propTypes = {
    vm: PropTypes.shape({}),
    onClose: PropTypes.func,
    errors: PropTypes.shape({}),
    awaitingPacket: PropTypes.bool,
    inProgress: PropTypes.bool,
    vmProtectedIPAdd: PropTypes.func
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    errors: state.forms.errors[formId] || {},
    inProgress: state.cvds.antiDDoSInProgress,
    awaitingPacket: state.cvdsFinanceInfo.calculation.packet == null ? false
        : state.cvdsFinanceInfo.calculation.packet.is_awaiting
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors, vmProtectedIPAdd
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 32,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 28
    },
    title: {
        marginBottom: 12,
        color: '#455562',
        fontSize: 16,
        lineHeight: 19
    },
    text: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 18
    },
    price: {
        color: '#627687',
        fontSize: 14,
        lineHeight: 20,
        fontWeight: '500',
        marginTop: 16,
        paddingBottom: 16,
        borderBottomColor: 'rgba(69, 85, 98, 0.3)',
        borderBottomWidth: 1,
        borderStyle: 'dashed'
    },
    buttonBlock: {
        flex: 1,
        marginTop: 26,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    packetWarning: {
        marginTop: 16
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProtectedIPModal);
