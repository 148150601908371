import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
    Image,
    Text,
    View,
    StyleSheet,
    TouchableOpacity,
} from 'react-native';

import InputPassword from '../components/General/Form/InputPassword';
import Errors from '../components/General/Form/Errors';
import Button from '../components/General/Button/Button';
import { passwordReset } from '../actions/user/actions';
import { netangelsLogoIcon } from '../icons';

const formId = 'restorePasswordFormId';

const styles = StyleSheet.create({
    logoWrapper: {
        flex: 0.1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    authScreen: {
        marginTop: '11%',
        padding: 22,
        flex: 1,
        flexDirection: 'column'
    },
    headerText: {
        fontSize: 22,
        marginBottom: 30,
        textAlign: 'center',
        lineHeight: 25,
        color: '#455562'
    },
    form: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 4,
    },
    hint: {
        color: 'rgba(69, 85, 98, 0.70)',
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 22,
        marginLeft: 12,
    },
    button: {
        borderRadius: 4,
    },
    linkButton: {
        fontSize: 11,
        lineHeight: 13,
        letterSpacing: 1.38,
        textAlign: 'center',
        opacity: 0.75,
        color: '#4e5f6e',
        marginTop: 25,
        textTransform: 'uppercase',
    },
    allErrorWrapper: {
        textAlign: 'center',
        marginBottom: 10
    },
});

function RestorePasswordConfirmScreen(props) {
    const { navigation, route } = props;
    const dispatch = useDispatch();
    if (route.params.key) {
        const untilDate = dayjs.unix(route.params.key);
        const now = dayjs();
        if (untilDate < now) {
            navigation.navigate('RestorePasswordFail');
        }
    }
    const inProgress = useSelector((state) => state.user.passwordResetInProgress);
    const errors = useSelector((state) => state.forms.errors[formId]) || {};

    return <View style={styles.authScreen}>
        <View style={styles.logoWrapper}>
            <Image source={netangelsLogoIcon} style={{ width: 162, height: 30 }} />
        </View>
        <View style={styles.form}>
            <Text style={styles.headerText}>Введите новый пароль</Text>
            {errors.__all__ && <View style={styles.allErrorWrapper}>
                <Errors errors={errors.__all__} />
            </View>}
            <View>
                <Formik
                    initialValues={{
                        password: '',
                    }}
                    onSubmit={(values) => dispatch(
                        passwordReset(
                            formId,
                            { password: values.password, key: route.params.secretKey },
                            () => navigation.navigate('Auth'),
                            () => navigation.navigate('OtpStack'),
                        ),
                    )}>
                    {({ handleChange, handleBlur, handleSubmit, values }) => (
                        <View>
                            <InputPassword
                                needBorder={true}
                                style={styles.input}
                                errors={errors.password}
                                value={values.password}
                                autoFocus={false}
                                onChange={handleChange('password')}
                            />
                            <Button
                                additionalWrapperStyles={[{ marginTop: 22 }]}
                                inProgress={inProgress}
                                style={styles.button}
                                onPress={handleSubmit}
                                title="Сохранить пароль"
                            />
                            <TouchableOpacity onPress={() => navigation.navigate('Auth')}>
                                <Text style={styles.linkButton}>
                                    назад к авторизации
                                </Text>
                            </TouchableOpacity>
                        </View>
                    )}
                </Formik>
            </View>
        </View>
    </View>;
}

RestorePasswordConfirmScreen.propTypes = {
    navigation: PropTypes.shape({}),
    route: PropTypes.shape({})
};

export default RestorePasswordConfirmScreen;
