import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native';

import {
    backupsIcon,
    backupsDisabledIcon
} from '../../icons';
import { isActiveVM } from './utils';
import VolumeFill from './VolumeFill';
import Modal from '../General/Modal/Modal';
import { isEmpty } from '../../utils/general';
import CVDSWebsockets from './CVDSWebsockets';
import ChangeVolumeModal from './Modals/ChangeVolumeModal';
import { setCurrentVolume } from '../../actions/cvds/actions';
import ActionButton from '../General/ActionButton/ActionButton';
import { blockedButtonTooltips } from '../../constants/general';

function VolumesTab(props) {
    const { vm, setCurrentVolume } = props;
    const [isAddVolumeModalShowed, setAddVolumeModalVisible] = React.useState(false);
    const navigation = useNavigation();
    const [isActionButtonOpen, setIsActionButtonOpen] = React.useState(false);

    useFocusEffect(React.useCallback(() => {
        setIsActionButtonOpen(true);
        return () => {
            setIsActionButtonOpen(false);
        };
    }));
    return <View style={styles.wrapper}>
        {!isEmpty(vm) && <View>
            <TouchableOpacity style={styles.diskBlock}
                onPress={() => setCurrentVolume(vm.disk,
                    () => navigation.navigate('CVDSVMEditVolume'))}>
                <Image source={vm.disk.is_backup_enabled ? backupsIcon : backupsDisabledIcon}
                    style={styles.backupIcon}/>
                <VolumeFill
                    id={vm.disk.uid}
                    size={vm.disk.size}
                    isLoaded={vm.volumesUsedSize != null}
                    isLoading={vm.volumesUsedSizeIsLoading}
                    isError={vm.volumesUsedSizeIsError}
                    percentThreshold={11}
                    data={vm.volumesUsedSize && vm.volumesUsedSize[vm.disk.uid]}
                    name={`Основной диск (${vm.disk.name || vm.disk.uid})`}
                />
            </TouchableOpacity>
            {vm.volumes.map((volume, index) => <TouchableOpacity style={styles.diskBlock}
                onPress={() => setCurrentVolume(volume,
                    () => navigation.navigate('CVDSVMEditVolume'))}
                key={`additional-volume-${index}`}>
                <Image source={volume.is_backup_enabled ? backupsIcon : backupsDisabledIcon}
                    style={styles.backupIcon}/>
                <View style={styles.volumeFill}>
                    <VolumeFill
                        id={volume.uid}
                        size={volume.size}
                        isLoaded={vm.volumesUsedSize != null}
                        isLoading={vm.volumesUsedSizeIsLoading}
                        isError={vm.volumesUsedSizeIsError}
                        data={vm.volumesUsedSize && vm.volumesUsedSize[volume.uid]}
                        percentThreshold={11}
                        name={volume.name || volume.uid}
                    />
                </View>
            </TouchableOpacity>)}
        </View>}
        {!isEmpty(vm) && isActionButtonOpen && <ActionButton isOpen={isActionButtonOpen}
            type='+'
            disabled={!isActiveVM(vm.state)}
            disabledText={blockedButtonTooltips.cvds[vm.state]}
            onPress={() => setAddVolumeModalVisible(true)}
        />}
        <Modal isModalShowed={isAddVolumeModalShowed}
            component={<ChangeVolumeModal
                onClose={() => setAddVolumeModalVisible(false)}/>}
            onClose={() => setAddVolumeModalVisible(false)} />
        <CVDSWebsockets />
    </View>;
}

VolumesTab.propTypes = {
    vm: PropTypes.shape({}),
    setCurrentVolume: PropTypes.func
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    setCurrentVolume
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        paddingTop: 16
    },
    backupIcon: {
        height: 20,
        width: 25,
        marginRight: 12
    },
    diskBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingLeft: 16,
        paddingTop: 11,
        paddingBottom: 17,
        paddingRight: 18,
        marginBottom: 4,
        backgroundColor: '#ffffff'
    },
    volumeFill: {
        height: 38,
        flex: 1
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(VolumesTab);