import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text } from 'react-native';

import { flushErrors } from '../../../actions/forms/actions';
import { containerDelete } from '../../../actions/hosting/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'ContainerDeleteConfirmForm';

function ContainerDelete(props) {
    const {
        errors,
        onClose,
        inProgress,
        containerId,
        flushErrors,
        containerName,
        containerDelete,
    } = props;
    const navigation = useNavigation();
    React.useEffect(() => {
        flushErrors();
    }, []);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>
            Подтвердите удаление контейнера &laquo;{containerName}&raquo;
        </Text>
        <Text style={styles.text}>Вместе с контейнером будут удалены:</Text>
        <Text style={styles.text}>
            &mdash; Сайты (файлы и настройки)<br/>
            &mdash; Базы данных<br/>
            &mdash; FTP-аккаунты<br/>
        </Text>
        {errors.__all__ && <Text style={styles.error}>{errors.__all__}</Text>}
        <View style={styles.buttons}>
            <AcceptButton
                type='danger'
                onSubmit={() => containerDelete(formId, containerId, () => {
                    onClose();
                    navigation.navigate('Hosting');
                })}
                title='Удалить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

ContainerDelete.propTypes = {
    inProgress: PropTypes.bool,
    onClose: PropTypes.func,
    errors: PropTypes.shape({}),
    containerChangeSSHPassword: PropTypes.func,
    flushErrors: PropTypes.func,
    containerId: PropTypes.number,
    containerName: PropTypes.string,
};

const mapStateToProps = (state) => ({
    inProgress: state.hosting.containerDeleteInProgress,
    errors: state.forms.errors.ContainerDeleteConfirmForm || {},
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    containerDelete
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 44,
        paddingBottom: 42,
        paddingLeft: 40,
        paddingRight: 40,
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 10,
        color: '#455562',
    },
    buttons: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
        fontSize: 13,
    },
    text: {
        color: '#888888',
        fontSize: 14,
        lineHeight: 20,
        marginBottom: 10,
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ContainerDelete);