import React from 'react';
import { useSelector } from 'react-redux';
import {
    View,
    StyleSheet,
    Text,
    Linking,
} from 'react-native';

import Modal from '../General/Modal/Modal';
import Switch from '../General/Switch/Switch';
import { formatDate } from '../../utils/general';
import ChangeRenewalModal from './Modals/ChangeRenewalModal';
import publishersMap from '../../../data/certificates/publishers.json';

function SslDetail() {
    const { certificate } = useSelector((state) => state.certificates);

    const isError = certificate.state === 'Error';
    const needShowPublisher = certificate.publisher !== 'Unknown';
    const hasRenewal = needShowPublisher && certificate.publisher !== 'LeaderSSL';

    const [isChangeRenewalModalShowed, setChangeRenewalModalShowed] = React.useState(false);

    return <View>
        {isError && <View style={styles.errorBlock}>
            <Text style={styles.errorText}>Ошибка</Text>
            <Text style={styles.supportLink}
                onPress={() => Linking.openURL('mailto:info@netangels.ru')}>
                Обратитесь в техподдержку
            </Text>
        </View>}
        {certificate.is_in_transfer && <View style={styles.transferBlock}>
            <Text style={styles.errorText}>В процессе передачи</Text>
        </View>}
        <View style={styles.headerBlock}>
            <Text style={styles.headerText}>
                Данные сертификата
            </Text>
        </View>
        <View style={styles.infoItem}>
            <Text style={styles.infoItemName}>Издатель</Text>
            <Text style={styles.infoItemValue}>
                {publishersMap[certificate.publisher]}
            </Text>
        </View>
        <View style={styles.infoItem}>
            <Text style={styles.infoItemName}>Выпущен</Text>
            <Text style={styles.infoItemValue}>
                {formatDate(certificate.not_before, 'DD.MM.YYYY')}
            </Text>
        </View>
        <View style={styles.infoItem}>
            <Text style={styles.infoItemName}>Действует до</Text>
            <Text style={styles.infoItemValue}>
                {formatDate(certificate.not_after, 'DD.MM.YYYY')}
            </Text>
        </View>
        {hasRenewal && <View style={[
            styles.infoItem,
            { paddingBottom: 14, paddingTop: 13, minHeight: 47 }
        ]}>
            <Switch label='Автопродление'
                isEnable={certificate.is_renewal}
                onChange={() => setChangeRenewalModalShowed(true)}
            />
        </View>}
        <View style={styles.headerBlock}>
            <Text style={styles.headerText}>
                домены с сертификатом
            </Text>
        </View>
        {certificate && certificate.domains.map((domain, i) => <View
            key={`certificate-domain-${i}`} style={styles.infoItem}>
            <Text style={styles.infoItemName}>{domain}</Text>
        </View>)}
        <Modal isModalShowed={isChangeRenewalModalShowed}
            component={<ChangeRenewalModal
                certificate={certificate}
                onClose={() => {
                    setChangeRenewalModalShowed(false);
                }}/>}
            onClose={() => setChangeRenewalModalShowed(false)} />
    </View>;
}

const styles = StyleSheet.create({
    headerBlock: {
        paddingBottom: 16,
        paddingTop: 25,
        paddingLeft: 20,
        paddingRight: 21,
        flex: 1,
        maxHeight: 56,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        color: '#455562',
    },
    infoItem: {
        flex: 1,
        backgroundColor: '#ffffff',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 16,
        paddingTop: 16,
        paddingRight: 20,
        paddingLeft: 20,
        marginBottom: 1,
    },
    infoItemName: {
        fontSize: 13,
        color: '#455562',
        fontWeight: '500',
    },
    infoItemValue: {
        fontSize: 13,
        color: '#455562',
        fontWeight: '400',
        opacity: 0.7,
    },
    errorBlock: {
        backgroundColor: '#e07474',
        paddingTop: 16,
        paddingBottom: 17,
        paddingLeft: 20,
        paddingRight: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    errorText: {
        color: '#ffffff',
        fontSize: 13,
        fontWeight: '500',
    },
    transferBlock: {
        backgroundColor: '#e28a58',
        paddingTop: 16,
        paddingBottom: 17,
        paddingLeft: 20,
        paddingRight: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    supportLink: {
        color: '#ffffff',
        fontSize: 12,
        textDecorationLine: 'underline',
    },
});

export default SslDetail;
