import React from 'react';
import Config from 'react-native-config';
import * as dayjs from 'dayjs';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from 'redux-logger';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Platform } from 'react-native';

import { makeWebsocketManager } from '@netangels/core/websockets/websocket';
import { userChannelName } from '@netangels/core/constants/websockets';

import { ModalContextProvider } from './client/contexts/ModalContext';

import { dayjsLocal } from './client/utils/general';

import makeReducers from './client/reducers';
import AppNavigation from './client/route';
import { WebsocketContextProvider } from './client/contexts/WebsocketContext';

export default function App() {
    const gateway = {
        url: Config.GATEWAY_API_URL,
    };
    let initialState = {};
    let wsData = null;

    if (Platform.OS === 'web') {
        gateway.url = window.GATEWAY_API_URL;
        const token = window.GATEWAY_TOKEN;
        initialState = window.__STATES__;
        if (token) {
            gateway.token = token;
        }
        wsData = {
            url: window.WS_URL,
            tokenUrl: window.WS_TOKEN_URL,
            signChannelsUrl: window.WS_SIGN_CHANNELS_URL,
        };
    }

    const thunk = thunkMiddleware.withExtraArgument({ gateway });
    const middlewares = [thunk];

    middlewares.push(loggerMiddleware);

    const store = createStore(
        makeReducers(initialState),
        applyMiddleware(...middlewares)
    );

    let ws = null;
    if (wsData != null) {
        ws = makeWebsocketManager(
            `${wsData.url}/connection/websocket`,
            wsData.tokenUrl,
            { subscribeEndpoint: wsData.signChannelsUrl }
        );
    }

    dayjsLocal();
    dayjs.locale('ru');

    return (
        <Provider store={store}>
            <SafeAreaProvider>
                <WebsocketContextProvider ws={ws}>
                    <ModalContextProvider>
                        <AppNavigation />
                    </ModalContextProvider>
                </WebsocketContextProvider>
            </SafeAreaProvider>
        </Provider>
    );
}
