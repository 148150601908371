import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../../icons';
import {
    fetchUserVMList,
    fetchCVDSBackups,
    restoreFromBackupToInit
} from '../../actions/cvds/actions';
import { screenHeaderStyles } from '../../utils/styles';
import RestoreBackupListLoader from '../../components/General/RestoreBackupLoader/RestoreBackupListLoader';
import RestoreBackupBlock from '../../components/CVDS/CvdsBackup/RestoreBackupBlock';
import ScreenHeaderTitle from '../../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const CvdsBackupStack = createStackNavigator();

function CvdsBackupStackScreen() {
    const { vmList } = useSelector(
        (state) => state.cvds,
    );
    const { backups, backupsInProgress } = useSelector((state) => state.cvdsBackups);
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();

    useFocusEffect(React.useCallback(() => {
        dispatch(fetchCVDSBackups());
        dispatch(restoreFromBackupToInit());
        if (vmList == null) {
            dispatch(fetchUserVMList());
        }
    }, []));

    return <CvdsBackupStack.Navigator>
        <CvdsBackupStack.Screen name="CVDSBackupScreen"
            component={
                (backups == null || backupsInProgress)
                    ? RestoreBackupListLoader : RestoreBackupBlock}
            options={{
                headerTitle: () => <ScreenHeaderTitle title='Мастер восстановления' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.navigate('MainStack'),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </CvdsBackupStack.Navigator>;
}

export default CvdsBackupStackScreen;
