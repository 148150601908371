import React from 'react';
import PropTypes from 'prop-types';
import { View, ImageBackground, StyleSheet } from 'react-native';

import {
    getThresholdColorClass,
    clamp
} from '../../../utils/general';
import { tileFillGray } from '../../../icons';
import { thresholdColors } from '../../../constants/general';

const styles = StyleSheet.create({
    resourceFillsScale: {
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2,
        paddingTop: 2,
        paddingBottom: 2,
        textAlign: 'center'
    },
    resourceFillsScaleImage: {
        resizeMode: 'repeat'
    },
    resourceFillsScaleImageBackround: {
        flex: 1
    }
});

function ResourceFillScale(props) {
    const {
        children,
        needBorderRadius = false,
        scaleModifiers = [],
        min = 0
    } = props;
    const percent = clamp(props.percent, min);
    const color = props.color || getThresholdColorClass(percent);

    if (color === 'tileGrayFill') {
        return <View style={[{ width: `${percent}%`, borderRadius: needBorderRadius ? 4 : 0 }]}>
            <ImageBackground source={tileFillGray} style={styles.resourceFillsScaleImageBackround}
                imageStyle={styles.resourceFillsScaleImage}>
                {children}
            </ImageBackground>
        </View>;
    }
    return <View style={[
        {
            width: `${percent}%`,
            borderRadius: needBorderRadius ? 4 : 0,
            backgroundColor: thresholdColors[color]
        },
        styles.resourceFillsScale,
        ...scaleModifiers
    ]}>
        {children}
    </View>;
}

ResourceFillScale.propTypes = {
    children: PropTypes.shape({
        title: PropTypes.string,
        type: PropTypes.string,
        link: PropTypes.string,
    }),
    color: PropTypes.string,
    percent: PropTypes.number.isRequired,
    min: PropTypes.number,
    needBorderRadius: PropTypes.bool,
};

export default ResourceFillScale;
