import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        flexDirection: 'row'
    },
    text: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 20
    },
    dashedSpring: {
        flex: 1,
        bottom: 4,
        position: 'relative',
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        borderBottomColor: '#a1aab1'
    },
    total: {
        fontWeight: '600'
    },
    changed: {
        color: '#e17334',
        fontWeight: '600'
    }
});

function CreatePacketRow(props) {
    const {
        title,
        value,
        format,
        type = 'info',
        isChange = false
    } = props;
    const textStyles = [styles.text];
    if (type === 'total') {
        textStyles.push(styles.total);
    }
    if (isChange) {
        textStyles.push(styles.changed);
    }
    return <View style={styles.wrapper}>
        <Text style={textStyles}>{title}</Text>
        <View style={styles.dashedSpring}/>
        <Text style={textStyles}>{format(value)}</Text>
    </View>;
}

CreatePacketRow.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    value: PropTypes.any,
    format: PropTypes.func.isRequired,
    isChange: PropTypes.bool
};

export default CreatePacketRow;