import { isFunction } from '@netangels/core/utils/functions';
import { formErrors } from '../forms/actions';

import {
    ON_CHECK_TRANSFER_SSL,
    ON_CHECK_TRANSFER_DNS_ZONE,
    ON_CHECK_TRANSFER_DOMAINS,
    TRANSFER_ACCEPT_SUCCESSFULLY,
    TRANSFER_ACCEPT_IN_PROGRESS,
    TRANSFER_ACCEPT_WITH_ERRORS,
    TRANSFER_STATUS_IN_PROGRESS,
    TRANSFER_STATUS_WITH_ERRORS,
    TRANSFER_STATUS_SUCCESSFULLY,
    TRANSFER_START_WITH_ERRORS,
    TRANSFER_START_IN_PROGRESS,
    TRANSFER_START_SUCCESSFULLY,
    TRANSFER_CANCEL_BY_RESOURCE_IN_PROGRESS,
    TRANSFER_CANCEL_BY_RESOURCE_SUCCESSFULLY,
    TRANSFER_CANCEL_BY_RESOURCE_WITH_ERRORS,
    TRANSFER_RESOURCES_ON_INIT,
    TRANSFER_SEARCH_ON_INIT,
    TRANSFER_CHANGE_SEARCH_REQUEST,
    CHECK_RESOURCES_IN_PROGRESS,
    CHECK_RESOURCES_SUCCESSFULLY,
    CHECK_RESOURCES_WITH_ERRORS,
    FETCH_INSTANCE_RESOURCES_IN_PROGRESS,
    FETCH_INSTANCE_RESOURCES_SUCCESSFULLY,
    FETCH_INSTANCE_RESOURCES_WITH_ERRORS,
    CHANGE_TRANSFER_QUERY_SUCCESSFULLY,
} from './actionTypes';

import TransferResource from '../../gateway/resources/transfer';

export const onCheckDNSZone = (checkedEntities) => async (dispatch) => {
    dispatch({ type: ON_CHECK_TRANSFER_DNS_ZONE, data: { checkedEntities } });
};

export const onCheckSSL = (checkedEntities) => async (dispatch) => {
    dispatch({ type: ON_CHECK_TRANSFER_SSL, data: { checkedEntities } });
};

export const onCheckDomains = (checkedEntities) => async (dispatch) => {
    dispatch({ type: ON_CHECK_TRANSFER_DOMAINS, data: { checkedEntities } });
};

export const initResources = () => async (dispatch) => {
    dispatch({ type: TRANSFER_RESOURCES_ON_INIT });
};

export const initSearch = () => async (dispatch) => {
    dispatch({ type: TRANSFER_SEARCH_ON_INIT });
};

export const changeSearchRequest = (request) => async (dispatch) => {
    dispatch({
        type: TRANSFER_CHANGE_SEARCH_REQUEST,
        data: { searchRequest: request },
    });
};

export const checkResources = (formId, resources) => async (dispatch, getParams, { gateway }) => {
    dispatch({ type: CHECK_RESOURCES_IN_PROGRESS });
    const resource = new TransferResource(gateway);
    const response = await resource.checkResources(resources);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CHECK_RESOURCES_SUCCESSFULLY,
            data,
        });
    } else {
        dispatch(formErrors(formId, data.errors));
        dispatch({ type: CHECK_RESOURCES_WITH_ERRORS });
    }
};

export const startTransfer = (
    formId,
    { recipient, resources },
    checkResourcesFormId,
    callback
) => async (dispatch, getParams, { gateway }) => {
    dispatch({ type: TRANSFER_START_IN_PROGRESS });
    const resource = new TransferResource(gateway);
    const response = await resource.startTask(recipient, resources);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: TRANSFER_START_SUCCESSFULLY,
            data,
        });
        dispatch(checkResources(checkResourcesFormId, resources));
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: TRANSFER_START_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const cancelTransferByResource = (
    formId,
    resourceId,
    resourceType,
    closeCallback,
    callback
) => async (dispatch, getParams, { gateway }) => {
    dispatch({
        type: TRANSFER_CANCEL_BY_RESOURCE_IN_PROGRESS,
        data: { resourceId, resourceType },
    });
    const resource = new TransferResource(gateway);
    const response = await resource.cancelTaskByResource(
        resourceId,
        resourceType,
    );
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: TRANSFER_CANCEL_BY_RESOURCE_SUCCESSFULLY,
            data: { resourceId, resourceType },
        });
        if (isFunction(closeCallback)) {
            closeCallback();
        }
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({
            type: TRANSFER_CANCEL_BY_RESOURCE_WITH_ERRORS,
            data: { resourceId, resourceType },
        });
        dispatch(formErrors(formId, data.errors));
    }
};

export const getTransferStatus = (key) => async (dispatch, getParams, { gateway }) => {
    dispatch({ type: TRANSFER_STATUS_IN_PROGRESS });
    const resource = new TransferResource(gateway);
    const response = await resource.checkKey(key);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: TRANSFER_STATUS_SUCCESSFULLY,
            data: {
                ...data,
                transferKey: key,
            },
        });
    } else {
        dispatch({ type: TRANSFER_STATUS_WITH_ERRORS });
    }
};

export const acceptTransfer = (
    formId, taskId, closeCallback, callback
) => async (dispatch, getParams, { gateway }) => {
    dispatch({ type: TRANSFER_ACCEPT_IN_PROGRESS });
    const resource = new TransferResource(gateway);
    const response = await resource.finishTask(taskId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: TRANSFER_ACCEPT_SUCCESSFULLY,
            data,
        });
        if (isFunction(closeCallback)) {
            closeCallback();
        }
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: TRANSFER_ACCEPT_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const fetchVmResources = (id) => async (dispatch, getParams, { gateway }) => {
    dispatch({ type: FETCH_INSTANCE_RESOURCES_IN_PROGRESS });
    const resource = new TransferResource(gateway);
    const response = await resource.fetchVmResources(id);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_INSTANCE_RESOURCES_SUCCESSFULLY,
            data,
        });
    } else {
        dispatch({ type: FETCH_INSTANCE_RESOURCES_WITH_ERRORS });
    }
};

export const fetchContainerResources = (id) => async (dispatch, getParams, { gateway }) => {
    dispatch({ type: FETCH_INSTANCE_RESOURCES_IN_PROGRESS });
    const resource = new TransferResource(gateway);
    const response = await resource.fetchContainerResources(id);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_INSTANCE_RESOURCES_SUCCESSFULLY,
            data,
        });
    } else {
        dispatch({ type: FETCH_INSTANCE_RESOURCES_WITH_ERRORS });
    }
};

export const fetchMailDomainResources = (id) => async (dispatch, getParams, { gateway }) => {
    dispatch({ type: FETCH_INSTANCE_RESOURCES_IN_PROGRESS });
    const resource = new TransferResource(gateway);
    const response = await resource.fetchMailDomainResources(id);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_INSTANCE_RESOURCES_SUCCESSFULLY,
            data,
        });
    } else {
        dispatch({ type: FETCH_INSTANCE_RESOURCES_WITH_ERRORS });
    }
};

export const changeTransferQuery = (data) => async (dispatch) => {
    dispatch({
        type: CHANGE_TRANSFER_QUERY_SUCCESSFULLY,
        data,
    });
};
