import {
    PARTNERSHIP_STATISTIC_IN_PROGRESS,
    PARTNERSHIP_STATISTIC_SUCCESSFULLY,
    PARTNERSHIP_STATISTIC_WITH_ERRORS,
} from '../../actions/partnership/actionTypes';

export default () => {
    const defaultState = {
        statistics: null,
        inProgress: false,
        isError: false,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case PARTNERSHIP_STATISTIC_IN_PROGRESS: {
            return {
                ...state,
                isError: false,
                inProgress: true,
            };
        }
        case PARTNERSHIP_STATISTIC_SUCCESSFULLY: {
            const { data } = action;
            return {
                ...state,
                statistics: data,
                isError: false,
                inProgress: false
            };
        }
        case PARTNERSHIP_STATISTIC_WITH_ERRORS: {
            return {
                ...state,
                statistics: null,
                isError: true,
                inProgress: false
            };
        }
        default:
            return state;
        }
    };
};
