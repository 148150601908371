import React from 'react';
import PropTypes from 'prop-types';
import {
    View, Image, StyleSheet, Text, TouchableOpacity
} from 'react-native';

import { trashIcon } from '../../../icons';
import Modal from '../../General/Modal/Modal';
import AccountEmailDelete from './AccountEmailDeleteModal';

function Emails({
    emails, unverifiedEmails, formId, emailsType, deleteEmail
}) {
    const components = [];
    if (unverifiedEmails.length !== 0) {
        unverifiedEmails.forEach((unverifiedEmail) => components.push(
            <Email
                email={unverifiedEmail}
                formId={formId}
                emailType={emailsType}
                isUnverified={true}
                deleteEmail={deleteEmail}
            />
        ));
    }
    if (emails.length !== 0) {
        emails.forEach((email) => components.push(
            <Email
                email={email}
                formId={formId}
                emailType={emailsType}
                isUnverified={false}
                deleteEmail={deleteEmail}
            />
        ));
    }
    return components;
}

function Email({
    email, formId, emailType, isUnverified, deleteEmail
}) {
    const [isModalDelete, setModalDelete] = React.useState(false);
    return (
        <View style={styles.email} key={`email-${email}-${emailType}`}>
            <Text style={styles.emailName} numberOfLines={1}>
                {email}
            </Text>
            {isUnverified && <View style={styles.unverified} />}
            <View style={styles.spring} />
            <TouchableOpacity
                onPress={() => setModalDelete(true)}
                style={styles.deleteBlock}
            >
                <Image source={trashIcon} style={styles.trashIcon} />
            </TouchableOpacity>
            <Modal
                isModalShowed={isModalDelete}
                component={
                    <AccountEmailDelete
                        formId={formId}
                        email={email}
                        deleteEmail={deleteEmail}
                        emailType={emailType}
                        onClose={() => setModalDelete(false)}
                    />
                }
                onClose={() => setModalDelete(false)}
            />
        </View>
    );
}

function AccountEmail(props) {
    const {
        emails, formId, emailsType, deleteEmail, unverifiedEmails
    } = props;
    return (
        <View style={styles.wrapper}>
            <Emails
                emails={emails}
                unverifiedEmails={unverifiedEmails}
                formId={formId}
                emailsType={emailsType}
                deleteEmail={deleteEmail}
            />
        </View>
    );
}

AccountEmail.propTypes = {
    formId: PropTypes.string,
    emailsType: PropTypes.string,
    emails: PropTypes.arrayOf(PropTypes.string),
    unverifiedEmails: PropTypes.arrayOf(PropTypes.string),
};

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(69, 85, 98, 0.15)',
    },
    email: {
        width: '100%',
        flexDirection: 'row',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 12,
        paddingRight: 12,
        fontSize: 12,
        lineHeight: 14,
        color: '#455562',
        alignItems: 'center',
        borderTopWidth: 1,
        borderTopColor: 'rgba(69, 85, 98, 0.15)',
    },
    emailName: {
        marginRight: 9,
    },
    unverified: {
        height: 8,
        width: 8,
        borderRadius: 5,
        backgroundColor: '#ef874d',
        marginRight: 35,
    },
    spring: {
        flex: 1,
    },
    trashIcon: {
        height: 12,
        width: 12,
    },
});

export default AccountEmail;
