const BaseResource = require('../resource');

class FTPSResource extends BaseResource {
    async create(data) {
        return this.makeRequest('post', '', data);
    }

    async delete(ftpsId) {
        return this.makeRequest('delete', `${ftpsId}/`);
    }

    async changePassword(ftpsId, password) {
        return this.makeRequest('put', `${ftpsId}/set-password/`, { password });
    }

    async changeDirectory(ftpsId, homedir) {
        return this.makeRequest('put', `${ftpsId}/`, { homedir });
    }
}

FTPSResource.resource = 'ftps';
FTPSResource.service = 'hosting';

module.exports = FTPSResource;
