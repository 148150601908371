import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';

import MonitoringEventLink from './MonitoringEventLink';

function MonitoringEventMultipleLink(props) {
    const { links } = props;

    return (
        <View style={styles.monitoringBlock}>
            {links
                .map((linkObject, i) => {
                    if (linkObject.link) {
                        return (
                            <MonitoringEventLink
                                {...props}
                                link={linkObject.link}
                                key={`ui-kit-monitoring-event-link-${i}`}
                                title={linkObject.title}
                            />
                        );
                    }
                    return (
                        <Text
                            style={styles.text}
                            key={`ui-kit-monitoring-event-link-${i}`}
                        >
                            {linkObject.title}
                        </Text>
                    );
                })
                .reduce((prevLink, currLink, i, arr) => {
                    if (arr.length > 2 && i !== arr.length - 1) {
                        return [prevLink, ', ', currLink];
                    }
                    return [prevLink, <Text
                        style={styles.delimiter}
                        key={`ui-kit-monitoring-event-link-delimiter-${i}`}
                    >
                        или
                    </Text>, currLink];
                })}
        </View>
    );
}

MonitoringEventMultipleLink.dynamicName = 'MonitoringEventMultipleLink';

MonitoringEventMultipleLink.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            async: PropTypes.bool,
            title: PropTypes.string,
            link: PropTypes.string,
        }),
    ),
};

const styles = StyleSheet.create({
    monitoringBlock: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    delimiter: {
        color: '#455562',
        fontSize: 10,
        fontWeight: '400',
        lineHeight: 14,
        marginLeft: 3,
        marginRight: 3,
        opacity: 0.8
    },
    text: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '400',
        lineHeight: 14,
    },
});

MonitoringEventMultipleLink.dynamicName = 'MonitoringEventMultipleLink';

export default MonitoringEventMultipleLink;
