import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text } from 'react-native';

import { monitoringColorsClassMap } from '../../constants/monitoring';
import ResourceFills from '../General/ResourceFills/ResourceFills';

function MonitoringEventProgressBar(props) {
    const { title, metadata, severity } = props;

    return (
        <ResourceFills
            isLoaded={true}
            name={title}
            height={6}
            needBorderRadius={true}
            additionalHeaderTextStyles={[styles.title,
                { color: severity.toLowerCase() === 'warning' ? '#e17334' : '#d55a5a' }]}
            scales={[
                {
                    color: monitoringColorsClassMap[severity],
                    percent: Number.parseInt(metadata.usage, 10),
                },
            ]}
        />
    );
}

const styles = StyleSheet.create({
    text: {
        fontSize: 10,
        fontWeight: '500',
        lineHeight: 11,
        whiteSpace: 'nowrap',
    },
});

MonitoringEventProgressBar.propTypes = {
    metadata: PropTypes.shape({
        usage: PropTypes.string,
    }),
    severity: PropTypes.string,
    title: PropTypes.string,
};
MonitoringEventProgressBar.dynamicName = 'MonitoringEventProgressBar';

export default MonitoringEventProgressBar;
