import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, StyleSheet } from 'react-native';

import { detectTariffByRam } from '../utils';
import { calculationPacketRefresh } from '../../../actions/hosting/actions';
import UpdatePacketModal from '../../General/CreatePacketModal/UpdatePacketModal';

const formId = 'ContainerPacketUpdateConfirm';

function transformToInt(v) {
    return parseInt(v, 10);
}

function getSummaryValue(currentValue, nextValue) {
    return (transformToInt(currentValue) + transformToInt(nextValue)).toString();
}

function getPacketInfo(packet) {
    return [{
        value: detectTariffByRam(transformToInt(packet.memory)),
        isChange: false,
        title: 'Тариф',
        format: v => v.title
    }, {
        value: transformToInt(packet.disk) / 1024,
        isChange: false,
        title: 'Диск',
        format: v => `${v} ГБ`
    }, {
        value: packet.memcached,
        isChange: false,
        title: 'Memcache',
        format: v => `${v} МБ`
    }, {
        value: packet.redis,
        isChange: false,
        title: 'Redis',
        format: v => `${v} МБ`
    }];
}

function getNewPacketInfo(calculation, packet) {
    return [{
        value: detectTariffByRam(transformToInt(getSummaryValue(packet.memory,
            calculation.memory.value))),
        isChange: transformToInt(calculation.memory.value) !== 0,
        title: 'Тариф',
        format: v => v.title
    }, {
        value: transformToInt(getSummaryValue(packet.disk,
            calculation.disk.value)) / 1024,
        isChange: transformToInt(calculation.disk.value) !== 0,
        title: 'Диск',
        format: v => `${v} ГБ`
    }, {
        value: getSummaryValue(packet.memcached,
            calculation.memcached.value),
        isChange: transformToInt(calculation.memcached.value) !== 0,
        title: 'Memcache',
        format: v => `${v} МБ`
    }, {
        value: getSummaryValue(packet.redis,
            calculation.redis.value),
        isChange: transformToInt(calculation.redis.value) !== 0,
        title: 'Redis',
        format: v => `${v} МБ`
    }];
}

function HostingUpdateAnnualModal(props) {
    const {
        errors,
        inProgress,
        onClose,
        container,
        calculation,
        calculationPacketRefresh,
    } = props;
    const {
        packet,
        restAmount,
        monthPrice,
        currentPacketCost,
        recalculationCharge,
    } = calculation;
    return <View style={styles.wrapper}>
        <Text style={[styles.title]}>Обновление годового пакета</Text>
        <UpdatePacketModal
            inProgress={inProgress}
            packetDescriptionParameters={getPacketInfo(packet)}
            newPacketDescriptionParameters={getNewPacketInfo(calculation, packet)}
            errors={errors}
            timeTo={packet.timeTo}
            monthPrice={monthPrice}
            onClose={onClose}
            currentPacketCost={currentPacketCost}
            recalculationCharge={recalculationCharge}
            restAmount={restAmount}
            onAccept={() => calculationPacketRefresh(formId, container.id, () => onClose())}/>
    </View>;
}

HostingUpdateAnnualModal.propTypes = {
    container: PropTypes.shape({}),
    inProgress: PropTypes.bool,
    calculation: PropTypes.shape({
        packet: PropTypes.shape({
            disk: PropTypes.string,
            redis: PropTypes.string,
            memory: PropTypes.string,
            memcached: PropTypes.string
        }),
        disk: PropTypes.shape({ value: PropTypes.string }),
        redis: PropTypes.shape({ value: PropTypes.string }),
        memory: PropTypes.shape({ value: PropTypes.string }),
        memcached: PropTypes.shape({ value: PropTypes.string }),
        currentPacketCost: PropTypes.number,
        monthPrice: PropTypes.number,
        restAmount: PropTypes.number,
        recalculationCharge: PropTypes.number
    }),
    paidTill: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    calculationPacketRefresh: PropTypes.func,
    errors: PropTypes.shape({})
};

const mapStateToProps = state => ({
    container: state.hosting.container,
    inProgress: state.hosting.updatePacketInProgress,
    errors: state.forms.errors[formId] || {}
});

const mapDispatchToProps = dispatch => bindActionCreators({
    calculationPacketRefresh
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 30,
        paddingLeft: 24,
        paddingRight: 21,
        paddingBottom: 18
    },
    title: {
        marginBottom: 16,
        color: '#455562',
        textAlign: 'center',
        fontSize: 16,
        lineHeight: 18
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(HostingUpdateAnnualModal);
