import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StyleSheet, View, Text, Image } from 'react-native';

import {
    expectedCharges,
    setForecastDate
} from '../../../actions/finances/actions';
import ForecastLoader from './ForecastLoader';
import { emptySectionFinance } from '../../../icons';
import { getResourceColorType } from '../../../utils/general';
import FinanceFilter from '../FinanceDetail/FinanceFilter/FinanceFilter';
import { statisticServicesMap, statisticServicesMapColor } from '../constants';
import HorizontalBarChart from '../../General/HorizontalBarChart/HorizontalBarChart';
import TimelineHorizontalChart from '../../General/Statistics/TimelineHorizontalChart';
import FinanceStatisticList from '../FinanceDetail/FinanceStatistic/FinanceStatisticList';

function getCurrentDateData(charges, current, statisticFilters) {
    const chargesGroups = charges[current];

    if (statisticFilters.length > 0) {
        return {
            ...chargesGroups,
            groups: chargesGroups.groups.map((resource) => ({
                ...resource,
                colorType: getResourceColorType(resource)
            }))
        };
    }
    return chargesGroups;
}

function timelineData(dates, charges) {
    return dates.map((d) => {
        const { balance } = charges[d];
        const isBalanceNegative = balance < 0;
        return {
            day: dayjs(d, 'YYYY-MM-DD'),
            dayColorModifier: isBalanceNegative ? '#d06565' : 'rgba(17, 171, 112, 0.75)',
            modifiers: isBalanceNegative ? ['red'] : ['green']
        };
    });
}

function getStatisticSummarizeResources(currentDateData,
    statisticFilters, setStatisticFilters) {
    const statisticsCharges = currentDateData;
    const { summarize } = statisticsCharges;
    if (statisticFilters.length > 0) {
        return statisticsCharges.groups
            .filter((resource) => statisticFilters.find(s => s.service === resource.service))
            .map((resource) => ({
                id: resource.service,
                colorType: getResourceColorType(resource),
                title: resource.resource_name || 'Другое',
                value: resource.total,
                formatValue: (v) => `${v.toFixed(2)} ₽`
            }));
    }
    return summarize.map((service) => ({
        id: service.service,
        title: statisticServicesMap[service.service] || service.service,
        colorType: statisticServicesMapColor[service.service] || 'gray',
        value: service.total,
        onClick: () => {
            if (!statisticFilters.find((s) => s.service !== service.service)) {
                setStatisticFilters(statisticFilters.concat(service));
            }
        },
        formatValue: (v) => `${v.toFixed(2)} ₽`
    }));
}

function Forecast(props) {
    const {
        dates,
        charges,
        current,
        inProgress,
        expectedCharges,
        setForecastDate
    } = props;
    const [statisticFilters, setStatisticFilters] = React.useState([]);

    React.useEffect(() => {
        if (charges == null && !inProgress) {
            expectedCharges();
        }
        return () => {};
    }, []);
    let currentDateData = null;
    let statisticSummarizeResources = [];
    if (!inProgress && charges) {
        currentDateData = getCurrentDateData(charges,
            current, statisticFilters);
        statisticSummarizeResources = getStatisticSummarizeResources(currentDateData,
            statisticFilters, setStatisticFilters);
    }
    const currentDate = dayjs(current, 'YYYY-MM-DD');
    const hasForecast = currentDateData && currentDateData.groups.length !== 0;
    return <View>
        <Text style={styles.title}>ПРОГНОЗ БАЛАНСА</Text>
        {inProgress && <ForecastLoader />}
        {!inProgress && <View style={styles.wrapper}>
            {current && <Text style={styles.balanceText}>
                Баланс на {currentDate.format('D MMMM')}
            </Text>}
            {charges && <Text style={[styles.balance,
                { color: currentDateData.balance < 0 ? '#d06565' : '#11AB70' }]}>
                {currentDateData.balance.toFixed(2)}&nbsp;₽
            </Text>}
            <View>
                {charges && <TimelineHorizontalChart
                    current={currentDate}
                    days={timelineData(dates, charges)}
                    onChange={(day) => {
                        setForecastDate(day.format('YYYY-MM-DD'));
                    }}
                />}
                {(charges && !hasForecast) && <View style={styles.emptyWrapper}>
                    <Image source={emptySectionFinance} style={styles.image}/>
                    <Text style={styles.emptyText}>
                        Нет ожидаемых списаний на {currentDate.format('D MMMM')}
                    </Text>
                </View>}
            </View>
            {charges && statisticSummarizeResources.length > 0 && <View
                style={styles.forecastListHeader}>
                <Text style={styles.forecastListTitle}>Ожидаемые списания за услуги</Text>
                <View style={styles.filtersBlock}>
                    {statisticFilters.map((item, i) => <View
                        key={`finance-forecaster-cost-${i}`}>
                        <FinanceFilter
                            title={statisticServicesMap[item.service] || item.service}
                            onClose={() => setStatisticFilters(
                                statisticFilters.filter((r) => r.service !== item.service)
                            )}
                        />
                    </View>)}
                </View>
                <HorizontalBarChart
                    resources={statisticSummarizeResources}
                    formatTotal={(v) => `Всего: ${v.toFixed(2)} ₽`} />
            </View>}
            {charges && <FinanceStatisticList
                withoutGroups={true}
                filters={statisticFilters}
                statisticsCharges={currentDateData}
            />}
        </View>}
    </View>
};

Forecast.propTypes = {
    current: PropTypes.string,
    dates: PropTypes.arrayOf(PropTypes.string),
    charges: PropTypes.objectOf(PropTypes.shape({
        balance: PropTypes.number,
        groups: PropTypes.arrayOf(PropTypes.shape({
            service: PropTypes.string,
            resource_name: PropTypes.string,
            total: PropTypes.number
        })),
        summarize: PropTypes.arrayOf(PropTypes.shape({
            service: PropTypes.string,
            total: PropTypes.number
        }))
    })),
    isError: PropTypes.bool,
    inProgress: PropTypes.bool,
    expectedCharges: PropTypes.func,
    setForecastDate: PropTypes.func
};

const mapStateToProps = state => ({
    ...state.expectedCharges
});
const mapDispatchToProps = dispatch => bindActionCreators({
    expectedCharges,
    setForecastDate
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
        backgroundColor: '#ffffff',
        flex: 1
    },
    title: {
        opacity: 0.75,
        color: '#455562',
        fontSize: 11,
        fontWeight: '600',
        letterSpacing: 1.38,
        lineHeight: 13,
        marginLeft: 20,
        marginTop: 25,
        marginBottom: 11
    },
    balanceText: {
        opacity: 0.8,
        color: '#455562',
        fontSize: 12,
        fontWeight: '600',
        lineHeight: 14,
        marginTop: 16,
        marginLeft: 19,
        marginBottom: 7
    },
    balance: {
        fontSize: 24,
        fontWeight: '600',
        lineHeight: 28,
        marginLeft: 19,
        marginBottom: 6
    },
    filtersBlock: {
        marginBottom: 8,
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    forecastListHeader: {
        paddingLeft: 20,
        paddingRight: 15,
        paddingBottom: 26
    },
    forecastListTitle: {
        opacity: 0.75,
        color: '#455562',
        fontSize: 11,
        fontWeight: '600',
        textTransform: 'uppercase',
        letterSpacing: 1.38,
        lineHeight: 13,
        marginTop: 28,
        marginBottom: 10
    },
    image: {
        height: 140,
        width: 260,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    emptyWrapper: {
        marginTop: 14,
        paddingTop: 20,
        paddingBottom: 35,
        paddingLeft: 20,
        paddingRight: 20
    },
    emptyText: {
        marginTop: 20,
        fontSize: 14,
        color: '#455562',
        textAlign: 'center'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Forecast);
