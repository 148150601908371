const BaseResource = require('./resource');

class NotificationResource extends BaseResource {
    list(limit, offset) {
        return this.makeRequest('get', null, { limit: limit || 100, offset: offset || 0 });
    }

    mark(data) {
        return this.makeRequest('post', 'mark/read/', data);
    }

    hide(data) {
        return this.makeRequest('post', 'mark/hidden/', data);
    }

    readAll() {
        return this.makeRequest('post', 'mark/all-read/');
    }
}

NotificationResource.resource = null;
NotificationResource.service = 'notification';

module.exports = NotificationResource;
