import {
    mysqlIcon,
    mongodbIcon,
    postgresIcon,
    rorIcon,
    modxIcon,
    drupalIcon,
    bitrixIcon,
    joomlaIcon,
    djangoIcon,
    wordpressIcon,
} from '../icons/index';

export const engines = {
    php: 'PHP',
    python: 'Python',
    node: 'Node.js',
    ruby: 'Ruby',
    exec: 'Python AsyncIO'
};

export const dbmsList = [
    {
        type: 'mysql',
        name: 'MySQL',
        icon: mysqlIcon,
    }, {
        type: 'postgres',
        name: 'PostgreSQL',
        icon: postgresIcon,
    }, {
        type: 'mongodb',
        name: 'MongoDB',
        icon: mongodbIcon,
    }
];

export const dbms = {
    mysql: 'MySQL',
    mongodb: 'MongoDB',
    postgres: 'PostgreSQL',
};

export const cmsList = [
    {
        name: 'Bitrix',
        icon: bitrixIcon,
        versions: [
            {
                name: 'Старт',
                type: 'bitrix_start'
            }, {
                name: 'Стандарт',
                type: 'bitrix_standart',
                default: true
            }, {
                name: 'Малый бизнес',
                type: 'bitrix_smallbus'
            }, {
                name: 'Бизнес',
                type: 'bitrix_business'
            }
        ]
    }, {
        name: 'Wordpress',
        icon: wordpressIcon,
        versions: [
            {
                type: 'wordpress',
                default: true
            }
        ]
    }, {
        name: 'Joomla',
        icon: joomlaIcon,
        versions: [
            {
                type: 'joomla',
                default: true
            }
        ]
    }, {
        name: 'ModX',
        icon: modxIcon,
        versions: [
            {
                type: 'modx',
                default: true
            }
        ]
    }, {
        name: 'Drupal',
        icon: drupalIcon,
        versions: [
            {
                type: 'drupal',
                default: true
            }
        ]
    }, {
        name: 'Django',
        icon: djangoIcon,
        versions: [
            {
                name: 'MySQL',
                type: 'django_mysql',
                default: true
            }, {
                name: 'PostgreSQL',
                type: 'django_postgres'
            }
        ]
    }, {
        name: 'RoR',
        icon: rorIcon,
        versions: [
            {
                name: 'MySQL',
                type: 'rails_mysql',
                default: true
            }, {
                name: 'PostgreSQL',
                type: 'rails_postgres'
            }
        ]
    }
];

export const charsetMap = [
    {
        value: '-',
        label: 'Нет'
    },
    {
        value: 'utf-8',
        label: 'UTF-8'
    }, {
        value: 'windows-1251',
        label: 'Windows-1251'
    }
];

export const charsetMapForDbms = [
    {
        value: 'utf8',
        label: 'UTF-8'
    }, {
        value: 'cp1251',
        label: 'CP1251'
    }
];

export const normalizeCharsetMap = {
    '-': {
        value: '-',
        label: 'Нет'
    },
    'utf-8': {
        value: 'utf-8',
        label: 'UTF-8'
    },
    'windows-1251': {
        value: 'windows-1251',
        label: 'Windows-1251'
    },
    utf8: {
        value: 'utf8',
        label: 'UTF-8'
    },
    windows1251: {
        value: 'windows1251',
        label: 'Windows-1251'
    },
    cp1251: {
        value: 'cp1251',
        label: 'CP1251'
    }
};

export const TABLE_VIEW = 'TABLE_VIEW';
export const LIST_VIEW = 'LIST_VIEW';

export const tariffs = [
    {
        name: 'lite',
        title: 'Лайт',
        default: true,
        description: 'Для маленьких',
        ram: 512,
        cpu: 100
    }, {
        name: 'standard',
        title: 'Стандарт',
        description: 'Стандарт',
        ram: 1024,
        cpu: 200
    }, {
        name: 'profi',
        title: 'Профи',
        description: 'На вырост',
        ram: 2048,
        cpu: 400
    }
];

export const tariffsMapByRam = (() => {
    const map = {};
    tariffs.forEach((item) => {
        map[item.ram] = item;
    });
    return map;
})();

export const environmentMap = {
    new: 2,
    old: 1
};

export const cpuProblemThreshold = 95;
export const memoryProblemThreshold = 95;
export const storageProblemThreshold = 95;

export const bytesOptions = {
    unit: 'b', // Входные юниты
    output: 'b', // Выходные юниты (минимально)
    decimals: 2, // Кол-во цифр после запятой
    empty: undefined, // Тест для нулевого значения,
    units: ['b', 'kb', 'mb', 'gb', 'tb'],
    texts: ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ'],
    onlyNumber: false
};
