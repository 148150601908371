import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import Input from '../../General/Form/Input';
import Button from '../../General/Button/Button';
import { flushErrors } from '../../../actions/forms/actions';
import InputPassword from '../../General/Form/InputPassword';
import { changePassword } from '../../../actions/account/actions';

const formId = 'ChangeAccountPasswordConfirmForm';

function AccountChangePassword(props) {
    const {
        errors,
        flushErrors,
        changePassword,
        changePasswordInProgress,
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);

    const navigation = useNavigation();
    const [oldPassword, setChangedCurrentPass] = React.useState('');
    const [password, setChangedNewPass] = React.useState('');
    const [repeatedPassword, setChangedRepeatPass] = React.useState('');
    return <View style={styles.wrapper}>
        <View>
            <Text style={styles.description}>
                &mdash; минимум 8 символов<br/>
                &mdash; латинские буквы<br/>
                &mdash; минимум одна цифра или спецсимвол<br/>
                &mdash; без групп из одинаковых символов<br/>
            </Text>
            <Input
                label='Текущий пароль'
                errors={errors.old_password}
                needHideText={true}
                additionalWrapperStyles={[{ marginBottom: 20 }]}
                textInputAttrs={{
                    autoFocus: true,
                    numberOfLines: 1,
                    value: oldPassword,
                    onChange: (e) => setChangedCurrentPass(e.target.value)
                }}
            />
            <InputPassword
                label='Новый пароль'
                errors={errors.password}
                value={password}
                autoFocus={false}
                onChange={(value) => setChangedNewPass(value)}
            />
            <Input
                label='Повторите пароль'
                needHideText={true}
                additionalWrapperStyles={[{ marginTop: 20 }]}
                errors={errors.repeated_password}
                textInputAttrs={{
                    numberOfLines: 1,
                    value: repeatedPassword,
                    onChange: (e) => setChangedRepeatPass(e.target.value)
                }}
            />
        </View>
        <View>
            {errors.__all__ && <Text style={styles.error}>{errors.__all__}</Text>}
            <Button title='Сохранить' onPress={() => changePassword(formId,
                { oldPassword, password, repeatedPassword },
                () => {
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                    } else {
                        navigation.navigate('MainStack', { screen: 'Account' });
                    }
                })}
            inProgress={changePasswordInProgress}/>
        </View>
    </View>;
}

AccountChangePassword.propTypes = {
    errors: PropTypes.shape({}),
    changePassword: PropTypes.func,
    flushErrors: PropTypes.func,
    changePasswordInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    changePasswordInProgress: state.account.changePasswordInProgress,
    errors: state.forms.errors[formId] || {}
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    changePassword
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingBottom: 24,
        paddingRight: 18,
        paddingLeft: 20,
        flex: 1,
        height: '100%',
        justifyContent: 'space-between'
    },
    description: {
        fontSize: 14,
        lineHeight: 20,
        color: '#455562',
        paddingBottom: 20,
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountChangePassword);
