import {
    FETCH_MONITORING_IN_PROGRESS,
    FETCH_MONITORING_SUCCESSFULLY,
    FETCH_MONITORING_WITH_ERRORS,
    WS_MONITORING_EVENT_UPDATE,
    INIT_MONITORING_EVENT,
    INIT_MONITORING_SERVICE_EVENT,
    FETCH_MONITORING_BY_SERVICE_IN_PROGRESS,
    FETCH_MONITORING_BY_SERVICE_SUCCESSFULLY,
    FETCH_MONITORING_BY_SERVICE_WITH_ERRORS,
} from '../../actions/monitoring/actionTypes';

export default () => {
    const defaultState = {
        events: [],
        eventsIsError: false,
        eventsInProgress: false,
        serviceEvents: [],
        serviceEventsIsError: false,
        serviceEventsInProgress: false,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case FETCH_MONITORING_SUCCESSFULLY: {
            return {
                ...state,
                events: action.data.entities,
                eventsIsError: false,
                eventsInProgress: false,
            };
        }
        case FETCH_MONITORING_IN_PROGRESS: {
            return {
                ...state,
                eventsIsError: false,
                eventsInProgress: true,
            };
        }
        case FETCH_MONITORING_WITH_ERRORS: {
            return {
                ...state,
                eventsIsError: true,
                eventsInProgress: false,
            };
        }
        case WS_MONITORING_EVENT_UPDATE: {
            const updatedEventIndex = state.events.findIndex(
                (e) => e.message_id === action.data.message_id,
            );
            if (updatedEventIndex !== -1) {
                const newEvents = state.events;
                newEvents[updatedEventIndex] = action.data;
                return {
                    ...state,
                    events: [...newEvents],
                };
            }
            return {
                ...state,
                events: [...state.events, action.data],
            };
        }
        case FETCH_MONITORING_BY_SERVICE_SUCCESSFULLY: {
            return {
                ...state,
                serviceEvents: action.data.entities,
                serviceEventsIsError: false,
                serviceEventsInProgress: false,
            };
        }
        case FETCH_MONITORING_BY_SERVICE_IN_PROGRESS: {
            return {
                ...state,
                serviceEventsIsError: false,
                serviceEventsInProgress: true,
            };
        }
        case FETCH_MONITORING_BY_SERVICE_WITH_ERRORS: {
            return {
                ...state,
                serviceEventsIsError: true,
                serviceEventsInProgress: false,
            };
        }
        case INIT_MONITORING_EVENT: {
            return {
                ...state,
                events: [],
            };
        }
        case INIT_MONITORING_SERVICE_EVENT: {
            return {
                ...state,
                serviceEvents: [],
            };
        }
        default:
            return state;
        }
    };
};
