import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, Image, StyleSheet } from 'react-native';

import {
    ssdIcon,
} from '../../../icons';
import { getPercent } from '../../../utils/general';
import { thresholdColors } from '../../../constants/general';
import { getThresholdColorClass } from '../../../utils/general';
import ResourceFills from '../../General/ResourceFills/ResourceFills';

function formatValue(value) {
    const valueInGb = value / 1024;
    return Math.round(valueInGb * 10) / 10;
}

function StatisticsMemory(props) {
    const {
        limits,
        container,
    } = props;
    const [fillPercents, setChangeFillPercents] = React.useState(0);
    const percent = getPercent(container.quota, limits.storage.real);
    return <View style={styles.wrapper}>
        <Image source={ssdIcon} style={styles.icon}/>
        <View style={styles.textBlock}>
            <Text style={styles.title}>Диск</Text>
            <Text style={styles.subTitle}>
                {formatValue(container.quota)} ГБ
            </Text>
        </View>
        <View style={{ flex: 2, maxWidth: 135, flexDirection: 'row' }}>
            <View style={styles.resourceFills}>
                <ResourceFills isLoaded={true}
                    scales={[{
                        formatValue: formatValue.bind(this),
                        percent: percent,
                        min: 5,
                        scaleModifiers: [{
                            height: 15
                        }]
                    }]}
                    bottom={(scales) => {
                        let [fillPercents, current, max] = [0, 0, 0];
                        scales.forEach((scale) => {
                            fillPercents += scale.percent;
                            setChangeFillPercents(fillPercents);
                            current += scale.current;
                            if (scale.max > max) {
                                max = scale.max;
                            }
                        });
                    }}
                />
            </View>
            <Text style={{
                    color: thresholdColors[getThresholdColorClass(fillPercents)],
                    fontWeight: '500',
                    fontSize: 12,
                    lineHeight: 16,
                    textAlign: 'right',
                    flex: 1,
                }}>
                ~{fillPercents}%
            </Text>
        </View>
        
    </View>;
}

StatisticsMemory.propTypes = {
    container: PropTypes.shape({}),
    limits: PropTypes.shape({})
};

const mapStateToProps = state => ({
    container: state.hosting.container,
    limits: state.hosting.container.limits.limits
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 16,
        paddingRight: 19,
        paddingBottom: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 1,
        backgroundColor: '#ffffff'
    },
    resourceFills: {
        width: 96,
        marginRight: 7,
    },
    textBlock: {
        flex: 1
    },
    title: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14,
        fontWeight: '600'
    },
    subTitle: {
        color: '#455562',
        fontSize: 10,
        lineHeight: 14
    },
    icon: {
        height: 27,
        width: 24,
        marginRight: 14
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsMemory);
