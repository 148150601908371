import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import { flushErrors } from '../../../actions/forms/actions';
import { deleteEmail } from '../../../actions/account/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';
import Errors from '../../General/Form/Errors';

function AccountEmailsDeleteModal(props) {
    const {
        email,
        errors,
        formId,
        onClose,
        emailType,
        inProgress,
        flushErrors,
        deleteEmail,
    } = props;
    React.useEffect(() => {
        flushErrors();
    }, []);
    const currentErrors = errors[formId] || {};
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Подтвердите удаление<br/>email: {email}</Text>
        {currentErrors.__all__ && <Errors errors={currentErrors.__all__}/>}
        <View style={styles.buttons}>
            <AcceptButton
                onSubmit={() => deleteEmail(formId, { emailType, email },
                    () => onClose())}
                title='Удалить'
                type='danger'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

AccountEmailsDeleteModal.propTypes = {
    onClose: PropTypes.func,
    errors: PropTypes.shape({}),
    containerChangeSSHPassword: PropTypes.func,
    flushErrors: PropTypes.func,
    containerId: PropTypes.number,
    containerName: PropTypes.string,
    inProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    inProgress: state.account.deleteEmailInProgress,
    errors: state.forms.errors,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    deleteEmail,
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 44,
        paddingBottom: 42,
        paddingLeft: 40,
        paddingRight: 40,
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 10,
        color: '#455562',
        fontFamily: 'Roboto',
    },
    buttons: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        width: 136,
        height: 46,
        fontWeight: '400',
        fontSize: 14,
        textAlign: 'center',
        color: '#455562',
        paddingTop: 8,
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
    },
    acceptButton: {
        width: 136,
        height: 46,
        fontWeight: '400',
        fontSize: 14,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#0f79d5',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
        borderRightWidth: 1,
        borderRightColor: '#cccccc',
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
        fontSize: 13,
    },
    redText: {
        color: '#D51010',
        fontFamily: 'Roboto',
    },
    grayText: {
        color: '#455562',
        fontFamily: 'Roboto',
    },
    text: {
        color: '#888888',
        fontSize: 14,
        lineHeight: 20,
        marginBottom: 10,
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountEmailsDeleteModal);