import * as dayjs from 'dayjs';
import { flushErrors, formErrors } from '../forms/actions';

import {
    PARTNERSHIP_DATA_IN_PROGRESS,
    PARTNERSHIP_DATA_WITH_ERRORS,
    PARTNERSHIP_DATA_SUCCESSFULLY,
    PARTNERSHIP_REFERRAL_SITES_IN_PROGRESS,
    PARTNERSHIP_REFERRAL_SITES_WITH_ERRORS,
    PARTNERSHIP_REFERRAL_SITES_SUCCESSFULLY,
    CREATE_REFERRAL_SITE_SUCCESSFULLY,
    DELETE_REFERRAL_SITE_SUCCESSFULLY,
    PARTNERSHIP_CREATE_SUCCESSFULLY,
    PARTNERSHIP_CREATE_IN_PROGRESS,
    PARTNERSHIP_CREATE_WITH_ERRORS,
    REPORT_MONTHS_IN_PROGRESS,
    REPORT_MONTHS_SUCCESSFULLY,
    REPORT_MONTHS_SITES_WITH_ERRORS,
    PARTNERSHIP_UPDATE_SUCCESSFULLY,
    PARTNERSHIP_UPDATE_IN_PROGRESS,
    PARTNERSHIP_UPDATE_WITH_ERRORS,
    WITHDRAWAL_SUCCESSFULLY,
    WITHDRAWAL_IN_PROGRESS,
    WITHDRAWAL_WITH_ERRORS,
    PARTNERSHIP_CLIENTS_IN_PROGRESS,
    PARTNERSHIP_CLIENTS_SUCCESSFULLY,
    PARTNERSHIP_REGISTER_USER_WITH_ERRORS,
    PARTNERSHIP_CLIENTS_WITH_ERRORS,
    PARTNERSHIP_CHARGES_MONTH_GROUP_IN_PROGRESS,
    PARTNERSHIP_CHARGES_MONTH_GROUP_SUCCESSFULLY,
    PARTNERSHIP_CHARGES_MONTH_GROUP_WITH_ERRORS,
    PARTNERSHIP_CHARGES_MONTH_CHANGE_DATE,
    MONTHS_FOR_ACCRUALS_IN_PROGRESS,
    MONTHS_FOR_ACCRUALS_SUCCESSFULLY,
    MONTHS_FOR_ACCRUALS_WITH_ERRORS,
    PARTNERSHIP_REGISTER_USER_IN_PROGRESS,
    PARTNERSHIP_REGISTER_USER_SUCCESSFULLY,
    PARTNERSHIP_STATISTIC_SUCCESSFULLY,
    PARTNERSHIP_STATISTIC_WITH_ERRORS,
    PARTNERSHIP_STATISTIC_IN_PROGRESS,
    PARTNERSHIP_VERIFY_REFERRAL_SITE_IN_PROGRESS,
    PARTNERSHIP_VERIFY_REFERRAL_SITE_SUCCESSFULLY,
    PARTNERSHIP_CHALLENGES_IN_PROGRESS,
    PARTNERSHIP_CHALLENGES_SUCCESSFULLY,
    PARTNERSHIP_CHALLENGES_WITH_ERRORS,
    PARTNERSHIP_UPDATE_BALANCE,
    PARTNERSHIP_UPDATE_UNTREATED_WITHDRAWAL,
    PARTNERSHIP_ADD_CLIENT,
} from './actionTypes';

import { isFunction } from '../../utils/general';
import PartnershipResource from '../../gateway/resources/partnership';
import PartnershipReferralSitesResource from '../../gateway/resources/partnership/referralSites';

export const partnershipData = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: PARTNERSHIP_DATA_IN_PROGRESS });
    const resource = new PartnershipResource(gateway);
    const response = await resource.data();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: PARTNERSHIP_DATA_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: PARTNERSHIP_DATA_WITH_ERRORS });
    }
};

export const chargesMonthGroups = (date) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: PARTNERSHIP_CHARGES_MONTH_GROUP_IN_PROGRESS });
    const resource = new PartnershipResource(gateway);
    const response = await resource.chargesMonthGroups(date);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: PARTNERSHIP_CHARGES_MONTH_GROUP_SUCCESSFULLY,
            data
        });
        dispatch({
            type: PARTNERSHIP_CHARGES_MONTH_CHANGE_DATE,
            data: {
                date
            }
        });
    } else {
        dispatch({ type: PARTNERSHIP_CHARGES_MONTH_GROUP_WITH_ERRORS });
    }
};

export const monthsForAccruals = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: MONTHS_FOR_ACCRUALS_IN_PROGRESS });
    const resource = new PartnershipResource(gateway);
    const response = await resource.monthsForAccruals();
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: MONTHS_FOR_ACCRUALS_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: MONTHS_FOR_ACCRUALS_WITH_ERRORS
        });
    }
};

export const partnershipClients = (limit, offset, refresh) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: PARTNERSHIP_CLIENTS_IN_PROGRESS });
    const resource = new PartnershipResource(gateway);
    const response = await resource.clientDetail(limit, offset);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: PARTNERSHIP_CLIENTS_SUCCESSFULLY,
            data: {
                ...data,
                refresh
            }
        });
    } else {
        dispatch({ type: PARTNERSHIP_CLIENTS_WITH_ERRORS });
    }
};

export const partnershipRegisterUser = (formId, registerData, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: PARTNERSHIP_REGISTER_USER_IN_PROGRESS });
    const resource = new PartnershipResource(gateway);
    const response = await resource.register(registerData);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: PARTNERSHIP_REGISTER_USER_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: PARTNERSHIP_REGISTER_USER_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const referralSitesList = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: PARTNERSHIP_REFERRAL_SITES_IN_PROGRESS });
    const resource = new PartnershipReferralSitesResource(gateway);
    const response = await resource.list();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: PARTNERSHIP_REFERRAL_SITES_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: PARTNERSHIP_REFERRAL_SITES_WITH_ERRORS });
    }
};

export const referralSiteCreate = (
    formId, { site }, callback
) => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    const resource = new PartnershipReferralSitesResource(gateway);
    const response = await resource.create(site);
    const data = await response.json();
    if (response.ok) {
        callback(data);
        dispatch({
            type: CREATE_REFERRAL_SITE_SUCCESSFULLY,
            data
        });
    } else {
        dispatch(formErrors(formId, data.errors));
    }
};

export const referralSiteDelete = (
    formId, siteId, callback
) => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    const resource = new PartnershipReferralSitesResource(gateway);
    const response = await resource.delete(siteId);
    const data = await response.json();
    if (response.ok) {
        callback(data);
        dispatch({
            type: DELETE_REFERRAL_SITE_SUCCESSFULLY,
            data
        });
    } else {
        dispatch(formErrors(formId, data.errors));
    }
};

export const fetchStatistics = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: PARTNERSHIP_STATISTIC_IN_PROGRESS });
    const resource = new PartnershipResource(gateway);
    const response = await resource.statistics();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: PARTNERSHIP_STATISTIC_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: PARTNERSHIP_STATISTIC_WITH_ERRORS });
    }
};

export const createPartnership = (formId, isAccept) => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    dispatch({ type: PARTNERSHIP_CREATE_IN_PROGRESS });
    const resource = new PartnershipResource(gateway);
    const response = await resource.createPartnership(isAccept);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: PARTNERSHIP_CREATE_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: PARTNERSHIP_CREATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const getMonthsForPartnerReport = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: REPORT_MONTHS_IN_PROGRESS });
    const resource = new PartnershipResource(gateway);
    const response = await resource.reportMonths();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: REPORT_MONTHS_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: REPORT_MONTHS_SITES_WITH_ERRORS });
    }
};

export const partnershipUpdate = (
    formId, { rinv, bik }, callback
) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: PARTNERSHIP_UPDATE_IN_PROGRESS });
    const resource = new PartnershipResource(gateway);
    const response = await resource.partnershipUpdate(rinv, bik);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: PARTNERSHIP_UPDATE_SUCCESSFULLY,
            data
        });
        callback();
    } else {
        dispatch({ type: PARTNERSHIP_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const referralSiteVerify = (
    formId, referralSiteId, callback
) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: PARTNERSHIP_VERIFY_REFERRAL_SITE_IN_PROGRESS });
    const resource = new PartnershipReferralSitesResource(gateway);
    const response = await resource.verify(referralSiteId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: PARTNERSHIP_VERIFY_REFERRAL_SITE_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch(formErrors(formId, data.errors));
    }
};

export const referralSiteChallenges = (
    referralSiteId, callback
) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: PARTNERSHIP_CHALLENGES_IN_PROGRESS });
    const resource = new PartnershipReferralSitesResource(gateway);
    const response = await resource.challenges(referralSiteId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: PARTNERSHIP_CHALLENGES_SUCCESSFULLY,
            data: {
                id: referralSiteId,
                ...data,
            }
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({
            type: PARTNERSHIP_CHALLENGES_WITH_ERRORS
        });
    }
};

export const withdrawals = (
    formId, { amount, type }, callback
) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: WITHDRAWAL_IN_PROGRESS });
    const resource = new PartnershipResource(gateway);
    const response = await resource.withdrawals(amount, type);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: WITHDRAWAL_SUCCESSFULLY,
            data
        });
        callback();
    } else {
        dispatch({ type: WITHDRAWAL_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const wsParthershipUpdateBalance = (balance) => async (dispatch) => {
    dispatch({
        type: PARTNERSHIP_UPDATE_BALANCE,
        data: {
            balance
        },
    });
};

export const wsParthershipWithdrawalUntreatedUpdate = (has_untreated_withdrawal) => async (dispatch) => {
    dispatch({
        type: PARTNERSHIP_UPDATE_UNTREATED_WITHDRAWAL,
        data: {
            has_untreated_withdrawal
        },
    });
};

export const wsEntriesFetch = (entry) => async (dispatch, getState) => {
    const state = getState();
    const { partnership: { accrualsCurrentDate } } = state;
    const { date } = entry;
    const accrualsMoment = dayjs(accrualsCurrentDate, 'YYYY-MM-DD').format('YYYY-MM');
    const entryDateMoment = dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM');
    if (accrualsMoment === entryDateMoment) {
        dispatch(chargesMonthGroups(accrualsCurrentDate));
    }
};

export const wsRegisterClient = (client) => async (dispatch, getState, { gateway }) => {
    const resource = new PartnershipResource(gateway);
    const response = await resource.getClient(client.id);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: PARTNERSHIP_ADD_CLIENT,
            data
        });
    }
};
