import React from 'react';
import PropTypes from 'prop-types';
import {
    View,
    StyleSheet,
    Text,
} from 'react-native';

import Spring from '../../General/Spring/Spring';

function RestoreEmptyStep(props) {
    const { description, button } = props;
    return (
        <View style={styles.wrapper}>
            <Text style={styles.title}>
                резервных копий не найдено
            </Text>
            <Text style={styles.description}>
                {description}
            </Text>
            <Spring />
            {button}
        </View>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
        paddingLeft: 20,
    },
    title: {
        color: '#586A7B',
        fontSize: 12,
        fontWeight: '500',
        letterSpacing: 1.2,
        marginBottom: 10,
        textTransform: 'uppercase',
    },
    description: {
        color: '#455562',
        fontSize: 14,
        opacity: 0.7,
    }
});

RestoreEmptyStep.propTypes = {
    description: PropTypes.string,
    button: PropTypes.element,
};

export default RestoreEmptyStep;
