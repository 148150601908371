import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    View, StyleSheet, Text, Image
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import { userIcon } from '../../../icons';
import { mainWrapperStyles } from '../../../utils/styles';

function AccountUser(props) {
    const {
        name,
        login,
    } = props;

    return <LinearGradient
        colors={['#3F90D7', '#127DDB']}
        start={[0.5, 0]}
        end={[0.5, 1]}
        location={[0, 1]}
        style={styles.wrapper}
    >
        <Image source={userIcon} style={styles.headerIcon} />
        <View style={styles.content}>
            <Text style={styles.contentLogin}>{login}</Text>
            <Text style={styles.contentName}>{name}</Text>
        </View>
    </LinearGradient>;
}

AccountUser.propTypes = {
    login: PropTypes.string,
    name: PropTypes.string,
};

const mapStateToProps = (state) => ({
    login: state.user.user.login,
    name: state.user.user.name
});
const mapDispatchToProps = () => ({});

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 14,
        paddingBottom: 12,
        alignItems: 'center',
        ...mainWrapperStyles,
    },
    headerIcon: {
        width: 18,
        height: 20,
    },
    content: {
        textAlign: 'center',
        marginTop: 9,
    },
    contentLogin: {
        color: '#ffffff',
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 24,
    },
    contentName: {
        fontSize: 10,
        lineHeight: 24,
        color: '#ffffff',
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountUser);
