import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { isActiveVM } from './utils';
import Button from '../General/Button/Button';
import WarningCancelPacketBlock from './WarningCancelPacketBlock';
import cvdsTariffData from '../../../data/cvds/tariffs.json';
import { changeTariff, changeTariffCalculation } from '../../actions/cvds/actions';
import { blockedButtonTooltips } from '../../constants/general';

import TariffsList from './TariffsList';

const formId = 'ChangeVMTariffForm';

function VMTariffsPage(props) {
    const {
        vm,
        errors,
        inProgress,
        changeTariff,
        awaitingPacket,
        tariffCalculation,
        changeTariffCalculation
    } = props;

    const navigation = useNavigation();
    const currentTariff = cvdsTariffData.find(tariff => tariff.id === vm.tariff);
    const [newTariff, setNewTariff] = React.useState(currentTariff);
    const newPrice = tariffCalculation != null ? tariffCalculation.cost.month : 0;
    return <View>
        {awaitingPacket && <View style={styles.packetWarning}>
            <WarningCancelPacketBlock mainText='У вас имеется незавершенный заказ
                на годовой пакет для виртуальной машины. Изменение тарифа приведет к'
            warningText='отмене заказа годового пакета'/>
        </View>}
        <TariffsList vm={vm} onChange={(tariff) => {
            setNewTariff(tariff);
            changeTariffCalculation(vm.id, tariff.id);
        }}/>
        <Text style={styles.text}>
            Стоимость новой конфигурации VDS «{vm.name}» составит
            <Text style={styles.priceText}>{newPrice} ₽/мес</Text>
        </Text>
        <Button title={`Подключить тариф ${newTariff.name}`}
            onPress={() => changeTariff(
                formId,
                { vmId: vm.id, tariffId: newTariff.id },
                () => navigation.goBack()
            )}
            inProgress={inProgress}
            disabled={newTariff.id === currentTariff.id || !isActiveVM(vm.state)}
            disabledText={newTariff.id === currentTariff.id ? ''
                : blockedButtonTooltips.cvds[vm.state]}
            additionalWrapperStyles={[styles.buttonWrapper]}
            additionalButtonTextStyles={[styles.buttonText]}
            additionalButtonStyles={[styles.button]}/>
        {errors.__all__ && <Errors errors={errors.__all__}/>}
    </View>;
};

VMTariffsPage.propTypes = {
    vm: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    changeTariff: PropTypes.func,
    awaitingPacket: PropTypes.bool,
    inProgress: PropTypes.bool,
    tariffCalculation: PropTypes.shape({}),
    changeTariffCalculation: PropTypes.func

};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    inProgress: state.cvds.changeTariffInProgress,
    errors: state.forms.errors[formId] || {},
    tariffCalculation: state.cvdsFinanceInfo.tariffCalculation,
    awaitingPacket: (state.cvdsFinanceInfo.calculation == null
        || state.cvdsFinanceInfo.calculation.packet == null) ? false
        : state.cvdsFinanceInfo.calculation.packet.is_awaiting
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    changeTariffCalculation, changeTariff
}, dispatch);

const styles = StyleSheet.create({
    text: {
        marginTop: 25,
        marginLeft: 24,
        marginRight: 23,
        marginBottom: 24,
        color: '#455562',
        fontSize: 14,
        lineHeight: 18
    },
    priceText: {
        fontWeight: '600',
        marginLeft: 4
    },
    buttonWrapper: {
        marginLeft: 12,
        marginRight: 12
    },
    button: {
        paddingTop: 11,
        paddingBottom: 13
    },
    buttonText: {
        fontSize: 14,
        lineHeight: 16,
        fontWeight: '500',
        minHeight: 16,
    },
    packetWarning: {
        paddingLeft: 15,
        paddingRight: 10,
        paddingTop: 20,
        paddingBottom: 20,
        marginTop: 25,
        backgroundColor: '#ffffff',
        borderTopWidth: 1,
        borderTopColor: 'rgba(69, 85, 98, 0.15)',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(69, 85, 98, 0.15)'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(VMTariffsPage);
