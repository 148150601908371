import {
    IPS_RESERVED_FREE_FETCHED_SUCCESSFULLY,
    IPS_RESERVED_FREE_FETCHED_IN_PROGRESS,
    IPS_RESERVED_FREE_FETCHED_WITH_ERRORS,
} from './actionTypes';
import { isFunction } from '../../utils/general';
import IPsResource from '../../gateway/resources/ips';

export const ipsReservedFreeList = callback => async (dispatch, getState, { gateway }) => {
    dispatch({ type: IPS_RESERVED_FREE_FETCHED_IN_PROGRESS });
    const resource = new IPsResource(gateway);
    const response = await resource.ipsReservedFreeList();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: IPS_RESERVED_FREE_FETCHED_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({
            type: IPS_RESERVED_FREE_FETCHED_WITH_ERRORS
        });
    }
};
