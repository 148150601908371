import React, { useContext } from 'react';
import { WebsocketContext } from '../contexts/WebsocketContext';

function useWebsockets() {
    const { ws } = useContext(WebsocketContext);

    return ws;
}

export default useWebsockets;
