import {
    ENABLED,
    DISABLED_BY_USER,
    DISABLED_BY_SERVICE,
    DISABLED_BY_ADMIN,
    DISABLED_BY_QUOTA,
} from '../../constants/general';

export const STATUS_OK = 'OK';
export const STATUS_FAIL = 'FAIL';

export const defaultMailboxesSortingOrder = 'name';
export const mailboxesPageEntitiesLimit = 20;

export const mailboxStatesMap = {
    [ENABLED]: 'active',
    [DISABLED_BY_USER]: 'stopped',
    [DISABLED_BY_SERVICE]: 'stoppedbyservice',
    [DISABLED_BY_ADMIN]: 'stoppedbyadmin',
    [DISABLED_BY_QUOTA]: 'expired',
};

export const defaultColors = [
    '#636efa',
    '#EF553B',
    '#00cc96',
    '#ab63fa',
    '#FFA15A',
    '#19d3f3',
    '#FF6692',
    '#B6E880',
    '#FF97FF',
    '#FECB52',
];
