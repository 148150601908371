import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, StyleSheet } from 'react-native';

import { updateCertificate } from '../../../actions/certificates/actions';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'ChangeRenewalModalForm';

function ChangeRenewalModal(props) {
    const {
        certificate,
        onClose,
    } = props;
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);
    const { updateCertificateInProgress } = useSelector((state) => state.certificates);

    return <View style={styles.wrapper}>
        <Text style={styles.title}>
            {!certificate.is_renewal ? 'Включение' : 'Отключение'} автопродления
        </Text>
        <Text style={styles.text}>
            Автоматическое продление сертификата {certificate.name} будет {!certificate.is_renewal ? 'включено' : 'отключено'}
        </Text>
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => dispatch(
                    updateCertificate(
                        formId,
                        certificate.id,
                        { is_renewal: !certificate.is_renewal },
                        () => onClose(),
                    ),
                )}
                title={!certificate.is_renewal ? 'Включить' : 'Отключить'}
                type={!certificate.is_renewal ? 'blue' : 'danger'}
                inProgress={updateCertificateInProgress}/>
            <CancelButton onClose={onClose} disabled={updateCertificateInProgress} />
        </View>
    </View>;
}

ChangeRenewalModal.propTypes = {
    onClose: PropTypes.func,
    certificate: PropTypes.shape({
        is_renewal: PropTypes.bool,
        id: PropTypes.number,
        name: PropTypes.string,
    }),
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 18,
        lineHeight: 20,
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    text: {
        color: '#455562',
        fontWeight: '400',
        fontSize: 12,
        lineHeight: 16,
    }
});

export default ChangeRenewalModal;
