import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    Image,
    TouchableOpacity,
    ScrollView
} from 'react-native';

import Modal from '../General/Modal/Modal';
import {
    rightArrowLightIcon,
    premiumIcon,
    prolongIcon,
    criticalIcon,
} from '../../icons';
import {
    certificateDetail,
    fetchMoreCertificates,
} from '../../actions/certificates/actions';
import OrderCertificateModal from './Modals/OrderCertificateModal';
import Circle from '../General/Circle/Circle';
import SslResourceLoader from './SslResourceLoader';
import { pluralize, formatDate } from '../../utils/general';
import publishersMap from '../../../data/certificates/publishers.json';
import Spring from '../General/Spring/Spring';

function CertificateResource({ certificate, onPress }) {
    const {
        id,
        name,
        state,
        domains,
        publisher,
        not_after,
        is_renewal,
    } = certificate;
    const isError = state === 'Error';
    const notAfterTime = `истекает ${formatDate(not_after, 'DD.MM.YYYY')}`;
    const needShowDates = state !== 'Processed';
    const needShowPublisher = publisher !== 'Unknown';
    const hasRenewal = needShowPublisher && publisher !== 'LeaderSSL';

    return <TouchableOpacity style={styles.wrapper}
        onPress={onPress}>
        <View style={styles.certificate}>
            <View style={styles.header}>
                <Text style={[styles.headerTitle, { color: isError ? '#d55a5a' : '#455562' }]}>{name}</Text>
                {isError && <Image source={criticalIcon} style={styles.criticalIcon} />}
                <View style={styles.headerCount}>
                    <Text style={styles.headerCountText}>
                        {domains.length} {pluralize(domains.length, [
                            'домен',
                            'домена',
                            'доменов',
                        ])}
                    </Text>
                </View>
            </View>
            <View style={styles.info}>
                <View style={styles.publisherBlock}>
                    <Text style={styles.publisherText}>
                        {publishersMap[publisher]}
                    </Text>
                    {(publisher === 'AlphaSSL' || publisher === 'LeaderSSL')
                        && <Image source={premiumIcon} style={styles.premiumIcon} />}
                </View>
                <Text style={styles.afterTimeText}>{needShowDates
                    ? notAfterTime : 'Выпускается'}</Text>
                {hasRenewal
                    && is_renewal && <Image source={prolongIcon} style={styles.prolongIcon} />}
            </View>
        </View>
        <Image source={rightArrowLightIcon} style={styles.icon} />
    </TouchableOpacity>;
}

CertificateResource.propTypes = {
    certificate: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        domains: PropTypes.arrayOf(PropTypes.shape({})),
        publisher: PropTypes.string,
        not_after: PropTypes.string,
        state: PropTypes.string,
        is_renewal: PropTypes.bool,
    }),
    onPress: PropTypes.func,
};

function isCloseToBottom({ layoutMeasurement, contentOffset, contentSize }) {
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - 136;
}

function SslList() {
    const limit = 10;
    const formId = 'FetchDomains';
    const dispatch = useDispatch();
    const {
        certificates,
        moreCertificatesInProgress,
    } = useSelector((state) => state.certificates);
    const navigation = useNavigation();

    const [isCreateModalShowed, setCreateModalShowed] = React.useState(false);
    const [isStartingPagination, setIsStartingPagination] = React.useState(false);

    return <ScrollView
        style={{ flex: 1 }}
        scrollEventThrottle={0}
        onScroll={(e) => {
            if (
                isCloseToBottom(e.nativeEvent)
                && isStartingPagination
                && !moreCertificatesInProgress
                && certificates.count !== certificates.entities.length
            ) {
                dispatch(
                    fetchMoreCertificates(formId, limit, certificates.entities.length)
                );
            }
        }}>
        <View style={styles.headerBlock}>
            <Text style={styles.headerText}>
                мои сертификаты
            </Text>
            <Circle count={certificates.count} />
            <Spring />
            <TouchableOpacity style={styles.button} onPress={() => setCreateModalShowed(true)}>
                <Text style={styles.blueText}>Добавить</Text>
            </TouchableOpacity>
        </View>
        {certificates && certificates.entities.map((certificate, i) => <CertificateResource
            key={`certificate-${i}`}
            certificate={certificate}
            onPress={() => {
                dispatch(certificateDetail(certificate.id));
                navigation.navigate('SSLDetail', {
                    certificateId: certificate.id
                });
            }}
        />)}
        {moreCertificatesInProgress && <SslResourceLoader />}
        {!isStartingPagination
            && certificates && certificates.count !== certificates.entities.length
            && <View style={styles.whiteButtonBlock}>
                <TouchableOpacity style={styles.whiteButton}
                    onPress={() => {
                        setIsStartingPagination(true);
                        dispatch(
                            fetchMoreCertificates(formId, limit, certificates.entities.length)
                        );
                    }}
                >
                    <Text style={styles.whiteButtonText}>Показать еще {
                        certificates.count - certificates.entities.length > 10
                            ? 10
                            : certificates.count - certificates.entities.length
                    }</Text>
                </TouchableOpacity>
            </View>
        }
        <Modal isModalShowed={isCreateModalShowed}
            component={<OrderCertificateModal
                onClose={() => {
                    setCreateModalShowed(false);
                }}/>}
            onClose={() => setCreateModalShowed(false)} />
    </ScrollView>;
}

const styles = StyleSheet.create({
    wrapper: {
        margin: 0,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        width: '100%',
        color: '#455562',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        backgroundColor: '#FFFFFF',
        borderBottomColor: '#F6F6F6',
        borderBottomWidth: 2,
    },
    headerBlock: {
        paddingBottom: 16,
        paddingLeft: 20,
        paddingRight: 21,
        flex: 1,
        maxHeight: 56,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginRight: 8,
        color: '#455562',
    },
    blueText: {
        fontSize: 12,
        fontWeight: '500',
        color: '#0f79d5',
    },
    certificate: {
        flex: 1
    },
    status: {
        color: '#e17334',
        fontSize: 10,
        fontWeight: 400,
        marginRight: 20,
        marginLeft: 20,
    },
    icon: {
        width: 7,
        height: 13,
    },
    premiumIcon: {
        width: 12,
        height: 12,
        marginLeft: 3,
    },
    prolongIcon: {
        marginLeft: 11,
        width: 16,
        height: 14,
    },
    criticalIcon: {
        marginLeft: 4,
        width: 12,
        height: 12,
    },
    header: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        marginBottom: 6,
    },
    headerTitle: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 15,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    headerCount: {
        borderRadius: 25,
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 8,
        paddingRight: 8,
        marginLeft: 12,
        backgroundColor: '#ebebeb',
    },
    headerCountText: {
        color: '#455562',
        fontWeight: '400',
        fontSize: 10,
        lineHeight: 12,
    },
    info: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
    },
    publisherBlock: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    publisherText: {
        color: '#455562',
        fontWeight: '400',
        fontSize: 10,
        lineHeight: 12,
    },
    afterTimeText: {
        color: '#455562',
        fontWeight: '400',
        fontSize: 10,
        lineHeight: 12,
        opacity: 0.7,
        marginLeft: 15,
    },
    whiteButtonBlock: {
        paddingTop: 15,
        paddingRight: 20,
        paddingLeft: 20,
        marginBottom: 20,
    },
    whiteButton: {
        borderRadius: 4,
        borderWidth: 1,
        borderColor: 'rgba(69, 85, 98, 0.50)',
        backgroundColor: '#ffffff',
        borderStyle: 'solid',
        paddingTop: 12,
        paddingBottom: 13,
        width: '100%',
    },
    whiteButtonText: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '400',
        textAlign: 'center',
    },
});

export default SslList;
