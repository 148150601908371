import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, StyleSheet } from 'react-native';

import { switchMailboxLocal } from '../../../actions/mail/actions';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'DeleteAliasModalForm';

function MailboxLocalWarningModal(props) {
    const {
        mailboxId,
        mailboxName,
        onClose,
    } = props;
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);
    const { changeMailboxInProgress } = useSelector((state) => state.mail);

    return <View style={styles.wrapper}>
        <Text style={styles.title}>Отключение хранения писем в ящике {mailboxName}</Text>
        <Text style={styles.warningText}>
            <Text style={styles.redWarningText}>Ящик будет очищен</Text>
            и перестанет собирать почту, пересылка на другие адреса будет по-прежнему работать.
            В ящик нельзя будет авторизоваться.
        </Text>
        <View>
            <View style={styles.buttonBlock}>
                <AcceptButton
                    onSubmit={() => dispatch(
                        switchMailboxLocal(
                            formId,
                            mailboxId,
                            false,
                            () => onClose(),
                        ),
                    )}
                    type='danger'
                    title='Отключить'
                    inProgress={changeMailboxInProgress}/>
                <CancelButton onClose={onClose} disabled={changeMailboxInProgress} />
            </View>
        </View>
    </View>;
}

MailboxLocalWarningModal.propTypes = {
    onClose: PropTypes.func,
    mailboxId: PropTypes.number,
    mailboxName: PropTypes.string,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
    },
    warningText: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 16,
        color: '#455562',
        marginBottom: 14,
    },
    redWarningText: {
        color: '#f93b25',
        fontWeight: 500,
        marginRight: 3,
    },
});

export default MailboxLocalWarningModal;
