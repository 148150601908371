import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';

import { calculateIPPrice, getNormalizedIPOptions } from '../utils';
import { IPv4, IPv6 } from '../../../constants/cvds';
import { flushErrors } from '../../../actions/forms/actions';

function AddIPModalSwitcher(props) {
    const {
        ips,
        version,
        setVersion,
        createType,
        flushErrors,
        setCurrentIp,
        setСreateType
    } = props;

    let ipv4Modifiers = [{}];
    let ipv6Modifiers = [{}];
    let ipv4TextModifiers = [{}];
    let ipv6TextModifiers = [{}];
    if (version === IPv4) {
        ipv6Modifiers = [{}];
        ipv4Modifiers = [styles.activeSwitcherButton, {
            borderRightWidth: 1
        }];
        ipv4TextModifiers = [styles.blueText];
        ipv6TextModifiers = [{
            color: '#455562',
            opacity: 0.7
        }];
    } else if (version === IPv6) {
        ipv4Modifiers = [{}];
        ipv6Modifiers = [styles.activeSwitcherButton, {
            borderLeftWidth: 1
        }];
        ipv6TextModifiers = [styles.blueText];
        ipv4TextModifiers = [{
            color: '#455562',
            opacity: 0.7
        }];
    }
    return <View style={styles.switcherBlock}>
        <TouchableOpacity style={[ styles.switcherButton, styles.ipv4Button, ipv4Modifiers ]}
            onPress={() => {
                const normalizedIp = getNormalizedIPOptions(ips, IPv4)[0] || '';
                const newCreateType = normalizedIp ? createType : 'new';
                setVersion(IPv4);
                setCurrentIp(normalizedIp);
                setСreateType(newCreateType);
                flushErrors();
            }}>
            <Text style={[ styles.switcherTitle, ipv4TextModifiers ]}>IPv4</Text>
            <Text style={[ styles.switcherSubtitle, ipv4TextModifiers ]}>
                {calculateIPPrice(1, IPv4).toFixed(0)} ₽/мес
            </Text>
        </TouchableOpacity>
        <TouchableOpacity style={[ styles.switcherButton, styles.ipv6Button, ipv6Modifiers ]}
            onPress={() => {
                const normalizedIp = getNormalizedIPOptions(ips, IPv6)[0] || '';
                const newCreateType = normalizedIp ? createType : 'new';
                setVersion(IPv6);
                setCurrentIp(normalizedIp);
                setСreateType(newCreateType);
                flushErrors();
            }}>
            <Text style={[ styles.switcherTitle, ipv6TextModifiers ]}>IPv6</Text>
            <Text style={[ styles.switcherSubtitle, ipv6TextModifiers ]}>бесплатно</Text>
        </TouchableOpacity>
    </View>;
};

AddIPModalSwitcher.propTypes = {
    ips: PropTypes.shape({
        entities: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    flushErrors: PropTypes.func,
    version: PropTypes.number,
    setVersion: PropTypes.func,
    createType: PropTypes.string,
    setCurrentIp: PropTypes.func,
    setСreateType: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({ flushErrors }, dispatch);

const styles = StyleSheet.create({
    switcherBlock: {
        flex: 1,
        flexDirection: 'row'
    },
    switcherButton: {
        flex: 1,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16
    },
    activeSwitcherButton: {
        borderBottomColor: '#0f79d5',
        borderTopColor: '#0f79d5',
        borderLeftColor: '#0f79d5',
        borderRightColor: '#0f79d5',
        backgroundColor: 'rgba(15, 121, 213, 0.07)'
    },
    ipv4Button: {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(69, 85, 98, 0.3)',
        borderTopWidth: 1,
        borderTopColor: 'rgba(69, 85, 98, 0.3)',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(69, 85, 98, 0.3)',
    },
    ipv6Button: {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(69, 85, 98, 0.3)',
        borderTopWidth: 1,
        borderTopColor: 'rgba(69, 85, 98, 0.3)',
        borderRightWidth: 1,
        borderRightColor: 'rgba(69, 85, 98, 0.3)',
    },
    switcherTitle: {
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 14,
        marginBottom: 1
    },
    switcherSubtitle: {
        fontSize: 10,
        fontWeight: '500',
        lineHeight: 12
    },
    blueText: {
        color: '#0f79d5',
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddIPModalSwitcher);
