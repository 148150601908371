import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';

import { getNormalizedIPOptions } from '../utils';
import SelectModal from '../../General/SelectModal/SelectModal';

function AddIPModalRadioButtonsBlock(props) {
    const {
        ips,
        version,
        currentIp,
        setCurrentIp,
        createType,
        setСreateType
    } = props;

    const normalizedIPOptions = getNormalizedIPOptions(ips, version);
    const initialIndex = currentIp
        ? normalizedIPOptions.findIndex((ip) => ip.value === currentIp.value)
        : 0;
    return <View style={styles.createTypesBlock}>
        <TouchableOpacity style={[styles.typeBlock, { minHeight: 69 }]} onPress={() => setСreateType('reserved')}>
            <View style={createType === 'reserved' ? styles.activeOuterCircle
                : styles.outerCircle}>
                {createType === 'reserved' && <View style={styles.innerCircle}/>}
            </View>
            <View style={styles.pickerBlock}>
                <Text style={styles.typeBlockText}>зарезервированный IP-адрес</Text>
                <SelectModal
                    initialSelectedIndex={initialIndex}
                    additionalWrapperStyle={[styles.selectWrapper]}
                    additionalSelectLabelStyle={[styles.selectLabel]}
                    onChange={(value) => {
                        setCurrentIp(value);
                        setСreateType('reserved');
                    }}
                    currentItem={currentIp || normalizedIPOptions[0]}
                    itemList={normalizedIPOptions}/>
            </View>
        </TouchableOpacity>
        <TouchableOpacity style={styles.typeBlock} onPress={() => setСreateType('new')}>
            <View style={createType === 'new' ? styles.activeOuterCircle
                : styles.outerCircle}>
                {createType === 'new' && <View style={styles.innerCircle}/>}
            </View>
            <View>
                <Text style={styles.typeBlockText}>новый IP-адрес</Text>
            </View>
        </TouchableOpacity>
    </View>;
}

AddIPModalRadioButtonsBlock.propTypes = {
    ips: PropTypes.shape({
        entities: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    currentIp: PropTypes.shape({
        value: PropTypes.string,
    }),
    version: PropTypes.number,
    createType: PropTypes.string,
    setCurrentIp: PropTypes.func,
    setСreateType: PropTypes.func
};

const styles = StyleSheet.create({
    createTypesBlock: {
        marginTop: 17
    },
    typeBlock: {
        flex: 1,
        flexDirection: 'row'
    },
    typeBlockText: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        marginLeft: 9,
        marginBottom: 3,
    },
    pickerBlock: {
        flex: 1
    },
    outerCircle: {
        height: 18,
        width: 18,
        borderTopWidth: 1,
        borderTopColor: 'rgba(161, 170, 177, 0.5)',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(161, 170, 177, 0.5)',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(161, 170, 177, 0.5)',
        borderRightWidth: 1,
        borderRightColor: 'rgba(161, 170, 177, 0.5)',
        borderRadius: 9,
        backgroundColor: '#f5f5f5'
    },
    activeOuterCircle: {
        height: 18,
        width: 18,
        borderTopWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderLeftWidth: 1,
        borderLeftColor: '#0f79d5',
        borderRightWidth: 1,
        borderRightColor: '#0f79d5',
        borderRadius: 9,
        backgroundColor: '#ffffff',
        paddingTop: 4,
        paddingLeft: 4
    },
    innerCircle: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0f79d5'
    },
    selectLabel: {
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 19
    },
    selectWrapper: {
        marginLeft: 9,
        marginTop: 3,
    },
});

export default AddIPModalRadioButtonsBlock;
