import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ScrollView, View, Text, StyleSheet, Image } from 'react-native';

import {
    chargesMonthGroups,
    chargesChangeMonth,
    getMonthsForCharges
} from '../../../../actions/finances/actions';
import FinanceFilter from '../FinanceFilter/FinanceFilter';
import { hashCode, getColorType } from '../../../../utils/general';
import FinanceStatisticList from '../FinanceStatistic/FinanceStatisticList';
import { statisticServicesMap, statisticServicesMapColor } from '../../constants';
import HorizontalBarChart from '../../../General/HorizontalBarChart/HorizontalBarChart';
import { emptySectionFinance } from '../../../../icons';
import MonthPickerSelector from '../../../General/MonthPicker/MonthPickerSelector';

function getResourceHashCode(resource) {
    const resourceHashName = `${resource.service}:${resource.resource_name || 'other'}`;
    return Math.abs(hashCode(resourceHashName));
}

function statisticsSummarizeResources(statisticsCharges, statisticFilters, setStatisticFilters) {
    const { summarize } = statisticsCharges;
    if (statisticFilters.length > 0) {
        return statisticsCharges.groups
            .filter(resource => statisticFilters.find(s => s.service === resource.service))
            .map(resource => ({
                id: resource.service,
                colorType: getColorType(getResourceHashCode(resource)),
                title: resource.resource_name || 'Другое',
                value: resource.total,
                formatValue: v => `${v.toFixed(2)} ₽`
            }));
    }
    return summarize.map(service => ({
        id: service.service,
        title: statisticServicesMap[service.service] || service.service,
        colorType: statisticServicesMapColor[service.service] || 'gray',
        value: service.total,
        onClick: () => {
            if (!statisticFilters.find(s => s.service !== service.service)) {
                setStatisticFilters(statisticFilters.concat(service));
            }
        },
        formatValue: v => `${v.toFixed(2)} ₽`
    }));
}

function getStatisticsCharges(statisticsCharges, statisticFilters) {
    if (statisticFilters.length > 0) {
        return {
            ...statisticsCharges,
            groups: statisticsCharges.groups.map(resource => {
                return {
                    ...resource,
                    colorType: getColorType(getResourceHashCode(resource))
                }
            })
        };
    }
    return statisticsCharges;
}

function hasSummarize(statisticsCharges) {
    return Boolean(statisticsCharges && statisticsCharges.summarize
        && statisticsCharges.summarize.length > 0);
}

function hasCharges(statisticsCharges) {
    return Boolean(statisticsCharges && statisticsCharges.summarize
        && statisticsCharges.groups.length > 0);
}

function FinanceDetailTab(props) {
    const {
        chargesMonths,
        statisticsCharges,
        chargesChangeMonth,
        getMonthsForCharges,
        chargesMonthGroups,
        statisticsChargesDate
    } = props;
    const [statisticFilters, setStatisticFilters ] = React.useState([]);
    React.useEffect(() => {
        getMonthsForCharges();
        if (statisticsCharges == null) {
            chargesMonthGroups(statisticsChargesDate);
        }
    }, []);

    React.useEffect(() => {
        chargesMonthGroups(statisticsChargesDate);
    }, [statisticsChargesDate]);

    const currentDate = dayjs(statisticsChargesDate);
    return <ScrollView>
        <Text style={styles.titleText}>Услуги и расходы</Text>
        <View style={styles.costsInner}>
            <MonthPickerSelector
                dates={chargesMonths}
                onNextButtonClick={(date, nextDate) => {
                    chargesChangeMonth(nextDate.format('YYYY-MM-DD'));
                }}
                onPrevButtonClick={(date, prevDate) => {
                    chargesChangeMonth(prevDate.format('YYYY-MM-DD'));
                }}
                onChange={(value) => {
                    const date = value.format('YYYY-MM-DD');
                    chargesChangeMonth(date);
                }}
                currentDate={currentDate} />
            {statisticFilters.length !== 0 && <View style={styles.filtersInner}>
                <View style={styles.filters}>
                    {statisticFilters.map((item, i) => <FinanceFilter
                        key={`finance-filter-cost-${i}`}
                        title={statisticServicesMap[item.service] || item.service}
                        onClose={() => {
                            setStatisticFilters(statisticFilters.filter(r => r.service !== item.service));
                        }}
                    />)}
                </View>
            </View>}
            {hasSummarize(statisticsCharges)
                && <View style={styles.barChartInner}>
                <HorizontalBarChart
                    resources={statisticsSummarizeResources(statisticsCharges,
                        statisticFilters, setStatisticFilters)}
                    formatTotal={v => `Всего: ${v.toFixed(2)} ₽`}/>
            </View>}
            {hasCharges(statisticsCharges) && <FinanceStatisticList
                filters={statisticFilters}
                statisticsCharges={getStatisticsCharges(statisticsCharges, statisticFilters)}
            />}
            {!hasCharges(statisticsCharges) && <View style={styles.emptyWrapper}>
                <Image source={emptySectionFinance} style={styles.image}/>
                <Text style={styles.emptyText}>
                    В выбранном месяце не производилось списаний
                </Text>
            </View>}
        </View>
    </ScrollView>;
}

FinanceDetailTab.propTypes = {
    statisticsCharges: PropTypes.shape({
        groups: PropTypes.arrayOf(PropTypes.shape({
            service: PropTypes.string,
            resource_name: PropTypes.string,
            total: PropTypes.number
        })),
        summarize: PropTypes.arrayOf(PropTypes.shape({
            service: PropTypes.string,
            total: PropTypes.number
        }))
    }),
    chargesMonthGroups: PropTypes.func,
    chargesChangeMonth: PropTypes.func,
    statisticsChargesDate: PropTypes.string,
    changeFinancesCostsViewType: PropTypes.func,
    chargesMonths: PropTypes.arrayOf(PropTypes.string),
    chargesMonthsInProgress: PropTypes.bool,
    getMonthsForCharges: PropTypes.func
};

const mapStateToProps = (state) => ({
    chargesMonths: state.finance.chargesMonths,
    awaitingPackets: state.finance.awaitingPackets,
    statisticsCharges: state.finance.statisticsCharges,
    statisticsChargesDate: state.finance.statisticsChargesDate
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    getMonthsForCharges,
    chargesMonthGroups,
    chargesChangeMonth
}, dispatch);

const styles = StyleSheet.create({
    titleText: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '600',
        textTransform: 'uppercase',
        lineHeight: 13,
        opacity: 0.75,
        letterSpacing: 1.38,
        marginTop: 27,
        marginBottom: 13,
        marginLeft: 20
    },
    costsInner: {
        backgroundColor: '#ffffff'
    },
    barChartInner: {
        marginTop: 20,
        marginLeft: 20,
        marginRight: 16,
        marginBottom: 14
    },
    filtersInner: {
        paddingTop: 14,
        paddingLeft: 20,
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    image: {
        height: 140,
        width: 260,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    emptyWrapper: {
        marginTop: 14,
        paddingTop: 20,
        paddingBottom: 35,
        paddingLeft: 20,
        paddingRight: 20
    },
    emptyText: {
        marginTop: 20,
        fontSize: 14,
        color: '#455562',
        textAlign: 'center'
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(FinanceDetailTab);
