import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import {
    downloadActAgent,
    getMonthsForPartnerReport
} from '../../../actions/finances/actions';
import Errors from '../../General/Form/Errors';
import SelectModal from '../../General/SelectModal/SelectModal';
import AcceptButton from '../../General/Modal/AcceptButton';
import { flushErrors } from '../../../actions/forms/actions';

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    acceptButton: {
        width: '100%',
        borderRightWidth: 0,
    },
    errorBlock: {
        marginTop: 10
    }
})

const formId = 'PartnerReportModalForm';

function normalizeOptions(options) {
    if (options && options.length > 0) {
        return options.map((option, i) => {
            const label = dayjs(option, 'YYYY-MM-DD').format('MMMM YYYY');
            return {
                value: option,
                index: i,
                label: label[0].toUpperCase() + label.slice(1)
            };
        });
    }
    return [];
}

function PartnerReportModal(props) {
    const {
        errors,
        onClose,
        inProgress,
        flushErrors,
        downloadActAgent,
        monthsForPartnerReport,
        getMonthsForPartnerReport,
        monthsForPartnerReportInProgress
    } = props;

    React.useEffect(() => {
        flushErrors();
        if (!monthsForPartnerReportInProgress && monthsForPartnerReport.length === 0) {
            getMonthsForPartnerReport();
        }
    }, []);
    const normalizedOptions = normalizeOptions(monthsForPartnerReport);
    const [date, setDate] = React.useState(null);
    React.useEffect(() => {
        if (normalizedOptions.length !== 0 && date == null) {
            setDate(normalizedOptions[0]);
        }
    }, [normalizedOptions]);
    const initialIndex = date
        ? normalizedOptions.findIndex((d) => d.value === date.value)
        : 0;
    return <View style={[styles.wrapper]}>
        <Text style={[styles.title]}>Партнерский отчет</Text>
        <SelectModal
            initialSelectedIndex={initialIndex}
            onChange={(value) => setDate(value)}
            currentItem={date || normalizedOptions[0]}
            itemList={normalizedOptions}/>
        <View style={[styles.errorBlock]}>
            <Errors errors={errors.__all__} />
        </View>
        <View style={styles.buttonBlock}>
            <AcceptButton
                additionalButtonStyles={[styles.acceptButton]}
                onSubmit={() => downloadActAgent(formId, dayjs(date.value).format('YYYY-MM-DD'), onClose)}
                title='Сформировать отчет'
                inProgress={inProgress}/>
        </View>
    </View>;
}

PartnerReportModal.propTypes = {
    onClose: PropTypes.func,
    partnerV2: PropTypes.bool,
    inProgress: PropTypes.bool,
    errors: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    downloadActAgent: PropTypes.func,
    getMonthsForPartnerReport: PropTypes.func,
    monthsForPartnerReportInProgress: PropTypes.bool,
    monthsForPartnerReport: PropTypes.arrayOf(PropTypes.string)
};

const mapStateToProps = (state) => ({
    errors: state.forms.errors[formId] || {},
    inProgress: state.finance.downloadActInProgress,
    partnerV2: state.user.financeInfo.partner_v2,
    monthsForPartnerReport: state.finance.monthsForPartnerReport,
    monthsForPartnerReportInProgress: state.finance.monthsForPartnerReportInProgress
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    downloadActAgent, flushErrors, getMonthsForPartnerReport
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PartnerReportModal);