import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, Text, StyleSheet } from 'react-native';
import { bindActionCreators } from 'redux';

import Input from '../../General/Form/Input';
import Errors from '../../General/Form/Errors';
import { vmRename } from '../../../actions/cvds/actions';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'RenameModalForm';

function RenameModal(props) {
    const {
        vm,
        errors,
        onClose,
        vmRename,
        inProgress,
        flushErrors
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);
    const [vmName, setVmName] = React.useState(vm.name);
    return <View style={styles.wrapper}>
        <Text style={[styles.title]}>Переименование ВМ {vm.name} ({vm.uid})</Text>
        <Input errors={errors.name}
            label='Новое имя'
            textInputAttrs={{
                autoFocus: true,
                numberOfLines: 1,
                value: vmName,
                onChange: (e) => setVmName(e.target.value)
            }}
        />
        {errors.__all__ && <Errors errors={errors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                additionalButtonStyles={[{ width: 160 }]}
                onSubmit={() => vmRename(formId, { vmId: vm.id, vmName }, onClose)}
                title='Переименовать'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

RenameModal.propTypes = {
    vm: PropTypes.shape({}),
    onClose: PropTypes.func,
    vmRename: PropTypes.func,
    inProgress: PropTypes.bool,
    errors: PropTypes.shape({})
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    inProgress: state.cvds.vmUpdateInProgress,
    errors: state.forms.errors[formId] || {}
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    vmRename, flushErrors
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        marginTop: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RenameModal);
