import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, StyleSheet } from 'react-native';

import VolumeFill from './VolumeFill';
import Modal from '../General/Modal/Modal';
import Errors from '../General/Form/Errors';
import Switch from '../General/Switch/Switch';
import Button from '../General/Button/Button';
import BackupModal from './Modals/BackupModal';
import SliderDynamic from '../General/Slider/SliderDynamic';
import { flushErrors } from '../../actions/forms/actions';
import cvdsTariffData from '../../../data/cvds/tariffs.json';
import ResizeVolumeWarning from './Modals/ResizeVolumeWarning';
import WarningCancelPacketBlock from './WarningCancelPacketBlock';
import WarningCancelPacketModal from './Modals/WarningCancelPacketModal';
import {
    vmDiskResize,
    vmVolumeResize,
    vmDiskChangeBackup,
    vmVolumeChangeBackup
} from '../../actions/cvds/actions';
import {
    isActiveVM,
    getStorageScale,
    calculateBackupPrice,
    calculateStoragePrice,
    calculateTotalPercent
} from './utils';
import { blockedButtonTooltips } from '../../constants/general';

const formId = 'VolumeChangeSizeForm';
const backupFormId = 'VolumeChangeBackupForm';

function getPrice(volumeType, storage, backup, tariffId) {
    const price = calculateStoragePrice(volumeType.toLowerCase(),
        storage, backup, tariffId, true);
    return `${price.toFixed(2)} ₽/мес`;
}

function VolumeEditPage(props) {
    const {
        vm,
        errors,
        volume,
        inProgress,
        flushErrors,
        backupErrors,
        vmDiskResize,
        awaitingPacket,
        vmVolumeResize,
        backupInProgress,
        vmDiskChangeBackup,
        vmVolumeChangeBackup
    } = props;

    React.useEffect(() => {
        flushErrors();
        return () => {};
    }, []);

    const currentTariff = cvdsTariffData.find(tariff => tariff.id === vm.tariff);
    const storageScale = getStorageScale(currentTariff);
    const [storage, setStorage] = React.useState(volume.size);
    const [isBackupModalShowed, setBackupModalVisible] = React.useState(false);
    const [isResizeWarningShowed, setResizeWarningModalVisible] = React.useState(false);
    const [isWarningPacketModalShowed, setWarningPacketModalVisible] = React.useState(false);
    return <View style={styles.wrapper}>
        <View style={styles.topBlock}>
            <View style={styles.volumeFill}>
                <VolumeFill
                    id={volume.uid}
                    size={volume.size}
                    isLoaded={vm.volumesUsedSize != null}
                    isLoading={vm.volumesUsedSizeIsLoading}
                    isError={vm.volumesUsedSizeIsError}
                    data={vm.volumesUsedSize && vm.volumesUsedSize[volume.uid]}
                    percentThreshold={11}
                    name={(data) => {
                        const { fs_used_pct, fs_reserved_pct } = data;
                        if (fs_used_pct == null || fs_reserved_pct == null) {
                            return null;
                        }
                        return `Занято ${calculateTotalPercent(data).toFixed(0)}%`;
                    }}
                />
            </View>
            <View style={styles.slider}>
                <SliderDynamic
                    title='Размер диска'
                    items={storageScale}
                    value={storage}
                    unit='ГБ'
                    onChange={(storage) => {
                        setStorage(storage);
                    }}/>
            </View>
            <View style={styles.backupBlockWrapper}>
                <View style={styles.backupBlock}>
                    <View style={styles.textBlock}>
                        <Text style={styles.backupTitle}>Резервное копирование</Text>
                        <Text style={styles.backupText}>
                            Стоимость за {storage} ГБ — {calculateBackupPrice(storage, true)
                                .toFixed(2)} ₽/мес
                        </Text>
                    </View>
                    <View>
                        <Switch isEnable={volume.is_backup_enabled}
                            disabled={!isActiveVM(vm.state)}
                            disabledText={blockedButtonTooltips.cvds[vm.state]}
                            onChange={() => {
                                const data = {
                                    vmId: vm.id,
                                    isBackupEnabled: volume.is_backup_enabled
                                };
                                if (volume.is_backup_enabled) {
                                    setBackupModalVisible(true);
                                } else if (awaitingPacket) {
                                    setWarningPacketModalVisible(true);
                                } else if (volume.uid === vm.disk.uid) {
                                    vmDiskChangeBackup(backupFormId, data);
                                } else {
                                    vmVolumeChangeBackup(backupFormId,
                                        { ...data, volumeId: volume.id });
                                }
                            }}/>
                    </View>
                </View>
                {backupErrors.__all__ && <Errors errors={backupErrors.__all__}/>}
            </View>
            <View style={styles.priceBlock}>
                <View style={styles.textBlock}>
                    <Text style={styles.priceTitle}>Стоимость диска</Text>
                    <Text style={styles.priceSubtitle}>
                        {volume.is_backup_enabled ? 'с резервным копированием'
                            : 'без резервного копирования'}
                    </Text>
                </View>
                <Text style={styles.priceTitle}>
                    {getPrice(volume.type || currentTariff.disk.type, storage,
                        volume.is_backup_enabled, currentTariff.id)}
                </Text>
            </View>
            {(awaitingPacket && volume.size !== storage) && <View style={styles.packetWarning}>
                <WarningCancelPacketBlock
                    mainText={`У вас заказан годовой пакет для виртуальной машины с диском ${
                        volume.size} ГБ. Изменение размера диска приведет к`}
                    warningText='отмене заказа годового пакета' />
            </View>}
        </View>
        <Modal isModalShowed={isBackupModalShowed}
            component={<BackupModal
                awaitingPacket={awaitingPacket}
                onAccept={() => {
                    const data = {
                        vmId: vm.id,
                        isBackupEnabled: volume.is_backup_enabled
                    };
                    if (volume.uid === vm.disk.uid) {
                        vmDiskChangeBackup(backupFormId, data,
                            () => setBackupModalVisible(false));
                    } else {
                        vmVolumeChangeBackup(backupFormId,
                            { ...data, volumeId: volume.id },
                            () => setBackupModalVisible(false));
                    }
                }}
                onClose={() => setBackupModalVisible(false)}
            />}
            onClose={() => setBackupModalVisible(false)} />
        <Modal isModalShowed={isResizeWarningShowed}
            component={<ResizeVolumeWarning
                onAccept={() => {
                    if (volume.uid === vm.disk.uid) {
                        vmDiskResize(formId,
                            { vmId: vm.id, size: storage },
                            () => setResizeWarningModalVisible(false));
                    } else {
                        vmVolumeResize(formId,
                            { vmId: vm.id, size: storage, volumeId: volume.id },
                            () => setResizeWarningModalVisible(false));
                    }
                }}
                onClose={() => setResizeWarningModalVisible(false)}/>}
            onClose={() => setResizeWarningModalVisible(false)} />
        <Modal isModalShowed={isWarningPacketModalShowed}
            component={<WarningCancelPacketModal
                warningText='отмене заказа годового пакета'
                mainText='У вас заказан годовой пакет для ВМ без резервного копирования.
                    Включение опции приведет к'
                inProgress={backupInProgress}
                onAccept={() => {
                    const data = {
                        vmId: vm.id,
                        isBackupEnabled: volume.is_backup_enabled
                    };
                    if (volume.uid === vm.disk.uid) {
                        vmDiskChangeBackup(backupFormId, data,
                            () => setWarningPacketModalVisible(false));
                    } else {
                        vmVolumeChangeBackup(backupFormId,
                            { ...data, volumeId: volume.id },
                            () => setWarningPacketModalVisible(false));
                    }
                }}
                onClose={() => setWarningPacketModalVisible(false)}/>}
            onClose={() => setWarningPacketModalVisible(false)} />
        {volume.size !== storage && <View style={styles.bottomBlock}>
            {errors.__all__ && <Errors errors={errors.__all__}/>}
            <Button onPress={() => {
                if (volume.size > storage) {
                    setResizeWarningModalVisible(true);
                } else {
                    if (volume.uid === vm.disk.uid) {
                        vmDiskResize(formId,
                            { vmId: vm.id, size: storage });
                    }
                    vmVolumeResize(formId,
                        { vmId: vm.id, size: storage, volumeId: volume.id });
                }
            }} title='Сохранить изменения'
            inProgress={inProgress}
            disabled={!isActiveVM(vm.state)}
            disabledText={blockedButtonTooltips.cvds[vm.state]}
            additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 13 }]}
            additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, minHeight: 16 }]}
            />
        </View>}
    </View>;
};

VolumeEditPage.propTypes = {
    vm: PropTypes.shape({}),
    inProgress: PropTypes.bool,
    volume: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    vmDiskResize: PropTypes.func,
    vmVolumeResize: PropTypes.func,
    awaitingPacket: PropTypes.bool,
    backupErrors: PropTypes.shape({}),
    vmDiskChangeBackup: PropTypes.func,
    vmVolumeChangeBackup: PropTypes.func,
    backupInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    volume: state.cvds.currentVolume,
    errors: state.forms.errors[formId] || {},
    backupInProgress: state.cvds.backupSwitchInProgress,
    inProgress: state.cvds.vmUpdateInProgress,
    backupErrors: state.forms.errors[backupFormId] || {},
    awaitingPacket: state.cvdsFinanceInfo.calculation.packet == null ? false
        : state.cvdsFinanceInfo.calculation.packet.is_awaiting
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    vmDiskChangeBackup,
    vmVolumeChangeBackup,
    vmDiskResize,
    vmVolumeResize
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: '#ffffff',
        paddingTop: 23,
        paddingLeft: 23,
        paddingRight: 24,
        paddingBottom: 32,
        flex: 1,
        justifyContent: 'space-between'
    },
    volumeFill: {
        marginBottom: 25
    },
    slider: {
        marginBottom: 16
    },
    backupBlock: {
        flex: 1,
        flexDirection: 'row',
        paddingTop: 16,
        borderTopWidth: 1,
        borderTopColor: 'rgba(69, 85, 98, 0.3)',
    },
    backupBlockWrapper: {
        paddingBottom: 17,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(69, 85, 98, 0.3)',
        borderStyle: 'dashed',
    },
    textBlock: {
        flex: 1
    },
    backupText: {
        color: '#455562',
        fontSize: 11,
        lineHeight: 17
    },
    backupTitle: {
        marginBottom: 3,
        color: '#455562',
        fontSize: 13,
        fontWeight: '600',
        lineHeight: 12
    },
    priceBlock: {
        flex: 1,
        flexDirection: 'row',
        paddingTop: 18,
    },
    priceSubtitle: {
        color: '#455562',
        fontSize: 11,
        lineHeight: 14
    },
    priceTitle: {
        marginBottom: 3,
        color: '#455562',
        fontSize: 13,
        fontWeight: '600',
        lineHeight: 12
    },
    buttonBlock: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        width: 160,
        height: 46,
        fontWeight: '400',
        fontSize: 14,
        textAlign: 'center',
        color: '#455562',
        paddingTop: 8,
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
    },
    acceptButton: {
        width: 160,
        height: 46,
        fontWeight: '400',
        fontSize: 14,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#0f79d5',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
        borderRightWidth: 1,
        borderRightColor: '#cccccc',
    },
    blueText: {
        color: '#0f79d5',
    },
    packetWarning: {
        marginTop: 10
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(VolumeEditPage);
