import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, ScrollView } from 'react-native';

import TimelineDay from './TimelineDay';

function TimelineHorizontalChart(props) {
    const {
        days,
        current,
        onChange
    } = props;
    return <ScrollView horizontal={true} automaticallyAdjustContentInsets={false}
        showsHorizontalScrollIndicator={false}
        style={styles.wrapper}>
        <View style={styles.innerBlock}>
            {days.map((item, i) => <TimelineDay
                day={item.day}
                dayColorModifier={item.dayColorModifier}
                current={current}
                onChange={onChange}
                key={`timeline-horizontal-day-${i}`} />)}
        </View>
    </ScrollView>;
}

TimelineHorizontalChart.propTypes = {
    days: PropTypes.arrayOf(PropTypes.shape({
        day: PropTypes.object,
        dayColorModifier: PropTypes.arrayOf(PropTypes.string),
        modifiers: PropTypes.arrayOf(PropTypes.string)
    })).isRequired,
    current: PropTypes.object.isRequired,
    onChange: PropTypes.func
};

const styles = StyleSheet.create({
    wrapper: {
        height: 41
    },
    innerBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    }
});

export default TimelineHorizontalChart;
