export const PARTNERSHIP_DATA_IN_PROGRESS = 'PARTNERSHIP_DATA_IN_PROGRESS';
export const PARTNERSHIP_DATA_WITH_ERRORS = 'PARTNERSHIP_DATA_WITH_ERRORS';
export const PARTNERSHIP_DATA_SUCCESSFULLY = 'PARTNERSHIP_DATA_SUCCESSFULLY';

export const PARTNERSHIP_REFERRAL_SITES_IN_PROGRESS = 'PARTNERSHIP_REFERRAL_SITES_IN_PROGRESS';
export const PARTNERSHIP_REFERRAL_SITES_WITH_ERRORS = 'PARTNERSHIP_REFERRAL_SITES_WITH_ERRORS';
export const PARTNERSHIP_REFERRAL_SITES_SUCCESSFULLY = 'PARTNERSHIP_REFERRAL_SITES_SUCCESSFULLY';

export const CREATE_REFERRAL_SITE_SUCCESSFULLY = 'CREATE_REFERRAL_SITE_SUCCESSFULLY';
export const DELETE_REFERRAL_SITE_SUCCESSFULLY = 'DELETE_REFERRAL_SITE_SUCCESSFULLY';

export const PARTNERSHIP_CREATE_SUCCESSFULLY = 'PARTNERSHIP_CREATE_SUCCESSFULLY';
export const PARTNERSHIP_CREATE_IN_PROGRESS = 'PARTNERSHIP_CREATE_IN_PROGRESS';
export const PARTNERSHIP_CREATE_WITH_ERRORS = 'PARTNERSHIP_CREATE_WITH_ERRORS';

export const REPORT_MONTHS_IN_PROGRESS = 'REPORT_MONTHS_IN_PROGRESS';
export const REPORT_MONTHS_SUCCESSFULLY = 'REPORT_MONTHS_SUCCESSFULLY';
export const REPORT_MONTHS_SITES_WITH_ERRORS = 'REPORT_MONTHS_SITES_WITH_ERRORS';

export const PARTNERSHIP_UPDATE_SUCCESSFULLY = 'PARTNERSHIP_UPDATE_SUCCESSFULLY';
export const PARTNERSHIP_UPDATE_IN_PROGRESS = 'PARTNERSHIP_UPDATE_IN_PROGRESS';
export const PARTNERSHIP_UPDATE_WITH_ERRORS = 'PARTNERSHIP_UPDATE_WITH_ERRORS';

export const PARTNERSHIP_UPDATE_BALANCE = 'PARTNERSHIP_UPDATE_BALANCE';
export const PARTNERSHIP_UPDATE_UNTREATED_WITHDRAWAL = 'PARTNERSHIP_UPDATE_UNTREATED_WITHDRAWAL';

export const WITHDRAWAL_SUCCESSFULLY = 'WITHDRAWAL_SUCCESSFULLY';
export const WITHDRAWAL_IN_PROGRESS = 'WITHDRAWAL_IN_PROGRESS';
export const WITHDRAWAL_WITH_ERRORS = 'WITHDRAWAL_WITH_ERRORS';

export const PARTNERSHIP_CLIENTS_IN_PROGRESS = 'PARTNERSHIP_CLIENTS_IN_PROGRESS';
export const PARTNERSHIP_CLIENTS_SUCCESSFULLY = 'PARTNERSHIP_CLIENTS_SUCCESSFULLY';
export const PARTNERSHIP_CLIENTS_WITH_ERRORS = 'PARTNERSHIP_CLIENTS_WITH_ERRORS';
export const PARTNERSHIP_ADD_CLIENT = 'PARTNERSHIP_ADD_CLIENT';

export const PARTNERSHIP_CHARGES_MONTH_GROUP_IN_PROGRESS = 'PARTNERSHIP_CHARGES_MONTH_GROUP_IN_PROGRESS';
export const PARTNERSHIP_CHARGES_MONTH_GROUP_SUCCESSFULLY = 'PARTNERSHIP_CHARGES_MONTH_GROUP_SUCCESSFULLY';
export const PARTNERSHIP_CHARGES_MONTH_GROUP_WITH_ERRORS = 'PARTNERSHIP_CHARGES_MONTH_GROUP_WITH_ERRORS';
export const PARTNERSHIP_CHARGES_MONTH_CHANGE_DATE = 'PARTNERSHIP_CHARGES_MONTH_CHANGE_DATE';

export const MONTHS_FOR_ACCRUALS_IN_PROGRESS = 'MONTHS_FOR_ACCRUALS_IN_PROGRESS';
export const MONTHS_FOR_ACCRUALS_SUCCESSFULLY = 'MONTHS_FOR_ACCRUALS_SUCCESSFULLY';
export const MONTHS_FOR_ACCRUALS_WITH_ERRORS = 'MONTHS_FOR_ACCRUALS_WITH_ERRORS';

export const PARTNERSHIP_REGISTER_USER_IN_PROGRESS = 'PARTNERSHIP_REGISTER_USER_IN_PROGRESS';
export const PARTNERSHIP_REGISTER_USER_SUCCESSFULLY = 'PARTNERSHIP_REGISTER_USER_SUCCESSFULLY';
export const PARTNERSHIP_REGISTER_USER_WITH_ERRORS = 'PARTNERSHIP_REGISTER_USER_WITH_ERRORS';

export const PARTNERSHIP_STATISTIC_IN_PROGRESS = 'PARTNERSHIP_STATISTIC_IN_PROGRESS';
export const PARTNERSHIP_STATISTIC_SUCCESSFULLY = 'PARTNERSHIP_STATISTIC_SUCCESSFULLY';
export const PARTNERSHIP_STATISTIC_WITH_ERRORS = 'PARTNERSHIP_STATISTIC_WITH_ERRORS';

export const PARTNERSHIP_CHALLENGES_SUCCESSFULLY = 'PARTNERSHIP_CHALLENGES_SUCCESSFULLY';
export const PARTNERSHIP_CHALLENGES_WITH_ERRORS = 'PARTNERSHIP_CHALLENGES_WITH_ERRORS';
export const PARTNERSHIP_CHALLENGES_IN_PROGRESS = 'PARTNERSHIP_CHALLENGES_IN_PROGRESS';

export const PARTNERSHIP_VERIFY_REFERRAL_SITE_SUCCESSFULLY = 'PARTNERSHIP_VERIFY_REFERRAL_SITE_SUCCESSFULLY';
export const PARTNERSHIP_VERIFY_REFERRAL_SITE_IN_PROGRESS = 'PARTNERSHIP_VERIFY_REFERRAL_SITE_IN_PROGRESS';
