import React from 'react';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
} from 'react-native';

import { eventsData } from '../../constants/monitoring';
import { makeMonitoringEventComponent, makeMonitoringResourceLink, eventsMapByService } from '../../utils/monitoring';

function MonitoringItem(props) {
    const { monitoringEvent } = props;

    const navigation = useNavigation();
    const navigationParams = makeMonitoringResourceLink(
        monitoringEvent.service,
        monitoringEvent.metadata,
    );
    const eventData = eventsData.find((ev) => ev.service === monitoringEvent.service);
    return <TouchableOpacity
        style={styles.wrapper}
        onPress={() => navigation.navigate(navigationParams.link, navigationParams.params)}
    >
        <View style={styles.headerBlock}>
            <Text style={styles.resourceName}>
                {eventData.itemName} «{monitoringEvent.metadata.resource_name}»
            </Text>
            {monitoringEvent.metadata.resource_uid && <Text style={styles.resourceUid}>
                {monitoringEvent.metadata.resource_uid}
            </Text>}
        </View>
        <View style={[styles.viewWrapper,
            {
                backgroundColor: monitoringEvent.severity.toLowerCase() === 'warning'
                    ? 'rgba(225, 115, 52, 0.07)' : 'rgba(224, 116, 116, 0.07)',
            }
        ]}>
            {makeMonitoringEventComponent(
                eventsData,
                monitoringEvent.service,
                `${monitoringEvent.resource_type}:${monitoringEvent.severity_code}`,
                'view',
                monitoringEvent,
                true,
            )}
        </View>
        <View style={styles.resourceSolution}>
            {makeMonitoringEventComponent(
                eventsData,
                monitoringEvent.service,
                `${monitoringEvent.resource_type}:${monitoringEvent.severity_code}`,
                'solution',
                monitoringEvent,
                true,
            )}
        </View>
    </TouchableOpacity>;
}

MonitoringItem.propTypes = {
    monitoringEvent: PropTypes.shape({
        metadata: PropTypes.shape({
            usage: PropTypes.string,
        }),
        service: PropTypes.string,
        resource_type: PropTypes.string,
        severity_code: PropTypes.string,
    }),
};

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: '#ffffff',
        paddingTop: 12,
        paddingBottom: 15,
        paddingLeft: 12,
        paddingRight: 12,
        marginBottom: 12,
        borderRadius: 16,
        marginLeft: 18,
        marginRight: 18,
    },
    viewWrapper: {
        borderRadius: 8,
        paddingTop: 12,
        paddingBottom: 16,
        paddingLeft: 8,
        paddingRight: 8,
    },
    resourceName: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 24,
        fontWeight: '500',
        marginBottom: 3.5,
    },
    resourceUid: {
        color: '#455562',
        fontSize: 10,
        lineHeight: 24,
        fontWeight: '400',
        marginBottom: 3.5,
        opacity: 0.7,
    },
    headerBlock: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    resourceSolution: {
        marginTop: 8,
    }
});

export default MonitoringItem;
