/* eslint-disable global-require */
export const netangelsLogoIcon = require('./icons/netangels-logo.png');
export const generalBottomTabIcon = require('./icons/general-bottom-tab.png');
export const generalBottomTabActiveIcon = require('./icons/general-bottom-tab-active.png');
export const financeBottomTabIcon = require('./icons/finance-bottom-tab.png');
export const financeBottomTabActiveIcon = require('./icons/finance-bottom-tab-active.png');
export const accountBottomTabIcon = require('./icons/account-bottom-tab.png');
export const accountBottomTabActiveIcon = require('./icons/account-bottom-tab-active.png');
export const actionIcon = require('./icons/action.png');
export const actionReverseGrayIcon = require('./icons/action-reverse-gray.png');
export const bellIcon = require('./icons/bell.png');
export const bellBlueIcon = require('./icons/bell-blue.png');
export const gpuPreviewIcon = require('./icons/gpu-preview.png');
export const warningIcon = require('./icons/warning.png');
export const dangerIcon = require('./icons/danger.png');
export const logoutIcon = require('./icons/logout.png');
export const userIcon = require('./icons/user-menu-icon.png');
export const userBlueIcon = require('./icons/user-menu-blue-icon.png');
export const passwordIcon = require('./icons/password.png');
export const passwordBlueIcon = require('./icons/password-blue.png');
export const passwordGreyIcon = require('./icons/password-grey.png');
export const trashIcon = require('./icons/trash.png');
export const trashDisabledIcon = require('./icons/trash-disabled.png');
export const rightIcon = require('./icons/right.png');
export const plusIcon = require('./icons/plus.png');
export const minusIcon = require('./icons/minus.png');
export const checkedIcon = require('./icons/checked.png');
export const closedIcon = require('./icons/close.png');
export const cpuIcon = require('./icons/cpu.png');
export const ramIcon = require('./icons/ram.png');
export const rightArrowLightIcon = require('./icons/right-arrow-light.png');
export const rightArrowBoldIcon = require('./icons/right-arrow-bold.png');
export const arrowDownIcon = require('./icons/arrow-down.png');
export const returnIcon = require('./icons/return.png');
export const returnDisabledIcon = require('./icons/return-disabled.png');
export const checkedRadioButton = require('./icons/checked-radio-button.png');
export const editBlueIcon = require('./icons/edit-blue.png');
export const turnOffBlueIcon = require('./icons/turn-off.png');
export const actionReverseIcon = require('./icons/action-reverse.png');
export const annualIcon = require('./icons/annual.png');
export const openEyeIcon = require('./icons/open-eye.png');
export const closedEyeIcon = require('./icons/closed-eye.png');
export const copyBlueIcon = require('./icons/copy-blue.png');
export const longArroyUpGreenIcon = require('./icons/long-arroy-up.png');
export const longArroyDownRedIcon = require('./icons/long-arroy-down.png');
export const forwardIcon = require('./icons/forward.png');
export const searchIcon = require('./icons/search.png');
export const nothingFoundIcon = require('./icons/nothing-found.png');
export const menuBackIcon = require('./icons/menu-back.png');
export const criticalIcon = require('./icons/critical.png');

export const promisedPaymentIcon = require('./icons/promised-payment.png');
export const promisedPaymentDisabledIcon = require('./icons/promised-payment-disabled.png');
export const detailIcon = require('./icons/detail.png');
export const forecastIcon = require('./icons/forecast.png');
export const certificateIcon = require('./icons/cert.png');

export const cvdsWelcomePageIcon = require('./icons/vds-welcome.png');

export const cvdsPaymentPageIcon = require('./icons/cvds-payment-page.png');

export const launchHostingIcon = require('./icons/header-hosting.png');
export const siteEmptyHostingIcon = require('./icons/site-empty.png');

export const promisedPaymentImage = require('./icons/promised-payment-image.png');
export const promisedPaymentBannerImage = require('./icons/promised-payment-banner.png');

export const sberbankIcon = require('./payment-systems/sberbank.png');
export const applePayIcon = require('./payment-systems/apple-pay.png');
export const yamIcon = require('./payment-systems/yam.png');
export const webmoneyIcon = require('./payment-systems/webmoney.png');
export const bankcardIcon = require('./payment-systems/bankcard.png');
export const bankIcon = require('./payment-systems/bank.png');
export const sbpIcon = require('./icons/sbp.png');
export const intellectMoneyIcon = require('./icons/intellect-money.png');

export const vdsIcon = require('./services-icons/cvds.png');
export const cloudHostingIcon = require('./services-icons/hosting.png');
export const cloudMailIcon = require('./services-icons/mail.png');
export const dnsIcon = require('./services-icons/dns.png');
export const sslIcon = require('./services-icons/ssl.png');
export const domainsIcon = require('./services-icons/domains.png');

export const mailProIcon = require('./icons/mail-pro.png');
export const launchMailIcon = require('./icons/header-mail.png');
export const launchMailboxIcon = require('./icons/mailbox-empty.png');
export const errorMailIcon = require('./icons/mail-error.png');
export const likeIcon = require('./icons/like.png');
export const databaseIcon = require('./icons/database.png');
export const tweakIcon = require('./icons/tweak.png');

export const emptySectionFinance = require('./icons/empty-section.png');

export const vmActionsIcon = require('./icons/vm-actions.png');
export const reloadIcon = require('./icons/reload.png');
export const reloadDisabledIcon = require('./icons/reload-disabled.png');
export const transferIcon = require('./icons/transfer.png');
export const transferDisabledIcon = require('./icons/transfer-disabled.png');
export const turnOffIcon = require('./icons/turn-off.png');
export const turnOffDisabledIcon = require('./icons/turn-off-disabled.png');

export const distributionsIconsMap = {
    bigbluebutton: require('./distributions/bigbluebutton.png'),
    bitrix_env: require('./distributions/bitrix_env.png'),
    bitrix: require('./distributions/bitrix_env.png'),
    bitrixenv: require('./distributions/bitrix_env.png'),
    centos: require('./distributions/centos.png'),
    debian: require('./distributions/debian.png'),
    default_os: require('./icons/default-os.png'),
    docker: require('./distributions/docker.png'),
    gitlab: require('./distributions/gitlab.png'),
    netangels_bitrix: require('./distributions/netangels_bitrix.png'),
    netangels: require('./distributions/netangels.png'),
    'opensuse-leap': require('./distributions/opensuse-leap.png'),
    sangoma: require('./distributions/sangoma.png'),
    ubuntu: require('./distributions/ubuntu.png'),
    ispmanager: require('./distributions/ispmanager.png'),
    nvidia: require('./distributions/nvidia.png')
};
export const deletedVmIcon = require('./icons/deleted-vm.png');

export const cvdsPacketIcon = require('./icons/packet-icon.png');

export const supportIcon = require('./icons/support.png');

export const reservedIcon = require('./icons/reserved-ip.png');
export const ddosGuardBGIcon = require('./icons/ddos-guard-bg.png');

export const sasIcon = require('./icons/sas.png');
export const ssdIcon = require('./icons/ssd.png');
export const nvmeIcon = require('./icons/nvme.png');

export const tileFillGray = require('./icons/tile-fill-gray.png');

export const backupsIcon = require('./icons/backups.png');
export const backupsDisabledIcon = require('./icons/backups-disabled.png');

export const editIcon = require('./icons/edit.png');
export const editGreyIcon = require('./icons/edit-grey.png');

export const cursorDownIcon = require('./icons/cursor-down.png');

export const sslFreeIcon = require('./icons/ssl-free.png');

export const ftpEmptyIcon = require('./icons/ftp-empty.png');

export const mongodbIcon = require('./icons/dbms/mongodb.png');
export const mysqlIcon = require('./icons/dbms/mysql.png');
export const postgresIcon = require('./icons/dbms/postgres.png');

export const phpIcon = require('./icons/engine/php.png');
export const rubyIcon = require('./icons/engine/ruby.png');
export const execIcon = require('./icons/engine/exec.png');
export const nodeIcon = require('./icons/engine/node.png');
export const pythonIcon = require('./icons/engine/python.png');

export const rorIcon = require('./icons/cms/ror.png');
export const modxIcon = require('./icons/cms/modx.png');
export const drupalIcon = require('./icons/cms/drupal.png');
export const bitrixIcon = require('./icons/cms/bitrix.png');
export const joomlaIcon = require('./icons/cms/joomla.png');
export const djangoIcon = require('./icons/cms/django.png');
export const wordpressIcon = require('./icons/cms/wordpress.png');

export const addButtonIcon = require('./icons/plus-icon.png');
export const addButtonDisabledIcon = require('./icons/plus-icon-disabled.png');

export const partnersLaunchIcon = require('./icons/partners-launch.png');
export const partnershipBottomTabIcon = require('./icons/partnership.png');
export const partnershipClientIcon = require('./icons/partnership-client.png');
export const partnershipClientsEmptyIcon = require('./icons/clients-empty.png');
export const partnershipBottomTabActiveIcon = require('./icons/partnership-active.png');
export const partnershipBringClientIcon = require('./icons/partnership-bring-client.png');
export const partnershipReceiveMoneyIcon = require('./icons/partnership-receive-money.png');

export const downloadIcon = require('./icons/download.png');
export const mailIcon = require('./icons/mail.png');
export const walletIcon = require('./icons/wallet.png');

export const authFailIcon = require('./icons/fail-img.png');

export const launchSslIcon = require('./icons/header-ssl.png');
export const premiumIcon = require('./icons/premium.png');
export const prolongIcon = require('./icons/prolong.png');
export const prolongBlueIcon = require('./icons/prolong-blue.png');
