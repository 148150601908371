import React from 'react';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs'
import { StyleSheet, View, Text } from 'react-native';

import FinanceStatistic from './FinanceStatistic';
import Separator from '../../../General/Separator/Separator';

const styles = StyleSheet.create({
    financeDate: {
        color: '#455562',
        fontSize: 9,
        letterSpacing: 1.13,
        lineHeight: 10,
        marginBottom: 12,
        marginLeft: 20
    },
    groupDate: {
        marginBottom: 12,
        marginTop: 12
    }
})

function formatDate(date) {
    return dayjs(date).format('DD MMMM');
}

function getStatisticDateList(date, dateList) {
    return dateList.map((item, i) => <View key={`statistic-${i}`}>
        <Separator />
        <FinanceStatistic element={item}/>
    </View>);
}

function getStatistics(statisticsCharges, filters) {
        const filterStatistic = {};
        let { groups } = statisticsCharges;
        if (filters.length > 0) {
            groups = groups.filter(g => filters.find(f => f.service === g.service));
        }
        groups.forEach((element) => {
            if (filterStatistic[element.event_date] == null) {
                filterStatistic[element.event_date] = [];
            }
            filterStatistic[element.event_date].push(element);
        });
        const dateList = Object.keys(filterStatistic).sort((a, b) => {
            const normalizedA = dayjs(a, 'YYYY-MM-DD');
            const normalizedB = dayjs(b, 'YYYY-MM-DD');
            if (normalizedA < normalizedB) {
                return 1;
            }
            if (normalizedA > normalizedB) {
                return -1;
            }
            return 0;
        });

        return dateList.map((date, d) => <View key={`group-date-${d}`}
            style={d !== dateList.length -1 ? styles.groupDate : { marginTop: 12 }}>
            <Text style={styles.financeDate}>{formatDate(date).toUpperCase()}</Text>
            <View>
                {getStatisticDateList(date, filterStatistic[date])}
                <Separator />
            </View>
        </View>);
    }

function getStatisticsWithoutGroup(statisticsCharges, filters) {
    let { groups } = statisticsCharges;
    if (filters.length > 0) {
        groups = groups.filter(g => filters.find(f => f.service === g.service));
    }
    return getStatisticDateList('without-date', groups);
}

function FinanceStatisticList(props) {
    const {
        filters = [],
        statisticsCharges,
        withoutGroups = false
    } = props;

    return <React.Fragment>
        {statisticsCharges && <View>
            {withoutGroups && getStatisticsWithoutGroup(statisticsCharges, filters)}
            {!withoutGroups && getStatistics(statisticsCharges, filters)}
        </View>}
    </React.Fragment>;
}


FinanceStatisticList.propTypes = {
    statisticsCharges: PropTypes.shape({
        groups: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    withoutGroups: PropTypes.bool,
    filters: PropTypes.arrayOf(PropTypes.shape({}))
};

export default FinanceStatisticList;
