import {
    sberbankIcon,
    bankIcon,
    bankcardIcon,
    webmoneyIcon,
    yamIcon,
    applePayIcon,
    sbpIcon,
    intellectMoneyIcon
} from '../../icons';
import { upperFirst } from '../../utils/general';

export const couponServicesMap = {
    hosting: 'Виртуальный хостинг',
    cloud_hosting: 'Хостинг',
    cloud_vds: 'Облачные VDS',
    datastorage: 'Хранилище данных',
    domains: 'Регистрация доменов',
    sales365: 'Sales 365'
};

export const statisticServicesMap = {
    hosting: 'Виртуальный хостинг',
    cloud_hosting: 'Хостинг',
    cloud_vds: 'Облачные VDS',
    datastorage: 'Хранилище данных',
    domains: 'Домены',
    sales365: 'Sales 365',
    cloud_mail: 'Облачная почта',
    ssl: 'SSL',
    documents: 'Доставка документов',
    z66: 'Сайты z66.ru',
    unknown: 'Другое'
};

export const paymentsTypeMap = {
    WebMoney: {
        title: 'WebMoney',
        icon: webmoneyIcon
    },
    YandexMoney: {
        title: 'ЮMoney',
        icon: yamIcon
    },
    BankCard: {
        title: 'Банковской картой',
        icon: bankcardIcon
    },
    Bank: {
        title: 'Оплата по счету',
        icon: bankIcon
    },
    SberbankOnline: {
        title: 'Сбербанк.Онлайн',
        icon: sberbankIcon
    },
    ApplePay: {
        title: 'Apple Pay',
        icon: applePayIcon
    },
    IntellectMoney: {
        title: 'IntellectMoney',
        icon: intellectMoneyIcon
    },
    SBP: {
        title: 'Системой быстрых платежей',
        icon: sbpIcon
    },
    YooKassaCard: {
        title: 'ЮКасса',
        icon: yamIcon
    },
};

export const statisticServicesMapColor = {
    hosting: 'blue',
    cloud_hosting: 'turquoise',
    cloud_vds: 'red',
    vds: 'mustard',
    datastorage: 'blue',
    domains: 'purple',
    sales365: 'green',
    cloud_mail: 'yellow',
    ssl: 'green',
    documents: 'brown',
    z66: 'pink',
    unknown: 'gray'
};

export const paymentsTypeMapColor = {
    Webmoney: 'blue',
    YandexMoney: 'turquoise',
    BankCard: 'red',
    SberbankOnline: 'blue',
    Terminal: 'purple',
    Autopayment: 'green',
    Agent: 'yellow',
    BankPayment: 'green',
    Office: 'brown',
    Unknown: 'gray'
};

export const couponServicesForTransferMoneyMap = {
    cloud_hosting: 'Хостинг',
    cloud_vds: 'Облачные VDS',
    domains: 'Регистрация доменов',
    hosting: 'Виртуальный хостинг'
};

export const tariffs = [
    {
        title: 'Лайт',
        ram: 512,
    }, {
        title: 'Стандарт',
        ram: 1024
    }, {
        title: 'Профи',
        ram: 2048
    }
];

export const detectTariffByRam = (memory) => {
    for (const tariff of tariffs) {
        if (memory <= tariff.ram) {
            return tariff;
        }
    }
    return tariffs[tariffs.length - 1];
}

export const packetsMap = {
    cloud_hosting: {
        title: packet => `Годовой пакет для контейнера «${packet.resource_name}»`,
        description: (packet) => {
            const descriptionObjects = [
                `Тариф «${detectTariffByRam(packet.memory).title}»`,
                `Диск: ${packet.disk / 1024} ГБ`
            ];
            if (packet.redis > 0) {
                descriptionObjects.push(`Redis: ${packet.redis} МБ`);
            }
            if (packet.memcached > 0) {
                descriptionObjects.push(`Memcached: ${packet.memcached} МБ`);
            }
            return descriptionObjects.join(', ');
        }
    },
    cloud_vds: {
        title: packet => `Годовой пакет для виртуальной машины «${packet.resource_name}»`,
        description: (packet) => {
            const descriptionObjects = [
                `Тариф «${upperFirst(packet.vm_type.toLowerCase())}»`,
            ];
            if (packet.ip_count > 0) {
                descriptionObjects.push(`IP: ${packet.ip_count} шт`);
            }
            if (packet.storage_ssd > 0) {
                descriptionObjects.push(`Диск SSD: ${packet.storage_ssd} ГБ`);
            }
            if (packet.storage_sas > 0) {
                descriptionObjects.push(`Диск SAS: ${packet.storage_sas} ГБ`);
            }
            if (packet.storage_iscsi > 0) {
                descriptionObjects.push(`Диск ISCSI: ${packet.storage_iscsi} ГБ`);
            }
            if (packet.backup_size > 0) {
                descriptionObjects.push(`Бекапы: ${packet.backup_size} ГБ`);
            }
            return descriptionObjects.join(', ');
        }
    }
};

export const documentDeliveryTypes = {
    NoneDelivery: 0,
    MailDelivery: 1,
    MailSpecDelivery: 2,
    EmailDelivery: 3,
    DiadocDelivery: 4
};
