import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    View,
    StyleSheet,
    Text,
    Platform,
    Image,
    TouchableOpacity
} from 'react-native';

import {
    arrowDownIcon
} from '../../../icons/index';
import Modal from '../Modal/Modal';
import WebSelectModal from './WebSelectModal';
import MobileSelectModal from './MobileSelectModal';
import { addCustomNotification } from '../../../actions/notifications/actions';

function SelectModal(props) {
    const {
        label,
        disabled = false,
        disabledText,
        currentItem,
        addCustomNotification,
        additionalIconStyle = [],
        additionalWrapperStyle = [],
        additionalSelectLabelStyle = [],
    } = props;
    const [isModalChangeItem, setModalChangeItemVisible] = React.useState(false);
    return <View style={[styles.wrapper, ...additionalWrapperStyle]}>
        <Text style={styles.label}>{label}</Text>
        <TouchableOpacity style={styles.selectBlock}
            onPress={() => {
                if (!disabled) {
                    setModalChangeItemVisible(true);
                } else if (disabledText != null && disabledText.length !== 0) {
                    addCustomNotification(disabledText);
                }
            }}>
            <Text style={[styles.selectLabel, ...additionalSelectLabelStyle]}>
                {currentItem.label}
            </Text>
            {!disabled && <Image
                source={arrowDownIcon}
                style={[styles.icon, ...additionalIconStyle]}
            />}
        </TouchableOpacity>
        <Modal isModalShowed={isModalChangeItem}
            component={Platform.select({
                ios: <MobileSelectModal {...props}
                    onClose={() => setModalChangeItemVisible(false)}/>,
                android: <MobileSelectModal {...props}
                    onClose={() => setModalChangeItemVisible(false)}/>,
                web: <WebSelectModal {...props}
                    onClose={() => setModalChangeItemVisible(false)}/>,
                default: <WebSelectModal {...props}
                    onClose={() => setModalChangeItemVisible(false)}/>,
            })}
            onClose={() => setModalChangeItemVisible(false)} />
    </View>;
}

SelectModal.propTypes = {
    currentItem: PropTypes.shape({}),
    onChange: PropTypes.func,
    label: PropTypes.string,
    selectTitle: PropTypes.string,
    disabled: PropTypes.bool,
    disabledText: PropTypes.string,
    addCustomNotification: PropTypes.func,
    additionalIconStyle: PropTypes.arrayOf(),
    additionalWrapperStyle: PropTypes.arrayOf(),
    additionalSelectLabelStyle: PropTypes.arrayOf(),
};

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
    },
    label: {
        fontSize: 13,
        lineHeight: 18,
        marginBottom: 6,
        color: '#455562',
    },
    error: {
        color: '#d06565',
        marginTop: 4,
    },
    blueText: {
        color: '#0f79d5',
    },
    grayText: {
        color: '#455562',
    },
    icon: {
        width: 13,
        height: 7,
        marginRight: 20,
    },
    selectBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'baseline',
        borderBottomColor: '#dce2e9',
        borderBottomWidth: 1,
        marginBottom: 29,
    },
    selectLabel: {
        flex: 1,
        fontSize: 14,
        lineHeight: 19,
        paddingBottom: 10,
        color: '#455562',
    },
})


const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({ addCustomNotification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectModal);
