const BaseResource = require('../resource');
const GatewayClient = require('../../gateway-client');

class MailResource extends BaseResource {
    constructor({ url, token }) {
        super({ url, token });
        this.client = new GatewayClient({
            url,
            token,
            baseUrl: '/api/v2',
        });
    }

    async userMailInfo() {
        return this.makeRequest('get', null);
    }

    async updateMailQuota(paidQuotaEnabled) {
        return this.makeRequest('put', null, {
            paid_quota_enabled: paidQuotaEnabled,
        });
    }

    async userStatisticInfo() {
        return this.makeRequest('get', 'statistics/top10/');
    }

    async mailDomainsInfo() {
        return this.makeRequest('get', 'service-details/');
    }

    async mailCalculation() {
        return this.makeRequest('get', 'calculation/');
    }

    async mailPacketList() {
        return this.makeRequest('get', 'packet/list/');
    }

    async preferredPacket() {
        return this.makeRequest('get', 'packet/preferred/');
    }

    async createPacket(limit) {
        return this.makeRequest('post', 'packet/create/', { limit });
    }

    async updatePacket({ next_limit, prolong }) {
        return this.makeRequest('put', 'packet/update/', {
            next_limit,
            prolong,
        });
    }

    async cancelPacket() {
        return this.makeRequest('delete', 'packet/cancel/');
    }
}

MailResource.resource = null;
MailResource.service = 'mail';

module.exports = MailResource;
