import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';

import Errors from './Errors';
import { generatePassword } from '../../../utils/general';
import { passwordGreyIcon, openEyeIcon, closedEyeIcon } from '../../../icons';

function InputPassword(props) {
    const {
        label,
        errors,
        value,
        onChange,
        autoFocus,
        needBorder,
        additionalWrapperStyles = [],
    } = props;
    const [focused, setFocused] = React.useState(autoFocus || false);
    const [changedValue, setChangedValue] = React.useState(value);
    const [showPassword, setShowPassword] = React.useState(true);
    const focusOrEmpty = focused || (value && value.length > 0);
    const labelStyle = focusOrEmpty
        ? {
            fontSize: 12,
            lineHeight: 14,
            top: 9,
        }
        : {
            fontSize: 16,
            lineHeight: 19,
            top: 19,
        };
    const inputStyle = label ? { paddingTop: 26 } : { paddingTop: 11 };
    const iconStyle = label ? { marginRight: 18 } : { marginRight: 13 };
    const styles = StyleSheet.create({
        wrapper: {
            position: 'relative',
        },
        input: {
            borderColor: '#e4e4e4',
            borderStyle: 'solid',
            borderBottomWidth: 1,
            fontSize: 14,
            lineHeight: 19,
            color: '#455562',
            paddingBottom: 5,
            backgroundColor: '#ffffff',
            minHeight: 25,
        },
        label: {
            fontSize: 11,
            fontWeight: '400',
            lineHeight: 14,
            color: '#455562',
            marginBottom: 4,
        },
        icon: {
            width: 16,
            height: 16,
            ...iconStyle,
        },
        iconEye: {
            width: 20,
            height: 15,
            opacity: 0.4,
            ...iconStyle,
        },
        passwordIcon: {
            textAlign: 'end',
            position: 'absolute',
            right: 30,
            width: 40,
            paddingLeft: 10,
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        error: {
            borderColor: '#d06565',
        },
        showPassword: {
            textAlign: 'end',
            position: 'absolute',
            right: 0,
            width: 40,
            paddingLeft: 10,
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        }
    });
    const borderStyles = StyleSheet.create({
        input: {
            borderColor: 'rgba(69,85,98,0.3)',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            fontSize: 16,
            lineHeight: 19,
            color: '#455562',
            paddingRight: 12,
            paddingBottom: 11,
            paddingLeft: 12,
            backgroundColor: focusOrEmpty ? '#ffffff' : '#F2F6FA',
            ...inputStyle
        },
        label: {
            position: 'absolute',
            left: 12,
            color: 'rgba(69,85,98,0.5)',
            ...labelStyle
        },
    });
    const additionalInputClasses = [];
    if (errors != null && errors.length > 0) {
        additionalInputClasses.push('error');
    }
    let inputRef = null;
    const currentInputStyle = needBorder ? borderStyles.input : styles.input;
    const currentLabelStyle = needBorder ? borderStyles.label : styles.label;
    return <View style={[styles.wrapper, ...additionalWrapperStyles]}>
        {label && <Text style={currentLabelStyle} onClick={() => inputRef.focus()}>{label}</Text>}
        <View>
            <TextInput
                style={[currentInputStyle, ...additionalInputClasses.map((cls) => styles[cls])]}
                autoCompleteType='off'
                ref={(ref) => inputRef = ref}
                onBlur={() => setFocused(false)}
                onFocus={() => setFocused(true)}
                numberOfLines={1}
                secureTextEntry={showPassword}
                onSubmitEditing={() => setFocused(false)}
                value={changedValue}
                onChange={(e) => {
                    setChangedValue(e.target.value);
                    onChange(e.target.value);
                }}>
            </TextInput>
            <TouchableOpacity
                style={[styles.passwordIcon, { bottom: needBorder ? 14 : 10 }]}
                onPress={() => {
                    const newPassword = generatePassword(16);
                    setChangedValue(newPassword);
                    onChange(newPassword);
                }}>
                <Image source={passwordGreyIcon} style={styles.icon} />
            </TouchableOpacity>
            <TouchableOpacity
                style={[styles.showPassword, { bottom: needBorder ? 14 : 10 }]}
                onPress={() => {
                    setShowPassword(!showPassword);
                }}>
                <Image source={!showPassword ? openEyeIcon : closedEyeIcon}
                    resizeMode='contain'
                    style={styles.iconEye} />
            </TouchableOpacity>
        </View>
        <Errors errors={errors} />
    </View>;
}

InputPassword.propTypes = {
    label: PropTypes.string,
    errors: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string
    ]),
    textInputAttrs: PropTypes.shape({}),
    needBorder: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    additionalWrapperStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ]))
};

InputPassword.defaultProps = {
    errors: [],
    textInputAttrs: {},
    needBorder: false,
};

export default InputPassword;
