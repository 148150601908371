import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity
} from 'react-native';

import Input from '../../General/Form/Input';
import { userType } from '../../../constants/partnership';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';
import { partnershipRegisterUser } from '../../../actions/partnership/actions';

const formId = 'AddClientModalForm';

function AddClientModal(props) {
    const {
        errors,
        onClose,
        inProgress,
        updateList,
        flushErrors,
        partnershipRegisterUser
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);

    const [inn, setInn] = React.useState('');
    const [fio, setFio] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [activeTab, setActiveTab] = React.useState(userType.private);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Новый клиент</Text>
        <View style={styles.headerBlock}>
            <TouchableOpacity onPress={() => setActiveTab(userType.private)}>
                <Text style={[styles.headerText, {
                    color: activeTab === userType.private ? '#0f79d5' : '#455562',
                    opacity: activeTab === userType.private ? 1 : 0.7,
                    borderBottomWidth: activeTab === userType.private ? 2 : 0
                }]}>Физическое лицо</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setActiveTab(userType.organization)}>
                <Text style={[styles.headerText, {
                    color: activeTab === userType.organization ? '#0f79d5' : '#455562',
                    opacity: activeTab === userType.organization ? 1 : 0.7,
                    borderBottomWidth: activeTab === userType.organization ? 2 : 0
                }]}>Юридическое лицо</Text>
            </TouchableOpacity>
        </View>
        {activeTab === userType.private && <Input label='ФИО'
            errors={errors.name}
            additionalWrapperStyles={[{ marginBottom: 20 }]}
            textInputAttrs={{
                onChange: (e) => {
                    flushErrors();
                    setFio(e.target.value);
                },
                value: fio
            }}/>}
        {activeTab === userType.organization && <Input label='ИНН организации'
            errors={errors.inn}
            additionalWrapperStyles={[{ marginBottom: 20 }]}
            textInputAttrs={{
                onChange: (e) => {
                    flushErrors();
                    setInn(e.target.value);
                },
                value: inn
            }}/>}
        <Input label='Email'
            errors={errors.email}
            additionalWrapperStyles={[{ marginBottom: 20 }]}
            textInputAttrs={{
                onChange: (e) => {
                    flushErrors();
                    setEmail(e.target.value);
                },
                value: email
            }}/>
        <Input label='Мобильный телефон'
            errors={errors.phone}
            textInputAttrs={{
                onChange: (e) => {
                    flushErrors();
                    setPhone(e.target.value);
                },
                value: phone
            }}/>
        {errors.__all__ && <Text style={styles.error}>{errors.__all__}</Text>}
        <View style={styles.buttons}>
            <AcceptButton
                onSubmit={() => {
                    const data = { email, phone, user_type: activeTab };
                    if (activeTab === userType.private) {
                        data.name = fio;
                    }
                    if (activeTab === userType.organization) {
                        data.inn = inn;
                    }
                    partnershipRegisterUser(formId, data, () => {
                        updateList();
                        onClose();
                    });
                }}
                title='Сохранить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

AddClientModal.propTypes = {
    inProgress: PropTypes.bool,
    onClose: PropTypes.func,
    formId: PropTypes.string,
    updateList: PropTypes.func,
    errors: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    partnershipRegisterUser: PropTypes.func,
    partnership: PropTypes.shape({
        rinv: PropTypes.string,
        bank: PropTypes.string,
        bik: PropTypes.string,
        name: PropTypes.string
    })
};

const mapStateToProps = (state) => ({
    inProgress: state.partnership.registerUserInProgress,
    partnership: state.partnership.partnership,
    errors: state.forms.errors[formId] || {}
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    partnershipRegisterUser
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 18,
        paddingBottom: 42,
        paddingLeft: 16,
        paddingRight: 16,
    },
    title: {
        fontSize: 20,
        marginLeft: 4,
        lineHeight: 29,
        marginBottom: 24,
        color: '#455562',
    },
    headerBlock: {
        flex: 1,
        marginLeft: 4,
        marginBottom: 18,
        flexDirection: 'row'
    },
    headerText: {
        fontSize: 14,
        lineHeight: 16,
        marginRight: 24,
        fontWeight: '500',
        paddingBottom: 10,
        borderBottomColor: '#0f79d5'
    },
    buttons: {
        flex: 1,
        marginTop: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
    },
    input: {
        borderColor: '#dce2e9',
        borderWidth: 1,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
        marginBottom: 10,
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClientModal);
