import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../../icons';
import {
    fetchMonitoringEventsByService
} from '../../actions/monitoring/actions';
import { screenHeaderStyles } from '../../utils/styles';
import MonitoringServiceScreen from '../../components/Monitoring/MonitoringServiceScreen';
import ScreenHeaderTitle from '../../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const MonitoringHostingStack = createStackNavigator();

function MonitoringHostingStackScreen(props) {
    const { navigation } = props;
    const dispatch = useDispatch();
    const insets = useSafeAreaInsets();

    useFocusEffect(React.useCallback(() => {
        dispatch(fetchMonitoringEventsByService('cloud_hosting'));
    }, []));

    return <MonitoringHostingStack.Navigator>
        <MonitoringHostingStack.Screen name="MonitoringHostingScreen" component={MonitoringServiceScreen} options={{
            headerTitle: () => <ScreenHeaderTitle title='Мониторинг хостинга' />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => navigation.navigate('Monitoring'),
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </MonitoringHostingStack.Navigator>;
}

MonitoringHostingStackScreen.propTypes = {
    navigation: PropTypes.object,
};

export default MonitoringHostingStackScreen;
