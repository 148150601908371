import React from 'react';
import { Platform } from 'react-native';

function Modal(props) {
    const Component = Platform.select({
        android: () => require('./MobileModal'),
        ios: () => require('./MobileModal'),
        web: () => require('./WebModal'),
        default: () => require('./WebModal')
    })();
    return Component.default(props);
}

export default Modal;
