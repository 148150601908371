import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';

import { reloadIcon, reloadDisabledIcon } from '../../../icons';
import Modal from '../../General/Modal/Modal';
import { engines } from '../../../constants/hosting';
import VirtualhostReload from '../HostingModal/VirtualhostReload';

function EngineBlock(props) {
    const {
        type,
        numWorkers,
        virtualhostId,
        engineVersion,
        virtualhostEnabled,
    } = props;
    const [isModalReload, setModalReload] = React.useState(false);
    return <View>
        <Text style={styles.title}>{type}</Text>
        <TouchableOpacity
            onPress={() => {
                if (virtualhostEnabled) {
                    setModalReload(true)
                }
            }}
            style={virtualhostEnabled ? styles.restartButton :  [styles.restartButton, styles.restartButtonDisabled]}>
            <Image source={virtualhostEnabled ? reloadIcon : reloadDisabledIcon} style={styles.restartButtonIcon}/>
            <Text style={virtualhostEnabled ? styles.restartButtonText : styles.restartButtonTextDisabled}>Перезапустить {type}</Text>
        </TouchableOpacity>
        <View style={styles.versionBlock}>
            <Text style={styles.versionLabel}>Версия {type}</Text>
            <Text style={styles.versionValue}>{engineVersion}</Text>
        </View>
        <View style={styles.workersBlock}>
            <Text style={styles.workersLabel}>Количество процессов</Text>
            <Text style={styles.workersValue}>{numWorkers}</Text>
        </View>
        <Modal isModalShowed={isModalReload}
            component={<VirtualhostReload virtualhostId={virtualhostId} type={type}
            onClose={() => setModalReload(false)} />}
            onClose={() => setModalReload(false)} />
    </View>;
}

EngineBlock.propTypes = {
    type: PropTypes.string,
    numWorkers: PropTypes.number,
    virtualhostId: PropTypes.number,
    engineVersion: PropTypes.string,
    virtualhostEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    virtualhostId: state.hosting.virtualhost.id,
    virtualhostEnabled: state.hosting.virtualhost.enabled,
    type: engines[state.hosting.virtualhost.engine],
    numWorkers: state.hosting.virtualhost.num_workers,
    engineVersion: state.hosting.virtualhost.engine_version
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const styles = StyleSheet.create({
    title: {
        textTransform: 'uppercase',
        opacity: 0.75,
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.38,
        lineHeight: 13,
        marginTop: 13,
        marginLeft: 21,
        marginBottom: 13
    },
    restartButton: {
        paddingTop: 17,
        paddingBottom: 18,
        paddingLeft: 19,
        flex: 1,
        marginBottom: 16,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff'
    },
    restartButtonDisabled: {
        backgroundColor: 'rgba(136, 136, 136, 0.2)',
    },
    restartButtonIcon: {
        width: 18,
        height: 18,
        marginRight: 18,
        transform: 'rotate(0.25turn)'
    },
    restartButtonText: {
        color: '#0F79D5',
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 14
    },
    restartButtonTextDisabled: {
        color: '#455562',
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 14
    },
    versionBlock: {
        paddingTop: 17,
        paddingBottom: 18,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        borderBottomWidth: 1,
        borderBottomColor: '#E4E4E4'
    },
    versionLabel: {
        color: '#455562',
        fontSize: 14,
        lineHeight: 18
    },
    versionValue: {
        color: '#A1AAB1',
        fontSize: 12,
        lineHeight: 14
    },
    workersBlock: {
        paddingTop: 17,
        paddingBottom: 18,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff' 
    },
    workersLabel: {
        color: '#455562',
        fontSize: 14,
        lineHeight: 18
    },
    workersValue: {
        color: '#A1AAB1',
        fontSize: 12,
        lineHeight: 14
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(EngineBlock);