import React from 'react';
import PropTypes from 'prop-types';
import {
    Text,
    View,
    Image,
    StyleSheet,
} from 'react-native';

import { servicesResourceNameForServiceIconsMap } from '../../constants/general';

function BatchResourceCard(props) {
    const {
        service,
        resourceDescriptionFunc,
        resources,
    } = props;

    return (
        <View style={styles.wrapper}>
            <View style={styles.cardWrapper}>
                <Image
                    source={servicesResourceNameForServiceIconsMap[service]}
                    style={styles.icon}
                />
                <View style={styles.resourcesWrapper}>
                    {resources.map((resource, i) => (
                        <React.Fragment key={`transfer-resource-${i}`}>
                            <View style={styles.resourceBlock}>
                                <Text style={styles.title}>
                                    {resource.name}
                                </Text>
                                <Text style={styles.description}>
                                    {resourceDescriptionFunc(resource)}
                                </Text>
                            </View>
                        </React.Fragment>
                    ))}
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: '#ffffff',
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 19,
        paddingRight: 19,
    },
    icon: {
        width: 18,
        height: 18,
        marginTop: 8,
        marginRight: 10,
    },
    title: {
        marginBottom: 4,
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 15,
    },
    description: {
        color: '#586A7B',
        fontSize: 11,
        fontWeight: '400',
        lineHeight: 13,
    },
    cardWrapper: {
        flex: 1,
        flexDirection: 'row',
    },
    resourcesWrapper: {
        flex: 1,
        borderTopWidth: 1,
        borderStyle: 'solid',
        borderColor: '#E4E4E4',
        flexDirection: 'column',
    },
    resourceBlock: {
        paddingTop: 7,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: '#E4E4E4',
        paddingBottom: 7
    },
});

BatchResourceCard.propTypes = {
    service: PropTypes.string,
    resourceDescriptionFunc: PropTypes.func,
    resources: PropTypes.arrayOf(PropTypes.shape())
};

export default BatchResourceCard;
