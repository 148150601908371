import React from 'react';
import { useSelector } from 'react-redux';
import {
    View, StyleSheet, Text, Image,
    TouchableOpacity
} from 'react-native';

import Button from '../General/Button/Button';
import Modal from '../General/Modal/Modal';
import { formatMemory } from '../../utils/general';
import { launchMailboxIcon } from '../../icons';
import CreateMailboxModal from './Modals/CreateMailboxModal';
import ChangeDomainQuotaModal from './Modals/ChangeDomainQuotaModal';
import Spring from '../General/Spring/Spring';
import MailWebsocket from './MailWebsocket';

function MailboxLaunchPage() {
    const { domain, userInfo } = useSelector((state) => state.mail);
    let domainLimit = 0;
    if (domain != null) {
        domainLimit = domain.quota === 0 ? domain.max_quota : domain.quota;
    }
    const [isCreateModalShowed, setCreateModalShowed] = React.useState(false);
    const [isChangeQuotaModalShowed, setChangeQuotaModalShowed] = React.useState(false);

    return <View style={styles.wrapper}>
        {userInfo && userInfo.paid_quota_enabled && <View style={{ width: '100%' }}>
            <View style={styles.quotaBlock}>
                <Text style={styles.quotaText}>Лимит на домен</Text>
                <TouchableOpacity onPress={() => setChangeQuotaModalShowed(true)}>
                    <Text style={styles.blueText}>{formatMemory(domainLimit, true)}</Text>
                </TouchableOpacity>
            </View>
        </View>}
        <Spring />
        <Image source={launchMailboxIcon} style={styles.image} />
        <View style={styles.content}>
            <Text style={styles.description}>
                Почтовых ящиков пока нет
            </Text>
        </View>
        <Button title='Создать почтовый ящик'
            onPress={() => setCreateModalShowed(true)}
            additionalWrapperStyles={[{ width: '100%' }]}
            additionalButtonStyles={[{
                paddingTop: 11,
                paddingBottom: 13,
                marginLeft: 22,
                marginRight: 22,
            }]}
            additionalButtonTextStyles={[{
                fontSize: 14,
                lineHeight: 16,
                fontWeight: '400',
            }]}/>
        <Spring />
        <Modal isModalShowed={isCreateModalShowed}
            component={<CreateMailboxModal
                domainId={domain.id}
                onClose={() => setCreateModalShowed(false)}/>}
            onClose={() => setCreateModalShowed(false)} />
        <Modal isModalShowed={isChangeQuotaModalShowed}
            component={<ChangeDomainQuotaModal
                onClose={() => setChangeQuotaModalShowed(false)}/>}
            onClose={() => setChangeQuotaModalShowed(false)} />
        <MailWebsocket />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        margin: 0,
        flex: 1,
        width: '100%',
        alignItems: 'center',
    },
    image: {
        minHeight: 195,
        minWidth: 234,
        marginBottom: 20,
    },
    title: {
        width: 220,
        textAlign: 'center',
        fontSize: 16,
        color: '#455562',
        letterSpacing: 0,
        lineHeight: 19,
        marginBottom: 12,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    description: {
        width: 268,
        fontSize: 16,
        textAlign: 'center',
        color: '#4E5F6E',
        lineHeight: 19,
        marginBottom: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    quotaBlock: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        paddingTop: 20,
        paddingBottom: 21,
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    quotaText: {
        fontSize: 13,
        fontWeight: '500',
        color: '#455562',
    },
    blueText: {
        fontSize: 12,
        fontWeight: '500',
        color: '#0f79d5',
    },
});

export default MailboxLaunchPage;
