import React from 'react';
import { View, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    spring: {
        flex: 1,
    },
});

function Spring() {
    return <View style={styles.spring} />;
}

export default Spring;
