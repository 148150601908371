import React from 'react';
import { StyleSheet, View } from 'react-native';

import Separator from '../../General/Separator/Separator';

function getDates(count) {
    const resources = [];
    for (let i = 0; i < count; i += 1) {
        resources.push(<View style={styles.date} key={`resourse-${i}`}/>)
    }
    return resources;
}

function ForecastLoader() {
    return <View style={styles.wrapper}>
        <View style={styles.title} />
        <View style={styles.balanceText} />
        <View style={styles.chooseDatesBlock}>
            <View style={styles.currentChoseDate} />
            {getDates(30)}
        </View>
        <View style={styles.subTitle} />
        <View>
            <View style={styles.filtersBlock}>
                <View style={[styles.filter, { width: 101 }]} />
                <View style={[{ width: 79 }, styles.filter]} />
                <View style={[{ width: 91 }, styles.filter]} />
                <View style={[{ width: 140 }, styles.filter]} />
            </View>
        </View>
        <View style={styles.horizontalBarChartTitle} />
        <View style={styles.horizontalBarChart} />
        <Separator />
        <View style={styles.statisticsList}>
            <View style={styles.statisticBlock}>
                <View style={styles.statisticIndicator} />
                <View style={styles.statisticTextBlock}>
                    <View style={[{ width: 175, marginBottom: 5 }, styles.statisticText]} />
                    <View style={[{ width: 78, marginBottom: 7 }, styles.statisticText]} />
                    <View style={[{ width: 30 }, styles.statisticText]} />
                </View>
                <View style={styles.spring} />
                <View style={styles.statisticPrice} />
            </View>
            <Separator />
            <View style={styles.statisticBlock}>
                <View style={styles.statisticIndicator} />
                <View style={styles.statisticTextBlock}>
                    <View style={[{ width: 126, marginBottom: 5 }, styles.statisticText]} />
                    <View style={[{ width: 148, marginBottom: 7 }, styles.statisticText]} />
                    <View style={[{ width: 49 }, styles.statisticText]} />
                </View>
                <View style={styles.spring} />
                <View style={styles.statisticPrice} />
            </View>
            <Separator />
            <View style={styles.statisticBlock}>
                <View style={styles.statisticIndicator} />
                <View style={styles.statisticTextBlock}>
                    <View style={[{ width: 175, marginBottom: 5 }, styles.statisticText]} />
                    <View style={[{ width: 78, marginBottom: 7 }, styles.statisticText]} />
                    <View style={[{ width: 30 }, styles.statisticText]} />
                </View>
                <View style={styles.spring} />
                <View style={styles.statisticPrice} />
            </View>
            <Separator />
        </View>
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
        backgroundColor: '#ffffff',
        flex: 1,
        paddingTop: 16,
    },
    title: {
        height: 14,
        width: 117,
        marginLeft: 20,
        borderRadius: 2,
        backgroundColor: '#d8d8d8',
        marginBottom: 10,
    },
    balanceText: {
        height: 25,
        width: 98,
        marginLeft: 20,
        borderRadius: 2,
        backgroundColor: '#d8d8d8',
        marginBottom: 12,
    },
    chooseDatesBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 37,
        maxHeight: 25,
        marginLeft: 20,
        overflow: 'hidden',
    },
    currentChoseDate: {
        height: 25,
        width: 23,
        borderRadius: 2,
        backgroundColor: '#d8d8d8',
        marginRight: 8,
    },
    date: {
        height: 11,
        width: 11,
        borderRadius: 2,
        backgroundColor: '#d8d8d8',
        marginRight: 8,
    },
    subTitle: {
        height: 14,
        width: 237,
        marginLeft: 20,
        borderRadius: 2,
        backgroundColor: '#d8d8d8',
        marginBottom: 17,
    },
    filtersBlock: {
        flex: 1,
        marginLeft: 20,
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    filter: {
        height: 28,
        borderRadius: 2,
        backgroundColor: '#d8d8d8',
        marginRight: 8,
        marginBottom: 7,
    },
    horizontalBarChartTitle: {
        marginTop: 9,
        marginBottom: 13,
        height: 14,
        width: 94,
        borderRadius: 2,
        marginLeft: 20,
        backgroundColor: '#d8d8d8',
    },
    horizontalBarChart: {
        marginBottom: 24,
        height: 6,
        width: 284,
        borderRadius: 2,
        marginLeft: 20,
        backgroundColor: '#d8d8d8',
    },
    statisticsList: {
        flex: 1,
        height: '100%',
    },
    statisticBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 16,
        paddingTop: 19,
        paddingLeft: 20,
        paddingRight: 15,
        height: 74,
    },
    statisticText: {
        height: 9,
        borderRadius: 2,
        backgroundColor: '#d8d8d8',
    },
    statisticIndicator: {
        height: 10,
        width: 10,
        marginRight: 13,
        borderRadius: 2,
        backgroundColor: '#d8d8d8',
    },
    spring: {
        flex: 1,
    },
    statisticPrice: {
        height: 9,
        width: 58,
        borderRadius: 2,
        backgroundColor: '#d8d8d8',
    }
});

export default ForecastLoader;
