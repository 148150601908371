import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, Image, StyleSheet } from 'react-native';

import {
    fetchBarChartCPU,
} from '../../../actions/hosting/actions';
import { detectTariffByRam } from '../utils';
import { cpuIcon } from '../../../icons';
import StatisticsSevenDays from '../../General/Statistics/StatisticsSevenDays';


function normalizeDatapointsRegardingTariff(datapoints, tariff) {
    if (datapoints) {
        return datapoints.map(datapoint => ({
            value: getPercentCPU(tariff, datapoint.value),
            date: datapoint.date
        }));
    }
    return [];
}

function normalizeCPU(cp) {
    return Math.round(cp);
}

function getPercentCPU(tariff, value) {
    const normalizedCPU = normalizeCPU(value);
    return (normalizedCPU * 100) / tariff.cpu;
}

function normalizedCumulative(cumulative, tariff) {
    return Math.round(getPercentCPU(tariff, cumulative));
}

function StatisticsSevenDaysCPU(props) {
    const {
        tariff,
        isError,
        isLoaded,
        isLoading,
        container,
        cumulative,
        datapoints,
        fetchBarChartCPU,
    } = props;

    React.useEffect(() => {
        if (!isLoaded && !isError && !isLoading) {
            fetchBarChartCPU(container.id);
        }
    }, []);
    return <View style={styles.wrapper}>
        <Image source={cpuIcon} style={styles.icon}/>
        <View style={styles.textBlock}>
            <Text style={styles.title}>CPU</Text>
            <Text style={styles.subTitle}>
                СР {tariff.cpu}
            </Text>
        </View>
        <StatisticsSevenDays
            isLoading={datapoints == null && isLoading}
            isError={isError}
            generalValue={normalizedCumulative(cumulative, tariff)}
            days={normalizeDatapointsRegardingTariff(datapoints, tariff)}
            formatGeneralValue={value => `~${value && value.toFixed(0)}%`}/>
    </View>;
}

StatisticsSevenDaysCPU.propTypes = {
    container: PropTypes.shape({}),
    cumulative: PropTypes.number,
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    datapoints: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
    })),
    fetchBarChartCPU: PropTypes.func
};

const mapStateToProps = state => ({
    ...state.hosting.barChartCPU,
    container: state.hosting.container,
    tariff: detectTariffByRam(state.hosting.container.memory_limit),
});
const mapDispatchToProps = dispatch => bindActionCreators({
    fetchBarChartCPU,
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 16,
        paddingRight: 19,
        paddingBottom: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 1,
        backgroundColor: '#ffffff'
    },
    textBlock: {
        flex: 1
    },
    title: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14,
        fontWeight: '600'
    },
    subTitle: {
        color: '#455562',
        fontSize: 10,
        lineHeight: 14
    },
    icon: {
        height: 24,
        width: 24,
        marginRight: 12
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsSevenDaysCPU);
