import dayjs from 'dayjs';
import {
    COUPONS_LIST_IN_PROGRESS,
    COUPONS_LIST_WITH_ERRORS,
    COUPONS_LIST_SUCCESSFULLY,
    COUPON_ACTIVATE_IN_PROGRESS,
    COUPON_ACTIVATE_SUCCESSFULLY,
    COUPON_ACTIVATE_WITH_ERRORS,
    AWAITING_PACKET_LIST_IN_PROGRESS,
    AWAITING_PACKET_LIST_SUCCESSFULLY,
    AWAITING_PACKET_LIST_WITH_ERRORS,
    CHARGES_MONTH_GROUP_IN_PROGRESS,
    CHARGES_MONTH_GROUP_SUCCESSFULLY,
    CHARGES_MONTH_GROUP_WITH_ERRORS,
    AWAITING_PACKET_CANCEL_SUCCESSFULLY,
    SET_PAYMENT_AMOUNT,
    MONTHS_FOR_PAYMENTS_SUCCESSFULLY,
    MONTHS_FOR_PAYMENTS_WITH_ERRORS,
    MONTHS_FOR_PAYMENTS_IN_PROGRESS,
    MONTHS_FOR_CHARGES_IN_PROGRESS,
    MONTHS_FOR_CHARGES_SUCCESSFULLY,
    MONTHS_FOR_CHARGES_WITH_ERRORS,
    REPORT_MONTHS_IN_PROGRESS,
    REPORT_MONTHS_SUCCESSFULLY,
    REPORT_MONTHS_WITH_ERRORS,
    MONTHS_FOR_ACTS_IN_PROGRESS,
    MONTHS_FOR_ACTS_SUCCESSFULLY,
    MONTHS_FOR_ACTS_WITH_ERRORS,
    CHARGES_MONTH_CHANGE_DATE,
    AUTOPAYMENT_DETAIL_FETCH_SUCCESSFULLY,
    AUTOPAYMENT_DETAIL_FETCH_IN_PROGRESS,
    AUTOPAYMENT_DETAIL_FETCH_WITH_ERRORS,
    DOWNLOAD_ACT_IN_PROGRESS,
    DOWNLOAD_ACT_SUCCESSFULLY,
    DOWNLOAD_ACT_WITH_ERRORS,
    INIT_PAYMENT_IN_PROGRESS,
    INIT_PAYMENT_SUCCESSFULLY,
    INIT_PAYMENT_WITH_ERRORS,
} from '../../actions/finances/actionTypes';

export default (initialState) => {
    const defaultState = {
        coupons: null,
        couponsInProgress: false,
        awaitingPackets: null,
        awaitingPacketsInProgress: false,
        chargesMonths: null,
        chargesMonthsInProgress: false,
        statisticsCharges: null,
        statisticsChargesInProgress: false,
        statisticsChargesDate: dayjs().format('YYYY-MM-DD'),
        paymentAmount: null,
        monthsPayments: [],
        monthsPaymentsInProgress: false,
        chargesMonthsForAct: [],
        chargesMonthsForActInProgress: false,
        monthsForPartnerReport: [],
        monthsForPartnerReportInProgress: false,
        autopayment: null,
        autopaymentFetchInProgress: false,
        couponActivateInProgress: false,
        downloadActInProgress: false,
        initPaymentInProgress: false,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case COUPONS_LIST_SUCCESSFULLY: {
            return {
                ...state,
                coupons: action.data,
                couponsInProgress: false,
            };
        }
        case COUPONS_LIST_IN_PROGRESS: {
            return {
                ...state,
                couponsInProgress: true,
            };
        }
        case COUPONS_LIST_WITH_ERRORS: {
            return {
                ...state,
                couponsInProgress: false,
            };
        }
        case COUPON_ACTIVATE_IN_PROGRESS: {
            return {
                ...state,
                couponActivateInProgress: true,
            };
        }
        case COUPON_ACTIVATE_WITH_ERRORS: {
            return {
                ...state,
                couponActivateInProgress: false,
            };
        }
        case COUPON_ACTIVATE_SUCCESSFULLY: {
            if (state.coupons == null) {
                return state;
            }
            const coupon = action.data;
            let coupons = [...state.coupons];
            const couponService = state.coupons.find(item => item.service === coupon.service);
            // Coupon have activated first time from target service
            if (couponService == null) {
                coupons.push({
                    balance: coupon.balance,
                    coupons: [coupon],
                    service: coupon.service
                });
            }
            coupons = coupons.map((item) => {
                if (item.service === coupon.service
                    && !item.coupons.find(c => c.id === coupon.id)) {
                    return {
                        ...item,
                        balance: item.balance + action.data.balance,
                        coupons: [...item.coupons, action.data]
                    };
                }
                return item;
            });
            return {
                ...state,
                coupons,
                couponActivateInProgress: false,
            };
        }
        case AWAITING_PACKET_LIST_SUCCESSFULLY: {
            return {
                ...state,
                awaitingPackets: action.data,
                awaitingPacketsInProgress: false,
            };
        }
        case AWAITING_PACKET_LIST_IN_PROGRESS: {
            return {
                ...state,
                awaitingPacketsInProgress: true,
            };
        }
        case AWAITING_PACKET_LIST_WITH_ERRORS: {
            return {
                ...state,
                awaitingPacketsInProgress: false,
            };
        }
        case CHARGES_MONTH_GROUP_SUCCESSFULLY: {
            return {
                ...state,
                statisticsCharges: action.data,
                statisticsChargesInProgress: false,
            };
        }
        case CHARGES_MONTH_GROUP_IN_PROGRESS: {
            return {
                ...state,
                statisticsChargesInProgress: true,
            };
        }
        case CHARGES_MONTH_GROUP_WITH_ERRORS: {
            return {
                ...state,
                statisticsChargesInProgress: false,
            };
        }
        case AWAITING_PACKET_CANCEL_SUCCESSFULLY: {
            return {
                ...state,
                awaitingPackets: state.awaitingPackets.filter(packet => packet.service !== action.data.service
                    && packet.resource_id !== action.data.resourceId)
            };
        }
        case SET_PAYMENT_AMOUNT: {
            return {
                ...state,
                paymentAmount: action.data
            };
        }
        case MONTHS_FOR_PAYMENTS_IN_PROGRESS: {
            return {
                ...state,
                monthsPaymentsInProgress: true
            };
        }
        case MONTHS_FOR_CHARGES_IN_PROGRESS: {
            return {
                ...state,
                chargesMonthsInProgress: true
            };
        }
        case MONTHS_FOR_CHARGES_SUCCESSFULLY: {
            const currentMonth = dayjs().format('YYYY-MM');
            const months = [...action.data.entities];
            if (months.indexOf(currentMonth) === -1) {
                months.push(currentMonth);
            }
            months.sort((a, b) => {
                const normalizedA = dayjs(a);
                const normalizedB = dayjs(b);
                if (normalizedA > normalizedB) {
                    return 1;
                }
                if (normalizedA < normalizedB) {
                    return -1;
                }
                return 0;
            });
            return {
                ...state,
                chargesMonthsInProgress: false,
                chargesMonths: months,
            };
        }
        case MONTHS_FOR_CHARGES_WITH_ERRORS: {
            return {
                ...state,
                chargesMonthsInProgress: false
            };
        }
        case MONTHS_FOR_PAYMENTS_SUCCESSFULLY: {
            const currentMonth = dayjs().format('YYYY-MM');
            const months = [...action.data.entities];
            if (months.indexOf(currentMonth) === -1) {
                months.push(currentMonth);
            }
            months.sort((a, b) => {
                const normalizedA = dayjs(a);
                const normalizedB = dayjs(b);
                if (normalizedA > normalizedB) {
                    return 1;
                }
                if (normalizedA < normalizedB) {
                    return -1;
                }
                return 0;
            });
            return {
                ...state,
                monthsPaymentsInProgress: false,
                monthsPayments: months,
            };
        }
        case MONTHS_FOR_PAYMENTS_WITH_ERRORS: {
            return {
                ...state,
                monthsPaymentsInProgress: false
            };
        }
        case REPORT_MONTHS_IN_PROGRESS: {
            return {
                ...state,
                monthsForPartnerReportInProgress: true
            };
        }
        case REPORT_MONTHS_WITH_ERRORS: {
            return {
                ...state,
                monthsForPartnerReportInProgress: false
            };
        }
        case REPORT_MONTHS_SUCCESSFULLY: {
            return {
                ...state,
                monthsForPartnerReportInProgress: false,
                monthsForPartnerReport: action.data.entities
            };
        }
        case MONTHS_FOR_ACTS_SUCCESSFULLY: {
            return {
                ...state,
                chargesMonthsForAct: action.data.entities,
                chargesMonthsForActInProgress: false
            };
        }
        case MONTHS_FOR_ACTS_IN_PROGRESS: {
            return {
                ...state,
                chargesMonthsForActInProgress: true
            };
        }
        case MONTHS_FOR_ACTS_WITH_ERRORS: {
            return {
                ...state,
                chargesMonthsForActInProgress: false
            };
        }
        case CHARGES_MONTH_CHANGE_DATE: {
            return {
                ...state,
                statisticsChargesDate: action.data.date
            };
        }
        case AUTOPAYMENT_DETAIL_FETCH_SUCCESSFULLY: {
            return {
                ...state,
                autopayment: action.data,
                autopaymentFetchInProgress: false,
            };
        }
        case AUTOPAYMENT_DETAIL_FETCH_IN_PROGRESS: {
            return {
                ...state,
                autopaymentFetchInProgress: true,
            };
        }
        case AUTOPAYMENT_DETAIL_FETCH_WITH_ERRORS: {
            return {
                ...state,
                autopaymentFetchInProgress: false,
            };
        }
        case DOWNLOAD_ACT_SUCCESSFULLY: {
            return {
                ...state,
                downloadActInProgress: false,
            };
        }
        case DOWNLOAD_ACT_IN_PROGRESS: {
            return {
                ...state,
                downloadActInProgress: true,
            };
        }
        case DOWNLOAD_ACT_WITH_ERRORS: {
            return {
                ...state,
                downloadActInProgress: false,
            };
        }
        case INIT_PAYMENT_IN_PROGRESS: {
            return {
                ...state,
                initPaymentInProgress: true
            };
        }
        case INIT_PAYMENT_SUCCESSFULLY: {
            return {
                ...state,
                initPaymentInProgress: false
            };
        }
        case INIT_PAYMENT_WITH_ERRORS: {
            return {
                ...state,
                initPaymentInProgress: false
            };
        }
        default:
            return state;
        }
    };
};
