import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
    Image,
    Text,
    View,
    StyleSheet,
    TouchableOpacity,
} from 'react-native';
import Input from '../components/General/Form/Input';
import Errors from '../components/General/Form/Errors';
import Button from '../components/General/Button/Button';
import { passwordRestore } from '../actions/user/actions';
import { netangelsLogoIcon } from '../icons';

const formId = 'restorePasswordFormId';

const styles = StyleSheet.create({
    logoWrapper: {
        flex: 0.1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    authScreen: {
        marginTop: '11%',
        padding: 22,
        flex: 1,
        flexDirection: 'column'
    },
    headerText: {
        fontSize: 22,
        marginBottom: 30,
        textAlign: 'center',
        lineHeight: 25,
        color: '#455562'
    },
    form: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 4,
    },
    hint: {
        color: 'rgba(69, 85, 98, 0.70)',
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 22,
        marginLeft: 12,
    },
    button: {
        borderRadius: 4,
    },
    linkButton: {
        fontSize: 11,
        lineHeight: 13,
        letterSpacing: 1.38,
        textAlign: 'center',
        opacity: 0.75,
        color: '#4e5f6e',
        marginTop: 25,
        textTransform: 'uppercase',
    },
    allErrorWrapper: {
        textAlign: 'center',
        marginBottom: 10
    },
    methodsBlock: {
        flex: 1,
        marginLeft: 12,
        marginBottom: 23,
        flexDirection: 'row',
    },
    methodButtonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    methodButtonTitle: {
        color: '#586a7b',
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 20
    },
    outerCircle: {
        height: 18,
        width: 18,
        marginRight: 12,
        borderTopWidth: 1,
        borderTopColor: 'rgba(69, 85, 98, 0.50);',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(69, 85, 98, 0.50);',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(69, 85, 98, 0.50);',
        borderRightWidth: 1,
        borderRightColor: 'rgba(69, 85, 98, 0.50);',
        borderRadius: 9,
        backgroundColor: '#ffffff'
    },
    activeOuterCircle: {
        height: 18,
        width: 18,
        marginRight: 13,
        borderTopWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderLeftWidth: 1,
        borderLeftColor: '#0f79d5',
        borderRightWidth: 1,
        borderRightColor: '#0f79d5',
        borderRadius: 9,
        backgroundColor: '#ffffff',
        paddingTop: 4,
        paddingLeft: 4
    },
    innerCircle: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0f79d5'
    },
});

function methodButton(currentMethod, methodName, setMethod, text) {
    const isCurrentMethod = currentMethod === methodName;
    return <TouchableOpacity onPress={() => setMethod(methodName)}
        style={styles.methodButtonBlock}>
        <View style={isCurrentMethod ? styles.activeOuterCircle : styles.outerCircle}>
            {isCurrentMethod && <View style={styles.innerCircle}/>}
        </View>
        <Text style={styles.methodButtonTitle}>{text}</Text>
    </TouchableOpacity>;
}

function RestorePasswordScreen(props) {
    const { navigation } = props;
    const dispatch = useDispatch();
    const [transport, setTransport] = useState('email');
    const inProgress = useSelector((state) => state.user.passwordRestoreInProgress);
    const errors = useSelector((state) => state.forms.errors[formId]) || {};

    return <View style={styles.authScreen}>
        <View style={styles.logoWrapper}>
            <Image source={netangelsLogoIcon} style={{ width: 162, height: 30 }} />
        </View>
        <View style={styles.form}>
            <Text style={styles.headerText}>Восстановление пароля</Text>
            {errors.__all__ && <View style={styles.allErrorWrapper}>
                <Errors errors={errors.__all__} />
            </View>}
            <View>
                <Formik
                    initialValues={{
                        domain: '',
                    }}
                    onSubmit={(values) => dispatch(
                        passwordRestore(formId, { domain: values.domain, transport }, () => {
                            navigation.navigate('RestorePasswordDone');
                        })
                    )}>
                    {({ handleChange, handleBlur, handleSubmit, values }) => (
                        <View>
                            <Input
                                needBorder={true}
                                additionalWrapperStyles={[{ marginBottom: 10 }]}
                                errors={errors.domain}
                                label='Логин или имя сайта'
                                textInputAttrs={{
                                    onChangeText: handleChange('domain'),
                                    onBlur: handleBlur('domain'),
                                    value: values.domain
                                }}
                            />
                            <Text style={styles.hint}>Например: u12345 или company.ru</Text>
                            <View style={styles.methodsBlock}>
                                {methodButton(transport, 'email', setTransport, 'на почту')}
                                {methodButton(transport, 'sms', setTransport, 'в SMS')}
                            </View>
                            <Button
                                inProgress={inProgress}
                                style={styles.button}
                                onPress={handleSubmit}
                                title="Восстановить пароль"
                            />
                            <TouchableOpacity onPress={() => navigation.navigate('Auth')}>
                                <Text style={styles.linkButton}>
                                    Отменить
                                </Text>
                            </TouchableOpacity>
                        </View>
                    )}
                </Formik>
            </View>
        </View>
    </View>;
}

RestorePasswordScreen.propTypes = {
    navigation: PropTypes.shape({}),
};

export default RestorePasswordScreen;
