import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, } from 'react-native';

import Input from '../../General/Form/Input';
import { flushErrors } from '../../../actions/forms/actions';
import { aliasAddAction } from '../../../actions/hosting/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'HostingVHAddAliasForm';

function VirtualhostAddAlias(props) {
    const {
        errors,
        onClose,
        inProgress,
        flushErrors,
        virtualhostId,
        aliasAddAction,
    } = props;

    React.useEffect(() => {
        flushErrors()
    }, []);
    const [newAlias, setModalNewAlias] = React.useState(null);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Новый домен (алиас)</Text>
        <Input
            errors={errors && errors.errors && errors.errors.alias}
            textInputAttrs={{
                autoFocus: true,
                numberOfLines: 1,
                value: newAlias,
                placeholder: 'Введите адрес',
                onChange: (e) => {
                    flushErrors();
                    setModalNewAlias(e.target.value);
                }
            }}
        />
        {errors.__all__ && <Text style={styles.error}>{errors.__all__}</Text>}
        <View style={styles.buttons}>
            <AcceptButton
                onSubmit={() => aliasAddAction(formId, virtualhostId, newAlias, () => onClose())}
                title='Добавить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

VirtualhostAddAlias.propTypes = {
    onClose: PropTypes.func,
    inProgress: PropTypes.bool,
    errors: PropTypes.shape({}),
    aliasAddAction: PropTypes.func,
    flushErrors: PropTypes.func,
    virtualhostId: PropTypes.number,
};

const mapStateToProps = (state) => ({
    inProgress: state.hosting.virtualhostUpdateInProgress,
    errors: state.forms.errors[formId] || {},
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    aliasAddAction
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 44,
        paddingBottom: 42,
        paddingLeft: 40,
        paddingRight: 40,
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 20,
        color: '#455562',
    },
    buttons: {
        flex: 1,
        marginTop: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
    },
    input: {
        borderColor: '#dce2e9',
        borderWidth: 1,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
        marginBottom: 10,
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(VirtualhostAddAlias);