import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@react-native-community/slider';
import { View, Text, StyleSheet } from 'react-native';

function getMax(items) {
    return items.length - 1;
}

function getScaleValue(value, items) {
    for (let i = 0; i < items.length; i += 1) {
        if (items[i] === value) {
            return i;
        }
    }
    return 0;
}

function getValue(position, items) {
    return items[position];
}

function SliderDynamic(props) {
    const {
        title, items,
        unit, value,
        disabled = false,
        onAfterChange = () => {},
        onBeforeChange = () => {},
        notNeedToShowValue,
        getUnits = (value, unit) => `${value} ${unit}`,
        onChange = () => {},
        hideBounds = false,
    } = props;
    const [newValue, setNewValue] = React.useState(getScaleValue(value, items));
    React.useEffect(() => {
        setNewValue(getScaleValue(value, items));
    }, [value]);
    const min = 0;
    const max = getMax(items);
    return <View>
        <View style={styles.textBlock}>
            {title && <Text style={styles.title}>{title}</Text>}
            {!notNeedToShowValue && <Text style={styles.storageSize}>
                {getUnits(getValue(newValue, items), unit)}
            </Text>}
        </View>
        <View>
            <Slider
                style={{ height: 24 }}
                step={1}
                minimumValue={0}
                maximumValue={getMax(items)}
                value={newValue}
                disabled={disabled}
                thumbStyle={styles.thumbStyle}
                minimumTrackTintColor="#0f79d5"
                maximumTrackTintColor="#B0B0B0"
                onSlidingStart={(v) => onBeforeChange(v)}
                onSlidingComplete={(v) => {
                    if (!disabled) {
                        onAfterChange(getValue(v, items), v)
                    }
                }}
                onValueChange={(v) => {
                    if (!disabled) {
                        setNewValue(v);
                        onChange(getValue(v, items), v);
                    }
                }}/>
            {!hideBounds && <View style={styles.textBlock}>
                <Text style={styles.unitText}>
                    {getUnits(getValue(min, items), unit)}
                </Text>
                <Text style={styles.unitText}>
                    {getUnits(getValue(max, items), unit)}
                </Text>
            </View>}
        </View>
    </View>;
}

SliderDynamic.propTypes = {
    title: PropTypes.string,
    unit: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.number),
    value: PropTypes.number,
    getUnits: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    hideBounds: PropTypes.bool,
    onBeforeChange: PropTypes.func,
};

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 9,
        marginBottom: 8,
        backgroundColor: '#ffffff'
    },
    textBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    title: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 18
    },
    storageSize: {
        color: '#0f79d5',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 18
    },
    thumbStyle: {
        backgroundColor: '#ffffff',
        borderColor: '#dedede',
        borderWidth: 1,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowOffset: { width: 0, height: 1 },
        shadowRadius: 0,
        height: 18,
        width: 18
    },
    unitText: {
        opacity: 0.8,
        color: '#455562',
        fontSize: 10,
        lineHeight: 16
    },
})

export default SliderDynamic;
