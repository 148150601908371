import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigation } from '@react-navigation/native';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';

import {
    ssdIcon,
    sasIcon,
    nvmeIcon,
    rightIcon,
    backupsIcon,
    backupsDisabledIcon
} from '../../icons';
import { isActiveVM } from './utils';
import Modal from '../General/Modal/Modal';
import Switch from '../General/Switch/Switch';
import BackupModal from './Modals/BackupModal';
import WarningCancelPacketModal from './Modals/WarningCancelPacketModal';
import diskData from '../../../data/cvds/disk-types.json';
import VolumeFill from './VolumeFill';
import { vmDiskChangeBackup } from '../../actions/cvds/actions';
import { blockedButtonTooltips } from '../../constants/general';

const diskIcons = {
    sas: sasIcon,
    ssd: ssdIcon,
    nvme: nvmeIcon
};

const formId = 'VolumeChangeBackupForm';

function getDiskType(diskId) {
    const diskType = diskData.find(disk => disk.id === diskId.toLocaleLowerCase()).short;
    return diskType.toLocaleLowerCase();
}

function VolumesBlock(props) {
    const {
        vm,
        inProgress,
        awaitingPacket,
        vmDiskChangeBackup,
    } = props;
    const [isBackupModalShowed, setBackupModalVisible] = React.useState(false);
    const [isWarningPacketModalShowed, setWarningPacketModalVisible] = React.useState(false);
    const navigation = useNavigation();
    return <View style={styles.wrapper}>
        <TouchableOpacity
            style={styles.titleBlock}
            onPress={() => navigation.navigate('CVDSVMDisk')}>
            <Text style={styles.title}>Диски и бэкапы</Text>
            <Image source={rightIcon} style={styles.icon}/>
        </TouchableOpacity>
        <View style={styles.diskBlock}>
            <Image source={diskIcons[getDiskType(vm.disk.type)]} style={styles.diskIcon}/>
            <VolumeFill
                id={vm.disk.uid}
                size={vm.disk.size}
                isLoaded={vm.volumesUsedSize != null}
                isLoading={vm.volumesUsedSizeIsLoading}
                isError={vm.volumesUsedSizeIsError}
                percentThreshold={11}
                data={vm.volumesUsedSize && vm.volumesUsedSize[vm.disk.uid]}
                name={`Основной диск (${vm.disk.name || vm.disk.uid})`}
            />
        </View>
        <View style={styles.backupBlock}>
            <Image source={vm.disk.is_backup_enabled ? backupsIcon : backupsDisabledIcon}
                style={styles.backupIcon}/>
            <View style={styles.backupTextBlock}>
                <Text style={styles.backupTitle}>Резервное копирование</Text>
                <Text style={styles.backupText}>виртуальной машины и основного диска</Text>
            </View>
            <View>
                <Switch isEnable={vm.disk.is_backup_enabled}
                    onChange={() => {
                        const data = {
                            vmId: vm.id,
                            isBackupEnabled: vm.disk.is_backup_enabled
                        };
                        if (vm.disk.is_backup_enabled) {
                            setBackupModalVisible(true);
                        } else if (awaitingPacket) {
                            setWarningPacketModalVisible(true);
                        } else {
                            vmDiskChangeBackup(formId, data);
                        }
                    }} disabled={!isActiveVM(vm.state)}
                    disabledText={blockedButtonTooltips.cvds[vm.state]}/>
                <Modal isModalShowed={isBackupModalShowed}
                    component={<BackupModal awaitingPacket={awaitingPacket}
                        onAccept={() => vmDiskChangeBackup(formId, {
                            vmId: vm.id,
                            isBackupEnabled: vm.disk.is_backup_enabled
                        }, () => setBackupModalVisible(false))} onClose={() => setBackupModalVisible(false)}/>}
                        onClose={() => setBackupModalVisible(false)} />
                <Modal isModalShowed={isWarningPacketModalShowed}
                    component={<WarningCancelPacketModal
                        inProgress={inProgress}
                        warningText='отмене заказа годового пакета'
                        mainText='У вас заказан годовой пакет для ВМ без резервного копирования.
                            Включение опции приведет к'
                        onAccept={() => vmDiskChangeBackup(formId, {
                            vmId: vm.id,
                            isBackupEnabled: vm.disk.is_backup_enabled
                        }, setWarningPacketModalVisible(false))}
                        onClose={() => setWarningPacketModalVisible(false)}/>}
                    onClose={() => setWarningPacketModalVisible(false)} />
            </View>
        </View>
        {vm.volumes.length !== 0 && <View style={styles.volumesBlock}>
            <Text style={styles.volumesBlockTitle}>Дополнительные диски</Text>
            {vm.volumes.map((volume, index) => <View style={styles.volumeBlock}
                key={`additional-volume-${index}`}>
                <Image source={volume.is_backup_enabled ? backupsIcon : backupsDisabledIcon}
                    style={styles.backupIcon}/>
                <VolumeFill
                    id={volume.uid}
                    size={volume.size}
                    isLoaded={vm.volumesUsedSize != null}
                    isLoading={vm.volumesUsedSizeIsLoading}
                    isError={vm.volumesUsedSizeIsError}
                    data={vm.volumesUsedSize && vm.volumesUsedSize[volume.uid]}
                    percentThreshold={11}
                    name={volume.name || volume.uid}
                />
            </View>)}
        </View>}
    </View>
};

VolumesBlock.propTypes = {
    vm: PropTypes.shape({}),
    inProgress: PropTypes.bool,
    awaitingPacket: PropTypes.bool,
    vmDiskChangeBackup: PropTypes.func
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    inProgress: state.cvds.backupSwitchInProgress,
    awaitingPacket: (state.cvdsFinanceInfo.calculation == null
        || state.cvdsFinanceInfo.calculation.packet == null) ? false
        : state.cvdsFinanceInfo.calculation.packet.is_awaiting
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    vmDiskChangeBackup
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 30
    },
    titleBlock: {
        flex: 1,
        paddingLeft: 16,
        paddingRight: 20,
        marginBottom: 16,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    title: {
        color: '#455562',
        fontSize: 14,
        fontWeight: '600',
        lineHeight: 18
    },
    icon: {
        height: 12.55,
        width: 7
    },
    diskBlock: {
        flex: 1,
        flexDirection: 'row',
        paddingLeft: 16,
        paddingTop: 20,
        paddingBottom: 23,
        paddingRight: 20,
        backgroundColor: 'rgba(79, 184, 144, 0.08)'
    },
    diskIcon: {
        height: 24,
        width: 21,
        marginRight: 14
    },
    backupBlock: {
        flex: 1,
        flexDirection: 'row',
        paddingLeft: 16,
        paddingTop: 20,
        paddingBottom: 18,
        paddingRight: 20,
        backgroundColor: '#ffffff'
    },
    backupTextBlock: {
        flex: 1
    },
    backupText: {
        color: '#455562',
        fontSize: 11,
        lineHeight: 13
    },
    backupTitle: {
        marginBottom: 3,
        color: '#455562',
        fontSize: 12,
        fontWeight: '600',
        lineHeight: 14
    },
    backupIcon: {
        height: 19.35,
        width: 24,
        marginRight: 12
    },
    volumesBlock: {
        borderTopWidth: 1,
        borderTopColor: 'rgba(69, 85, 98, 0.3)',
        borderStyle: 'dashed',
        paddingLeft: 16,
        paddingTop: 17,
        paddingBottom: 25,
        paddingRight: 20,
        backgroundColor: '#ffffff'
    },
    volumesBlockTitle: {
        color: '#455562',
        fontSize: 14,
        fontWeight: '600',
        lineHeight: 18
    },
    volumeBlock: {
        flex: 1,
        marginTop: 17,
        flexDirection: 'row',
        alignItems: 'flex-end'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(VolumesBlock);