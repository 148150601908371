import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';

import Modal from '../General/Modal/Modal';
import ContainerDelete from './HostingModal/ContainerDelete';
import ContainerRename from './HostingModal/ContainerRename';
import { DISABLED_BY_SERVICE } from '../../constants/general';
import { addCustomNotification } from '../../actions/notifications/actions';
import ContainerSSHChangePassword from './HostingModal/ContainerSSHChangePassword';
import {
    editIcon,
    trashIcon,
    editBlueIcon,
    returnIcon,
    trashDisabledIcon,
    passwordBlueIcon,
    passwordGreyIcon,
    returnDisabledIcon,
    transferIcon,
    transferDisabledIcon,
} from '../../icons';

function hasPacket(container, calculationData) {
    const containerLogin = container ? container.login : null;
    if (
        containerLogin
        && calculationData
        && calculationData[containerLogin]
        && calculationData[containerLogin].packet
    ) {
        return true;
    }
    return false;
}

function formatDate(date) {
    return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY');
}

function ContainerActions(props) {
    const { container, calculationData, addCustomNotification } = props;
    const isEnabled = container && container.enabled;
    const navigation = useNavigation();
    const enabledTextModifier = isEnabled
        ? [styles.actionText, styles.blueText]
        : [styles.actionDisabledText];
    const [isModalChangePassword, setModalChangePasswordVisible] = React.useState(false);
    const [isModalDeleteContainer, setModalDeleteContainer] = React.useState(false);
    const [isModalRenameContainer, setModalRenameContainer] = React.useState(false);
    const canDelete = !hasPacket(container, calculationData) || container.is_in_transfer;
    return <View style={styles.wrapper}>
        <View style={styles.actions}>
            <TouchableOpacity style={isEnabled ? [styles.action] : [styles.actionDisabled]}
                onPress={() => {
                    if (isEnabled) {
                        setModalChangePasswordVisible(true);
                    }
                }}>
                <Image
                    source={isEnabled ? passwordBlueIcon : passwordGreyIcon}
                    style={styles.icon}
                />
                <Text style={enabledTextModifier}>Сменить пароль SSH-доступа</Text>
            </TouchableOpacity>
            <TouchableOpacity style={isEnabled && container.state !== DISABLED_BY_SERVICE
                ? [styles.action] : [styles.actionDisabled]}
            onPress={() => {
                if (isEnabled && container.state !== DISABLED_BY_SERVICE) {
                    navigation.navigate('HostingBackupResources', { containerId: container.id });
                }
            }}>
                <Image source={isEnabled && container.state !== DISABLED_BY_SERVICE
                    ? returnIcon : returnDisabledIcon} style={styles.returnIcon} />
                <Text style={enabledTextModifier}>Восстановить содержимое</Text>
            </TouchableOpacity>
            <TouchableOpacity style={isEnabled ? [styles.action] : [styles.actionDisabled]}
                onPress={() => {
                    if (isEnabled) {
                        setModalRenameContainer(true);
                    }
                }}>
                <Image source={isEnabled ? editBlueIcon : editIcon} style={styles.icon} />
                <Text style={enabledTextModifier}>Переименовать контейнер</Text>
            </TouchableOpacity>
            <TouchableOpacity style={isEnabled ? [styles.action] : [styles.actionDisabled]}
                onPress={() => {
                    if (isEnabled) {
                        navigation.navigate('Transfer', {
                            id: `${container.id}`,
                            service: 'containers'
                        });
                    }
                }}>
                <Image
                    source={isEnabled ? transferIcon : transferDisabledIcon}
                    style={styles.icon}
                />
                <Text style={enabledTextModifier}>Передать ресурс</Text>
            </TouchableOpacity>
            <TouchableOpacity
                style={canDelete
                    ? [styles.action, { marginTop: 10 }]
                    : [styles.actionDisabled, { marginTop: 10 }]
                }
                onPress={() => {
                    if (canDelete) {
                        setModalDeleteContainer(true);
                    } else {
                        addCustomNotification(container.is_in_transfer
                            ? 'Контейнер находится в процессе передачи другому пользователю. Удаление контейнера невозможно.'
                            : `Для контейнера подключен годовой пакет до ${formatDate(calculationData[container.login].packet.paidTill)}. Удаление контейнера невозможно.`);
                    }
                }}>
                <Image
                    source={canDelete ? trashIcon : trashDisabledIcon}
                    style={[styles.icon, { height: 17, width: 16 }]}
                />
                <Text
                    style={canDelete
                        ? [styles.actionText, styles.delete]
                        : [styles.actionDisabledText]}
                >
                    Удалить контейнер
                </Text>
            </TouchableOpacity>
        </View>
        <Modal isModalShowed={isModalChangePassword}
            component={<ContainerSSHChangePassword
                containerId={container ? container.id : null}
                onClose={() => setModalChangePasswordVisible(false)}/>}
            onClose={() => setModalChangePasswordVisible(false)} />
        <Modal isModalShowed={isModalDeleteContainer}
            component={<ContainerDelete
                containerName={container ? container.name : null}
                containerId={container ? container.id : null}
                onClose={() => setModalDeleteContainer(false)}/>}
            onClose={() => setModalDeleteContainer(false)} />
        <Modal isModalShowed={isModalRenameContainer}
            component={<ContainerRename
                containerId={container ? container.id : null}
                onClose={() => setModalRenameContainer(false)}/>}
            onClose={() => setModalRenameContainer(false)} />
    </View>;
}

ContainerActions.propTypes = {
    container: PropTypes.shape({
        login: PropTypes.string
    }),
    calculationData: PropTypes.shape({}),
    addCustomNotification: PropTypes.func,
};

const mapStateToProps = (state) => ({
    container: state.hosting.container,
    calculationData: state.hosting.calculationData
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ addCustomNotification }, dispatch);

const styles = StyleSheet.create({
    actions: {
        flex: 1,
        flexDirection: 'colume',
        alignItems: 'center',
        justifyContent: 'start',
    },
    action: {
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 20,
        paddingBottom: 23,
        paddingLeft: 20,
        paddingRight: 20,
        borderBottomColor: '#E4E4E4',
        borderBottomWidth: 1,
        backgroundColor: '#FFFFFF',
    },
    actionDisabled: {
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 20,
        paddingBottom: 23,
        paddingRight: 20,
        backgroundColor: 'rgba(136, 136, 136, 0.2)',
        paddingLeft: 20,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(69, 85, 98, 0.2)'
    },
    actionText: {
        fontSize: 14,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 14,
    },
    actionDisabledText: {
        fontSize: 14,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 14,
        color: '#455562',
    },
    blueText: {
        color: '#0F79D5',
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 18,
    },
    returnIcon: {
        width: 16,
        height: 16,
        marginRight: 18,
    },
    delete: {
        color: '#D51010',
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerActions);