import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, StyleSheet } from 'react-native';

import { createPacket } from '../../../actions/mail/actions';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'DeleteAliasModalForm';

function OrderPacketModal(props) {
    const {
        limit,
        onClose,
    } = props;
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);
    const { orderPacketInProgress } = useSelector((state) => state.mail);

    return <View style={styles.wrapper}>
        <Text style={styles.title}>Подтвердите заказ пакета на {limit} ГБ</Text>
        <View>
            <View style={styles.buttonBlock}>
                <AcceptButton
                    onSubmit={() => dispatch(
                        createPacket(formId, limit, () => onClose()),
                    )}
                    title='Заказать'
                    inProgress={orderPacketInProgress}/>
                <CancelButton onClose={onClose} disabled={orderPacketInProgress} />
            </View>
        </View>
    </View>;
}

OrderPacketModal.propTypes = {
    onClose: PropTypes.func,
    limit: PropTypes.number,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 18,
        lineHeight: 20,
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
    },
});

export default OrderPacketModal;
