import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';

import { addCustomNotification } from '../../../actions/notifications/actions';

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
    },
    title: {
        flex: 1,
        color: '#455562',
        fontSize: 14,
        fontWeight: '500',
    },
    switchBlockActive: {
        flex: 1,
        width: 38,
        height: 21,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 1,
        paddingRight: 1,
        borderRadius: 11,
        cursor: 'pointer',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#0f79d5',
    },
    switchBlockInActive: {
        flex: 1,
        width: 38,
        height: 21,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 1.1,
        paddingRight: 1.1,
        borderRadius: 11,
        cursor: 'pointer',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#a1aab180',
    },
    spring: {
        flex: 1,
    },
    circleStyle: {
        width: 18,
        height: 18,
        borderRadius: 50,
        backgroundColor: '#FFFFFF',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
})

function CustomSwitch(props) {
    const {
        label,
        onChange,
        isEnable,
        disabled = false,
        disabledText,
        addCustomNotification,
        additionalLabelStyles = [],
    } = props;
    const [value, setOnChanged] = React.useState(isEnable);
    React.useEffect(() => {
        setOnChanged(isEnable);
    }, [isEnable]);
    return <View style={styles.wrapper}>
        {label && <Text style={[styles.title, ...additionalLabelStyles]}>{label}</Text>}
        <TouchableOpacity style={[styles.switchWrapper, { opacity: disabled ? 0.5 : 1 }]}
            onPress={() => {
                if (!disabled) {
                    onChange(!value);
                } else if (disabledText != null && disabledText.length !== 0) {
                    addCustomNotification(disabledText);
                }
            }}>
            <View style={value ? styles.switchBlockActive : styles.switchBlockInActive}>
                {value && <View style={styles.spring}></View>}
                <View style={styles.circleStyle}></View>
            </View>
        </TouchableOpacity>
    </View>;
}

CustomSwitch.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onChange: PropTypes.func,
    isEnable: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledText: PropTypes.string,
    addCustomNotification: PropTypes.func,
    additionalLabelStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ])),
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({ addCustomNotification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomSwitch);
