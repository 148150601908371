import React from 'react';
import PropTypes from 'prop-types';
import {
    StyleSheet, View, TouchableOpacity, Image
} from 'react-native';
import { plusIcon, minusIcon } from '../../../icons';

const styles = StyleSheet.create({
    wrapper: {
        borderColor: '#0F79D5',
        borderWidth: 1,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        height: '100%'
    },
    value: {
        color: '#455562',
        fontFamily: 'Roboto',
        fontSize: 12,
        justifyContent: 'center',
        paddingRight: 7,
        paddingLeft: 7,
    },
    signContainer: {
        backgroundColor: '#EDF5FC',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 5.5,
        paddingLeft: 5.5,
    },
    minusSignDisabled: {
        opacity: 0.3,
    },
    minusSignContainer: {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
    },
    minusIcon: {
        width: 9,
        height: 1,
    },
    plusSignDisabled: {
        opacity: 0.3,
    },
    plusSignContainer: {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
    },
    plusIcon: {
        width: 9,
        height: 9,
    },
});

function InputHorizontalNumber(props) {
    const {
        min, max, value, onChange
    } = props;

    const minusSignContainerStyles = [
        styles.signContainer,
        styles.minusSignContainer,
    ];
    const plusSignContainerStyles = [
        styles.signContainer,
        styles.plusSignContainer,
    ];

    const canSubstract = value - 1 >= min;
    const canAdd = value + 1 <= max;

    if (!canSubstract) {
        minusSignContainerStyles.push(styles.minusSignDisabled);
    }

    if (!canAdd) {
        plusSignContainerStyles.push(styles.plusSignDisabled);
    }

    return (
        <View style={styles.wrapper}>
            <View style={styles.container}>
                <TouchableOpacity
                    onPress={() => {
                        if (canSubstract) {
                            onChange(value - 1);
                        }
                    }}
                >
                    <View style={minusSignContainerStyles}>
                        <Image source={minusIcon} style={styles.minusIcon} />
                    </View>
                </TouchableOpacity>
                <View style={styles.value}>{value}</View>
                <TouchableOpacity
                    onPress={() => {
                        if (canAdd) {
                            onChange(value + 1);
                        }
                    }}
                >
                    <View style={plusSignContainerStyles}>
                        <Image source={plusIcon} style={styles.plusIcon} />
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    );
}

InputHorizontalNumber.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func,
};

export default InputHorizontalNumber;
