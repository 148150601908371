import * as dayjs from 'dayjs';

import {
    PARTNERSHIP_DATA_IN_PROGRESS,
    PARTNERSHIP_DATA_WITH_ERRORS,
    PARTNERSHIP_DATA_SUCCESSFULLY,
    PARTNERSHIP_UPDATE_SUCCESSFULLY,
    PARTNERSHIP_UPDATE_IN_PROGRESS,
    PARTNERSHIP_UPDATE_WITH_ERRORS,
    PARTNERSHIP_CREATE_SUCCESSFULLY,
    PARTNERSHIP_CREATE_IN_PROGRESS,
    PARTNERSHIP_CREATE_WITH_ERRORS,
    WITHDRAWAL_SUCCESSFULLY,
    WITHDRAWAL_IN_PROGRESS,
    WITHDRAWAL_WITH_ERRORS,
    PARTNERSHIP_CLIENTS_IN_PROGRESS,
    PARTNERSHIP_CLIENTS_SUCCESSFULLY,
    PARTNERSHIP_CLIENTS_WITH_ERRORS,
    REPORT_MONTHS_SUCCESSFULLY,
    PARTNERSHIP_CHARGES_MONTH_GROUP_IN_PROGRESS,
    PARTNERSHIP_CHARGES_MONTH_GROUP_SUCCESSFULLY,
    PARTNERSHIP_CHARGES_MONTH_GROUP_WITH_ERRORS,
    PARTNERSHIP_CHARGES_MONTH_CHANGE_DATE,
    MONTHS_FOR_ACCRUALS_IN_PROGRESS,
    MONTHS_FOR_ACCRUALS_SUCCESSFULLY,
    MONTHS_FOR_ACCRUALS_WITH_ERRORS,
    PARTNERSHIP_UPDATE_BALANCE,
    PARTNERSHIP_UPDATE_UNTREATED_WITHDRAWAL,
    PARTNERSHIP_ADD_CLIENT,
    PARTNERSHIP_REGISTER_USER_IN_PROGRESS,
    PARTNERSHIP_REGISTER_USER_SUCCESSFULLY,
    PARTNERSHIP_REGISTER_USER_WITH_ERRORS,
} from '../../actions/partnership/actionTypes';

export default () => {
    const defaultState = {
        partnership: null,
        partnershipInProgress: false,
        registerUserInProgress: false,
        monthsForPartnerReport: null,
        accrualsCurrentDate: dayjs().format('YYYY-MM-DD'),
        accruals: null,
        accrualsInProgress: false,
        accrualsMonths: null,
        accrualsMonthsInProgress: false,
        clients: null,
        clientsInProgress: false,
        editRequisitesInProgress: false,
        withdrawalInProgress: false,
        createPartnershipInProgress: false,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case PARTNERSHIP_DATA_SUCCESSFULLY: {
            return {
                ...state,
                partnership: action.data,
                partnershipInProgress: false,
            };
        }
        case PARTNERSHIP_UPDATE_UNTREATED_WITHDRAWAL: {
            return {
                ...state,
                partnership: {
                    ...state.partnership,
                    has_untreated_withdrawal: action.data.has_untreated_withdrawal,
                }
            };
        }
        case PARTNERSHIP_UPDATE_BALANCE: {
            return {
                ...state,
                partnership: {
                    ...state.partnership,
                    balance: action.data.balance
                }
            };
        }
        case PARTNERSHIP_DATA_IN_PROGRESS: {
            return {
                ...state,
                partnership: action.data,
                partnershipInProgress: false,
            };
        }
        case PARTNERSHIP_DATA_WITH_ERRORS: {
            return {
                ...state,
                partnershipInProgress: false,
            };
        }
        case PARTNERSHIP_CHARGES_MONTH_GROUP_SUCCESSFULLY: {
            return {
                ...state,
                accruals: action.data,
                accrualsInProgress: false,
            };
        }
        case PARTNERSHIP_CHARGES_MONTH_GROUP_IN_PROGRESS: {
            return {
                ...state,
                accrualsInProgress: true,
            };
        }
        case PARTNERSHIP_CHARGES_MONTH_GROUP_WITH_ERRORS: {
            return {
                ...state,
                accrualsInProgress: false,
            };
        }
        case PARTNERSHIP_CHARGES_MONTH_CHANGE_DATE: {
            return {
                ...state,
                accrualsCurrentDate: action.data.date
            };
        }
        case MONTHS_FOR_ACCRUALS_IN_PROGRESS: {
            return {
                ...state,
                accrualsMonthsInProgress: true
            };
        }
        case MONTHS_FOR_ACCRUALS_SUCCESSFULLY: {
            const currentMonth = dayjs().format('YYYY-MM');
            const months = [...action.data.entities];
            if (months.indexOf(currentMonth) === -1) {
                months.push(currentMonth);
            }
            months.sort((a, b) => {
                const normalizedA = dayjs(a);
                const normalizedB = dayjs(b);
                if (normalizedA > normalizedB) {
                    return 1;
                }
                if (normalizedA < normalizedB) {
                    return -1;
                }
                return 0;
            });
            return {
                ...state,
                accrualsMonthsInProgress: false,
                accrualsMonths: months,
            };
        }
        case MONTHS_FOR_ACCRUALS_WITH_ERRORS: {
            return {
                ...state,
                accrualsMonthsInProgress: false
            };
        }
        case PARTNERSHIP_ADD_CLIENT: {
            if (state.clients) {
                const client = action.data;
                const isClientAdded = state.clients.entities.find((e) => e.id === client.id);
                let { entities, count } = state.clients;
                if (!isClientAdded) {
                    entities = [client, ...entities];
                    count = state.clients.count + 1;
                } else {
                    entities = entities.map((e) => (e.id === client.id ? client : e));
                }
                return {
                    ...state,
                    clients: { count, entities }
                };
            }
            return state;
        }
        case PARTNERSHIP_CLIENTS_SUCCESSFULLY: {
            if (state.clients == null) {
                return {
                    ...state,
                    clients: action.data,
                    clientsInProgress: false
                };
            }
            if (action.data.refresh) {
                return {
                    ...state,
                    clients: {
                        count: action.data.count,
                        entities: [
                            ...action.data.entities
                        ]
                    },
                    clientsInProgress: false,
                };
            }
            return {
                ...state,
                clients: {
                    count: action.data.count,
                    entities: [
                        ...state.clients.entities,
                        ...action.data.entities
                    ]
                },
                clientsInProgress: false,
            };
        }
        case PARTNERSHIP_CLIENTS_IN_PROGRESS: {
            return {
                ...state,
                clientsInProgress: true,
            };
        }
        case PARTNERSHIP_CLIENTS_WITH_ERRORS: {
            return {
                ...state,
                clientsInProgress: false,
            };
        }
        case PARTNERSHIP_CREATE_IN_PROGRESS: {
            return {
                ...state,
                createPartnershipInProgress: true,
            };
        }
        case PARTNERSHIP_CREATE_WITH_ERRORS: {
            return {
                ...state,
                createPartnershipInProgress: false,
            };
        }
        case PARTNERSHIP_CREATE_SUCCESSFULLY: {
            return {
                ...state,
                partnership: action.data,
                createPartnershipInProgress: false,
            };
        }
        case REPORT_MONTHS_SUCCESSFULLY: {
            return {
                ...state,
                monthsForPartnerReport: action.data.entities
            };
        }
        case PARTNERSHIP_UPDATE_IN_PROGRESS: {
            return {
                ...state,
                editRequisitesInProgress: true,
            };
        }
        case PARTNERSHIP_UPDATE_WITH_ERRORS: {
            return {
                ...state,
                editRequisitesInProgress: false
            };
        }
        case PARTNERSHIP_UPDATE_SUCCESSFULLY: {
            return {
                ...state,
                partnership: action.data,
                editRequisitesInProgress: false,
            };
        }
        case WITHDRAWAL_IN_PROGRESS: {
            return {
                ...state,
                withdrawalInProgress: true,
            };
        }
        case WITHDRAWAL_WITH_ERRORS: {
            return {
                ...state,
                withdrawalInProgress: false
            };
        }
        case WITHDRAWAL_SUCCESSFULLY: {
            const { data } = action;
            if (!data.is_completed) {
                return state;
            }
            const partnership = data.partner;
            partnership.balance -= data.amount;
            return {
                ...state,
                partnership,
                withdrawalInProgress: false,
            };
        }
        case PARTNERSHIP_REGISTER_USER_IN_PROGRESS: {
            return {
                ...state,
                registerUserInProgress: true
            };
        }
        case PARTNERSHIP_REGISTER_USER_SUCCESSFULLY: {
            return {
                ...state,
                registerUserInProgress: false
            };
        }
        case PARTNERSHIP_REGISTER_USER_WITH_ERRORS: {
            return {
                ...state,
                registerUserInProgress: false
            };
        }
        default:
            return state;
        }
    };
};
