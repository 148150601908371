import cvdsTariffData from '../../data/cvds/tariffs.json';

export const disksIds = ['fast', 'normal', 'slow'];

export const tariffsByCategory = [
    {
        category: 'small',
        categoryName: 'Стандартные',
    },
    {
        category: 'big',
        categoryName: 'Премиум',
    },
    {
        category: 'bitrix',
        categoryName: 'Битрикс',
    },
    {
        category: 'start',
        categoryName: 'Стартовые',
    },
    {
        category: 'ultra',
        categoryName: 'Ультра',
    },
];

export const tariffsDict = {
    small: cvdsTariffData.filter(
        (tariff) => tariff.type === 'small' && tariff.enabled
    ),
    big: cvdsTariffData.filter(
        (tariff) => tariff.type === 'big' && tariff.enabled
    ),
    bitrix: cvdsTariffData.filter(
        (tariff) => tariff.type === 'bitrix' && tariff.enabled
    ),
    start: cvdsTariffData.filter(
        (tariff) => tariff.type === 'start' && tariff.enabled
    ),
    ultra: cvdsTariffData.filter(
        (tariff) => tariff.type === 'ultra' && tariff.enabled
    ),
};

export const IP_PUBLIC_POOL = 'Public';
export const IP_PROTECTED_POOL = 'Protected';
export const IP_PRIVATE_POOL = 'Private';

export const IPv4 = 4;
export const IPv6 = 6;
