import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';

import Modal from '../../General/Modal/Modal';
import { arrowDownIcon } from '../../../icons/index';
import { cmsList } from '../../../constants/hosting';

function CmsBlock({cms, onChange, currentCms}) {
    const defaultVersion = cms.versions.find(v => v.default === true);
    const [version, setChangeVersion] = React.useState(defaultVersion);
    const [openSubMenu, setModalOpenSubMenu] = React.useState(false);
    const isCurrenCms = cms.versions.find(v => v.type === currentCms);

    const modifier = isCurrenCms ? [styles.currentItem, styles.item] : [styles.item, styles.notCurrentItem];
    return <TouchableOpacity
        style={modifier}
        onPress={() => {
            if (cms.versions.length > 1) {
                setModalOpenSubMenu(!openSubMenu);
            } else {
                onChange(version);
            }
        }}>
        <Image source={cms.icon} style={styles.icon}/>
        <View style={styles.info}>
            <Text style={isCurrenCms ? [styles.itemText, styles.blueText] : [styles.itemText, styles.grayText]}>{cms.name}</Text>
            {version.name && <Text style={styles.hint}>{version.name}</Text>}
        </View>
        {cms.versions.length > 1 && <Image source={arrowDownIcon} style={styles.iconArrow}/>}
        <Modal isModalShowed={openSubMenu}
            component={<View>
                <Text style={styles.versionsTitle}>РЕДАКЦИИ</Text>
                {cms.versions.map((version, j) => <TouchableOpacity key={`item-${j}`}
                    onPress={() => {
                        onChange(version);
                        setChangeVersion(version);
                        setModalOpenSubMenu(false);
                    }}>
                    <Text style={styles.version}>{version.name}</Text>
                </TouchableOpacity>)}
            </View>}
            onClose={() => setModalOpenSubMenu(false)} />
    </TouchableOpacity>;
}

function CmsListBlock(props) {
    const {cms, capabilities, changeCMS} = props;
    const filteredCms = cmsList.map((item) => {
        const filteredVersions = item.versions.filter(version => capabilities.applications.includes(version.type));
        return {
            ...item,
            versions: filteredVersions
        };
    }).filter(item => item.versions.length > 0);

    return <View style={styles.wrapper}>
    {filteredCms.map((item, i) => <CmsBlock cms={item}
        onChange={(version) => changeCMS(version.type)}
        currentCms={cms} key={`item-${i}`}/>)}
    </View>;
}

CmsListBlock.propTypes = {
    container: PropTypes.shape({}),
    containers: PropTypes.shape({}),
    capabilities: PropTypes.shape({}),
};

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 20,
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
    },
    blueText: {
        color: '#0f79d5',
    },
    grayText: {
        color: '#455562',
    },
    iconArrow: {
        width: 13,
        height: 7,
        marginRight: 20,
    },
    icon: {
        width: 32,
        height: 32,
        marginRight: 5,
    },
    currentItem: {
        borderColor: '#0F79D5',
        backgroundColor: '#F3F8FD',
        borderLeftWidth: 6,
        paddingLeft: 8,
    },
    notCurrentItem: {
        paddingLeft: 13,
        borderColor: 'rgba(69, 85, 98, 0.248498)',
    },
    item: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        paddingTop: 9,
        paddingBottom: 7,
        marginBottom: 8,
        borderRadius: 4,
    },
    itemText: {
        fontWeight: '500',
        fontSize: 12,
        lineHeight: 14,
    },
    title: {
        fontWeight: '500',
        fontSize: 11,
        lineHeight: 13,
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        color: '#586A7B',
        opacity: '0.75',
        paddingBottom: 12,
        marginTop: 20,
    },
    hint: {
        fontSize: 10,
        lineHeight: 18,
        color: '#4E5F6E',
    },
    info: {
        flex: 1,
        flexDirection: 'column',
    },
    version: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 10,
        fontSize: 13,
        fontWeight: '500',
        borderTopColor: 'rgba(69, 85, 98, 0.2)',
        borderTopWidth: 0.5,
        color: '#455562',
    },
    versionsTitle: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 20,
        color: '#455562',
    }
})

export default CmsListBlock;