export const FETCH_MONITORING_IN_PROGRESS = 'FETCH_MONITORING_IN_PROGRESS';
export const FETCH_MONITORING_SUCCESSFULLY = 'FETCH_MONITORING_SUCCESSFULLY';
export const FETCH_MONITORING_WITH_ERRORS = 'FETCH_MONITORING_WITH_ERRORS';

export const WS_MONITORING_EVENT_UPDATE = 'WS_MONITORING_EVENT_UPDATE';

export const FETCH_MONITORING_BY_SERVICE_IN_PROGRESS = 'FETCH_MONITORING_BY_SERVICE_IN_PROGRESS';
export const FETCH_MONITORING_BY_SERVICE_SUCCESSFULLY = 'FETCH_MONITORING_BY_SERVICE_SUCCESSFULLY';
export const FETCH_MONITORING_BY_SERVICE_WITH_ERRORS = 'FETCH_MONITORING_BY_SERVICE_WITH_ERRORS';

export const INIT_MONITORING_EVENT = 'INIT_MONITORING_EVENT';
export const INIT_MONITORING_SERVICE_EVENT = 'INIT_MONITORING_SERVICE_EVENT';
