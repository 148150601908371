import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text, ScrollView } from 'react-native';

import State from './StateBlock';
import SSLBlock from './SSLBLock';
import AliasesBlock from './AliasesBlock';
import SSLFreeBlock from './SSLFreeBlock';
import { normalizeCharsetMap } from '../../../constants/hosting';

function VirtualhostCharset({ charset }) {
    return <View style={styles.itemBlock}>
        <Text style={styles.charsetText}>Кодировка</Text>
        <Text style={styles.charsetType}>{normalizeCharsetMap[charset].label}</Text>
    </View>;
}

function Virtualhost(props) {
    const {
        virtualhost,
        virtualhostInProgress
    } = props;

    return <View style={styles.wrapperPage}>
        {!virtualhostInProgress && virtualhost && <ScrollView>
            <State virtualhost={virtualhost} />
            <VirtualhostCharset charset={virtualhost.charset} />
            <AliasesBlock aliases={virtualhost.aliases}
                enabled={virtualhost.enabled}
                virtualhostName={virtualhost.name}
                mainAlias={virtualhost.main_alias}
                virtualhostId={virtualhost.id}/>
            {virtualhost.certificate_id === 0 && <SSLFreeBlock enabled={virtualhost.enabled}
                virtualhostId={virtualhost.id}/>}
            {virtualhost.certificate_id != 0 && <SSLBlock domains={virtualhost.aliases}
                virtualhostEnabled={virtualhost.enabled}
                virtualhostId={virtualhost.id}
                isForceHTTP={virtualhost.force_https}
                certificateId={virtualhost.certificate_id}/>}
        </ScrollView>}
        {virtualhostInProgress && virtualhost == null && <View style={styles.loading}></View>}
    </View>;
}

Virtualhost.propTypes = {
    virtualhost: PropTypes.shape({}),
    virtualhostInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    virtualhost: state.hosting.virtualhost,
    virtualhostInProgress: state.hosting.virtualhostInProgress,
});

const mapDispatchToProps = () => ({});

const styles = StyleSheet.create({
    wrapperPage: {
        height: '100%',
    },
    loading: {
        height: 107,
        backgroundColor: '#b0b0b0',
    },
    itemBlock: {
        width: '100%',
        flex: 1,
        maxHeight: 60,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        backgroundColor: '#ffffff',
        paddingTop: 21,
        paddingBottom: 21,
        paddingLeft: 18,
        paddingRight: 18,
        marginRight: 10,
    },
    charsetText: {
        flex: 1,
        color: '#455562',
        fontSize: 14,
        letterSpacing: 0,
        lineHeight: 18,
    },
    charsetType: {
        color: '#A1AAB1',
        fontSize: 12,
        letterSpacing: 0,
        lineHeight: 14,
        textAlign: 'right',
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Virtualhost);