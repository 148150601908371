import { flushErrors, formErrors } from '../forms/actions';

import {
    CERTIFICATE_CREATE,
    CERTIFICATE_DETAIL,
    CERTIFICATE_DETAIL_IN_PROGRESS,
    CERTIFICATE_DETAIL_WITH_ERRORS,
    FIND_CERTIFICATES_SUCCESSFULLY,
    FIND_CERTIFICATES_IN_PROGRESS,
    FIND_CERTIFICATES_WITH_ERRORS,
    INIT_FIND_CERTIFICATES,
    FETCH_CERTIFICATES_IN_PROGRESS,
    FETCH_CERTIFICATES_SUCCESSFULLY,
    FETCH_CERTIFICATES_WITH_ERRORS,
    UPDATE_CERTIFICATE_SUCCESSFULLY,
    UPDATE_CERTIFICATE_IN_PROGRESS,
    UPDATE_CERTIFICATE_WITH_ERRORS,
    DELETE_CERTIFICATE_SUCCESSFULLY,
    ORDER_CERTIFICATE_SUCCESSFULLY,
    ORDER_CERTIFICATE_IN_PROGRESS,
    ORDER_CERTIFICATE_WITH_ERRORS,
    FETCH_MORE_CERTIFICATES_IN_PROGRESS,
    FETCH_MORE_CERTIFICATES_SUCCESSFULLY,
    FETCH_MORE_CERTIFICATES_WITH_ERRORS,
    INIT_CERTIFICATE,
} from './actionTypes';
import { isFunction } from '../../utils/general';
import CertificateResource from '../../gateway/resources/certificates';

export const certificatesFind = domains => async (dispatch, getState, { gateway }) => {
    dispatch({ type: FIND_CERTIFICATES_IN_PROGRESS });
    const resource = new CertificateResource(gateway);
    const response = await resource.find(domains);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FIND_CERTIFICATES_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: FIND_CERTIFICATES_WITH_ERRORS,
            data
        });
    }
};

export const certificatesCreate = (formId, certificate, privateKey, callback) => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    const resource = new CertificateResource(gateway);
    const response = await resource.create(certificate, privateKey);
    const data = await response.json();
    if (response.ok) {
        dispatch({ type: CERTIFICATE_CREATE, data });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch(formErrors(formId, data.errors));
    }
};

export const certificateDetail = (certificateId, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: CERTIFICATE_DETAIL_IN_PROGRESS });
    const resource = new CertificateResource(gateway);
    const response = await resource.detail(certificateId);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            data,
            type: CERTIFICATE_DETAIL,
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({ type: CERTIFICATE_DETAIL_WITH_ERRORS });
    }
};

export const initCertificate = () => async (dispatch) => {
    dispatch({
        type: INIT_CERTIFICATE,
    });
};

export const initFindCertificates = () => async (dispatch) => {
    dispatch({
        type: INIT_FIND_CERTIFICATES,
    });
};

export const fetchCertificates = (
    limit
) => async (dispatch, getParams, { gateway }) => {
    dispatch({ type: FETCH_CERTIFICATES_IN_PROGRESS });
    const resource = new CertificateResource(gateway);
    const response = await resource.list(limit, 0);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_CERTIFICATES_SUCCESSFULLY,
            data,
        });
    } else {
        if ([403, 404].includes(response.status)) {
            dispatch({
                type: FETCH_CERTIFICATES_SUCCESSFULLY,
                data: {
                    entities: [],
                    count: 0,
                },
            });
            return;
        }
        dispatch({ type: FETCH_CERTIFICATES_WITH_ERRORS });
    }
};

export const fetchMoreCertificates = (
    formId, limit, offset
) => async (dispatch, _getState, { gateway }) => {
    dispatch({ type: FETCH_MORE_CERTIFICATES_IN_PROGRESS });
    const resource = new CertificateResource(gateway);
    const response = await resource.list(limit, offset);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_MORE_CERTIFICATES_SUCCESSFULLY,
            data,
        });
    } else {
        dispatch({ type: FETCH_MORE_CERTIFICATES_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const certificateProlong = (
    formId, certificateId, callback
) => async (dispatch, getParams, { gateway }) => {
    dispatch({ type: UPDATE_CERTIFICATE_IN_PROGRESS });
    const resource = new CertificateResource(gateway);
    const response = await resource.prolong(certificateId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: UPDATE_CERTIFICATE_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch(formErrors(formId, data.errors));
        dispatch({ type: UPDATE_CERTIFICATE_WITH_ERRORS });
    }
};

export const updateCertificate = (
    formId, certificateId, changedData, callback
) => async (dispatch, getParams, { gateway }) => {
    dispatch({ type: UPDATE_CERTIFICATE_IN_PROGRESS });
    const resource = new CertificateResource(gateway);
    const response = await resource.update(certificateId, changedData);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: UPDATE_CERTIFICATE_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: UPDATE_CERTIFICATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const deleteCertificate = (
    formId, id, callback
) => async (dispatch, getState, { gateway }) => {
    const resource = new CertificateResource(gateway);
    const response = await resource.delete(id);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: DELETE_CERTIFICATE_SUCCESSFULLY,
            data: {
                id,
            },
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch(formErrors(formId, data.errors));
    }
};

export const orderCertificate = (
    formId, requestData, callback
) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: ORDER_CERTIFICATE_IN_PROGRESS });
    const resource = new CertificateResource(gateway);
    const response = await resource.order(requestData);
    const data = await response.json();
    if (response.ok) {
        dispatch({ type: ORDER_CERTIFICATE_SUCCESSFULLY, data });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({ type: ORDER_CERTIFICATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const wsCertificateDelete = (data) => async (dispatch) => {
    dispatch({
        type: DELETE_CERTIFICATE_SUCCESSFULLY,
        data,
    });
};

export const wsCertificateCreate = (data) => async (dispatch) => {
    dispatch({
        type: ORDER_CERTIFICATE_SUCCESSFULLY,
        data,
    });
};

export const wsCertificateUpdate = (data) => async (dispatch) => {
    dispatch({
        type: UPDATE_CERTIFICATE_SUCCESSFULLY,
        data,
    });
};
