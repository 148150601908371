import React from 'react';
import * as dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
} from 'react-native';

import Spring from '../../General/Spring/Spring';
import Button from '../../General/Button/Button';
import { containerBackupRestore, dbmsBackupRestore } from '../../../actions/hosting/actions';

const formId = 'HostingBackupFormId';

function RestoreConfigurationStep() {
    const {
        currentContainer,
        restoreInProgress,
        currentMethod,
        currentDbms,
        currentVirtualhostObject,
        filesForArchive,
        restoreDate,
        restoreDbmsDate,
        currentDbmsMethod,
        newDbms
    } = useSelector(
        (state) => state.hostingRestoreFromBackup,
    );
    const dispatch = useDispatch();
    const navigation = useNavigation();

    return <View style={styles.wrapper}>
        <Text style={styles.title}>
            параметры восстановления
        </Text>
        {currentMethod && <View style={styles.infoBlock}>
            <Text style={styles.infoText}>
                Восстановление файлов сайта
                <Text style={styles.infoBoldText}>
                    {currentVirtualhostObject.name}
                </Text>
                в контейнере
                <Text style={styles.infoBoldText}>
                    «{currentContainer.name}» ({currentContainer.login}) на{' '}
                    {dayjs.utc(restoreDate).tz('Asia/Yekaterinburg').format(
                        'DD MMMM HH:mm',
                    )} (МСК+2){' '}
                </Text>
                {currentMethod === 'restoreFile' && 'в отдельный каталог'}
                {currentMethod === 'rewrite' && 'поверх существующих'}
            </Text>
        </View>}
        {restoreDbmsDate && <View style={currentMethod ? styles.dbmsInfoBlock : styles.infoBlock}>
            <Text style={currentMethod ? styles.dbmsInfoText : styles.infoText}>
                Восстановление базы данных
                <Text style={styles.infoBoldText}>
                    «{currentDbms.name}» на{' '}
                    {dayjs.utc(restoreDbmsDate).tz('Asia/Yekaterinburg').format(
                        'DD MMMM HH:mm',
                    )} (МСК+2){' '}
                </Text>
                {currentDbmsMethod === 'restoreToDbms' && newDbms
                    && <React.Fragment>
                        в
                        <Text style={styles.infoBoldText}>
                            {newDbms.name}
                        </Text>
                    </React.Fragment>}
                {currentDbmsMethod === 'rewrite' && 'поверх существующей'}
            </Text>
        </View>}
        <Spring />
        <Button
            onPress={() => {
                const restoreDbmsFunc = () => dispatch(
                    dbmsBackupRestore(
                        formId,
                        currentDbms.id,
                        {
                            date: restoreDbmsDate,
                            databaseId: newDbms == null ? currentDbms.id : newDbms.id,
                        },
                        () =>
                            navigation.navigate('HostingContainerVirtualhost', {
                                virtualhostId: currentVirtualhostObject.id,
                            }),
                    ),
                );
                if (currentMethod) {
                    dispatch(
                        containerBackupRestore(
                            formId,
                            currentContainer.id,
                            {
                                rewrite: currentMethod === 'rewrite',
                                files: filesForArchive,
                                date: restoreDate,
                            },
                            () => {
                                if (!restoreDbmsDate) {
                                    navigation.navigate('HostingContainerVirtualhost', {
                                        virtualhostId: currentVirtualhostObject.id
                                    });
                                } else {
                                    restoreDbmsFunc();
                                }
                            },
                        ),
                    );
                } else {
                    restoreDbmsFunc();
                }
            }}
            title="Начать восстановление"
            inProgress={restoreInProgress}
            additionalWrapperStyles={[{ marginLeft: 20, marginRight: 20, marginBottom: 30 }]}
            additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 10 }]}
            additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, fontWeight: '400' }]}
        />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
    },
    title: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginBottom: 10,
        marginLeft: 20,
    },
    infoBlock: {
        paddingBottom: 16,
        paddingTop: 16,
        paddingLeft: 23,
        paddingRight: 29,
        backgroundColor: '#ffffff'
    },
    dbmsInfoBlock: {
        paddingBottom: 16,
        paddingTop: 0,
        paddingLeft: 23,
        paddingRight: 29,
        backgroundColor: '#ffffff',
        borderTopColor: 'rgba(69, 85, 98, 0.25)',
        borderTopWidth: 2,
    },
    infoText: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 18,
    },
    dbmsInfoText: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 18,
        marginBottom: 8,
        marginTop: 16,
    },
    infoBoldText: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 18,
        fontWeight: 500,
        marginLeft: 3,
        marginRight: 3,
    }
});

export default RestoreConfigurationStep;
