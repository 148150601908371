const BaseResource = require('../resource');
const { makePaginateParams } = require('../../utils');

class ContainersResource extends BaseResource {
    async create(data) {
        return this.makeRequest('post', null, data);
    }

    async list(limit, offset) {
        return this.makeRequest('get', null, makePaginateParams(limit, offset));
    }

    async delete(containerId) {
        return this.makeRequest('delete', `${containerId}/`);
    }

    async listWithVirtualhost(limit, offset) {
        return this.makeRequest('get', 'with-virtualhosts/', makePaginateParams(limit, offset));
    }

    async detail(containerId) {
        return this.makeRequest('get', `${containerId}/`);
    }

    async virtualhosts(containerId, limit, offset) {
        return this.makeRequest('get', `${containerId}/virtualhosts/`, makePaginateParams(limit, offset));
    }

    async update(containerId, data) {
        return this.makeRequest('put', `${containerId}/`, data);
    }

    async limits(containerId) {
        return this.makeRequest('get', `${containerId}/limits/`);
    }

    async calculation(containerId) {
        return this.makeRequest('get', `${containerId}/calculation/`);
    }

    async rawCalculation(containerLogin, memory, disk, redis, memcached) {
        const data = {
            container: containerLogin,
            memory,
            disk,
        };
        if (redis != null && redis > 0) {
            data.redis = redis;
        }
        if (memcached != null && memcached > 0) {
            data.memcached = memcached;
        }
        return this.makeRequest('get', 'calculation/', data);
    }

    async packetCreate(containerId) {
        return this.makeRequest('post', `${containerId}/packet/create/`);
    }

    async packetUpdate(containerId, data) {
        return this.makeRequest('put', `${containerId}/packet/update/`, data);
    }

    async packetRefresh(containerId) {
        return this.makeRequest('post', `${containerId}/packet/refresh/`);
    }

    async setPassword(containerId, password) {
        return this.makeRequest('put', `${containerId}/set-password/`, { password });
    }

    async redisUpdate(containerId, memory) {
        return this.makeRequest('post', `${containerId}/redis/`, {
            memory_limit: memory
        });
    }

    async redisDelete(containerId) {
        return this.makeRequest('delete', `${containerId}/redis/`, null);
    }

    async memcacheUpdate(containerId, memory) {
        return this.makeRequest('post', `${containerId}/memcache/`, {
            memory_limit: memory
        });
    }

    async memcacheDelete(containerId) {
        return this.makeRequest('delete', `${containerId}/memcache/`, null);
    }

    async barChartCPU(containerId) {
        return this.makeRequest('get', `${containerId}/statistics/cpubars/`);
    }

    async barChartRAM(containerId) {
        return this.makeRequest('get', `${containerId}/statistics/rambars/`);
    }

    async capabilities(containerId) {
        return this.makeRequest('get', `${containerId}/capabilities/`);
    }

    async backupsList(containerId) {
        return this.makeRequest('get', `${containerId}/backups/`);
    }

    async backupRestore(containerId, { rewrite, files, date }) {
        return this.makeRequest(
            'post',
            `${containerId}/backups/${date}/restore/`,
            { rewrite, files },
        );
    }

    async getContainerCalculation(configuration) {
        return this.makeRequest('get', 'cost-calculation/', configuration);
    }
}

ContainersResource.resource = 'containers';
ContainersResource.service = 'hosting';

module.exports = ContainersResource;
