const BaseResource = require('../resource');
const { makePaginateParams } = require('../../utils');

class CVDSVMResource extends BaseResource {
    async list(limit, offset) {
        return this.makeRequest('get', null, { limit: limit || 100, offset: offset || 0 });
    }
    async create(data) {
        return this.makeRequest('post', null, data);
    }

    async rename(vmId, name) {
        return this.makeRequest('post', `${vmId}/change-name/`, { name });
    }

    async reload(vmId, data) {
        return this.makeRequest('post', `${vmId}/restart/`, { force: data });
    }

    async start(vmId) {
        return this.makeRequest('post', `${vmId}/start/`);
    }

    async stop(vmId) {
        return this.makeRequest('post', `${vmId}/stop/`);
    }

    async delete(vmId, archived) {
        return this.makeRequest('delete', `${vmId}/`, { archived });
    }

    async volumesUsedSize(vmId) {
        return this.makeRequest('get', `${vmId}/statistics/disks/`);
    }

    async enableBackup(vmId, backupEnabled) {
        return this.makeRequest('post', `${vmId}/disk/change-backup/`,
            { is_backup_enabled: !backupEnabled });
    }

    async volumeAdd(vmId, size, backup) {
        return this.makeRequest('post', `${vmId}/volumes/`,
            { size, is_backup_enabled: backup });
    }

    async vmCPUstat(vmId) {
        return this.makeRequest('get', `${vmId}/statistics/cpubars/`);
    }

    async vmRAMstat(vmId) {
        return this.makeRequest('get', `${vmId}/statistics/rambars/`);
    }

    async get(vmId) {
        return this.makeRequest('get', `${vmId}/`);
    }

    async financeInfo(vmId) {
        return this.makeRequest('get', `${vmId}/calculation/`);
    }

    async createPacket(vmId) {
        return this.makeRequest('post', `${vmId}/packet/create/`);
    }

    async cancelPacket(vmId) {
        return this.makeRequest('delete', `${vmId}/packet/cancel/`);
    }

    async updatePacket(vmId, parameters) {
        return this.makeRequest('put', `${vmId}/packet/update/`, parameters);
    }

    async refreshPacket(vmId) {
        return this.makeRequest('post', `${vmId}/packet/refresh/`);
    }

    async changeTariff(vmId, tariffId) {
        return this.makeRequest('post', `${vmId}/change-tariff/`, { tariff: tariffId });
    }

    async changeTariffCalculation(vmId, tariffId) {
        return this.makeRequest('post', `${vmId}/tariff/calculation/`, { tariff: tariffId });
    }

    async resetPassword({ vmId, password }) {
        return this.makeRequest('post', `${vmId}/change-password/`, { password });
    }

    async supportAccess(vmId) {
        return this.makeRequest('post', `${vmId}/support-access/`);
    }

    async enableLan(vmId) {
        return this.makeRequest('post', `${vmId}/lan/`);
    }

    async disableLan(vmId) {
        return this.makeRequest('delete', `${vmId}/lan/`);
    }

    async volumeChangeBackup(vmId, volumeId, isBackupEnabled) {
        return this.makeRequest('post', `${vmId}/volumes/${volumeId}/change-backup/`,
            { is_backup_enabled: !isBackupEnabled });
    }

    async diskResize(vmId, size) {
        return this.makeRequest('post', `${vmId}/disk/resize/`, { size });
    }

    async volumeResize(vmId, volumeId, size) {
        return this.makeRequest('post', `${vmId}/volumes/${volumeId}/resize/`, { size });
    }

    async removeIp(vmId, ip) {
        return this.makeRequest('delete', `${vmId}/ips/`, { ip });
    }

    async protectedIPAdd(vmId, bandwidth) {
        return this.makeRequest('post', `${vmId}/ips/protected/`, { bandwidth });
    }

    async disableProtection(vmId) {
        return this.makeRequest('post', `${vmId}/disable-protection/`);
    }

    async changeBandwidth(vmId, bandwidth) {
        return this.makeRequest('post', `${vmId}/change-bandwidth/`, { bandwidth });
    }

    async addIp(vmId, version) {
        return this.makeRequest('post', `${vmId}/ips/`, { version });
    }

    async addReservedIp(vmId, ip) {
        return this.makeRequest('post', `${vmId}/ips/reserved/`, { ip });
    }

    async vmProtectedWebsites(vmId, limit, offset) {
        return this.makeRequest(
            'get',
            `${vmId}/protected-websites/`,
            makePaginateParams(limit, offset),
        );
    }

    async addProtectedWebsite(vmId, { domain, certificate }) {
        const data = { domain };
        if (certificate !== -1) {
            data.certificate_id = certificate;
        }
        return this.makeRequest('post', `${vmId}/protected-websites/`, data);
    }

    async changeProtectedWebsite(vmId, domainId, certificate) {
        return this.makeRequest(
            'put',
            `${vmId}/protected-websites/${domainId}/`,
            { certificate_id: certificate },
        );
    }

    async deleteProtectedWebsite(vmId, domainId) {
        return this.makeRequest(
            'delete',
            `${vmId}/protected-websites/${domainId}/`,
        );
    }

    async financeInfoByConfiguration(data) {
        return this.makeRequest('get', 'cost-calculation/', data);
    }
}

CVDSVMResource.resource = 'vms';
CVDSVMResource.service = 'cloud';

module.exports = CVDSVMResource;
