import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';

import Input from '../../General/Form/Input';
import Button from '../../General/Button/Button';
import { autopaymentFetch } from '../../../actions/finances/actions';

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 24,
        paddingBottom: 24,
        paddingRight: 18,
        paddingLeft: 20,
        flex: 1,
        height: '100%'
    },
    form: {
        flex: 1,
        justifyContent: 'space-between'
    },
    title: {
        opacity: 0.75,
        marginBottom: 23,
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.38,
        lineHeight: 13,
        textTransform: 'uppercase'
    },
    recommendBlock: {
        flex: 1,
        flexDirection: 'row',
    },
    blueText: {
        color: '#0f79d5',
        fontSize: 12,
        fontWeight: '500',
    },
    recommendText: {
        color: 'rgba(69, 85, 98, 0.50)',
        fontSize: 12,
        fontWeight: '400',
    }
});

function isPaymentValid(amount) {
    const parsedAmount = parseInt(amount, 10);
    const normalizedAmount = Number.isNaN(parsedAmount) ? 0 : parsedAmount;
    return normalizedAmount >= 100;
}

function PaymentAmountForm(props) {
    const { onChange } = props;
    const [errors, setErrors] = React.useState({});
    const [amount, setAmmount] = React.useState('');
    const dispatch = useDispatch();
    const { autopayment, autopaymentFetchInProgress } = useSelector((state) => state.finance);

    useEffect(() => {
        if (!autopaymentFetchInProgress && autopayment == null) {
            dispatch(autopaymentFetch());
        }
    }, []);

    return <View style={styles.wrapper}>
        <Text style={styles.title}>сумма пополнения</Text>
        <View style={styles.form}>
            <View style={styles.amountBlock}>
                <Input errors={errors.amount}
                    additionalWrapperStyles={[{ marginBottom: 20 }]}
                    textInputAttrs={{
                        onChangeText: (text) => {
                            setAmmount(text);
                        },
                        placeholder: 'от 100₽',
                        value: amount
                    }}
                />
                {autopayment && autopayment.recommended_month_payment > 0
                    && <View style={styles.recommendBlock}>
                        <Text style={styles.blueText}>
                            <TouchableOpacity
                                onPress={() => setAmmount(
                                    autopayment.recommended_month_payment.toFixed(2)
                                )}
                            >
                                {autopayment.recommended_month_payment.toFixed(2)}
                            </TouchableOpacity> ₽</Text>
                        <Text style={styles.recommendText}> — рекомендуемый платеж</Text>
                    </View>}
            </View>
            <Button style={styles.button}
                onPress={() => {
                    if (isPaymentValid(amount)) {
                        onChange(amount);
                    } else {
                        setErrors({
                            ...errors,
                            amount: ['Сумма платежа должна быть от 100 рублей']
                        });
                    }
                }} title="Продолжить" />
        </View>
    </View>;
}

PaymentAmountForm.propTypes = {
    onChange: PropTypes.func
};

export default PaymentAmountForm;
