import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useCallback } from 'react';
import { merge } from '@netangels/core/utils/functions';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import Virtualhosts from './Virtualhosts';
import ContainerActionsTab from './ContainerActions';
import ContainerTariffTab from './ContainerTariffTab/ContainerTariffTab';

import {
    containerLimits,
    fetchUserContainer,
    fetchContainerCalculation,
    containerListCapabilities,
    fetchContainerVirtualhosts,
} from '../../actions/hosting/actions';
import { tabBarOptions } from '../../utils/tab-bar';

const ContainerTab = createMaterialTopTabNavigator();

function ContainerTabs(props) {
    const {
        container,
        swipeState,
        containerLimits,
        fetchUserContainer,
        containerFetchInProgress,
        fetchContainerCalculation,
        containerListCapabilities,
        fetchContainerVirtualhosts,
    } = props;
    const route = useRoute();

    return <ContainerTab.Navigator
        swipeEnabled={!swipeState}
        tabBarOptions={merge(tabBarOptions, {
            tabStyle: {
                width: 80
            }
        })}>
        <ContainerTab.Screen name="HostingVirtualhosts" component={Virtualhosts} options={{
            tabBarLabel: 'Сайты',
            title: 'Хостинг - Сайты'
        }} />
        <ContainerTab.Screen name="HostingTariff" component={ContainerTariffTab} options={{
            tabBarLabel: 'Тариф',
            title: 'Хостинг - Тариф'
        }}/>
        <ContainerTab.Screen name="HostingActions" component={ContainerActionsTab} options={{
            tabBarLabel: 'Действия',
            title: 'Хостинг - Действия'
        }}/>
    </ContainerTab.Navigator>;
}

ContainerTabs.propTypes = {
    fetchUserContainer: PropTypes.func,
    containerLimits: PropTypes.func,
    fetchContainerVirtualhosts: PropTypes.func,
    containerFetchInProgress: PropTypes.bool,
    containerListCapabilities: PropTypes.func,
    fetchContainerCalculation: PropTypes.func,
    swipeState: PropTypes.bool,
    container: PropTypes.shape({
        id: PropTypes.number
    }),
    route: PropTypes.shape({
        params: PropTypes.shape({
            containerId: PropTypes.string,
            containerName: PropTypes.string,
        })
    })
};

const mapStateToProps = (state) => ({
    container: state.hosting.container,
    swipeState: state.ui.swipeState,
    containerFetchInProgress: state.hosting.containerFetchInProgress,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    containerLimits,
    fetchUserContainer,
    containerListCapabilities,
    fetchContainerCalculation,
    fetchContainerVirtualhosts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContainerTabs);
