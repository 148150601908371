import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { containerListCapabilities, fetchUserContainers, fetchUserContainer } from '../actions/hosting/actions';
import { screenHeaderStyles } from '../utils/styles';
import HostingCreateVirtualhost from '../components/Hosting/HostingCreateVirtualhost';
import { NavigationProps } from '../types';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const HostingCreateVirtualhostStack = createStackNavigator();

function HostingCreateVirtualhostStackScreen(props) {
    const {
        route,
        container,
        navigation,
        containers,
        capabilities,
        fetchUserContainer,
        fetchUserContainers,
        containerFetchInProgress,
        containersFetchInProgress,
        containerListCapabilities,
    } = props;
    const insets = useSafeAreaInsets();
    const { containerId } = route.params;

    useFocusEffect(useCallback(() => {
        if (!containersFetchInProgress && containers == null) {
            fetchUserContainers();
        }
        if (!containerFetchInProgress && container == null) {
            fetchUserContainer(containerId);
        }
        if (capabilities == null) {
            containerListCapabilities('CreateVirtualhostCapabilities', containerId);
        }
        return () => {};
    }, []));

    return <HostingCreateVirtualhostStack.Navigator>
        <HostingCreateVirtualhostStack.Screen
            name="HostingCreateVirtualhostStack" component={HostingCreateVirtualhost} options={{
                headerTitle: () => <ScreenHeaderTitle title='Создание нового сайта' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.navigate('HostingContainer', {
                        containerId
                    }),
                    backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }}/>
    </HostingCreateVirtualhostStack.Navigator>;
}

HostingCreateVirtualhostStackScreen.propTypes = {
    navigation: NavigationProps,
    containers: PropTypes.shape({}),
    containersFetchInProgress: PropTypes.bool,
    containerFetchInProgress: PropTypes.bool,
    container: PropTypes.shape({}),
    capabilities: PropTypes.shape({}),
    fetchUserContainer: PropTypes.shape({}),
    fetchUserContainers: PropTypes.shape({}),
    containerListCapabilities: PropTypes.func,
};

const mapStateToProps = (state) => ({
    user: state.user.user,
    container: state.hosting.container,
    containers: state.hosting.containers,
    containerFetchInProgress: state.hosting.containerFetchInProgress,
    containersFetchInProgress: state.hosting.containersFetchInProgress,
    capabilities: state.hosting.capabilities,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserContainers,
    fetchUserContainer,
    containerListCapabilities,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HostingCreateVirtualhostStackScreen);
