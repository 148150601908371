const BaseResource = require('../resource');

class MonitoringResource extends BaseResource {
    async resources() {
        return this.makeRequest('get', null);
    }

    async resourcesByService(service) {
        return this.makeRequest('get', `${service}/`);
    }
}

MonitoringResource.resource = null;
MonitoringResource.service = 'monitoring';

module.exports = MonitoringResource;
