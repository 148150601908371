import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';
import { bindActionCreators } from 'redux';

import Errors from '../../General/Form/Errors';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'VolumeChangeSForm';

function ResizeVolumeWarning(props) {
    const {
        errors,
        onAccept,
        onClose,
        inProgress,
        flushErrors
    } = props;

    React.useEffect(() => {
        flushErrors();
        return () => {};
    }, []);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Уменьшение диска</Text>
        <Text style={styles.text}>
            В процессе уменьшения диска работа виртуальной машины будет остановлена,
            а&nbsp;сайты станут недоступны.
        </Text>
        <Text style={styles.text}>
            Это может занять несколько часов,
            в&nbsp;зависимости от размера диска и количества файлов.
        </Text>
        {errors.__all__ && <Errors errors={errors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={onAccept}
                title='Продолжить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

ResizeVolumeWarning.propTypes = {
    onClose: PropTypes.func,
    onAccept: PropTypes.func,
    errors: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    inProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    inProgress: state.cvds.vmUpdateInProgress,
    errors: state.forms.errors[formId] || {}
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    text: {
        fontSize: 12,
        lineHeight: 16,
        color: '#455562',
        minWidth: 270,
        marginBottom: 8
    },
    buttonBlock: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResizeVolumeWarning);
