import React from 'react';
import PropTypes from 'prop-types';
import {
    Text,
    View,
    Image,
    StyleSheet,
} from 'react-native';

import { servicesResourceNameForServiceIconsMap } from '../../constants/general';

function ResourceCard(props) {
    const {
        name,
        service,
        description,
        uidText,
        price,
    } = props;

    return (
        <View style={styles.wrapper}>
            <View style={styles.cardWrapper}>
                <Image
                    source={servicesResourceNameForServiceIconsMap[service]}
                    style={styles.icon}
                />
                <View>
                    <Text style={styles.title}>
                        {name}
                    </Text>
                    <Text style={styles.description}>
                        {description}
                    </Text>
                    <View>
                        {uidText && <View style={styles.uidBlock}>
                            <Text style={[styles.description, { fontWeight: 500 }]}>
                                {uidText}
                            </Text>
                            <Text style={[styles.description, { marginLeft: 20 }]}>
                                {price}
                            </Text>
                        </View>}
                    </View>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: '#ffffff',
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 19,
        paddingRight: 19,
    },
    icon: {
        width: 18,
        height: 18,
        marginRight: 10,
    },
    title: {
        marginBottom: 4,
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 15,
    },
    description: {
        color: '#586A7B',
        fontSize: 11,
        fontWeight: '400',
        lineHeight: 13,
    },
    cardWrapper: {
        flex: 1,
        flexDirection: 'row',
    },
    uidBlock: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 8,
    },
});

ResourceCard.propTypes = {
    name: PropTypes.string,
    uidText: PropTypes.string,
    price: PropTypes.string,
    service: PropTypes.string,
    description: PropTypes.element,
};

export default ResourceCard;
