import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { navigationHelper } from '../utils/transfer';
import { getTransferStatus, changeTransferQuery } from '../actions/transfer/actions';
import { screenHeaderStyles } from '../utils/styles';
import { servicesResourceNameForTransferMap } from '../constants/general';
import TransferLoader from '../components/Transfer/TransferLoader';
import AcceptPage from '../components/Transfer/AcceptPage';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const TransferAcceptStack = createStackNavigator();

function TransferAcceptStackScreen(props) {
    const { route } = props;
    const {
        instance,
        transferData
    } = useSelector((state) => state.transfer);
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();
    const { key } = route.params;
    let service = '';
    let currentInstance = null;
    if (transferData && transferData.resources && transferData.resources.container) {
        currentInstance = transferData.resources.container;
        service = 'containers';
    } else if (transferData && transferData.resources && transferData.resources.vm) {
        currentInstance = transferData.resources.vm;
        service = 'vms';
    } else if (transferData && transferData.resources && transferData.resources.mail_domain) {
        currentInstance = transferData.resources.mail_domain;
        service = 'mail_domains';
    } else if (
        transferData && transferData.resources && transferData.resources.zones.length !== 0
    ) {
        currentInstance = transferData.resources.zones[0];
        service = 'zones';
    } else if (
        transferData && transferData.resources && transferData.resources.domains.length !== 0
    ) {
        currentInstance = transferData.resources.domains[0];
        service = 'domains';
    } else if (
        transferData && transferData.resources && transferData.resources.certificates.length !== 0
    ) {
        currentInstance = transferData.resources.certificates[0];
        service = 'certificates';
    }
    if (currentInstance != null && instance == null) {
        dispatch(changeTransferQuery({ instance: currentInstance, service }));
    }

    useFocusEffect(React.useCallback(() => {
        if (transferData == null) {
            dispatch(getTransferStatus(key));
        }
    }, []));

    return <TransferAcceptStack.Navigator>
        <TransferAcceptStack.Screen name="TransferAcceptScreen"
            component={
                (service.length === 0)
                    ? TransferLoader : AcceptPage}
            options={{
                headerTitle: () => <ScreenHeaderTitle
                    title={
                        service.length !== 0 ? `Передача ${servicesResourceNameForTransferMap[service]}`
                            : 'Идет загрузка...'} />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigationHelper(service, navigation, instance),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </TransferAcceptStack.Navigator>;
}

TransferAcceptStackScreen.propTypes = {
    route: PropTypes.object,
};

export default TransferAcceptStackScreen;
