import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, Image, Text, StyleSheet } from 'react-native';

import { bToMb } from '@netangels/core/utils/convert';
import { getPercent } from '@netangels/core/utils/price';

import {
    mailProIcon,
    tweakIcon,
    databaseIcon,
    likeIcon
} from '../../../icons';
import mailData from '../../../../data/mail/prices.json';
import Errors from '../../General/Form/Errors';
import { formatMemory } from '../../../utils/general';
import { updateMailQuota } from '../../../actions/mail/actions';
import ResourceFills from '../../General/ResourceFills/ResourceFills';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'ActivateMailPaid';

function PaidQuotaAdvantage({ icon, title, description, additionalStyle = {} }) {
    return <View style={[styles.advantageWrapper, additionalStyle]}>
        <Image source={icon} style={styles.advantageIcon} />
        <View style={styles.advantageTextBlock}>
            <Text style={styles.advantageTitle}>{title}</Text>
            <Text style={styles.advantageDescription}>{description}</Text>
        </View>
    </View>;
}

PaidQuotaAdvantage.propTypes = {
    icon: PropTypes.shape({}),
    title: PropTypes.string,
    description: PropTypes.string,
    additionalStyle: PropTypes.shape({}),
};

function ActivateMailPaidQuotaModal(props) {
    const {
        onClose,
    } = props;
    const { errors } = useSelector((state) => state.forms);
    const currentErrors = errors[formId] || {};
    const {
        userInfo,
        userStatisticsInfo,
        updateMailQuotaInProgress
    } = useSelector((state) => state.mail);
    const dispatch = useDispatch();

    const current = userStatisticsInfo && bToMb(userStatisticsInfo.summary.used);
    const normalizedUsed = current < 1024
        ? `${current} МБ`
        : Number.parseFloat((current / 1024).toFixed(2));
    const formattedFreeQuota = userInfo && formatMemory(userInfo.free_quota, true);
    const header = userInfo && `Занято ${normalizedUsed} из ${formattedFreeQuota} (${getPercent(
        userInfo.free_quota,
        current,
    )}%)`;
    const price = (mailData.quota.price * 24 * 30) / 1000;
    const advantageTextWithFree = `вы платите только за фактически занимаемое место, превышающее ${formattedFreeQuota} доступной вам бесплатной квоты — всего ${price} ₽/ГБ в месяц`
    return (
        <View style={styles.wrapper}>
            <View style={styles.headerBlock}>
                <Image source={mailProIcon} style={styles.icon} />
                <Text style={styles.headerTitle}>Почта.</Text>
                <Text style={styles.headerTitleBlue}>
                    про
                </Text>
            </View>
            {userInfo && userInfo.free_quota > 0 && (
                <View>
                    <Text style={styles.freeQuotaText}>
                        Бесплатная квота — {formattedFreeQuota}
                    </Text>
                    <ResourceFills
                        isLoaded={true}
                        isLoading={false}
                        name={header}
                        scales={[
                            {
                                max: userInfo && userInfo.free_quota,
                                current,
                            },
                        ]}
                    />
                    <Text style={styles.description}>
                        Чтобы почта продолжала приходить при
                        исчерпании бесплатной квоты — подключите
                        <Text style={styles.descriptionBold}>
                            Почта.
                        </Text>
                        <Text style={styles.descriptionBlue}>
                            про
                        </Text>
                    </Text>
                </View>
            )}
            <View>
                <PaidQuotaAdvantage
                    icon={databaseIcon}
                    title="Больше места"
                    description="получите до 4 ТБ пространства для каждого домена"
                />
                <PaidQuotaAdvantage
                    icon={tweakIcon}
                    title="Управление лимитами"
                    description="ограничивайте объем для каждого домена или ящика"
                />
                <PaidQuotaAdvantage
                    icon={likeIcon}
                    title="Выгодные условия"
                    additionalStyle={{ minHeight: 76, marginBottom: 24 }}
                    description={formattedFreeQuota
                        ? advantageTextWithFree
                        : `вы платите только за фактически занимаемое место — всего ${price} ₽/ГБ в месяц`}
                />
            </View>
            {currentErrors.__all__ && <Errors errors={currentErrors.__all__} />}
            <View>
                <View style={styles.buttonBlock}>
                    <AcceptButton
                        onSubmit={() => dispatch(
                            updateMailQuota(formId, true, () => onClose()),
                        )}
                        title='Подключить'
                        inProgress={updateMailQuotaInProgress}/>
                    <CancelButton onClose={onClose} disabled={updateMailQuotaInProgress} />
                </View>
            </View>
        </View>
    );
}

ActivateMailPaidQuotaModal.propTypes = {
    onClose: PropTypes.func,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
    },
    headerTitle: {
        color: '#455562',
        lineHeight: 19,
        fontSize: 16,
        fontWeight: '500',
    },
    headerTitleBlue: {
        color: '#0f79d5',
        lineHeight: 19,
        fontSize: 16,
        fontWeight: '500',
    },
    icon: {
        width: 24,
        height: 24,
        marginRight: 16,
    },
    freeQuotaText: {
        color: '#455562',
        lineHeight: 16,
        fontSize: 14,
        fontWeight: '500',
        marginTop: 20,
        marginBottom: 12,
    },
    description: {
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 16,
        marginTop: 8,
        marginBottom: 8,
        color: '#455562',
    },
    descriptionBold: {
        marginLeft: 2,
        fontWeight: '500',
        color: '#455562',
    },
    descriptionBlue: {
        fontWeight: '500',
        color: '#0f79d5',
    },
    advantageWrapper: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 16,
        alignItems: 'flex-start',
        height: 48,
    },
    advantageIcon: {
        height: 16,
        width: 16,
        marginRight: 17,
    },
    advantageTextBlock: {
        flex: 1,
        flexDirection: 'column',
    },
    advantageTitle: {
        fontSize: 14,
        fontWeight: '500',
        color: '#455562',
        lineHeight: 16,
        marginBottom: 4,
    },
    advantageDescription: {
        fontSize: 11,
        fontWeight: '400',
        color: '#455562',
        lineHeight: 14,
    }
});

export default ActivateMailPaidQuotaModal;
