import React, { useEffect } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../../icons';
import {
    containerBackupsList,
    fetchUserContainers,
    restoreFromBackupToInit,
    restoreFromBackupUpdateParams,
} from '../../actions/hosting/actions';
import { screenHeaderStyles } from '../../utils/styles';
import MailResourceLoader from '../../components/Mail/MailResourceLoader';
import RestoreContainerStep from '../../components/Hosting/HostingBackup/RestoreContainerStep';
import ScreenHeaderTitle from '../../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const HostingBackupContainerStack = createStackNavigator();

function HostingBackupContainerStackScreen(props) {
    const { route } = props;
    const {
        backups,
        backupsFetchInProgress,
        containers,
        containersFetchInProgress,
    } = useSelector(
        (state) => state.hosting,
    );
    const { currentContainer, restoreDate } = useSelector(
        (state) => state.hostingRestoreFromBackup,
    );
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();

    const containerId = Number.parseInt(route.params.containerId, 10);
    const currentRestoreDate = dayjs.unix(route.params.restoreDate).format('YYYY-MM-DD HH:mm');
    useEffect(() => {
        if (containers == null && !containersFetchInProgress) {
            dispatch(fetchUserContainers());
        }
        if (restoreDate == null) {
            dispatch(
                restoreFromBackupUpdateParams({
                    restoreDate: currentRestoreDate,
                }),
            );
        }
        return () => {
            dispatch(restoreFromBackupToInit());
        };
    }, []);
    useEffect(() => {
        if (currentContainer) {
            dispatch(
                restoreFromBackupUpdateParams({
                    currentMethod: 'restoreFile',
                    filesForArchive: [],
                }),
            );
        } else if (containers) {
            const container = containers.entities.find(
                (c) => c.id === containerId,
            );
            dispatch(
                restoreFromBackupUpdateParams(
                    {
                        currentContainer: container,
                        currentMethod: 'restoreFile',
                    },
                    () => {
                        dispatch(containerBackupsList(container.id));
                    },
                ),
            );
        }
    }, [containers]);

    return <HostingBackupContainerStack.Navigator>
        <HostingBackupContainerStack.Screen name="HostingBackupContainerScreen"
            component={
                (backups == null || backupsFetchInProgress)
                    ? MailResourceLoader : RestoreContainerStep}
            options={{
                headerTitle: () => <ScreenHeaderTitle title='Подтверждение' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.navigate('HostingBackupResources', { containerId }),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </HostingBackupContainerStack.Navigator>;
}

HostingBackupContainerStackScreen.propTypes = {
    route: PropTypes.object,
};

export default HostingBackupContainerStackScreen;
