import React from 'react';
import PropTypes from 'prop-types';
import {
    StyleSheet,
    Text,
    View,
    Image
} from 'react-native';

import { warningIcon, dangerIcon } from '../../icons';

function MonitoringEventText(props) {
    const {
        title,
        severity
    } = props;

    return (
        <View style={styles.monitoringBlock}>
            <Image
                source={severity.toLowerCase() === 'warning' ? warningIcon : dangerIcon}
                style={styles.icon}
            />
            <Text style={[styles.text,
                { color: severity.toLowerCase() === 'warning' ? '#e17334' : '#d55a5a' },
            ]}>{title}</Text>
        </View>
    );
}

MonitoringEventText.dynamicName = 'MonitoringEventText';

MonitoringEventText.propTypes = {
    title: PropTypes.string,
    severity: PropTypes.string,
};

const styles = StyleSheet.create({
    monitoringBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 15,
    },
    icon: {
        width: 15,
        height: 15,
        marginRight: 7,
    },
});

export default MonitoringEventText;
