import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text } from 'react-native';

import { pluralize } from '../../../utils/general';


const styles = StyleSheet.create({
    timer: {
        flex: 1,
        flexDirection: 'row'
    },
    timerSeparator: {
        fontSize: 24,
        lineHeight: 44,
        marginLeft: 3,
        color: '#455562'
    },
    timerItem: {
        flex: 1,
        maxWidth: 54,
        flexDirection: 'row'
    },
    timerTime: {
        width: 38,
        fontSize: 20,
        lineHeight: 38,
        textAlign: 'center',
        color: '#455562',
        backgroundColor: '#e5e5e5',
        borderRadius: 4
    },
    timerHint: {
        opacity: 0.5,
        fontSize: 10,
        lineHeight: 14,
        marginTop: 4,
        textAlign: 'center'
    }
})

function TimerBlock(props) {
    const { timerList } = props;

    return <View style={[styles.timer]}>
        {timerList.map((item, i) => <View style={[styles.timerItem]}
            key={`time-block-${i}`}>
            <View>
                <Text style={[styles.timerTime]}>{item.amount}</Text>
                <Text style={[styles.timerHint]}>
                    {pluralize(item.amount, item.variants)}
                </Text>
            </View>
            {i !== timerList.length - 1 && <Text style={[styles.timerSeparator]}>:</Text>}
        </View>)}
    </View>;
}

TimerBlock.propTypes = {
    timerList: PropTypes.arrayOf(PropTypes.shape({
        amount: PropTypes.string,
        variants: PropTypes.arrayOf(PropTypes.string)
    })),
}

export default TimerBlock;
