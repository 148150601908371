import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';

import { getTariffInfo, isNotWorkingVM } from '../utils';
import { formatPrice } from '../../../utils/general';

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: '#ffffff'
    },
    entry: {
        paddingTop: 12.5,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 14.5,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTopWidth: 1,
        borderTopColor: '#e4e4e4'
    },
    entryLabel: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 20,
        fontWeight: '600'
    },
    entryValue: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 20
    },
    totalBlock: {
        paddingTop: 17.5,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 17.5,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTopWidth: 1,
        borderTopColor: '#e4e4e4'
    },
    totalText: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 15,
        fontWeight: '600'
    }
})

function FinanceBlockWithoutPacket(props) {
    const { vm, calculation } = props;
    const tariffInfo = getTariffInfo(calculation, !isNotWorkingVM(vm.state));

    return <View style={styles.wrapper}>
        {tariffInfo.filter(tariffInfo => tariffInfo.isDisplayed == null
            || tariffInfo.isDisplayed).map((item, index) => <View
            style={styles.entry}
            key={`tariff-info-${index}`}>
            <Text style={styles.entryLabel}>
                {item.name}
            </Text>
            <Text style={styles.entryValue}>
                {formatPrice(item.price)} ₽/мес
            </Text>
        </View>)}
        <View style={styles.totalBlock}>
            <Text style={styles.totalText}>
                Итого
            </Text>
            <Text style={styles.totalText}>
                {formatPrice(calculation.cost.month)} ₽/мес
            </Text>
        </View>
    </View>;
}

FinanceBlockWithoutPacket.propTypes = {
    vm: PropTypes.shape({
        state: PropTypes.string
    }),
    calculation: PropTypes.shape({
        resources: PropTypes.shape({
            backup: PropTypes.shape({
                count: PropTypes.number,
                cost: PropTypes.number
            }),
            ips: PropTypes.shape({
                count: PropTypes.number,
                cost: PropTypes.number
            }),
            storage: PropTypes.shape({
                fast: PropTypes.shape({
                    count: PropTypes.number,
                    cost: PropTypes.number
                }),
                normal: PropTypes.shape({
                    count: PropTypes.number,
                    cost: PropTypes.number
                }),
                slow: PropTypes.shape({
                    count: PropTypes.number,
                    cost: PropTypes.number
                })
            }),
            vm: PropTypes.shape({
                tariff: PropTypes.string,
                cost: PropTypes.number
            })
        }),
        cost: PropTypes.shape({
            month: PropTypes.number
        })
    })
};

const mapStateToProps = state => ({
    vm: state.cvds.currentVM,
    calculation: state.cvdsFinanceInfo.calculation
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FinanceBlockWithoutPacket);
