import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ScrollView } from 'react-native';
import { bindActionCreators } from 'redux';

import ContainersList from './ContainersList';
import HostingLaunchPage from './HostingLaunchPage';
import ResourceLoader from '../General/ResourceLoader/ResourceLoader';

function HostingScreen(props) {
    const {
        containers,
        containersFetchInProgress,
    } = props;
    return <ScrollView>
        {containers && containers.count === 0 && <HostingLaunchPage />}
        {containersFetchInProgress && containers == null && <ResourceLoader height={77} count={3}/>}
        {!containersFetchInProgress && containers !== null
            && containers.count !== 0 && <ContainersList containers={containers}/>}
    </ScrollView>;
}

HostingScreen.propTypes = {
    containers: PropTypes.shape({
        count: PropTypes.number
    }),
    containersFetchInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    containers: state.hosting.containers,
    containersFetchInProgress: state.hosting.containersFetchInProgress,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HostingScreen);
