import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Image } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';

import Modal from '../components/General/Modal/Modal';
import Action from '../components/General/Actions/Action';
import Actions from '../components/General/Actions/Actions';
import { fetchUserVirtualhost } from '../actions/hosting/actions';
import VirtualhostTabs from '../components/Hosting/VirtualhostTab';
import {
    menuBackIcon,
    turnOffIcon,
    trashIcon,
    turnOffDisabledIcon,
    tweakIcon,
    returnIcon,
    returnDisabledIcon,
} from '../icons/index';
import { screenHeaderStyles } from '../utils/styles';
import { DISABLED_BY_SERVICE } from '../constants/general';
import VirtualhostDelete from '../components/Hosting/HostingModal/VirtualhostDelete';
import VirtualhostSwitchState from '../components/Hosting/HostingModal/VirtualhostSwitchState';
import { ModalContext } from '../contexts/ModalContext';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const VirtualhostStack = createStackNavigator();

function VirtualhostStackScreen(props) {
    const {
        route,
        navigation,
        virtualhost,
        virtualhostName,
        virtualhostInProgress,
        fetchUserVirtualhost,
    } = props;

    const insets = useSafeAreaInsets();
    const [isModalSwitchState, setModalSwitchState] = React.useState(false);
    const [isModalDeleteVirtualhost, setModalDeleteVirtualhost] = React.useState(false);

    useFocusEffect(React.useCallback(() => {
        if ((!virtualhostInProgress && virtualhost == null)
            || (virtualhost && virtualhost.id !== Number.parseInt(route.params.virtualhostId, 10))) {
            fetchUserVirtualhost(route.params.virtualhostId);
        }
    }, [virtualhost]));

    const { closeModal } = useContext(ModalContext);

    const isEnabled = virtualhost && virtualhost.enabled;
    const canChange = (virtualhost && virtualhost.state === "DISABLED_BY_USER") || (virtualhost &&  virtualhost.state === "ENABLED");
    return <VirtualhostStack.Navigator>
        <VirtualhostStack.Screen name="HostingContainerVirtualhostStack"
            component={VirtualhostTabs}
            initialParams={{
                virtualhostId: route.params.virtualhostId,
                virtualhostName: virtualhostName || route.params.virtualhostName
            }}
            options={{
                headerTitle: () => <ScreenHeaderTitle
                    title={virtualhostName || route.params.virtualhostName || 'Идет загрузка...'} />,
                headerRight: () => <View>
                    <Actions modalId='hosting-container-virtualhost' icon={tweakIcon}
                        iconWidth={16} iconHeight={16}
                        additionalButtonStyles={[styles.actions]}>
                        <Action icon={canChange ? turnOffIcon : turnOffDisabledIcon}
                            title={virtualhost && virtualhost.enabled ? 'Отключить' : 'Включить'}
                            enabled={canChange}
                            iconStyles={[{width: 18, height: 17}]}
                            onPress={() => {
                                closeModal();
                                setModalSwitchState(true);
                            }} />
                        <Action icon={isEnabled && virtualhost.state !== DISABLED_BY_SERVICE
                            ? returnIcon : returnDisabledIcon}
                        title='Восстановить содержимое'
                        enabled={isEnabled && virtualhost.state !== DISABLED_BY_SERVICE}
                        onPress={() => {
                            closeModal();
                            navigation.navigate(
                                'HostingBackupVirtualhost',
                                {
                                    containerId: virtualhost.container.id,
                                    virtualhost: virtualhost.name,
                                }
                            );
                        }} />
                        <Action icon={trashIcon}
                            textStyles={[{ color: '#D51010' }]} title='Удалить сайт'
                            onPress={() => {
                                closeModal();
                                setModalDeleteVirtualhost(true);
                            }}/>
                    </Actions>
                    <Modal isModalShowed={isModalSwitchState}
                        component={<VirtualhostSwitchState
                            virtualhostId={virtualhost && virtualhost.id}
                            enabled={virtualhost && virtualhost.enabled}
                            onClose={() => setModalSwitchState(false)}/>}
                        onClose={() => setModalSwitchState(false)} />
                    <Modal isModalShowed={isModalDeleteVirtualhost}
                        component={<VirtualhostDelete
                            containerId={virtualhost && virtualhost.container.id}
                            virtualhostName={virtualhost && virtualhost.name}
                            virtualhostId={virtualhost && virtualhost.id}
                            enabled={virtualhost && virtualhost.enabled}
                            onClose={() => setModalDeleteVirtualhost(false)}/>}
                        onClose={() => setModalDeleteVirtualhost(false)} />
                </View>,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => {
                        navigation.navigate('HostingContainer', {
                            containerId: virtualhost.container_id
                        });
                    },
                    backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
                headerStyle: {
                    ...screenHeaderStyles.headerStyle,
                    opacity: virtualhost && !virtualhost.enabled ? 0.3 : 1,
                    backgroundColor: virtualhost && !virtualhost.enabled ? 'rgba(69, 85, 98, 0.05)' : '#f5f5f5'
                },
                headerTintColor: '#ffffff',
            }} />
    </VirtualhostStack.Navigator>;
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    virtualhost: state.hosting.virtualhost,
    virtualhostInProgress: state.hosting.virtualhostInProgress,
    virtualhostName: state.hosting.virtualhost && state.hosting.virtualhost.name
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserVirtualhost
}, dispatch);

const styles = StyleSheet.create({
    actions: {
        backgroundColor: 'transparent',
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginRight: 9
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(VirtualhostStackScreen);
