import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';

import { cvdsPaymentPageIcon } from '../../icons';
import PaymentForm from '../General/Payments/PaymentForm';
import PaymentPagePromoForm from './PaymentPagePromoForm';
import PaymentPageMoneyForm from './PaymentPageMoneyForm';
import { flushErrors } from '../../actions/forms/actions';

function PaymentPage(props) {
    const {
        flushErrors
    } = props;
    const [paymentType, setPaymentType] = React.useState('money');
    const [paymentForm, setPaymentForm] = React.useState(null);
    const paymentFormRef = useRef(null);

    useLayoutEffect(() => {
        if (paymentForm != null && paymentFormRef.current != null) {
            paymentFormRef.current.submit();
        }
    }, [paymentForm])

    return <View style={styles.wrapper}>
        {paymentForm}
        {<React.Fragment>
            <Image source={cvdsPaymentPageIcon} style={styles.image} />
            <Text style={styles.title}>Быстрое пополнение баланса</Text>
            <Text style={styles.text}>Для создания первой виртуальной машины
                баланс аккаунта должен быть не менее 200 ₽</Text>
            <View style={styles.paymentBlock}>
                {paymentType === 'money' && <PaymentPageMoneyForm
                    setPaymentForm={(data) => {
                        setPaymentForm(<PaymentForm {...data} innerRef={paymentFormRef} />)
                    }}/>}
                {paymentType === 'promo' && <PaymentPagePromoForm />}
            </View>
            <TouchableOpacity onPress={() => {
                flushErrors();
                if (paymentType === 'money') {
                    setPaymentType('promo');
                } else {
                    setPaymentType('money');
                }}}>
                <Text style={styles.promoLink}>
                    {paymentType === 'money' ? 'У меня есть промокод' : 'Пополнить баланс аккаунта'}
                </Text>
            </TouchableOpacity>
        </React.Fragment>}
    </View>;
};

PaymentPage.propTypes = {
    flushErrors: PropTypes.func
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingLeft: 16,
        paddingRight: 24,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        marginTop: 37,
        marginBottom: 10,
        color: '#455562',
        textAlign: 'center',
        fontSize: 16,
        lineHeight: 19
    },
    text: {
        width: 286,
        textAlign: 'center',
        color: 'rgba(69,85,98,0.7)',
        fontSize: 12,
        lineHeight: 16
    },
    image: {
        width: 270,
        height: 165
    },
    paymentBlock: {
        marginTop: 18,
        marginBottom: 16
    },
    promoLink: {
        marginTop: 8,
        color: '#0F79D5',
        fontSize: 14,
        lineHeight: 16,
        paddingBottom: 3,
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        borderBottomColor: '#0F79D5'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
