export function connectToWebsocketWithDelay(fn, timeout) {
    let timeoutDescriptor = setTimeout(() => {
        fn();
        timeoutDescriptor = null;
    }, timeout);

    return {
        cancel: () => {
            if (timeoutDescriptor != null) {
                clearTimeout(timeoutDescriptor);
                timeoutDescriptor = null;
            }
        }
    };
}
