import React, { useCallback } from 'react';
import { userChannelName } from '@netangels/core/constants/websockets';
import { useFocusEffect, useNavigation} from '@react-navigation/native';

import { connect } from 'react-redux';
import {
    ScrollView,
    TouchableOpacity,
    Text,
    View,
    StyleSheet
} from 'react-native';
import { bindActionCreators } from 'redux';

import { getChannel } from '@netangels/core/utils/websockets';

import MessageBlock from './MessageBlock';
import {
    updateNotification,
    readAllNotification,
    fetchUserNotifications
} from '../../actions/notifications/actions';
import useWebsockets from '../../hooks/useWebsockets';
import { connectToWebsocketWithDelay } from '../../utils/websockets';

const styles = StyleSheet.create({
    readAllBlock: {
        backgroundColor: '#e8f4fe',
        paddingTop: 13,
        paddingBottom: 14,
        position: 'fixed',
        width: '100%',
        bottom: 0,
    },
    readAllText: {
        color: '#0f79d5',
        fontSize: 11,
        fontWeight: '500',
        lineHeight: 11,
        textAlign: 'center',
    }
});

function NotificationsScreen(props) {
    const {
        user,
        notifications,
        readAllNotification,
        updateNotification,
        fetchUserNotifications,
    } = props;
    const ws = useWebsockets();
    const navigation = useNavigation();
    let notificationsSubscriber = null;

    useFocusEffect(useCallback(() => {
        if (notifications == null) {
            fetchUserNotifications();
        }
        const connectionState = connectToWebsocketWithDelay(() => {
            if (notificationsSubscriber == null) {
                const channelName = getChannel(userChannelName, user.login, true);

                notificationsSubscriber = ws.subscriber('notification', channelName);
                notificationsSubscriber.on('notification', (data) => {
                    updateNotification(data);
                });
            }
        });
        return () => {
            connectionState.cancel();
            if (notificationsSubscriber != null) {
                ws.unsubscribe(notificationsSubscriber);
                notificationsSubscriber = null;
            }
        };
    }, [notifications]));

    return <View>
        <ScrollView style={{ marginBottom: 38 }}>
            {notifications != null && notifications.entities.map((item, i) => <MessageBlock item={item} key={`item-${i}`}/>)}
        </ScrollView>
        {notifications && notifications.unread > 0 && <TouchableOpacity
            style={styles.readAllBlock}
            onPress={() => readAllNotification(() => {
                if (navigation.canGoBack()) {
                    navigation.goBack();
                } else {
                    navigation.navigate('MainStack');
                }
            })}
        >
            <Text style={styles.readAllText}>пометить все как прочитанные</Text>
        </TouchableOpacity>}
    </View>;
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    notifications: state.notifications.notifications
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserNotifications, updateNotification, readAllNotification
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsScreen);
