import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';

import cvdsStatesData from '../../../data/cvds/states.json';
import CopypableText from '../General/CopypableText/CopypableText';
import DistributionIcon from './DistributionIcon/DistributionIcon';
import MultiStateBlock from '../General/MultiStateBlock/MultiStateBlock';

const styles = StyleSheet.create({
    distributionBlock: {
        flex: 1,
        height: 71,
        paddingTop: 13,
        paddingLeft: 15,
        paddingBottom: 12,
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        backgroundColor: '#ffffff',
        borderBottomColor: 'rgba(69, 85, 98, 0.3)',
        borderBottomStyle: 'dashed'
    },
    distributionIcon: {
        marginRight: 12
    },
    distributionTitle: {
        color: '#455562',
        marginBottom: 3,
        fontSize: 12,
        fontWeight: '600',
        lineHeight: 14
    },
    distributionSubtitle: {
        color: '#6D8193',
        fontSize: 11
    },
    vmInfoBlock: {
        paddingTop: 11,
        paddingLeft: 20,
        paddingBottom: 18,
        paddingRight: 40,
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        justifyContent: 'space-between',
    },
    vmInfoItem: {
        flexDirection: 'row',
        height: 20,
    },
    vmInfoItemTitle: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '600',
        lineHeight: 20,
        marginRight: 4
    },
    stateBlock: {
        marginTop: 8,
        marginBottom: 20,
        paddingTop: 11,
        paddingLeft: 16,
        paddingRight: 23,
        paddingBottom: 10,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        minHeight: 51,
    },
    stateTitle: {
        fontSize: 12,
        lineHeight: 14,
        color: '#455562',
        fontWeight: '500',
    },
    stateBlockText: {
        marginLeft: 7,
        fontSize: 10,
        lineHeight: 12,
    }
});

function VMInfoBlock() {
    const vm = useSelector((state) => state.cvds.currentVM);

    return <View>
        <View style={styles.stateBlock}>
            <Text style={styles.stateTitle}>Статус</Text>
            <View>
                <MultiStateBlock state={vm.state.toLowerCase()}
                    additionalTitleStyles={styles.stateBlockText}
                    title={cvdsStatesData[vm.state]} />
            </View>
        </View>
        <View>
            <View style={styles.distributionBlock}>
                <View style={styles.distributionIcon}>
                    <DistributionIcon distribution={vm.image.uid}
                        iconWidth='46px' iconHeight='46px'/>
                </View>
                <View>
                    <Text style={styles.distributionTitle}>{vm.image.display_name}</Text>
                    {vm.image.panel_version != null && <Text style={styles.distributionSubtitle}>
                        Версия {vm.image.panel_version}
                    </Text>}
                </View>
            </View>
            <View style={styles.vmInfoBlock}>
                <View style={styles.vmInfoItem}>
                    <Text style={styles.vmInfoItemTitle}>IP:</Text>
                    <CopypableText text={vm.main_ip}/>
                </View>
                <View style={styles.vmInfoItem}>
                    <Text style={styles.vmInfoItemTitle}>ID:</Text>
                    <CopypableText text={vm.uid}/>
                </View>
            </View>
        </View>
    </View>;
}

export default VMInfoBlock;
