import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';

function getResources(count, styles) {
    const resources = [];
    for (let i = 0; i < count; i++) {
        resources.push(<View style={styles} key={`resourse-${i}`}/>)
    }
    return resources;
}

function ResourceLoader(props) {
    const {
        count = 3,
        height,
        additionalStyles = [],
    } = props;
    const styles = StyleSheet.create({
        loadItem: {
            height,
            backgroundColor: '#b0b0b0',
            borderBottomColor: '#FFFFFF',
            borderBottomWidth: 2,
        }
    });
    return <View>
        {getResources(count, [styles.loadItem, ...additionalStyles])}
    </View>;
}

ResourceLoader.propTypes = {
    count: PropTypes.number,
    height: PropTypes.number,
    additionalStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ])),
};

export default ResourceLoader;
