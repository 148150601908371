import mailData from '../../../data/mail/prices.json';

export const getPrice = (size) => ((((mailData.quota.price * size) / 1024) * 24 * 30) / 1000)
    .toFixed(2);

export const thinspaceNumber = (number, thinspaceAll = false) => {
    if (!thinspaceAll && Math.abs(number) < 10000) {
        return number;
    }
    return number
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, String.fromCharCode(0x2009));
};
