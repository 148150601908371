const BaseResource = require('../resource');
const GatewayClient = require('../../gateway-client');

class MailboxesResource extends BaseResource {
    constructor({ url, token }) {
        super({ url, token });
        this.client = new GatewayClient({
            url,
            token,
            baseUrl: '/api/v2',
        });
    }

    async info(mailboxId) {
        return this.makeRequest('get', `${mailboxId}/`);
    }

    async createMailbox(domainId, { name, password }) {
        return this.makeRequest('post', null, {
            domain_id: domainId,
            name,
            password,
        });
    }

    async updateMailbox(mailboxId, data) {
        return this.makeRequest('put', `${mailboxId}/`, data);
    }

    async switchMailboxLocal(mailboxId, isLocal) {
        return this.makeRequest('put', `${mailboxId}/`, {
            is_local: isLocal,
        });
    }

    async deleteMailbox(mailboxId) {
        return this.makeRequest('delete', `${mailboxId}/`);
    }

    async addAlias(mailboxId, alias) {
        return this.makeRequest('put', `${mailboxId}/add-alias/`, { alias });
    }

    async deleteAlias(mailboxId, alias) {
        return this.makeRequest('put', `${mailboxId}/delete-alias/`, { alias });
    }
}

MailboxesResource.resource = 'mailboxes';
MailboxesResource.service = 'mail';

module.exports = MailboxesResource;
