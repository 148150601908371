import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    loadingCircle: {
        width: 18,
        height: 18,
        borderWidth: 1,
        marginRight: 12,
        borderRadius: '50%',
        backgroundColor: '#b0b0b0',
        borderColor: '#FFFFFF',
    },
    circle: {
        borderWidth: 1,
        marginRight: 12,
        borderColor: 'rgba(161,170,177,0.5)',
        borderRadius: '50%',
        opacity: '0.75',
        backgroundColor: '#d8d8d8',
    },
    circleText: {
        color: '#333333',
        minWidth: 16,
        fontSize: 9,
        paddingTop: 4,
        paddingBottom: 3,
        paddingLeft: 3,
        paddingRight: 3,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 9,
        textAlign: 'center'
    },
});

function Circle(props) {
    const { count, inProgress = false, showExactNumber = false } = props;
    return <View style={!inProgress ? styles.circle : styles.loadingCircle}>
        {!inProgress && <Text style={styles.circleText}>
            {count > 99 && !showExactNumber ? '99+' : count}
        </Text>}
    </View>;
}

Circle.propTypes = {
    count: PropTypes.number,
    inProgress: PropTypes.bool,
    showExactNumber: PropTypes.bool,
};

export default Circle;
