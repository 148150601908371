import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Image, Text, StyleSheet, TouchableOpacity } from 'react-native';

import { isActiveVM } from '../utils';
import { closedIcon } from '../../../icons';
import Modal from '../../General/Modal/Modal';
import CopypableText from '../../General/CopypableText/CopypableText';
import { IP_PROTECTED_POOL } from '../../../constants/cvds';
import { blockedButtonTooltips } from '../../../constants/general';
import { ModalContext } from '../../../contexts/ModalContext';
import { addCustomNotification } from '../../../actions/notifications/actions';
import DeleteProtectedIPModal from '../Modals/DeleteProtectedIPModal';
import AddProtectedIPModal from '../Modals/AddProtectedIPModal';

function getIps(ips) {
    return ips.filter((ip) => ip.pool === IP_PROTECTED_POOL);
}

const addIPModalId = 'add-protected-ip-modal';
const deleteIPModalId = 'delete-protected-ip-modal';

function ProtectedIpsListScreen() {
    const dispatch = useDispatch();
    const { currentVM } = useSelector((state) => state.cvds);
    const [deletedIP, setDeletedIP] = React.useState(null);
    const isEnabledActions = isActiveVM(currentVM.state) && currentVM.is_managed;
    const disabledText = (isActiveVM(currentVM.state) && !currentVM.is_managed)
        ? blockedButtonTooltips.cvds.DisableGuestAgent
        : blockedButtonTooltips.cvds[currentVM.state];

    const { openModal, closeModal, checkModal } = useContext(ModalContext);
    return <View style={styles.wrapper}>
        <View style={styles.headerBlock}>
            <Text style={styles.headerText}>
                IP-адреса
            </Text>
            <TouchableOpacity
                onPress={() => {
                    if (isEnabledActions) {
                        openModal(addIPModalId);
                    } else {
                        addCustomNotification(disabledText);
                    }
                }}
            >
                <Text style={[styles.blueText,
                    { color: isEnabledActions ? '#0F79D5' : '#888888' }
                ]}>Добавить</Text>
            </TouchableOpacity>
        </View>
        <View>
            {getIps(currentVM.ips).map((item) => <View key={`ddos-ip-${item.ip}`}
                style={styles.ipItem}>
                <CopypableText text={item.ip}/>
                <TouchableOpacity onPress={() => {
                    if (isEnabledActions) {
                        setDeletedIP(item.ip);
                        openModal(deleteIPModalId);
                    } else {
                        dispatch(addCustomNotification(disabledText));
                    }
                }} style={[{ opacity: isEnabledActions ? 1 : 0.5 }]}>
                    <Image source={closedIcon} style={styles.closedIcon} />
                </TouchableOpacity>
            </View>)}
        </View>
        <Modal isModalShowed={checkModal(deleteIPModalId)}
            component={<DeleteProtectedIPModal ip={deletedIP}
                ips={getIps(currentVM.ips)}
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
        <Modal isModalShowed={checkModal(addIPModalId)}
            component={<AddProtectedIPModal
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
    },
    closedIcon: {
        width: 13,
        height: 13
    },
    ipItem: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 15,
        paddingLeft: 18,
        paddingRight: 15,
        paddingBottom: 17,
        marginBottom: 1,
        backgroundColor: '#ffffff'
    },
    headerBlock: {
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginRight: 8,
        color: '#455562',
    },
    blueText: {
        fontSize: 12,
        fontWeight: '500',
        color: '#0f79d5',
    },
});

export default ProtectedIpsListScreen;
