import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, StyleSheet, Text, View } from 'react-native';

import Errors from './Errors';

function InputWithBlock(props) {
    const { textInputAttrs, errors, additionalBlock } = props;
    const { value } = textInputAttrs;

    const inputAttrs = {
        ...textInputAttrs
    };
    const styles = StyleSheet.create({
        wrapper: {
            width: '100%',
            position: 'relative',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
        },
        input: {
            width: '100%',
            borderColor: '#e4e4e4',
            borderStyle: 'solid',
            borderBottomWidth: 1,
            fontSize: 12,
            lineHeight: 19,
            color: '#455562',
            paddingRight: 12,
            paddingBottom: 5,
            backgroundColor: '#ffffff',
        },
        error: {
            borderColor: '#d06565',
        },
        additionalBlock: {
            paddingBottom: 5,
            borderColor: '#e4e4e4',
            borderStyle: 'solid',
            borderBottomWidth: 1,
            color: '#a1aab1',
            fontSize: 12,
            fontWeight: '400',
            lineHeight: 19,
        }
    });
    const additionalInputClasses = [];
    if (errors != null && errors.length > 0) {
        additionalInputClasses.push('error');
    }

    return <View>
        <View style={styles.wrapper}>
            {additionalBlock && <Text style={[styles.additionalBlock,
                ...additionalInputClasses.map((cls) => styles[cls])]}>
                {additionalBlock}
            </Text>}
            <TextInput style={[styles.input, ...additionalInputClasses.map((cls) => styles[cls])]}
                autoCompleteType='off'
                {...inputAttrs}>
            </TextInput>
        </View>
        <Errors errors={errors} />
    </View>;
}

InputWithBlock.propTypes = {
    label: PropTypes.string,
    errors: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string
    ]),
    additionalBlock: PropTypes.string,
    textInputAttrs: PropTypes.shape({}),
};

InputWithBlock.defaultProps = {
    errors: [],
    textInputAttrs: {}
};

export default InputWithBlock;
