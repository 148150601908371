import React from 'react';
import PropTypes from 'prop-types';
import { Picker } from '@react-native-picker/picker';
import { View, StyleSheet, Text } from 'react-native';

import { isFunction } from '../../../utils/general';
import AcceptButton from '../Modal/AcceptButton';
import CancelButton from '../Modal/CancelButton';


function MobileSelectModal(props) {
    const {
        onClose,
        onChange,
        itemList,
        selectTitle,
        currentItem,
    } = props;

    const [selectedValue, setSelectedValue] = React.useState(currentItem);
    return <View style={[styles.wrapper]}>
        {selectTitle && <Text style={[styles.title]}>{selectTitle}</Text>}
        <Picker
            selectedValue={selectedValue}
            onValueChange={(itemValue, itemIndex) =>
                setSelectedValue(itemValue)
            }>
            {itemList.map(item => <Picker.Item key={`picker-item-${item.index}`}
                label={item.label} value={item.index} />)}
        </Picker>
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => {
                    onChange(selectedValue);
                    if (isFunction(selectedValue.onSelected)) {
                        selectedValue.onSelected();
                    }
                    onClose();
                }}
                title="Выбрать"/>
            <CancelButton onClose={onClose} />
        </View>
    </View>;
}

MobileSelectModal.propTypes = {
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    selectTitle: PropTypes.string,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
})

export default MobileSelectModal;