import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, StyleSheet } from 'react-native';
import { getValueFromPercent } from '@netangels/core/utils/price';

import { flushErrors } from '../../../actions/forms/actions';
import { detectTariffByRam, getPriceForPacket } from '../utils';
import { calculationPacketCreate } from '../../../actions/hosting/actions';
import CreatePacketModal from '../../General/CreatePacketModal/CreatePacketModal';

const formId = 'HostingCreatePacketModalForm';

function transformToInt(v) {
    return parseInt(v, 10);
}

function HostingCreateAnnualModal(props) {
    const {
        errors,
        onClose,
        container,
        inProgress,
        flushErrors,
        calculation,
        calculationPacketCreate
    } = props;

    const {
        disk,
        redis,
        memory,
        memcached,
        currentPacketCost
    } = calculation;

    React.useEffect(() => {
        flushErrors();
    }, []);

    const fullCost = getValueFromPercent(currentPacketCost, 10);
    const descriptionParameters = [{
        value: detectTariffByRam(transformToInt(memory.value)),
        isChange: false,
        title: 'Тариф',
        format: v => v.title
    }, {
        value: transformToInt(disk.value) / 1024,
        isChange: false,
        title: 'Диск',
        format: v => `${v} ГБ`
    }, {
        value: memcached.value,
        isChange: false,
        title: 'Memcache',
        format: v => `${v} МБ`
    }, {
        value: redis.value,
        isChange: false,
        title: 'Redis',
        format: v => `${v} МБ`
    }];
    return <View style={styles.wrapper}>
        <Text style={[styles.title]}>Заказ годового пакета</Text>
        <CreatePacketModal
            inProgress={inProgress}
            descriptionParameters={descriptionParameters}
            priceForPacket={getPriceForPacket(currentPacketCost)}
            onAccept={() => calculationPacketCreate(formId, container.id, onClose)}
            priceForPacketAdvantage={getPriceForPacket(fullCost - currentPacketCost)}
            onClose={() => onClose()}
            errors={errors}/>
    </View>;
}

HostingCreateAnnualModal.propTypes = {
    inProgress: PropTypes.bool,
    container: PropTypes.shape({}),
    calculationPacketCreate: PropTypes.func,
};

const mapStateToProps = (state) => ({
    container: state.hosting.container,
    inProgress: state.hosting.createPacketInProgress,
    errors: state.forms.errors[formId] || {},
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    calculationPacketCreate, flushErrors
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 30,
        paddingLeft: 24,
        paddingRight: 21,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        textAlign: 'center',
        fontSize: 16,
        lineHeight: 18
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(HostingCreateAnnualModal);
