import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { NavigationProps } from '../types';

import { screenHeaderStyles } from '../utils/styles';
import HostingCreateContainerStep1 from '../components/Hosting/HostingCreateContainer/HostingCreateContainerStep1';
import HostingCreateContainerStep2 from '../components/Hosting/HostingCreateContainer/HostingCreateContainerStep2';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const HostingCreateContainerStack = createStackNavigator();

function HostingCreateContainerStackScreen(props) {
    const { navigation } = props;
    const insets = useSafeAreaInsets();

    return <HostingCreateContainerStack.Navigator>
        <HostingCreateContainerStack.Screen
            name="HostingCreateContainerStep1" component={HostingCreateContainerStep1} options={{
                headerTitle: () => <ScreenHeaderTitle title='Новый контейнер' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.navigate('Hosting'),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }}/>
        <HostingCreateContainerStack.Screen
            name="HostingCreateContainerStep2" component={HostingCreateContainerStep2} options={{
                headerTitle: () => <ScreenHeaderTitle title='Новый контейнер' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.navigate('HostingCreateContainerStep1'),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }}/>
    </HostingCreateContainerStack.Navigator>;
}

HostingCreateContainerStackScreen.propTypes = {
    navigation: NavigationProps
};

const mapStateToProps = (state) => ({
    user: state.user.user,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HostingCreateContainerStackScreen);
