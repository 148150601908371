import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View, Text, Image, StyleSheet, TouchableOpacity
} from 'react-native';

import { rightIcon } from '../../icons';
import VMInfoBlock from './VMInfoBlock';
import CVDSWebsockets from './CVDSWebsockets';
import cvdsTariffData from '../../../data/cvds/tariffs.json';
import VolumesBlock from './VolumesBlock';
import FinanceBlock from './Finance/FinanceBlock';
import StatisticsTenDaysCPU from './Statistics/StatisticsTenDaysCPU';
import StatisticsTenDaysRAM from './Statistics/StatisticsTenDaysRAM';
import { isEmpty } from '../../utils/general';
import GPUBlock from './GPUBlock';

const styles = StyleSheet.create({
    changeTariffButton: {
        flex: 1,
        marginTop: 21,
        marginLeft: 16,
        marginRight: 20,
        marginBottom: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    rightIcon: {
        height: 13,
        width: 7
    },
    tariffTitle: {
        color: '#455562',
        fontSize: 14,
        fontWeight: '600',
        lineHeight: 18
    }
});

function VMSummary() {
    const navigation = useNavigation();

    const vm = useSelector((state) => state.cvds.currentVM);

    const currentTariff = cvdsTariffData.find((cvdsTariff) => cvdsTariff.id === vm.tariff);

    return <View>
        {!isEmpty(vm) && <React.Fragment>
            <VMInfoBlock />
            <TouchableOpacity
                onPress={() => navigation.navigate('CVDSVMTariffs')}
                style={styles.changeTariffButton}>
                <Text style={styles.tariffTitle}>Тариф &laquo;{currentTariff.name}&raquo;</Text>
                <Image source={rightIcon} style={styles.rightIcon}/>
            </TouchableOpacity>
            <StatisticsTenDaysCPU/>
            <StatisticsTenDaysRAM/>
            {currentTariff.type === 'ultra' && <GPUBlock vm={vm} />}
            <VolumesBlock />
            <FinanceBlock />
        </React.Fragment>}
        <CVDSWebsockets />
    </View>;
}

export default VMSummary;
