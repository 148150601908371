import React, { useLayoutEffect, useRef } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';

import PaymentForm from '../../General/Payments/PaymentForm';
import {
    initPayment,
    downloadInvoice
} from '../../../actions/finances/actions';
import { downloadIcon, mailIcon, walletIcon } from '../../../icons';

function InvoiceActionsModal(props) {
    const {
        onClose,
        invoice,
        initPayment,
        downloadInvoice
    } = props;
    const [paymentForm, setPaymentForm] = React.useState(null);
    const paymentFormRef = useRef(null);
    const navigation = useNavigation();
    useLayoutEffect(() => {
        if (paymentForm != null && paymentFormRef.current != null) {
            paymentFormRef.current.submit();
        }
    }, [paymentForm])
    return <View style={styles.wrapper}>
        {paymentForm}
        <Text style={styles.title}>Счет №{invoice.number} от {dayjs(invoice.created_at,
            'YYYY-MM-DD').format('DD.MM.YYYY')} на {invoice.total.toFixed(2)} ₽</Text>
        <View>
            <TouchableOpacity style={styles.button}
                onPress={() => initPayment('Card', `${invoice.total}`, (data) => {
                    setPaymentForm(<PaymentForm {...data} innerRef={paymentFormRef} />)
                })}>
                <Image source={walletIcon} style={styles.walletIcon} />
                <Text style={styles.buttonText}>Оплатить банковской картой</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button}
                onPress={() => downloadInvoice(invoice.id, onClose)}>
                <Image source={downloadIcon} style={styles.downloadIcon} />
                <Text style={styles.buttonText}>Скачать счет</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button}
                onPress={() => {
                    navigation.navigate('SendInvoiceToEmail', { invoiceId: invoice.id });
                    onClose();
                }}>
                <Image source={mailIcon} style={styles.mailIcon} />
                <Text style={styles.buttonText}>Отправить на Email</Text>
            </TouchableOpacity>
        </View>
    </View>;
}

InvoiceActionsModal.propTypes = {
    invoice: PropTypes.shape({}),
    onClose: PropTypes.func,
    initPayment: PropTypes.func,
    downloadInvoice: PropTypes.func
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    initPayment, downloadInvoice
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingLeft: 6,
        paddingRight: 5,
        paddingBottom: 3
    },
    title: {
        opacity: 0.75,
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.38,
        lineHeight: 13,
        marginTop: 15,
        marginBottom: 14.5,
        marginLeft: 9,
        textTransform: 'uppercase'
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        flex: 1,
        paddingTop: 16,
        paddingLeft: 9,
        paddingBottom: 16,
        borderTopWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderTopColor: '#e4e4e4',
    },
    walletIcon: {
        height: 12,
        width: 15,
        marginRight: 17
    },
    downloadIcon: {
        height: 16,
        width: 15,
        marginRight: 17
    },
    mailIcon: {
        height: 12,
        width: 16,
        marginRight: 16
    },
    buttonText: {
        color: '#0f79d5',
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 16
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceActionsModal);