import React from 'react';
import { merge } from '@netangels/core/utils/functions';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { tabBarOptions } from '../utils/tab-bar';
import PartnershipInfoTab from '../components/Partnership/PartnershipInfoTab';
import PartnershipDetailTab from '../components/Partnership/PartnershipDetailTab';
import PartnershipClientsTab from '../components/Partnership/PartnershipClientsTab';

const PartnershipTab = createMaterialTopTabNavigator();

function PartnershipTabs() {
    return <PartnershipTab.Navigator
        tabBarOptions={merge(tabBarOptions, {
            tabStyle: {
                width: 110
            }
        })}>
        <PartnershipTab.Screen name="PartnershipInfo" component={PartnershipInfoTab} options={{
            title: 'Партнерская программа - Сводка',
            tabBarLabel: 'Сводка'
        }}/>
        <PartnershipTab.Screen name="PartnershipDetail" component={PartnershipDetailTab} options={{
            title: 'Партнерская программа - Детализация',
            tabBarLabel: 'Детализация',
        }}/>
        <PartnershipTab.Screen name="PartnershipClients" component={PartnershipClientsTab} options={{
            title: 'Партнерская программа - Клиенты',
            tabBarLabel: 'Клиенты',
        }}/>
    </PartnershipTab.Navigator>;
}

export default PartnershipTabs;
