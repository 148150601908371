import { FORM_ERRORS, FLUSH_ERRORS, FLUSH_ERROR } from './actionTypes';

export const flushErrors = () => async (dispatch) => {
    dispatch({
        type: FLUSH_ERRORS
    });
};

export const flushError = (formId, errorType) => async (dispatch) => {
    dispatch({
        type: FLUSH_ERROR,
        data: { formId, errorType }
    });
};

export const formErrors = (formId, errors) => async (dispatch) => {
    dispatch({
        type: FORM_ERRORS,
        data: { formId, errors }
    });
};
