import React from 'react';
import * as dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
} from 'react-native';

import Spring from '../../General/Spring/Spring';
import Button from '../../General/Button/Button';
import { containerBackupRestore } from '../../../actions/hosting/actions';

const formId = 'HostingBackupFormId';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

function RestoreContainerStep() {
    const { currentContainer, restoreInProgress, restoreDate } = useSelector(
        (state) => state.hostingRestoreFromBackup,
    );
    const dispatch = useDispatch();
    const navigation = useNavigation();

    return <View style={styles.wrapper}>
        <Text style={styles.title}>
            параметры восстановления
        </Text>
        <View style={styles.infoBlock}>
            <Text style={styles.infoText}>
                Восстановление файлов контейнера
                <Text style={styles.infoBoldText}>
                    «{currentContainer.name}» ({currentContainer.login}) на{' '}
                    {dayjs.utc(restoreDate).tz('Asia/Yekaterinburg').format(
                        'DD MMMM HH:mm',
                    )} (МСК+2){' '}
                </Text>
                в отдельный каталог
            </Text>
        </View>
        <Spring />
        <Button
            onPress={() => dispatch(
                containerBackupRestore(
                    formId,
                    currentContainer.id,
                    {
                        rewrite: false,
                        files: [],
                        date: restoreDate
                    },
                    () => navigation.navigate('HostingVirtualhosts', {
                        containerId: currentContainer.id,
                        containerName: currentContainer.name
                    }),
                ),
            )}
            title="Начать восстановление"
            inProgress={restoreInProgress}
            additionalWrapperStyles={[{ marginLeft: 20, marginRight: 20, marginBottom: 30 }]}
            additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 10 }]}
            additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, fontWeight: '400' }]}
        />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
    },
    title: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginBottom: 10,
        marginLeft: 20,
    },
    infoBlock: {
        paddingBottom: 16,
        paddingTop: 16,
        paddingLeft: 23,
        paddingRight: 29,
        backgroundColor: '#ffffff'
    },
    infoText: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 18,
    },
    infoBoldText: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 18,
        fontWeight: 500,
        marginLeft: 3,
        marginRight: 3,
    }
});

export default RestoreContainerStep;
