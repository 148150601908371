import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, StyleSheet } from 'react-native';

import { mbToGb } from '@netangels/core/utils/convert';

import { getPrice } from '../utils';
import { formatMemory } from '../../../utils/general';
import { changeDomainQuota } from '../../../actions/mail/actions';
import { flushErrors } from '../../../actions/forms/actions';
import SliderDynamic from '../../General/Slider/SliderDynamic';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'ChangeDomainQuotaModalForm';

function getQuotaScale(max) {
    const values = [];
    for (let value = 1; value <= max; value += 1) {
        values.push(value);
    }
    return values;
}

function ChangeDomainQuotaModal(props) {
    const {
        onClose,
    } = props;
    const dispatch = useDispatch();
    const { domain, userInfo, updateDomainInProgress } = useSelector((state) => state.mail);
    const { errors } = useSelector((state) => state.forms);
    const currentErrors = errors[formId] || {};
    const currentQuota = mbToGb(domain.quota === 0 ? domain.max_quota : domain.quota);
    const [quota, setQuota] = React.useState(currentQuota);
    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);
    const hint = `Списание происходит за фактически занятое место, превышающее общую бесплатную квоту (${formatMemory(userInfo.free_quota, true)}), по тарифу ${getPrice(1024)} ₽ за ГБ в месяц`;
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Дисковая квота для домена {domain.name}</Text>
        <SliderDynamic
            title='Лимит занимаемого места'
            items={getQuotaScale(mbToGb(domain.max_quota))}
            value={quota}
            unit='ГБ'
            hideBounds={true}
            onChange={(changedQuota) => {
                setQuota(changedQuota);
            }}/>
        <Text style={styles.hintText}>{hint}</Text>
        {currentErrors.__all__ && <Text style={styles.error}>{currentErrors.__all__}</Text>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => {
                    if (quota === currentQuota) {
                        onClose();
                        return;
                    }
                    dispatch(
                        changeDomainQuota(
                            formId,
                            domain.id,
                            Number.parseInt(quota, 10) * 1024,
                            () => onClose(),
                        ),
                    );
                }}
                title='Сохранить'
                inProgress={updateDomainInProgress}/>
            <CancelButton onClose={onClose} disabled={updateDomainInProgress} />
        </View>
    </View>;
}

ChangeDomainQuotaModal.propTypes = {
    onClose: PropTypes.func,
    domain: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        quota: PropTypes.number,
        max_quota: PropTypes.number,
    }),
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
    },
    hintText: {
        marginTop: 16,
        marginBottom: 24,
        color: '#455562',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 16,
    },
});

export default ChangeDomainQuotaModal;
