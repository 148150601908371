import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import {
    View, Text, Image, StyleSheet, TouchableOpacity
} from 'react-native';

import { rightIcon } from '../../icons';
import { isErrorStateVM } from './utils';
import { formatMemory } from '../../utils/general';
import cvdsStatesData from '../../../data/cvds/states.json';
import cvdsTariffData from '../../../data/cvds/tariffs.json';
import MultiStateBlock from '../General/MultiStateBlock/MultiStateBlock';
import ActionButton from '../General/ActionButton/ActionButton';

function VMList(props) {
    const { entities } = props.vmList;

    const navigation = useNavigation();

    const [isActionButtonOpen, setIsActionButtonOpen] = React.useState(true);
    useFocusEffect(
        React.useCallback(() => {
            setIsActionButtonOpen(true);
            return () => {
                setIsActionButtonOpen(false);
            };
        })
    );

    return (
        <View>
            {entities.map((vm, i) => {
                const tariffData = cvdsTariffData.find(
                    (tariff) => tariff.id === vm.tariff
                );
                return (
                    <TouchableOpacity
                        key={`vms-item-${i}`}
                        style={styles.vmBlock}
                        onPress={() => {
                            if (!isErrorStateVM(vm.state)) {
                                navigation.navigate('CVDSVM', { vmId: vm.id });
                            }
                        }}
                    >
                        <View style={styles.infoBlock}>
                            <Text
                                style={[
                                    styles.title,
                                    { opacity: isErrorStateVM(vm.state) ? 0.3 : 1 },
                                ]}
                            >
                                {vm.name}
                            </Text>
                            <Text
                                style={[
                                    styles.uid,
                                    { opacity: isErrorStateVM(vm.state) ? 0.3 : 1 },
                                ]}
                            >
                                {vm.uid}
                            </Text>
                            <Text
                                style={[
                                    styles.text,
                                    { opacity: isErrorStateVM(vm.state) ? 0.3 : 1 },
                                ]}
                            >
                                {tariffData.name} (CPUx{tariffData.features.cpu}, RAM{' '}
                                {formatMemory(tariffData.features.memory)}), Диски:{' '}
                                {vm.disk.size} ГБ
                            </Text>
                            <MultiStateBlock
                                state={vm.state.toLowerCase()}
                                title={cvdsStatesData[vm.state]}
                            />
                        </View>
                        {!isErrorStateVM(vm.state) && (
                            <Image source={rightIcon} style={styles.image} />
                        )}
                    </TouchableOpacity>
                );
            })}
            {isActionButtonOpen && (
                <ActionButton
                    isOpen={isActionButtonOpen}
                    type="+"
                    onPress={() => navigation.navigate('CVDSCreate')}
                />
            )}
        </View>
    );
}

VMList.propTypes = {
    vmList: PropTypes.shape({
        entities: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

const mapStateToProps = (state) => ({
    vmList: state.cvds.vmList,
});
const mapDispatchToProps = () => ({});

const styles = StyleSheet.create({
    vmBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        justifyContent: 'space-between',
        marginBottom: 1,
        paddingTop: 16,
        paddingBottom: 19,
        paddingLeft: 20,
        paddingRight: 20,
    },
    infoBlock: {
        flex: 1,
    },
    title: {
        marginBottom: 5,
        color: '#455562',
        fontSize: 13,
        lineHeight: 15,
        fontWeight: '600',
    },
    uid: {
        color: 'rgba(69,85,98,0.7)',
        fontSize: 10,
        lineHeight: 11,
        marginBottom: 4,
    },
    text: {
        marginBottom: 8,
        color: '#455562',
        fontSize: 10,
        lineHeight: 11,
    },
    image: {
        height: 12.55,
        width: 7,
    },
    addButton: {
        height: 47,
        width: 47,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 25,
        right: 25,
        backgroundColor: '#0f79d5',
        borderRadius: '50%',
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.25,
        shadowRadius: 6,
    },
    addButtonText: {
        fontSize: 28,
        color: '#ffffff',
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(VMList);
