import PropTypes from 'prop-types';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';

import Errors from '../../General/Form/Errors';
import { flushErrors } from '../../../actions/forms/actions';
import { cancelTransferByResource } from '../../../actions/transfer/actions';
import { resourceTypesList } from '../../../constants/general';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';
import { navigationHelper } from '../../../utils/transfer';

const formId = 'StartTransferFormId';

function RefuseTransferModal(props) {
    const {
        onClose,
        service,
        instance,
    } = props;
    const navigation = useNavigation();
    const { transferCancelInProgress } = useSelector(
        (state) => state.transfer,
    );
    const { errors } = useSelector((state) => state.forms);
    const resourceType = resourceTypesList.find(
        (r) => r.service === service,
    ).resource_type;

    const currentErrors = errors[formId] || {};
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);

    return <View style={styles.wrapper}>
        <Text style={styles.title}>Отказаться от передачи ресурсов</Text>
        {currentErrors.__all__ && <Errors errors={currentErrors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => dispatch(
                    cancelTransferByResource(
                        formId,
                        instance.id,
                        resourceType,
                        () => onClose(),
                        () => navigationHelper(service, navigation, instance),
                    )
                )}
                type='danger'
                title='Да'
                inProgress={transferCancelInProgress}/>
            <CancelButton onClose={onClose} disabled={transferCancelInProgress} title='Нет'/>
        </View>
    </View>;
}

RefuseTransferModal.propTypes = {
    onClose: PropTypes.func,
    instance: PropTypes.shape({}),
    service: PropTypes.string,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 30,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20
    },
    title: {
        marginBottom: 22,
        color: '#455562',
        fontSize: 16,
        lineHeight: 19
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default RefuseTransferModal;