import React, { useEffect } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../../icons';
import {
    fetchUserVirtualhosts,
    containerBackupsList,
    fetchUserContainers,
    fetchDbms,
    restoreFromBackupUpdateParams,
} from '../../actions/hosting/actions';
import { screenHeaderStyles } from '../../utils/styles';
import RestoreBackupListLoader from '../../components/General/RestoreBackupLoader/RestoreBackupListLoader';
import RestoreVirtualhostStep from '../../components/Hosting/HostingBackup/RestoreVirtualhostStep';
import ScreenHeaderTitle from '../../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const HostingBackupVirtualhostStack = createStackNavigator();

function HostingBackupVirtualhostStackScreen(props) {
    const { route } = props;
    const {
        backups,
        virtualhosts,
        backupsFetchInProgress,
        containers,
        containersFetchInProgress,
    } = useSelector(
        (state) => state.hosting,
    );
    const { currentContainer, restoreDate, currentVirtualhostObject } = useSelector(
        (state) => state.hostingRestoreFromBackup,
    );
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();

    const containerId = Number.parseInt(route.params.containerId, 10);
    const currentRestoreDate = route.params.restoreDate
        && dayjs.unix(route.params.restoreDate).format('YYYY-MM-DD HH:mm');
    const { virtualhost } = route.params;
    useEffect(() => {
        dispatch(fetchUserVirtualhosts(containerId));
        if (currentVirtualhostObject) {
            dispatch(fetchDbms(currentVirtualhostObject.id));
        }
        if (!containersFetchInProgress) {
            dispatch(fetchUserContainers());
        }
        dispatch(
            restoreFromBackupUpdateParams({
                restoreDateFromParams: currentRestoreDate,
                currentVirtualhost: virtualhost
            }),
        );
        if (restoreDate == null) {
            dispatch(
                restoreFromBackupUpdateParams({
                    restoreDate: currentRestoreDate,
                }),
            );
        }
    }, []);

    useEffect(() => {
        if (currentContainer) {
            dispatch(containerBackupsList(currentContainer.id));
        } else if (containers) {
            const container = containers.entities.find(
                (c) => c.id === containerId,
            );
            dispatch(
                restoreFromBackupUpdateParams(
                    {
                        currentMethod: 'rewrite',
                        currentContainer: container,
                    },
                    () => dispatch(containerBackupsList(container.id)),
                ),
            );
        }
    }, [containers]);
    useEffect(() => {
        if (
            currentVirtualhostObject == null && virtualhosts
            && virtualhosts.entities.length > 0
        ) {
            const virtualhostObject = virtualhosts.entities.find(
                (c) => c.name === virtualhost,
            );
            dispatch(
                restoreFromBackupUpdateParams(
                    {
                        currentVirtualhostObject: virtualhostObject,
                    },
                    () => dispatch(fetchDbms(virtualhostObject.id)),
                ),
            );
        }
    }, [virtualhosts]);
    useEffect(() => {
        if (backups && backups.entities && backups.entities.length > 0 && restoreDate == null) {
            dispatch(
                restoreFromBackupUpdateParams({
                    restoreDate: backups.entities[0].date,
                }),
            );
        }
    }, [backups]);

    return <HostingBackupVirtualhostStack.Navigator>
        <HostingBackupVirtualhostStack.Screen name="HostingBackupVirtualhostScreen"
            component={
                (backups == null || backupsFetchInProgress)
                    ? RestoreBackupListLoader : RestoreVirtualhostStep}
            options={{
                headerTitle: () => <ScreenHeaderTitle title={virtualhost} />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => {
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        } else {
                            navigation.navigate('HostingBackupResources', { containerId });
                        }
                    },
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </HostingBackupVirtualhostStack.Navigator>;
}

HostingBackupVirtualhostStackScreen.propTypes = {
    route: PropTypes.object,
};

export default HostingBackupVirtualhostStackScreen;
