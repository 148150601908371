import React, { useEffect, useContext } from 'react';
import * as dayjs from 'dayjs';
import {
    Text,
    View,
    StyleSheet,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import {
    getVolumesSizeByShortType,
    isSasDisk,
    isSsdDisk,
    isNvmeDisk,
    getBackupsSize,
} from '../../utils/cvds';
import {
    servicesResourceNameMap,
    servicesResourceBatchNameMap,
} from '../../constants/general';
import {
    formatMemory,
    isEmpty,
    pluralize,
    normalizeContainerPrice
} from '../../utils/general';
import BatchResourceCard from './BatchResourceCard';
import { financeInfoByConfiguration } from '../../actions/cvds/actions';
import { getContainerCalculation } from '../../actions/hosting/actions';
import ResourceCard from './ResourceCard';
import ResourcesTables from './ResourcesTables';
import Button from '../General/Button/Button';
import Modal from '../General/Modal/Modal';
import { ModalContext } from '../../contexts/ModalContext';
import { getContainerTariffInfo, getVmTariffInfo } from '../../utils/transfer';
import Spring from '../General/Spring/Spring';
import RefuseTransferModal from './Modals/RefuseTransferModal';
import AcceptTransferModal from './Modals/AcceptTransferModal';

const acceptTransferModalId = 'accept-transfer-modal';
const refuseTransferModalId = 'refuse-transfer-modal';

function resourceDescription(service, instance, calculation) {
    if (service === 'vms' && !isEmpty(calculation)) {
        return getVmTariffInfo(instance, calculation);
    }
    if (service === 'containers' && !isEmpty(calculation)) {
        return getContainerTariffInfo(instance, calculation);
    }
    if (service === 'mail_domains') {
        return `Количество ящиков: ${
            instance.mailboxes_count
        }, занято ${formatMemory(instance.used, true)}`;
    }
    if (service === 'zones') {
        return `Количество записей: ${instance.records_count}`;
    }
    if (service === 'domains') {
        const prolongTime = dayjs(
            instance.prolong_time,
            'YYYY-MM-DD HH:mm',
        ).format('DD.MM.YYYY');
        return `Действует до ${prolongTime}`;
    }
    if (service === 'certificates') {
        const notBefore = dayjs(
            instance.not_after,
            'YYYY-MM-DD HH:mm:ss',
        ).format('DD.MM.YYYY');
        return `Действует до ${notBefore}`;
    }
    return '';
}

function resourceDescriptionBatch(service, instance) {
    if (service === 'zones') {
        return `${instance.records_count} ${pluralize(
            instance.records_count,
            ['запись', 'записи', 'записей'],
        )}`;
    }
    if (service === 'domains') {
        const prolongTime = dayjs(
            instance.prolong_time,
            'YYYY-MM-DD HH:mm',
        ).format('DD.MM.YYYY');
        return `Действует до ${prolongTime}`;
    }
    if (service === 'certificates') {
        const notBefore = dayjs(
            instance.not_after,
            'YYYY-MM-DD HH:mm:ss',
        ).format('DD.MM.YYYY');
        return `Действует до ${notBefore}`;
    }
    return '';
}

function AcceptPage() {
    const dispatch = useDispatch();
    const { openModal, closeModal, checkModal } = useContext(ModalContext);
    const { transferData, service, instance } = useSelector((state) => state.transfer);
    const { task, resources } = transferData;
    const { containerCalculation } = useSelector((state) => state.hosting);
    const vmCalculation = useSelector((state) => state.cvdsFinanceInfo.calculation);

    useEffect(() => {
        if (service === 'vms') {
            dispatch(financeInfoByConfiguration({
                tariff: instance.tariff,
                is_paid: true,
                ip_count: instance.ips.length,
                storage_sas: getVolumesSizeByShortType(instance, isSasDisk),
                storage_ssd: getVolumesSizeByShortType(instance, isSsdDisk),
                storage_nvme: getVolumesSizeByShortType(instance, isNvmeDisk),
                backup_size: getBackupsSize(instance),
                bandwidth: instance.bandwidth,
            }));
        } else if (service === 'containers') {
            const configuration = {
                memory: instance.memory_limit,
                disk: instance.quota,
            };
            if (instance.redis) {
                configuration.redis = instance.redis.memory_limit;
            }
            if (instance.memcache) {
                configuration.memcached = instance.memcache.memory_limit;
            }
            dispatch(getContainerCalculation(configuration));
        }
    }, []);
    let uidText = '';
    let price = null;
    if (service === 'vms' && !isEmpty(vmCalculation)) {
        uidText = `ID: ${instance.uid}`;
        let monthCost = vmCalculation.cost.month.toFixed(2);
        if (vmCalculation.cost_without_packet) {
            monthCost = vmCalculation.cost_without_packet.month.toFixed(2);
        }
        price = `${monthCost} ₽/мес`;
    } else if (service === 'containers' && !isEmpty(containerCalculation)) {
        uidText = `Логин: ${instance.login}`;
        price = `${normalizeContainerPrice(
            containerCalculation.fullMonthPrice,
        )} ₽/мес`;
    }
    return (
        <View style={styles.wrapper}>
            <Text style={styles.senderBlock}>
                Завершите процедуру передачи контейнера от пользователя {task.sender} ({task.sender_name}) на ваш аккаунт
            </Text>
            {instance && (
                <React.Fragment>
                    {(!resources[service] || resources[service].length === 1) && (
                        <React.Fragment>
                            <View>
                                <View style={styles.headerBlock}>
                                    <Text style={styles.title}>
                                        {servicesResourceNameMap[service]}
                                    </Text>
                                    <Text
                                        style={styles.inProgressText}
                                    >
                                        В процессе передачи
                                    </Text>
                                </View>
                            </View>
                            <ResourceCard
                                service={service}
                                name={instance.name}
                                uidText={uidText}
                                price={price}
                                description={resourceDescription(
                                    service,
                                    instance,
                                    service === 'vms'
                                        ? vmCalculation
                                        : containerCalculation,
                                )}
                            />
                            {(resources.zones.length !== 0
                                || resources.certificates.length !== 0
                                || resources.domains.length !== 0) && <ResourcesTables
                                service={service}
                                instance={instance}
                                resources={resources}
                                transferInProgress={true}
                                description="дополнительно передаются"
                            />}
                        </React.Fragment>
                    )}
                    {resources[service] && resources[service].length > 1 && (
                        <React.Fragment>
                            <View>
                                <View style={styles.headerBlock}>
                                    <Text style={styles.title}>
                                        {servicesResourceBatchNameMap[service]}
                                    </Text>
                                    <Text
                                        style={styles.inProgressText}
                                    >
                                        В процессе передачи
                                    </Text>
                                </View>
                            </View>
                            <BatchResourceCard
                                service={service}
                                resources={resources[service]}
                                resourceDescriptionFunc={(resource) => resourceDescriptionBatch(
                                    service, resource
                                )}
                            />
                        </React.Fragment>
                    )}
                    <Spring />
                    <View style={styles.buttonBlock}>
                        <Button
                            title="Принять ресурсы"
                            onPress={() => openModal(acceptTransferModalId)}
                            additionalWrapperStyles={[{ marginLeft: 20, marginRight: 20 }]}
                            additionalButtonStyles={[{ paddingTop: 12, paddingBottom: 12 }]}
                            additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, fontWeight: '400' }]}
                        />
                        <Button
                            type='white'
                            onPress={() => openModal(refuseTransferModalId)}
                            title='Отказаться'
                            additionalWrapperStyles={[{
                                marginLeft: 20, marginTop: 10, marginRight: 20
                            }]}
                            additionalButtonStyles={[{ paddingTop: 12, paddingBottom: 12 }]}
                            additionalButtonTextStyles={[{
                                fontSize: 14,
                                lineHeight: 16,
                                fontWeight: '400',
                                color: '#455562'
                            }]}
                        />
                    </View>
                </React.Fragment>
            )}
            <Modal isModalShowed={checkModal(acceptTransferModalId)}
                component={<AcceptTransferModal
                    service={service}
                    task={task}
                    instance={instance}
                    onClose={() => closeModal()}/>}
                onClose={() => closeModal()} />
            <Modal isModalShowed={checkModal(refuseTransferModalId)}
                component={<RefuseTransferModal
                    service={service}
                    instance={instance}
                    onClose={() => closeModal()}/>}
                onClose={() => closeModal()} />
        </View>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
        backgroundColor: '#f6f6f6',
        flex: 1,
        paddingBottom: 30
    },
    senderBlock: {
        color: '#455562',
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 16,
        paddingTop: 17,
        paddingRight: 17,
        paddingLeft: 21,
        paddingBottom: 15,
        marginBottom: 30,
        backgroundColor: '#ffffff',
    },
    title: {
        opacity: 0.75,
        paddingTop: 2,
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.38,
        lineHeight: 13,
        textTransform: 'uppercase'
    },
    headerBlock: {
        flex: 1,
        marginBottom: 7,
        paddingLeft: 20,
        paddingRight: 17,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inProgressText: {
        color: '#ffffff',
        fontSize: 9,
        lineHeight: 11,
        paddingTop: 4,
        paddingBottom: 3,
        paddingLeft: 9,
        paddingRight: 9,
        backgroundColor: '#e28a58',
        borderRadius: 50,
    },
    buttonBlock: {
        marginTop: 30,
    }
});

export default AcceptPage;
