import React from 'react';
import { View, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    separator: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#E4E4E4',
        width: '100%'
    }
});

function Separator() {
    return <View style={[styles.separator]} />;
}

export default Separator;
