import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, Image, Text, StyleSheet } from 'react-native';

import { bToMb } from '@netangels/core/utils/convert';

import { mailProIcon } from '../../../icons';
import Errors from '../../General/Form/Errors';
import { formatMemory } from '../../../utils/general';
import { updateMailQuota } from '../../../actions/mail/actions';
import HorizontalBarChart from '../../General/HorizontalBarChart/HorizontalBarChart';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'ActivateMailPaid';

function CancelMailPaidQuotaModal(props) {
    const {
        onClose,
    } = props;
    const { errors } = useSelector((state) => state.forms);
    const currentErrors = errors[formId] || {};
    const {
        userInfo,
        userStatisticsInfo,
        updateMailQuotaInProgress
    } = useSelector((state) => state.mail);
    const dispatch = useDispatch();

    const current = userStatisticsInfo && bToMb(userStatisticsInfo.summary.used);
    const paidUsed = current - userInfo.free_quota;
    const resources = [
        {
            id: `free:${userInfo.free_quota}`,
            title: 'Бесплатная квота',
            colorType: '#29ac7a',
            value: userInfo.free_quota,
            formatValue: (v) => formatMemory(v, true),
        },
    ];
    if (paidUsed > 0) {
        resources.push({
            id: `paid:${current}`,
            title: 'Дополнительное пространство',
            colorType: '#eaa641',
            value: paidUsed,
            formatValue: (v) => formatMemory(v, true),
        });
    }
    return (
        <View style={styles.wrapper}>
            <View style={styles.headerBlock}>
                <Image source={mailProIcon} style={styles.icon} />
                <Text style={styles.headerTitle}>Почта.</Text>
                <Text style={styles.headerTitleBlue}>
                    про
                </Text>
            </View>
            <Text style={styles.totalQuotaText}>
                Всего занято {formatMemory(current, true)}
            </Text>
            <View>
                <HorizontalBarChart
                    hideTotal={true}
                    resources={resources}
                    needLegendColorBox={true}
                    needLegendBackground={false}
                    horizontalBarChartLegendStyles={styles.horizontalBarChartLegend}
                    horizontalBarChartWrapperStyles={styles.horizontalBarChartWrapper}
                    horizontalBarChartStatisticStyles={styles.horizontalBarChartStatistic}
                    horizontalBarChartLegendValueStyles={styles.horizontalBarChartLegendValue}
                    horizontalBarChartLegendTitleStyles={styles.horizontalBarChartLegendTitle}
                    horizontalBarChartLegendResourceStyles={styles.horizontalBarChartLegendResource}
                />
            </View>
            <Text style={styles.description}>
                Если вы хотите отключить{' '}
                <Text style={styles.descriptionBold}>
                    Почту.
                    <Text style={styles.descriptionBlue}>
                        про
                    </Text>
                </Text>{' '}
                и отказаться от дополнительного пространства,
                вам необходимо очистить свои почтовые ящики,
                чтобы вся ваша почта укладывалась в бесплатную
                квоту {formatMemory(userInfo.free_quota, true)}
            </Text>
            {currentErrors.__all__ && <Errors errors={currentErrors.__all__} />}
            <View>
                <View style={styles.buttonBlock}>
                    <AcceptButton
                        onSubmit={() => dispatch(
                            updateMailQuota(formId, false, () => onClose()),
                        )}
                        type='danger'
                        title='Отключить'
                        inProgress={updateMailQuotaInProgress}/>
                    <CancelButton onClose={onClose} disabled={updateMailQuotaInProgress} />
                </View>
            </View>
        </View>
    );
}

CancelMailPaidQuotaModal.propTypes = {
    onClose: PropTypes.func,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
    },
    headerBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerTitle: {
        color: '#455562',
        lineHeight: 19,
        fontSize: 16,
        fontWeight: '500',
    },
    headerTitleBlue: {
        color: '#0f79d5',
        lineHeight: 19,
        fontSize: 16,
        fontWeight: '500',
    },
    totalQuotaText: {
        color: '#455562',
        lineHeight: 16,
        fontSize: 14,
        fontWeight: '500',
        marginBottom: 0,
        marginTop: 20,
    },
    icon: {
        width: 24,
        height: 24,
        marginRight: 16,
    },
    description: {
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 16,
        marginTop: 8,
        marginBottom: 8,
        color: '#455562',
    },
    descriptionBold: {
        marginLeft: 2,
        fontWeight: '500',
        color: '#455562',
    },
    descriptionBlue: {
        fontWeight: '500',
        color: '#0f79d5',
    },
    horizontalBarChartWrapper: {
        marginBottom: 16,
        marginTop: 8,
        flex: 1,
        flexDirection: 'column-reverse',
    },
    horizontalBarChartLegend: {
        flexDirection: 'column',
    },
    horizontalBarChartLegendValue: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14,
        fontWeight: 400,
    },
    horizontalBarChartLegendTitle: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14,
        fontWeight: 500,
        flex: 1,
    },
    horizontalBarChartLegendResource: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 0,
        marginRight: 0,
        marginTop: 12,
        alignItems: 'center',
    },
    horizontalBarChartStatistic: {
        height: 20,
    },
});

export default CancelMailPaidQuotaModal;
