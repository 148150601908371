import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    Image
} from 'react-native';

import {
    rightIcon,
} from '../../../icons';
import { fetchCVDSBackupDates, restoreFromBackupUpdateParams } from '../../../actions/cvds/actions';
import Spring from '../../General/Spring/Spring';

function RestoreBackupDisksStep() {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const { currentVM } = useSelector((state) => state.cvdsRestoreFromBackup);
    let activeList = [];
    let deletedList = [];
    if (currentVM) {
        activeList = currentVM.resources.filter(
            (entity) => entity.deleted == null,
        );
        deletedList = currentVM.resources.filter(
            (entity) => entity.deleted != null,
        );
    }
    const onClickFunction = (item) => {
        dispatch(
            fetchCVDSBackupDates(
                item.resource_url,
                (backupDates) => {
                    if (backupDates.entities.length === 0) {
                        return;
                    }
                    const {
                        backup_date,
                        resource_name,
                        resource_type,
                        methods,
                    } = backupDates.entities[0];
                    dispatch(
                        restoreFromBackupUpdateParams({
                            backupDate: backup_date,
                            resourceName: resource_name,
                            resourceType: resource_type,
                            backupMethod: methods[0],
                            backupMethods: methods,
                        }),
                    );
                },
            ),
        );
        dispatch(
            restoreFromBackupUpdateParams(
                {
                    currentVolume: item,
                },
                () => navigation.navigate('CVDSBackupMethods', { vmId: currentVM.id, volumeId: item.id }),
            ),
        );
    };

    return (
        <View style={styles.wrapper}>
            {activeList && activeList.length > 0 && <Text style={styles.title}>
                подключенные диски
            </Text>}
            {activeList.map((item) => (
                <View key={`volume-active-backup-${item.uid}`}>
                    <TouchableOpacity
                        style={styles.resourceBlock}
                        onPress={() => onClickFunction(item)}
                    >
                        <View style={styles.resourceBlockTextBlock}>
                            <Text style={styles.resourceBlockTitle}>
                                {item.name}
                            </Text>
                            <Text style={styles.resourceBlockText}>
                                {item.uid}
                            </Text>
                        </View>
                        <Spring />
                        <Text style={styles.resourceBlockInfo}>
                            {item.size} ГБ
                        </Text>
                        <Image source={rightIcon} style={styles.image}/>
                    </TouchableOpacity>
                </View>
            ))}
            {deletedList && deletedList.length > 0 && <Text style={styles.title}>
                удаленные диски
            </Text>}
            {deletedList.map((item) => (
                <View key={`volume-deleted-backup-${item.uid}`}>
                    <TouchableOpacity
                        style={styles.resourceBlock}
                        onPress={() => onClickFunction(item)}
                    >
                        <View style={styles.resourceBlockTextBlock}>
                            <Text style={styles.resourceBlockTitle}>
                                {item.name}
                            </Text>
                            <Text style={styles.resourceBlockText}>
                                {item.uid}
                            </Text>
                        </View>
                        <Spring />
                        <Text style={styles.resourceBlockInfo}>
                            {item.size} ГБ
                        </Text>
                        <Image source={rightIcon} style={styles.image}/>
                    </TouchableOpacity>
                </View>
            ))}
        </View>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
    },
    title: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginBottom: 10,
        marginLeft: 20,
    },
    resourceBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        paddingLeft: 20,
        paddingBottom: 12,
        paddingTop: 12,
        paddingRight: 20,
        marginBottom: 1,
    },
    resourceBlockTitle: {
        marginBottom: 2,
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 16
    },
    resourceBlockText: {
        color: '#586A7B',
        fontSize: 11,
        lineHeight: 14
    },
    resourceBlockInfo: {
        color: '#455562',
        fontSize: 12,
        marginRight: 30,
        fontWeight: '400',
    },
    image: {
        width: 7,
        height: 13,
    }
});

export default RestoreBackupDisksStep;
