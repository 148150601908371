import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    View, StyleSheet, Text, Image, Linking, ScrollView, TouchableOpacity
} from 'react-native';

import {
    partnersLaunchIcon,
    partnershipClientIcon,
    partnershipBringClientIcon,
    partnershipReceiveMoneyIcon
} from '../../icons';
import { isEmpty } from '../../utils/general';
import Errors from '../General/Form/Errors';
import Button from '../General/Button/Button';
import Checkbox from '../General/Form/Checkbox';
import { createPartnership } from '../../actions/partnership/actions';

const formId = 'PartnershipCreate';

function PartnershipLaunchPage(props) {
    const { errors, inProgress, createPartnership } = props;
    const advantages = [{
        icon: partnershipBringClientIcon,
        title: 'Вы приводите нового клиента в компанию NetAngels'
    }, {
        icon: partnershipClientIcon,
        title: 'Клиент заказывает любые услуги и оплачивает их'
    }, {
        icon: partnershipReceiveMoneyIcon,
        title: 'Вы получаете комиссию со всех оплаченных клиентом заказов'
    }];
    const [isAgree, setIsAgree] = React.useState(false);
    return <ScrollView>
        <View style={styles.wrapper}>
            <Image source={partnersLaunchIcon} style={styles.image} />
            <View style={styles.content}>
                <Text style={styles.title}>Получайте&nbsp;
                    <Text style={styles.bold}>до 30%</Text>&nbsp;пожизненно&nbsp;
                </Text>
                <Text style={styles.description}>
                    со всех услуг, оплаченных вашими клиентами
                </Text>
            </View>
            <View style={styles.advantages}>
                {advantages.map((item, i) => <View key={`partnership-advantages-${i}`}
                    style={styles.advantage}>
                    <Image source={item.icon} style={styles.advantageIcon} />
                    <Text style={styles.advantageTitle}>{item.title}</Text>
                </View>)}
            </View>
            <View style={styles.argeeBlock}>
                <Checkbox
                    value={isAgree}
                    onValueChange={() => setIsAgree(!isAgree)}
                    additionalIconStyles={[{ maxWidth: 18 }]}
                >
                    <Text style={styles.agreeText}>
                        Я соглашаюсь с&nbsp;
                        <TouchableOpacity activeOpacity={1} style={styles.link}
                            onPress={() => Linking.openURL('https://panel.netangels.ru/documents/ag_contract/', '_blank')}>
                            условиями договора
                        </TouchableOpacity>
                    </Text>
                </Checkbox>
                {!isEmpty(errors) && <Errors errors={errors.__all__ || errors.is_accept}/>}
            </View>
            <Button title='Подключиться к программе'
                disabled={!isAgree}
                inProgress={inProgress}
                onPress={() => createPartnership(formId, isAgree)}
                additionalWrapperStyles={[{ width: '100%' }]}
                additionalButtonStyles={[{ paddingTop: 12, paddingBottom: 13 }]}
                additionalButtonTextStyles={[{
                    fontSize: 13,
                    lineHeight: 15,
                    minHeight: 15,
                    fontWeight: '400',
                }]}/>
        </View>
    </ScrollView>;
}

PartnershipLaunchPage.propTypes = {
    errors: PropTypes.shape({}),
    inProgress: PropTypes.bool,
    createPartnership: PropTypes.func,
};

const mapStateToProps = (state) => ({
    inProgress: state.partnership.createPartnershipInProgress,
    errors: state.forms.errors[formId] || {},
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createPartnership,
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        margin: 0,
        paddingTop: 35,
        paddingLeft: 20,
        paddingRight: 18,
        paddingBottom: 30,
        flex: 1,
        width: '100%',
        flexDirection: 'colume',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        minHeight: 156,
        minWidth: 220,
        marginBottom: 19,
        marginRight: 40,
    },
    title: {
        maxWidth: 320,
        textAlign: 'center',
        fontSize: 16,
        color: '#455562',
        letterSpacing: 0,
        lineHeight: 19,
        marginBottom: 1,
    },
    description: {
        maxWidth: 258,
        fontSize: 11,
        textAlign: 'center',
        letterSpacing: 0,
        color: '#455562',
        lineHeight: 20,
        marginBottom: 16,
    },
    advantages: {
        width: '100%',
    },
    advantage: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'start',
    },
    advantageIcon: {
        width: 70,
        height: 70,
        marginRight: 4,
    },
    advantageTitle: {
        fontSize: 11,
        lineHeight: 14,
        color: '#455562',
        maxWidth: 190,
        marginTop: 18,
    },
    argeeBlock: {
        marginLeft: 50,
        width: '100%',
        marginBottom: 18,
    },
    agreeText: {
        fontSize: 11,
        lineHeight: 14,
        color: '#455562',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    link: {
        color: '#0F79D5',
    },
    bold: {
        fontWeight: '500',
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnershipLaunchPage);