export const ACCOUNT_INFO_SUCCESSFULLY = 'ACCOUNT_INFO_SUCCESSFULLY';
export const ACCOUNT_INFO_IN_PROGRESS = 'ACCOUNT_INFO_IN_PROGRESS';
export const ACCOUNT_INFO_WITH_ERRORS = 'ACCOUNT_INFO_WITH_ERRORS';

export const ACCOUNT_CHANGE_PHONE_IN_PROGRESS = 'ACCOUNT_CHANGE_PHONE_IN_PROGRESS';
export const ACCOUNT_CHANGE_PHONE_SUCCESSFULLY = 'ACCOUNT_CHANGE_PHONE_SUCCESSFULLY';
export const ACCOUNT_CHANGE_PHONE_WITH_ERRORS = 'ACCOUNT_CHANGE_PHONE_WITH_ERRORS';

export const ACCOUNT_VERIFY_PHONE = 'ACCOUNT_VERIFY_PHONE';
export const ACCOUNT_VERIFIED_PHONE = 'ACCOUNT_VERIFIED_PHONE';

export const CHANGE_NOTIFICATION_PHONE = 'CHANGE_NOTIFICATION_PHONE';
export const CHANGE_NOTIFICATION_PHONE_WITH_ERRORS = 'CHANGE_NOTIFICATION_PHONE_WITH_ERRORS';

export const CHANGE_REQUISITES_IN_PROGRESS = 'CHANGE_REQUISITES_IN_PROGRESS';
export const CHANGE_REQUISITES_SUCCESSFULLY = 'CHANGE_REQUISITES_SUCCESSFULLY';
export const CHANGE_REQUISITES_WITH_ERRORS = 'CHANGE_REQUISITES_WITH_ERRORS';

export const CHANGE_PASSWORD_SUCCESSFULLY = 'CHANGE_PASSWORD_SUCCESSFULLY';
export const CHANGE_PASSWORD_IN_PROGRESS = 'CHANGE_PASSWORD_IN_PROGRESS';
export const CHANGE_PASSWORD_WITH_ERRORS = 'CHANGE_PASSWORD_WITH_ERRORS';

export const ADD_EMAIL_SUCCESSFULLY = 'ADD_EMAIL_SUCCESSFULLY';
export const ADD_EMAIL_IN_PROGRESS = 'ADD_EMAIL_IN_PROGRESS';
export const ADD_EMAIL_WITH_ERRORS = 'ADD_EMAIL_WITH_ERRORS';

export const DELETE_EMAIL_IN_PROGRESS = 'DELETE_EMAIL_IN_PROGRESS';
export const DELETE_EMAIL_SUCCESSFULLY = 'DELETE_EMAIL_SUCCESSFULLY';
export const DELETE_EMAIL_WITH_ERRORS = 'DELETE_EMAIL_WITH_ERRORS';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';

export const GET_OTP_KEY = 'GET_OTP_KEY';
export const ACCOUNT_ENABLE_OTP_IN_PROGRESS = 'ACCOUNT_ENABLE_OTP_IN_PROGRESS';
export const ACCOUNT_ENABLE_OTP_SUCCESSFULLY = 'ACCOUNT_ENABLE_OTP_SUCCESSFULLY';
export const ACCOUNT_ENABLE_OTP_WITH_ERRORS = 'ACCOUNT_ENABLE_OTP_WITH_ERRORS';
export const ACCOUNT_DISABLE_OTP_IN_PROGRESS = 'ACCOUNT_DISABLE_OTP_IN_PROGRESS';
export const ACCOUNT_DISABLE_OTP_SUCCESSFULLY = 'ACCOUNT_DISABLE_OTP_SUCCESSFULLY';
export const ACCOUNT_DISABLE_OTP_WITH_ERRORS = 'ACCOUNT_DISABLE_OTP_WITH_ERRORS';
