import React, { useEffect } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ResourceCard from './ResourceCard';
import ResourcesTables from './ResourcesTables';
import { formatMemory, normalizeContainerPrice } from '../../utils/general';
import { vmFinanceInfo } from '../../actions/cvds/actions';
import {
    fetchContainerResources,
    fetchVmResources,
    fetchMailDomainResources,
} from '../../actions/transfer/actions';
import { flushErrors } from '../../actions/forms/actions';
import { fetchContainerCalculation } from '../../actions/hosting/actions';
import { getContainerTariffInfo, getVmTariffInfo } from '../../utils/transfer';

function resourceDescription(instance, service) {
    if (service === 'vms') {
        return getVmTariffInfo(instance);
    }
    if (service === 'containers') {
        return getContainerTariffInfo(instance);
    }
    if (service === 'mail_domains') {
        return `Количество ящиков: ${
            instance.mailboxes_count
        }, занято ${formatMemory(instance.used, true)}`;
    }
    if (service === 'zones') {
        return `Количество записей: ${instance.records_count}`;
    }
    if (service === 'domains') {
        const prolongTime = dayjs(
            instance.prolong_time,
            'YYYY-MM-DD HH:mm',
        ).format('DD.MM.YYYY');
        return `Действует до ${prolongTime}`;
    }
    if (service === 'certificates') {
        const notBefore = dayjs(
            instance.not_after,
            'YYYY-MM-DD HH:mm:ss',
        ).format('DD.MM.YYYY');
        return `Действует до ${notBefore}`;
    }
    return '';
}

function TransferPageContainer(props) {
    const {
        service,
        instance,
    } = props;
    let uidText = '';
    let price = null;
    let description = '';
    const dispatch = useDispatch();
    const { relatedResources } = useSelector((state) => state.transfer);
    const containerCalculation = useSelector((state) => state.hosting.calculation);
    const vmCalculation = useSelector((state) => state.cvdsFinanceInfo.calculation);
    const isEmptyRelatedResources = Object.keys(relatedResources).reduce(
        (accumulator, currentValue) => accumulator + relatedResources[currentValue].length, 0
    ) === 0;
    if (service === 'vms' && vmCalculation) {
        uidText = `ID: ${instance.uid}`;
        let monthCost = vmCalculation.cost.month.toFixed(2);
        if (vmCalculation.cost_without_packet) {
            monthCost = vmCalculation.cost_without_packet.month.toFixed(2);
        }
        price = `${monthCost} ₽/мес`;
        description = isEmptyRelatedResources
            ? ''
            : 'передать дополнительно';
    } else if (service === 'containers' && containerCalculation) {
        uidText = `Логин: ${instance.login}`;
        price = `${normalizeContainerPrice(
            containerCalculation.calculation.fullMonthPrice,
        )} ₽/мес`;
        description = isEmptyRelatedResources
            ? ''
            : 'передать дополнительно';
    } else if (service === 'mail_domains') {
        description = isEmptyRelatedResources
            ? ''
            : 'передать дополнительно';
    } else if (service === 'zones') {
        description = 'передать дополнительно';
    }
    if (service === 'domains') {
        description = 'передать дополнительно';
    }
    if (service === 'certificates') {
        description = 'передать дополнительно';
    }

    useEffect(() => {
        dispatch(flushErrors());
        if (service === 'vms') {
            dispatch(vmFinanceInfo(instance.id));
            dispatch(fetchVmResources(instance.id));
        } else if (service === 'containers') {
            dispatch(fetchContainerCalculation(instance.id));
            dispatch(fetchContainerResources(instance.id));
        } else if (service === 'mail_domains') {
            dispatch(fetchMailDomainResources(instance.id));
        }
    }, []);

    return (
        <React.Fragment>
            {instance && (
                <React.Fragment>
                    <ResourceCard
                        service={service}
                        name={instance.name}
                        uidText={uidText}
                        price={price}
                        description={resourceDescription(
                            instance, service, vmCalculation, containerCalculation
                        )}
                    />
                    {(service === 'vms'
                        || service === 'containers'
                        || service === 'mail_domains') && (
                        <ResourcesTables
                            service={service}
                            instance={instance}
                            resources={relatedResources}
                            description={description}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

TransferPageContainer.propTypes = {
    service: PropTypes.string,
    instance: PropTypes.shape({
        id: PropTypes.number,
        uid: PropTypes.string,
        name: PropTypes.string,
        login: PropTypes.string,
        domain: PropTypes.string,
    }),
    resources: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TransferPageContainer;
