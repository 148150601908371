export const stateIconColor = {
    active: {
        backgroundColor: '#29ac7a'
    },
    error: {
        backgroundColor: '#c30202'
    },
    stopped: {
        backgroundColor: 'rgba(69, 85, 98, 0.7)'
    },
    stoppedbyservice: {
        backgroundColor: 'rgba(69, 85, 98, 0.7)'
    },
    maintenancebyadmin: {
        backgroundColor: 'rgba(69, 85, 98, 0.7)'
    },
    stoppedbyadmin: {
        backgroundColor: '#c30202'
    },
    restoring: {
        backgroundColor: '#36a3d8'
    },
    restarting: {
        backgroundColor: '#36a3d8'
    },
    tariffupdate: {
        backgroundColor: '#36a3d8'
    },
    starting: {
        backgroundColor: '#36a3d8'
    },
    diskupdating: {
        backgroundColor: '#36a3d8'
    },
    building: {
        backgroundColor: '#36a3d8'
    },
    transfer: {
        backgroundColor: '#e28a58'
    },
    offlinemigrating: {
        backgroundColor: '#e28a58'
    },
    livemigrating: {
        backgroundColor: '#e28a58'
    },
    stopping: {
        backgroundColor: '#e28a58'
    },
    archiving: {
        backgroundColor: '#e28a58'
    },
    migrating: {
        backgroundColor: '#34aef0'
    },
    backuping: {
        backgroundColor: '#34aef0'
    },
    rescuemode: {
        backgroundColor: '#392b2f'
    }
};

export const stateTextColor = {
    active: {
        color: '#29ac7a'
    },
    error: {
        color: '#c30202'
    },
    stopped: {
        color: 'rgba(69, 85, 98, 0.7)'
    },
    stoppedbyservice: {
        color: 'rgba(69, 85, 98, 0.7)'
    },
    maintenancebyadmin: {
        color: 'rgba(69, 85, 98, 0.7)'
    },
    stoppedbyadmin: {
        color: '#c30202'
    },
    restoring: {
        color: '#36a3d8'
    },
    restarting: {
        color: '#36a3d8'
    },
    tariffupdate: {
        color: '#36a3d8'
    },
    starting: {
        color: '#36a3d8'
    },
    diskupdating: {
        color: '#36a3d8'
    },
    building: {
        color: '#36a3d8'
    },
    transfer: {
        color: '#e28a58'
    },
    offlinemigrating: {
        color: '#e28a58'
    },
    livemigrating: {
        color: '#e28a58'
    },
    stopping: {
        color: '#e28a58'
    },
    archiving: {
        color: '#e28a58'
    },
    migrating: {
        color: '#34aef0'
    },
    backuping: {
        color: '#34aef0'
    },
    rescuemode: {
        color: '#392b2f'
    }
}
