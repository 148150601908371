import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text, Image, TouchableOpacity, ScrollView } from 'react-native';

import { formatMemory, canNotStartSearch } from '../../utils/general';
import Modal from '../General/Modal/Modal';
import { rightArrowLightIcon, nothingFoundIcon } from '../../icons';
import {
    fetchMoreDomains,
    searchDomains,
    initSearchDomains,
    changeDomainsSearchQuery,
} from '../../actions/mail/actions';
import { humanStateMap, ENABLED } from '../../constants/general';
import CreateDomainModal from './Modals/CreateDomainModal';
import MailResourceLoader from './MailResourceLoader';
import Circle from '../General/Circle/Circle';
import SearchInput from '../General/Form/SearchInput';
import MailWebsocket from './MailWebsocket';

function DomainResource({ domain, navigation, paidQuotaEnabled }) {
    const {
        id,
        used,
        name,
        quota,
        state,
        validated,
        max_quota,
        mailboxes_count,
    } = domain;
    const isDisabled = state !== ENABLED;
    const domainLimit = quota === 0 ? max_quota : quota;
    const quotaInfo = paidQuotaEnabled ? `/ ${formatMemory(domainLimit, true)}` : '';
    return <TouchableOpacity style={styles.wrapper}
        onPress={() => {
            navigation.navigate('MailDomain', {
                domainId: id,
                domainName: name
            });
        }}>
        <View style={styles.domain}>
            <View style={styles.header}>
                <Text style={styles.headerTitle}>{name}</Text>
                <Circle count={mailboxes_count} />
            </View>
            <View style={styles.info}>
                <Text style={styles.domainQuotaText}>
                    {formatMemory(used, true)} {quotaInfo}
                </Text>
            </View>
        </View>
        {isDisabled && <Text style={styles.status}>{humanStateMap[state]}</Text>}
        {!isDisabled && !validated && (
            <Text style={styles.status}>Не подтвержден</Text>
        )}
        <Image source={rightArrowLightIcon} style={styles.icon} />
    </TouchableOpacity>;
}

DomainResource.propTypes = {
    domain: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        dkim: PropTypes.string,
        validated: PropTypes.bool,
        used: PropTypes.number,
        is_backup_enabled: PropTypes.bool,
        quota: PropTypes.number,
        mailboxes_count: PropTypes.number,
        state: PropTypes.string,
        max_quota: PropTypes.number,
        in_transfer: PropTypes.bool,
    }),
    paidQuotaEnabled: PropTypes.bool,
};

function isCloseToBottom({ layoutMeasurement, contentOffset, contentSize }) {
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - 136;
}

function DomainsTab() {
    const limit = 10;
    const formId = 'FetchDomains';
    const dispatch = useDispatch();
    const {
        domains,
        userInfo,
        searchDomainsInProgress,
        moreDomainsInProgress,
        searchDomainsQuery,
        searchDomainsResult
    } = useSelector((state) => state.mail);
    const navigation = useNavigation();

    const [isCreateModalShowed, setCreateModalShowed] = React.useState(false);
    const [isStartingPagination, setIsStartingPagination] = React.useState(false);

    let currentDomains = domains;
    if (searchDomainsResult && !canNotStartSearch(searchDomainsQuery)) {
        currentDomains = searchDomainsResult;
    }

    return <ScrollView
        style={{ flex: 1 }}
        scrollEventThrottle={0}
        onScroll={(e) => {
            if (
                isCloseToBottom(e.nativeEvent)
                && isStartingPagination
                && !searchDomainsResult
                && !moreDomainsInProgress
                && domains.count !== domains.entities.length
            ) {
                dispatch(
                    fetchMoreDomains(formId, limit, domains.entities.length)
                );
            }
        }}>
        <View style={styles.searchInputBlock}>
            <SearchInput
                onSearch={(request) => dispatch(searchDomains({ filter: `name % "${request}"` }))}
                searchRequest={searchDomainsQuery}
                changeSearchRequest={(query, limit) => dispatch(
                    changeDomainsSearchQuery(query, limit)
                )}
            />
        </View>
        <View style={styles.headerBlock}>
            <Text style={styles.headerText}>
                почтовые домены
            </Text>
            <Circle count={currentDomains.count} inProgress={searchDomainsInProgress}/>
            <View style={styles.spring} />
            <TouchableOpacity style={styles.button} onPress={() => setCreateModalShowed(true)}>
                <Text style={styles.blueText}>Добавить</Text>
            </TouchableOpacity>
        </View>
        {!canNotStartSearch(searchDomainsQuery)
            && searchDomainsResult && searchDomainsResult.count === 0
            && <View>
                <Image source={nothingFoundIcon} style={styles.nothingFound} />
                <Text style={styles.nothingFoundText}>
                    Ничего не найдено
                </Text>
            </View>
        }
        {!searchDomainsInProgress
            && currentDomains && currentDomains.entities.map((domain, i) => <DomainResource
            key={`mail-domain-${i}`}
            domain={domain}
            navigation={navigation}
            paidQuotaEnabled={userInfo && userInfo.paid_quota_enabled}
        />)}
        {(moreDomainsInProgress || searchDomainsInProgress) && <MailResourceLoader />}
        {!isStartingPagination
            && !searchDomainsInProgress
            && !searchDomainsResult
            && domains && domains.count !== domains.entities.length
            && <View style={styles.whiteButtonBlock}>
                <TouchableOpacity style={styles.whiteButton}
                    onPress={() => {
                        setIsStartingPagination(true);
                        dispatch(
                            fetchMoreDomains(formId, limit, domains.entities.length)
                        );
                    }}
                >
                    <Text style={styles.whiteButtonText}>Показать еще {
                        domains.count - domains.entities.length > 10
                            ? 10
                            : domains.count - domains.entities.length
                    }</Text>
                </TouchableOpacity>
            </View>
        }
        <Modal isModalShowed={isCreateModalShowed}
            component={<CreateDomainModal
                onClose={() => {
                    setCreateModalShowed(false);
                    dispatch(initSearchDomains());
                }}/>}
            onClose={() => setCreateModalShowed(false)} />
        <MailWebsocket />
    </ScrollView>;
}

const styles = StyleSheet.create({
    wrapper: {
        margin: 0,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        width: '100%',
        color: '#455562',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        backgroundColor: '#FFFFFF',
        borderBottomColor: '#F6F6F6',
        borderBottomWidth: 2,
    },
    headerBlock: {
        paddingBottom: 16,
        paddingTop: 14,
        paddingLeft: 20,
        paddingRight: 21,
        flex: 1,
        maxHeight: 43,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginRight: 8,
        color: '#455562',
    },
    blueText: {
        fontSize: 12,
        fontWeight: '500',
        color: '#0f79d5',
    },
    domain: {
        flex: 1
    },
    status: {
        color: '#e17334',
        fontSize: 10,
        fontWeight: 400,
        marginRight: 20,
        marginLeft: 20,
    },
    icon: {
        width: 7,
        height: 13,
    },
    nothingFound: {
        width: 40,
        height: 40,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 25,
    },
    nothingFoundText: {
        marginTop: 18,
        textAlign: 'center',
        color: '#455562',
        opacity: 0.5,
        fontSize: 13,
        lineHeight: 15,
    },
    header: {
        flex: 1,
        color: '#455562',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        marginBottom: 3,
    },
    headerTitle: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
        marginRight: 8,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    progressBar: {
        width: 94,
        height: 2,
    },
    domainQuotaText: {
        opacity: 0.8,
        color: '#455562',
        fontSize: 10,
        letterSpacing: 0,
        lineHeight: 12,
    },
    info: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'start',
    },
    whiteButtonBlock: {
        paddingTop: 15,
        paddingRight: 20,
        paddingLeft: 20,
        marginBottom: 20,
    },
    whiteButton: {
        borderRadius: 4,
        borderWidth: 1,
        borderColor: 'rgba(69, 85, 98, 0.50)',
        backgroundColor: '#ffffff',
        borderStyle: 'solid',
        paddingTop: 12,
        paddingBottom: 13,
        width: '100%',
    },
    whiteButtonText: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '400',
        textAlign: 'center',
    },
    spring: {
        flex: 1,
    },
    searchInputBlock: {
        marginTop: 10,
        marginLeft: 20,
        marginRight: 20,
    }
});

export default DomainsTab;
