import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity
} from 'react-native';

import AccountEmail from './AccountEmail';
import Modal from '../../General/Modal/Modal';
import AccountAddEmail from '../AccountModal/AccountAddEmail';
import { ModalContext } from '../../../contexts/ModalContext';
import { mainWrapperStyles } from '../../../utils/styles';

const tabs = [{
    title: 'Административные',
    type: 'adm',
}, {
    title: 'Технические',
    type: 'tech',
}];

function isCurrentTab(tabType, currentTab) {
    return currentTab === tabType;
}

function getTabsBlock(currentTab, onChangeTab) {
    return tabs.map((tab, t) => {
        const styleModifierView = isCurrentTab(tab.type, currentTab)
            ? styles.tabCurrentItem : styles.tabItem;
        const styleModifierText = isCurrentTab(tab.type, currentTab)
            ? styles.tabCurrentItemText : styles.tabItemText;
        return <View key={`tab-${t}`} style={styleModifierView}>
            <TouchableOpacity onPress={() => onChangeTab(tab.type)}>
                <Text style={styleModifierText}>{tab.title}</Text>
            </TouchableOpacity>
        </View>;
    });
}

function getTabBody(currentTab, tabDict) {
    return <View>
        <Text style={styles.tabDescription}>{tabDict[currentTab].description}</Text>
        <Text>{tabDict[currentTab].body}</Text>
        {tabDict[currentTab].unverifiedEmails.length !== 0
            && <View style={styles.hint}>
                <View style={styles.hintIndicate}></View>
                <Text style={styles.hintText}>Отправлено письмо для подтверждения ящика</Text>
            </View>}
        <TouchableOpacity style={styles.button} onPress={tabDict[currentTab].addEmail}>
            <Text style={styles.buttonText}>{tabDict[currentTab].buttonTitle}</Text>
        </TouchableOpacity>
    </View>;
}

let addEmailHelper = {
    formId: 'EmailsAdmForm',
    emailType: 'adm',
    title: 'Добавить административный Email',
};

function AccountEmails() {
    const { openModal, closeModal, checkModal } = useContext(ModalContext);
    const { account } = useSelector((state) => state.account);
    const emailsAdm = account.emails_adm;
    const unverifiedEmailsAdm = account.unverified_emails_adm;
    const emailsTech = account.emails_tech;
    const unverifiedEmailsTech = account.unverified_emails_tech;

    const tabDict = {
        adm: {
            description: 'Все технические уведомления, баланс, платежи и документы',
            buttonTitle: 'Добавить административный Email',
            body: <AccountEmail emails={emailsAdm}
                unverifiedEmails={unverifiedEmailsAdm}
                formId='EmailsAdmForm' emailsType='adm'/>,
            unverifiedEmails: unverifiedEmailsAdm,
            addEmail: () => {
                openModal('account-add-email-modal');
                addEmailHelper = {
                    formId: 'EmailsAdmForm',
                    emailType: 'adm',
                    title: 'Добавить административный Email',
                };
            }
        },
        tech: {
            description: 'Реквизиты FTP/SSH, уведомления о превышении квоты',
            buttonTitle: 'Добавить технический Email',
            body: <AccountEmail emails={emailsTech}
                unverifiedEmails={unverifiedEmailsTech}
                formId='EmailsTechForm' emailsType='tech'/>,
            unverifiedEmails: unverifiedEmailsTech,
            addEmail: () => {
                openModal('account-add-email-modal');
                addEmailHelper = {
                    formId: 'EmailsTechForm',
                    emailType: 'tech',
                    title: 'Добавить технический Email',
                };
            }
        }
    };
    const [currentTab, setModalCurrentTab] = React.useState(account.is_master ? 'adm' : 'tech');

    return <View style={styles.wrapper}>
        <Text style={styles.title}>Адреса электронной почты</Text>
        <View>
            <View style={styles.tabs}>
                {getTabsBlock(currentTab, setModalCurrentTab)}
            </View>
            {getTabBody(currentTab, tabDict)}
        </View>
        <Modal isModalShowed={checkModal('account-add-email-modal')}
            component={<AccountAddEmail {...addEmailHelper}
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        marginBottom: 12,
        ...mainWrapperStyles,
        paddingBottom: 19,
        paddingTop: 18,
    },
    title: {
        color: '#455562',
        marginBottom: 16,
        marginLeft: 12,
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 14,
    },
    tabs: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 10,
    },
    tabItem: {
        opacity: 0.7,
        marginRight: 12,
        marginLeft: 12,
        paddingBottom: 10,
        color: '#455562',
    },
    tabCurrentItem: {
        opacity: 1,
        marginLeft: 12,
        marginRight: 12,
        paddingBottom: 8,
        borderBottomWidth: 2,
        borderBottomColor: '#0f79d5',
    },
    tabItemText: {
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 14,
        color: '#455562',
    },
    tabCurrentItemText: {
        color: '#0f79d5',
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 14,
    },
    tabDescription: {
        fontSize: 10,
        lineHeight: 14,
        paddingLeft: 12,
        paddingRight: 28,
        paddingBottom: 10,
        opacity: 0.8,
        maxWidth: 230,
        color: '#455562',
    },
    button: {
        paddingTop: 16,
        paddingLeft: 12,
    },
    buttonText: {
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 14,
        color: '#0f79d5',
    },
    hint: {
        flexDirection: 'row',
        alignItems: 'center',
        opacity: 0.8,
        paddingTop: 15,
        paddingLeft: 12,
    },
    hintText: {
        color: '#455562',
        fontSize: 10,
        lineHeight: 14,
    },
    hintIndicate: {
        height: 8,
        width: 8,
        borderRadius: 5,
        marginRight: 5,
        backgroundColor: '#ef874d',
    }
});

export default AccountEmails;
