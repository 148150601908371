import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Image, Text, StyleSheet, TouchableOpacity } from 'react-native';

import { isActiveVM } from '../utils';
import { trashIcon, certificateIcon } from '../../../icons';
import Modal from '../../General/Modal/Modal';
import { blockedButtonTooltips } from '../../../constants/general';
import { ModalContext } from '../../../contexts/ModalContext';
import { addCustomNotification } from '../../../actions/notifications/actions';
import DeleteProtectedWebsiteModal from '../Modals/DeleteProtectedWebsiteModal';
import ChangeProtectedWebsiteModal from '../Modals/ChangeProtectedWebsiteModal';
import AddProtectedWebsiteModal from '../Modals/AddProtectedWebsiteModal';
import Actions from '../../General/Actions/Actions';
import Action from '../../General/Actions/Action';

const addWebsiteModalId = 'add-protected-ip-modal';
const changeWebsiteModalId = 'change-protected-ip-modal';
const deleteWebsiteModalId = 'delete-protected-ip-modal';

function ProtectedWebsitesListScreen() {
    const { currentVM, protectedWebsites } = useSelector((state) => state.cvds);
    const [deletedWebsite, setDeletedWebsite] = React.useState(null);
    const [changedWebsite, setChangedWebsite] = React.useState(null);
    const isEnabledActions = isActiveVM(currentVM.state) && currentVM.is_managed;
    const disabledText = (isActiveVM(currentVM.state) && !currentVM.is_managed)
        ? blockedButtonTooltips.cvds.DisableGuestAgent
        : blockedButtonTooltips.cvds[currentVM.state];

    const { openModal, closeModal, checkModal } = useContext(ModalContext);
    return <View style={styles.wrapper}>
        <View style={styles.headerBlock}>
            <Text style={styles.headerText}>
                Домены
            </Text>
            <TouchableOpacity
                onPress={() => {
                    if (isEnabledActions) {
                        openModal(addWebsiteModalId);
                    } else {
                        addCustomNotification(disabledText);
                    }
                }}
            >
                <Text style={[styles.blueText,
                    { color: isEnabledActions ? '#0F79D5' : '#888888' }
                ]}>Добавить</Text>
            </TouchableOpacity>
        </View>
        <View>
            {protectedWebsites.entities.map((item) => <View key={`ddos-website-${item.id}`}
                style={styles.websiteItem}>
                <View style={styles.websiteItemTextBlock}>
                    <Text style={styles.websiteItemDomain}>{item.domain}</Text>
                    <Text style={[styles.websiteItemCertificate,
                        { color: item.certificate_id !== 0 ? '#455562' : '#E17334' }
                    ]}>{item.certificate_id !== 0
                            ? 'SSL-cертификат подключен'
                            : 'SSL-cертификат не подключен'}</Text>
                </View>
                <Actions modalId='protected-websites-actions'
                    additionalButtonStyles={[styles.actions]}>
                    <Action title='Подключить сертификат'
                        enabled={isEnabledActions}
                        icon={certificateIcon}
                        textStyles={[{ color: '#455562' }]}
                        disabledText={disabledText}
                        onPress={() => {
                            closeModal();
                            setChangedWebsite(item);
                            openModal(changeWebsiteModalId);
                        }}/>
                    <Action icon={trashIcon}
                        enabled={isEnabledActions}
                        disabledText={disabledText}
                        textStyles={[{ color: '#D51010' }]} title='Удалить домен'
                        onPress={() => {
                            closeModal();
                            setDeletedWebsite(item);
                            openModal(deleteWebsiteModalId);
                        }}/>
                </Actions>
            </View>)}
        </View>
        <Modal isModalShowed={checkModal(deleteWebsiteModalId)}
            component={<DeleteProtectedWebsiteModal website={deletedWebsite}
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
        <Modal isModalShowed={checkModal(addWebsiteModalId)}
            component={<AddProtectedWebsiteModal
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
        <Modal isModalShowed={checkModal(changeWebsiteModalId)}
            component={<ChangeProtectedWebsiteModal website={changedWebsite}
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
    },
    websiteItem: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18,
        marginBottom: 1,
        backgroundColor: '#ffffff'
    },
    websiteItemDomain: {
        marginBottom: 4,
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 13,
    },
    websiteItemCertificate: {
        fontSize: 11,
        lineHeight: 11,
    },
    headerBlock: {
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginRight: 8,
        color: '#455562',
    },
    blueText: {
        fontSize: 12,
        fontWeight: '500',
        color: '#0f79d5',
    },
    actions: {
        borderWidth: 0,
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
        paddingBottom: 0,
    }
});

export default ProtectedWebsitesListScreen;
