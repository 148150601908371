import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import Input from '../../General/Form/Input';
import { addEmail } from '../../../actions/account/actions';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'ChangeAccountPasswordConfirmForm';

function AccountAddEmail(props) {
    const {
        title,
        errors,
        onClose,
        addEmail,
        emailType,
        inProgress,
        flushErrors,
    } = props;

    React.useEffect(() => {
        flushErrors()
    }, []);
    const formErrors = errors[formId] || {};
    const [email, setModalChangeEmail] = React.useState('');
    return <View style={styles.wrapper}>
        <Text style={styles.title}>{title}</Text>
        <View>
            <Input
                errors={formErrors.email}
                textInputAttrs={{
                    autoFocus: true,
                    numberOfLines: 1,
                    placeholder: 'admin@mysite.com',
                    onChange: (e) => {
                        flushErrors();
                        setModalChangeEmail(e.target.value);
                    }
                }}
            />
        </View>
        {formErrors.__all__ && <Text style={styles.error}>{formErrors.__all__}</Text>}
        <View style={styles.buttons}>
            <AcceptButton
                onSubmit={() => addEmail(formId, { emailType, email },
                    () => onClose())}
                title='Добавить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

AccountAddEmail.propTypes = {
    title: PropTypes.string,
    emailType: PropTypes.string,
    formId: PropTypes.string,
    errors: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    inProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    inProgress: state.account.addEmailInProgress,
    errors: state.forms.errors || {}
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addEmail,
    flushErrors,
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 44,
        paddingBottom: 42,
        paddingLeft: 40,
        paddingRight: 40,
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 20,
        color: '#455562',
    },
    buttons: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
    },
    error: {
        color: '#d06565',
        marginTop: 10,
    },
    blueText: {
        color: '#0f79d5',
        fontWeight: '500',
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountAddEmail);