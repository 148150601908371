import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Platform,
    StyleSheet,
    View,
    Text
} from 'react-native';
import { isSafari, isMobileSafari } from 'react-device-detect';

import {
    createInvoice,
    initPayment
} from '../../../actions/finances/actions';
import Modal from '../../General/Modal/Modal';
import Button from '../../General/Button/Button';
import PaymentSystemButton from './PaymentSystemButton';
import Separator from '../../General/Separator/Separator';
import { ModalContext } from '../../../contexts/ModalContext';
import InvoiceActionsModal from '../FinanceDocumentsTab/InvoiceActionsModal';

const invoiceActionsModalPaymentId = 'invoice-action-modal-payment';

const checkIsApplePayAllow = () => {
    if (Platform.OS === 'ios') {
        return true;
    }
    if (Platform.OS === 'web' && (isSafari || isMobileSafari)) {
        return true;
    }
    return false;
};

const styles = StyleSheet.create({
    additionalButtonStyles: {
        borderRadius: 0,
        backgroundColor: '#ffffff',
        borderWidth: 0,
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 19,
        paddingRight: 20
    },
    button: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    yandexIconStyles: {
        width: 16,
        height: 11,
    },
    titleStyles: {
        fontSize: 10,
        letterSpacing: 1.36,
        lineHeight: 12,
        fontWeight: '500',
        textTransform: 'uppercase',
        marginTop: 34.5,
        marginLeft: 19,
        marginBottom: 8,
        color: '#455562',
        opacity: 0.75
    }
});

function initPaymentSystem(paymentSystemType, amount, initPayment, setPaymentForm) {
    initPayment(paymentSystemType, amount, (data) => {
        setPaymentForm(data);
    });
}

function PaymentSystems(props) {
    const {
        amount,
        initPayment,
        createInvoice,
        setPaymentForm,
        allowElectronicPayment
    } = props;
    const { openModal, closeModal, checkModal } = useContext(ModalContext);
    const [currentInvoice, setCurrentInvoice] = React.useState(null);

    function makeInvoice() {
        const normalizedAmount = Number.parseInt(amount, 10);
        createInvoice('confirmPaymentFormId', normalizedAmount, (invoice, url) => {
            setCurrentInvoice(invoice);
            openModal(invoiceActionsModalPaymentId);
        });
    }
    return <View>
        <Button additionalButtonStyles={[styles.additionalButtonStyles]}
            onPress={() => initPaymentSystem('SBP', amount, initPayment, setPaymentForm)}
            component={<PaymentSystemButton type='SBP'/>}/>
        <Separator/>
        <Button additionalButtonStyles={[styles.additionalButtonStyles]}
            onPress={() => initPaymentSystem('Card', amount, initPayment, setPaymentForm)}
            component={<PaymentSystemButton type='BankCard'/>}/>
        <Separator/>
        <Button additionalButtonStyles={[styles.additionalButtonStyles]}
            onPress={() => makeInvoice()}
            component={<PaymentSystemButton type='Bank'/>}/>
        <Separator/>
        {checkIsApplePayAllow() && <React.Fragment>
            <Button
                additionalButtonStyles={[styles.additionalButtonStyles]}
                onPress={() => initPaymentSystem('ApplePay', amount, initPayment, setPaymentForm)}
                component={<PaymentSystemButton type='ApplePay'/>}
            />
            <Separator/>
        </React.Fragment>}
        {allowElectronicPayment && <React.Fragment>
            <Button
                additionalButtonStyles={[styles.additionalButtonStyles]}
                onPress={() => initPaymentSystem('YandexMoney', amount, initPayment, setPaymentForm)}
                component={<PaymentSystemButton type='YandexMoney' customIconStyle={styles.yandexIconStyles}/>}
            />
            <Separator/>
        </React.Fragment>}
        <Text style={styles.titleStyles}>картой для нерезидентов РФ</Text>
        <Separator/>
        <Button additionalButtonStyles={[styles.additionalButtonStyles]}
            onPress={() => initPaymentSystem('IntellectMoney', amount, initPayment, setPaymentForm)}
            component={<PaymentSystemButton type='IntellectMoney'/>}/>
        <Separator/>
        <Button additionalButtonStyles={[styles.additionalButtonStyles]}
            onPress={() => initPaymentSystem('YooKassaCard', amount, initPayment, setPaymentForm)}
            component={<PaymentSystemButton type='YooKassaCard' customIconStyle={styles.yandexIconStyles}/>}/>
        <Separator/>
        <Modal isModalShowed={checkModal(invoiceActionsModalPaymentId)}
            component={<InvoiceActionsModal invoice={currentInvoice}
                onClose={() => {
                    closeModal();
                }}/>}
            onClose={() => closeModal()} />
    </View>;
}

PaymentSystems.propTypes = {
    amount: PropTypes.string,
    setPaymentForm: PropTypes.func,
    initPayment: PropTypes.func,
    createInvoice: PropTypes.func,
    allowElectronicPayment: PropTypes.bool
};

const mapStateToProps = (state) => ({
    allowElectronicPayment: state.user.financeInfo
        && state.user.financeInfo.allow_electronic_payment
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    initPayment, createInvoice
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSystems);
