import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import Input from '../../General/Form/Input';
import { flushErrors } from '../../../actions/forms/actions';
import { couponActivate } from '../../../actions/finances/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        marginTop: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

const formId = 'ActivateConfirmForm';

function ActivateModal(props) {
    const {
        onClose,
        inProgress,
        flushErrors,
        couponActivate,
        errors
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);
    const [token, setToken] = React.useState('');

    return <View style={[styles.wrapper]}>
        <Text style={[styles.title]}>Активировать промокод</Text>
        <Input
            label='Код'
            errors={errors.token}
            textInputAttrs={{
                onChangeText: (text) => {
                    setToken(text);
                },
                value: token
            }}
        />
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => couponActivate(formId, { token }, onClose)}
                title='Активировать'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

ActivateModal.propTypes = {
    flushErrors: PropTypes.func,
    couponActivate: PropTypes.func,
    errors: PropTypes.shape({}),
    inProgress: PropTypes.bool,
    onClose: PropTypes.func
};

const mapStateToProps = (state) => ({
    inProgress: state.finance.couponActivateInProgress,
    errors: state.forms.errors[formId] || {}
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    couponActivate, flushErrors
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActivateModal);