import React from 'react';
import { View, StyleSheet } from 'react-native';

import ResourceLoader from '../ResourceLoader/ResourceLoader';

function RestoreBackupWithDatesLoader() {
    return <View style={styles.wrapper}>
        <View style={styles.title}>
            <ResourceLoader height={13} count={1}/>
        </View>
        <View>
            <View style={styles.dates}>
                <View style={styles.date}>
                    <ResourceLoader height={36} count={1}/>
                </View>
                <View style={styles.date}>
                    <ResourceLoader height={36} count={1}/>
                </View>
                <View style={styles.date}>
                    <ResourceLoader height={36} count={1}/>
                </View>
            </View>
        </View>
        <View style={styles.title}>
            <ResourceLoader height={13} count={1}/>
        </View>
        <View style={styles.mainBlock}>
            <ResourceLoader height={54} count={1}/>
        </View>
        <ResourceLoader height={54} count={4}/>
    </View>;
}

const styles = StyleSheet.create({
    title: {
        marginLeft: 20,
        marginBottom: 10,
        maxWidth: 225,
    },
    dates: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 30,
        marginLeft: 20,
    },
    date: {
        width: 95,
        marginRight: 10,
    },
    mainBlock: {
        marginBottom: 10,
    }
});

export default RestoreBackupWithDatesLoader;
