import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, StyleSheet } from 'react-native';

import Input from '../../General/Form/Input';
import { addAliasMailbox } from '../../../actions/mail/actions';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'AddAliasModalForm';

function AddAliasModal(props) {
    const {
        mailboxId,
        onClose,
    } = props;
    const dispatch = useDispatch();
    const { errors } = useSelector((state) => state.forms);
    const { changeMailboxInProgress } = useSelector((state) => state.mail);
    const currentErrors = errors[formId] || {};
    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Добавить адрес для пересылки</Text>
        <Formik
            initialValues={{
                alias: '',
            }}
            onSubmit={(values) => dispatch(
                addAliasMailbox(formId, mailboxId, values.alias, () => onClose())
            )}>
            {({ handleChange, handleBlur, handleSubmit, values }) => (
                <View>
                    <Input
                        errors={currentErrors.alias}
                        label='Адрес для пересылки'
                        textInputAttrs={{
                            onChangeText: handleChange('alias'),
                            onBlur: handleBlur('alias'),
                            value: values.alias
                        }}
                    />
                    <View style={styles.buttonBlock}>
                        <AcceptButton
                            onSubmit={() => handleSubmit()}
                            title='Добавить'
                            inProgress={changeMailboxInProgress}/>
                        <CancelButton onClose={onClose} disabled={changeMailboxInProgress} />
                    </View>
                </View>
            )}
        </Formik>
    </View>;
}

AddAliasModal.propTypes = {
    onClose: PropTypes.func,
    mailboxId: PropTypes.number,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
    },
});

export default AddAliasModal;
