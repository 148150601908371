import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { NavigationProps } from '../types';
import { screenHeaderStyles } from '../utils/styles';
import { partnershipData } from '../actions/partnership/actions';
import ChangeRequisites from '../components/Partnership/PartnershipEditRequisites';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const ChangeRequisitesStack = createStackNavigator();

function ChangeRequisitesStackScreen(props) {
    const {
        navigation,
        partnership,
        partnershipData
    } = props;
    const insets = useSafeAreaInsets();

    React.useEffect(() => {
        if (partnership == null) {
            partnershipData();
        }
    }, []);

    return <ChangeRequisitesStack.Navigator>
        <ChangeRequisitesStack.Screen name="ChangeRequisitesStack" component={ChangeRequisites} options={{
            headerTitle: () => <ScreenHeaderTitle title='Реквизиты для вывода средств' />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => {
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                    } else {
                        navigation.navigate('PartnershipWithdrawal');
                    }
                },
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </ChangeRequisitesStack.Navigator>;
}

ChangeRequisitesStackScreen.propTypes = {
    navigation: NavigationProps,
    partnershipData: PropTypes.func,
    partnership: PropTypes.shape({})
};

const mapStateToProps = (state) => ({
    partnership: state.partnership.partnership
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ partnershipData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRequisitesStackScreen);
