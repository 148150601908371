import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import { flushErrors } from '../../../actions/forms/actions';
import InputPassword from '../../General/Form/InputPassword';
import { ftpChangePassword } from '../../../actions/hosting/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'ChangeFTPPassword';

function VirtualhostFTPChangePassword(props) {
    const {
        ftp,
        errors,
        onClose,
        inProgress,
        flushErrors,
        ftpChangePassword,
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);

    const [password, setModalChangedNewPass] = React.useState('');
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Смена пароля FTP для аккаунта &laquo;{ftp.login}&raquo;</Text>
        <InputPassword
            errors={errors.password}
            value={password}
            autoFocus={true}
            onChange={(value) => setModalChangedNewPass(value)}
        />
        {errors.__all__ && <Text style={styles.error}>{errors.__all__}</Text>}
        <View style={styles.buttons}>
            <AcceptButton
                onSubmit={() => ftpChangePassword(formId, ftp.id, password || '',
                    () => onClose())}
                title='Сохранить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

VirtualhostFTPChangePassword.propTypes = {
    onClose: PropTypes.func,
    inProgress: PropTypes.bool,
    errors: PropTypes.shape({}),
    ftp: PropTypes.shape({
        id: PropTypes.number,
        login: PropTypes.string,
    }),
    ftpChangePassword: PropTypes.func,
    flushErrors: PropTypes.func,
};

const mapStateToProps = (state) => ({
    inProgress: state.hosting.ftpChangePasswordInProgress,
    errors: state.forms.errors[formId] || {},
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    ftpChangePassword
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 44,
        paddingBottom: 42,
        paddingLeft: 40,
        paddingRight: 40,
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 20,
        color: '#455562',
    },
    buttons: {
        flex: 1,
        marginTop: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
    },
    input: {
        borderColor: '#dce2e9',
        borderWidth: 1,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
        marginBottom: 10,
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(VirtualhostFTPChangePassword);