import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

function TimelineDay(props) {
    const {
        day,
        dayColorModifier,
        current,
        onChange
    } = props;

    const isCurrent = day.format('YYYY-MM-DD') === current.format('YYYY-MM-DD');
    return <TouchableOpacity onPress={() => {
            if (!isCurrent) {
                onChange(day);
            }
        }} style={[
            isCurrent ? styles.currentDayBlock : styles.dayBlock,
            { borderBottomColor: dayColorModifier }
        ]}>
        <Text style={isCurrent ? styles.currentDayBlockText : styles.dayBlockText}>
            {day.format('DD')}
        </Text>
        {isCurrent && <Text style={styles.currentDayBlockMonth}>
            {day.format('MMM').replace('.', '')}
        </Text>}
    </TouchableOpacity>;
}

TimelineDay.propTypes = {
    day: PropTypes.object,
    dayColorModifier: PropTypes.arrayOf(PropTypes.string),
    current: PropTypes.object,
    onChange: PropTypes.func
};

const styles = StyleSheet.create({
    dayBlock: {
        paddingLeft: 4,
        paddingRight: 4,
        paddingBottom: 6,
        borderBottomWidth: 2,
        zIndex: 2
    },
    currentDayBlock: {
        position: 'relative',
        paddingTop: 9,
        paddingLeft: 2,
        paddingRight: 2,
        marginLeft: 2,
        marginRight: 2,
        borderBottomWidth: 4,
        borderRadius: 2,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopColor: 'rgba(69, 85, 98, 0.09)',
        borderLeftColor: 'rgba(69, 85, 98, 0.09)',
        borderRightColor: 'rgba(69, 85, 98, 0.09)'
    },
    currentDayBlockText: {
        color: '#455562',
        fontSize: 16,
        lineHeight: 14,
        textAlign: 'center'
    },
    currentDayBlockMonth: {
        color: '#455562',
        fontSize: 9,
        lineHeight: 14,
        textAlign: 'center'
    },
    dayBlockText: {
        opacity: 0.7,
        color: '#455562',
        fontSize: 9,
        lineHeight: 10,
    }
});

export default TimelineDay;
