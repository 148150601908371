import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    View, Text, Image, StyleSheet
} from 'react-native';

import {
    fetchBarChartCPU,
} from '../../../actions/cvds/actions';
import { cpuIcon } from '../../../icons';
import { pluralize } from '../../../utils/general';
import cvdsTariffData from '../../../../data/cvds/tariffs.json';
import StatisticsTenDays from '../../General/Statistics/StatisticsTenDays';

function getCpuLimit(tariffId) {
    const tariffData = cvdsTariffData.find(tariff => tariff.id === tariffId);
    return tariffData.features.cpu;
}

function normalizeDatapointsRegardingTariff(datapoints) {
    if (datapoints) {
        return datapoints.map(datapoint => ({
            value: datapoint.value,
            date: datapoint.date
        }));
    }
    return [];
}

function StatisticsTenDaysCPU(props) {
    const {
        vm,
        isError,
        isLoading,
        fetchBarChartCPU,
        cumulative,
        datapoints,
    } = props;

    React.useEffect(() => {
        if (datapoints == null) {
            fetchBarChartCPU(vm.id);
        }
    }, []);
    const cpuLimit = getCpuLimit(vm.tariff);
    return <View style={styles.wrapper}>
        <Image source={cpuIcon} style={styles.icon}/>
        <View style={styles.textBlock}>
            <Text style={styles.title}>Процессор</Text>
            <Text style={styles.subTitle}>
                {cpuLimit} {pluralize(cpuLimit, ['Ядро', 'Ядра', 'Ядер'])}
            </Text>
        </View>
        <StatisticsTenDays
            isLoading={datapoints == null && isLoading}
            isError={isError}
            generalValue={cumulative}
            days={normalizeDatapointsRegardingTariff(datapoints)}
            formatGeneralValue={value => `~${value && value.toFixed(0)}%`}/>
    </View>;
}

StatisticsTenDaysCPU.propTypes = {
    vm: PropTypes.shape({}),
    cumulative: PropTypes.number,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    datapoints: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
    })),
    fetchBarChartCPU: PropTypes.func
};

const mapStateToProps = state => ({
    ...state.cvds.currentVM.barChartCPU,
    vm: state.cvds.currentVM
});
const mapDispatchToProps = dispatch => bindActionCreators({
    fetchBarChartCPU,
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 16,
        paddingRight: 19,
        paddingBottom: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 1,
        backgroundColor: '#ffffff'
    },
    textBlock: {
        flex: 1
    },
    title: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14,
        fontWeight: '600'
    },
    subTitle: {
        color: '#455562',
        fontSize: 10,
        lineHeight: 14
    },
    icon: {
        height: 24,
        width: 24,
        marginRight: 12
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsTenDaysCPU);
