import React from 'react';
import PropTypes from 'prop-types';
import {
    View, StyleSheet, Modal, TouchableWithoutFeedback,
    TouchableOpacity
} from 'react-native';

const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    modalView: {
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: "white",
        borderRadius: 6,
        width: '100%',
    },
});

function MobileModal({
    isModalShowed = false,
    containerStyles = [],
    modalViewStyles = [],
    onClose = () => {},
    component
}) {
    return <View>
        <Modal visible={isModalShowed}
            transparent={true}
            onRequestClose={() => onClose()}>
            <TouchableOpacity style={[styles.centeredView, ...containerStyles]}
                activeOpacity={1}
                onPressOut={() => onClose()}>
                <View style={{ paddingRight: 5, paddingLeft: 5, width: '100%' }}>
                    <TouchableWithoutFeedback>
                        <View style={[styles.modalView, ...modalViewStyles]}>
                            {isModalShowed && component}
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </TouchableOpacity>
        </Modal>
    </View>;
}

MobileModal.propTypes = {
    isModalShowed: PropTypes.bool,
    containerStyles: PropTypes.arrayOf(PropTypes.shape({})),
    modalViewStyles: PropTypes.arrayOf(PropTypes.shape({})),
    onClose: PropTypes.func,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

export default MobileModal;
