const _ = require('lodash');
const fetch = require('isomorphic-fetch');

const fakeCatchResponse = (err) => ({
    ok: false,
    data: '{}',
    headers: {},
    redirected: false,
    statusText: err,
});

class RawClient {
    constructor({ token }) {
        this.jwtToken = token;
    }

    get defaultRequestOptions() {
        const options = {
            mode: 'cors',
            credentials: 'include',
            headers: {},
        };
        if (this.jwtToken) {
            options.headers.Authorization = `Bearer ${this.jwtToken}`;
        }
        return options;
    }

    makeUrl(url) {
        return url;
    }

    async makeRequest(url, requestOptions) {
        const options = _.merge(
            this.defaultRequestOptions,
            requestOptions || {},
        );
        options.headers['Content-Type'] =
            options.headers['Content-Type'] || 'application/json';
        if (options.data) {
            options.data = JSON.stringify(options.data);
        }
        const response = await fetch(this.makeUrl(url), options).catch((err) =>
            fakeCatchResponse(err),
        );
        return response;
    }

    async makeRequestUpload(url, requestOptions) {
        const options = _.merge(
            this.defaultRequestOptions,
            requestOptions || {},
        );
        const response = await fetch(this.makeUrl(url), options).catch((err) =>
            fakeCatchResponse(err),
        );
        return response;
    }

    async get(url, data, options) {
        let resultUrl = url || '';
        if (data != null) {
            const resultParamsArray = [];
            for (const key of Object.keys(data)) {
                const value = data[key];
                if (_.isArray(value)) {
                    for (const val of value) {
                        resultParamsArray.push(
                            `${encodeURIComponent(key)}=${encodeURIComponent(
                                val,
                            )}`,
                        );
                    }
                } else {
                    resultParamsArray.push(
                        `${encodeURIComponent(key)}=${encodeURIComponent(
                            value,
                        )}`,
                    );
                }
            }
            resultUrl = `${resultUrl}?${resultParamsArray.join('&')}`;
        }
        return this.makeRequest(resultUrl, _.merge({ method: 'GET' }, options));
    }

    async post(url, data, options) {
        const body = _.isPlainObject(data) ? JSON.stringify(data) : data;
        const requestOptions = _.merge(
            {
                body,
                method: 'POST',
            },
            options,
        );
        return this.makeRequest(url, requestOptions);
    }

    async delete(url, data, options) {
        const body = _.isPlainObject(data) ? JSON.stringify(data) : data;
        const requestOptions = _.merge(
            {
                body,
                method: 'DELETE',
            },
            options,
        );
        return this.makeRequest(url, requestOptions);
    }

    async put(url, data, options) {
        const requestOptions = _.merge(
            {
                body: JSON.stringify(data),
                method: 'PUT',
            },
            options,
        );
        return this.makeRequest(url, requestOptions);
    }

    async upload(method, url, data, options) {
        const body = data;
        const requestOptions = _.merge(
            {
                body,
                method: method.toUpperCase(),
            },
            options,
        );
        return this.makeRequestUpload(url, requestOptions);
    }
}

class RawResource {
    constructor({ token }) {
        this.client = new RawClient({ token });
    }

    async makeRequest(method, url, data, options) {
        return this.client[method](url, data, options);
    }

    async makeRequestUpload(method, url, data, options) {
        return this.client.upload(method, url, data, options);
    }
}

module.exports = RawResource;
