import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';


function WarningCancelPacketBlock(props) {
    const { mainText, warningText } = props;

    return <View>
        <Text style={styles.title}>
            Заказ годового пакета будет отменен
        </Text>
        <Text style={styles.description}>
            {mainText}
            <Text style={styles.warning}>
                {warningText}.
            </Text>
        </Text>
    </View>;
};

WarningCancelPacketBlock.propTypes = {
    mainText: PropTypes.string,
    warningText: PropTypes.string
};

const styles = StyleSheet.create({
    title: {
        color: '#d37947',
        fontSize: 14,
        fontWeight: '600',
        marginBottom: 10
    },
    description: {
        color: '#455562',
        fontSize: 12
    },
    warning: {
        color: '#d37947',
        fontSize: 12,
        display: 'inline-block',
        fontWeight: '600',
        marginLeft: 3
    }
});

export default WarningCancelPacketBlock;
