import {
    ACCOUNT_CHANGE_PHONE_SUCCESSFULLY,
    ACCOUNT_CHANGE_PHONE_IN_PROGRESS,
    ACCOUNT_CHANGE_PHONE_WITH_ERRORS,
    ACCOUNT_VERIFY_PHONE,
    ACCOUNT_VERIFIED_PHONE,
    ACCOUNT_INFO_IN_PROGRESS,
    ACCOUNT_INFO_WITH_ERRORS,
    ACCOUNT_INFO_SUCCESSFULLY,
    CHANGE_NOTIFICATION_PHONE,
    DELETE_EMAIL_IN_PROGRESS,
    DELETE_EMAIL_SUCCESSFULLY,
    DELETE_EMAIL_WITH_ERRORS,
    VERIFY_EMAIL,
    CHANGE_PASSWORD_IN_PROGRESS,
    CHANGE_PASSWORD_SUCCESSFULLY,
    CHANGE_PASSWORD_WITH_ERRORS,
    ADD_EMAIL_SUCCESSFULLY,
    ADD_EMAIL_IN_PROGRESS,
    ADD_EMAIL_WITH_ERRORS,
    CHANGE_REQUISITES_IN_PROGRESS,
    CHANGE_REQUISITES_SUCCESSFULLY,
    CHANGE_REQUISITES_WITH_ERRORS,
    GET_OTP_KEY,
    ACCOUNT_ENABLE_OTP_IN_PROGRESS,
    ACCOUNT_ENABLE_OTP_SUCCESSFULLY,
    ACCOUNT_ENABLE_OTP_WITH_ERRORS,
    ACCOUNT_DISABLE_OTP_IN_PROGRESS,
    ACCOUNT_DISABLE_OTP_SUCCESSFULLY,
    ACCOUNT_DISABLE_OTP_WITH_ERRORS,
} from '../actions/account/actionTypes';

export default () => {
    const defaultState = {
        account: null,
        accountInProgress: false,
        addEmailInProgress: false,
        changePhoneInProgress: false,
        deleteEmailInProgress: false,
        changePasswordInProgress: false,
        changeRequisitesInProgress: false,
        disableOtpInProgress: false,
        enableOtpInProgress: false,
        otpKey: null,
        otpCodes: null,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case ACCOUNT_INFO_SUCCESSFULLY: {
            return {
                ...state,
                account: action.data,
                accountInProgress: false,
            };
        }
        case ACCOUNT_INFO_IN_PROGRESS: {
            return {
                ...state,
                account: action.data,
                accountInProgress: true,
            };
        }
        case ACCOUNT_INFO_WITH_ERRORS: {
            return {
                ...state,
                accountInProgress: false,
            };
        }
        case ACCOUNT_CHANGE_PHONE_SUCCESSFULLY: {
            return {
                ...state,
                account: {
                    ...state.account,
                    phone_verification_data: action.data.phone_verification_data
                },
                changePhoneInProgress: false,
            };
        }
        case ACCOUNT_CHANGE_PHONE_IN_PROGRESS: {
            return {
                ...state,
                changePhoneInProgress: true,
            };
        }
        case ACCOUNT_CHANGE_PHONE_WITH_ERRORS: {
            return {
                ...state,
                changePhoneInProgress: false,
            };
        }
        case ACCOUNT_VERIFY_PHONE: {
            return {
                ...state,
                account: {
                    ...state.account,
                    ...action.data,
                    phone_verification_data: null,
                }
            };
        }
        case ACCOUNT_VERIFIED_PHONE: {
            return {
                ...state,
                account: {
                    ...state.account,
                    ...action.data
                }
            };
        }
        case CHANGE_NOTIFICATION_PHONE: {
            return {
                ...state,
                account: {
                    ...state.account,
                    phone_notification_enabled: action.data.phone_notification_enabled
                }
            };
        }
        case CHANGE_REQUISITES_IN_PROGRESS: {
            return {
                ...state,
                changeRequisitesInProgress: true,
            };
        }
        case CHANGE_REQUISITES_WITH_ERRORS: {
            return {
                ...state,
                changeRequisitesInProgress: false,
            };
        }
        case CHANGE_REQUISITES_SUCCESSFULLY: {
            return {
                ...state,
                account: {
                    ...state.account,
                    org_data: action.data.org_data,
                    post_addr: action.data.post_addr
                },
                changeRequisitesInProgress: false,
            };
        }
        case ADD_EMAIL_SUCCESSFULLY: {
            const key = `unverified_emails_${action.data.email_type}`;
            const newEmails = [...state.account[key].filter(email => email !== action.data.email)];
            return {
                ...state,
                account: {
                    ...state.account,
                    [key]: [...newEmails, action.data.email]
                },
                addEmailInProgress: false,
            };
        }
        case ADD_EMAIL_IN_PROGRESS: {
            return {
                ...state,
                addEmailInProgress: true,
            };
        }
        case ADD_EMAIL_WITH_ERRORS: {
            return {
                ...state,
                addEmailInProgress: false,
            };
        }
        case DELETE_EMAIL_IN_PROGRESS: {
            return {
                ...state,
                deleteEmailInProgress: true,
            };
        }
        case DELETE_EMAIL_WITH_ERRORS: {
            return {
                ...state,
                deleteEmailInProgress: false,
            };
        }
        case DELETE_EMAIL_SUCCESSFULLY: {
            const deletedEmail = action.data.email;
            const unverifiedKey = `unverified_emails_${action.data.email_type}`;
            const key = `emails_${action.data.email_type}`;
            const newEmails = state.account[key].filter(email => email !== deletedEmail);
            const newUnverifiedEmails = state.account[unverifiedKey].filter(email => email !== deletedEmail);
            return {
                ...state,
                account: {
                    ...state.account,
                    [key]: newEmails,
                    [unverifiedKey]: newUnverifiedEmails
                },
                deleteEmailInProgress: false,
            };
        }
        case VERIFY_EMAIL: {
            const { email } = action.data;
            const unverifiedKey = `unverified_emails_${action.data.email_type}`;
            const key = `emails_${action.data.email_type}`;
            const emails = state.account[key].filter(eml => eml !== email);
            const unverifiedEmails = state.account[unverifiedKey].filter(eml => eml !== email);
            return {
                ...state,
                account: {
                    ...state.account,
                    [key]: [...emails, email],
                    [unverifiedKey]: unverifiedEmails
                }
            };
        }
        case CHANGE_PASSWORD_SUCCESSFULLY: {
            return {
                ...state,
                changePasswordInProgress: false,
            };
        }
        case CHANGE_PASSWORD_IN_PROGRESS: {
            return {
                ...state,
                changePasswordInProgress: true,
            };
        }
        case CHANGE_PASSWORD_WITH_ERRORS: {
            return {
                ...state,
                changePasswordInProgress: false,
            };
        }
        case GET_OTP_KEY: {
            return {
                ...state,
                otpKey: action.data.secret_key,
            };
        }
        case ACCOUNT_ENABLE_OTP_IN_PROGRESS: {
            return {
                ...state,
                enableOtpInProgress: true,
            };
        }
        case ACCOUNT_ENABLE_OTP_WITH_ERRORS: {
            return {
                ...state,
                enableOtpInProgress: false,
            };
        }
        case ACCOUNT_ENABLE_OTP_SUCCESSFULLY: {
            return {
                ...state,
                otpCodes: action.data.codes,
                enableOtpInProgress: false,
                account: {
                    ...state.account,
                    otp_enabled: true,
                },
            };
        }
        case ACCOUNT_DISABLE_OTP_IN_PROGRESS: {
            return {
                ...state,
                disableOtpInProgress: true,
            };
        }
        case ACCOUNT_DISABLE_OTP_WITH_ERRORS: {
            return {
                ...state,
                disableOtpInProgress: false,
            };
        }
        case ACCOUNT_DISABLE_OTP_SUCCESSFULLY: {
            return {
                ...state,
                otpCodes: action.data.codes,
                disableOtpInProgress: false,
                account: {
                    ...state.account,
                    otp_enabled: false,
                },
            };
        }
        default:
            return state;
        }
    };
};
