import React from 'react';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    Image,
    TouchableOpacity
} from 'react-native';

import { mainServices, mainServicesList } from '../../../constants/general';

function ServicesList() {
    const navigation = useNavigation();
    function servicesList() {
        return mainServicesList.map((service) => <TouchableOpacity
            onPress={() => navigation.navigate(service.screenName)}
            key={`mobile-service-item-${service.service}`}
            style={styles.serviceItem}>
            <Image source={mainServices[service.service].image} style={styles.icon} />
            <Text style={styles.serviceItemTitle}>
                {mainServices[service.service].title}
            </Text>
        </TouchableOpacity>);
    }

    return <View style={styles.wrapper}>
        <Text style={styles.title}>Услуги</Text>
        <View>
            {servicesList(navigation)}
        </View>
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 24,
    },
    title: {
        color: '#455562',
        paddingBottom: 12,
        paddingLeft: 21,
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 16,
    },
    icon: {
        width: 24,
        height: 24,
        marginRight: 17
    },
    serviceItem: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#ffffff',
        borderRadius: 8,
        paddingLeft: 12,
        paddingRight: 18,
        paddingTop: 10,
        paddingBottom: 12,
        marginLeft: 18,
        marginRight: 18,
        marginBottom: 8,
    },
    serviceItemTitle: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 14,
    },
});

export default ServicesList;