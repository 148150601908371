export const linking = {
    config: {
        screens: {
            RestorePassword: {
                path: 'auth/password/restore/'
            },
            RestorePasswordDone: {
                path: 'auth/password/restore/done/'
            },
            RestorePasswordFail: {
                path: 'auth/password/confirm/fail/'
            },
            RestorePasswordConfirm: {
                path: 'auth/password/confirm/:secretKey/'
            },
            Auth: {
                path: 'auth',
                screens: {
                    AuthFail: {
                        path: 'fail',
                    },
                },
            },
            OtpStack: {
                path: 'otp',
                screens: {
                    OtpFail: {
                        path: 'fail',
                    },
                },
            },
            MainStack: {
                path: '',
                screens: {
                    Account: {
                        path: 'account',
                        screens: {
                            AccountScreen: ''
                        }
                    },
                    Finance: {
                        path: 'finance',
                        screens: {
                            FinanceTabs: {
                                path: '',
                                screens: {
                                    FinanceBalance: 'balance',
                                    FinanceDetail: 'details',
                                    FinanceDocuments: 'documents'
                                }
                            }
                        }
                    },
                    Partnership: {
                        path: 'partnership',
                        screens: {
                            PartnershipTabs: {
                                path: '',
                                screens: {
                                    PartnershipInfo: 'info',
                                    PartnershipDetail: 'details',
                                    PartnershipClients: 'clients',
                                }
                            }
                        }
                    },
                    General: {
                        path: 'home',
                        screens: {
                            GeneralScreen: ''
                        }
                    },
                }
            },
            Hosting: {
                path: 'hosting',
                screens: {
                    HostingScreen: 'containers'
                }
            },
            HostingBackup: {
                path: 'hosting/backups',
                screens: {
                    HostingBackupScreen: {
                        path: '',
                    },
                }
            },
            HostingBackupResources: {
                path: 'hosting/backups/:containerId/',
                screens: {
                    HostingBackupResourcesScreen: '',
                }
            },
            HostingBackupContainer: {
                path: 'hosting/backups/:containerId/:restoreDate/',
                screens: {
                    HostingBackupContainerScreen: '',
                }
            },
            HostingBackupVirtualhost: {
                path: 'hosting/backups/:containerId/virtualhost/:virtualhost/:restoreDate?/',
                screens: {
                    HostingBackupVirtualhostScreen: '',
                }
            },
            HostingBackupDbms: {
                path: 'hosting/backups/:containerId/virtualhost/:virtualhost/:restoreDate?/dbms/:dbmsId?/',
                screens: {
                    HostingBackupDbmsScreen: '',
                }
            },
            HostingBackupConfiguration: {
                path: 'hosting/backups/configuration/',
                screens: {
                    HostingBackupConfigurationScreen: '',
                }
            },
            CVDSBackup: {
                path: 'cloud/backups',
                screens: {
                    CVDSBackupScreen: {
                        path: '',
                    },
                }
            },
            CVDSBackupDisks: {
                path: 'cloud/backups/:vmId/',
                screens: {
                    CVDSBackupDisksScreen: '',
                }
            },
            CVDSBackupMethods: {
                path: 'cloud/backups/:vmId/:volumeId',
                screens: {
                    CVDSBackupMethodsScreen: '',
                }
            },
            CVDSBackupConfiguration: {
                path: 'cloud/backups/configuration/',
                screens: {
                    CVDSBackupConfigurationScreen: '',
                }
            },
            HostingContainer: {
                path: 'hosting/:containerId',
                screens: {
                    HostingContainerStack: {
                        path: '',
                        screens: {
                            HostingVirtualhosts: 'virtualhosts',
                            HostingTariff: 'tariff',
                            HostingActions: 'actions'
                        }
                    }
                }
            },
            HostingContainerVirtualhost: {
                path: 'hosting-virtualhost/:virtualhostId',
                screens: {
                    HostingContainerVirtualhostStack: {
                        path: '',
                        screens: {
                            HostingVirtualhost: 'details',
                            HostingVirtualhostAccess: 'access',
                            HostingVirtualhostApplications: 'applications',
                        }
                    }
                }
            },
            HostingCreateVirtualhost: {
                path: 'hosting-containers/:containerId/create',
                screens: {
                    HostingCreateVirtualhostStack: ''
                }
            },
            HostingCreateContainer: {
                path: 'hosting/create',
                screens: {
                    HostingCreateContainerStep1: '1',
                    HostingCreateContainerStep2: '2'
                }
            },
            CVDSVMEditBandwidth: {
                path: 'cloud/vm/:vmId/bandwidth/',
                screens: {
                    CVDSVMEditBandwidthStack: ''
                }
            },
            CVDSVMProtectedIps: {
                path: 'cloud/vm/:vmId/ips/',
                screens: {
                    CVDSVMProtectedIpsStack: ''
                }
            },
            CVDSVMProtectedWebsites: {
                path: 'cloud/vm/:vmId/websites/',
                screens: {
                    CVDSVMProtectedWebsitesStack: ''
                }
            },
            CVDSVM: {
                path: 'cloud/vm/:vmId',
                screens: {
                    CVDSVMStack: {
                        path: '',
                        screens: {
                            VMSummary: 'details',
                            CVDSVMDisk: 'volumes',
                            VMAccess: 'access',
                            VMNetwork: 'network'
                        }
                    }
                }
            },
            CVDS: {
                path: 'cloud',
                screens: {
                    CVDSStack: 'vms'
                }
            },
            CVDSCreate: {
                path: 'cloud/create',
                screens: {
                    CVDSCreateStack: ''
                }
            },
            Payment: {
                path: 'finance/payment',
                screens: {
                    PaymentStack: ''
                }
            },
            PartnershipWithdrawal: {
                path: 'partnership/withdrawal',
                screens: {
                    PartnershipWithdrawalStack: ''
                }
            },
            Notifications: {
                path: 'notifications',
                screens: {
                    Notification: ''
                }
            },
            SendInvoiceToEmail: {
                path: 'finance/send-invoice/:invoiceId',
                screens: {
                    SendInvoiceToEmailScreen: ''
                }
            },
            ChangePassword: {
                path: 'account/password',
                screens: {
                    ChangePasswordStack: ''
                }
            },
            ChangeRequisites: {
                path: 'partnership/withdrawal/requisites',
                screens: {
                    ChangeRequisitesStack: ''
                }
            },
            Mail: {
                path: 'mail/v2/',
                screens: {
                    MailTabs: {
                        path: '',
                        screens: {
                            MailDomains: '',
                            MailStatistics: 'statistics',
                        }
                    }
                }
            },
            MailDomain: {
                path: 'mail/v2/:domainId',
                screens: {
                    MailDomainStack: '',
                }
            },
            MailboxSettings: {
                path: 'mail/v2/:domainId/:mailboxId',
                screens: {
                    MailboxSettingsStack: '',
                }
            },
            MailPacket: {
                path: 'mail/v2/statistics/packets/',
                screens: {
                    MailPacketStack: '',
                }
            },
            TransferAccept: {
                path: 'transfer/accept/:key',
                screens: {
                    TransferAcceptScreen: '',
                }
            },
            Transfer: {
                path: 'transfer/:id?/:service?/',
                params: {
                    id: null,
                    service: null,
                },
                screens: {
                    TransferScreen: '',
                }
            },
            Monitoring: {
                path: 'monitoring/',
                screens: {
                    MonitoringScreen: '',
                }
            },
            MonitoringCvds: {
                path: 'monitoring/cvds/',
                screens: {
                    MonitoringCvdsScreen: '',
                }
            },
            MonitoringHosting: {
                path: 'monitoring/hosting/',
                screens: {
                    MonitoringHostingScreen: '',
                }
            },
            MonitoringMail: {
                path: 'monitoring/mail/',
                screens: {
                    MonitoringMailScreen: '',
                }
            },
            SSL: {
                path: 'certificates/',
                screens: {
                    SslScreen: '',
                }
            },
            SSLDetail: {
                path: 'certificates/:certificateId',
                screens: {
                    SslDetailScreen: '',
                }
            },
        }
    }
};
