import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';


import Modal from '../../General/Modal/Modal';
import { sslFreeIcon } from '../../../icons/index';
import SSLFreeOrder from '../HostingModal/SSLFreeOrder';

function SSLFreeBlock(props) {
    const {
        enabled,
        virtualhostId,
    } = props;
    const [isModalAddSSL, setModalAddSSL] = React.useState(false);

    return <View style={styles.wrapper}>
        <View style={styles.info}>
            <Text style={styles.title}>SSL-сертификат</Text>
            <Text style={styles.description}>
                Установите бесплатный<br/>
                сертификат Let’s Encrypt<br/>
                с автопродлением</Text>
            <TouchableOpacity onPress={() => {
                if (enabled) {
                    setModalAddSSL(true);
                }}}
                style={styles.button}>
                <Text style={enabled ? styles.buttonText : styles.disableButtonText }>Подключить бесплатно</Text>
            </TouchableOpacity>
        </View>
        <Image source={sslFreeIcon} style={styles.image}/>
        <Modal isModalShowed={isModalAddSSL}
            component={<SSLFreeOrder
                virtualhostId={virtualhostId}
                onClose={() => setModalAddSSL(false)}/>}
            onClose={() => setModalAddSSL(false)} />
    </View>;
}

SSLFreeBlock.propTypes = {
    virtualhostId: PropTypes.number,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        backgroundColor: '#ffffff',
        marginTop: 10,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'center',
        paddingTop: 19,
        paddingLeft: 18,
        minHeight: 162
    },
    info: {
        flex: 1,
    },
    description: {
        color: '#455562',
        fontSize: 11,
        letterSpacing: 0,
        lineHeight: 14,
        paddingBottom: 19,
    },
    title: {
        opacity: '0.75',
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: '1.38px',
        lineHeight: 13,
        textTransform: 'uppercase',
        paddingBottom: 15,
    },
    button: {
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
    },
    buttonText: {
        color: '#0F79D5',
    },
    disableButton: {
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
        opacity: '0.5',
    },
    disableButtonText: {
        color: '#888',
    },
    image: {
        width: 115,
        height: 143,
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SSLFreeBlock);