import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Clipboard from 'expo-clipboard';
import {
    Text, View, ScrollView, StyleSheet, Image, TouchableOpacity
} from 'react-native';

import {
    copyBlueIcon,
    longArroyUpGreenIcon,
    longArroyDownRedIcon,
} from '../../icons';
import Button from '../General/Button/Button';
import {
    monthsDeclension,
    partnershipServiceMap,
    partnershipServiceColorMap
} from '../../constants/partnership';
import { addCustomNotification } from '../../actions/notifications/actions';

const month = (months) => {
    const month = dayjs(months[months.length - 1].month).format('MMMM');
    return month;
};
import { formatPrice } from '../../utils/general';

function difference(prev, current) {
    if (current === 0 && prev === 0) {
        return '0%';
    }
    if (prev === 0) {
        return <View style={styles.differentBlock}>
            <Text style={styles.increased}>100%</Text>
            <Image source={longArroyUpGreenIcon} style={styles.iconArroy}
                resizeMode='contain'/>
        </View>;
    }
    const result = ((current - prev) / prev) * 100;
    return <View style={styles.differentBlock}>
        <Text style={result > 0 ? styles.increased : styles.decreased }>
            {Math.abs(result).toFixed(0)}%
        </Text>
        <Image source={result > 0 ? longArroyUpGreenIcon : longArroyDownRedIcon}
            resizeMode='contain'
            style={styles.iconArroy}/>
    </View>;
}

function DistributionByServices({ month }) {
    if (month.accruals.length > 0) {
        return month.accruals.map((item, i) => <View
            key={`partnership-service-${i}`}>
            <View style={styles.serviceBlock}>
                <Text style={[
                    styles.circle,
                    { backgroundColor: partnershipServiceColorMap[item.service] }
                ]}></Text>
                <Text style={styles.service}>
                    {partnershipServiceMap[item.service]}&nbsp;
                        - <Text style={styles.bold}>{item.amount} ₽</Text>
                </Text>
            </View>
        </View>);
    }
    return <View>
        <Text style={styles.service}>В данном месяце начислений не производилось</Text>
    </View>;
}

function StatisticBlock({ title, count, prevCount, needRub }) {
    return <View style={styles.statisticWrapper}>
        <Text style={styles.statisticTitle}>{title}</Text>
        <Text style={styles.statisticValue}>{count} {needRub ? '₽' : ''}</Text>
        <Text style={styles.statisticHint}>
            {difference(prevCount, count)}&nbsp;по сравнению с прошлым месяцем
        </Text>
    </View>;
}

function PartnershipInfoTab(props) {
    const { navigation } = props;
    const dispatch = useDispatch();
    const { login } = useSelector((state) => state.user.user);
    const { partnership } = useSelector((state) => state.partnership);
    const { statistics } = useSelector((state) => state.partnershipStatistics);
    return <ScrollView>
        <View>
            <Text style={styles.balanceTitle}>партнерский счет</Text>
            {partnership && <Text style={styles.balanceValue}>{formatPrice(partnership.balance)}</Text>}
            <Button title='Вывести средства'
                additionalWrapperStyles={[{
                    marginLeft: 25, marginRight: 25, marginBottom: 23, marginTop: 16
                }]}
                additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 13 }]}
                additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16 }]}
                onPress={() => navigation.navigate('PartnershipWithdrawal')}/>
        </View>
        {statistics && statistics.months && <View>
            <StatisticBlock title={`Доход за ${month(statistics.months)}`}
                needRub={true}
                count={statistics.months[statistics.months.length - 1].amount}
                prevCount={statistics.months[statistics.months.length - 2].amount} />
            <StatisticBlock title={`Новые клиенты в ${monthsDeclension[month(statistics.months)]}`}
                needRub={false}
                count={statistics.months[statistics.months.length - 1].clients}
                prevCount={statistics.months[statistics.months.length - 2].clients} />
            <View style={styles.statisticWrapper}>
                <Text style={styles.statisticTitle}>
                    Распределение дохода по услугам в&nbsp;{
                        monthsDeclension[month(statistics.months)]
                    }
                </Text>
                <View style={styles.serviceWrapper}>
                    <DistributionByServices
                        month={statistics.months[statistics.months.length - 1]}
                    />
                </View>
            </View>
        </View>}
        <TouchableOpacity onPress={() => {
            Clipboard.setString(`https://netangels.ru/?p_ref=${login}`);
            dispatch(addCustomNotification('Скопировано'));
        }}
        style={styles.copyBlock}>
            <Image source={copyBlueIcon} style={styles.image} />
            <Text style={styles.copyText}>Скопировать реферальную ссылку</Text>
        </TouchableOpacity>
    </ScrollView>;
}

StatisticBlock.propTypes = {
    title: PropTypes.string,
    count: PropTypes.number,
    prevCount: PropTypes.number,
    needRub: PropTypes.bool,
};

PartnershipInfoTab.propTypes = {
    navigation: PropTypes.shape(),
};

const styles = StyleSheet.create({
    balanceTitle: {
        fontSize: 11,
        color: '#455562',
        fontWeight: '500',
        opacity: '0.75',
        lineHeight: 13,
        letterSpacing: '1.375px',
        textTransform: 'uppercase',
        margingBottom: 6,
        marginLeft: 25,
        marginTop: 15,
    },
    balanceValue: {
        fontSize: 30,
        color: '#455562',
        fontWeight: '500',
        lineHeight: 35,
        marginLeft: 25,
    },
    statisticWrapper: {
        backgroundColor: '#ffffff',
        paddingLeft: 25,
        paddingBottom: 13,
        paddingTop: 21,
        marginBottom: 4,
    },
    statisticTitle: {
        fontSize: 13,
        color: '#455562',
        fontWeight: '500',
        lineHeight: 14,
        marginBottom: 8,
    },
    statisticValue: {
        fontSize: 20,
        color: '#455562',
        fontWeight: '500',
        lineHeight: 23,
        marginBottom: 5,
    },
    statisticHint: {
        fontSize: 11,
        lineHeight: 13,
        color: '#455562',
        fontWeight: '500',
        opacity: '0.7',
    },
    bold: {
        fontWeight: '500',
    },
    serviceWrapper: {
        flex: 1,
        marginTop: 15,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
    },
    serviceBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 20,
        marginBottom: 10,
    },
    service: {
        fontSize: 11,
        lineHeight: 13,
        color: '#455562',
        whiteSpace: 'nowrap',
    },
    circle: {
        minWidth: 10,
        height: 10,
        borderRadius: '50%',
        marginRight: 7,
    },
    hosting: {
        backgroundColor: '#49B28A',
    },
    vds: {
        backgroundColor: '#AC7BC9',
    },
    copyText: {
        color: '#0F79D5',
        fontSize: 13,
        lineHeight: 15,
    },
    copyBlock: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        color: '#0F79D5',
        paddingTop: 20,
        paddingLeft: 25,
        marginBottom: 20,
        marginTop: 3,
        paddingBottom: 20,
    },
    image: {
        width: 16,
        height: 16,
        marginRight: 12,
    },
    differentBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    iconArroy: {
        minWidth: 9,
        height: 9,
        marginLeft: 2,
    },
    increased: {
        color: '#11AB70',
    },
    decreased: {
        color: '#D06565',
    }
});

export default PartnershipInfoTab;
