const BaseResource = require('../resource');
const { makePaginateParams } = require('../../utils');

class VirtualhostsResource extends BaseResource {
    async detail(virtualhostId) {
        return this.makeRequest('get', `${virtualhostId}/`);
    }

    async delete(virtualhostId) {
        return this.makeRequest('delete', `${virtualhostId}/`);
    }

    async enable(virtualhostId) {
        return this.makeRequest('put', `${virtualhostId}/enable/`);
    }

    async disable(virtualhostId) {
        return this.makeRequest('put', `${virtualhostId}/disable/`);
    }

    async update(virtualhostId, data) {
        return this.makeRequest('put', `${virtualhostId}/`, data);
    }

    async ftps(virtualhostId, limit, offset) {
        return this.makeRequest('get', `${virtualhostId}/ftps/`, makePaginateParams(limit, offset));
    }

    async create(data) {
        return this.makeRequest('post', 'create-and-install/', data);
    }

    async addAlias(virtualhostId, alias) {
        return this.makeRequest('put', `${virtualhostId}/add-alias/`, { alias });
    }

    async deleteAlias(virtualhostId, alias) {
        return this.makeRequest('put', `${virtualhostId}/delete-alias/`, { alias });
    }

    async certificateOrder(virtualhostId) {
        return this.makeRequest('put', `${virtualhostId}/order-certificate/`);
    }

    async certificateUpload(virtualhostId, formData) {
        return this.makeRequestUpload('post', `${virtualhostId}/upload-certificate/`, formData);
    }

    async engineVersions(virtualhostId) {
        return this.makeRequest('get', `${virtualhostId}/engine-versions/`);
    }

    async engineSettings(virtualhostId, data) {
        return this.makeRequest('put', `${virtualhostId}/engine-settings/`, data);
    }

    async environments(virtualhostId, data) {
        return this.makeRequest('put', `${virtualhostId}/environments/`, data);
    }

    async virtualhostRestart(virtualhostId) {
        return this.makeRequest('put', `${virtualhostId}/restart/`);
    }

    async phpSettingsForCMS(virtualhostId, cms) {
        return this.makeRequest('put', `${virtualhostId}/set-phpsettings-for-cms/`, { cms_name: cms });
    }

    async redisSessionSwitch(virtualhostId, state) {
        return this.makeRequest('put', `${virtualhostId}/redis-session-switch/`, {
            enabled: state
        });
    }

    async setComment(virtualhostId, comment) {
        return this.makeRequest('put', `${virtualhostId}/comment/`, {
            comment
        });
    }

    async backupsList(virtualhostId) {
        return this.makeRequest('get', `${virtualhostId}/backups/`);
    }
}

VirtualhostsResource.resource = 'virtualhosts';
VirtualhostsResource.service = 'hosting';

module.exports = VirtualhostsResource;
