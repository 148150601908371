import {
    NOTIFICATIONS_IN_PROGRESS,
    NOTIFICATIONS_SUCCESSFULLY,
    NOTIFICATIONS_WITH_ERRORS,
    NOTIFICATIONS_MARK_SUCCESSFULLY,
    NOTIFICATIONS_MARK_WITH_ERRORS,
    NOTIFICATION_UPDATE,
    NOTIFICATION_MOMENT,
    NOTIFICATION_MOMENT_DELETE,
    CUSTOM_NOTIFICATION,
    CUSTOM_NOTIFICATION_DELETE,
    ALL_NOTIFICATIONS_MARKED_SUCCESSFULLY,
} from './actionTypes';

import { isFunction } from '../../utils/general';
import { momentNotificationTimeout } from '../../constants/general';
import NotificationResource from '../../gateway/resources/notifications';

export const fetchUserNotifications = (callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: NOTIFICATIONS_IN_PROGRESS });
    const resource = new NotificationResource(gateway);
    const response = await resource.list();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: NOTIFICATIONS_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: NOTIFICATIONS_WITH_ERRORS });
    }
};

export const markUserNotifications = (notifications) => async (dispatch, getState, { gateway }) => {
    if (notifications.length > 0) {
        const resource = new NotificationResource(gateway);
        const response = await resource.mark({ uids: notifications.map(item => item.uid) });
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: NOTIFICATIONS_MARK_SUCCESSFULLY,
                data
            });
        } else {
            dispatch({ type: NOTIFICATIONS_MARK_WITH_ERRORS });
        }
    }
};

export const updateNotification = (notification) => async (dispatch) => {
    dispatch({
        type: NOTIFICATION_UPDATE,
        data: notification
    });
};

export const momentNotification = (notification) => async (dispatch) => {
    if (!notification.is_hidden) {
        dispatch({
            type: NOTIFICATION_MOMENT,
            data: notification
        });
        if (notification.state !== 'Progress') {
            setTimeout(() => {
                dispatch({
                    type: NOTIFICATION_MOMENT_DELETE,
                    data: notification
                });
            }, momentNotificationTimeout);
        }
    }
};

export const addCustomNotification = (message) => async (dispatch) => {
    dispatch({
        type: CUSTOM_NOTIFICATION,
        data: { message }
    });
    setTimeout(() => {
        dispatch({
            type: CUSTOM_NOTIFICATION_DELETE,
            data: { message }
        });
    }, momentNotificationTimeout);
};

export const momentNotificationDelete = (notification) => async (dispatch, getState, { gateway }) => {
    if (notification.uid) {
        const resource = new NotificationResource(gateway);
        const response = await resource.hide({ uids: [notification.uid] });
        if (response.ok) {
            dispatch({
                type: NOTIFICATION_MOMENT_DELETE,
                data: notification
            });
        }
    } else {
        dispatch({
            type: CUSTOM_NOTIFICATION_DELETE,
            data: notification
        });
    }
};

export const readAllNotification = (callback) => async (dispatch, getState, { gateway }) => {
    const resource = new NotificationResource(gateway);
    const response = await resource.readAll();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: ALL_NOTIFICATIONS_MARKED_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    }
};
