import PropTypes from 'prop-types';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';

import Errors from '../../General/Form/Errors';
import { flushErrors } from '../../../actions/forms/actions';
import { acceptTransfer } from '../../../actions/transfer/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';
import { navigationHelper } from '../../../utils/transfer';

const formId = 'StartTransferFormId';

function AcceptTransferModal(props) {
    const {
        onClose,
        service,
        task,
        instance,
    } = props;
    const navigation = useNavigation();
    const { transferCancelInProgress } = useSelector(
        (state) => state.transfer,
    );
    const { errors } = useSelector((state) => state.forms);

    const currentErrors = errors[formId] || {};
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);

    return <View style={styles.wrapper}>
        <Text style={styles.title}>Принять ресурсы от {task.sender}</Text>
        {currentErrors.__all__ && <Errors errors={currentErrors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => dispatch(
                    acceptTransfer(
                        formId,
                        task.id,
                        () => onClose(),
                        () => navigationHelper(service, navigation, instance),
                    )
                )}
                title='Принять'
                inProgress={transferCancelInProgress}/>
            <CancelButton onClose={onClose} disabled={transferCancelInProgress}/>
        </View>
    </View>;
}

AcceptTransferModal.propTypes = {
    onClose: PropTypes.func,
    task: PropTypes.shape({
        id: PropTypes.string,
        sender: PropTypes.string,
    }),
    instance: PropTypes.shape({
        id: PropTypes.string,
    }),
    service: PropTypes.string,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 30,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20
    },
    title: {
        marginBottom: 22,
        color: '#455562',
        fontSize: 16,
        lineHeight: 19
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default AcceptTransferModal;