import React from 'react';
import { View, StyleSheet } from 'react-native';

import ResourceLoader from '../ResourceLoader/ResourceLoader';

function RestoreBackupListLoader() {
    return <View style={styles.wrapper}>
        <View style={[styles.title, { marginTop: 0 }]}>
            <ResourceLoader height={13} count={1}/>
        </View>
        <ResourceLoader height={54} count={3}/>
        <View style={styles.title}>
            <ResourceLoader height={13} count={1}/>
        </View>
        <ResourceLoader height={54} count={3}/>
    </View>;
}

const styles = StyleSheet.create({
    title: {
        marginTop: 24,
        marginLeft: 20,
        marginBottom: 10,
        maxWidth: 225,
    },
});

export default RestoreBackupListLoader;
