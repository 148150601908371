import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';

import {
    createContainerAction,
    createContainerSetParams,
    createContainerFlushParams
} from '../../../actions/hosting/actions';
import { calculatePrice } from '../calculation';
import Button from '../../General/Button/Button';
import Switch from '../../General/Switch/Switch';
import prices from '../../../data/hosting/prices.json';
import SliderDynamic from '../../General/Slider/SliderDynamic';
import { tariffs, environmentMap } from '../../../constants/hosting';
import { formatPrice } from '../../../utils/general';

const formId = 'containerCreateForm';

function normalizeQuota(storage) {
    return storage * 1024;
}

function calculate(createPacket, createContainerParams) {
    let calculatedPrice = currentPacketCost(createContainerParams);
    if (createPacket) {
        calculatedPrice = currentPacketCost(createContainerParams) - discountCost(createContainerParams);
    }
    return (calculatedPrice / 12).toFixed(2);
}

function currentPacketCost(createContainerParams) {
    const { containerTariff, storage, redis, memcached } = createContainerParams;
    return 12 * calculatePrice(containerTariff.ram, storage, redis || 0, memcached || 0, prices);
}

function discountCost(createContainerParams) {
    return (currentPacketCost(createContainerParams) * 0.1).toFixed(2);
}

function Application({ application, errors }) {
    return <View style={styles.applicationBlock}>
        <View style={styles.application}>
            <Text style={styles.applicationTitle}>{application.title}</Text>
            <View style={styles.spring}/>
            {application.value != null && application.isChecked && <Text style={styles.applicationValue}>
                {application.value} МБ
            </Text>}
            <View>
                <Switch
                    isEnable={application.isChecked}
                    onChange={() => application.onChangeSwitch()}/>
            </View>
        </View>
        {application.isChecked && <SliderDynamic
            value={256}
            items={application.scale}
            unit='МБ'
            notNeedToShowValue={true}
            onChange={(value) => application.onChangeSlider(value)}/>}
        {!application.isChecked && <Text style={styles.applicationHint}>
            {application.hint}
        </Text>}
        {errors[application.title.toLowerCase()] && <Text tyle={styles.errors}>{errors[application.title.toLowerCase()]}</Text>}
    </View>
}

function TariffsBlock({ onChange, tariffChecked }) {
    return <View>
        {tariffs.map((tariff, i) => {
            const currentTariff = tariff.name === tariffChecked;
            const modifiersWrapper = currentTariff ? [styles.tariff, styles.tariffChecked] : [styles.tariff, styles.tariffUnchecked];
            const modifiersTitle = currentTariff ? [styles.tariffTitle, styles.tariffTitleChecked] : [styles.tariffTitle, styles.tariffTitleUnchecked];

            return <TouchableOpacity key={`tariff-${tariff.name}-${i}`}
                    onPress={() => onChange(tariff)} style={modifiersWrapper}>
                <View style={currentTariff ? styles.tariffCircleChecked : styles.tariffCircle}>
                    {currentTariff && <View style={styles.tariffCircleSmall}></View>}
                </View>
                <Text style={modifiersTitle}>{tariff.title}</Text>
                <View style={styles.spring}/>
                <View>
                    <Text style={styles.tariffResource}>RAM: {tariff.ram} МБ, CPU: {tariff.cpu} СР</Text>
                </View>
            </TouchableOpacity>;
        })}
    </View>;
}

function HostingCreateContainerStep2(props) {
    const {
        errors,
        inProgress,
        createContainerAction,
        createContainerParams,
        createContainerSetParams,
        createContainerFlushParams,
    } = props;
    const navigation = useNavigation();
    const storageScale = [];
    const redisScale = [];
    for (let i = 5; i <= 100; i++) {
        storageScale.push(i);
    }
    for (let j = 256; j <= 2048; j = j + 256) {
        redisScale.push(j);
    }
    const [redis, setRedis] = React.useState(null);
    const [memcached, setMemcached] = React.useState(null);
    const [createPacket, setCreatePacket] = React.useState(false);
    const [isRedisChecked, setRedisChecked] = React.useState(false);
    const [isMemcachedChecked, setMemcachedChecked] = React.useState(false);
    const [tariffChecked, setTariffChecked] = React.useState(tariffs.filter(tariff => tariff.default)[0].name);
    const applications = [{
            title: 'Redis',
            value: redis,
            isChecked: isRedisChecked,
            scale: redisScale,
            hint: 'Включить Redis, одну из самых популярных NoSQL баз данных в мире.',
            onChangeSwitch: () => {
                setRedisChecked(!isRedisChecked);
                if (!isRedisChecked) {
                    createContainerSetParams({ redis: 256 },
                        () => setRedis(256));
                } else {
                    createContainerSetParams({ redis: null },
                        () => setRedis(null));
                }
            },
            onChangeSlider: (value) => {
                createContainerSetParams({ redis: value },
                    () => setRedis(value));
            }
        }, {
            title: 'Memcached',
            value: memcached,
            isChecked: isMemcachedChecked,
            scale: redisScale,
            hint: 'Включить кеширующее хранилище Memcached.',
            onChangeSwitch: () => {
                setMemcachedChecked(!isMemcachedChecked);
                if (!isMemcachedChecked) {
                    createContainerSetParams({ memcached: 256 },
                        () => setMemcached(256));
                } else {
                    createContainerSetParams({ memcached: null },
                        () => setMemcached(null));
                }
            },
            onChangeSlider: (value) => {
                createContainerSetParams({ memcached: value },
                    () => setMemcached(value));
            }
        }
    ];

    return <View style={styles.wrapper}>
        <View style={styles.tabs}>
            <TouchableOpacity
                onPress={() => navigation.navigate('HostingCreateContainerStep1')}
                style={[styles.disabledTab, styles.tab, styles.leftTab ]}>
                <Text style={styles.disabledTabCircle}>1</Text>
                <Text style={styles.disabledTabText}>Имя и окружение</Text>
            </TouchableOpacity>
            <View style={styles.tab}>
                <Text style={styles.tabCircle}>2</Text>
                <Text style={styles.tabText}>Тариф</Text>
            </View>
        </View>
        <TariffsBlock
            tariffChecked={tariffChecked}
            onChange={(tariff) => {
                createContainerSetParams({ containerTariff: tariffs.find(t => t.name === tariff.name) },
                    () => setTariffChecked(tariff.name));
            }}/>
        {errors.memory_limit && <Text style={styles.errors}>{errors.memory_limit}</Text>}
        <View style={styles.storageBlock}>
            <SliderDynamic
                title='Размер диска'
                value={5}
                items={storageScale}
                unit='ГБ'
                onChange={(storage) => {
                    createContainerSetParams({ storage: storage });
                }}/>
        </View>
        {errors.quota && <Text style={styles.errors}>{errors.quota}</Text>}
        {applications.map((application, a) => <Application
            errors={errors}
            application={application}
            key={`hosting-create-container-application-${a}`}/>)}
        <View style={[styles.switch, styles.switchPacket]}>
            <Switch label='Оплата за год со скидкой 10%'
                isEnable={createPacket}
                onChange={() => createContainerSetParams({ create_packet: !createPacket },
                    () => setCreatePacket(!createPacket))}/>
        </View>
        {!createPacket && <Text style={styles.packetHint}>
            Годовой пакет можно будет подключить позже
        </Text>}
        {createPacket && <Text style={styles.packetHint}>
            Вы платите за год {(currentPacketCost(createContainerParams) - discountCost(createContainerParams)).toFixed(2)} ₽
        </Text>}
        {errors.__all__ && <Text style={styles.errors}>{errors.__all__}</Text>}
        <Button title={`Создать контейнер | ${formatPrice(calculate(createPacket, createContainerParams))} ₽/мес`}
            inProgress={inProgress}
            onPress={() => createContainerAction(
                formId,
                createContainerParams.name,
                createContainerParams.containerTariff.ram,
                normalizeQuota(createContainerParams.storage),
                environmentMap[createContainerParams.containerType],
                createContainerParams.redis,
                createContainerParams.memcached,
                createContainerParams.createPacket,
                (data) => {
                    const { id } = data;
                    navigation.navigate('HostingContainer', { containerId: id, containerName: createContainerParams.name });
                    createContainerFlushParams();
                },
                (errors) => {
                    if (errors.name != null) {}
                })
            }
            additionalWrapperStyles={[{ width: '100%', marginBottom: 14, marginTop: 40, }]}
            additionalButtonStyles={[{ paddingTop: 15, paddingBottom: 15 }]}
            additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15 }]}/>
    </View>;
}

HostingCreateContainerStep2.propTypes = {
    createContainerAction: PropTypes.func,
    createContainerSetParams: PropTypes.func,
    createContainerFlushParams: PropTypes.func,
    createContainerParams: PropTypes.shape({
        containerTariff: PropTypes.shape({
            ram: PropTypes.number,
        }),
        containerName: PropTypes.string,
        createContainerInProgress: PropTypes.bool,
        create_packet: PropTypes.bool,
        environment: PropTypes.number,
        memcached: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
        ]),
        redis: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
        ]),
    }),
    errors: PropTypes.shape({}),
    inProgress: PropTypes.bool,
};

const mapStateToProps = state => ({
    inProgress: state.hostingCreateContainer.createContainerInProgress,
    errors: state.forms.errors[formId] || {},
    createContainerParams: state.hostingCreateContainer
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createContainerAction,
    createContainerSetParams,
    createContainerFlushParams,
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 20,
        paddingLeft: 12,
        paddingRight: 12,
    },
    tabs: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
        marginLeft: 12,
        marginRight: 12,
    },
    storageBlock: {
        marginBottom: 22,
        marginTop: 13,
    },
    applicationBlock: {
        marginBottom: 22,
    },
    application: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 8,
    },
    applicationTitle: {
        fontSize: 13,
        color: '#455562',
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 18,
    },
    applicationHint: {
        fontSize: 12,
        color: '#888888',
    },
    spring: {
        flex: 1,
    },
    applicationValue: {
        color: '#0F79D5',
        fontSize: 12,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 14,
        textAlign: 'right',
        marginRight: 10,
    },
    tab: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
    },
    leftTab: {
        marginRight: 32,
    },
    disabledTabText: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
    },
    disabledTabCircle: {
        color: '#455562',
        borderRadius: '50%',
        borderColor: '#455562',
        textAlign: 'center',
        borderWidth: 1,
        paddingTop: 8,
        paddingBottom: 9,
        paddingLeft: 8,
        paddingRight: 9,
        borderStyle: 'solid',
        marginRight: 12,
        lineHeight: 8,
        backgroundColor: '#FFFFFF',
    },
    tabText: {
        color: '#0F79D5',
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
    },
    tabCircle: {
        color: '#0F79D5',
        borderRadius: '50%',
        borderColor: '#0F79D5',
        fontWeight: '500',
        textAlign: 'center',
        borderWidth: 1,
        paddingTop: 4,
        paddingBottom: 5,
        paddingLeft: 8,
        paddingRight: 8,
        borderStyle: 'solid',
        marginRight: 12,
        backgroundColor: '#FFFFFF',
    },
    hint: {
        fontSize: 11,
        letterSpacing: 0,
        lineHeight: 13,
        color: '#455562',
        opacity: '0.5',
        textAlign: 'center',
    },
    switch: {
        width: '100%',
        paddingBottom: 10,
        color: '#455562',
    },
    switchPacket: {
        paddingBottom: 3,
    },
    tariffChecked: {
        borderColor: '#0F79D5',
    },
    tariffUnchecked: {
        borderColor: 'rgba(69,85,98,0.25)',
    },
    tariff: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        height: 40,
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 14,
        paddingRight: 14,
        marginBottom: 8,
    },
    tariffTitle: {
        fontSize: 14,
        fontWeight: '500',
        letterSpacing: 0,
    },
    tariffTitleChecked: {
        color: '#0F79D5',
    },
    tariffTitleUnchecked: {
        color: '#455562',
    },
    tariffResource: {
        color: '#455562',
        fontSize: 10,
        letterSpacing: 0,
        lineHeight: 11,
        textAlign: 'right',
    },
    tariffCircle: {
        height: 18,
        width: 18,
        borderWidth: 1,
        marginRight: 12,
        borderColor: 'rgba(161,170,177,0.5)',
        borderRadius: 9,
        backgroundColor: '#F5F5F5',
    },
    tariffCircleChecked: {
        height: 18,
        width: 18,
        borderWidth: 1,
        marginRight: 12,
        borderColor: '#0F79D5',
        borderRadius: 9,
        backgroundColor: '#FFFFFF',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tariffCircleSmall: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0F79D5'
    },
    packetHint: {
        color: '#455562',
        fontSize: 10,
        letterSpacing: 0,
        linHeight: 11,
    },
    errors: {
        marginTop: 5,
        marginBottom: 5,
        fontSize: 12,
        fontWeight: '300',
        color: '#c80502'
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(HostingCreateContainerStep2);