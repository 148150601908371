import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';

import { stateIconColor, stateTextColor } from './style';

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    icon: {
        height: 8,
        width: 8,
        borderRadius: '50%',
    },
    text: {
        marginLeft: 4,
        fontSize: 10,
        lineHeight: 10
    }
});

function MultiStateBlock(props) {
    const {
        title,
        state,
        additionalTitleStyles
    } = props;

    return <View style={styles.wrapper}>
        <View style={[styles.icon, stateIconColor[state]]} />
        {title && <Text style={[styles.text, stateTextColor[state], additionalTitleStyles]}>
            {title}
        </Text>}
    </View>;
}

MultiStateBlock.propTypes = {
    title: PropTypes.string,
    state: PropTypes.string,
    additionalTitleStyles: PropTypes.shape({})
};

export default MultiStateBlock;