import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { isEmpty } from '../utils/general';
import { screenHeaderStyles } from '../utils/styles';
import {
    fetchUserVM,
    vmFinanceInfo,
} from '../actions/cvds/actions';
import ProtectedIpsListScreen from '../components/CVDS/AntiDDoS/ProtectedIpsList';
import ResourceLoader from '../components/General/ResourceLoader/ResourceLoader';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const CvdsVmProtectedIpsStack = createStackNavigator();

function BandwidthResourceLoader() {
    return <ResourceLoader height={54} count={3}/>;
}

function CvdsVmProtectedIpsStackScreen(props) {
    const {
        route,
        navigation,
        currentVM,
        fetchUserVM,
        vmInProgress,
        vmFinanceInfo,
    } = props;
    const insets = useSafeAreaInsets();
    useFocusEffect(React.useCallback(() => {
        if (route.params.vmId && (isEmpty(currentVM)
            || currentVM.id !== Number.parseInt(route.params.vmId, 10)) && !vmInProgress) {
            const vmId = Number.parseInt(route.params.vmId, 10);
            fetchUserVM(vmId, () => {
                vmFinanceInfo(vmId);
            });
        }
    }, [route.params.vmId]));
    return <CvdsVmProtectedIpsStack.Navigator>
        <CvdsVmProtectedIpsStack.Screen name="CVDSVMProtectedIpsStack"
            component={isEmpty(currentVM) ? BandwidthResourceLoader : ProtectedIpsListScreen}
            options={{
                headerTitle: () => <ScreenHeaderTitle
                    title={`Защищенные IP-адреса ${currentVM.name}` || 'Загрузка...'} />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => {
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        } else {
                            navigation.navigate('CVDSVM', {
                                screen: 'VMNetwork',
                                vmId: currentVM.id
                            });
                        }
                    },
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }}/>
    </CvdsVmProtectedIpsStack.Navigator>;
}

CvdsVmProtectedIpsStackScreen.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
    vmInProgress: PropTypes.bool,
    currentVM: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        state: PropTypes.string
    }),
    fetchUserVM: PropTypes.func,
    vmFinanceInfo: PropTypes.func,
};

const mapStateToProps = (state) => ({
    currentVM: state.cvds.currentVM,
    vmInProgress: state.cvds.vmInProgress,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserVM, vmFinanceInfo
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CvdsVmProtectedIpsStackScreen);
