import {
    VM_CREATE_IN_PROGRESS,
    VM_CREATE_SUCCESSFULLY,
    CVDS_CREATE_VM_UPDATE_PARAMS,
    VM_CREATE_WITH_ERRORS,
    VM_CREATE_TO_INIT,
    CVDS_CHANGE_DISTRIBUTION,
    DISTRIBUTIONS_FETCHED_SUCCESSFULLY,
} from '../../actions/cvds/actionTypes';

import cvdsTariffData from '../../../data/cvds/tariffs.json';

export default () => {
    const defaultState = {
        currentTariff: cvdsTariffData.find(
            (tariff) => tariff.type === 'small' && tariff.frontend.is_popular
        ),
        currentDistribution: {
            id: null,
            name: null,
            fullname: null,
            type: null,
            groupType: null,
            groupName: null,
            checkAdditionalInfoCondition: () => false,
        },
        currentGpu: null,
        currentGpuCount: 0,
        backup: true,
        packet: false,
        createVMInProgress: false,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case CVDS_CREATE_VM_UPDATE_PARAMS: {
            return {
                ...state,
                ...action.data,
            };
        }
        case DISTRIBUTIONS_FETCHED_SUCCESSFULLY: {
            return state;
        }
        case VM_CREATE_SUCCESSFULLY: {
            return {
                ...state,
                createVMInProgress: false,
            };
        }
        case VM_CREATE_IN_PROGRESS: {
            return {
                ...state,
                createVMInProgress: true,
            };
        }
        case VM_CREATE_WITH_ERRORS: {
            return {
                ...state,
                createVMInProgress: false,
            };
        }
        case CVDS_CHANGE_DISTRIBUTION: {
            const { requirements } = action.data;
            const { currentTariff } = state;
            if (requirements != null) {
                const { cpu_count, memory_mb } = requirements;
                if (
                    currentTariff.features.cpu < cpu_count
            || currentTariff.features.memory < memory_mb
                ) {
                    return {
                        ...state,
                        currentTariff: cvdsTariffData.find(
                            (t) => t.features.cpu >= cpu_count && t.features.memory >= memory_mb
                        ),
                    };
                }
            }
            return state;
        }
        case VM_CREATE_TO_INIT: {
            return defaultState;
        }
        default:
            return state;
        }
    };
};
