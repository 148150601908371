import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, Image, StyleSheet } from 'react-native';

import {
    fetchBarChartRAM,
} from '../../../actions/cvds/actions';
import { ramIcon } from '../../../icons';
import { formatMemory } from '../../../utils/general';
import cvdsTariffData from '../../../../data/cvds/tariffs.json';
import StatisticsTenDays from '../../General/Statistics/StatisticsTenDays';


function getMemoryLimit(tariffId) {
    const tariffData = cvdsTariffData.find(tariff => tariff.id === tariffId);
    return tariffData.features.memory;
}

function normalizeDatapointsRegardingTariff(datapoints) {
    if (datapoints) {
        return datapoints.map(datapoint => ({
            value: datapoint.value,
            date: datapoint.date
        }));
    }
    return [];
}

function StatisticsTenDaysRAM(props) {
    const {
        vm,
        isError,
        isLoading,
        cumulative,
        datapoints,
        fetchBarChartRAM
    } = props;

    React.useEffect(() => {
        if (datapoints == null) {
            fetchBarChartRAM(vm.id);
        }
    }, []);
    return <View style={styles.wrapper}>
        <Image source={ramIcon} style={styles.icon}/>
        <View style={styles.textBlock}>
            <Text style={styles.title}>Память</Text>
            <Text style={styles.subTitle}>
                {formatMemory(getMemoryLimit(vm.tariff))}
            </Text>
        </View>
        <StatisticsTenDays
            isLoading={datapoints == null && isLoading}
            isError={isError}
            generalValue={cumulative}
            days={normalizeDatapointsRegardingTariff(datapoints)}
            formatGeneralValue={value => `~${value && value.toFixed(0)}%`}/>
    </View>;
}

StatisticsTenDaysRAM.propTypes = {
    vm: PropTypes.shape({}),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    cumulative: PropTypes.number,
    fetchBarChartRAM: PropTypes.func.isRequired,
    datapoints: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
    }))
};

const mapStateToProps = state => ({
    ...state.cvds.currentVM.barChartRAM,
    vm: state.cvds.currentVM,
});
const mapDispatchToProps = dispatch => bindActionCreators({
    fetchBarChartRAM
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 16,
        paddingRight: 19,
        paddingBottom: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 1,
        backgroundColor: '#ffffff'
    },
    textBlock: {
        flex: 1
    },
    title: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14,
        fontWeight: '600'
    },
    subTitle: {
        color: '#455562',
        fontSize: 10,
        lineHeight: 14
    },
    icon: {
        height: 24,
        width: 24,
        marginRight: 12
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsTenDaysRAM);
