import React from 'react';
import PropTypes from 'prop-types';
import {
    View, StyleSheet, Text, Image, TouchableOpacity
} from 'react-native';

import { getEnginesList } from '../utils';
import { dbmsList } from '../../../constants/hosting';

function getEnginesBlock(engine, containerId, capabilities, containers, changeEngine) {
    const currentContainer = containers.entities.find((c) => c.id === containerId);
    const engines = getEnginesList(currentContainer.environment);
    const filteredEngines = engines.filter((item) => capabilities.engines.includes(item.type));

    return filteredEngines.map((item, i) => {
        const currentEngine = engine === item.type;
        const modifier = currentEngine
            ? [styles.currentItem, styles.item] : [styles.item, styles.notCurrentItem];
        const { versions } = item;
        return <TouchableOpacity key={`item-${i}`} style={modifier}
            onPress={() => changeEngine(item.type)}>
            <Image source={item.icon} style={styles.icon}/>
            <View>
                <Text style={currentEngine
                    ? [styles.itemText, styles.blueText]
                    : [styles.itemText, styles.grayText]}>
                    {item.name}
                </Text>
                <Text style={styles.hint}>{versions}</Text>
            </View>
        </TouchableOpacity>;
    });
}

function getDbmsBlock(dbms, db, changeDB, capabilities) {
    const filteredDbms = dbms.filter((item) => capabilities.databases.includes(item.type));
    return filteredDbms.map((item, i) => {
        const currentDB = db === item.type;
        const modifier = currentDB
            ? [styles.currentItem, styles.item] : [styles.item, styles.notCurrentItem];
        return <TouchableOpacity key={`item-${i}`}
            style={modifier}
            onPress={() => changeDB(item.type)}>
            <Image source={item.icon} style={styles.icon}/>
            <Text style={currentDB
                ? [styles.itemText, styles.blueText]
                : [styles.itemText, styles.grayText]}>
                {item.name}
            </Text>
        </TouchableOpacity>;
    });
}

function ConfigurationBlock(props) {
    const {
        db,
        engine,
        changeDB,
        containers,
        containerId,
        capabilities,
        changeEngine
    } = props;
    return <View>
        <Text style={styles.title}>Язык программирования</Text>
        {getEnginesBlock(engine, containerId, capabilities, containers, changeEngine)}
        <Text style={styles.title}>База данных</Text>
        {getDbmsBlock(dbmsList, db, changeDB, capabilities)}
    </View>;
}

ConfigurationBlock.propTypes = {
    db: PropTypes.string,
    engine: PropTypes.string,
    changeDB: PropTypes.func,
    containers: PropTypes.shape({}),
    containerId: PropTypes.number,
    capabilities: PropTypes.shape({}),
    changeEngine: PropTypes.func
};

const styles = StyleSheet.create({
    error: {
        color: '#d06565',
        marginBottom: 10,
    },
    blueText: {
        color: '#0f79d5',
    },
    grayText: {
        color: '#455562',
    },
    icon: {
        width: 32,
        height: 32,
        marginRight: 5,
    },
    currentItem: {
        borderColor: '#0F79D5',
        backgroundColor: '#F3F8FD',
        borderLeftWidth: 6,
        paddingLeft: 8,
    },
    notCurrentItem: {
        paddingLeft: 13,
        borderColor: 'rgba(69, 85, 98, 0.248498)',
    },
    item: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        paddingTop: 9,
        paddingBottom: 7,
        marginBottom: 8,
        borderRadius: 4,
    },
    itemText: {
        fontWeight: '500',
        fontSize: 12,
        lineHeight: 14,
    },
    title: {
        fontWeight: '500',
        fontSize: 11,
        lineHeight: 13,
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        color: '#586A7B',
        opacity: '0.75',
        paddingBottom: 12,
        marginTop: 20,
    },
    hint: {
        fontSize: 10,
        lineHeight: 18,
        color: '#4E5F6E',
    }
});

export default ConfigurationBlock;
