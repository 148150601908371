const BaseResource = require('../resource');

class CVDSBackupsResource extends BaseResource {
    async backupList() {
        return this.makeRequest('get', null);
    }

    async vmBackupList() {
        return this.makeRequest('get', 'vms/');
    }
}

CVDSBackupsResource.resource = 'backups';
CVDSBackupsResource.service = 'cloud';

module.exports = CVDSBackupsResource;
