import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import {
    View,
    StyleSheet,
    Text,
    ScrollView,
    TouchableOpacity
} from 'react-native';

// const utc = require('dayjs/plugin/utc');
// const timezone = require('dayjs/plugin/timezone');

// dayjs.extend(utc);
// dayjs.extend(timezone);

function BackupDateSelect(props) {
    const {
        onChange,
        points,
        currentDate,
        dateFieldName,
        isPointsTimeInUTC = true,
    } = props;
    const restorePoints = points.map((point) => {
        if (isPointsTimeInUTC) {
            return {
                value: point,
                label: dayjs
                    .utc(point[dateFieldName])
                    .tz('Asia/Yekaterinburg')
                    .format('DD MMM, HH:mm')
                    .replace('.,', ','),
            };
        }
        return {
            value: point,
            label: dayjs(point[dateFieldName], 'YYYY-MM-DD HH:mm')
                .format('DD MMM, HH:mm')
                .replace('.,', ','),
        };
    });

    return <ScrollView horizontal={true} automaticallyAdjustContentInsets={false}
        showsHorizontalScrollIndicator={false}
        style={styles.wrapper}>
        <View style={styles.innerBlock}>
            {restorePoints.map((point, index) => <TouchableOpacity
                style={
                    currentDate === point.value[dateFieldName]
                        ? styles.activePointBlock : styles.pointBlock
                }
                key={`restore-points-${index}`}
                onPress={() => onChange(point.value)}
            >
                <Text style={
                    currentDate === point.value[dateFieldName]
                        ? styles.activePointTitle : styles.pointTitle
                }>
                    {point.label}
                </Text>
            </TouchableOpacity>)}
        </View>
    </ScrollView>;
}

const styles = StyleSheet.create({
    wrapper: {
        height: 41
    },
    innerBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    pointBlock: {
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: 'rgba(69, 85, 98, 0.25)',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 8,
        paddingRight: 8,
        borderRadius: 4,
        marginRight: 10,
    },
    activePointBlock: {
        backgroundColor: '#f5fafd',
        borderWidth: 1,
        borderColor: '#0f79d5',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 8,
        paddingRight: 8,
        borderRadius: 4,
        marginRight: 10,
    },
    pointTitle: {
        color: '#455562',
        fontSize: 12,
    },
    activePointTitle: {
        color: '#0f79d5',
        fontSize: 12,
    },
});

BackupDateSelect.propTypes = {
    isPointsTimeInUTC: PropTypes.bool,
    slidesPerView: PropTypes.number,
    onChange: PropTypes.func,
    currentDate: PropTypes.string,
    dateFieldName: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.shape({})),
};

export default BackupDateSelect;
