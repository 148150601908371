import React, { useContext } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Image } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';

import { ModalContext } from '../contexts/ModalContext';

import { isEmpty } from '../utils/general';
import { screenHeaderStyles } from '../utils/styles';
import Modal from '../components/General/Modal/Modal';
import {
    fetchUserVM,
    vmFinanceInfo,
    getVolumesUsedSize
} from '../actions/cvds/actions';
import CVDSVMScreen from '../components/CVDS/VMPage';
import Action from '../components/General/Actions/Action';
import Actions from '../components/General/Actions/Actions';
import {
    menuBackIcon,
    tweakIcon,
    reloadIcon,
    turnOffIcon,
    editBlueIcon,
    editIcon,
    reloadDisabledIcon,
    turnOffDisabledIcon,
    trashIcon,
    trashDisabledIcon,
    returnIcon,
    returnDisabledIcon,
    transferIcon,
    transferDisabledIcon
} from '../icons';
import Separator from '../components/General/Separator/Separator';
import {
    canDeleteVM,
    isActiveVM,
    isActiveOrStoppedVM,
    isErrorStateVM,
    isTransferStateVM,
    getButtonTooltip
} from '../components/CVDS/utils';
import RenameModal from '../components/CVDS/Modals/RenameModal';
import ReloadModal from '../components/CVDS/Modals/ReloadModal';
import DeleteVMModal from '../components/CVDS/Modals/DeleteVMModal';
import ChangeStateModal from '../components/CVDS/Modals/ChangeStateModal';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const CVDSVMStack = createStackNavigator();

const styles = StyleSheet.create({
    actions: {
        backgroundColor: 'transparent',
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginRight: 9
    }
});

function canDelete(packet, vm) {
    const hasAwaitingPacket = packet != null && packet.is_awaiting;
    return canDeleteVM(vm.state) && (packet == null || hasAwaitingPacket);
}

const formatDate = (date) => dayjs(date, 'YYYY-MM-DD HH:mm').format('DD MMMM YYYY');

function CVDSVMStackScreen(props) {
    const {
        route,
        packet,
        navigation,
        currentVM,
        fetchUserVM,
        vmInProgress,
        vmFinanceInfo,
        getVolumesUsedSize
    } = props;
    const insets = useSafeAreaInsets();

    useFocusEffect(React.useCallback(() => {
        if (route.params.vmId && (isEmpty(currentVM)
            || currentVM.id !== Number.parseInt(route.params.vmId, 10)) && !vmInProgress) {
            const vmId = Number.parseInt(route.params.vmId, 10);
            fetchUserVM(vmId, () => {
                vmFinanceInfo(vmId);
                getVolumesUsedSize(vmId);
            });
        }
    }, [route.params.vmId]));

    const [isRenameModalShowed, setRenameModalShowed] = React.useState(false);
    const [isReloadModalShowed, setReloadModalShowed] = React.useState(false);
    const [isChangeStateModalShowed, setChangeStateModalShowed] = React.useState(false);
    const [isDeleteStateModalShowed, setDeleteModalShowed] = React.useState(false);
    const { closeModal } = useContext(ModalContext);
    return <CVDSVMStack.Navigator>
        <CVDSVMStack.Screen name="CVDSVMStack" component={CVDSVMScreen}
            options={{
                headerTitle: () => <ScreenHeaderTitle title={currentVM.name || 'Загрузка...'} />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => {
                        navigation.navigate('CVDS');
                    },
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerRight: () => <View>
                    {!isEmpty(currentVM) && <Actions modalId='cvds-vm-actions' icon={tweakIcon}
                        iconWidth={16} iconHeight={16}
                        additionalButtonStyles={[styles.actions]}>
                        <Action icon={isErrorStateVM(currentVM.state) ? editIcon : editBlueIcon}
                            title='Переименовать'
                            disabledText={getButtonTooltip(currentVM.state)}
                            enabled={!isErrorStateVM(currentVM.state)}
                            onPress={() => {
                                closeModal();
                                setRenameModalShowed(true);
                            }} />
                        <Separator />
                        <Action icon={isActiveVM(currentVM.state) ? reloadIcon : reloadDisabledIcon}
                            title='Перезагрузить'
                            disabledText={getButtonTooltip(currentVM.state)}
                            enabled={isActiveVM(currentVM.state)}
                            onPress={() => {
                                closeModal();
                                setReloadModalShowed(true);
                            }}/>
                        <Separator />
                        <Action icon={isActiveOrStoppedVM(currentVM.state) ? turnOffIcon
                            : turnOffDisabledIcon} title={isActiveVM(currentVM.state) ? 'Отключить'
                            : 'Включить'}
                        disabledText={getButtonTooltip(currentVM.state)}
                        enabled={isActiveOrStoppedVM(currentVM.state)}
                        onPress={() => {
                            closeModal();
                            setChangeStateModalShowed(true);
                        }}/>
                        <Separator />
                        <Action icon={
                            isActiveOrStoppedVM(currentVM.state) && currentVM.disk.has_backups
                                ? returnIcon : returnDisabledIcon}
                        title='Восстановить'
                        disabledText={currentVM.disk.has_backups
                            ? getButtonTooltip(currentVM.state)
                            : getButtonTooltip('HasNotBackups')}
                        enabled={isActiveOrStoppedVM(currentVM.state) && currentVM.disk.has_backups}
                        onPress={() => {
                            closeModal();
                            navigation.navigate('CVDSBackupDisks', { vmId: currentVM.id });
                        }} />
                        <Separator />
                        <Action icon={
                            isActiveVM(currentVM.state) || isTransferStateVM(currentVM.state)
                                ? transferIcon : transferDisabledIcon}
                        title='Передать ресурс'
                        disabledText={getButtonTooltip(currentVM.state)}
                        enabled={isActiveVM(currentVM.state) || isTransferStateVM(currentVM.state)}
                        iconStyles={[{ width: 18, height: 17 }]}
                        onPress={() => {
                            closeModal();
                            navigation.navigate('Transfer', {
                                id: `${currentVM.id}`,
                                service: 'vms'
                            });
                        }} />
                        <Separator />
                        <Action icon={canDelete(packet, currentVM) ? trashIcon : trashDisabledIcon}
                            title='Удалить ВМ'
                            enabled={canDelete(packet, currentVM)}
                            disabledText={packet == null || packet.is_awaiting
                                ? getButtonTooltip(currentVM.state)
                                : `Для виртуальной машины подключен годовой пакет до
                                ${formatDate(packet.paid_till)}.
                                Удаление виртуальной машины невозможно.`}
                            textStyles={[{ color: canDelete(packet, currentVM) ? '#D51010' : '#888888' }]}
                            onPress={() => {
                                closeModal();
                                setDeleteModalShowed(true);
                            }}/>
                    </Actions>}
                    <Modal isModalShowed={isRenameModalShowed}
                        component={<RenameModal
                            onClose={() => setRenameModalShowed(false)}/>}
                        onClose={() => setRenameModalShowed(false)} />
                    <Modal isModalShowed={isReloadModalShowed}
                        component={<ReloadModal
                            onClose={() => setReloadModalShowed(false)}/>}
                        onClose={() => setReloadModalShowed(false)} />
                    <Modal isModalShowed={isChangeStateModalShowed}
                        component={<ChangeStateModal
                            onClose={() => setChangeStateModalShowed(false)}/>}
                        onClose={() => setChangeStateModalShowed(false)} />
                    <Modal isModalShowed={isDeleteStateModalShowed}
                        component={<DeleteVMModal
                            onClose={() => setDeleteModalShowed(false)}/>}
                        onClose={() => setDeleteModalShowed(false)} />
                </View>,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }}/>
    </CVDSVMStack.Navigator>;
}

CVDSVMStackScreen.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
    packet: PropTypes.shape({}),
    vmInProgress: PropTypes.bool,
    currentVM: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        state: PropTypes.string
    }),
    fetchUserVM: PropTypes.func,
    vmFinanceInfo: PropTypes.func,
    getVolumesUsedSize: PropTypes.func,
};

const mapStateToProps = (state) => ({
    currentVM: state.cvds.currentVM,
    vmInProgress: state.cvds.vmInProgress,
    packet: state.cvdsFinanceInfo.calculation == null ? null
        : state.cvdsFinanceInfo.calculation.packet,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserVM,
    vmFinanceInfo,
    getVolumesUsedSize,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CVDSVMStackScreen);
