import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
} from 'react-native';

import Spring from '../General/Spring/Spring';
import { initEvents } from '../../actions/monitoring/actions';
import ResourceLoader from '../General/ResourceLoader/ResourceLoader';

const monitoringInfo = {
    ok: {
        label: 'Все в порядке',
        color: '#45ad85'
    },
    warning: {
        label: 'Требуется внимание',
        color: '#e28a58'
    },
    critical: {
        label: 'Критическое состояние',
        color: '#e07474'
    },
};

function MonitoringMainScreen() {
    const navigation = useNavigation();
    const { events, eventsInProgress } = useSelector(
        (state) => state.monitoring,
    );
    let state = 'ok';
    let eventsCount = 0;
    if (events && events.find((e) => e.severity === 'Critical')) {
        state = 'critical';
        eventsCount = events.filter((e) => e.severity === 'Critical').length;
    } else if (events && events.find((e) => e.severity === 'Warning')) {
        state = 'warning';
        eventsCount = events.filter((e) => e.severity === 'Warning').length;
    }
    const dispatch = useDispatch();

    return <React.Fragment>
        {eventsInProgress && <View style={styles.loaderBlockWrapper}>
            <ResourceLoader
                height={110}
                count={1}
                additionalStyles={[styles.loaderBlock]}
            />
        </View>}
        {!eventsInProgress && <TouchableOpacity
            onPress={() => {
                dispatch(initEvents());
                navigation.navigate('Monitoring');
            }}
            style={[styles.wrapper, { backgroundColor: monitoringInfo[state].color }]}
        >
            <View style={styles.titleWrapper}>
                <Text style={styles.title}>Мониторинг</Text>
                {eventsCount !== 0 && <Text style={styles.count}>{eventsCount}</Text>}
            </View>
            <Spring />
            <View
                style={styles.buttonsWrapper}>
                <Text style={styles.text}>{monitoringInfo[state].label}</Text>
            </View>
        </TouchableOpacity>}
    </React.Fragment>;
}

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 10,
        paddingLeft: 12,
        paddingRight: 11,
        paddingBottom: 13,
        flex: 1,
        flexDirection: 'column',
        minHeight: 110,
        borderRadius: 8,
    },
    loaderBlockWrapper: {
        flex: 1,
        minWidth: 158,
    },
    loaderBlock: {
        minWidth: 158,
        borderRadius: 8,
        paddingLeft: 12,
        paddingRight: 11,
        borderBottomWidth: 0,
    },
    titleWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        fontWeight: '500',
        color: '#ffffff',
        fontSize: 8,
        lineHeight: 20,
        letterSpacing: 1,
        textTransform: 'uppercase'
    },
    count: {
        color: '#ffffff',
        textAlign: 'center',
        fontSize: 10,
        fontWeight: '500',
        lineHeight: 10,
        borderRadius: 16,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 3,
        paddingRight: 3,
        minWidth: 20,
        backgroundColor: 'rgba(255, 255, 255, 0.20)',
    },
    text: {
        fontSize: 12,
        lineHeight: 16,
        color: '#ffffff',
        fontWeight: '500',
        maxWidth: 80,
    },
});

export default MonitoringMainScreen;
