import * as dayjs from 'dayjs';

import {
    EXPECTED_CHARGES_IN_PROGRESS,
    EXPECTED_CHARGES_SUCCESSFULLY,
    EXPECTED_CHARGES_WITH_ERRORS,
    SET_FORECAST_DATE
} from '../../actions/finances/actionTypes';

const unpackDates = charges => Object.keys(charges)
    .map(d => dayjs(d, 'YYYY-MM-DD')).sort((a, b) => {
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
    }).map(m => m.format('YYYY-MM-DD'));

export default () => {
    const defaultState = {
        charges: null,
        dates: null,
        inProgress: false,
        isError: false,
        forecastDate: null,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case EXPECTED_CHARGES_IN_PROGRESS: {
            return {
                ...state,
                isError: false,
                inProgress: true,
            };
        }
        case EXPECTED_CHARGES_SUCCESSFULLY: {
            const { data } = action;
            const dates = unpackDates(data);
            return {
                ...state,
                dates,
                charges: data,
                current: dates[0],
                isError: false,
                inProgress: false
            };
        }
        case EXPECTED_CHARGES_WITH_ERRORS: {
            return {
                ...state,
                isError: true,
                inProgress: false
            };
        }
        case SET_FORECAST_DATE: {
            return {
                ...state,
                current: action.data.date
            };
        }
        default:
            return state;
        }
    };
};
