import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigation } from '@react-navigation/native';
import {
    View, Text,
    StyleSheet, TouchableOpacity
} from 'react-native';

import Input from '../../General/Form/Input';
import { flushErrors } from '../../../actions/forms/actions';
import { addCustomNotification } from '../../../actions/notifications/actions';
import { sendInvoiceToEmail } from '../../../actions/finances/actions';
import Button from '../../General/Button/Button';

const formId = 'SendInvoiceToEmailForm';

function SendInvoiceToEmailScreen(props) {
    const {
        route,
        errors,
        emailsAdm,
        inProgress,
        flushErrors,
        sendInvoiceToEmail,
        addCustomNotification,
    } = props;
    const navigation = useNavigation();
    const [currentEmail, setCurrentEmail] = React.useState('');
    const [currentMethod, setCurrentMethod] = React.useState('default');
    return <View style={styles.wrapper}>
        <Text style={styles.title}>куда направить счет?</Text>
        <View>
            <View style={styles.buttonBlock}>
                <TouchableOpacity style={[styles.button, { alignItems: 'center' }]}
                    onPress={() => {
                        setCurrentMethod('default');
                        flushErrors();
                        setCurrentEmail('');
                    }}>
                    <View style={currentMethod === 'default' ? styles.activeOuterCircle
                        : styles.outerCircle}>
                        {currentMethod === 'default' && <View style={styles.innerCircle}/>}
                    </View>
                    <View style={styles.buttonTextBlock}>
                        <Text style={[styles.buttonText, {
                            color: currentMethod === 'default' ? '#0f79d5' : '#455562'
                        }]}>На адреса, привязанные к аккаунту</Text>
                    </View>
                </TouchableOpacity>
            </View>
            <View style={styles.buttonBlock}>
                <TouchableOpacity style={[styles.button, { marginTop: 15 }]}
                    onPress={() => setCurrentMethod('custom')}>
                    <View style={currentMethod === 'custom' ? styles.activeOuterCircle
                        : styles.outerCircle}>
                        {currentMethod === 'custom' && <View style={styles.innerCircle}/>}
                    </View>
                    <View style={[styles.buttonTextBlock, { marginTop: 2 }]}>
                        <Text style={[styles.buttonText, {
                            color: currentMethod === 'custom' ? '#0f79d5' : '#455562',
                            marginBottom: 10
                        }]}>На указанный Email</Text>
                        <Input
                            errors={errors.email}
                            additionalWrapperStyles={[{ marginBottom: 26 }]}
                            textInputAttrs={{
                                numberOfLines: 1,
                                value: currentEmail,
                                placeholder: 'mail@example.com',
                                onChange: (e) => {
                                    flushErrors();
                                    setCurrentEmail(e.target.value);
                                }
                            }}
                        />
                    </View>
                </TouchableOpacity>
            </View>
        </View>
        <View style={styles.footer}>
            <Button title='Отправить счет'
                inProgress={inProgress}
                onPress={() => sendInvoiceToEmail(formId, {
                    id: route.params.invoiceId,
                    email: currentMethod === 'default' ? emailsAdm[0] : currentEmail
                }, () => {
                    addCustomNotification(`Счет отправлен на ${
                        currentMethod === 'default' ? emailsAdm[0] : currentEmail
                    }`);
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                    } else {
                        navigation.navigate('MainStack', {
                            screen: 'Finance',
                            params: {
                                screen: 'FinanceTabs', params: { screen: 'FinanceDocuments' }
                            }
                        });
                    }
                })}/>
        </View>
    </View>;
}

SendInvoiceToEmailScreen.propTypes = {
    route: PropTypes.object,
    errors: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    inProgress: PropTypes.bool,
    sendInvoiceToEmail: PropTypes.func,
    addCustomNotification: PropTypes.func,
    emailsAdm: PropTypes.arrayOf(PropTypes.string)
};

const mapStateToProps = (state) => ({
    emailsAdm: state.user.user.emailsAdm,
    inProgress: state.invoices.sendInvoiceInProgress,
    errors: state.forms.errors[formId] || {}
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors, sendInvoiceToEmail, addCustomNotification
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        flex: 1
    },
    title: {
        opacity: 0.75,
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.38,
        lineHeight: 13,
        marginTop: 24,
        marginBottom: 15,
        marginLeft: 20,
        textTransform: 'uppercase'
    },
    buttonBlock: {
        minHeight: 49,
        backgroundColor: '#ffffff'
    },
    button: {
        flex: 1,
        paddingLeft: 20,
        paddingRight: 17,
        borderBottomColor: '#e4e4e4',
        borderBottomWidth: 1,
        flexDirection: 'row'
    },
    buttonText: {
        flex: 1,
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 15
    },
    buttonTextBlock: {
        paddingLeft: 12,
        width: 265
    },
    outerCircle: {
        height: 18,
        width: 18,
        borderTopWidth: 1,
        borderTopColor: 'rgba(161, 170, 177, 0.5)',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(161, 170, 177, 0.5)',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(161, 170, 177, 0.5)',
        borderRightWidth: 1,
        borderRightColor: 'rgba(161, 170, 177, 0.5)',
        borderRadius: 9,
        backgroundColor: '#f5f5f5'
    },
    activeOuterCircle: {
        height: 18,
        width: 18,
        borderTopWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderLeftWidth: 1,
        borderLeftColor: '#0f79d5',
        borderRightWidth: 1,
        borderRightColor: '#0f79d5',
        borderRadius: 9,
        backgroundColor: '#ffffff',
        paddingTop: 4,
        paddingLeft: 4
    },
    innerCircle: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0f79d5'
    },
    footer: {
        flex: 1,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 32,
        justifyContent: 'flex-end'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SendInvoiceToEmailScreen);
