import React from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, View, ScrollView, Text} from 'react-native';

const heightFactors = [1, 0.75, 0.5, 0.7];

function fakeItems(n, startIndex) {
    const itemsArr = [];
    for (let i = 0; i < n; i += 1) {
        itemsArr[i] = {
            value: -1,
            index: i + startIndex,
            label: '',
            heightModifier: heightFactors[n - i],
        };
    }
    return itemsArr;
}

function getItemTemporaryIndex(event, itemHeight) {
    return Math.round(event.nativeEvent.contentOffset.y / itemHeight);
}

function getExtendedItems(items, transparentItemRows) {
    if (items.length === 1) {
        return [
            ...fakeItems(2, -2),
            ...items,
            ...fakeItems(2, 1),
        ];
    }
    return [
        ...fakeItems(transparentItemRows, -transparentItemRows),
        ...items,
        ...fakeItems(transparentItemRows, items.length),
    ];
}

function renderPickerListItem(
    item, index, itemIndex,
    itemHeight
) {
    let itemStyleType = '';
    let heightModifier = heightFactors[0];
    if (item.index === itemIndex) {
        itemStyleType = 'currentValue';
    } else if (
        item.index === (itemIndex + 2)
        || item.index === (itemIndex - 2)
    ) {
        heightModifier = heightFactors[2];
        itemStyleType = 'prePreviousValue';
    } else if (
        item.index === (itemIndex + 1)
        || item.index === (itemIndex - 1)
    ) {
        heightModifier = heightFactors[1];
        itemStyleType = 'previousValue';
    } else {
        itemStyleType = 'hiddenValue';
    }
    const currentHeightModifier = heightModifier || item.heightModifier;
    return <View
        key={index}
        style={[styles.listItem, { height: itemHeight * currentHeightModifier }]}
    >
        <Text style={[
            styles.listItemText, styles[itemStyleType],
            { lineHeight: itemHeight * heightModifier }
        ]}>
            {item.label}
        </Text>
    </View>;
}

function DynamicallySelectedPicker(props) {
    const {
        items = [{index: 0, value: 0, label: 'No items'}],
        width = 266,
        height = 161,
        firstItemHeight = 46,
        onScroll = () => {},
        transparentItemRows = 2,
        initialSelectedIndex = 0,
    } = props;

    const [scrollViewRef, setScrollViewRef] = React.useState(null);
    const [itemIndex, setItemIndex] = React.useState(initialSelectedIndex);
    const [needScrollTo, setNeedScrollTo] = React.useState(itemIndex === initialSelectedIndex);
    const snapToOffsets = [];
    heightFactors.map((factor) => snapToOffsets.push(factor * firstItemHeight));
    const extendedItems = getExtendedItems(items, transparentItemRows);
    React.useEffect(() => {
        if (
            scrollViewRef != null && initialSelectedIndex !== 0
            && needScrollTo
        ) {
            setNeedScrollTo(false);
            let scrollY = firstItemHeight * (initialSelectedIndex - 1);
            for (let i = 1; i <= transparentItemRows; i += 1) {
                scrollY += heightFactors[i] * firstItemHeight;
            }
            scrollViewRef.scrollTo({
                x: 0,
                y: scrollY,
                animated: false,
            });
        }
    }, [scrollViewRef]);
    return <View style={{height: height, width: width}}>
        <ScrollView
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            ref={(ref) => setScrollViewRef(ref)}
            onScroll={(event) => {
                const tempIndex = getItemTemporaryIndex(event, firstItemHeight);
                if (tempIndex !== initialSelectedIndex && items[tempIndex] != null) {
                    setItemIndex(tempIndex);
                    onScroll({ index: tempIndex, item: items[tempIndex] });
                } else {
                    setItemIndex(initialSelectedIndex);
                    onScroll({
                        index: initialSelectedIndex,
                        item: items[initialSelectedIndex],
                    });
                }
            }}
            scrollEventThrottle
            snapToOffsets={snapToOffsets}>
            {extendedItems.map((item, index) => renderPickerListItem(
                item, index, itemIndex, firstItemHeight,
            ))}
        </ScrollView>
    </View>;
}

DynamicallySelectedPicker.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string
        }),
    ),
    needScrollToEnd: PropTypes.bool,
    onScroll: PropTypes.func,
    initialSelectedIndex: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    firstItemHeight: PropTypes.number,
    transparentItemRows: PropTypes.number,
};

const styles = StyleSheet.create({
    listItem: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    listItemText: {
        color: '#455562',
        textAlign: 'center',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    currentValue: {
        fontSize: 14,
        borderTopWidth: 1,
        borderTopColor: '#E4E4E4',
        borderBottomWidth: 1,
        borderBottomColor: '#E4E4E4'
    },
    previousValue: {
        opacity: 0.8,
        fontSize: 13
    },
    prePreviousValue: {
        opacity: 0.5,
        fontSize: 11
    },
    hiddenValue: {
        opacity: 0,
    }
});

export default DynamicallySelectedPicker;
