import React, { useEffect } from 'react';
import {
    Image,
    Text,
    View,
    StyleSheet,
    TouchableOpacity,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { netangelsLogoIcon, authFailIcon } from '../../icons';

const styles = StyleSheet.create({
    logoWrapper: {
        flex: 0.1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    authScreen: {
        marginTop: '11%',
        padding: 22,
        flex: 1,
        flexDirection: 'column'
    },
    headerText: {
        fontSize: 22,
        marginBottom: 28,
        textAlign: 'center',
        lineHeight: 26,
        color: '#455562'
    },
    form: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 4,
    },
    descriptionText: {
        fontSize: 12,
        marginTop: 24,
        textAlign: 'center',
        lineHeight: 16,
        color: '#455562'
    },
    linkButton: {
        fontSize: 11,
        textAlign: 'center',
        color: '#0f79d5',
        marginBottom: 24,
        fontWeight: '500',
        letterSpacing: '1.375',
        textTransform: 'uppercase'
    },
});

function OtpFail() {
    const navigation = useNavigation();
    const pageProps = {
        title: 'Вход, проверка двухфакторной авторизации Netangels. Панель управления хостингом NetAngels',
    };
    useEffect(() => {
        document.title = pageProps.title;
    }, []);

    return <View style={styles.authScreen}>
        <View style={styles.logoWrapper}>
            <Image source={netangelsLogoIcon} style={{ width: 162, height: 30 }} />
        </View>
        <View style={styles.form}>
            <Text style={styles.headerText}>Пинкод введен неверно</Text>
            <Image source={authFailIcon} style={{ width: 276, height: 176 }} />
            <Text style={styles.descriptionText}>
                Пожалуйста, попробуйте повторить попытку авторизации
            </Text>
        </View>
        <TouchableOpacity onPress={() => navigation.navigate('Auth')}>
            <Text style={styles.linkButton}>Попробовать ещё раз</Text>
        </TouchableOpacity>
    </View>;
}

export default OtpFail;
