import {
    ACCOUNT_INFO_IN_PROGRESS,
    ACCOUNT_INFO_SUCCESSFULLY,
    ACCOUNT_INFO_WITH_ERRORS,
    CHANGE_NOTIFICATION_PHONE,
    CHANGE_NOTIFICATION_PHONE_WITH_ERRORS,
    CHANGE_REQUISITES_IN_PROGRESS,
    CHANGE_REQUISITES_SUCCESSFULLY,
    CHANGE_REQUISITES_WITH_ERRORS,
    CHANGE_PASSWORD_SUCCESSFULLY,
    ADD_EMAIL_SUCCESSFULLY,
    ADD_EMAIL_IN_PROGRESS,
    ADD_EMAIL_WITH_ERRORS,
    DELETE_EMAIL_IN_PROGRESS,
    DELETE_EMAIL_SUCCESSFULLY,
    DELETE_EMAIL_WITH_ERRORS,
    ACCOUNT_VERIFY_PHONE,
    ACCOUNT_VERIFIED_PHONE,
    VERIFY_EMAIL,
    CHANGE_PASSWORD_IN_PROGRESS,
    CHANGE_PASSWORD_WITH_ERRORS,
    ACCOUNT_CHANGE_PHONE_IN_PROGRESS,
    ACCOUNT_CHANGE_PHONE_SUCCESSFULLY,
    ACCOUNT_CHANGE_PHONE_WITH_ERRORS,
    GET_OTP_KEY,
    ACCOUNT_ENABLE_OTP_IN_PROGRESS,
    ACCOUNT_ENABLE_OTP_SUCCESSFULLY,
    ACCOUNT_ENABLE_OTP_WITH_ERRORS,
    ACCOUNT_DISABLE_OTP_IN_PROGRESS,
    ACCOUNT_DISABLE_OTP_SUCCESSFULLY,
    ACCOUNT_DISABLE_OTP_WITH_ERRORS,
} from './actionTypes';
import { isFunction } from '../../utils/general';
import { formErrors, flushErrors } from '../forms/actions';
import AccountResource from '../../gateway/resources/account';

export const fetchAccount = (callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: ACCOUNT_INFO_IN_PROGRESS });
    const resource = new AccountResource(gateway);
    const response = await resource.getAccount();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: ACCOUNT_INFO_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: ACCOUNT_INFO_WITH_ERRORS });
    }
}

export const changeNotificationPhone = (phoneNotificationEnabled, callback) => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    const resource = new AccountResource(gateway);
    const response = await resource.changePhoneNotifications({ phoneNotificationEnabled });
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CHANGE_NOTIFICATION_PHONE,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: CHANGE_NOTIFICATION_PHONE_WITH_ERRORS });
    }
};

export const changeRequisites = (formId, { postAddr, rinv, bik }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    dispatch({ type: CHANGE_REQUISITES_IN_PROGRESS });
    const resource = new AccountResource(gateway);
    const response = await resource.changeRequisites({
        post_addr: postAddr,
        rinv,
        bik,
    });
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CHANGE_REQUISITES_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: CHANGE_REQUISITES_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const changePassword = (formId, { oldPassword, password, repeatedPassword }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    dispatch({ type: CHANGE_PASSWORD_IN_PROGRESS });
    const resource = new AccountResource(gateway);
    const response = await resource.changePassword(oldPassword, password, repeatedPassword);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CHANGE_PASSWORD_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: CHANGE_PASSWORD_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const addEmail = (formId, { emailType, email }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: ADD_EMAIL_IN_PROGRESS });
    const resource = new AccountResource(gateway);
    const response = await resource.addEmail(emailType, email);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: ADD_EMAIL_SUCCESSFULLY,
            data: {
                ...data,
                email_type: emailType
            }
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: ADD_EMAIL_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const deleteEmail = (formId, { emailType, email }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: DELETE_EMAIL_IN_PROGRESS });
    const resource = new AccountResource(gateway);
    const response = await resource.deleteEmail(emailType, email);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: DELETE_EMAIL_SUCCESSFULLY,
            data: {
                ...data,
                email_type: emailType
            }
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: DELETE_EMAIL_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const changeMobilePhone = (formId, { phone }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: ACCOUNT_CHANGE_PHONE_IN_PROGRESS });
    dispatch(flushErrors());
    const resource = new AccountResource(gateway);
    const response = await resource.changePhone({ phone });
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: ACCOUNT_CHANGE_PHONE_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: ACCOUNT_CHANGE_PHONE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const verifyPhone = (formId, { pin }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    const resource = new AccountResource(gateway);
    const response = await resource.verifyPhone(pin);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: ACCOUNT_VERIFY_PHONE,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch(formErrors(formId, data.errors));
    }
};

export const resetPhone = formId => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    const resource = new AccountResource(gateway);
    const response = await resource.resetPhone();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: ACCOUNT_CHANGE_PHONE_SUCCESSFULLY,
            data
        });
    } else {
        dispatch(formErrors(formId, data.errors));
    }
};

export const resendPinPhone = formId => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    const resource = new AccountResource(gateway);
    const response = await resource.resendPinPhone();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: ACCOUNT_CHANGE_PHONE_SUCCESSFULLY,
            data
        });
    } else {
        dispatch(formErrors(formId, data.errors));
    }
};

export const wsAccountAddUnverifiedEmail = data => async (dispatch) => {
    dispatch({
        type: ADD_EMAIL_SUCCESSFULLY,
        data
    });
};

export const wsAccountDeleteUnverifiedEmail = data => async (dispatch) => {
    dispatch({
        type: DELETE_EMAIL_SUCCESSFULLY,
        data
    });
};

export const wsAccountAddVerifiedEmail = data => async (dispatch) => {
    dispatch({
        type: VERIFY_EMAIL,
        data
    });
};

export const wsAccountChangeNotificationPhone = data => async (dispatch) => {
    dispatch({
        type: CHANGE_NOTIFICATION_PHONE,
        data
    });
};

export const wsAccountSendVerifyPhone = data => async (dispatch) => {
    dispatch({
        type: ACCOUNT_VERIFY_PHONE,
        data
    });
};

export const wsAccountVerifyPhone = data => async (dispatch) => {
    dispatch({
        type: ACCOUNT_VERIFIED_PHONE,
        data
    });
};


export const wsAccountChangeRequisites = data => async (dispatch) => {
    dispatch({
        type: CHANGE_REQUISITES_SUCCESSFULLY,
        data
    });
};

export const getOtpKey = () => async (dispatch, getState, { gateway }) => {
    const resource = new AccountResource(gateway);
    const response = await resource.getOtpKey();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: GET_OTP_KEY,
            data,
        });
    }
};

export const enableOtp = (
    formId, token, callback, errorCallback
) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: ACCOUNT_ENABLE_OTP_IN_PROGRESS });
    const resource = new AccountResource(gateway);
    const response = await resource.enableOtp(token);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: ACCOUNT_ENABLE_OTP_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: ACCOUNT_ENABLE_OTP_WITH_ERRORS });
        if (isFunction(errorCallback)) {
            errorCallback();
        }
        dispatch(formErrors(formId, data.errors));
    }
};

export const disableOtp = (
    formId, password, callback
) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: ACCOUNT_DISABLE_OTP_IN_PROGRESS });
    const resource = new AccountResource(gateway);
    const response = await resource.disableOtp(password);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: ACCOUNT_DISABLE_OTP_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: ACCOUNT_DISABLE_OTP_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};
