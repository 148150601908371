export const ON_CHECK_TRANSFER_DNS_ZONE = 'ON_CHECK_TRANSFER_DNS_ZONE';
export const ON_CHECK_TRANSFER_SSL = 'ON_CHECK_TRANSFER_SSL';
export const ON_CHECK_TRANSFER_DOMAINS = 'ON_CHECK_TRANSFER_DOMAINS';

export const TRANSFER_STATUS_IN_PROGRESS = 'TRANSFER_STATUS_IN_PROGRESS';
export const TRANSFER_STATUS_WITH_ERRORS = 'TRANSFER_STATUS_WITH_ERRORS';
export const TRANSFER_STATUS_SUCCESSFULLY = 'TRANSFER_STATUS_SUCCESSFULLY';

export const TRANSFER_ACCEPT_IN_PROGRESS = 'TRANSFER_ACCEPT_IN_PROGRESS';
export const TRANSFER_ACCEPT_WITH_ERRORS = 'TRANSFER_ACCEPT_WITH_ERRORS';
export const TRANSFER_ACCEPT_SUCCESSFULLY = 'TRANSFER_ACCEPT_SUCCESSFULLY';

export const TRANSFER_START_IN_PROGRESS = 'TRANSFER_START_IN_PROGRESS';
export const TRANSFER_START_WITH_ERRORS = 'TRANSFER_START_WITH_ERRORS';
export const TRANSFER_START_SUCCESSFULLY = 'TRANSFER_START_SUCCESSFULLY';

export const TRANSFER_CANCEL_BY_RESOURCE_IN_PROGRESS = 'TRANSFER_CANCEL_BY_RESOURCE_IN_PROGRESS';
export const TRANSFER_CANCEL_BY_RESOURCE_WITH_ERRORS = 'TRANSFER_CANCEL_BY_RESOURCE_WITH_ERRORS';
export const TRANSFER_CANCEL_BY_RESOURCE_SUCCESSFULLY = 'TRANSFER_CANCEL_BY_RESOURCE_SUCCESSFULLY';

export const TRANSFER_RESOURCES_ON_INIT = 'TRANSFER_RESOURCES_ON_INIT';

export const TRANSFER_SEARCH_ON_INIT = 'TRANSFER_SEARCH_ON_INIT';
export const TRANSFER_CHANGE_SEARCH_REQUEST = 'TRANSFER_CHANGE_SEARCH_REQUEST';

export const CHECK_RESOURCES_IN_PROGRESS = 'CHECK_RESOURCES_IN_PROGRESS';
export const CHECK_RESOURCES_WITH_ERRORS = 'CHECK_RESOURCES_WITH_ERRORS';
export const CHECK_RESOURCES_SUCCESSFULLY = 'CHECK_RESOURCES_SUCCESSFULLY';

export const FETCH_INSTANCE_RESOURCES_IN_PROGRESS = 'FETCH_INSTANCE_RESOURCES_IN_PROGRESS';
export const FETCH_INSTANCE_RESOURCES_SUCCESSFULLY = 'FETCH_INSTANCE_RESOURCES_SUCCESSFULLY';
export const FETCH_INSTANCE_RESOURCES_WITH_ERRORS = 'FETCH_INSTANCE_RESOURCES_WITH_ERRORS';

export const CHANGE_TRANSFER_QUERY_SUCCESSFULLY = 'CHANGE_TRANSFER_QUERY_SUCCESSFULLY';
