import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    Text,
    View,
    Image,
    StyleSheet,
    TouchableOpacity,
} from 'react-native';

import { rightIcon } from '../../icons';
import { checkItem } from '../../utils/transfer';
import Checkbox from '../General/Form/Checkbox';
import Spring from '../General/Spring/Spring';

function RelatedResourcesTable(props) {
    const {
        title,
        type,
        entities,
        onCheck,
        checkedEntities,
        transferInProgress,
        icon,
    } = props;
    const dispatch = useDispatch();
    const [isOpenList, setOpenList] = useState(true);
    return (
        <View style={styles.wrapper}>
            <TouchableOpacity
                style={styles.titleWrapper}
                onPress={() => setOpenList(!isOpenList)}
            >
                <Image source={icon} style={styles.icon} />
                <Text style={styles.title}>{title}</Text>
                <Spring />
                <Image source={rightIcon}
                    style={isOpenList ? styles.bottomIcon : styles.rightIcon}/>
            </TouchableOpacity>
            {isOpenList && entities.map((item, i) => <View key={`related-${type}-${i}`}
                style={styles.entityWrapper}>
                {!transferInProgress && <Checkbox
                    value={checkedEntities.indexOf(item) !== -1}
                    onValueChange={() => checkItem(
                        onCheck, checkedEntities, item, dispatch
                    )}
                />}
                <Text style={styles.entityTitle}>
                    {item.name}
                </Text>
            </View>)}
        </View>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        marginBottom: 15,
    },
    titleWrapper: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 19,
        paddingTop: 18,
        paddingBottom: 19,
        paddingRight: 17,
        backgroundColor: '#ffffff',
    },
    title: {
        color: '#0F79D5',
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 14,
    },
    icon: {
        width: 18,
        height: 18,
        marginRight: 10,
    },
    rightIcon: {
        height: 12.55,
        width: 7
    },
    bottomIcon: {
        transform: 'rotate(90deg)',
        height: 12.55,
        width: 7
    },
    entityWrapper: {
        backgroundColor: '#ffffff',
        marginTop: 1,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 19,
        paddingTop: 18,
        paddingBottom: 18,
    },
    entityTitle: {
        marginLeft: 10,
        color: '#455562',
        fontSize: 12,
    }
});

RelatedResourcesTable.propTypes = {
    icon: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    onCheck: PropTypes.func,
    transferInProgress: PropTypes.bool,
    entities: PropTypes.arrayOf(PropTypes.shape({})),
    checkedEntities: PropTypes.arrayOf(PropTypes.string),
};

export default RelatedResourcesTable;
