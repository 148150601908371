export const FETCH_MAIL_DOMAINS_IN_PROGRESS = 'FETCH_MAIL_DOMAINS_IN_PROGRESS';
export const FETCH_MAIL_DOMAINS_SUCCESSFULLY = 'FETCH_MAIL_DOMAINS_SUCCESSFULLY';
export const FETCH_MAIL_DOMAINS_WITH_ERRORS = 'FETCH_MAIL_DOMAINS_WITH_ERRORS';

export const FETCH_MORE_MAIL_DOMAINS_IN_PROGRESS = 'FETCH_MORE_MAIL_DOMAINS_IN_PROGRESS';
export const FETCH_MORE_MAIL_DOMAINS_SUCCESSFULLY = 'FETCH_MORE_MAIL_DOMAINS_SUCCESSFULLY';
export const FETCH_MORE_MAIL_DOMAINS_WITH_ERRORS = 'FETCH_MORE_MAIL_DOMAINS_WITH_ERRORS';

export const FETCH_DOMAIN_IN_PROGRESS = 'FETCH_DOMAIN_IN_PROGRESS';
export const FETCH_DOMAIN_SUCCESSFULLY = 'FETCH_DOMAIN_SUCCESSFULLY';
export const FETCH_DOMAIN_WITH_ERRORS = 'FETCH_DOMAIN_WITH_ERRORS';

export const UPDATE_DOMAIN_SUCCESSFULLY = 'UPDATE_DOMAIN_SUCCESSFULLY';
export const UPDATE_DOMAIN_IN_PROGRESS = 'UPDATE_DOMAIN_IN_PROGRESS';
export const UPDATE_DOMAIN_WITH_ERRORS = 'UPDATE_DOMAIN_WITH_ERRORS';

export const DELETE_DOMAIN_SUCCESSFULLY = 'DELETE_DOMAIN_SUCCESSFULLY';

export const FETCH_USER_MAIL_INFO_IN_PROGRESS =
    'FETCH_USER_MAIL_INFO_IN_PROGRESS';
export const FETCH_USER_MAIL_INFO_SUCCESSFULLY =
    'FETCH_USER_MAIL_INFO_SUCCESSFULLY';
export const FETCH_USER_MAIL_INFO_WITH_ERRORS =
    'FETCH_USER_MAIL_INFO_WITH_ERRORS';

export const FETCH_USER_MAIL_STATISTICS_IN_PROGRESS =
    'FETCH_USER_MAIL_STATISTICS_IN_PROGRESS';
export const FETCH_USER_MAIL_STATISTICS_SUCCESSFULLY =
    'FETCH_USER_MAIL_STATISTICS_SUCCESSFULLY';
export const FETCH_USER_MAIL_STATISTICS_WITH_ERRORS =
    'FETCH_USER_MAIL_STATISTICS_WITH_ERRORS';

export const CREATE_DOMAIN_IN_PROGRESS = 'CREATE_DOMAIN_IN_PROGRESS';
export const CREATE_DOMAIN_WITH_ERRORS = 'CREATE_DOMAIN_WITH_ERRORS';
export const CREATE_DOMAIN_SUCCESSFULLY = 'CREATE_DOMAIN_SUCCESSFULLY';

export const FETCH_MAILBOXES_INIT = 'FETCH_MAILBOXES_INIT';
export const FETCH_MAILBOXES_IN_PROGRESS = 'FETCH_MAILBOXES_IN_PROGRESS';
export const FETCH_MAILBOXES_SUCCESSFULLY = 'FETCH_MAILBOXES_SUCCESSFULLY';
export const FETCH_MAILBOXES_WITH_ERRORS = 'FETCH_MAILBOXES_WITH_ERRORS';

export const FETCH_MORE_MAILBOXES_IN_PROGRESS = 'FETCH_MORE_MAILBOXES_IN_PROGRESS';
export const FETCH_MORE_MAILBOXES_SUCCESSFULLY = 'FETCH_MORE_MAILBOXES_SUCCESSFULLY';
export const FETCH_MORE_MAILBOXES_WITH_ERRORS = 'FETCH_MORE_MAILBOXES_WITH_ERRORS';

export const SEARCH_MAIL_DOMAINS_IN_PROGRESS =
    'SEARCH_MAIL_DOMAINS_IN_PROGRESS';
export const SEARCH_MAIL_DOMAINS_SUCCESSFULLY =
    'SEARCH_MAIL_DOMAINS_SUCCESSFULLY';
export const SEARCH_MAIL_DOMAINS_WITH_ERRORS =
    'SEARCH_MAIL_DOMAINS_WITH_ERRORS';

export const SEARCH_MAIL_DOMAINS_INIT = 'SEARCH_MAIL_DOMAINS_INIT';
export const CHANGE_MAIL_DOMAINS_SEARCH_QUERY_SUCCESSFULLY =
    'CHANGE_MAIL_DOMAINS_SEARCH_QUERY_SUCCESSFULLY';

export const CREATE_MAILBOX_SUCCESSFULLY = 'CREATE_MAILBOX_SUCCESSFULLY';
export const CREATE_MAILBOX_IN_PROGRESS = 'CREATE_MAILBOX_IN_PROGRESS';
export const CREATE_MAILBOX_WITH_ERRORS = 'CREATE_MAILBOX_WITH_ERRORS';

export const CHANGE_MAILBOX_SUCCESSFULLY = 'CHANGE_MAILBOX_SUCCESSFULLY';
export const CHANGE_MAILBOX_IN_PROGRESS = 'CHANGE_MAILBOX_IN_PROGRESS';
export const CHANGE_MAILBOX_WITH_ERRORS = 'CHANGE_MAILBOX_WITH_ERRORS';

export const DELETE_MAILBOX_SUCCESSFULLY = 'DELETE_MAILBOX_SUCCESSFULLY';

export const DELETE_MAILBOXES_SUCCESSFULLY = 'DELETE_MAILBOXES_SUCCESSFULLY';

export const SEARCH_MAILBOXES_INIT = 'SEARCH_MAILBOXES_INIT';
export const SEARCH_MAILBOXES_IN_PROGRESS = 'SEARCH_MAILBOXES_IN_PROGRESS';
export const SEARCH_MAILBOXES_SUCCESSFULLY = 'SEARCH_MAILBOXES_SUCCESSFULLY';
export const SEARCH_MAILBOXES_WITH_ERRORS = 'SEARCH_MAILBOXES_WITH_ERRORS';
export const CHANGE_MAILBOXES_SEARCH_QUERY_SUCCESSFULLY =
    'CHANGE_MAILBOXES_SEARCH_QUERY_SUCCESSFULLY';

export const FETCH_DOMAINS_INFO_IN_PROGRESS = 'FETCH_DOMAINS_INFO_IN_PROGRESS';
export const FETCH_DOMAINS_INFO_SUCCESSFULLY =
    'FETCH_DOMAINS_INFO_SUCCESSFULLY';

export const FETCH_MAIL_PACKET_LIST_IN_PROGRESS =
    'FETCH_MAIL_PACKET_LIST_IN_PROGRESS';
export const FETCH_MAIL_PACKET_LIST_SUCCESSFULLY =
    'FETCH_MAIL_PACKET_LIST_SUCCESSFULLY';
export const FETCH_MAIL_PACKET_LIST_WITH_ERRORS =
    'FETCH_MAIL_PACKET_LIST_WITH_ERRORS';
export const FETCH_MAIL_PREFERRED_PACKET_SUCCESSFULLY =
    'FETCH_MAIL_PREFERRED_PACKET_SUCCESSFULLY';
export const CREATE_MAIL_PACKET_SUCCESSFULLY =
    'CREATE_MAIL_PACKET_SUCCESSFULLY';
export const CREATE_MAIL_PACKET_IN_PROGRESS = 'CREATE_MAIL_PACKET_IN_PROGRESS';
export const CREATE_MAIL_PACKET_WITH_ERRORS = 'CREATE_MAIL_PACKET_WITH_ERRORS';

export const FETCH_MAIL_CALCULATION_SUCCESSFULLY =
    'FETCH_MAIL_CALCULATION_SUCCESSFULLY';
export const CANCEL_MAIL_PACKET_SUCCESSFULLY =
    'CANCEL_MAIL_PACKET_SUCCESSFULLY';

export const FETCH_MAILBOX_IN_PROGRESS = 'FETCH_MAILBOX_IN_PROGRESS';
export const FETCH_MAILBOX_SUCCESSFULLY = 'FETCH_MAILBOX_SUCCESSFULLY';
export const FETCH_MAILBOX_WITH_ERRORS = 'FETCH_MAILBOX_WITH_ERRORS';

export const UPDATE_MAIL_QUOTA_IN_PROGRESS = 'UPDATE_MAIL_QUOTA_IN_PROGRESS';
export const UPDATE_MAIL_QUOTA_SUCCESSFULLY = 'UPDATE_MAIL_QUOTA_SUCCESSFULLY';
export const UPDATE_MAIL_QUOTA_WITH_ERRORS = 'UPDATE_MAIL_QUOTA_WITH_ERRORS';
