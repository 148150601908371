import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';

import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';
import DynamicallySelectedPicker from '../../General/DynamicallySelectedPicker/DynamicallySelectedPicker';

function WebContainerSelectModal(props) {
    const {
        onClose,
        onChange,
        containers,
        currentContainer,
        containerListCapabilities
    } = props;

    const [selectedValue, setSelectedValue] = React.useState(currentContainer);
    const items = containers.entities.map((container, i) => ({
        label: container.name,
        value: container.id,
        index: i
    }));
    const initialIndex = items.findIndex(item => item.value === currentContainer.value);
    return <View style={[styles.wrapper]}>
        <Text style={[styles.title]}>Выберите контейнер</Text>
        <View style={styles.select}>
            <DynamicallySelectedPicker
                items={items}
                initialSelectedIndex={initialIndex}
                onScroll={(selected) => setSelectedValue(selected.item)}/>
        </View>
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => {
                    onChange(selectedValue);
                    containerListCapabilities('containerListCapabilities', selectedValue.value, () => onClose());
                }}
                title="Выбрать"/>
            <CancelButton onClose={onClose} />
        </View>
    </View>;
}

WebContainerSelectModal.propTypes = {
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    containers: PropTypes.shape({}),
    currentContainer: PropTypes.shape({}),
    containerListCapabilities: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = () => ({});

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    select: {
        width: '100%',
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(WebContainerSelectModal);