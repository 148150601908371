import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';

import Modal from '../../General/Modal/Modal';
import { couponServicesMap } from '../constants';
import ActivateModal from '../FinanceModals/ActivateModal';
import { formatPrice } from '../../../utils/general';
const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 15,
    },
    headerBlock: {
        paddingLeft: 20,
        paddingRight: 21,
        marginBottom: 18,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontWeight: '500',
        color: '#455562',
        fontSize: 11,
        lineHeight: 13,
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
    },
    couponBlock: {
        flex: 1,
        marginBottom: 1,
        backgroundColor: '#ffffff',
        paddingLeft: 20,
        paddingBottom: 16,
        paddingRight: 23,
        paddingTop: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    couponService: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 15,
    },
    couponPrice: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 15,
        textAlign: 'right'
    },
    buttonText: {
        color: '#0F79D5',
        fontSize: 12,
        fontWeight: '500',
    },
});

function FinanceCoupon(props) {
    const {
        coupons
    } = props;
    const [isActivateModalShowed, setActivateModalVisible] = React.useState(false);

    return <View style={[styles.wrapper]}>
        <View style={styles.headerBlock}>
            <Text style={styles.title}>Промокоды</Text>
            <TouchableOpacity onPress={() => setActivateModalVisible(true)}>
                <Text style={styles.buttonText}>Активировать</Text>
            </TouchableOpacity>
        </View>
        {coupons && coupons.map((coupon, i) => <View key={`coupon-${i}`} style={styles.couponBlock}>
            <Text style={styles.couponService}>{couponServicesMap[coupon.service]}</Text>
            <Text style={styles.couponPrice}>{formatPrice(coupon.balance.toFixed(2))} ₽</Text>
        </View>)}
        <Modal isModalShowed={isActivateModalShowed}
            component={<ActivateModal onClose={() => setActivateModalVisible(false)}/>}
            onClose={() => setActivateModalVisible(false)} />
    </View>;
}

FinanceCoupon.propTypes = {
    coupons: PropTypes.arrayOf(PropTypes.shape({
        balance: PropTypes.number,
        service: PropTypes.string,
        coupons: PropTypes.arrayOf(PropTypes.shape({}))
    }))
};

const mapStateToProps = (state) => ({
    coupons: state.finance.coupons
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FinanceCoupon);
