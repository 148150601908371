/* eslint-disable no-undef */
export const COUPONS_LIST_SUCCESSFULLY = 'COUPONS_LIST_SUCCESSFULLY';
export const COUPONS_LIST_IN_PROGRESS = 'COUPONS_LIST_IN_PROGRESS';
export const COUPONS_LIST_WITH_ERRORS = 'COUPONS_LIST_WITH_ERRORS';

export const COUPON_ACTIVATE_IN_PROGRESS = 'COUPON_ACTIVATE_IN_PROGRESS';
export const COUPON_ACTIVATE_SUCCESSFULLY = 'COUPON_ACTIVATE_SUCCESSFULLY';
export const COUPON_ACTIVATE_WITH_ERRORS = 'COUPON_ACTIVATE_WITH_ERRORS';

export const AWAITING_PACKET_LIST_IN_PROGRESS = 'AWAITING_PACKET_LIST_IN_PROGRESS';
export const AWAITING_PACKET_LIST_SUCCESSFULLY = 'AWAITING_PACKET_LIST_SUCCESSFULLY';
export const AWAITING_PACKET_LIST_WITH_ERRORS = 'AWAITING_PACKET_LIST_WITH_ERRORS';
export const AWAITING_PACKET_CANCEL_SUCCESSFULLY = 'AWAITING_PACKET_CANCEL_SUCCESSFULLY';
export const AWAITING_PACKET_CANCEL_WITH_ERRORS = 'AWAITING_PACKET_CANCEL_WITH_ERRORS';

export const CHARGES_MONTH_GROUP_IN_PROGRESS = 'CHARGES_MONTH_GROUP_IN_PROGRESS';
export const CHARGES_MONTH_GROUP_SUCCESSFULLY = 'CHARGES_MONTH_GROUP_SUCCESSFULLY';
export const CHARGES_MONTH_GROUP_WITH_ERRORS = 'CHARGES_MONTH_GROUP_WITH_ERRORS';

export const CREATE_INVOICE_SUCCESSFULLY = 'CREATE_INVOICE_SUCCESSFULLY';
export const CREATE_INVOICE_IN_PROGRESS = 'CREATE_INVOICE_IN_PROGRESS';
export const CREATE_INVOICE_WITH_ERRORS = 'CREATE_INVOICE_WITH_ERRORS';

export const SET_PAYMENT_AMOUNT = 'SET_PAYMENT_AMOUNT';

export const DEFERRED_PAYMENT_SET_IN_PROGRESS = 'DEFERRED_PAYMENT_SET_IN_PROGRESS';
export const DEFERRED_PAYMENT_SET_WITH_ERRORS = 'DEFERRED_PAYMENT_SET_WITH_ERRORS';
export const DEFERRED_PAYMENT_SET_SUCCESSFULLY = 'DEFERRED_PAYMENT_SET_SUCCESSFULLY';

export const MONTHS_FOR_PAYMENTS_IN_PROGRESS = 'MONTHS_FOR_PAYMENTS_IN_PROGRESS';
export const MONTHS_FOR_PAYMENTS_SUCCESSFULLY = 'MONTHS_FOR_PAYMENTS_SUCCESSFULLY';
export const MONTHS_FOR_PAYMENTS_WITH_ERRORS = 'MONTHS_FOR_PAYMENTS_WITH_ERRORS';

export const MONTHS_FOR_CHARGES_IN_PROGRESS = 'MONTHS_FOR_CHARGES_IN_PROGRESS';
export const MONTHS_FOR_CHARGES_SUCCESSFULLY = 'MONTHS_FOR_CHARGES_SUCCESSFULLY';
export const MONTHS_FOR_CHARGES_WITH_ERRORS = 'MONTHS_FOR_CHARGES_WITH_ERRORS';

export const REPORT_MONTHS_IN_PROGRESS = 'REPORT_MONTHS_IN_PROGRESS';
export const REPORT_MONTHS_WITH_ERRORS = 'REPORT_MONTHS_WITH_ERRORS';
export const REPORT_MONTHS_SUCCESSFULLY = 'REPORT_MONTHS_SUCCESSFULLY';

export const MONTHS_FOR_ACTS_IN_PROGRESS = 'MONTHS_FOR_ACTS_IN_PROGRESS';
export const MONTHS_FOR_ACTS_SUCCESSFULLY = 'MONTHS_FOR_ACTS_SUCCESSFULLY';
export const MONTHS_FOR_ACTS_WITH_ERRORS = 'MONTHS_FOR_ACTS_WITH_ERRORS';

export const CHARGES_MONTH_CHANGE_DATE = 'CHARGES_MONTH_CHANGE_DATE';

export const EXPECTED_CHARGES_IN_PROGRESS = 'EXPECTED_CHARGES_IN_PROGRESS';
export const EXPECTED_CHARGES_SUCCESSFULLY = 'EXPECTED_CHARGES_SUCCESSFULLY';
export const EXPECTED_CHARGES_WITH_ERRORS = 'EXPECTED_CHARGES_WITH_ERRORS';

export const SET_FORECAST_DATE = 'SET_FORECAST_DATE';

export const WS_USER_BALANCE_UPDATE = 'WS_USER_BALANCE_UPDATE';

export const INVOICES_LIST_IN_PROGRESS = 'INVOICES_LIST_IN_PROGRESS';
export const INVOICES_LIST_SUCCESSFULLY = 'INVOICES_LIST_SUCCESSFULLY';
export const INVOICES_LIST_WITH_ERRORS = 'INVOICES_LIST_WITH_ERRORS';

export const SEND_INVOICE_TO_EMAIL_SUCCESSFULLY = 'SEND_INVOICE_TO_EMAIL_SUCCESSFULLY';
export const SEND_INVOICE_TO_EMAIL_IN_PROGRESS = 'SEND_INVOICE_TO_EMAIL_IN_PROGRESS';
export const SEND_INVOICE_TO_EMAIL_WITH_ERRORS = 'SEND_INVOICE_TO_EMAIL_WITH_ERRORS';

export const AUTOPAYMENT_DETAIL_FETCH_IN_PROGRESS = 'AUTOPAYMENT_DETAIL_FETCH_IN_PROGRESS';
export const AUTOPAYMENT_DETAIL_FETCH_SUCCESSFULLY = 'AUTOPAYMENT_DETAIL_FETCH_SUCCESSFULLY';
export const AUTOPAYMENT_DETAIL_FETCH_WITH_ERRORS = 'AUTOPAYMENT_DETAIL_FETCH_WITH_ERRORS';

export const DOWNLOAD_ACT_IN_PROGRESS = 'DOWNLOAD_ACT_IN_PROGRESS';
export const DOWNLOAD_ACT_SUCCESSFULLY = 'DOWNLOAD_ACT_SUCCESSFULLY';
export const DOWNLOAD_ACT_WITH_ERRORS = 'DOWNLOAD_ACT_WITH_ERRORS';

export const INIT_PAYMENT_IN_PROGRESS = 'INIT_PAYMENT_IN_PROGRESS';
export const INIT_PAYMENT_SUCCESSFULLY = 'INIT_PAYMENT_SUCCESSFULLY';
export const INIT_PAYMENT_WITH_ERRORS = 'INIT_PAYMENT_WITH_ERRORS';
