export const VM_LIST_FETCHED_IN_PROGRESS = 'VM_LIST_FETCHED_IN_PROGRESS';
export const VM_LIST_FETCHED_WITH_ERRORS = 'VM_LIST_FETCHED_WITH_ERRORS';
export const VM_LIST_FETCHED_SUCCESSFULLY = 'VM_LIST_FETCHED_SUCCESSFULLY';

export const CVDS_CREATE_VM_UPDATE_PARAMS = 'CVDS_CREATE_VM_UPDATE_PARAMS';
export const CVDS_CHANGE_DISTRIBUTION = 'CVDS_CHANGE_DISTRIBUTION';

export const VM_CREATE_SUCCESSFULLY = 'VM_CREATE_SUCCESSFULLY';
export const VM_CREATE_IN_PROGRESS = 'VM_CREATE_IN_PROGRESS';
export const VM_CREATE_WITH_ERRORS = 'VM_CREATE_WITH_ERRORS';
export const VM_CREATE_TO_INIT = 'VM_CREATE_TO_INIT';

export const DISTRIBUTIONS_FETCHED_IN_PROGRESS = 'DISTRIBUTIONS_FETCHED_IN_PROGRESS';
export const DISTRIBUTIONS_FETCHED_SUCCESSFULLY = 'DISTRIBUTIONS_FETCHED_SUCCESSFULLY';
export const DISTRIBUTIONS_FETCHED_WITH_ERRORS = 'DISTRIBUTIONS_FETCHED_WITH_ERRORS';

export const VM_RELOAD_SUCCESSFULLY = 'VM_RELOAD_SUCCESSFULLY';

export const VM_CHANGE_STATE_SUCCESSFULLY = 'VM_CHANGE_STATE_SUCCESSFULLY';

export const VOLUMES_USED_SIZE_WITH_ERROR = 'VOLUMES_USED_SIZE_WITH_ERROR';
export const VOLUMES_USED_SIZE_IN_PROGRESS = 'VOLUMES_USED_SIZE_IN_PROGRESS';
export const VOLUMES_USED_SIZE_SUCCESSFULLY = 'VOLUMES_USED_SIZE_SUCCESSFULLY';

export const VM_BACKUP_SWITCH_SUCCESSFULLY = 'VM_BACKUP_SWITCH_SUCCESSFULLY';
export const VM_BACKUP_SWITCH_IN_PROGRESS = 'VM_BACKUP_SWITCH_IN_PROGRESS';
export const VM_BACKUP_SWITCH_WITH_ERRORS = 'VM_BACKUP_SWITCH_WITH_ERRORS';

export const VM_CHANGE_BANDWIDTH_SUCCESSFULLY = 'VM_CHANGE_BANDWIDTH_SUCCESSFULLY';
export const VM_CHANGE_BANDWIDTH_IN_PROGRESS = 'VM_CHANGE_BANDWIDTH_IN_PROGRESS';
export const VM_CHANGE_BANDWIDTH_WITH_ERRORS = 'VM_CHANGE_BANDWIDTH_WITH_ERRORS';

export const VM_REMOVE_IP_SUCCESSFULLY = 'VM_REMOVE_IP_SUCCESSFULLY';

export const VM_DISABLE_PROTECTION_IN_PROGRESS = 'VM_DISABLE_PROTECTION_IN_PROGRESS';
export const VM_DISABLE_PROTECTION_SUCCESSFULLY = 'VM_DISABLE_PROTECTION_SUCCESSFULLY';
export const VM_DISABLE_PROTECTION_WITH_ERRORS = 'VM_DISABLE_PROTECTION_WITH_ERRORS';

export const VM_BAR_CHART_CPU_FETCH_IN_PROGRESS = 'VM_BAR_CHART_CPU_FETCH_IN_PROGRESS';
export const VM_BAR_CHART_CPU_FETCH_SUCCESSFULLY = 'VM_BAR_CHART_CPU_FETCH_SUCCESSFULLY';
export const VM_BAR_CHART_CPU_FETCH_WITH_ERRORS = 'VM_BAR_CHART_CPU_FETCH_WITH_ERRORS';

export const VM_BAR_CHART_RAM_FETCH_IN_PROGRESS = 'VM_BAR_CHART_RAM_FETCH_IN_PROGRESS';
export const VM_BAR_CHART_RAM_FETCH_SUCCESSFULLY = 'VM_BAR_CHART_RAM_FETCH_SUCCESSFULLY';
export const VM_BAR_CHART_RAM_FETCH_WITH_ERRORS = 'VM_BAR_CHART_RAM_FETCH_WITH_ERRORS';

export const VM_FETCHED_IN_PROGRESS = 'VM_FETCHED_IN_PROGRESS';
export const VM_FETCHED_SUCCESSFULLY = 'VM_FETCHED_SUCCESSFULLY';
export const VM_FETCHED_WITH_ERRORS = 'VM_FETCHED_WITH_ERRORS';

export const VM_GET_FINANCE_INFO_SUCCESSFULLY = 'VM_GET_FINANCE_INFO_SUCCESSFULLY';
export const VM_GET_FINANCE_INFO_WITH_ERRORS = 'VM_GET_FINANCE_INFO_WITH_ERRORS';

export const VM_CREATE_PACKET_SUCCESSFULLY = 'VM_CREATE_PACKET_SUCCESSFULLY';
export const VM_CREATE_PACKET_IN_PROGRESS = 'VM_CREATE_PACKET_IN_PROGRESS';
export const VM_CREATE_PACKET_WITH_ERRORS = 'VM_CREATE_PACKET_WITH_ERRORS';

export const VM_CANCEL_PACKET_SUCCESSFULLY = 'VM_CANCEL_PACKET_SUCCESSFULLY';

export const VM_UPDATE_PACKET_SUCCESSFULLY = 'VM_UPDATE_PACKET_SUCCESSFULLY';
export const VM_UPDATE_PACKET_WITH_ERRORS = 'VM_UPDATE_PACKET_WITH_ERRORS';
export const VM_UPDATE_PACKET_IN_PROGRESS = 'VM_UPDATE_PACKET_IN_PROGRESS';

export const VM_CHANGE_TARIFF_SUCCESSFULLY = 'VM_CHANGE_TARIFF_SUCCESSFULLY';
export const VM_CHANGE_TARIFF_IN_PROGRESS = 'VM_CHANGE_TARIFF_IN_PROGRESS';
export const VM_CHANGE_TARIFF_WITH_ERRORS = 'VM_CHANGE_TARIFF_WITH_ERRORS';

export const FETCH_TARIFF_CALCULATION_IN_PROGRESS = 'FETCH_TARIFF_CALCULATION_IN_PROGRESS';
export const FETCH_TARIFF_CALCULATION_SUCCESSFULLY = 'FETCH_TARIFF_CALCULATION_SUCCESSFULLY';
export const FETCH_TARIFF_CALCULATION_WITH_ERRORS = 'FETCH_TARIFF_CALCULATION_WITH_ERRORS';
export const VM_RESET_PASSWORD_SUCCESSFULLY = 'VM_RESET_PASSWORD_SUCCESSFULLY';
export const VM_SUPPORT_ACCESS_IN_PROGRESS = 'VM_SUPPORT_ACCESS_IN_PROGRESS';
export const VM_SUPPORT_ACCESS_SUCCESSFULLY = 'VM_SUPPORT_ACCESS_SUCCESSFULLY';
export const VM_SUPPORT_ACCESS_WITH_ERRORS = 'VM_SUPPORT_ACCESS_WITH_ERRORS';
export const VM_PRIVATE_NETWORK_SWITCH_SUCCESSFULLY = 'VM_PRIVATE_NETWORK_SWITCH_SUCCESSFULLY';
export const VM_VOLUME_ADD_SUCCESSFULLY = 'VM_VOLUME_ADD_SUCCESSFULLY';

export const VM_RENAME_SUCCESSFULLY = 'VM_RENAME_SUCCESSFULLY';

export const SET_CURRENT_VOLUME = 'SET_CURRENT_VOLUME';

export const VM_VOLUME_BACKUP_SWITCH_SUCCESSFULLY = 'VM_VOLUME_BACKUP_SWITCH_SUCCESSFULLY';
export const VM_VOLUME_BACKUP_SWITCH_IN_PROGRESS = 'VM_VOLUME_BACKUP_SWITCH_IN_PROGRESS';
export const VM_VOLUME_BACKUP_SWITCH_WITH_ERRORS = 'VM_VOLUME_BACKUP_SWITCH_WITH_ERRORS';
export const VM_DISK_CHANGE_INFO_SUCCESSFULLY = 'VM_DISK_CHANGE_INFO_SUCCESSFULLY';
export const VM_VOLUME_CHANGE_INFO_SUCCESSFULLY = 'VM_VOLUME_CHANGE_INFO_SUCCESSFULLY';

export const VM_DELETE_SUCCESSFULLY = 'VM_DELETE_SUCCESSFULLY';

export const VMS_INFO_SUCCESSFULLY = 'VMS_INFO_SUCCESSFULLY';

export const WS_VM_CREATE_SUCCESSFULLY = 'WS_VM_CREATE_SUCCESSFULLY';
export const WS_VM_UPDATE = 'WS_VM_UPDATE';
export const WS_VM_UPDATE_IN_LIST = 'WS_VM_UPDATE_IN_LIST';
export const WS_VM_VOLUME_CREATE_SUCCESSFULLY = 'WS_VM_VOLUME_CREATE_SUCCESSFULLY';
export const WS_VM_VOLUME_DELETE_SUCCESSFULLY = 'WS_VM_VOLUME_DELETE_SUCCESSFULLY';
export const WS_VM_UPDATE_FINANCE_INFO = 'WS_VM_UPDATE_FINANCE_INFO';

export const VM_ADD_IP_IN_PROGRESS = 'VM_ADD_IP_IN_PROGRESS';
export const VM_ADD_IP_SUCCESSFULLY = 'VM_ADD_IP_SUCCESSFULLY';
export const VM_ADD_IP_WITH_ERRORS = 'VM_ADD_IP_WITH_ERRORS';

export const VM_TO_INIT = 'VM_TO_INIT';

export const VM_UPDATE_IN_PROGRESS = 'VM_UPDATE_IN_PROGRESS';
export const VM_UPDATE_WITH_ERRORS = 'VM_UPDATE_WITH_ERRORS';

export const VM_FETCH_PROTECTED_WEBSITES_IN_PROGRESS = 'VM_FETCH_PROTECTED_WEBSITES_IN_PROGRESS';
export const VM_FETCH_PROTECTED_WEBSITES_SUCCESSFULLY = 'VM_FETCH_PROTECTED_WEBSITES_SUCCESSFULLY';
export const VM_FETCH_PROTECTED_WEBSITES_WITH_ERRORS = 'VM_FETCH_PROTECTED_WEBSITES_WITH_ERRORS';
export const VM_ADD_PROTECTED_WEBSITE_SUCCESSFULLY = 'VM_ADD_PROTECTED_WEBSITE_SUCCESSFULLY';
export const VM_ADD_PROTECTED_WEBSITE_IN_PROGRESS = 'VM_ADD_PROTECTED_WEBSITE_IN_PROGRESS';
export const VM_ADD_PROTECTED_WEBSITE_WITH_ERRORS = 'VM_ADD_PROTECTED_WEBSITE_WITH_ERRORS';
export const VM_CHANGE_PROTECTED_WEBSITE_IN_PROGRESS = 'VM_CHANGE_PROTECTED_WEBSITE_IN_PROGRESS';
export const VM_CHANGE_PROTECTED_WEBSITE_WITH_ERRORS = 'VM_CHANGE_PROTECTED_WEBSITE_WITH_ERRORS';
export const VM_CHANGE_PROTECTED_WEBSITE_SUCCESSFULLY = 'VM_CHANGE_PROTECTED_WEBSITE_SUCCESSFULLY';
export const VM_DELETE_PROTECTED_WEBSITE_SUCCESSFULLY = 'VM_DELETE_PROTECTED_WEBSITE_SUCCESSFULLY';
export const VM_DELETE_PROTECTED_WEBSITE_IN_PROGRESS = 'VM_DELETE_PROTECTED_WEBSITE_IN_PROGRESS';
export const VM_DELETE_PROTECTED_WEBSITE_WITH_ERRORS = 'VM_DELETE_PROTECTED_WEBSITE_WITH_ERRORS';

export const BACKUPS_FETCHED_IN_PROGRESS = 'BACKUPS_FETCHED_IN_PROGRESS';
export const BACKUPS_FETCHED_SUCCESSFULLY = 'BACKUPS_FETCHED_SUCCESSFULLY';
export const BACKUPS_FETCHED_WITH_ERRORS = 'BACKUPS_FETCHED_WITH_ERRORS';

export const BACKUP_DATES_FETCHED_IN_PROGRESS = 'BACKUP_DATES_FETCHED_IN_PROGRESS';
export const BACKUP_DATES_FETCHED_SUCCESSFULLY = 'BACKUP_DATES_FETCHED_SUCCESSFULLY';
export const BACKUP_DATES_FETCHED_WITH_ERRORS = 'BACKUP_DATES_FETCHED_WITH_ERRORS';

export const RESTORE_FROM_BACKUP_TO_INIT = 'RESTORE_FROM_BACKUP_TO_INIT';
export const RESTORE_FROM_BACKUP_UPDATE_PARAMS = 'RESTORE_FROM_BACKUP_UPDATE_PARAMS';

export const RESTORE_FROM_BACKUP_SUCCESSFULLY = 'RESTORE_FROM_BACKUP_SUCCESSFULLY';
export const RESTORE_FROM_BACKUP_IN_PROGRESS = 'RESTORE_FROM_BACKUP_IN_PROGRESS';
export const RESTORE_FROM_BACKUP_WITH_ERRORS = 'RESTORE_FROM_BACKUP_WITH_ERRORS';
