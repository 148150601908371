import { clamp } from '../../utils/general';
import {
    CONTAINER_CREATE_SET_PARAMS,
    CONTAINER_CREATE_SUCCESSFULLY,
    CONTAINER_CREATE_IN_PROGRESS,
    CONTAINER_CREATE_FLUSH_PARAMS,
    CONTAINER_CREATE_WITH_ERRORS
} from '../../actions/hosting/actionTypes';
import { tariffs } from '../../constants/hosting';

const getTariffOrDefault = (tariffType) => {
    const getDefaultTariff = () => tariffs.find(t => t.default === true);
    if (tariffType == null) {
        return getDefaultTariff();
    }
    return tariffs.find(t => t.name === tariffType) || getDefaultTariff();
};

const defaultState = {
    containerType: 'new',
    name: 'Контейнер для сайтов',
    storage: 5,
    redis: null,
    memcached: null,
    createContainerInProgress: false,
    containerTariff: getTariffOrDefault()
};

export default (initialState) => {
    let containerCreateState = { ...defaultState };

    return (state = containerCreateState, action) => {
        switch (action.type) {
        case CONTAINER_CREATE_SET_PARAMS: {
            return {
                ...state,
                ...action.data
            };
        }
        case CONTAINER_CREATE_SUCCESSFULLY: {
            return {
                ...state,
                createContainerInProgress: false,
            };
        }
        case CONTAINER_CREATE_IN_PROGRESS: {
            return {
                ...state,
                createContainerInProgress: true
            };
        }
        case CONTAINER_CREATE_WITH_ERRORS: {
            return {
                ...state,
                createContainerInProgress: false,
            };
        }
        case CONTAINER_CREATE_FLUSH_PARAMS: {
            return {
                ...defaultState
            };
        }
        default: {
            return state;
        }
        }
    };
};
