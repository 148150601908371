import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text } from 'react-native';

import { clamp, getThresholdColorClass } from '../../../utils/general';

function ProgressBar(props) {
    const {
        min,
        color,
        percent,
        children,
        modifiers
    } = props;
    const normalizePercent = clamp(percent, min || 0);
    const normalizeColor = color || getThresholdColorClass(normalizePercent);
    return <View style={[styles.wrapper, modifiers]}>
        <View style={[{ width: `${normalizePercent}%`, height: '100%' }, styles[normalizeColor]]}>
            {children}
        </View>
    </View>;
}

ProgressBar.propTypes = {
    children: PropTypes.shape({
        title: PropTypes.string,
        type: PropTypes.string,
        link: PropTypes.string,
    }),
    modifiers: PropTypes.shape({}),
    color: PropTypes.string,
    percent: PropTypes.number.isRequired,
    min: PropTypes.number,
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        height: '100%',
        borderRadius: 10,
        backgroundColor: '#B0B0B0',
    },
    safe: {
        backgroundColor: '#29ac7a',
    },
    warning: {
        backgroundColor: '#fa8f34',
    },
    danger: {
        backgroundColor: '#d06565',
    },
    blue: {
        backgroundColor: '#0f79d5',
    }
})

export default ProgressBar;
