import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';

import { sumBy } from '../../../utils/general';
import { statisticServicesMap, statisticServicesMapColor } from '../constants';
import HorizontalBarChartStatistic from '../../General/HorizontalBarChart/HorizontalBarChartStatistic';
import { formatPrice } from '../../../utils/general';

function statisticsSummarizeResources(statisticsCharges) {
    const { summarize } = statisticsCharges;

    return summarize.map(service => ({
        id: service.service,
        title: statisticServicesMap[service.service] || service.service,
        colorType: statisticServicesMapColor[service.service] || 'gray',
        value: service.total,
        formatValue: v => `${v.toFixed(2)} ₽`
    }));
}

function hasSummarize(statisticsCharges) {
    return Boolean(statisticsCharges && statisticsCharges.summarize
        && statisticsCharges.summarize.length > 0);
}

function total(resources) {
    return sumBy(resources, r => r.value).toFixed(2);
}

function FinanceCostsMain(props) {
    const {
        statisticsCharges,
        statisticsChargesDate,
        statisticsChargesInProgress
    } = props;

    const navigation = useNavigation();

    const statisticsChargesMonth = new Date(statisticsChargesDate).toLocaleDateString(
        'ru', { month: 'long' });
    let resources = [];
    if (!statisticsChargesInProgress && hasSummarize(statisticsCharges)) {
        resources = statisticsSummarizeResources(statisticsCharges);
    }
    return !statisticsChargesInProgress && <View style={styles.wrapper}>
        <View style={styles.titleBlock}>
            <TouchableOpacity onPress={() => navigation.navigate('FinanceDetail')}>
                <Text style={styles.titleText}>Расходы за {statisticsChargesMonth}</Text>
            </TouchableOpacity>
            <Text style={styles.titleText}>{formatPrice(total(resources))} ₽</Text>
        </View>
        {hasSummarize(statisticsCharges) && <HorizontalBarChartStatistic resources={resources}
            total={total(resources)} />}
        {!hasSummarize(statisticsCharges) && <Text style={styles.emptyText}>
            В текущем месяце не производилось списаний
        </Text>}
    </View>;
}

FinanceCostsMain.propTypes = {
    statisticsCharges: PropTypes.shape({
        groups: PropTypes.arrayOf(PropTypes.shape({
            service: PropTypes.string,
            resource_name: PropTypes.string,
            total: PropTypes.number
        })),
        summarize: PropTypes.arrayOf(PropTypes.shape({
            service: PropTypes.string,
            total: PropTypes.number
        }))
    }),
    statisticsChargesInProgress: PropTypes.bool,
    statisticsChargesDate: PropTypes.string,
};

const mapStateToProps = state => ({
    statisticsCharges: state.finance.statisticsCharges,
    statisticsChargesDate: state.finance.statisticsChargesDate,
    statisticsChargesInProgress: state.finance.statisticsChargesInProgress
});

const mapDispatchToProps = () => ({});

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 24,
        marginBottom: 29,
        backgroundColor: '#ffffff',
        paddingTop: 23,
        paddingRight: 18,
        paddingBottom: 26,
        paddingLeft: 20
    },
    titleBlock: {
        flex: 1,
        marginBottom: 13,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    titleText: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '600',
        lineHeight: 14
    },
    emptyText: {
        fontSize: 12,
        lineHeight: 14,
        color: '#455562'
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(FinanceCostsMain);
