import React from 'react';
import PropTypes from 'prop-types';
import {
    View,
    StyleSheet,
    Text,
    Image,
} from 'react-native';

import { bToMb } from '@netangels/core/utils/convert';

import {
    clamp,
    getPercent,
    getThresholdColorClass,
    formatMemory
} from '../../utils/general';
import { cloudMailIcon } from '../../icons';
import Switch from '../General/Switch/Switch';
import ResourceFills from '../General/ResourceFills/ResourceFills';

function CurrentPacketBlock(props) {
    const {
        packet,
        used,
        freeQuota,
        prolong,
        setProlong,
        needProlong = false,
        needHeader = true,
        additionalWrapperStyles = [],
    } = props;
    const {
        limit,
    } = packet;

    const packetLimit = limit * 1024;
    const current = bToMb(used);
    const paidUsed = current - freeQuota;
    const packetRest = packetLimit - paidUsed > 0 ? packetLimit - paidUsed : 0;
    const percent = clamp(getPercent(packetLimit, packetRest));
    const color = getThresholdColorClass(100 - clamp(percent, 0));
    return <View>
        <View style={[styles.resourceFillsBlock, ...additionalWrapperStyles]}>
            {needHeader && <View style={styles.resourceFillsHeaderBlock}>
                <Image source={cloudMailIcon} style={styles.resourceFillsHeaderBlockIcon} />
                <Text style={styles.resourceFillsHeaderBlockText}>
                    Пакет почты {packetLimit / 1024} ГБ
                </Text>
            </View>}
            <ResourceFills
                isLoaded={true}
                isLoading={false}
                height={5}
                additionalHeaderTextStyles={[{ fontSize: 11, lineHeight: 11 }]}
                backgroundAdditionalStyles={{ borderRadius: 2 }}
                additionalHeader={`Всего ${packetLimit / 1024} ГБ`}
                name={`Осталось ${formatMemory(packetRest, true)}`}
                scales={[
                    {
                        max: packetLimit,
                        color,
                        current: packetRest,
                        scaleModifiers: [{ borderRadius: 2 }]
                    },
                ]}
            />
        </View>
        {needProlong && <View style={styles.prolongSwitch}>
            <Switch label='Автоматическое продление'
                isEnable={prolong}
                onChange={setProlong}/>
        </View>}
    </View>;
}

CurrentPacketBlock.propTypes = {
    packet: PropTypes.shape({
        cost: PropTypes.number,
        limit: PropTypes.number,
    }),
    used: PropTypes.number,
    freeQuota: PropTypes.number,
    prolong: PropTypes.bool,
    setProlong: PropTypes.func,
    needProlong: PropTypes.bool,
    needHeader: PropTypes.bool,
    additionalWrapperStyles: PropTypes.arrayOf(),
};

const styles = StyleSheet.create({
    prolongSwitch: {
        backgroundColor: '#ffffff',
        marginTop: 8,
        paddingTop: 16,
        paddingLeft: 22,
        paddingBottom: 16,
        paddingRight: 20,
    },
    resourceFillsBlock: {
        backgroundColor: '#ffffff',
        paddingTop: 16,
        paddingLeft: 20,
        paddingBottom: 24,
        paddingRight: 20,
    },
    resourceFillsHeaderBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 7,
    },
    resourceFillsHeaderBlockIcon: {
        width: 24,
        height: 25,
        marginRight: 12,
    },
    resourceFillsHeaderBlockText: {
        color: '#455562',
        fontSize: 14,
        fontWeight: 500,
    },
});

export default CurrentPacketBlock;
