import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Clipboard from 'expo-clipboard';
import { TextInput, StyleSheet, View } from 'react-native';

import Errors from './Errors';
import { isEmpty } from '../../../utils/general';

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    input: {
        borderColor: 'rgba(69,85,98,0.3)',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 4,
        fontSize: 20,
        lineHeight: 'normal',
        color: '#455562',
        paddingTop: 11,
        paddingRight: 12,
        paddingBottom: 11,
        paddingLeft: 11,
        width: 35,
        height: 53,
        marginRight: 10,
    },
    emptyInput: {
        borderRadius: 4,
        height: 53,
        width: 35,
        paddingLeft: 11,
        marginRight: 10,
        backgroundColor: '#F2F6FA',
    },
    codeInput: {
        width: 26,
        paddingLeft: 7,
        paddingRight: 7,
        marginRight: 4,
    },
    error: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#d06565',
    },
    errorWrapper: {
        width: '100%',
        position: 'absolute',
        bottom: -26,
        textAlign: 'center',
    },
});

function PinInput(props) {
    const {
        textInputAttrs, count = 6, errors, onChange, onFill
    } = props;
    const [currentInput, setCurrentInput] = useState(null);
    const [pin, setPin] = useState(Array.from({ length: count }, () => ''));
    const [inputRefs, setInputRefs] = useState(
        Array.from({ length: count }, () => React.createRef())
    );

    const inputAttrs = {
        ...textInputAttrs,
    };
    const additionalInputClasses = [];
    if (errors != null && errors.length > 0) {
        additionalInputClasses.push('error');
    }

    const focusNext = () => {
        const index = pin.findIndex((p) => p.length === 0);
        const ref = inputRefs[index];
        if (ref) {
            if (currentInput != null) {
                currentInput.blur();
            }
            setCurrentInput(ref);
            ref.focus();
        }
    };

    const focusPrev = () => {
        const index = pin.findIndex((p) => p.length === 0);
        const ref = inputRefs[index - 1];
        if (ref) {
            if (currentInput != null) {
                currentInput.blur();
            }
            setCurrentInput(ref);
            ref.focus();
        }
    };

    const resetPin = () => {
        setPin(Array.from({ length: count }, () => ''));
    };

    useEffect(() => {
        resetPin();
        setInputRefs(Array.from({ length: count }, () => React.createRef()));
    }, [count]);

    useEffect(() => {
        const index = pin.findIndex((p) => p.length === 0) - 1;
        if (index === -1) {
            focusNext();
        }
        if (pin[index]) {
            if (currentInput === inputRefs[index]) {
                focusNext();
            } else {
                focusPrev();
            }
            onChange(pin);
        }
        if (pin.every((p) => p.length > 0)) {
            onFill(pin);
        }
    }, [pin]);

    useEffect(() => {
        if (!isEmpty(errors) && pin.every((p) => p.length > 0)) {
            resetPin();
        }
    }, [errors]);

    const handleOnChangeText = async (e, index) => {
        const content = e.target && e.target.value;
        const copiedContent = await Clipboard.getStringAsync();
        if (e.target && content.length > 1) {
            focusNext();
        }
        if (copiedContent !== '') {
            const isPasted = content.includes(copiedContent);
            if (isPasted) {
                const pin = content.split('');
                setPin(pin);
                focusNext();
                onFill(pin);
                return;
            }
        }
        const value = content.replace(/[^0-9]/, '');
        if (!/^[0-9]{0,1}$/.test(content)) {
            return;
        }
        setPin(pin.map((v, i) => (i === index && value.length < 2 ? value : v)));
    };
    return (
        <View>
            <View style={styles.wrapper}>
                {pin.map((value, index) => {
                    const isNotEmpty = value && value.length > 0;
                    const inputStyles = isNotEmpty ? styles.input : styles.emptyInput;
                    const codeStyles = count === 10 ? styles.codeInput : {};
                    return (
                        <TextInput
                            style={[
                                inputStyles,
                                codeStyles,
                                ...additionalInputClasses.map((cls) => styles[cls]),
                            ]}
                            autoCompleteType="off"
                            pattern="^\d{0,1}$"
                            onChange={(e) => handleOnChangeText(e, index)}
                            value={value}
                            key={`pin-input-key-${index}`}
                            ref={(ref) => (inputRefs[index] = ref)}
                            keyboardType='numeric'
                            onKeyPress={(e) => {
                                if (value.length === 0 && e.nativeEvent.key === 'Backspace') {
                                    focusPrev();
                                    e.stopPropagation();
                                    e.preventDefault();
                                }
                            }}
                            {...inputAttrs}
                        ></TextInput>
                    );
                })}
            </View>
            <View style={styles.errorWrapper}>
                <Errors errors={errors} />
            </View>
        </View>
    );
}

PinInput.propTypes = {
    errors: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
    ]),
    onRef: PropTypes.func,
    onFill: PropTypes.func,
    count: PropTypes.number,
    onChange: PropTypes.func,
    textInputAttrs: PropTypes.shape({}),
};

PinInput.defaultProps = {
    errors: [],
    onChange: () => {},
    textInputAttrs: {},
};

export default PinInput;
