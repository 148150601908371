import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { WebView } from 'react-native-webview';
import { View, StyleSheet } from 'react-native';

import { isString } from '../../../utils/general';

const styles = StyleSheet.create({
    errorWrapper: {
        marginTop: 5,
        minHeight: 16,
        marginBottom: 5,
    },
    error: {
        fontSize: 12,
        fontWeight: '300',
        color: '#c80502'
    }
});

function Errors(props) {
    const { errors } = props;
    const resultErrors = isString(errors) ? [errors] : errors;
    const [frameHeight, setFrameHeight] = useState(0);
    const re = /<(a)([^>]+)>/;
    return resultErrors != null && resultErrors.length > 0 && <View style={styles.errorWrapper}>
        {resultErrors.map((error, i) => {
            const errorStyle = '<style>body{font-size: 12px; color: #c80502; font-weight: 300; font-family: Roboto, sans-serif; margin: 0;}a{color: #0f79d5; margin-left: 2px}</style>';
            return <WebView
                key={`error-${i}`}
                originWhitelist={['*']}
                source={{
                    html: `${errorStyle}${error.replace(re, '<$1 target="_blank"$2>')}`
                }}
                style={[styles.error, { height: frameHeight }]}
                scrollEnabled={false}
                onLoad={(e) => setFrameHeight(e.target.contentWindow.document.body.scrollHeight)}
            />;
        })}
    </View>;
}

Errors.propTypes = {
    errors: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string
    ])
};

export default Errors;
