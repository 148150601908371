import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity
} from 'react-native';
import { createAndDownloadTxtFile } from '@netangels/core/utils/general';

import Errors from '../../General/Form/Errors';
import { flushErrors } from '../../../actions/forms/actions';
import { getOtpKey, enableOtp } from '../../../actions/account/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';
import CopypableText from '../../General/CopypableText/CopypableText';
import Separator from '../../General/Separator/Separator';
import PinInput from '../../General/Form/PinInput';

const formId = 'EnableOtpForm';

function EnableOtpModal(props) {
    const {
        onClose,
    } = props;
    const { otpKey, enableOtpInProgress, otpCodes } = useSelector(
        (state) => state.account,
    );
    const { errors } = useSelector((state) => state.forms);
    const [currentToken, setToken] = useState('');
    const [isEnableOtp, setIsEnableOtp] = useState(false);

    const currentErrors = errors[formId] || {};
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(flushErrors());
        dispatch(getOtpKey());
    }, []);

    return <View style={styles.wrapper}>
        {!isEnableOtp && <React.Fragment>
            <Text style={styles.title}>Двухфакторная авторизация</Text>
            <Text style={styles.text}>
                Введите символьный ключ в установленное приложение-аутентификатор
            </Text>
            <Text style={styles.code}>
                {otpKey}
            </Text>
            <CopypableText
                text={otpKey}
                title="Копировать ключ"
                needIcon={true}
                additionalBlockStyles={[styles.copyBlock]}
                additionalTextStyles={[styles.copyText]}
            />
            <Separator />
            <Text style={[styles.text, { marginBottom: 12, marginTop: 23 }]}>
                Введите код из приложения-аутентификатора
            </Text>
            <PinInput
                onFill={(pin) => {
                    const currentToken = pin.join('');
                    setToken(currentToken);
                    dispatch(
                        enableOtp(
                            formId,
                            currentToken,
                            () => setIsEnableOtp(true),
                        ),
                    );
                }}
                onChange={() => dispatch(flushErrors())}
                errors={currentErrors.token}
            />
            {currentErrors.__all__ && <Errors errors={currentErrors.__all__}/>}
            <View style={styles.buttonBlock}>
                <AcceptButton
                    onSubmit={() => dispatch(
                        enableOtp(
                            formId,
                            currentToken,
                            () => setIsEnableOtp(true),
                        ),
                    )}
                    title="Подключить"
                    inProgress={enableOtpInProgress}/>
                <CancelButton onClose={onClose} disabled={enableOtpInProgress} />
            </View>
        </React.Fragment>}
        {isEnableOtp && <React.Fragment>
            <Text style={styles.title}>Приложение успешно подключено</Text>
            <Text style={styles.text}>
                Мы сгенерировали резервные коды, с помощью которых вы сможете войти в аккаунт без доступа к телефону
            </Text>
            <View style={styles.code}>
                <View style={styles.codesBlock}>
                    <View style={styles.codesBlockColumn}>
                        {otpCodes.slice(0, 6).map((code, i) => (
                            <Text style={styles.codesBlockText} key={`otp-spare-code-${i}`}>
                                {i + 1}) {code}
                            </Text>
                        ))}
                    </View>
                    <View style={[styles.codesBlockColumn, { marginLeft: 16 }]}>
                        {otpCodes.slice(6).map((code, j) => (
                            <Text style={styles.codesBlockText} key={`otp-spare-code-${j + 6}`}>
                                {j + 7}) {code}
                            </Text>
                        ))}
                    </View>
                </View>
                <View>
                    <CopypableText
                        text={otpCodes.join(', ')}
                        title="Копировать коды"
                        needIcon={true}
                        additionalBlockStyles={[styles.copyBlock,
                            { marginBottom: 4, backgroundColor: 'transparent' }
                        ]}
                        additionalTextStyles={[styles.copyText]}
                    />
                </View>
            </View>
            <TouchableOpacity onPress={() => createAndDownloadTxtFile(
                otpCodes.join('\n'),
                'codes.txt',
                () => onClose(),
            )}
            >
                <Text style={styles.downloadCodesText}>Скачать резервные коды</Text>
            </TouchableOpacity>
        </React.Fragment>}
    </View>;
}

EnableOtpModal.propTypes = {
    onClose: PropTypes.func,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 30,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20
    },
    title: {
        marginBottom: 16,
        color: '#455562',
        fontSize: 16,
        lineHeight: 19
    },
    text: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 16
    },
    code: {
        color: '#455562',
        fontSize: 10,
        lineHeight: 18,
        fontWeight: '500',
        marginTop: 16,
        marginBottom: 13,
        paddingTop: 12,
        paddingLeft: 12,
        paddingBottom: 12,
        backgroundColor: 'rgba(69, 85, 98, 0.05)',
    },
    buttonBlock: {
        flex: 1,
        marginTop: 32,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    copyBlock: {
        marginBottom: 24,
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        color: '#0F79D5',
        marginTop: 3,
    },
    copyText: {
        color: '#0f79d5',
        borderBottomStyle: 'none',
    },
    codesBlock: {
        flex: 1,
        marginBottom: 16,
        flexDirection: 'row'
    },
    codesBlockText: {
        textAlign: 'right',
        color: '#455562',
        fontSize: 10,
        fontWeight: 500,
        lineHeight: 18,
        letterSpacing: 0.7
    },
    downloadCodesText: {
        marginTop: 11,
        color: '#0f79d5',
        fontSize: 14,
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: 3,
    }
});

export default EnableOtpModal;
