import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    Image
} from 'react-native';

import {
    rightIcon
} from '../../../icons';
import { fetchUserVirtualhosts, restoreFromBackupUpdateParams } from '../../../actions/hosting/actions';

function RestoreBackupBlock() {
    const { containers } = useSelector((state) => state.hosting);
    const dispatch = useDispatch();
    const navigation = useNavigation();

    return (
        <View style={styles.wrapper}>
            <Text style={styles.title}>
                контейнеры хостинга
            </Text>
            {containers.entities.map((item) => (
                <View key={`hosting-backup-${item.login}`}>
                    <TouchableOpacity
                        style={styles.resourceBlock}
                        onPress={() => dispatch(
                            restoreFromBackupUpdateParams(
                                {
                                    currentContainer: item,
                                },
                                () => {
                                    navigation.navigate('HostingBackupResources', { containerId: item.id });
                                    dispatch(fetchUserVirtualhosts(item.id));
                                },
                            ),
                        )}
                    >
                        <View style={styles.resourceBlockTextBlock}>
                            <Text style={styles.resourceBlockTitle}>
                                {item.name}
                            </Text>
                            <Text style={styles.resourceBlockText}>
                                {item.login}
                            </Text>
                        </View>
                        <Image source={rightIcon} style={styles.image}/>
                    </TouchableOpacity>
                </View>
            ))}
        </View>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
    },
    title: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginBottom: 10,
        marginLeft: 20,
    },
    resourceBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        paddingLeft: 20,
        paddingBottom: 12,
        paddingTop: 12,
        paddingRight: 20,
        marginBottom: 1,
    },
    resourceBlockTitle: {
        marginBottom: 2,
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 16
    },
    resourceBlockText: {
        color: '#586A7B',
        fontSize: 11,
        lineHeight: 14
    },
    image: {
        width: 7,
        height: 13,
    }
});

export default RestoreBackupBlock;
