export const partnershipEntriesMap = {
    accrual: 'Начисления',
    withdrawal: 'Выплаты',
};

export const partnershipEntriesColorMap = {
    accrual: 'green',
    withdrawal: 'red'
};

export const partnershipWithdrawalTypes = {
    bank: 'Default',
    balance: 'Balance'
};

export const partnershipClientsColorMap = {
    deleted: 'red',
    hasresources: 'turquoise',
    hasnotresources: 'yellow'
};

export const partnershipClientsTypes = {
    deleted: 'Аккаунт отключен',
    hasresources: 'Услуги заказаны',
    hasnotresources: 'Без услуг'
};

export const userType = {
    private: 'Private',
    organization: 'Organization'
};

export const partnershipServiceMap = {
    Unknown: 'Неизвестная усглуга',
    Hosting: 'Виртуальный хостинг',
    CloudVds: 'Облачные VDS',
    CloudHosting: 'Хостинг',
    CloudMail: 'Почта',
    Sales365: 'Sales 365',
    DataStorage: 'Хранилище данных'
};

export const partnershipServiceColorMap = {
    Unknown: '#7F7F7F',
    Hosting: '#1d48ce',
    CloudVds: '#e05101',
    CloudHosting: '#00b2bc',
    CloudMail: '#e3b319',
    Sales365: '#43c645',
    DataStorage: '#1d48ce'
};

export const monthsDeclension = {
    январь: 'январе',
    февраль: 'феврале',
    март: 'марте',
    апрель: 'апреле',
    май: 'мае',
    июнь: 'июне',
    июль: 'июле',
    август: 'августе',
    сентябрь: 'сентябре',
    октябрь: 'октябре',
    ноябрь: 'ноябре',
    декабрь: 'декабре',
};
