import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';

import { getColorType } from '../../../utils/general';
import { ResourcePropType } from './types';
import { backgroundColorsMap } from './styles';

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    resourceStatistic: {
        height: 6,
        backgroundColor: '#000000'
    }
})

function getWidthFromResource(value, total) {
    return (value * 100) / total;
}

function getResourceColorType(resource, index) {
    return resource.colorType || getColorType(index);
}

function HorizontalBarChartStatistic(props) {
    const {
        total,
        current,
        resources,
        additionalStyles,
    } = props;

    return <View style={styles.wrapper}>
        {resources.map((resource, i) => {
            const resourceStyles = [
                styles.resourceStatistic,
                additionalStyles,
                { width: `${getWidthFromResource(resource.value, total)}%` },
            ];
            const colorType = getResourceColorType(resource, i);
            if (colorType.includes('#')) {
                resourceStyles.push({ backgroundColor: colorType });
            } else {
                resourceStyles.push(backgroundColorsMap[colorType]);
            }
            if (current && resource.id !== current) {
                resourceStyles.push(backgroundColorsMap.darkened);
            }
            return <View
                title={`${resource.title} ${resource.formatValue(resource.value)}`}
                key={`resource-statistic-${i}`}
                style={resourceStyles} />;
        })}
    </View>;
}

HorizontalBarChartStatistic.propTypes = {
    resources: PropTypes.arrayOf(ResourcePropType),
    current: PropTypes.string,
    total: PropTypes.number,
    additionalStyles: PropTypes.shape({}),
};

export default HorizontalBarChartStatistic;
