import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, } from 'react-native';

import Input from '../../General/Form/Input';
import { flushErrors } from '../../../actions/forms/actions';
import { containerRename } from '../../../actions/hosting/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'RenameConfirmForm';

function ContainerRename(props) {
    const {
        errors,
        onClose,
        inProgress,
        containerId,
        flushErrors,
        containerName,
        containerRename,
    } = props;

    React.useEffect(() => {
        flushErrors()
    }, []);
    const [newName, setModalNewName] = React.useState(containerName);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Изменение имени контейнера:</Text>
        <Input
            errors={errors && errors.errors && errors.errors.name}
            textInputAttrs={{
                autoFocus: true,
                numberOfLines: 1,
                value: newName,
                onChange: (e) => {
                    flushErrors();
                    setModalNewName(e.target.value)
                }
            }}
        />
        {errors.errors && errors.errors.__all__ && <Text style={styles.error}>{errors.errors.__all__}</Text>}
        <View style={styles.buttons}>
            <AcceptButton
                onSubmit={() => containerRename(
                    formId, containerId, { name: newName }, () => onClose()
                )}
                title='Сохранить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

ContainerRename.propTypes = {
    onClose: PropTypes.func,
    errors: PropTypes.shape({}),
    containerRename: PropTypes.func,
    flushErrors: PropTypes.func,
    containerId: PropTypes.number,
    containerName: PropTypes.string,
    inProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    inProgress: state.hosting.containerUpdateInProgress,
    errors: state.forms.errors[formId] || {},
    containerId: state.hosting.container.id,
    containerName: state.hosting.container.name,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    containerRename
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 44,
        paddingBottom: 42,
        paddingLeft: 40,
        paddingRight: 40,
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 10,
        color: '#455562',
    },
    buttons: {
        flex: 1,
        marginTop: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
    },
    input: {
        borderColor: '#dce2e9',
        borderWidth: 1,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
        marginBottom: 10,
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(ContainerRename);