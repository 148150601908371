import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-native';
import { useDispatch } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';

import { menuBackIcon } from '../../icons';

import {
    initEvents,
    fetchMonitoringEvents
} from '../../actions/monitoring/actions';
import { screenHeaderStyles } from '../../utils/styles';
import MonitoringScreen from '../../components/Monitoring/MonitoringScreen';
import ScreenHeaderTitle from '../../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const MonitoringStack = createStackNavigator();

function MonitoringStackScreen(props) {
    const { navigation } = props;
    const dispatch = useDispatch();
    const insets = useSafeAreaInsets();

    useFocusEffect(React.useCallback(() => {
        dispatch(fetchMonitoringEvents());
        return () => dispatch(initEvents());
    }, []));

    return <MonitoringStack.Navigator>
        <MonitoringStack.Screen name="MonitoringScreen" component={MonitoringScreen} options={{
            headerTitle: () => <ScreenHeaderTitle title='Мониторинг' />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => navigation.navigate('MainStack'),
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </MonitoringStack.Navigator>;
}

MonitoringStackScreen.propTypes = {
    navigation: PropTypes.object,
};

export default MonitoringStackScreen;
