import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text } from 'react-native';

import Separator from '../Separator/Separator';
import CreatePacketRow from './CreatePacketRow';
import CreatePacketDescription from './CreatePacketDescription';
import AcceptButton from '../Modal/AcceptButton';
import CancelButton from '../Modal/CancelButton';
import { formatPrice } from '../../../utils/general';

const styles = StyleSheet.create({
    packetInfo: {
        paddingLeft: 17,
        paddingRight: 23,
        backgroundColor: '#f4f4f4'
    },
    packetInfoTotal: {
        paddingTop: 14,
        paddingBottom: 12
    },
    warningText: {
        marginTop: 18,
        marginBottom: 27,
        color: '#E17334',
        fontSize: 13,
        fontWeight: '600',
        lineHeight: 20,
        textAlign: 'center'
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorBlock: {
        position: 'absolute',
        textAlign: 'center',
        bottom: 50,
        width: '100%'
    }
});

function CreatePacketModal(props) {
    const {
        descriptionParameters,
        priceForPacket,
        priceForPacketAdvantage,
        onAccept,
        onClose,
        errors,
        inProgress,
    } = props;
    return <View>
        <View style={styles.packetInfo}>
            <CreatePacketDescription parameters={descriptionParameters} />
            <Separator />
            <View style={styles.packetInfoTotal}>
                <CreatePacketRow title='Стоимость за год' type='total'
                    value={formatPrice(priceForPacket)} format={v => `${v} ₽`}/>
            </View>
        </View>
        <Text style={styles.warningText}>
            Вы cэкономите {formatPrice(priceForPacketAdvantage)} ₽ в год
        </Text>
        <View style={styles.errorBlock}>
            {errors.__all__ && <Errors errors={errors.__all__}/>}
        </View>
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => onAccept()}
                title='Подключить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

CreatePacketModal.propTypes = {
    errors: PropTypes.shape({}),
    priceForPacket: PropTypes.string,
    priceForPacketAdvantage: PropTypes.string,
    descriptionParameters: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        isChange: PropTypes.bool,
        format: PropTypes.func,
        title: PropTypes.string,
        isDisplayed: PropTypes.bool
    })),
    onClose: PropTypes.func,
    onAccept: PropTypes.func,
    inProgress: PropTypes.bool,
};

export default CreatePacketModal;
