const BaseResource = require('../resource');

class FinancesResource extends BaseResource {
    async couponsList() {
        return this.makeRequest('get', 'coupons/');
    }

    async couponActivate(token) {
        return this.makeRequest('post', 'coupons/', { token });
    }

    async awaitingPacketList() {
        return this.makeRequest('get', 'awaiting-packets/');
    }

    async awaitingPacketCancel(service, resourceId) {
        return this.makeRequest('delete', `awaiting-packets/${service}/${resourceId}/`);
    }

    async chargesMonthGroups(date) {
        return this.makeRequest('get', `charges/${date}/`);
    }

    async monthsForCharges() {
        return this.makeRequest('get', 'charges/months/');
    }

    async createPayment(paymentSystemType, amount) {
        return this.makeRequest('post', 'payments/create/', {
            gateway: paymentSystemType,
            amount
        });
    }

    async createInvoice(amount) {
        return this.makeRequest('post', 'invoices/', { amount });
    }

    async listOfInvoices() {
        return this.makeRequest('get', 'invoices/');
    }

    async deferredPaymentSet() {
        return this.makeRequest('put', 'deferred-payment/');
    }

    async downloadInvoice(id) {
        return this.makeRequest('get', `invoices/download/${id}/`);
    }

    async sendInvoiceToEmail(id, email) {
        return this.makeRequest('post', `invoices/${id}/to-email/`, { email });
    }

    async monthsForPayments() {
        return this.makeRequest('get', 'payments/months/');
    }

    async monthsForActs() {
        return this.makeRequest('get', 'documents/act/months/');
    }

    async expectedCharges() {
        return this.makeRequest('get', 'expected-charges/');
    }
}

FinancesResource.resource = null;
FinancesResource.service = 'finances';

module.exports = FinancesResource;
