import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { View, Text, StyleSheet } from 'react-native';

import Input from '../../General/Form/Input';
import { createDomain } from '../../../actions/mail/actions';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'CreateDomainModalForm';

function CreateDomainModal(props) {
    const {
        onClose,
    } = props;
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const { errors } = useSelector((state) => state.forms);
    const { createDomainInProgress } = useSelector((state) => state.mail);
    const currentErrors = errors[formId] || {};
    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Новый почтовый домен</Text>
        <Formik
            initialValues={{ name: '' }}
            onSubmit={(values) => {
                dispatch(createDomain(formId, values, (domain) => {
                    onClose();
                    navigation.navigate('MailDomain', {
                        domainId: domain.id,
                        domainName: domain.name
                    });
                }));
            }}>
            {({ handleChange, handleBlur, handleSubmit, values }) => (
                <View>
                    <Input
                        errors={currentErrors.name}
                        label='Доменное имя'
                        textInputAttrs={{
                            onChangeText: handleChange('name'),
                            onBlur: handleBlur('name'),
                            value: values.name
                        }}
                    />
                    <View style={styles.buttonBlock}>
                        <AcceptButton
                            onSubmit={handleSubmit}
                            title='Добавить'
                            inProgress={createDomainInProgress}/>
                        <CancelButton onClose={onClose} disabled={createDomainInProgress} />
                    </View>
                </View>
            )}
        </Formik>
    </View>;
}

CreateDomainModal.propTypes = {
    onClose: PropTypes.func,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        marginTop: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default CreateDomainModal;
