import {
    USER_AUTHORIZE_IN_PROGRESS,
    USER_AUTHORIZE_SUCCESSFULLY,
    USER_AUTHORIZE_WITH_ERRORS,
    USER_FINANCE_INFO_SUCCESSFULLY,
    USER_ACTIVE_SERVICES_FETCHED_IN_PROGRESS,
    USER_ACTIVE_SERVICES_FETCHED_SUCCESSFULLY,
    USER_ACTIVE_SERVICES_FETCHED_WITH_ERRORS,
    USER_LOGOUT,
    SET_AUTH_DATA,
    USER_FINANCE_INFO_WITH_ERRORS,
    USER_FINANCE_INFO_IN_PROGRESS,
    PASSWORD_RESTORE_IN_PROGRESS,
    PASSWORD_RESTORE_SUCCESSFULLY,
    PASSWORD_RESTORE_WITH_ERRORS,
    PASSWORD_RESET_IN_PROGRESS,
    PASSWORD_RESET_SUCCESSFULLY,
    PASSWORD_RESET_WITH_ERRORS,
} from "./actionTypes";
import { formErrors, flushErrors } from '../forms/actions';
import PanelResource from '../../gateway/resources/panel';
import { isFunction } from '../../utils/general';
import AccountResource from '../../gateway/resources/account';

export const authorizeUser = (formId, { login, password }, callback, failCallback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: USER_AUTHORIZE_IN_PROGRESS });
    const resource = new AccountResource(gateway);
    const response = await resource.authorize(login, password);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: USER_AUTHORIZE_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback(data.action);
        }
        dispatch(flushErrors());
    } else {
        dispatch({ type: USER_AUTHORIZE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
        if (response.status === 429 && isFunction(failCallback)) {
            failCallback();
        }
    }
};

export const authorizeOtp = (
    formId, { scopeId, token }, callback, failCallback,
) => async (dispatch, getState, { gateway }) => {
    const resource = new AccountResource(gateway);
    const response = await resource.authorizeOtp(scopeId, token);
    const data = await response.json();
    if (response.ok) {
        if (isFunction(callback)) {
            callback();
        }
        dispatch(flushErrors());
    } else {
        if (response.status === 404 && isFunction(failCallback)) {
            failCallback();
        }
        dispatch(formErrors(formId, data.errors));
    }
};

export const logout = (callback) => async (dispatch, getState, { gateway }) => {
    const resource = new AccountResource(gateway);
    const response = await resource.logout();
    if (response.ok) {
        if (isFunction(callback)) {
            callback();
        }
    }
};

export const financeInfo = (callback, deniedCallback, withTimeout = false) => async (dispatch, getState, { gateway }) => {
    const resource = new PanelResource(gateway);
    dispatch({
        type: USER_FINANCE_INFO_IN_PROGRESS
    });
    const response = await resource.financeInfo();
    if (withTimeout) {
        const sleepPromise = new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
        await sleepPromise;
    }
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: USER_FINANCE_INFO_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else if (response.status === 403) {
        dispatch({
            type: USER_FINANCE_INFO_WITH_ERRORS
        });
        if (isFunction(deniedCallback)) {
            deniedCallback();
        }
    }
};

export const setAuthData = (data, callback) => async (dispatch) => {
    dispatch({
        type: SET_AUTH_DATA,
        data
    });
    if (isFunction(callback)) {
        callback();
    }
};

export const userActiveServices = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: USER_ACTIVE_SERVICES_FETCHED_IN_PROGRESS });
    const resource = new PanelResource(gateway);
    const response = await resource.userActiveServices();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: USER_ACTIVE_SERVICES_FETCHED_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: USER_ACTIVE_SERVICES_FETCHED_WITH_ERRORS });
    }
};

export const passwordRestore = (
    formId, { domain, transport }, callback
) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: PASSWORD_RESTORE_IN_PROGRESS });
    const resource = new AccountResource(gateway);
    const response = await resource.passwordRestore(domain, transport);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: PASSWORD_RESTORE_SUCCESSFULLY,
            data: { transport },
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: PASSWORD_RESTORE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const passwordReset = (
    formId, { key, password }, authCallback, otpCallback,
) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: PASSWORD_RESET_IN_PROGRESS });
    const resource = new AccountResource(gateway);
    const response = await resource.passwordReset(key, password);
    const data = await response.json();
    if (response.ok) {
        dispatch({ type: PASSWORD_RESET_SUCCESSFULLY });
        if (data.action === 'auth') {
            authCallback();
        }
        if (data.action === 'otp') {
            otpCallback();
        }
    } else {
        dispatch({ type: PASSWORD_RESET_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};
