import React from 'react';
import PropTypes from 'prop-types';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity
} from 'react-native';
import { StyleProps } from '../../../types';

const styles = StyleSheet.create({
    radioButtonBlock: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 20,
    },
    radioButtonTitle: {
        fontSize: 13,
        lineHeight: 15,
        fontWeight: '400',
    },
    radioButtonSublabelText: {
        fontWeight: '400',
        color: '#586a7b',
        fontSize: 12,
        marginLeft: 4,
    },
    outerCircle: {
        height: 18,
        width: 18,
        marginRight: 9,
        borderTopWidth: 1,
        borderTopColor: 'rgba(161, 170, 177, 0.5)',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(161, 170, 177, 0.5)',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(161, 170, 177, 0.5)',
        borderRightWidth: 1,
        borderRightColor: 'rgba(161, 170, 177, 0.5)',
        borderRadius: 9,
        backgroundColor: '#f5f5f5'
    },
    activeOuterCircle: {
        height: 18,
        width: 18,
        marginRight: 9,
        borderTopWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderLeftWidth: 1,
        borderLeftColor: '#0f79d5',
        borderRightWidth: 1,
        borderRightColor: '#0f79d5',
        borderRadius: 9,
        backgroundColor: '#ffffff',
        paddingTop: 4,
        paddingLeft: 4
    },
    innerCircle: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0f79d5'
    },
    radioButtonBlockBorder: {
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderRadius: 4,
        borderColor: 'rgba(69, 85, 98, 0.25)',
    },
    radioButtonBlockBorderActive: {
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderRadius: 4,
        borderColor: '#0f79d5',
        backgroundColor: '#f5fafd',
    }
});

function RadioButton(props) {
    const {
        label,
        sublabel,
        children,
        isActive = false,
        onChange = () => {},
        needBorder = false,
        additionalLabelStyles = [],
        additionalWrapperStyles = [],
        additionalRadioButtonBlockStyles = [],
        labelWrapperStyle = []
    } = props;

    const radioButtonBlockStyles = [styles.radioButtonBlock, ...additionalRadioButtonBlockStyles];
    if (needBorder) {
        if (isActive) {
            radioButtonBlockStyles.push(styles.radioButtonBlockBorderActive);
        } else {
            radioButtonBlockStyles.push(styles.radioButtonBlockBorder);
        }
    }

    return <View style={[styles.wrapper, ...additionalWrapperStyles]}>
        <TouchableOpacity style={radioButtonBlockStyles}
            onPress={onChange}>
            <View style={isActive ? styles.activeOuterCircle : styles.outerCircle}>
                {isActive && <View style={styles.innerCircle}/>}
            </View>
            <View style={labelWrapperStyle}>
                <Text style={[
                    styles.radioButtonTitle,
                    { color: isActive ? '#0f79d5' : '#455562' },
                    ...additionalLabelStyles
                ]}>
                    {label}
                </Text>
                {sublabel && <Text style={styles.radioButtonSublabelText}>{sublabel}</Text>}
            </View>
            {children != null && children}
        </TouchableOpacity>
    </View>;
}

RadioButton.propTypes = {
    label: PropTypes.string,
    sublabel: PropTypes.string,
    isActive: PropTypes.bool,
    onChange: PropTypes.func,
    needBorder: PropTypes.bool,
    children: PropTypes.element,
    additionalWrapperStyles: StyleProps,
    additionalLabelStyles: StyleProps,
    additionalRadioButtonBlockStyles: StyleProps,
    labelWrapperStyle: StyleProps
};

export default RadioButton;
