import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

import { statisticServicesMapColor } from '../../constants';
import { backgroundColorsMap } from '../../../General/HorizontalBarChart/styles';

const styles = StyleSheet.create({
    statisticDescription: {
        marginRight: 24,
        flex: 1
    },
    statisticText: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14,
        marginBottom: 3
    },
    statisticResource: {
        opacity: 0.7,
        color: '#455562',
        fontSize: 11,
        lineHeight: 13
    },
    statisticWrapper: {
        paddingTop: 17,
        paddingLeft: 20,
        paddingRight: 18,
        paddingBottom: 12,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
        
    },
    service: {
        width: 8,
        height: 8,
        marginRight: 13
    },
    statisticTotal: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14
    }
})

function FinanceStatistic(props) {
    const { element } = props;
    const colorType = element.colorType || statisticServicesMapColor[element.service];
    const serviceStyles = [
        styles.service,
        backgroundColorsMap[colorType]
    ];
    return <View style={styles.statisticWrapper}>
        <View style={serviceStyles} />
        <View style={styles.statisticDescription}>
            <Text style={styles.statisticText}>
                {element.description}
            </Text>
            {element.resource_id && <Text style={styles.statisticResource}>
                {element.resource_id}
            </Text>}
        </View>
        <Text style={styles.statisticTotal}>&ndash; {element.total.toFixed(2)} ₽</Text>
    </View>;
}

FinanceStatistic.propTypes = {
    element: PropTypes.shape({
        description: PropTypes.string,
        service: PropTypes.string,
        total: PropTypes.number,
        resource_id: PropTypes.string
    })
};

export default FinanceStatistic;
