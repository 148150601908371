import React from 'react';
import * as dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    Linking,
} from 'react-native';

import Spring from '../../General/Spring/Spring';
import Button from '../../General/Button/Button';
import {
    calculateStoragePrice,
    calculatePrice,
    calculateDatastoragePrice,
} from '../utils';
import { isEmpty } from '../../../utils/general';
import tariffsData from '../../../../data/cvds/tariffs.json';
import { makeBackup, vmCreate } from '../../../actions/cvds/actions';

const formId = 'CVDSBackupFormId';

function getConfigurationByMethod({
    backupMethod,
    currentVM,
    backupDate,
    vmForBackup,
    resourceName,
    currentVolume,
    dispatch,
}) {
    let priceInfo = '';
    let methodInfo = '';
    let backupText = null;
    let createParams = null;
    let backupFunc = () => {};

    const formatDate = (date) => `${dayjs(date, 'YYYY-MM-DD HH:mm').format('DD MMMM, HH:mm')} (МСК+2)`;
    if (backupMethod.id === 'rollback') {
        backupText = (
            <React.Fragment>
                Откат состояния диска «{currentVolume.name}»{' '}
                {backupMethod.disk_size} ГБ
                <Text style={styles.infoBoldText}>
                    ВМ&nbsp;«{currentVM.name}» на{' '}
                    {backupDate ? formatDate(backupDate) : ''}
                </Text>
            </React.Fragment>
        );
        methodInfo = `Восстановление поверх текущего состояния. Все файлы и папки будут возвращены в состояние на выбранную дату восстановления`;
        if (currentVolume.size !== backupMethod.disk_size) {
            const price = calculateStoragePrice(
                currentVolume.type.toLowerCase(),
                backupMethod.disk_size,
                true,
                isEmpty(vmForBackup) ? currentVM.tariff : vmForBackup.tariff,
                true,
            );
            priceInfo = `Стоимость диска: ${price.toFixed(2)} ₽/мес`;
        }
        backupFunc = (formId, navigation) => dispatch(
            makeBackup(
                formId,
                backupMethod.resource_url,
                () => navigation.navigate('CVDSVM', { vmId: currentVM.id }),
            ),
        );
    }
    if (backupMethod.id === 'create_vm') {
        backupText = (
            <React.Fragment>
                Восстановление
                <Text style={styles.infoBoldText}>
                    ВМ&nbsp;«{currentVM.name}» на{' '}
                    {backupDate ? formatDate(backupDate) : ''}
                </Text>
                &nbsp;в новую виртуальную машину
            </React.Fragment>
        );
        const tariff = tariffsData.find(
            (cvdsTariff) => cvdsTariff.id === backupMethod.tariff,
        );
        methodInfo = `Будет создана новая виртуальная машина с тарифом ${tariff.name} и диском ${backupMethod.disk_size} ГБ, в которую восстановится выбранная резервная копия`;
        const price = calculatePrice(
            tariff.id,
            tariff.type,
            backupMethod.disk_size,
            true,
        );
        priceInfo = `Стоимость ВМ: ${price.toFixed(2)} ₽/мес`;
        createParams = {
            disk_size: backupMethod.disk_size,
            resource_name: resourceName,
            backup_date: backupDate,
            tariff: backupMethod.tariff,
        };
        backupFunc = (formId) => dispatch(vmCreate(formId, createParams, (vm) => navigation.navigate('CVDSVM', { vmId: vm.id })));
    }
    // if (backupMethod.id === 'create_volume') {
    //     backupText = (
    //         <React.Fragment>
    //             Восстановление копии диска «{currentVolume.name}»{' '}
    //             {backupMethod.disk_size} ГБ
    //             <div className="cvds-backup-final-method-text__bold">
    //                 ВМ&nbsp;«{currentVM.name}» на{' '}
    //                 {backupDate ? `${formatDate(backupDate)} ` : ''}в{' '}
    //                 {isEmpty(vmForBackup)
    //                     ? ' текущую виртуальную машину'
    //                     : `ВМ «${vmForBackup.name}»`}
    //             </div>
    //         </React.Fragment>
    //     );
    //     methodInfo = `Для ВМ «${currentVM.name}» будет создан новый диск объемом
    //         ${currentVolume.size} ГБ, в который восстановится выбранная резервная копия`;
    //     const price = calculateStoragePrice(
    //         currentVolume.type.toLowerCase(),
    //         backupMethod.disk_size,
    //         true,
    //         isEmpty(vmForBackup) ? currentVM.tariff : vmForBackup.tariff,
    //         true,
    //     );
    //     priceInfo = `Стоимость диска: ${price.toFixed(2)} ₽/мес`;
    //     createParams = {
    //         vmId: isEmpty(vmForBackup) ? currentVM.id : vmForBackup.id,
    //         size: backupMethod.disk_size,
    //         resourceName,
    //         backupDate,
    //     };
    //     backupFunc = (formId) =>
    //         dispatch(vmVolumeAddFromBackup(formId, createParams));
    // }
    if (backupMethod.id === 'move_backup') {
        backupText = (
            <React.Fragment>
                Резервная копия диска «{currentVolume.name}»{' '}
                {currentVolume.size} ГБ
                <Text style={styles.infoBoldText}>
                    ВМ&nbsp;«{currentVM.name}» на{' '}
                    {backupDate ? formatDate(backupDate) : ''}
                </Text>{' '}
                будет сохранена в файловом хранилище
            </React.Fragment>
        );
        methodInfo = (
            <React.Fragment>
                Вам будет подключена услуга
                <Text style={styles.linkButton}
                    onPress={() => Linking.openURL('https://panel.netangels.ru/datastorage/account/')}>
                    «Файловое хранилище»
                </Text>
            </React.Fragment>
        );
        priceInfo = `Стоимость услуги: ${calculateDatastoragePrice().toFixed(2)} ₽/ГБ в мес`;
        backupFunc = (formId, navigation) => dispatch(
            makeBackup(
                formId,
                backupMethod.resource_url,
                () => {
                    if (currentVM.deleted) {
                        navigation.navigate('CVDS');
                    } else {
                        navigation.navigate('CVDSVM', { vmId: currentVM.id });
                    }
                },
            )
        );
    }
    return {
        priceInfo,
        backupFunc,
        methodInfo,
        backupText,
        backupTitle:
            backupMethod.id !== 'move_backup'
                ? 'Параметры восстановления'
                : 'Сохранение копии в файловое хранилище',
    };
}

function RestoreConfigurationStep() {
    const {
        restoreInProgress,
        backupMethod,
        currentVM,
        backupDate,
        vmForBackup,
        resourceName,
        currentVolume,
    } = useSelector(
        (state) => state.cvdsRestoreFromBackup,
    );
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const configurationInfo = getConfigurationByMethod({
        backupMethod,
        currentVM,
        backupDate,
        vmForBackup,
        resourceName,
        currentVolume,
        dispatch,
    });
    return <View style={styles.wrapper}>
        <Text style={styles.title}>
            {configurationInfo.backupTitle}
        </Text>
        <View style={styles.infoBlock}>
            <Text style={styles.infoText}>
                {configurationInfo.backupText}
            </Text>
        </View>
        <View style={backupMethod ? styles.dbmsInfoBlock : styles.infoBlock}>
            <Text style={backupMethod ? styles.dbmsInfoText : styles.infoText}>
                {configurationInfo.methodInfo}
            </Text>
            <Text style={styles.infoPrice}>
                {configurationInfo.priceInfo}
            </Text>
        </View>
        <Spring />
        <Button
            onPress={() => configurationInfo.backupFunc(formId, navigation)}
            title="Начать восстановление"
            inProgress={restoreInProgress}
            additionalWrapperStyles={[{ marginLeft: 20, marginRight: 20, marginBottom: 30 }]}
            additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 10 }]}
            additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, fontWeight: '400' }]}
        />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
    },
    title: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginBottom: 10,
        marginLeft: 20,
    },
    infoBlock: {
        paddingBottom: 16,
        paddingTop: 16,
        paddingLeft: 23,
        paddingRight: 29,
        backgroundColor: '#ffffff'
    },
    dbmsInfoBlock: {
        paddingBottom: 16,
        paddingTop: 0,
        paddingLeft: 23,
        paddingRight: 29,
        backgroundColor: '#ffffff',
        borderTopColor: 'rgba(69, 85, 98, 0.25)',
        borderTopWidth: 2,
    },
    infoText: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 18,
    },
    dbmsInfoText: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 18,
        marginBottom: 8,
        marginTop: 16,
    },
    infoBoldText: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 18,
        fontWeight: 500,
        marginLeft: 3,
        marginRight: 3,
    },
    infoPrice: {
        marginTop: 4,
        color: '#455562',
        fontSize: 12,
        lineHeight: 16,
        fontWeight: 500,
    },
    linkButton: {
        color: '#0f79d5',
        fontSize: 12,
        lineHeight: 18,
        marginLeft: 3,
    }
});

export default RestoreConfigurationStep;
