import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import VolumeEditPage from '../components/CVDS/VolumeEditPage';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const CVDSVMEditVolumeStack = createStackNavigator();

function CVDSVMEditVolumeStackScreen(props) {
    const { currentVolume, navigation } = props;
    const insets = useSafeAreaInsets();

    return <CVDSVMEditVolumeStack.Navigator>
        <CVDSVMEditVolumeStack.Screen name="CVDSVMVolumeEdit" component={VolumeEditPage} options={{
            headerTitle: () => <ScreenHeaderTitle
                title={currentVolume.name || currentVolume.uid} />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => navigation.goBack(),
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </CVDSVMEditVolumeStack.Navigator>;
}

CVDSVMEditVolumeStackScreen.propTypes = {
    currentVolume: PropTypes.shape({})
};

const mapStateToProps = (state) => ({
    currentVolume: state.cvds.currentVolume
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CVDSVMEditVolumeStackScreen);
