import { DynamicComponentGenerator } from './DynamicComponentGenerator';
import MonitoringEventLink from '../components/Monitoring/MonitoringEventLink';
import MonitoringEventText from '../components/Monitoring/MonitoringEventText';
import MonitoringEventProgressBar from '../components/Monitoring/MonitoringEventProgressBar';
import MonitoringEventMultipleLink from '../components/Monitoring/MonitoringEventMultipleLink';

export const dynamicComponentGenerator = new DynamicComponentGenerator();

dynamicComponentGenerator.register(MonitoringEventText);
dynamicComponentGenerator.register(MonitoringEventLink);
dynamicComponentGenerator.register(MonitoringEventProgressBar);
dynamicComponentGenerator.register(MonitoringEventMultipleLink);
