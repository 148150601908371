import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text } from 'react-native';

import AcceptButton from '../Modal/AcceptButton';
import CancelButton from '../Modal/CancelButton';
import DynamicallySelectedPicker from '../DynamicallySelectedPicker/DynamicallySelectedPicker';
import { isFunction } from '../../../utils/general';

function WebSelectModal(props) {
    const {
        onClose,
        onChange,
        itemList,
        selectTitle,
        currentItem,
    } = props;

    const [selectedValue, setSelectedValue] = React.useState(currentItem);
    const initialIndex = itemList.findIndex(item => item.value === currentItem.value);
    return <View style={[styles.wrapper]}>
        {selectTitle && <Text style={[styles.title]}>{selectTitle}</Text>}
        <View style={styles.select}>
            <DynamicallySelectedPicker
                items={itemList}
                initialSelectedIndex={initialIndex}
                onScroll={(selected) => setSelectedValue(selected.item)}/>
        </View>
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => {
                    onChange(selectedValue);
                    if (isFunction(selectedValue.onSelected)) {
                        selectedValue.onSelected();
                    }
                    onClose();
                }}
                title="Выбрать"/>
            <CancelButton onClose={onClose} />
        </View>
    </View>;
}

WebSelectModal.propTypes = {
    onClose: PropTypes.func,
    onChange: PropTypes.func,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    select: {
        width: '100%',
    }
})

export default WebSelectModal;