import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';

import Errors from '../../General/Form/Errors';
import { flushErrors } from '../../../actions/forms/actions';
import { startTransfer } from '../../../actions/transfer/actions';
import { resourceTypesList } from '../../../constants/general';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';
import Input from '../../General/Form/Input';

const formId = 'StartTransferFormId';
const checkResourcesFormId = 'CheckResourcesFormId';

function normalizedResources({
    instance,
    checkedDNSZones,
    checkedSSL,
    checkedDomains,
    service
}) {
    const finalResources = [
        {
            resource_id: instance.id,
            resource_type: resourceTypesList.find(
                (rt) => rt.service === service,
            ).resource_type,
        },
    ];
    if (checkedDNSZones.length !== 0) {
        checkedDNSZones.forEach((zone) => finalResources.push({
            resource_id: zone.id,
            resource_type: resourceTypesList.find(
                (rt) => rt.service === 'zones',
            ).resource_type,
        }));
    }
    if (checkedSSL.length !== 0) {
        checkedSSL.forEach((ssl) => finalResources.push({
            resource_id: ssl.id,
            resource_type: resourceTypesList.find(
                (rt) => rt.service === 'certificates',
            ).resource_type,
        }));
    }
    if (checkedDomains.length !== 0) {
        checkedDomains.forEach((domain) => finalResources.push({
            resource_id: domain.id,
            resource_type: resourceTypesList.find(
                (rt) => rt.service === 'domains',
            ).resource_type,
        }));
    }
    return finalResources;
}

function StartTransferModal(props) {
    const {
        onClose,
        service,
        instance,
    } = props;
    const {
        checkedSSL,
        checkedDomains,
        checkedDNSZones,
        transferStartInProgress
    } = useSelector(
        (state) => state.transfer,
    );
    const { errors } = useSelector((state) => state.forms);
    const [acceptLogin, setAcceptLogin] = useState('');

    const currentErrors = errors[formId] || {};
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);

    return <View style={styles.wrapper}>
        <Text style={styles.title}>Логин принимающего аккаунта</Text>
        <Input
            errors={currentErrors.recipient}
            textInputAttrs={{
                autoFocus: true,
                numberOfLines: 1,
                placeholder: 'uXXXXXX',
                value: acceptLogin,
                onChange: (e) => setAcceptLogin(e.target.value)
            }}
        />
        {currentErrors.__all__ && <Errors errors={currentErrors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => dispatch(
                    startTransfer(
                        formId,
                        {
                            recipient: acceptLogin,
                            resources: normalizedResources({
                                instance,
                                checkedDNSZones,
                                checkedSSL,
                                checkedDomains,
                                service
                            }),
                        },
                        checkResourcesFormId,
                        () => onClose()
                    ),
                )}
                title="Передать"
                disabled={acceptLogin.trim().length === 0}
                inProgress={transferStartInProgress}/>
            <CancelButton onClose={onClose} disabled={transferStartInProgress} />
        </View>
    </View>;
}

StartTransferModal.propTypes = {
    onClose: PropTypes.func,
    instance: PropTypes.shape({}),
    service: PropTypes.string,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 30,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20
    },
    title: {
        marginBottom: 22,
        color: '#455562',
        fontSize: 16,
        lineHeight: 19
    },
    buttonBlock: {
        flex: 1,
        marginTop: 44,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default StartTransferModal;