import React from 'react';
import PropTypes from 'prop-types';
import {
    TouchableOpacity, View, StyleSheet, Image, Text
} from 'react-native';

import { rightIcon, trashIcon } from '../../icons';

import gpuTypes from '../../../data/cvds/gpu-types.json';

const styles = StyleSheet.create({
    container: {},
    header: {
        flex: 1,
        marginTop: 20,
        marginLeft: 16,
        marginRight: 20,
        marginBottom: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headerTitle: {
        color: '#455562',
        fontSize: 14,
        fontWeight: '600',
        lineHeight: 18
    },
    rightIcon: {
        height: 13,
        width: 7
    },
    gpu: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        paddingTop: 18,
        paddingLeft: 16,
        paddingRight: 19,
        paddingBottom: 20,
    },
    gpuTitle: {
        flex: 1,
        fontSize: 12,
        lineHeight: 14,
        fontWeight: '600',
        color: '#455562',
    },
    trashIcon: {
        height: 12,
        width: 12,
    }
});

function GPUBlock(props) {
    const { vm } = props;

    const currentGpu = vm.gpu && vm.gpu.type && gpuTypes.find((gpu) => gpu.id === vm.gpu.type);

    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={() => {}}>
                <View style={styles.header}>
                    <Text style={styles.headerTitle}>Видеокарта</Text>
                    {/* <Image source={rightIcon} style={styles.rightIcon} /> */}
                </View>
            </TouchableOpacity>
            {currentGpu && (
                <View style={styles.gpu}>
                    <Text style={styles.gpuTitle}>
                        {vm.gpu.count}&nbsp;x&nbsp;{currentGpu.name}&nbsp;
                        {currentGpu.features.memory / 1024}&nbsp;GB
                    </Text>
                    {/* <TouchableOpacity>
                        <Image source={trashIcon} style={styles.trashIcon} />
                    </TouchableOpacity> */}
                </View>
            )}
        </View>
    );
}

GPUBlock.propTypes = {
    vm: PropTypes.shape({
        gpu: PropTypes.shape({
            type: PropTypes.string,
            count: PropTypes.number,
        }),
    }),
};

export default GPUBlock;
