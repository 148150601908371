import React from 'react';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';

import { detectTariffByRam } from '../utils';
import { rightArrowLightIcon } from '../../../icons/index';

function HostingTariffBlock(props) {
    const { container } = props;
    const navigation = useNavigation();
    const currentTariff = detectTariffByRam(container.memory_limit)
    return <TouchableOpacity style={styles.tariffBlock}
        onPress={() => {
            if (container && container.enabled) {
                navigation.navigate('HostingChangeTariff');
            }}
        }>
            <View style={styles.tariffWrapper}>
                <Text style={styles.tariffTitle}>
                    Тариф &laquo;{currentTariff.title}&raquo;
                </Text>
                <Text style={styles.tariffDescription}>
                    RAM {currentTariff.ram} МБ, CP {currentTariff.cpu}
                </Text>
            </View>
            {container && container.enabled && <Image source={rightArrowLightIcon} style={styles.icon} />}
    </TouchableOpacity>;
}


HostingTariffBlock.propTypes = {
    container: PropTypes.shape({
        memory_limit: PropTypes.number,
    }),
};

const styles = StyleSheet.create({
    tariffBlock: {
        paddingTop: 17,
        paddingBottom: 17,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: '#ffffff',
        marginBottom: 10,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 80,
    },
    tariffTitle: {
        color: '#455562',
        fontSize: 14,
        letterSpacing: 0,
        lineHeight: 16,
        fontWeight: '500',
        marginBottom: 3,
    },
    tariffDescription: {
        opacity: 0.7,
        color: '#455562',
        fontSize: 10,
        letterSpacing: 0,
        lineHeight: 16,
    },
    icon: {
        width: 7,
        height: 13,
    },
    tariffWrapper: {
        flex: 1,
    }
})

export default HostingTariffBlock;