import React, { useContext } from 'react';
import PropTypes, { string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';

import Modal from '../General/Modal/Modal';
import {
    trashIcon,
    returnIcon,
    ftpEmptyIcon,
    passwordBlueIcon,
    returnDisabledIcon,
    actionReverseGrayIcon,
} from '../../icons/index';
import Action from '../General/Actions/Action';
import Actions from '../General/Actions/Actions';
import FTPDelete from './HostingModal/FTPDelete';
import { DISABLED_BY_SERVICE } from '../../constants/general';
import FTPChangePassword from './HostingModal/FTPChangePassword';
import { fetchVirtualhostFTPs } from '../../actions/hosting/actions';
import FTPAdd from './HostingModal/FTPAdd';
import { ModalContext } from '../../contexts/ModalContext';

function VirtualhostFileTab(props) {
    const {
        ftps,
        virtualhost,
        ftpsFetchInProgress,
        fetchVirtualhostFTPs,
    } = props;
    useFocusEffect(React.useCallback(() => {
        if (!ftpsFetchInProgress && ftps == null && virtualhost !== null) {
            fetchVirtualhostFTPs(virtualhost.id);
        }
        return () => {};
    }));
    const navigation = useNavigation();
    const isEnabled = virtualhost && virtualhost.enabled;
    const enabledTextModifier = isEnabled
        ? [styles.actionText, styles.blueText]
        : [styles.actionDisabledText];
    const [isModalDeleteFTP, setModalDeleteFTP] = React.useState(false);
    const [isModalAddFTP, setModalAddFTP] = React.useState(false);
    const [isModalChangeFTPPassword, setModalChangeFTPPasswordVisible] = React.useState(false);
    const { closeModal } = useContext(ModalContext);
    return <React.Fragment>
        <View style={styles.actionWrapper}>
            <TouchableOpacity style={isEnabled && virtualhost.state !== DISABLED_BY_SERVICE
                ? [styles.action] : [styles.actionDisabled]}
            onPress={() => {
                if (isEnabled && virtualhost.state !== DISABLED_BY_SERVICE) {
                    navigation.navigate(
                        'HostingBackupVirtualhost',
                        {
                            containerId: virtualhost.container.id,
                            virtualhost: virtualhost.name,
                        }
                    );
                }
            }}>
                <Image source={isEnabled && virtualhost.state !== DISABLED_BY_SERVICE
                    ? returnIcon : returnDisabledIcon} style={styles.returnIcon} />
                <Text style={enabledTextModifier}>Восстановить содержимое</Text>
            </TouchableOpacity>
        </View>
        <View style={styles.wrapper}>
            {virtualhost && ftps != null && ftps.count !== 0 && <View>
                <Text style={styles.title}>FTP-доступ к сайту</Text>
                {ftps.entities.map((item, i) => <View key={`${virtualhost.id}-ftp-${i}`} style={styles.ftp}>
                    <Text style={styles.ftpText}>{item.login}</Text>
                    <Actions modalId='virtualhost-ftp-actions' icon={actionReverseGrayIcon}
                        iconWidth={4} iconHeight={16}
                        additionalButtonStyles={[styles.actions]}>
                        <Action icon={passwordBlueIcon} title='Сменить пароль'
                            enabled={true}
                            onPress={() => {
                                closeModal();
                                setModalChangeFTPPasswordVisible(true);
                            }}/>
                        <Action icon={trashIcon}
                            textStyles={[{ color: '#D51010' }]} title='Удалить FTP'
                            onPress={() => {
                                closeModal();
                                setModalDeleteFTP(true);
                            }}/>
                    </Actions>
                    <Modal isModalShowed={isModalChangeFTPPassword}
                        component={<FTPChangePassword
                            ftp={item}
                            onClose={() => setModalChangeFTPPasswordVisible(false)}/>}
                        onClose={() => setModalChangeFTPPasswordVisible(false)} />
                    <Modal isModalShowed={isModalDeleteFTP}
                        component={<FTPDelete ftp={item}
                            onClose={() => setModalDeleteFTP(false)}/>}
                        onClose={() => setModalDeleteFTP(false)} />
                </View>)}
                <TouchableOpacity onPress={() => {
                    if (virtualhost && virtualhost.enabled) {
                        setModalAddFTP(true);
                    }
                }}
                style={virtualhost && virtualhost.enabled ? styles.button : styles.disableButton }>
                    <Text style={virtualhost && virtualhost.enabled
                        ? styles.buttonText : styles.disableButtonText }>
                        Добавить FTP-аккаунт
                    </Text>
                </TouchableOpacity>
            </View>}
            {virtualhost && ftps != null && ftps.count === 0 && <View style={styles.ftpEmpty}>
                <Text style={styles.title}>FTP-доступ к сайту</Text>
                <Image source={ftpEmptyIcon} style={styles.image}/>
                <Text style={styles.description}>Создавайте любое количество FTP-аккаунтов
                    для доступа к файлам сайта
                </Text>
                <TouchableOpacity onPress={() => {
                    if (virtualhost && virtualhost.enabled) {
                        setModalAddFTP(true);
                    }
                }} style={virtualhost && virtualhost.enabled
                    ? styles.buttonFTPEmpty : styles.disableEmptyButton }>
                    <Text style={virtualhost && virtualhost.enabled
                        ? styles.buttonWhiteText
                        : styles.disableButtonText
                    }>
                        Создать FTP-аккаунт
                    </Text>
                </TouchableOpacity>
            </View>}
            <Modal isModalShowed={isModalAddFTP}
                component={<FTPAdd
                    virtualhostName={virtualhost && virtualhost.name}
                    onClose={() => setModalAddFTP(false)}/>}
                onClose={() => setModalAddFTP(false)} />
        </View>
    </React.Fragment>;
}

VirtualhostFileTab.propTypes = {
    ftpsFetchInProgress: PropTypes.bool,
    ftps: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.shape()]),
    virtualhost: PropTypes.shape({
        id: PropTypes.number,
        enabled: PropTypes.bool,
        name: PropTypes.strring,
        state: PropTypes.strring,
    }),
    fetchVirtualhostFTPs: PropTypes.func,
};

const mapStateToProps = (state) => ({
    ftps: state.hosting.ftps,
    virtualhost: state.hosting.virtualhost,
    ftpsFetchInProgress: state.hosting.ftpsFetchInProgress,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchVirtualhostFTPs
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: '#ffffff',
    },
    title: {
        width: '100%',
        opacity: '0.75',
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: '1.38px',
        lineHeight: 13,
        textTransform: 'uppercase',
        paddingTop: 21,
        paddingBottom: 21,
        paddingRight: 18,
        paddingLeft: 18,
        borderBottomColor: '#E4E4E4',
        borderBottomWidth: 1,
    },
    button: {
        paddingTop: 21,
        paddingBottom: 21,
        paddingRight: 18,
        paddingLeft: 18,
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
    },
    buttonFTPEmpty: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 25,
        paddingLeft: 25,
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
        marginBottom: 20,
        backgroundColor: '#0F79D5',
        borderRadius: 5,
    },
    buttonWhiteText: {
        color: '#ffffff',
    },
    buttonText: {
        color: '#0F79D5',
    },
    disableButton: {
        paddingTop: 21,
        paddingBottom: 21,
        paddingRight: 18,
        paddingLeft: 18,
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
        opacity: '0.5',
        backgroundColor: '#888888'
    },
    disableEmptyButton: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 25,
        paddingLeft: 25,
        fontSize: 13,
        opacity: '0.5',
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
        marginBottom: 20,
        backgroundColor: '#888888',
        borderRadius: 5,
    },
    disableButtonText: {
        color: '#ffffff',
    },
    ftp: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomColor: '#E4E4E4',
        borderBottomWidth: 1,
    },
    ftpText: {
        flex: 1,
        color: '#455562',
        fontSize: 13,
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: 18,
        paddingLeft: 18,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    closed: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: 18,
        paddingLeft: 18,
    },
    closedIcon: {
        height: 10,
        width: 10,
    },
    actions: {
        borderWidth: 0,
        paddingRight: 18,
        paddingLeft: 18,
        backgroundColor: 'transparent',
    },
    image: {
        minHeight: 138,
        width: 245,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 15,
        marginTop: 15,
    },
    ftpEmpty: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
    },
    description: {
        textAlign: 'center',
        paddingTop: 21,
        paddingBottom: 21,
        paddingRight: 18,
        paddingLeft: 18,
    },
    actionWrapper: {
        marginBottom: 10,
    },
    action: {
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 20,
        paddingBottom: 19,
        paddingLeft: 20,
        paddingRight: 20,
        borderBottomColor: '#E4E4E4',
        borderBottomWidth: 1,
        backgroundColor: '#FFFFFF',
    },
    actionDisabled: {
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 20,
        paddingBottom: 23,
        paddingRight: 20,
        backgroundColor: 'rgba(136, 136, 136, 0.2)',
        paddingLeft: 20,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(69, 85, 98, 0.2)'
    },
    actionText: {
        fontSize: 14,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 14,
    },
    actionDisabledText: {
        fontSize: 14,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 14,
        color: '#455562',
    },
    blueText: {
        color: '#0F79D5',
    },
    returnIcon: {
        width: 16,
        height: 16,
        marginRight: 18,
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(VirtualhostFileTab);