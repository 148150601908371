import React from 'react';
import {
    View,
    StyleSheet,
    Text,
} from 'react-native';
import PropTypes from 'prop-types';

function ScreenHeaderTitle(props) {
    const { title } = props;

    return <View style={styles.wrapper}>
        <Text style={styles.title}>{title}</Text>
    </View>;
}

ScreenHeaderTitle.propTypes = {
    title: PropTypes.string,
};

const styles = StyleSheet.create({
    title: {
        color: '#455562',
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 20,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

export default ScreenHeaderTitle;
