import React from 'react';
import * as dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
} from 'react-native';

import Button from '../../General/Button/Button';
import Spring from '../../General/Spring/Spring';
import RestoreEmptyStep from './RestoreEmptyStep';
import RadioButton from '../../General/Form/RadioButton';
import BackupDateSelect from '../../General/BackupDateSelect/BackupDateSelect';
import { restoreFromBackupUpdateParams } from '../../../actions/hosting/actions';

function RestoreDbmsStep() {
    const {
        restoreDbmsDate,
        currentDbms,
        currentMethod,
        newDbms,
        currentDbmsMethod,
    } = useSelector((state) => state.hostingRestoreFromBackup);
    const {
        dbms,
        dbmsBackups,
    } = useSelector((state) => state.hosting);
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const filteredDbms = dbms && dbms.entities.filter(
        (db) => db.id !== currentDbms.id,
    );
    return <React.Fragment>
        {dbmsBackups && dbmsBackups.entities.length === 0 && (
            <RestoreEmptyStep
                description='Вероятно, база данных создана недавно и у неё пока отсутствуют доступные точки восстановления'
                button={currentMethod && <Button
                    onPress={() => navigation.navigate('HostingBackupConfiguration')}
                    title="Продолжить"
                    additionalWrapperStyles={[{
                        marginLeft: 20, marginRight: 20, marginBottom: 30
                    }]}
                    additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 10 }]}
                    additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, fontWeight: '400' }]}
                />}
            />
        )}
        {dbmsBackups && dbmsBackups.entities.length !== 0 && <View style={styles.wrapper}>
            <View>
                <Text style={styles.title}>
                    точка восстановления базы данных (МСК+2)
                </Text>
                <View style={styles.dateSelectBlock}>
                    <BackupDateSelect
                        currentDate={restoreDbmsDate}
                        onChange={(value) => dispatch(
                            restoreFromBackupUpdateParams({
                                restoreDbmsDate: value.date,
                            }),
                        )}
                        points={dbmsBackups.entities}
                        dateFieldName="date"
                    />
                </View>
            </View>
            <Text style={styles.title}>
                способ восстановления
            </Text>
            <RadioButton
                additionalWrapperStyles={[{ marginBottom: 1 }]}
                onChange={() => dispatch(
                    restoreFromBackupUpdateParams(
                        {
                            currentDbmsMethod: 'rewrite',
                            newDbms: null,
                        },
                    ),
                )}
                isActive={currentDbmsMethod === 'rewrite'}
                label="Восстановить поверх"
            />
            {filteredDbms.length > 0 && <RadioButton
                additionalWrapperStyles={[{ marginBottom: 30 }]}
                onChange={() => dispatch(
                    restoreFromBackupUpdateParams(
                        {
                            currentDbmsMethod: 'restoreToDbms',
                            newDbms: filteredDbms[0],
                        },
                    ),
                )}
                isActive={currentDbmsMethod === 'restoreToDbms'}
                label="Восстановить в другую базу данных"
            />}
            {currentDbmsMethod === 'restoreToDbms' && <React.Fragment>
                <Text style={styles.title}>
                    в какую базу данных восстановить?
                </Text>
                {filteredDbms && filteredDbms.map((db, index) => <RadioButton
                    key={`restore-hosting-db-${index}`}
                    additionalWrapperStyles={[{ marginBottom: 1 }]}
                    onChange={() => dispatch(
                        restoreFromBackupUpdateParams(
                            {
                                newDbms: db,
                                currentDbmsMethod: 'restoreToDbms',
                            },
                        ),
                    )}
                    isActive={newDbms && newDbms.id === db.id}
                    label={db.name}
                />)}
            </React.Fragment>}
            <Spring />
            <Button
                onPress={() => navigation.navigate('HostingBackupConfiguration')}
                title="Продолжить"
                additionalWrapperStyles={[{ marginLeft: 20, marginRight: 20, marginBottom: 30 }]}
                additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 10 }]}
                additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, fontWeight: '400' }]}
            />
        </View>}
    </React.Fragment>;
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
    },
    title: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginBottom: 10,
        marginLeft: 20,
    },
    dateSelectBlock: {
        marginBottom: 30,
        marginLeft: 20,
    },
});

export default RestoreDbmsStep;
