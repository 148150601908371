import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

import { partnershipEntriesColorMap } from '../../../constants/partnership';
import { backgroundColorsMap } from '../../General/HorizontalBarChart/styles';

function entryPriceComponent(element) {
    return element.kind === 'Accrual'
        ? <Text style={[styles.statisticTotal,
            { color: element.amount >= 0 ? '#11ab70' : '#455562' }]}>
            {element.amount >= 0 ? '+' : '–'} {Math.abs(element.amount.toFixed(2))} ₽
        </Text>
        : <Text style={styles.statisticTotal}>
            – {Math.abs(element.amount).toFixed(2)} ₽
        </Text>;
}

function PartnershipEntriesBlock(props) {
    const { element } = props;
    const colorType = element.colorType || partnershipEntriesColorMap[element.kind.toLowerCase()];
    const serviceStyles = [styles.service, backgroundColorsMap[colorType]];
    let title = '';
    if (element.kind === 'Withdrawal') {
        title = element.withdrawal_type === 'Balance' ? 'Вывод средств на баланс пользователя'
            : 'Вывод средств на банковский счет';
    }
    if (element.kind === 'Accrual') {
        title = element.client.name;
    }
    return <View style={styles.statisticWrapper}>
        <View style={serviceStyles} />
        <View style={styles.statisticDescription}>
            <Text style={styles.statisticText}>
                {title}
            </Text>
            {element.client && element.client.login && <Text style={styles.statisticResource}>
                {element.client.login}, добавлен {dayjs(element.client.created,
                    'YYYY-MM-DD').format('DD.MM.YYYY')}
            </Text>}
        </View>
        {entryPriceComponent(element)}
    </View>;
}

PartnershipEntriesBlock.propTypes = {
    element: PropTypes.shape({
        added: PropTypes.string,
        amount: PropTypes.number,
        kind: PropTypes.string,
        client: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            login: PropTypes.string,
            created: PropTypes.string
        }),
        colorType: PropTypes.string,
        withdrawal_type: PropTypes.string
    })
};

const styles = StyleSheet.create({
    statisticDescription: {
        marginRight: 24,
        flex: 1
    },
    statisticText: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14,
        marginBottom: 3
    },
    statisticResource: {
        opacity: 0.7,
        color: '#455562',
        fontSize: 11,
        lineHeight: 13
    },
    statisticWrapper: {
        paddingTop: 17,
        paddingLeft: 20,
        paddingRight: 18,
        paddingBottom: 12,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    service: {
        width: 8,
        height: 8,
        marginRight: 13
    },
    statisticTotal: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14
    }
});

export default PartnershipEntriesBlock;
