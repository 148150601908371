import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, } from 'react-native';

import ProgressBar from '../General/Progress/ProgressBar';
import { normalizeNotificationDate } from '../../utils/general';

function getDate(date) {
    return normalizeNotificationDate(date);
}

function MessageBlock(props) {
    const {
        key,
        item,
    } = props;

    const isProgress = item.state === 'Progress';
    const modifiers = [styles[item.state.toLowerCase()]];
    if (item.is_read) {
        modifiers.push(styles.messageIsRead);
    }
    return <View key={key} style={[styles.wrapper, ...modifiers]}>
        {!isProgress && !item.is_read && <View style={[styles.state, styles[`state${item.state}`]]}/>}
        <View style={styles.inner}>
            <Text style={[styles.content, styles[item.state.toLowerCase()]]}>{item.message}</Text>
            {isProgress && <ProgressBar percent={item.progress}
                color='blue'
                modifiers={{ backgroundColor: 'rgb(239, 239, 239)', height: 5, marginTop: 10 }}/>}
        </View>
        {!isProgress && <Text style={[styles.time, styles[item.state.toLowerCase()]]}>
            {getDate(item.updated)}
        </Text>}
    </View>;
}

MessageBlock.propTypes = {
    item: PropTypes.shape({
        message: PropTypes.string,
        progress: PropTypes.number,
        updated: PropTypes.string,
        state: PropTypes.string,
        is_read: PropTypes.bool,
    }),
    key: PropTypes.string,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        minHeight: 'auto',
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        borderBottomWidth: 1,
        borderBottomColor: '#e4e4e4',
    },
    inner: {
        flex: 1,
        width: 270,
        paddingRight: 10,
    },
    content: {
        fontSize: 12,
        wordBreak: 'break-word',
    },
    time: {
        width: 45,
        opacity: 0.5,
        fontSize: 11,
        textAlign: 'end',
        wordBreak: 'break-word',
    },
    messageIsRead: {
        opacity: 0.7,
    },
    success: {
        color: '#455562',
    },
    error: {
        color: '#d06565',
    },
    state: {
        minWidth: 8,
        height: 8,
        marginTop: 3,
        marginBottom: 0,
        marginRight: 16,
        marginLeft: 0,
        borderRadius: '50%',
    },
    stateError: {
        backgroundColor: '#d06565',
    },
    stateSuccess: {
        backgroundColor: '#0f79d5',
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageBlock);
