const BaseResource = require('../resource');

class PartnershipReferralSitesResource extends BaseResource {
    async list() {
        return this.makeRequest('get', null);
    }

    async create(site) {
        return this.makeRequest('post', null, { site });
    }

    async delete(id) {
        return this.makeRequest('delete', `${id}/`);
    }

    async challenges(id) {
        return this.makeRequest('get', `${id}/challenges/`);
    }

    async verify(id) {
        return this.makeRequest('post', `${id}/verify/`, {});
    }
}

PartnershipReferralSitesResource.resource = 'referral-sites';
PartnershipReferralSitesResource.service = 'partnership';

module.exports = PartnershipReferralSitesResource;
