import React from 'react';
import { useSelector } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';

import { getPercent } from '@netangels/core/utils/price';
import { bToMb } from '@netangels/core/utils/convert';

import Separator from '../General/Separator/Separator';
import HorizontalBarChart from '../General/HorizontalBarChart/HorizontalBarChart';
import { formatMemory } from '../../utils/general';
import { defaultColors } from './constants';

function FreeStatisticsBlock() {
    const {
        userInfo,
        userStatisticsInfo,
        userStatisticsInfoInProgress,
        userStatisticsInfoError,
    } = useSelector((state) => state.mail);
    let quota = 0;
    let current = null;
    const resources = [];
    if (userStatisticsInfo && userInfo && userStatisticsInfo.domains) {
        current = bToMb(userStatisticsInfo.summary.used);
        userStatisticsInfo.domains.forEach((domain, i) => {
            if (domain.used > 0) {
                const domainSize = bToMb(domain.used);
                const colorType = defaultColors[i];
                resources.push({
                    id: `mail:domain:${domainSize}`,
                    title: domain.domain,
                    colorType,
                    value: domainSize,
                    formatValue: (v) => formatMemory(v, true),
                });
            }
        });
    }
    const normalizedCurrent = current < 1024
        ? `${current} МБ`
        : Number.parseFloat((current / 1024).toFixed(2));
    const header = `Занято ${normalizedCurrent} из ${formatMemory(
        userInfo.free_quota,
        true,
    )} (${getPercent(userInfo.free_quota, current)}%)`;
    const freeQuotaBalance = userInfo && current && userInfo.free_quota - current;
    return (
        <View style={styles.wrapper}>
            <Text style={styles.headerTitle}>{header}</Text>
            <HorizontalBarChart
                hideTotal={true}
                resources={resources}
                needLegendColorBox={true}
                needLegendBackground={false}
                horizontalBarChartLegendStyles={styles.horizontalBarChartLegend}
                horizontalBarChartWrapperStyles={styles.horizontalBarChartWrapper}
                horizontalBarChartStatisticStyles={styles.horizontalBarChartStatistic}
                horizontalBarChartLegendValueStyles={styles.horizontalBarChartLegendValue}
                horizontalBarChartLegendTitleStyles={styles.horizontalBarChartLegendTitle}
                horizontalBarChartLegendResourceStyles={styles.horizontalBarChartLegendResource}
            />
            <Separator />
            {freeQuotaBalance > 0 && <Text style={styles.infoText}>
                Осталось {formatMemory(freeQuotaBalance, true)} бесплатного места
            </Text>}
        </View>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 16,
        paddingBottom: 24,
        backgroundColor: '#FFFFFF',
    },
    headerTitle: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 18,
        marginBottom: 8,
        marginLeft: 21,
    },
    horizontalBarChartWrapper: {
        marginBottom: 16,
        marginLeft: 23,
        marginRight: 25,
        flex: 1,
        flexDirection: 'column-reverse',
    },
    horizontalBarChartLegend: {
        flexDirection: 'column',
    },
    horizontalBarChartLegendValue: {
        color: '#455562',
        fontSize: 11,
        lineHeight: 13,
        fontWeight: 400,
    },
    horizontalBarChartLegendTitle: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 15,
        fontWeight: 400,
        flex: 1,
    },
    horizontalBarChartLegendResource: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 0,
        marginRight: 0,
        marginTop: 12,
        alignItems: 'center',
    },
    horizontalBarChartStatistic: {
        height: 20,
    },
    infoText: {
        marginLeft: 23,
        marginTop: 12,
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 14,
        color: '#455562',
    },
});

export default FreeStatisticsBlock;
