import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Platform, StyleSheet, Image, TouchableOpacity
} from 'react-native';

import Button from '../Button/Button';
import { addCustomNotification } from '../../../actions/notifications/actions';
import { addButtonIcon, addButtonDisabledIcon } from '../../../icons';

function getActionButtonComponentFunc(type, additionalIconStyles) {
    switch (type) {
    case '+': {
        return ({
            onPress, disabled, disabledText, addCustomNotification
        }) => <TouchableOpacity
            onPress={() => {
                if (!disabled) {
                    onPress();
                } else if (disabledText != null && disabledText.length !== 0) {
                    addCustomNotification(disabledText);
                }
            }}>
            <Image source={!disabled ? addButtonIcon : addButtonDisabledIcon}
                style={[styles.icon, ...additionalIconStyles]} />
        </TouchableOpacity>;
    }
    default: {
        return ({
            onPress, disabled, disabledText, title
        }) => <Button title={title} onPress={() => {
            if (!disabled) {
                onPress();
            }
        }} disabled={disabled}
        disabledText={disabledText}
        additionalWrapperStyles={[styles.buttonWrapper]}
        additionalButtonStyles={[styles.button]}
        additionalButtonTextStyles={[styles.buttonText]}
        />;
    }
    }
}

function ActionButtonWeb({
    title,
    type,
    onPress,
    disabled,
    disabledText,
    addCustomNotification,
    additionalIconStyles = [],
}) {
    // eslint-disable-next-line global-require
    const usePortal = require('react-useportal').default;

    const rootElement = document.querySelector('#root');

    const { Portal } = usePortal({
        bindTo: rootElement,
    });
    const componentFunc = getActionButtonComponentFunc(type, additionalIconStyles);
    return <Portal>
        {componentFunc({
            onPress, disabled, disabledText, title, addCustomNotification
        })}
    </Portal>;
}

ActionButtonWeb.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    onPress: PropTypes.func,
    disabled: PropTypes.bool,
    disabledText: PropTypes.string,
    addCustomNotification: PropTypes.func,
    additionalIconStyles: PropTypes.arrayOf(PropTypes.shape({})),
};

function ActionButton({
    title,
    type,
    onPress,
    disabled,
    disabledText,
    addCustomNotification,
    additionalIconStyles = [],
}) {
    if (Platform.OS === 'web') {
        return <ActionButtonWeb title={title} type={type} onPress={onPress}
            disabled={disabled} disabledText={disabledText}
            addCustomNotification={addCustomNotification}
            additionalIconStyles={additionalIconStyles} />;
    }
    // Need to implement in future for other platforms
    // https://github.com/mastermoo/react-native-action-button
    throw Error('Not Implemented for current platform');
}

ActionButton.propTypes = {
    disabled: PropTypes.bool,
    onPress: PropTypes.func,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    disabledText: PropTypes.string,
    addCustomNotification: PropTypes.func,
    additionalIconStyles: PropTypes.arrayOf(PropTypes.shape({})),
};

const styles = StyleSheet.create({
    buttonWrapper: {
        width: 47,
        height: 47,
        position: 'fixed',
        bottom: 10,
        right: 10,
    },
    button: {
        paddingTop: 12,
        paddingBottom: 20,
        borderRadius: '50%',
        width: '100%',
        height: '100%'
    },
    buttonText: {
        fontSize: 36,
        lineHeight: 15,
        minHeight: 15,
        fontWeight: '300'
    },
    icon: {
        width: 47,
        height: 46,
        position: 'fixed',
        bottom: 0,
        right: 5,
    }
});

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({ addCustomNotification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActionButton);
