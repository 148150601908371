import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import { flushErrors } from '../../../actions/forms/actions';
import InputPassword from '../../General/Form/InputPassword';
import { vmResetPassword } from '../../../actions/cvds/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'ChangeRootPasswordConfirmForm';

function ChangeRootPasswordModal(props) {
    const {
        vm,
        emails,
        errors,
        onClose,
        inProgress,
        flushErrors,
        vmResetPassword
    } = props;

    React.useEffect(() => {
        flushErrors()
    }, []);

    const [password, setModalChangedNewPass] = React.useState('');
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Сброс пароля root</Text>
        <InputPassword
            label='Пароль(необязательно)'
            errors={errors.password}
            value={password}
            autoFocus={false}
            onChange={(value) => setModalChangedNewPass(value)}
        />
        <Text style={styles.description}>
            Новый пароль придет на {emails.join(', ')}
        </Text>
        {errors.__all__ && <Text style={styles.error}>{errors.__all__}</Text>}
        <View style={styles.buttons}>
            <AcceptButton
                onSubmit={() => vmResetPassword(formId, { vmId: vm.id, password },
                    () => onClose())}
                title="Сохранить"
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

ChangeRootPasswordModal.propTypes = {
    onClose: PropTypes.func,
    vm: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    emails: PropTypes.arrayOf(PropTypes.string),
    vmResetPassword: PropTypes.func,
    flushErrors: PropTypes.func,
    inProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    emails: state.user.user.emailsTech,
    inProgress: state.cvds.vmUpdateInProgress,
    errors: state.forms.errors[formId] || {}
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    vmResetPassword
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 20,
        color: '#455562',
    },
    description: {
        fontSize: 14,
        lineHeight: 20,
        color: '#455562',
        maxWidth: 300,
        marginTop: 10,
    },
    buttons: {
        flex: 1,
        marginTop: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        color: '#d06565',
        marginTop: 10,
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRootPasswordModal);
