import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity
} from 'react-native';

import { momentNotificationDelete } from '../../actions/notifications/actions';
import ProgressBar from '../General/Progress/ProgressBar';

function NotificationMomentBlock(props) {
    const { notificationsMoment, momentNotificationDelete } = props;
    return <View style={styles.wrapper}>
        {notificationsMoment.map((item, i) => <TouchableOpacity key={`item-${i}`}
            style={styles.notificationItem} onPress={() => momentNotificationDelete(item)}>
            <Text style={styles.notificationText}>{item.message}</Text>
            {item.state === 'Progress' && <ProgressBar percent={item.progress} color='blue'
                modifiers={{ backgroundColor: 'rgb(239, 239, 239)', height: 5, marginTop: 10 }}/>}
        </TouchableOpacity>)}
    </View>;
}

NotificationMomentBlock.propTypes = {
    momentNotificationDelete: PropTypes.func,
    notificationsMoment: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.string,
        state: PropTypes.string
    }))
};

const mapStateToProps = (state) => ({
    notificationsMoment: state.notifications.notificationsMoment
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ momentNotificationDelete }, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        marginLeft: 6,
        marginRight: 5
    },
    notificationItem: {
        borderRadius: 6,
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 11,
        marginBottom: 10,
        opacity: 0.8,
        backgroundColor: 'rgba(0, 0, 0, 0.90)',
    },
    notificationText: {
        fontSize: 12,
        textAlign: 'center',
        lineHeight: 15,
        color: '#ffffff'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMomentBlock);
