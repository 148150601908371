import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { merge } from '@netangels/core/utils/functions';

import FinanceBalanceTab from '../components/Finance/FinanceBalanceTab/FinanceBalanceTab';
import FinanceDocumentsTab from '../components/Finance/FinanceDocumentsTab/FinanceDocumentsTab';
import FinanceDetailTab from '../components/Finance/FinanceDetail/FinanceDetailTab/FinanceDetailTab';
import { tabBarOptions } from '../utils/tab-bar';

const FinanceTab = createMaterialTopTabNavigator();

function FinanceTabs() {
    return <FinanceTab.Navigator
        tabBarOptions={merge(tabBarOptions, {
            tabStyle: {
                width: 110
            }
        })}>
        <FinanceTab.Screen name="FinanceBalance" component={FinanceBalanceTab} options={{
            title: 'Финансы - Баланс',
            tabBarLabel: 'Баланс'
        }}/>
        <FinanceTab.Screen name="FinanceDetail" component={FinanceDetailTab} options={{
            title: 'Финансы - Детализация',
            tabBarLabel: 'Детализация',
        }}/>
        <FinanceTab.Screen name="FinanceDocuments" component={FinanceDocumentsTab} options={{
            title: 'Финансы - Документы',
            tabBarLabel: 'Документы',
        }}/>
    </FinanceTab.Navigator>;
}

export default FinanceTabs;
