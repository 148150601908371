export const screenHeaderStyles = {
    headerStyle: {
        backgroundColor: '#f5f5f5',
        elevation: 0,
        shadowOpacity: 0,
        borderBottomWidth: 0,
        height: 66,
    },
    headerTitleContainerStyle: {
        paddingTop: 22,
        height: 66,
        paddingBottom: 24,
        maxWidth: 300,
    },
    headerLeftContainerStyle: {
        paddingTop: 22,
        height: 66,
        paddingBottom: 24,
    },
    headerTintColor: '#455562',
    headerStyleDisabled: {
        backgroundColor: '#455562',
    },
    headerTitleAlign: 'center',
};

export const mainWrapperStyles = {
    borderRadius: 16,
    shadowColor: '#000000',
    shadowOpacity: 0.03,
    shadowRadius: 10,
    flexDirection: 'column',
    backgroundColor: '#ffffff',
};
