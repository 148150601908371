import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    Image,
} from 'react-native';

import {
    changeNotificationPhone
} from '../../../actions/account/actions';
import Modal from '../../General/Modal/Modal';
import Switch from '../../General/Switch/Switch';
import AccountChangePhoneStep1 from '../AccountModal/AccountChangePhoneStep1';
import AccountChangePhoneStep2 from '../AccountModal/AccountChangePhoneStep2';
import { ModalContext } from '../../../contexts/ModalContext';
import { mainWrapperStyles } from '../../../utils/styles';
import { rightArrowBoldIcon } from '../../../icons';

function getStep(phoneVerificationData) {
    if (phoneVerificationData && phoneVerificationData.send_time) {
        return 2;
    }
    return 1;
}

const modalId = 'account-change-phone-modal';

function AccountPhone() {
    const dispatch = useDispatch();
    const { closeModal, openModal, checkModal } = useContext(ModalContext);
    const {
        phone,
        phone_notification_enabled,
        phone_verification_data,
    } = useSelector((state) => state.account.account);

    const step = getStep(phone_verification_data) || 1;
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Мобильный телефон</Text>
        <View style={styles.phoneBlock}>
            <Text style={styles.phone}>{phone || 'Номер не привязан'}</Text>
            <TouchableOpacity onPress={() => openModal(modalId)} style={styles.changeBlock}>
                <Text style={styles.changeText}>Изменить</Text>
                <Image source={rightArrowBoldIcon} style={styles.rightIcon} />
            </TouchableOpacity>
        </View>
        {phone && <View style={styles.switch}>
            <Switch label='SMS-уведомления'
                isEnable={phone_notification_enabled}
                onChange={(value) => dispatch(changeNotificationPhone(value))}
                additionalLabelStyles={[styles.switchLabel]}/>
        </View>}
        {step === 1 && <Modal isModalShowed={checkModal(modalId)}
            component={<AccountChangePhoneStep1 onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />}
        {step === 2 && <Modal isModalShowed={checkModal(modalId)}
            component={<AccountChangePhoneStep2 onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />}
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 12,
        ...mainWrapperStyles,
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 20,
        paddingTop: 19,
    },
    title: {
        color: '#455562',
        marginBottom: 17,
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 12,
    },
    phoneBlock: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    phone: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 12,
    },
    changeBlock: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    changeText: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 12,
        opacity: 0.7,
    },
    rightIcon: {
        width: 4.7,
        height: 8,
        marginLeft: 8,
    },
    switchLabel: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 12,
    },
    switch: {
        marginTop: 17,
    }
});

export default AccountPhone;
