import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';

import { getChannel } from '@netangels/core/utils/websockets';
import { userChannelName } from '@netangels/core/constants/websockets';

import useWebsockets from '../hooks/useWebsockets';
import { screenHeaderStyles } from '../utils/styles';
import { fromWsCouponToCoupon } from '../utils/general';
import {
    couponsList,
    awaitingPacketList,
    chargesMonthGroups,
    wsUserBalanceUpdate,
    wsDeferredPaymentUpdate,
    wsCouponActivate
} from '../actions/finances/actions';

import FinanceTabs from './financeTabs';
import { connectToWebsocketWithDelay } from '../utils/websockets';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const FinanceStack = createStackNavigator();

function FinanceStackScreen(props) {
    const {
        user,
        coupons,
        navigation,
        couponsList,
        awaitingPackets,
        wsCouponActivate,
        couponsInProgress,
        statisticsCharges,
        chargesMonthGroups,
        awaitingPacketList,
        wsUserBalanceUpdate,
        statisticsChargesDate,
        wsDeferredPaymentUpdate,
        awaitingPacketsInProgress,
        statisticsChargesInProgress
    } = props;
    const insets = useSafeAreaInsets();
    const ws = useWebsockets();
    let financesSubscriber = null;
    let panelSubscriber = null;

    useFocusEffect(useCallback(() => {
        const checkAndFetchData = async () => {
            if (awaitingPackets == null && !awaitingPacketsInProgress) {
                awaitingPacketList();
            }
            if (statisticsCharges == null && !statisticsChargesInProgress) {
                chargesMonthGroups(statisticsChargesDate);
            }
            if (coupons == null && !couponsInProgress) {
                couponsList();
            }
        };
        checkAndFetchData();
        const connectionState = connectToWebsocketWithDelay(() => {
            const channelName = getChannel(userChannelName, user.login, true);
            if (financesSubscriber == null || panelSubscriber == null) {
                financesSubscriber = ws.subscriber('finances', channelName);
                financesSubscriber.on('deferred_payment.activate', (data) => {
                    wsDeferredPaymentUpdate(data);
                }).on('coupon.activate', (coupon) => {
                    wsCouponActivate(fromWsCouponToCoupon(coupon));
                });
            }
            if (panelSubscriber == null) {
                panelSubscriber = ws.subscriber('panel', channelName);
                panelSubscriber.on('user.balance_changed', (data) => {
                    wsUserBalanceUpdate(data);
                }).on('deferred_payment.deactivated', (data) => {
                    wsDeferredPaymentUpdate(data);
                });
            }
        });

        return () => {
            connectionState.cancel();
            if (financesSubscriber != null) {
                ws.unsubscribe(financesSubscriber);
                financesSubscriber = null;
            }
            if (panelSubscriber != null) {
                ws.unsubscribe(panelSubscriber);
                panelSubscriber = null;
            }
        };
    }, []));

    return <FinanceStack.Navigator>
        <FinanceStack.Screen name="FinanceTabs" options={{
            headerTitle: () => <ScreenHeaderTitle title='Финансы' />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => navigation.navigate('General'),
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }}>
            {props => <FinanceTabs {...props} />}
        </FinanceStack.Screen>
    </FinanceStack.Navigator>;
}

FinanceStackScreen.propTypes = {
    coupons: PropTypes.arrayOf(PropTypes.shape({})),
    couponsInProgress: PropTypes.bool,
    couponsList: PropTypes.func,
    awaitingPacketList: PropTypes.func,
    awaitingPacketsInProgress: PropTypes.bool,
    awaitingPackets: PropTypes.arrayOf(PropTypes.shape({
        cost: PropTypes.number,
        service: PropTypes.string,
        resource_id: PropTypes.string,
    })),
    statisticsCharges: PropTypes.shape({
        groups: PropTypes.arrayOf(PropTypes.shape({
            service: PropTypes.string,
            resource_name: PropTypes.string,
            total: PropTypes.number
        })),
        summarize: PropTypes.arrayOf(PropTypes.shape({
            service: PropTypes.string,
            total: PropTypes.number
        }))
    }),
    chargesMonthGroups: PropTypes.func,
    statisticsChargesDate: PropTypes.string,
    currentTab: PropTypes.string,
    wsCouponActivate: PropTypes.func,
    wsUserBalanceUpdate: PropTypes.func,
    wsDeferredPaymentUpdate: PropTypes.func
};

const mapStateToProps = (state) => ({
    user: state.user.user,
    coupons: state.finance.coupons,
    awaitingPackets: state.finance.awaitingPackets,
    couponsInProgress: state.finance.couponsInProgress,
    statisticsCharges: state.finance.statisticsCharges,
    statisticsChargesDate: state.finance.statisticsChargesDate,
    awaitingPacketsInProgress: state.finance.awaitingPacketsInProgress,
    statisticsChargesInProgress: state.finance.statisticsChargesInProgress
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    couponsList, awaitingPacketList, chargesMonthGroups,
    wsUserBalanceUpdate, wsDeferredPaymentUpdate, wsCouponActivate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinanceStackScreen);
