import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { bindActionCreators } from 'redux';

import { calculateIPPrice, calculateBandwidthPrice } from '../utils';
import WarningCancelPacketBlock from '../WarningCancelPacketBlock';
import { IPv4 } from '../../../constants/cvds';
import Errors from '../../General/Form/Errors';
import { flushErrors } from '../../../actions/forms/actions';
import { vmProtectedIPAdd, vmChangeBandwidth } from '../../../actions/cvds/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'AddAntiDDoSForm';

function AddAntiDDoSModal(props) {
    const {
        vm,
        errors,
        onClose,
        awaitingPacket,
        isChangeBandwidth,
        flushErrors,
        vmProtectedIPAdd,
        vmChangeBandwidth,
        inProgress,
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);
    const [currentBandwidth, setCurrentBandwidth] = React.useState(vm.bandwidth || 10);

    let fullBandwidthPrice = calculateBandwidthPrice(currentBandwidth);
    if (!isChangeBandwidth) {
        fullBandwidthPrice += calculateIPPrice(1, IPv4);
    }
    return <View style={styles.wrapper}>
        <Text style={styles.title}>DDOS Guard</Text>
        <Text style={styles.text}>
            Полная фильтрация входящего трафика с полосой до 50 Мбит/сек
        </Text>
        <View style={styles.bandwidthBlock}>
            <Text style={styles.text}>Полоса, Мбит/сек</Text>
            <View style={styles.bandwidthItems}>
                <TouchableOpacity onPress={() => setCurrentBandwidth(10)}
                    style={[styles.bandwidthItem, {
                        borderLeftColor: '#cccccc', borderLeftWidth: 1,
                        borderTopLeftRadius: 4, borderBottomLeftRadius: 4
                    }, currentBandwidth === 10 ? styles.activeBandwidthItem : {}]}>
                    <Text style={[styles.bandwidthItemText,
                        { color: currentBandwidth === 10 ? '#ffffff' : '#455562' }]}>10</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setCurrentBandwidth(20)}
                    style={[styles.bandwidthItem,
                        currentBandwidth === 20 ? styles.activeBandwidthItem : {}]}>
                    <Text style={[styles.bandwidthItemText,
                        { color: currentBandwidth === 20 ? '#ffffff' : '#455562' }]}>20</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setCurrentBandwidth(50)}
                    style={[styles.bandwidthItem, {
                        borderTopRightRadius: 4, borderBottomRightRadius: 4
                    }, currentBandwidth === 50 ? styles.activeBandwidthItem : {}]}>
                    <Text style={[styles.bandwidthItemText,
                        { color: currentBandwidth === 50 ? '#ffffff' : '#455562' }]}>50</Text>
                </TouchableOpacity>
            </View>
        </View>
        <Text style={styles.price}>
            Стоимость: {fullBandwidthPrice.toFixed(0)} ₽/мес
        </Text>
        {awaitingPacket && <View style={styles.packetWarning}>
            <WarningCancelPacketBlock mainText='У вас имеется незавершенный заказ
                на годовой пакет для виртуальной машины. Изменение тарифа приведет к'
            warningText='отмене заказа годового пакета'/>
        </View>}
        {errors.__all__ && <Errors errors={errors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton onSubmit={() => {
                if (!isChangeBandwidth) {
                    vmProtectedIPAdd(formId, vm.id, currentBandwidth, () => onClose());
                } else {
                    vmChangeBandwidth(formId, vm.id, currentBandwidth, () => onClose());
                }
            }} title="Подключить"
            inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
};

AddAntiDDoSModal.propTypes = {
    vm: PropTypes.shape({}),
    onClose: PropTypes.func,
    errors: PropTypes.shape({}),
    awaitingPacket: PropTypes.bool,
    vmProtectedIPAdd: PropTypes.func,
    vmChangeBandwidth: PropTypes.func,
    inProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    errors: state.forms.errors[formId] || {},
    inProgress: state.cvds.antiDDoSInProgress,
    awaitingPacket: state.cvdsFinanceInfo.calculation.packet == null ? false
        : state.cvdsFinanceInfo.calculation.packet.is_awaiting
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors, vmProtectedIPAdd, vmChangeBandwidth
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 32,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 28
    },
    title: {
        marginBottom: 12,
        color: '#455562',
        fontSize: 16,
        lineHeight: 19
    },
    text: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 18
    },
    bandwidthBlock: {
        marginTop: 16
    },
    bandwidthItems: {
        flex: 1,
        marginTop: 2,
        flexDirection: 'row'
    },
    bandwidthItem: {
        paddingTop: 12,
        paddingBottom: 11,
        paddingLeft: 11,
        paddingRight: 12,
        borderTopColor: '#cccccc',
        borderTopWidth: 1,
        borderBottomColor: '#cccccc',
        borderBottomWidth: 1,
        borderRightColor: '#cccccc',
        borderRightWidth: 1
    },
    bandwidthItemText: {
        fontSize: 14,
        lineHeight: 14
    },
    activeBandwidthItem: {
        backgroundColor: '#0F79D5',
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderRightWidth: 0,
        borderLeftWidth: 0
    },
    price: {
        color: '#627687',
        fontSize: 14,
        lineHeight: 20,
        fontWeight: '600',
        marginTop: 22,
        paddingBottom: 15,
        borderBottomColor: 'rgba(69, 85, 98, 0.3)',
        borderBottomWidth: 1,
        borderStyle: 'dashed'
    },
    buttonBlock: {
        flex: 1,
        marginTop: 26,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    packetWarning: {
        marginTop: 16
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAntiDDoSModal);
