const BaseResource = require('../resource');

class CVDSImagesResource extends BaseResource {
    async distributionsList() {
        return this.makeRequest('get', 'distributions/');
    }
}

CVDSImagesResource.resource = null;
CVDSImagesResource.service = 'images';

module.exports = CVDSImagesResource;
