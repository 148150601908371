import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
    View, StyleSheet, Text, Image,
    TouchableOpacity
} from 'react-native';

import Button from '../General/Button/Button';
import Modal from '../General/Modal/Modal';
import { errorMailIcon } from '../../icons';
import { ModalContext } from '../../contexts/ModalContext';
import ActivateMailPaidQuotaModal from './Modals/ActivateMailPaidQuotaModal';

const activatePaidQuotaModalId = 'activate-paid-quota-modal';

function MailErrorPage() {
    const { openModal, closeModal, checkModal } = useContext(ModalContext);

    return <View style={styles.wrapper}>
        <Image source={errorMailIcon} style={styles.image} />
        <View style={styles.content}>
            <Text style={styles.title}>Почта
                <Text style={styles.titleWarning}>отключена</Text>
            </Text>
            <View>
                <Text style={styles.description}>
                    У вас имеются почтовые домены, но отсутствует
                    бесплатное дисковое пространство для почты.
                    Для того, чтобы восстановить работу почты —
                </Text>
                <TouchableOpacity onPress={() => openModal(activatePaidQuotaModalId)}>
                    <Text style={styles.link}>подключите Почту.про</Text>
                </TouchableOpacity>
            </View>
        </View>
        <Button title='Подробнее'
            onPress={() => openModal(activatePaidQuotaModalId)}
            additionalWrapperStyles={[{ width: '100%' }]}
            additionalButtonStyles={[{
                paddingTop: 11,
                paddingBottom: 13,
                marginLeft: 22,
                marginRight: 22,
            }]}
            additionalButtonTextStyles={[{
                fontSize: 14,
                lineHeight: 16,
                fontWeight: '400',
            }]}/>
        <Modal isModalShowed={checkModal(activatePaidQuotaModalId)}
            component={<ActivateMailPaidQuotaModal
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        margin: 0,
        paddingTop: 51,
        paddingLeft: 10,
        paddingRight: 10,
        flex: 1,
        width: '100%',
        flexDirection: 'colume',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        minHeight: 240,
        minWidth: 300,
        marginBottom: 32,
    },
    title: {
        width: 220,
        textAlign: 'center',
        fontSize: 16,
        color: '#455562',
        letterSpacing: 0,
        lineHeight: 19,
        marginBottom: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    titleWarning: {
        color: '#d06565',
        marginLeft: 4,
    },
    description: {
        width: 294,
        fontSize: 12,
        textAlign: 'center',
        color: '#627687',
        lineHeight: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    link: {
        fontSize: 12,
        textAlign: 'center',
        color: '#0f79d5',
        lineHeight: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 24,
    }
});

export default MailErrorPage;
