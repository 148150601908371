import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { NavigationProps } from '../types';
import { screenHeaderStyles } from '../utils/styles';
import { partnershipData } from '../actions/partnership/actions';
import PartnershipWithdrawal from '../components/Partnership/PartnershipWithdrawal';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const PartnershipWithdrawalStack = createStackNavigator();

function PartnershipWithdrawalStackScreen(props) {
    const {
        navigation,
        partnership,
        partnershipData
    } = props;
    const insets = useSafeAreaInsets();

    React.useEffect(() => {
        if (partnership == null) {
            partnershipData();
        }
    }, []);

    return <PartnershipWithdrawalStack.Navigator>
        <PartnershipWithdrawalStack.Screen name="PartnershipWithdrawalStack"
            component={PartnershipWithdrawal} options={{
                headerTitle: () => <ScreenHeaderTitle title='Вывод средств' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => {
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        } else {
                            navigation.navigate('MainStack', {
                                screen: 'Partnership'
                            });
                        }
                    },
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </PartnershipWithdrawalStack.Navigator>;
}

PartnershipWithdrawalStackScreen.propTypes = {
    navigation: NavigationProps,
    partnershipData: PropTypes.func,
    partnership: PropTypes.shape({})
};

const mapStateToProps = (state) => ({
    partnership: state.partnership.partnership
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ partnershipData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PartnershipWithdrawalStackScreen);
