import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    Text,
    View,
    StyleSheet,
} from 'react-native';

import { dnsIcon, sslIcon, domainsIcon } from '../../icons';
import {
    onCheckDNSZone,
    onCheckSSL,
    onCheckDomains,
} from '../../actions/transfer/actions';
import RelatedResourcesTable from './RelatedResourcesTable';

function ResourcesTables(props) {
    const {
        service,
        instance,
        resources,
        transferInProgress,
        zonesInProgress,
        domainsInProgress,
        certificatesInProgress,
        description,
    } = props;
    const { checkedSSL, checkedDomains, checkedDNSZones } = useSelector((state) => state.transfer);
    let filteredZones = resources.zones || [];
    let filteredCertificates = resources.certificates || [];
    let filteredDomains = resources.domains || [];
    if (service === 'zones') {
        filteredZones = filteredZones.filter((z) => z.id !== instance.id);
    }
    if (service === 'domains') {
        filteredDomains = filteredDomains.filter((d) => d.id !== instance.id);
    }
    if (service === 'certificates') {
        filteredCertificates = filteredCertificates.filter(
            (c) => c.id !== instance.id,
        );
    }
    return (
        <View>
            {description && (
                <Text style={styles.title}>{description}</Text>
            )}
            {(zonesInProgress || filteredZones.length !== 0) && (
                <RelatedResourcesTable
                    type="dns"
                    title="Зоны DNS"
                    transferInProgress={transferInProgress}
                    icon={dnsIcon}
                    entities={filteredZones}
                    onCheck={onCheckDNSZone}
                    checkedEntities={checkedDNSZones}
                />
            )}
            {(domainsInProgress || filteredDomains.length !== 0) && (
                <RelatedResourcesTable
                    type="domains"
                    title="Домены"
                    icon={domainsIcon}
                    transferInProgress={transferInProgress}
                    entities={filteredDomains}
                    onCheck={onCheckDomains}
                    checkedEntities={checkedDomains}
                />
            )}
            {(certificatesInProgress || filteredCertificates.length !== 0) && (
                <RelatedResourcesTable
                    type="ssl"
                    title="SSL-сертификаты"
                    icon={sslIcon}
                    entities={filteredCertificates}
                    onCheck={onCheckSSL}
                    checkedEntities={checkedSSL}
                    transferInProgress={transferInProgress}
                />
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    title: {
        marginTop: 30,
        opacity: 0.75,
        marginLeft: 20,
        marginBottom: 10,
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.38,
        lineHeight: 13,
        textTransform: 'uppercase'
    },
});

ResourcesTables.propTypes = {
    zonesInProgress: PropTypes.bool,
    domainsInProgress: PropTypes.bool,
    certificatesInProgress: PropTypes.bool,
    resources: PropTypes.shape({}),
    instance: PropTypes.shape({}),
    service: PropTypes.string,
    description: PropTypes.string,
    transferInProgress: PropTypes.bool,
};

export default ResourcesTables;
