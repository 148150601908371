import { clamp } from '@netangels/core/utils/general';
import {
    NOTIFICATIONS_IN_PROGRESS,
    NOTIFICATIONS_SUCCESSFULLY,
    NOTIFICATIONS_WITH_ERRORS,
    NOTIFICATIONS_MARK_SUCCESSFULLY,
    NOTIFICATION_UPDATE,
    NOTIFICATION_MOMENT,
    NOTIFICATION_MOMENT_DELETE,
    CUSTOM_NOTIFICATION,
    CUSTOM_NOTIFICATION_DELETE,
    ALL_NOTIFICATIONS_MARKED_SUCCESSFULLY
} from '../actions/notifications/actionTypes';

const LIMIT_COUNT = 100;

const transformNotification = (notification) => ({
    ...notification,
    progress: clamp(notification.progress, 5, 100)
});

export default () => {
    const defaultState = {
        notifications: null,
        isLoading: false,
        offset: 0,
        notificationsMoment: []
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case NOTIFICATIONS_SUCCESSFULLY: {
            return {
                ...state,
                isLoading: false,
                notifications: action.data,
                offset: state.offset + LIMIT_COUNT
            };
        }
        case NOTIFICATIONS_IN_PROGRESS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case NOTIFICATIONS_WITH_ERRORS: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case NOTIFICATIONS_MARK_SUCCESSFULLY: {
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    unread: 0,
                    entities: state.notifications.entities.map((item) => {
                        const transformedItem = { ...item };
                        transformedItem.is_read = true;
                        return transformedItem;
                    })
                }
            };
        }
        case NOTIFICATION_UPDATE: {
            const notification = transformNotification(action.data);
            const existedNotification = state.notifications.entities.find(
                (item) => item.uid === notification.uid
            );
            if (existedNotification && notification.counter < existedNotification.counter) {
                return state;
            }
            const updatedNotifications = [
                notification,
                ...state.notifications.entities.filter((item) => item.uid !== notification.uid)
            ];
            let { unread } = state.notifications;
            if ((existedNotification && existedNotification.is_read)
                || existedNotification == null) {
                unread += 1;
            }
            return {
                ...state,
                notifications: {
                    unread,
                    count: state.notifications.count,
                    entities: updatedNotifications
                }
            };
        }
        case NOTIFICATION_MOMENT: {
            const notification = transformNotification(action.data);
            const notificationsMoment = state.notificationsMoment.find(
                (item) => item.uid === notification.uid
            );
            if (notificationsMoment && notification.counter < notificationsMoment.counter) {
                return state;
            }
            if (notification.state === 'Progress' && notificationsMoment) {
                return {
                    ...state,
                    notificationsMoment: state.notificationsMoment.map((item) => {
                        if (item.uid === notification.uid) {
                            return notification;
                        }
                        return item;
                    })
                };
            }
            return {
                ...state,
                notificationsMoment: [
                    notification,
                    ...state.notificationsMoment.filter((item) => item.uid !== notification.uid)
                ]
            };
        }
        case NOTIFICATION_MOMENT_DELETE: {
            return {
                ...state,
                notificationsMoment: [
                    ...state.notificationsMoment.filter((item) => item.uid !== action.data.uid)
                ]
            };
        }
        case CUSTOM_NOTIFICATION: {
            const { message } = action.data;
            if (state.notificationsMoment.find((n) => n.message === message)) {
                return state;
            }
            return {
                ...state,
                notificationsMoment: [
                    ...state.notificationsMoment,
                    action.data
                ]
            };
        }
        case CUSTOM_NOTIFICATION_DELETE: {
            return {
                ...state,
                notificationsMoment: [
                    ...state.notificationsMoment.filter(
                        (item) => item.message !== action.data.message
                    )
                ]
            };
        }
        case ALL_NOTIFICATIONS_MARKED_SUCCESSFULLY: {
            const uids = action.data.results;
            const transformedNotifications = state.notifications.entities.map((item) => {
                if (uids[item.uid]) {
                    return {
                        ...item,
                        is_read: true,
                    };
                }
                return item;
            });
            const unread = state.notifications.unread - Object.keys(uids).length;
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    entities: transformedNotifications,
                    unread: unread >= 0 ? unread : 0,
                },
            };
        }
        default:
            return state;
        }
    };
};
