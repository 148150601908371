import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import {
    downloadActSV,
    getMonthsForCharges,
    getMonthsForPayments,
} from '../../../actions/finances/actions';
import Errors from '../../General/Form/Errors';
import SelectModal from '../../General/SelectModal/SelectModal';
import { getUnique } from '../../../utils/general';
import AcceptButton from '../../General/Modal/AcceptButton';
import { flushErrors } from '../../../actions/forms/actions';

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    subTitle: {
        fontSize: 14,
        lineHeight: 20,
        marginBottom: 5
    },
    pickerInputBlock: {
        flex: 1,
        marginLeft: 1,
        flexDirection: 'row'
    },
    pickerInput: {
        height: 40,
        flex: 1,
        marginBottom: 20,
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    acceptButton: {
        width: '100%',
        borderRightWidth: 0,
    },
    errorBlock: {
        marginTop: 10
    },
    selectWrapper: {
        flex: 1,
    }
});

const formId = 'ActSvModalForm';

const monthsList = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

function normalizeYearsOptions(options) {
    if (options && options.length > 0) {
        const uniqueYears = getUnique(options.map(option => dayjs(option, 'YYYY-MM-DD').format('YYYY')));
        return uniqueYears.map((year, i) => ({
            value: year,
            index: i,
            label: year,
        }));
    }
    return [];
}

function normalizeMonthOptions(options) {
    if (options && options.length > 0) {
        return options.map((option, index) => ({
            value: `${index + 1}`.padStart(2, '0'),
            label: option,
            index,
        }));
    }
    return [];
}

function ActSVModal(props) {
    const {
        onClose,
        inProgress,
        flushErrors,
        downloadActSV,
        chargesMonths,
        monthsPayments,
        getMonthsForCharges,
        getMonthsForPayments,
        defaultMonthEnd = 11,
        chargesMonthsInProgress,
        monthsPaymentsInProgress,
        errors
    } = props;

    React.useEffect(() => {
        flushErrors();
        if (!monthsPaymentsInProgress && monthsPayments.length === 0) {
            getMonthsForPayments();
        }
        if (!chargesMonthsInProgress && chargesMonths.length === 0) {
            getMonthsForCharges();
        }
    }, []);
    const [months, setMonths] = React.useState(null);
    const [normalizedYearsOptions, setNormalizedYearsOptions] = React.useState(null);
    const [normalizedMonthsOptions, setNormalizedMonthsOptions] = React.useState(null);
    const [dateList, setDateList] = React.useState(null);
    const [monthStart, setMonthStart] = React.useState(null);
    const [yearStart, setYearStart] = React.useState(null);
    const [monthEnd, setMonthEnd] = React.useState(null);
    const [yearEnd, setYearEnd] = React.useState(null);
    const [currentYearIndex, setCurrentYearIndex] = React.useState(null);

    React.useEffect(() => {
        if (!(monthsPayments == null || chargesMonths == null)) {
            setMonths(getUnique([...monthsPayments, ...chargesMonths]));
        }
    }, [monthsPayments, chargesMonths]);

    React.useEffect(() => {
        if (months != null && months.length !== 0) {
            const sortedDateList = months.sort((a, b) => {
                const normalizeA = dayjs(a, 'YYYY-MM-DD');
                const normalizeB = dayjs(b, 'YYYY-MM-DD');
                if (normalizeA < normalizeB) {
                    return 1;
                } if (normalizeA > normalizeB) {
                    return -1;
                }
                return 0;
            });
            setDateList(sortedDateList);
        }
    }, [months]);

    React.useEffect(() => {
        if (dateList) {
            setNormalizedYearsOptions(normalizeYearsOptions(dateList));
            setNormalizedMonthsOptions(normalizeMonthOptions(monthsList));
        }
    }, [dateList]);

    React.useEffect(() => {
        if (normalizedYearsOptions && normalizedMonthsOptions) {
            setMonthStart(normalizedMonthsOptions[0]);
            setMonthEnd(normalizedMonthsOptions[defaultMonthEnd]);
            const lastYear = normalizedYearsOptions[0].value;
            const currentYear = dayjs().format('YYYY');
            const yearIndex = currentYear === lastYear && normalizedYearsOptions.length > 1
                ? 1
                : 0;
            setYearStart(normalizedYearsOptions[yearIndex]);
            setYearEnd(normalizedYearsOptions[yearIndex]);
            setCurrentYearIndex(yearIndex);
        }
    }, [normalizedYearsOptions, normalizedMonthsOptions]);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Акт сверки</Text>
        <Text style={styles.subTitle}>Начало периода</Text>
        {normalizedMonthsOptions && normalizedYearsOptions && currentYearIndex
            && <View style={styles.pickerInputBlock}>
                <View style={[styles.selectWrapper, { marginRight: 10 }]}>
                    <SelectModal
                        additionalIconStyle={[{ marginRight: 8 }]}
                        initialSelectedIndex={monthStart
                            ? normalizedMonthsOptions.findIndex((d) => d.value === monthStart.value)
                            : 0}
                        onChange={(value) => setMonthStart(value)}
                        currentItem={monthStart || normalizedMonthsOptions[0]}
                        itemList={normalizedMonthsOptions}/>
                </View>
                <View style={styles.selectWrapper}>
                    <SelectModal
                        additionalIconStyle={[{ marginRight: 8 }]}
                        initialSelectedIndex={currentYearIndex}
                        onChange={(value) => setYearStart(value)}
                        currentItem={yearStart || normalizedYearsOptions[currentYearIndex]}
                        itemList={normalizedYearsOptions}/>
                </View>
            </View>}
        <Text style={styles.subTitle}>Конец периода</Text>
        {normalizedMonthsOptions && normalizedYearsOptions && currentYearIndex
            && <View style={styles.pickerInputBlock}>
                <View style={[styles.selectWrapper, { marginRight: 10 }]}>
                    <SelectModal
                        additionalIconStyle={[{ marginRight: 8 }]}
                        initialSelectedIndex={monthEnd
                            ? normalizedMonthsOptions.findIndex((d) => d.value === monthEnd.value)
                            : defaultMonthEnd}
                        onChange={(value) => setMonthEnd(value)}
                        currentItem={monthEnd || normalizedMonthsOptions[defaultMonthEnd]}
                        itemList={normalizedMonthsOptions}/>
                </View>
                <View style={styles.selectWrapper}>
                    <SelectModal
                        additionalIconStyle={[{ marginRight: 8 }]}
                        initialSelectedIndex={currentYearIndex}
                        onChange={(value) => setYearEnd(value)}
                        currentItem={yearEnd || normalizedYearsOptions[currentYearIndex]}
                        itemList={normalizedYearsOptions}/>
                </View>
            </View>}
        <View style={styles.errorBlock}>
            <Errors errors={errors.__all__} />
        </View>
        <View style={styles.buttonBlock}>
            <AcceptButton
                additionalButtonStyles={[styles.acceptButton]}
                onSubmit={() => {
                    const startDate = dayjs(`${yearStart.value}-${monthStart.value}`,
                        'YYYY-MM').format('YYYY-MM-DD');
                    const endDate = dayjs(`${yearEnd.value}-${monthEnd.value}`,
                        'YYYY-MM').format('YYYY-MM-DD');
                    downloadActSV(formId, startDate, endDate, onClose);
                }}
                title='Скачать акт'
                inProgress={inProgress}/>
        </View>
    </View>;
}

ActSVModal.propTypes = {
    onChange: PropTypes.func,
    setMapInBag: PropTypes.func,
    onAccept: PropTypes.func,
    buttonTitle: PropTypes.string,
    monthEnd: PropTypes.number,
    flushErrors: PropTypes.func,
    downloadActSV: PropTypes.func,
    errors: PropTypes.shape({}),
    onClose: PropTypes.func,
    chargesMonths: PropTypes.arrayOf(PropTypes.string),
    chargesMonthsInProgress: PropTypes.bool,
    monthsPayments: PropTypes.arrayOf(PropTypes.string),
    monthsPaymentsInProgress: PropTypes.bool,
    getMonthsForCharges: PropTypes.func,
    getMonthsForPayments: PropTypes.func,
    inProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    chargesMonths: state.finance.chargesMonths,
    inProgress: state.finance.downloadActInProgress,
    chargesMonthsInProgress: state.finance.chargesMonthsInProgress,
    monthsPayments: state.finance.monthsPayments,
    monthsPaymentsInProgress: state.finance.monthsPaymentsInProgress,
    errors: state.forms.errors[formId] || {}
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    getMonthsForCharges,
    getMonthsForPayments,
    downloadActSV,
    flushErrors
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActSVModal);