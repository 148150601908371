import React, { useCallback } from 'react';
import {
    ScrollView,
    StyleSheet,
    TouchableOpacity,
    Platform,
    Text,
    Linking
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';

import { getChannel } from '@netangels/core/utils/websockets';
import { userChannelName } from '@netangels/core/constants/websockets';

import {
    wsAccountVerifyPhone,
    wsAccountSendVerifyPhone,
    wsAccountAddVerifiedEmail,
    wsAccountChangeRequisites,
    wsAccountAddUnverifiedEmail,
    wsAccountDeleteUnverifiedEmail,
    wsAccountChangeNotificationPhone
} from '../../../actions/account/actions';
import AccountUser from '../AccountUser/AccountUser';
import { logout } from '../../../actions/user/actions';
import AccountPhone from '../AccountPhone/AccountPhone';
import useWebsockets from '../../../hooks/useWebsockets';
import AccountEmails from '../AccountEmails/AccountEmails';
import AccountRequisites from '../AccountRequisites/AccountRequisites';
import { connectToWebsocketWithDelay } from '../../../utils/websockets';
import AccountAccessSecurity from '../AccountAccessSecurity/AccountAccessSecurity';

function AccountScreen() {
    const ws = useWebsockets();
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const { user } = useSelector((state) => state.user);
    const { account } = useSelector((state) => state.account);
    let accountSubscriber = null;

    useFocusEffect(useCallback(() => {
        const connectionState = connectToWebsocketWithDelay(() => {
            if (accountSubscriber == null) {
                const channelName = getChannel(userChannelName, user.login, true);

                accountSubscriber = ws.subscriber('account', channelName);
                accountSubscriber.on('email.unverified_add', (data) => {
                    dispatch(wsAccountAddUnverifiedEmail(data));
                })
                    .on('email.delete', (data) => {
                        dispatch(wsAccountDeleteUnverifiedEmail(data));
                    })
                    .on('email.verify', (data) => {
                        dispatch(wsAccountAddVerifiedEmail(data));
                    })
                    .on('phone.phone_notification_change', (data) => {
                        dispatch(wsAccountChangeNotificationPhone(data));
                    })
                    .on('phone.send_verify', (data) => {
                        dispatch(wsAccountSendVerifyPhone(data));
                    })
                    .on('phone.verify', (data) => {
                        dispatch(wsAccountVerifyPhone(data));
                    })
                    .on('requisites.change', (data) => {
                        dispatch(wsAccountChangeRequisites(data));
                    });
            }
        });
        return () => {
            connectionState.cancel();
            if (accountSubscriber != null) {
                ws.unsubscribe(accountSubscriber);
                accountSubscriber = null;
            }
        };
    }, []));

    return <ScrollView style={styles.wrapper}>
        {account && <AccountUser />}
        {account && <AccountPhone />}
        {account && <AccountAccessSecurity />}
        {account && <AccountEmails />}
        {account && <AccountRequisites />}
        {account && <React.Fragment>
            <TouchableOpacity
                onPress={() => Linking.openURL('/to-desktop')}
                style={styles.actionBlock}
            >
                <Text style={styles.action}>Перейти к полной версии</Text>
            </TouchableOpacity>
            <TouchableOpacity
                onPress={() => dispatch(
                    logout(() => {
                        if (Platform.OS === 'web') {
                            document.location = '/auth';
                        } else {
                            navigation.navigate('Auth');
                        }
                    })
                )}
                style={[styles.actionBlock, { marginBottom: 50 }]}
            >
                <Text style={styles.action}>Выйти из аккаунта</Text>
            </TouchableOpacity>
        </React.Fragment>}
    </ScrollView>;
}

const styles = StyleSheet.create({
    wrapper: {
        paddingLeft: 18,
        paddingRight: 18,
    },
    actionBlock: {
        borderRadius: 8,
        backgroundColor: '#ffffff',
        marginTop: 12,
        paddingBottom: 14,
        paddingTop: 12,
    },
    action: {
        color: '#0f79d5',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 14,
    }
});

export default AccountScreen;
