import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import {
    downloadAct,
    getMonthsForActs
} from '../../../actions/finances/actions';
import Errors from '../../General/Form/Errors';
import SelectModal from '../../General/SelectModal/SelectModal';
import AcceptButton from '../../General/Modal/AcceptButton';
import { flushErrors } from '../../../actions/forms/actions';

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    acceptButton: {
        width: '100%',
        borderRightWidth: 0,
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorBlock: {
        marginTop: 10
    }
});

const formId = 'ActOfCompletionOrderModalForm';

function normalizeOptions(options) {
    if (options && options.length > 0) {
        return options.map((option, i) => {
            const label = dayjs(option, 'YYYY-MM-DD').format('MMMM YYYY');
            return {
                value: option,
                label: label[0].toUpperCase() + label.slice(1),
                index: i,
            };
        });
    }
    return [];
}

function ActOfCompletionOrderModal(props) {
    const {
        onClose,
        inProgress,
        flushErrors,
        downloadAct,
        getMonthsForActs,
        chargesMonthsForAct,
        chargesMonthsForActInProgress,
        errors
    } = props;

    React.useEffect(() => {
        flushErrors();
        if (!chargesMonthsForActInProgress && chargesMonthsForAct.length === 0) {
            getMonthsForActs();
        }
    }, []);
    const normalizedOptions = normalizeOptions(chargesMonthsForAct);
    const [date, setDate] = React.useState(null);
    React.useEffect(() => {
        if (normalizedOptions.length !== 0 && !date) {
            setDate(normalizedOptions[0]);
        }
    }, [normalizedOptions]);
    const initialIndex = date
        ? normalizedOptions.findIndex((d) => d.value === date.value)
        : 0;
    return <View style={[styles.wrapper]}>
        <Text style={[styles.title]}>УПД</Text>
        <View style={{marginLeft: 1}}>
            <SelectModal
                initialSelectedIndex={initialIndex}
                onChange={(value) => setDate(value)}
                currentItem={date || normalizedOptions[0]}
                itemList={normalizedOptions}/>
        </View>
        <View style={[styles.errorBlock]}>
            <Errors errors={errors.__all__} />
        </View>
        <View style={styles.buttonBlock}>
            <AcceptButton
                additionalButtonStyles={[styles.acceptButton]}
                onSubmit={() => downloadAct(formId, dayjs(date.value).format('YYYY-MM-DD'), onClose)}
                title='Скачать акт'
                inProgress={inProgress}/>
        </View>
    </View>;
}

ActOfCompletionOrderModal.propTypes = {
    inProgress: PropTypes.bool,
    flushErrors: PropTypes.func,
    downloadAct: PropTypes.func,
    errors: PropTypes.shape({}),
    onClose: PropTypes.func,
    chargesMonthsForAct: PropTypes.arrayOf(PropTypes.string),
    chargesMonthsForActInProgress: PropTypes.bool,
    getMonthsForActs: PropTypes.func
};

const mapStateToProps = (state) => ({
    errors: state.forms.errors[formId] || {},
    chargesMonthsForAct: state.finance.chargesMonthsForAct,
    inProgress: state.finance.downloadActInProgress,
    chargesMonthsForActInProgress: state.finance.chargesMonthsForActInProgress
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    downloadAct, flushErrors, getMonthsForActs
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActOfCompletionOrderModal);