import {
    HOSTING_RESTORE_FROM_BACKUP_TO_INIT,
    HOSTING_RESTORE_FROM_BACKUP_UPDATE_PARAMS,
    CONTAINER_BACKUPS_RESTORE_SUCCESSFULLY,
    CONTAINER_BACKUPS_RESTORE_IN_PROGRESS,
    CONTAINER_BACKUPS_RESTORE_WITH_ERRORS,
} from '../../actions/hosting/actionTypes';

export default () => {
    const defaultState = {
        currentContainer: null,
        currentVirtualhost: null,
        currentDbms: null,
        restoreDate: null,
        currentMethod: null,
        filesForArchive: [],
        newDbms: null,
        currentDbmsMethod: null,
        restoreDbmsDate: null,
        currentVirtualhostObject: null,
        restoreInProgress: false,
        restoreDateFromParams: null,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case HOSTING_RESTORE_FROM_BACKUP_UPDATE_PARAMS: {
            return {
                ...state,
                ...action.data,
            };
        }
        case HOSTING_RESTORE_FROM_BACKUP_TO_INIT: {
            return defaultState;
        }
        case CONTAINER_BACKUPS_RESTORE_IN_PROGRESS: {
            return {
                ...state,
                restoreInProgress: true,
            };
        }
        case CONTAINER_BACKUPS_RESTORE_SUCCESSFULLY: {
            return {
                ...state,
                restoreInProgress: false,
            };
        }
        case CONTAINER_BACKUPS_RESTORE_WITH_ERRORS: {
            return {
                ...state,
                restoreInProgress: false,
            };
        }
        default:
            return state;
        }
    };
};
