import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    Image
} from 'react-native';

import Button from '../General/Button/Button';
import { siteEmptyHostingIcon } from '../../icons';

function VirtualhostsEmptyPage() {
    const navigation = useNavigation();
    const { container } = useSelector((state) => state.hosting);

    return <View style={styles.wrapper}>
        <Image source={siteEmptyHostingIcon} style={styles.image} />
        <View style={styles.content}>
            <Text style={styles.title}>В контейнере пока нет сайтов</Text>
            <Text style={styles.description}>Для начала, можно создать один</Text>
        </View>
        <Button title='Создать первый сайт'
            onPress={() => navigation.navigate('HostingCreateVirtualhost', {
                containerId: container.id
            })}
            additionalWrapperStyles={[{ width: '100%' }]}
            additionalButtonStyles={[{ paddingTop: 15, paddingBottom: 15 }]}
            additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15 }]}/>
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 90,
        padding: 28,
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'colume',
        alignItems: 'center',
        justifyContent: 'start',
    },
    image: {
        minHeight: 90,
        minWidth: 89,
        marginBottom: 23,
    },
    title: {
        maxWidth: 320,
        textAlign: 'center',
        fontSize: 16,
        color: '#455562',
        letterSpacing: 0,
        lineHeight: 19,
        marginBottom: 8,
    },
    description: {
        maxWidth: 258,
        fontSize: 12,
        textAlign: 'center',
        letterSpacing: 0,
        color: '#455562',
        lineHeight: 18,
        marginBottom: 32,
    },
});

export default VirtualhostsEmptyPage;