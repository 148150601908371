import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ScrollView } from 'react-native';

import FinanceBalance from '../FinanceBalance/FinanceBalance';
import FinanceCoupon from '../FinanceCoupon/FinanceCoupon';
import PromisedPaymentBanner from '../PromisedPaymentBanner/PromisedPaymentBanner';
import FinanceAwaitingPackets from '../FinanceAwaitingPackets/FinanceAwaitingPackets';
import FinanceCostsMain from '../FinanceCosts/FinanceCostsMain';

function FinanceBalanceTab(props) {
    const {
        awaitingPackets,
        creditInfo
    } = props;

    return <ScrollView>
        <FinanceBalance />
        <FinanceCoupon />
        {creditInfo.enabled_to != null && <PromisedPaymentBanner/>}
        {awaitingPackets && awaitingPackets.length !== 0 && <FinanceAwaitingPackets />}
        <FinanceCostsMain />
    </ScrollView>;
}

FinanceBalanceTab.propTypes = {
    creditInfo: PropTypes.shape({
        enabled_to: PropTypes.string
    }),
    awaitingPackets: PropTypes.arrayOf(PropTypes.shape({
        cost: PropTypes.number,
        service: PropTypes.string,
        resource_id: PropTypes.string,
    }))
};

const mapStateToProps = (state) => ({
    creditInfo: state.user.financeInfo.credit_info,
    awaitingPackets: state.finance.awaitingPackets
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FinanceBalanceTab);
