import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Image,
    Text,
    View,
    Linking,
    StyleSheet,
    TouchableOpacity,
} from 'react-native';
import Button from '../components/General/Button/Button';
import { netangelsLogoIcon } from '../icons';

const styles = StyleSheet.create({
    logoWrapper: {
        flex: 0.1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    authScreen: {
        marginTop: '11%',
        padding: 22,
        flex: 1,
        flexDirection: 'column'
    },
    headerText: {
        fontSize: 20,
        marginBottom: 30,
        textAlign: 'center',
        lineHeight: 25,
        color: '#455562'
    },
    form: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 4,
    },
    text: {
        color: '#455562',
        fontSize: 14,
        lineHeight: 18,
        fontWeight: 400,
        marginBottom: 22,
    },
    supportLink: {
        color: '#0f79d5',
        fontSize: 14,
        fontWeight: 400,
        marginLeft: 3,
    },
    hint: {
        color: 'rgba(69, 85, 98, 0.70)',
        fontSize: 12,
        lineHeight: 16,
        fontWeight: 400,
        marginTop: 22,
        marginLeft: 12,
        marginRight: 12
    },
    hintLinkBlock: {
        marginTop: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    hintLink: {
        color: '#0f79d5',
        fontSize: 14,
        lineHeight: 20,
        fontWeight: 400,
        paddingBottom: 3,
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderStyle: 'dashed',
    },
    mediaLink: {
        color: '#0f79d5',
        fontSize: 12,
        fontWeight: 400,
        marginLeft: 2,
        marginRight: 2,
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderStyle: 'solid',
    },
    button: {
        borderRadius: 4,
    },
    link: {
        marginLeft: 2,
        color: '#0f79d5',
    },
});

function RestorePasswordDoneScreen(props) {
    const { navigation } = props;
    const [isOpenHint, setIsOpenHint] = useState(false);
    const restoreTransport = useSelector((state) => state.user.passwordRestoreTransport);

    return <View style={styles.authScreen}>
        <View style={styles.logoWrapper}>
            <Image source={netangelsLogoIcon} style={{ width: 162, height: 30 }} />
        </View>
        <View style={styles.form}>
            <Text style={styles.headerText}>
                Ссылка для восстановления пароля отправлена{' '}
                {restoreTransport === 'sms'
                    ? 'в SMS'
                    : 'на почту'}
            </Text>
            <Text style={styles.text}>
                Если ссылка не пришла в течение 5 минут, обратитесь в
                <Text style={styles.supportLink}
                    onPress={() => Linking.openURL('mailto:info@netangels.ru')}>
                    службу технической поддержки
                </Text>
            </Text>
            <Button
                style={styles.button}
                onPress={() => navigation.navigate('Auth')}
                title="Понятно, спасибо"
            />
            <TouchableOpacity
                style={styles.hintLinkBlock}
                onPress={() => setIsOpenHint(!isOpenHint)}
            >
                <Text
                    style={styles.hintLink}
                    onClick={() => setIsOpenHint(!isOpenHint)}
                >
                    Нет доступа к почте и телефону?
                </Text>
            </TouchableOpacity>
            {isOpenHint && (
                <Text style={styles.hint}>
                    Владельцы договоров, оформленных на ООО и ИП, могут заполнить
                    <Text style={styles.mediaLink}
                        onPress={() => Linking.openURL('https://media.netangels.ru/website/media/e-mail.pdf')}>
                        заявление
                    </Text>
                    на добавление нового контактного почтового ящика и направить на
                    <Text style={styles.link}
                        onPress={() => Linking.openURL('mailto:fin@netangels.ru')}>
                        нашу почту
                    </Text>.
                </Text>
            )}
        </View>
    </View>;
}

RestorePasswordDoneScreen.propTypes = {
    navigation: PropTypes.shape({}),
};

export default RestorePasswordDoneScreen;
