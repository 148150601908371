import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import {
    setSwipeState
} from '../../../actions/ui/actions';
import {
    redisUpdate,
    redisDelete,
    rawCalculate,
    memcacheUpdate,
    memcacheDelete,
    containerChangeQuota,
    recalculateContainerPrice,
} from '../../../actions/hosting/actions';
import {
    unwrapRedisApplicationMemory,
    unwrapMemcachedApplicationMemory,
} from '../utils';
import TariffBlock from './HostingTariffBlock';
import Errors from '../../General/Form/Errors';
import { calculatePrice } from '../calculation';
import { mbToGb } from '../../../utils/general';
import Switch from '../../General/Switch/Switch';
import Button from '../../General/Button/Button';
import { normalizeContainerPrice } from '../utils';
import StatisticsMemory from '../Statistics/StatisticsMemory';
import SliderDynamic from '../../General/Slider/SliderDynamic';
import { DISABLED_BY_QUOTA } from '../../../constants/general';
import StatisticsTenDaysCPU from '../Statistics/StatisticsTenDaysCPU';
import StatisticsTenDaysRAM from '../Statistics/StatisticsTenDaysRAM';
import ResourceLoader from '../../General/ResourceLoader/ResourceLoader';
import ContainerAnnual from './ContainerAnnual';

const formIdChangeQuota = 'ChangeQuota';

function changeQuota(container, containerChangeQuota, rawCalculate, value, callback) {
    containerChangeQuota(formIdChangeQuota, container.id, value, callback);
    rawCalculate(
        container.login,
        container.memory_limit,
        value,
        unwrapRedisApplicationMemory(container),
        unwrapMemcachedApplicationMemory(container)
    );
}

function updateResource(container, resourceUpdate, resourceDelete, rawCalculate, value, unwrapMemcachedApplication) {
    if (value) {
        resourceUpdate(container.id, value);
    } else {
        resourceDelete(container.id);
    }
    rawCalculate(
        container.login,
        container.memory_limit,
        container.quota,
        value || 0,
        unwrapMemcachedApplication(container)
    );
}

function monthPrice(price, quota, redis, memcache, amount, memoryLimit) {
    const getApplicationMemoryLimit = app => (app != null ? app.memory_limit : 0);
    if (amount) {
        return amount;
    }
    return normalizeContainerPrice(calculatePrice(
        memoryLimit,
        quota / 1024,
        getApplicationMemoryLimit(redis),
        getApplicationMemoryLimit(memcache),
        price
    ));
}

function Application({ application }) {
    return <View style={styles.applicationBlock}
         style={!application.isEnable ? [styles.applicationBlock, styles.disabledBlock] : styles.applicationBlock}>
        <View style={styles.application}>
            <Text style={styles.applicationTitle}>{application.title}</Text>
            <View style={styles.spring}/>
            {application.value != null && application.isChecked && <Text style={styles.applicationValue}>
                {application.value} МБ
            </Text>}
            <View>
                <Switch
                    isEnable={application.isChecked}
                    onChange={() => application.onChangeSwitch()}/>
            </View>
        </View>
        {application.isChecked && <SliderDynamic
            value={application.value}
            items={application.scale}
            unit='МБ'
            notNeedToShowValue={true}
            onChange={(value) => {
                application.onChangeSlider(value)
            }}
            onBeforeChange={() => {
                application.onBeforeChange()
            }}
            onAfterChange={(value) => {
                application.onAfterChangeSlider(value)
            }}
            />}
        {!application.isChecked && <Text style={styles.applicationHint}>
            {application.hint}
        </Text>}
    </View>
}

function ContainerTariffTab(props) {
    const {
        errors,
        inProgress,
        container,
        redisUpdate,
        redisDelete,
        rawCalculate,
        setSwipeState,
        memcacheUpdate,
        memcacheDelete,
        calculationData,
        containerChangeQuota,
    } = props;
    if (container == null || (container && container.limits == null)) {
        return <View>
            <ResourceLoader height={66} count={3}/>
            <View style={styles.loaderPadding}/>
            <ResourceLoader height={69} count={1}/>
            <View style={styles.loaderPadding}/>
            <ResourceLoader height={92} count={1}/>
            </View>;
    }
    const storageLimits = container.limits.limits.storage;
    const canResourceChange = container.enabled || container.state === DISABLED_BY_QUOTA;
    const storageMin = container.state === DISABLED_BY_QUOTA ? mbToGb(container.quota) : mbToGb(storageLimits.min);
    const storageMax = mbToGb(storageLimits.max);
    const storageScale = [];
    for (let i = storageMin; i <= storageMax; i++) {
        storageScale.push(i);
    }

    const redisScale = [];
    for (let j = 256; j <= 2048; j = j + 256) {
        redisScale.push(j);
    }
    const [redis, setRedis] = React.useState(container.redis ? container.redis.memory_limit : null);
    const [memcached, setMemcached] = React.useState(container.memcache ? container.memcache.memory_limit : null);
    const [currentStorage, setCurrentStorage] = React.useState();
    const [isChangeStorage, setChangeStorage] = React.useState(false);

    const applications = [{
        title: 'Redis',
        value: redis,
        isChecked: container.redis != null,
        scale: redisScale,
        isEnable: canResourceChange,
        hint: 'Включить Redis, одну из самых популярных NoSQL баз данных в мире.',
        onChangeSwitch: () => {
            if (canResourceChange) {
                const redisValue = container.redis != null ? null : 256;
                updateResource(container, redisUpdate, redisDelete, rawCalculate, redisValue, unwrapMemcachedApplicationMemory)
                recalculateContainerPrice(
                    container.login,
                    container.memory_limit,
                    container.quota / 1024,
                    redisValue,
                    unwrapMemcachedApplicationMemory(container),
                    false
                );
            }
        },
        onBeforeChange: () => setSwipeState(true),
        onChangeSlider: (value) => {
            if (canResourceChange) {
                setRedis(value);
                recalculateContainerPrice(
                    container.login,
                    container.memory_limit,
                    container.quota / 1024,
                    value,
                    unwrapMemcachedApplicationMemory(container),
                    false
                );
            }
        },
        onAfterChangeSlider: (value) => {
            if (canResourceChange) {
                setSwipeState(false);
                updateResource(container, redisUpdate, redisDelete, rawCalculate, value, unwrapMemcachedApplicationMemory);
            }
        }
    }, {
        title: 'Memcached',
        value: memcached,
        isChecked: container.memcache != null,
        scale: redisScale,
        isEnable: canResourceChange,
        hint: 'Включить кеширующее хранилище Memcached.',
        onChangeSwitch: () => {
            if (canResourceChange) {
                const memcacheValue = container.memcache != null ? null : 256;
                updateResource(container, memcacheUpdate, memcacheDelete, rawCalculate, memcacheValue, unwrapRedisApplicationMemory)
                recalculateContainerPrice(
                    container.login,
                    container.memory_limit,
                    container.quota / 1024,
                    memcacheValue,
                    unwrapRedisApplicationMemory(container),
                    false
                );
            }
        },
        onAfterChangeSlider: (value) => {
            if (canResourceChange) {
                setSwipeState(false);
                updateResource(container, memcacheUpdate, memcacheDelete, rawCalculate, value, unwrapRedisApplicationMemory);
            }
        },
        onBeforeChange: () => setSwipeState(true),
        onChangeSlider: (value) => {
            if (canResourceChange) {
                setMemcached(value);
                recalculateContainerPrice(
                    container.login,
                    container.memory_limit,
                    container.quota / 1024,
                    value,
                    unwrapRedisApplicationMemory(container),
                    false
                );
            }
        },
    }];
    return <View style={styles.wrapper}>
        <View style={styles.applicationsTitleBlock}>
            <Text style={styles.applicationsTitle}>потребление</Text>
            <Text style={styles.priceTitle}>{calculationData && calculationData[container.login]
                && normalizeContainerPrice(
                    calculationData[container.login]
                        .fullMonthPrice,
                )}{' '}
            ₽/мес</Text>
        </View>
        <View style={styles.statistics}>
            <StatisticsTenDaysCPU/>
            <StatisticsTenDaysRAM/>
            <StatisticsMemory />
        </View>
        <TariffBlock container={container}/>
        <View style={!canResourceChange ? [styles.storageBlock, styles.disabledBlock] : styles.storageBlock}>
            <SliderDynamic
                title='Диск'
                value={mbToGb(container.quota)}
                items={storageScale}
                unit='ГБ'
                disabled={!canResourceChange}
                onAfterChange={(value) => {
                    if (canResourceChange) {
                        setSwipeState(false);
                        setCurrentStorage(value);
                        setChangeStorage(true);
                    }
                }}
                onBeforeChange={() => setSwipeState(true)}
                onChange={(value) => {
                    if (canResourceChange) {
                        recalculateContainerPrice(
                            container.login,
                            container.memory_limit,
                            value,
                            unwrapRedisApplicationMemory(container),
                            unwrapMemcachedApplicationMemory(container),
                            false
                        );
                    }
                }}/>
            {errors && errors.formIdChangeQuota && <Errors errors={errors.formIdChangeQuota.errors.quota} />}
        </View>
        {isChangeStorage && <Button title='Изменить размер'
            inProgress={inProgress}
            additionalWrapperStyles={[{ maxWidth: '100%', marginLeft: 20, marginRight: 20, }]}
            additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 13 }]}
            additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, minHeight: 16 }]}
            onPress={() => {
                if (canResourceChange) {
                    if (currentStorage !== mbToGb(container.quota)) {
                        changeQuota(
                            container,
                            containerChangeQuota,
                            rawCalculate,
                            currentStorage * 1024,
                            () => setChangeStorage(false)
                        );
                    }
                }
            }}/>}
        <View>
            <View style={styles.applicationsTitleBlock}>
                <Text style={styles.applicationsTitle}>приложения</Text>
            </View>
            {applications.map((application, a) => <Application
                application={application}
                key={`hosting-container-application-${a}`}/>)}
        </View>
        {calculationData && <ContainerAnnual
            calculationData={calculationData[container.login]}
            isEnabled={container.enabled}
        />}
    </View>;
}

ContainerTariffTab.propTypes = {
    container: PropTypes.shape({}),
    rawCalculate: PropTypes.func,
    containerChangeQuota: PropTypes.func,
    errors: PropTypes.shape({}),
    redisUpdate: PropTypes.func,
    redisDelete: PropTypes.func,
    memcacheUpdate: PropTypes.func,
    memcacheDelete: PropTypes.func,
    setSwipeState: PropTypes.func,
    inProgress: PropTypes.bool,
    calculationData: PropTypes.shape({})
};

const mapStateToProps = (state) => ({
    container: state.hosting.container,
    errors: state.forms.errors,
    inProgress: state.hosting.containerUpdateInProgress,
    calculationData: state.hosting.calculationData,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    redisUpdate,
    redisDelete,
    rawCalculate,
    setSwipeState,
    memcacheUpdate,
    memcacheDelete,
    containerChangeQuota,
}, dispatch);

const styles = StyleSheet.create({
    storageBlock: {
        marginBottom: 12,
        paddingTop: 17,
        paddingBottom: 17,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: '#ffffff',
    },
    disabledBlock: {
        backgroundColor: 'rgba(136, 136, 136, 0.2)',
        borderBottomColor: 'rgba(69, 85, 98, 0.2)',
        opacity: 0.7
    },
    statistics: {
        marginBottom: 10,
    },
    applicationBlock: {
        paddingTop: 17,
        paddingBottom: 17,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: '#ffffff',
        borderBottomColor: '#E4E4E4',
        borderBottomWidth: 1,
    },
    application: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 8,
    },
    applicationTitle: {
        fontSize: 13,
        color: '#455562',
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 18,
    },
    applicationHint: {
        fontSize: 12,
        color: '#888888',
    },
    spring: {
        flex: 1,
    },
    applicationValue: {
        color: '#0F79D5',
        fontSize: 12,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 14,
        textAlign: 'right',
        marginRight: 10,
    },
    switch: {
        width: '100%',
        paddingBottom: 10,
        color: '#455562',
    },
    applicationsTitleBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 11,
        paddingLeft: 20,
        paddingRight: 26,
        marginTop: 10,
    },
    applicationsTitle: {
        opacity: 0.75,
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: '1.38px',
        lineHeight: 13,
        textTransform: 'uppercase',
    },
    priceTitle: {
        opacity: 0.75,
        color: '#455562',
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 13,
    },
    loaderPadding: {
        paddingTop: 10,
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ContainerTariffTab);