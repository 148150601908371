import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, StyleSheet } from 'react-native';

import { certificateProlong } from '../../../actions/certificates/actions';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';
import pricesMap from '../../../../data/certificates/prices.json';

const formId = 'ProlongModalForm';

function ProlongModal(props) {
    const {
        needReorder,
        certificate,
        onClose,
    } = props;
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);
    const { updateCertificateInProgress } = useSelector((state) => state.certificates);

    const pricesForPublisher = pricesMap[certificate.publisher];
    const isWildcard = certificate.domains.findIndex((domain) => domain.startsWith('*.')) !== -1;
    const price = isWildcard
        ? pricesForPublisher.wildcard
        : pricesForPublisher.single;
    const buttonTitle = needReorder ? 'Перевыпустить' : 'Продлить';

    return <View style={styles.wrapper}>
        <Text style={styles.title}>
            {buttonTitle} сертификат
        </Text>
        {price > 0 && !needReorder && <Text style={styles.text}>
            Сертификат будет продлен на год за {price} ₽
        </Text>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => dispatch(
                    certificateProlong(
                        formId,
                        certificate.id,
                        () => onClose(),
                    ),
                )}
                title={buttonTitle}
                type='blue'
                inProgress={updateCertificateInProgress}/>
            <CancelButton onClose={onClose} disabled={updateCertificateInProgress} />
        </View>
    </View>;
}

ProlongModal.propTypes = {
    onClose: PropTypes.func,
    needReorder: PropTypes.bool,
    certificate: PropTypes.shape({
        is_renewal: PropTypes.bool,
        id: PropTypes.number,
        publisher: PropTypes.string,
        domains: PropTypes.arrayOf(PropTypes.string),
    }),
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 18,
        lineHeight: 20,
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    text: {
        color: '#455562',
        fontWeight: '400',
        fontSize: 12,
        lineHeight: 16,
    }
});

export default ProlongModal;
