import * as dayjs from 'dayjs';
import { Platform } from 'react-native';

import { tariffs } from '../constants/hosting';
import { thresholdColorsClass } from '../constants/general';
import { couponServicesMap } from '../components/Finance/constants';

export const upperFirst = (v) => v.slice(0, 1).toUpperCase() + v.slice(1);

export const isArray = (obj) => Object.prototype.toString.call(obj) === '[object Array]';
export const isPlainObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]';
export const isFunction = (obj) => Object.prototype.toString.call(obj) === '[object Function]';
export const isString = (obj) => typeof obj === 'string';
export const isEmpty = obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;

export const formatMemory = (value, needFixed) => {
    if (value < 1024) {
        return `${value} МБ`;
    }
    if (needFixed) {
        return `${Number.parseFloat((value / 1024).toFixed(2))} ГБ`;
    }
    return `${value / 1024} ГБ`;
};

export const pluralize = (amount, variants) => {
    let variant = 2;
    if (amount % 10 === 1 && amount % 100 !== 11) {
        variant = 0;
    } else if (amount % 10 >= 2 && amount % 10 <= 4 && (amount % 100 < 10 || amount % 100 >= 20)) {
        variant = 1;
    }
    return variants[variant];
};

export const normalizeNotificationDate = (date) => {
    const parsedDate = new Date(date);
    const currentMoment = new Date();
    const isCurrentMonth = currentMoment.getMonth() === parsedDate.getMonth();
    const deltaDays = currentMoment.getDate() - parsedDate.getDate();
    if (isCurrentMonth && deltaDays === 0) {
        return `${parsedDate.getHours() + 5}:${parsedDate.getMinutes().toString().padStart(2, '0')}`;
    }
    if (isCurrentMonth && deltaDays === 1) {
        return 'вчера';
    }
    return notificationFormatDate(parsedDate);
};

export const notificationFormatDate = (timestamp) => {
	const date = new Date(timestamp);
	const months = ['янв.', 'фев.', 'мар.', 'aпр.', 'май.', 'июн.', 'июл.', 'авг.', 'сен.', 'окт.', 'ноя.', 'дек.'];

	return date.getDate() + ' ' + months[date.getMonth()];

};
export const sumBy = (arr, fn) => arr.map(typeof fn === 'function' ? fn : val => val[fn])
    .reduce((acc, val) => acc + val, 0);

export const getColorTypeCreator = (start, end, prefix = 'customColor') => (i) => {
    const colorIdx = ((start + 1) + i) % end;
    return `${prefix}${colorIdx}`;
};

export const getThresholdColorClass = (value) => {
    for (const colorData of thresholdColorsClass) {
        const [threshold, className] = colorData;
        if (value <= threshold) {
            return className;
        }
    }
    return 'safe';
};

export const hashCode = (string) => {
    let hash = 0;
    if (string.length === 0) {
        return hash;
    }
    for (let i = 0; i < string.length; i += 1) {
        const char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char; // eslint-disable-line no-bitwise
        hash &= hash; // eslint-disable-line no-bitwise
    }
    return hash;
};

export const clamp = (value, min = 0, max = 100) => {
    if (value < min) {
        return min;
    }
    if (value > max) {
        return max;
    }
    return value;
};

export const getColorType = getColorTypeCreator(1, 40);

export const packetKey = (packet) =>  `${packet.service}:${packet.resource_id}`;

export const dayjsLocal = () => require('dayjs/locale/ru');

export const mbToGb = (value, isRound = true) => {
    const rawValue = value / 1024;
    if (isRound === false) {
        return rawValue;
    }
    return Math.round(rawValue);
};

export const bToMb = (value, isRound = true) => {
    const rawValue = value / 1024 / 1024;
    if (isRound === false) {
        return rawValue;
    }
    return Math.round(rawValue);
};

export const getUnique = (arr) =>  arr.filter((v, i, a) => a.indexOf(v) === i);

export const downloadFile = (file, filename) => {
    Platform.select({
        web: () => {
            const url = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.style = 'display: none';
            a.href = url;
            a.download = filename || 'download';
            const clickHandler = () => {
                setTimeout(() => {
                    a.removeEventListener('click', clickHandler);
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                }, 150);
            };
            document.body.appendChild(a);
            a.addEventListener('click', clickHandler, false);
            a.click();
            return a;
        },
        default: () => {}
    })();
};

export const generatePassword = (length, charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789") => {
    let newPassword = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        newPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    return newPassword;
};

export const getResourceHashName = (resource) => {
    const resourceName = resource.resource_name || 'other';

    return `${resource.service}:${resourceName}`;
};

export const getResourceHashCode = resource => Math.abs(
    hashCode(getResourceHashName(resource))
);

export const getPercent = (upper, current) => Math.round((current * 100) / upper);

export const getResourceColorType = resource => getColorType(getResourceHashCode(resource));

export const uniqBy = (arr, predicate) => {
    const cb = isFunction(predicate) ? predicate : (o) => o[predicate];
    return [...arr.reduce((map, item) => {
        const key = item == null ? item : cb(item);
        map.has(key) || map.set(key, item);
        return map;
    }, new Map()).values()];
};

export const fromWsCouponToCoupon = coupon => ({
    amount: coupon.amount,
    balance: coupon.balance,
    enabled_to: dayjs(coupon.enabled_to, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
    id: coupon.id,
    is_enabled: coupon.is_enabled,
    service: coupon.service,
    tariff: couponServicesMap[coupon.service],
    token: coupon.token
});

export const canNotStartSearch = (request, minQueryLength = 3) => request.length >= 0
    && request.length < minQueryLength;

export const pickBy = (object, predicate) => {
    const obj = {};
    for (const key in object) {
        if (predicate(object[key])) {
            obj[key] = object[key];
        }
    }
    return obj;
};

export const detectTariffByRam = (memory) => {
    for (const tariff of tariffs) {
        if (memory <= tariff.ram) {
            return tariff;
        }
    }
    return tariffs[tariffs.length - 1];
};

export const normalizeContainerPrice = (value) => Math.ceil(value).toFixed(0);

export const formatDate = (date, format) => dayjs(date).format(format);

export function formatPrice(price) {
    const formattedPrice = new Intl.NumberFormat("ru-RU").format(price)
    return formattedPrice;
};
