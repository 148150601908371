import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';


import Modal from '../../General/Modal/Modal';
import { closedIcon } from '../../../icons/index';
import { aliasDeleteAction } from '../../../actions/hosting/actions';
import VirtualhostAddAlias from '../HostingModal/VirtualhostAddAlias';

const formId = 'HostingVHAliasDelete';

function Alias({ alias, isMainAlias, virtualhostId, aliasDeleteAction, canDelete }) {
    return <View style={styles.alias}>
        <Text style={styles.aliasText}>{alias}</Text>
        {isMainAlias && <Text style={styles.isMainAlias}>основной</Text>}
        {!isMainAlias && canDelete &&<TouchableOpacity onPress={() => aliasDeleteAction(formId, virtualhostId, alias)}
            style={styles.closed}>
            <Image source={closedIcon} style={styles.closedIcon} />
        </TouchableOpacity>}
    </View>
}

function AliasesBlock(props) {
    const {
        enabled,
        aliases,
        mainAlias,
        virtualhostId,
        virtualhostName,
        aliasDeleteAction,
    } = props;
    const [isModalAddAlias, setModalAddAlias] = React.useState(false);

    return <View style={styles.wrapper}>
        <Text style={styles.title}>Домены (Алиасы)</Text>
        {aliases.map((item, i) => <Alias key={`alias-${i}-${item}`} alias={item}
            isMainAlias={mainAlias === item}
            virtualhostId={virtualhostId}
            canDelete={item !== virtualhostName && enabled}
            aliasDeleteAction={aliasDeleteAction}
        />)}
        <TouchableOpacity onPress={() => {
            if(enabled) {
                setModalAddAlias(true);
            }}}
            style={enabled ? styles.button : styles.disableButton }>
            <Text style={enabled ? styles.buttonText : styles.disableButtonText }>Добавить домен</Text>
        </TouchableOpacity>
        <Modal isModalShowed={isModalAddAlias}
            component={<VirtualhostAddAlias
                virtualhostId={virtualhostId}
                onClose={() => setModalAddAlias(false)}/>}
            onClose={() => setModalAddAlias(false)} />
    </View>;
}

AliasesBlock.propTypes = {
    aliases: PropTypes.arrayOf(PropTypes.string),
    virtualhostId: PropTypes.number,
    aliasDeleteAction: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    aliasDeleteAction
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: '#ffffff',
    },
    title: {
        opacity: '0.75',
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: '1.38px',
        lineHeight: 13,
        textTransform: 'uppercase',
        paddingTop: 21,
        paddingBottom: 21,
        paddingRight: 18,
        paddingLeft: 18,
        borderBottomColor: '#E4E4E4',
        borderBottomWidth: 1,
    },
    button: {
        paddingTop: 21,
        paddingBottom: 21,
        paddingRight: 18,
        paddingLeft: 18,
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
    },
    buttonText: {
        color: '#0F79D5',
    },
    disableButton: {
        paddingTop: 21,
        paddingBottom: 21,
        paddingRight: 18,
        paddingLeft: 18,
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
        opacity: '0.5',
        backgroundColor: '#888888'
    },
    disableButtonText: {
        color: '#ffffff',
    },
    alias: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomColor: '#E4E4E4',
        borderBottomWidth: 1,
    },
    aliasText: {
        flex: 1,
        color: '#455562',
        fontSize: 13,
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: 18,
        paddingLeft: 18,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    closed: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: 18,
        paddingLeft: 18,
    },
    closedIcon: {
        height: 10,
        width: 10,
    },
    isMainAlias: {
        color: '#A1AAB1',
        fontSize: 11,
        letterSpacing: 0,
        lineHeight: 13,
        paddingRight: 18,
        paddingLeft: 18,
        textAlign: 'right',
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AliasesBlock);