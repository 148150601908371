import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    Image,
    TouchableOpacity
} from 'react-native';

import { mainServices, mainMonitoringServicesList } from '../../constants/general';
import { eventsMapByService } from '../../utils/monitoring';
import Spring from '../General/Spring/Spring';
import ResourceLoader from '../General/ResourceLoader/ResourceLoader';

function MonitoringScreen() {
    const navigation = useNavigation();
    const { events, eventsInProgress } = useSelector(
        (state) => state.monitoring,
    );
    function servicesList() {
        return mainMonitoringServicesList.map((service) => {
            const eventsMap = eventsMapByService(
                events,
                service.service,
            );
            return <TouchableOpacity
                onPress={() => {
                    if (eventsMap.criticals.length !== 0
                        || eventsMap.warnings.length !== 0) {
                        navigation.navigate(service.screenName);
                    }
                }}
                key={`mobile-service-item-${service.service}`}
                style={styles.serviceItem}>
                <Image source={mainServices[service.service].image} style={styles.icon} />
                <Text style={styles.serviceItemTitle}>
                    {mainServices[service.service].title}
                </Text>
                <Spring />
                {eventsMap.warnings.length > 0
                    && <View style={[styles.eventsBlock, styles.eventsWarning]}>
                        <Text style={styles.eventsText}>{eventsMap.warnings.length}</Text>
                    </View>}
                {eventsMap.criticals.length > 0
                    && <View style={[styles.eventsBlock, styles.eventsCritical]}>
                        <Text style={styles.eventsText}>{eventsMap.criticals.length}</Text>
                    </View>}
                {eventsMap.criticals.length === 0
                    && eventsMap.warnings.length === 0
                    && <View style={styles.eventsBlock}>
                        <Text style={styles.eventsText}>ОК</Text>
                    </View>}
            </TouchableOpacity>;
        });
    }

    return <View style={styles.wrapper}>
        <View>
            {!eventsInProgress && servicesList(navigation)}
            {eventsInProgress && <ResourceLoader
                height={48}
                count={3}
                additionalStyles={[styles.serviceItemLoader]}
            />}
        </View>
    </View>;
}

const styles = StyleSheet.create({
    icon: {
        width: 24,
        height: 24,
        marginRight: 12
    },
    serviceItemLoader: {
        borderRadius: 16,
        marginLeft: 18,
        marginRight: 18,
        marginBottom: 12,
        borderBottomWidth: 0,
    },
    serviceItem: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#ffffff',
        borderRadius: 16,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 10,
        paddingBottom: 12,
        marginLeft: 18,
        marginRight: 18,
        marginBottom: 12,
        shadowColor: '#000000',
        shadowOpacity: 0.03,
        shadowRadius: 10,
    },
    serviceItemTitle: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 24,
    },
    eventsBlock: {
        marginLeft: 4,
        paddingBottom: 5,
        paddingTop: 5,
        paddingLeft: 5,
        paddingRight: 5,
        minWidth: 24,
        textAlign: 'center',
        borderRadius: 8,
        backgroundColor: '#45ad85'
    },
    eventsWarning: {
        backgroundColor: '#e28a58'
    },
    eventsCritical: {
        backgroundColor: '#e07474'
    },
    eventsText: {
        color: '#ffffff',
        fontSize: 10,
        fontWeight: '500',
        lineHeight: 10,
    }
});

export default MonitoringScreen;
