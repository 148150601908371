import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';

import Input from '../../General/Form/Input';
import Button from '../../General/Button/Button';
import { buster, wheezy } from '../../../data/hosting/environments.json';
import { createContainerSetParams } from '../../../actions/hosting/actions';

const environments = [{
        type: 'new',
        title: 'Облачный хостинг',
        subtitle: 'Современное окружение',
        description: `PHP от ${buster.php.min} до ${buster.php.max}.
Поддержка Python, Node.js, Ruby.
БД MySQL, PostgreSQL, MongoDB.`
    }, {
        type: 'old',
        title: 'Виртуальный хостинг',
        subtitle: 'Старое окружение',
        description: `PHP от ${wheezy.php.min} до ${wheezy.php.max}.
Подходит для размещения сайтов
на PHP < 5.6`
    }
];

const steps = () => [{
        style: [styles.tab, styles.leftTab],
        step: 1,
        title: 'Имя и окружение'
    }, {
        style: [styles.tab, styles.disabledTab],
        step: 2,
        title: 'Тариф'
    }
];

function Environment({item, setTypeChecked, typeChecked}) {
    return <TouchableOpacity
        onPress={() => setTypeChecked(item.type)}
        style={typeChecked === item.type ? [styles.type, styles.typeChecked] : [styles.type, styles.typeUnchecked]}>
        <View style={typeChecked === item.type ? styles.typeCircleChecked : styles.typeCircle}>
            {typeChecked === item.type && <View style={styles.typeCircleSmall}></View>}
        </View>
        <View style={styles.descriptionBlock}>
            <Text style={typeChecked === item.type ? [styles.typeTitle, styles.typeTitleChecked] : [styles.typeTitle, styles.typeTitleUnchecked]}>
                {item.title}
            </Text>
            <Text style={styles.typeSubtitle}>{item.subtitle}</Text>
            <View style={styles.dashed}/>
            <Text style={styles.typeDescription}>{item.description}</Text>
        </View>
    </TouchableOpacity>;
}

function Tab({ step }) {
    return <View style={step.style}>
        <Text style={styles.tabCircle}>{step.step}</Text>
        <Text style={styles.tabText}>{step.title}</Text>
    </View>;
}

const formId = 'containerCreateForm';

function HostingCreateContainerStep1(props) {
    const { containerType, errors, createContainerSetParams } = props;
    const navigation = useNavigation();
    const [typeChecked, setTypeChecked] = React.useState(containerType);
    const [containerName, setContainerName] = React.useState(props.containerName || 'Контейнер для сайтов');
    return <View style={styles.wrapper}>
        <View style={styles.tabs}>
            {steps().map((step, s) => <Tab step={step} key={`create-container-step-${step.step}-${s}`}/>)}
        </View>
        <Input
            needBorder={true}
            errors={errors.name}
            label='Имя'
            additionalWrapperStyles={[{ marginBottom: 20 }]}
            textInputAttrs={{
                onChange: (e) => setContainerName(e.target.value.trimStart()),
                value: containerName
            }}
        />
        {environments.map((item, i) => <Environment
            key={`environment-${item.type}-${i}`}
            item={item}
            setTypeChecked={setTypeChecked}
            typeChecked={typeChecked} />)}
        <Button title='Продолжить'
            disabled={containerName.length === 0}
            onPress={() => createContainerSetParams({
                name: containerName, containerType: typeChecked
            }, () => navigation.navigate('HostingCreateContainerStep2'))}
            additionalWrapperStyles={[{ width: '100%', marginBottom: 14, marginTop: 14 }]}
            additionalButtonStyles={[{ paddingTop: 15, paddingBottom: 15 }]}
            additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15 }]}/>
        {containerName.length !== 0 && <Text style={styles.hint}>перейти к выбору тарифа</Text>}
    </View>;
}

HostingCreateContainerStep1.propTypes = {
    createContainerSetParams: PropTypes.func,
    containerType: PropTypes.string,
    containerName: PropTypes.string,
    errors: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({
    errors: state.forms.errors[formId] || {},
    containerName: state.hostingCreateContainer.containerName,
    containerType: state.hostingCreateContainer.containerType
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createContainerSetParams,
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 20,
        paddingLeft: 12,
        paddingRight: 12,
    },
    tabs: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
        marginLeft: 12,
        marginRight: 12,
    },
    tab: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
    },
    leftTab: {
        marginRight: 32,
    },
    disabledTab: {
        opacity: '50%',
    },
    tabText: {
        color: '#0F79D5',
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
    },
    tabCircle: {
        color: '#0F79D5',
        borderRadius: '50%',
        borderColor: '#0F79D5',
        fontWeight: '500',
        textAlign: 'center',
        borderWidth: 1,
        paddingTop: 4,
        paddingBottom: 5,
        paddingLeft: 8,
        paddingRight: 8,
        borderStyle: 'solid',
        marginRight: 12,
        backgroundColor: '#FFFFFF',
    },
    hint: {
        fontSize: 11,
        letterSpacing: 0,
        lineHeight: 13,
        color: '#455562',
        opacity: '0.5',
        textAlign: 'center',
    },
    typeCircle: {
        height: 18,
        width: 18,
        borderWidth: 1,
        marginRight: 12,
        borderColor: 'rgba(161,170,177,0.5)',
        borderRadius: 9,
        backgroundColor: '#F5F5F5',
    },
    typeCircleChecked: {
        height: 18,
        width: 18,
        borderWidth: 1,
        marginRight: 12,
        borderColor: '#0F79D5',
        borderRadius: 9,
        backgroundColor: '#FFFFFF',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    typeCircleSmall: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0F79D5'
    },
    type: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'start',
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        paddingTop: 16,
        paddingBottom: 19,
        paddingLeft: 14,
        paddingRight: 14,
        marginBottom: 10,
        minHeight: 134
    },
    typeChecked: {
        borderColor: '#0F79D5',
    },
    typeUnchecked: {
        borderColor: 'rgba(69,85,98,0.25)',
    },
    descriptionBlock: {
        width: 224,
    },
    typeTitle: {
        fontSize: 14,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 16,
        marginBottom: 2,
    },
    typeTitleChecked: {
        color: '#0F79D5',
    },
    typeTitleUnchecked: {
        color: '#455562',
    },
    typeSubtitle: {
        color: '#455562',
        fontSize: 11,
        letterSpacing: 0,
        lineHeight: 13,
        marginBottom: 12,
    },
    typeDescription: {
        fontSize: 10,
        letterSpacing: 0,
        lineHeight: 14,
        color: '#455562',
    },
    dashed: {
        borderStyle: 'dashed',
        borderColor: '#455562',
        borderWidth: 1,
        opacity: '0.25',
        marginBottom: 12,
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(HostingCreateContainerStep1);