import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Picker } from '@react-native-picker/picker';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';


function MobileContainerSelectModal(props) {
    const {
        onClose,
        onChange,
        containers,
        currentContainer,
        containerListCapabilities
    } = props;

    const [selectedValue, setSelectedValue] = React.useState(currentContainer);
    const items = containers.entities.map((container, i) => ({
        label: container.name,
        value: container.id,
        index: i
    }));
    return <View style={[styles.wrapper]}>
        <Text style={[styles.title]}>Выберите контейнер</Text>
        <Picker
            selectedValue={selectedValue}
            onValueChange={(itemValue, itemIndex) =>
                setSelectedValue(itemValue)
            }>
            {items.map(item => <Picker.Item key={`picker-item-${item.index}`}
                label={item.label} value={item.index} />)}
        </Picker>
        <View style={styles.buttonBlock}>
            <TouchableOpacity style={styles.acceptButton}
                onPress={() => {
                    onChange(selectedValue);
                    containerListCapabilities('containerListCapabilities', selectedValue.value, () => onClose());
                }}>
                <Text style={styles.blueText}>Выбрать</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button}
                onPress={onClose}><Text>Отменить</Text></TouchableOpacity>
        </View>
    </View>;
}

MobileContainerSelectModal.propTypes = {
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    containers: PropTypes.shape({}),
    currentContainer: PropTypes.shape({}),
    containerListCapabilities: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = () => ({});

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        width: 136,
        height: 46,
        fontWeight: '400',
        fontSize: 14,
        textAlign: 'center',
        color: '#455562',
        paddingTop: 8,
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
    },
    acceptButton: {
        width: 136,
        height: 46,
        fontWeight: '400',
        fontSize: 14,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#0f79d5',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
        borderRightWidth: 1,
        borderRightColor: '#cccccc',
    },
    blueText: {
        color: '#0f79d5',
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileContainerSelectModal);