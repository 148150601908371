import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TouchableOpacity, StyleSheet, View, Text, ScrollView } from 'react-native';

import InvoicesList from './InvoicesList';
import {
    getMonthsForActs,
    getListOfInvoices,
    getMonthsForPayments,
    getMonthsForPartnerReport
} from '../../../actions/finances/actions';
import Modal from '../../General/Modal/Modal';
import ActSVModal from '../FinanceModals/ActSVModal';
import InvoiceModal from '../FinanceModals/InvoiceModal';
import PartnerReportModal from '../FinanceModals/PartnerReportModal';
import ActOfCompletionOrderModal from '../FinanceModals/ActOfCompletionOrderModal';
import { addCustomNotification } from '../../../actions/notifications/actions';
import { blockedButtonTooltips } from '../../../constants/general';

const styles = StyleSheet.create({
    title: {
        marginTop: 27,
        marginLeft: 20,
        marginBottom: 12,
        fontWeight: '600',
        color: '#455562',
        fontSize: 11,
        lineHeight: 13,
        letterSpacing: 1.38,
        opacity: 0.75,
        textTransform: 'uppercase'
    },
    buttonBlock: {
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 3
    },
    button: {
        backgroundColor: '#ffffff',
        paddingLeft: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#E4E4E4'
    },
    disabledButton: {
        backgroundColor: '#888888',
        paddingLeft: 20,
        opacity: 0.6,
        borderBottomWidth: 1,
        borderBottomColor: '#E4E4E4'
    },
    buttonText: {
        color: '#0F79D5',
        fontSize: 14,
        fontWeight: '600',
        lineHeight: 52
    },
    disabledButtonText: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: '600',
        lineHeight: 52
    }
})

function FinanceDocumentsTab(props) {
    const {
        partnerV2,
        getMonthsForActs,
        monthsPayments,
        getMonthsForPayments,
        chargesMonthsForAct,
        monthsForPartnerReport,
        getMonthsForPartnerReport,
        financeInvoices,
        getListOfInvoices,
        addCustomNotification
    } = props;
    const [isInvoiceModalShowed, setInvoiceModalVisible] = React.useState(false);
    const [isActOfCompletionOrderModalShowed,
        setActOfCompletionOrderModalVisible] = React.useState(false);
    const [isActSVModalShowed, setActSVModalVisible] = React.useState(false);
    const [isPartnerReportModalShowed,
        setPartnerReportModalVisible] = React.useState(false);

    React.useEffect(() => {
        getMonthsForPayments();
        getMonthsForActs();
        if (partnerV2) {
            getMonthsForPartnerReport();
        }
        if (!financeInvoices.inProgress && financeInvoices.entities == null) {
            getListOfInvoices();
        }
    }, []);
    const hasMonthsCharges = chargesMonthsForAct && chargesMonthsForAct.length > 0;
    const hasMonthsPayments = monthsPayments && monthsPayments.length > 0;
    const hasMonthsPartnerReport = monthsForPartnerReport && monthsForPartnerReport.length > 0;
    const enablePartnerReport = partnerV2 && hasMonthsPartnerReport;
    return <ScrollView>
        <Text style={styles.title}>Сформировать документ</Text>
        <View style={styles.buttonBlock}>
            <TouchableOpacity style={styles.button}
                onPress={() => setInvoiceModalVisible(true)}>
                <Text style={styles.buttonText}>Счет на оплату</Text>
            </TouchableOpacity>
            <TouchableOpacity style={hasMonthsCharges ? styles.button : styles.disabledButton}
                onPress={() => {
                    if (hasMonthsCharges) {
                        setActOfCompletionOrderModalVisible(true);
                    } else {
                        addCustomNotification(blockedButtonTooltips.finance.hasMonthsCharges);
                    }
                }}>

                <Text
                    style={hasMonthsCharges ? styles.buttonText : styles.disabledButtonText}
                >УПД</Text>
            </TouchableOpacity>
            <TouchableOpacity
                style={(hasMonthsCharges || hasMonthsPayments)
                    ? styles.button : styles.disabledButton}
                onPress={() => {
                    if (hasMonthsCharges || hasMonthsPayments) {
                        setActSVModalVisible(true);
                    } else {
                        addCustomNotification(
                            blockedButtonTooltips.finance.hasMonthsChargesAndPayments
                        );
                    }
                }}>
                <Text
                    style={(hasMonthsCharges || hasMonthsPayments)
                        ? styles.buttonText : styles.disabledButtonText}
                >Акт сверки</Text>
            </TouchableOpacity>
            <TouchableOpacity style={enablePartnerReport ? styles.button : styles.disabledButton}
                onPress={() => {
                    if (enablePartnerReport) {
                        setPartnerReportModalVisible(true);
                    } else {
                        addCustomNotification(
                            blockedButtonTooltips.finance.enablePartnerReport
                        );
                    }
                }}>
                <Text style={enablePartnerReport ? styles.buttonText : styles.disabledButtonText}>
                    Партнерский отчет</Text>
            </TouchableOpacity>
        </View>
        <InvoicesList />
        <Modal isModalShowed={isInvoiceModalShowed}
            component={<InvoiceModal onClose={() => setInvoiceModalVisible(false)}/>}
            onClose={() => setInvoiceModalVisible(false)} />
        <Modal isModalShowed={isActOfCompletionOrderModalShowed}
            component={<ActOfCompletionOrderModal
                onClose={() => setActOfCompletionOrderModalVisible(false)}/>}
            onClose={() => setActOfCompletionOrderModalVisible(false)} />
        <Modal isModalShowed={isActSVModalShowed}
            component={<ActSVModal onClose={() => setActSVModalVisible(false)}/>}
            onClose={() => setActSVModalVisible(false)} />
        <Modal isModalShowed={isPartnerReportModalShowed}
            component={<PartnerReportModal onClose={() => setPartnerReportModalVisible(false)}/>}
            onClose={() => setPartnerReportModalVisible(false)} />
    </ScrollView>;
}

FinanceDocumentsTab.propTypes = {
    partnerV2: PropTypes.bool,
    getMonthsForActs: PropTypes.func,
    getMonthsForPayments: PropTypes.func,
    getMonthsForPartnerReport: PropTypes.func,
    monthsPayments: PropTypes.arrayOf(PropTypes.string),
    chargesMonthsForAct: PropTypes.arrayOf(PropTypes.string),
    monthsForPartnerReport: PropTypes.arrayOf(PropTypes.string),
    financeInvoices: PropTypes.shape({
        entities: PropTypes.arrayOf(PropTypes.shape({})),
        inProgress: PropTypes.bool
    }),
    getListOfInvoices: PropTypes.func,
    addCustomNotification: PropTypes.func
};

const mapStateToProps = (state) => ({
    financeInvoices: state.invoices,
    partnerV2: state.user.financeInfo.partner_v2,
    monthsPayments: state.finance.monthsPayments,
    chargesMonthsForAct: state.finance.chargesMonthsForAct,
    monthsForPartnerReport: state.finance.monthsForPartnerReport
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    getMonthsForActs,
    getListOfInvoices,
    getMonthsForPayments,
    getMonthsForPartnerReport,
    addCustomNotification
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinanceDocumentsTab);
