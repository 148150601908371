import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import {
    View,
    Text,
    StyleSheet,
    Image,
    TouchableOpacity
} from 'react-native';

import Modal from '../Modal/Modal';
import { rightIcon } from '../../../icons';
import { upperFirst } from '../../../utils/general';
import CurrentMonthModal from './CurrentMonthModal';

function getCurrentMonth(currentDate) {
    if (currentDate.year() !== dayjs().year()) {
        return upperFirst(currentDate.format('MMMM YYYY'));
    }
    return upperFirst(currentDate.format('MMMM'));
}

function isNextButtonEnabled(dates, currentDate) {
    if (!dates) {
        return true;
    }
    if (getNextDate(dates, currentDate) == null) {
        return false;
    }
    return true;
}

function isPrevButtonEnabled(dates, currentDate) {
    if (!dates) {
        return true;
    }
    if (getPrevDate(dates, currentDate) == null) {
        return false;
    }
    return true;
}

function getNextDate(dates, currentDate) {
    if (!dates) {
        return currentDate.add(1, 'month');
    }
    const nextDateIndex = dates.indexOf(currentDate.format('YYYY-MM'));
    if (nextDateIndex === -1) {
        return null;
    }
    return dates[nextDateIndex + 1] || null;
}

function getPrevDate(dates, currentDate) {
    if (!dates) {
        return currentDate.add(1, 'month');
    }
    const nextDateIndex = dates.indexOf(currentDate.format('YYYY-MM'));
    if (nextDateIndex === -1) {
        return null;
    }
    return dates[nextDateIndex - 1] || null;
}

function MonthPickerSelector(props) {
    const {
        onChange,
        dates,
        currentDate,
        onNextButtonClick,
        onPrevButtonClick,
    } = props;

    const isNextEnabled = isNextButtonEnabled(dates, currentDate);
    const isPrevEnabled = isPrevButtonEnabled(dates, currentDate);
    const [isChangeCurrentMonthModalShowed,
        setChangeCurrentMonthModalVisible] = React.useState(false);
    const [currentMonth, setCurrentMonth] = React.useState(currentDate.format('YYYY-MM'));
    return <View>
        <View style={styles.changeMonthBlock}>
            <TouchableOpacity style={[styles.prevMonth, {
                opacity: !isPrevEnabled ? 0.3 : 1
            }]} onPress={() => {
                if (isPrevEnabled) {
                    const prevDate = dayjs(getPrevDate(dates, currentDate), 'YYYY-MM');
                    onPrevButtonClick(currentDate, prevDate);
                }
            }}>
                <Image source={rightIcon}
                    style={styles.prevMonthIcon}/>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setChangeCurrentMonthModalVisible(true)}
                style={styles.changeMonthTextBlock}>
                <Text style={styles.changeMonthText}>
                    {getCurrentMonth(currentDate)}
                </Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.nextMonth, {
                opacity: !isNextEnabled ? 0.3 : 1
            }]} onPress={() => {
                if (isNextEnabled) {
                    const nextDate = dayjs(getNextDate(dates, currentDate), 'YYYY-MM');
                    onNextButtonClick(currentDate, nextDate);
                }
            }}>
                <Image source={rightIcon}
                    style={styles.nextMonthIcon}/>
            </TouchableOpacity>
        </View>
        <Modal isModalShowed={isChangeCurrentMonthModalShowed}
            component={<CurrentMonthModal
                onAccept={(value) => {
                    onChange(value);
                    setCurrentMonth(value.format('YYYY-MM'));
                }}
                currentMonth={currentMonth}
                chargesMonths={dates}
                onClose={() => setChangeCurrentMonthModalVisible(false)}/>}
            onClose={() => setChangeCurrentMonthModalVisible(false)} />
    </View>;
}

MonthPickerSelector.propTypes = {
    dates: PropTypes.arrayOf(PropTypes.string),
    currentDate: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onNextButtonClick: PropTypes.func,
    onPrevButtonClick: PropTypes.func,
};

const styles = StyleSheet.create({
    changeMonthBlock: {
        flex: 1,
        flexDirection: 'row',
        borderRadius: 4,
        width: 150,
        marginLeft: 20,
        marginTop: 14,
        backgroundColor: '#F5F5F5',
        alignItems: 'center'
    },
    changeMonthTextBlock: {
        flex: 1,
        paddingTop: 6,
        paddingBottom: 5,
        textAlign: 'center'
    },
    changeMonthText: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '600',
        lineHeight: 14
    },
    prevMonth: {
        paddingLeft: 8.6,
        paddingRight: 8.6,
        paddingTop: 6.5,
        paddingBottom: 6.5,
        borderRightColor: 'rgba(69, 85, 98, 0.15)',
        borderRightWidth: 1
    },
    prevMonthIcon: {
        height: 12,
        width: 6,
        transform: 'scaleX(-1)'
    },
    nextMonth: {
        paddingRight: 8.6,
        paddingLeft: 8.6,
        paddingTop: 6.5,
        paddingBottom: 6.5,
        borderLeftColor: 'rgba(69, 85, 98, 0.15)',
        borderLeftWidth: 1
    },
    nextMonthIcon: {
        height: 12,
        width: 6
    }
});

export default MonthPickerSelector;
