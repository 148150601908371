export const backgroundColorsMap = {
    turquoise: {
        backgroundColor: '#00b2bc'
    },
    red: {
        backgroundColor: '#e05101'
    },
    yellow: {
        backgroundColor: '#e3b319'
    },
    purple: {
        backgroundColor: '#b070ec'
    },
    blue: {
        backgroundColor: '#1d48ce'
    },
    green: {
        backgroundColor: '#43c645'
    },
    mustard: {
        backgroundColor: '#bacf3e'
    },
    brown: {
        backgroundColor: '#7e380c'
    },
    pink: {
        backgroundColor: '#d43abb'
    },
    gray: {
        backgroundColor: '#7f7f7f'
    },
    customColor1: {
        backgroundColor: '#f37171'
    },
    customColor2: {
        backgroundColor: '#bacf3e'
    },
    customColor3: {
        backgroundColor: '#0f919e'
    },
    customColor4: {
        backgroundColor: '#ea77e2'
    },
    customColor5: {
        backgroundColor: '#c03e3e'
    },
    customColor6: {
        backgroundColor: '#9bad2b'
    },
    customColor7: {
        backgroundColor: '#2d6984'
    },
    customColor8: {
        backgroundColor: '#ca37c0'
    },
    customColor9: {
        backgroundColor: '#e68a57'
    },
    customColor10: {
        backgroundColor: '#49ad2b'
    },
    customColor11: {
        backgroundColor: '#53a0e6'
    },
    customColor12: {
        backgroundColor: '#783873'
    },
    customColor13: {
        backgroundColor: '#e05101'
    },
    customColor14: {
        backgroundColor: '#317d1a'
    },
    customColor15: {
        backgroundColor: '#2079c9'
    },
    customColor16: {
        backgroundColor: '#de86a0'
    },
    customColor17: {
        backgroundColor: '#ad4606'
    },
    customColor18: {
        backgroundColor: '#29591b'
    },
    customColor19: {
        backgroundColor: '#344ecc'
    },
    customColor20: {
        backgroundColor: '#d8567d'
    },
    customColor21: {
        backgroundColor: '#f5df77'
    },
    customColor22: {
        backgroundColor: '#7be5ce'
    },
    customColor23: {
        backgroundColor: '#2930a5'
    },
    customColor24: {
        backgroundColor: '#b12952'
    },
    customColor25: {
        backgroundColor: '#e3b319'
    },
    customColor26: {
        backgroundColor: '#46af98'
    },
    customColor27: {
        backgroundColor: '#ba77ea'
    },
    customColor28: {
        backgroundColor: '#c4c4c4'
    },
    customColor29: {
        backgroundColor: '#c59e0c'
    },
    customColor30: {
        backgroundColor: '#1d715e'
    },
    customColor31: {
        backgroundColor: '#9845d3'
    },
    customColor32: {
        backgroundColor: '#9a9a9a'
    },
    customColor33: {
        backgroundColor: '#726120'
    },
    customColor34: {
        backgroundColor: '#73d9e3'
    },
    customColor35: {
        backgroundColor: '#6e22a5'
    },
    customColor36: {
        backgroundColor: '#515151'
    },
    customColor37: {
        backgroundColor: '#ad9d61'
    },
    customColor38: {
        backgroundColor: '#21b8c7'
    },
    customColor39: {
        backgroundColor: '#7d6290'
    },
    customColor40: {
        backgroundColor: '#7c5c32'
    },
    darkened: {
        opacity: 0.5
    },
    packet: {
        backgroundColor: '#e7e7e7'
    }
};
