import contentDisposition from 'content-disposition';

import { flushErrors, formErrors } from '../forms/actions';

import {
    COUPONS_LIST_IN_PROGRESS,
    COUPONS_LIST_WITH_ERRORS,
    COUPONS_LIST_SUCCESSFULLY,
    COUPON_ACTIVATE_IN_PROGRESS,
    COUPON_ACTIVATE_SUCCESSFULLY,
    COUPON_ACTIVATE_WITH_ERRORS,
    AWAITING_PACKET_LIST_IN_PROGRESS,
    AWAITING_PACKET_LIST_SUCCESSFULLY,
    AWAITING_PACKET_LIST_WITH_ERRORS,
    AWAITING_PACKET_CANCEL_SUCCESSFULLY,
    AWAITING_PACKET_CANCEL_WITH_ERRORS,
    CHARGES_MONTH_GROUP_IN_PROGRESS,
    CHARGES_MONTH_GROUP_SUCCESSFULLY,
    CHARGES_MONTH_GROUP_WITH_ERRORS,
    CREATE_INVOICE_SUCCESSFULLY,
    CREATE_INVOICE_IN_PROGRESS,
    CREATE_INVOICE_WITH_ERRORS,
    SET_PAYMENT_AMOUNT,
    DEFERRED_PAYMENT_SET_SUCCESSFULLY,
    DEFERRED_PAYMENT_SET_IN_PROGRESS,
    DEFERRED_PAYMENT_SET_WITH_ERRORS,
    MONTHS_FOR_PAYMENTS_SUCCESSFULLY,
    MONTHS_FOR_PAYMENTS_WITH_ERRORS,
    MONTHS_FOR_PAYMENTS_IN_PROGRESS,
    MONTHS_FOR_CHARGES_IN_PROGRESS,
    MONTHS_FOR_CHARGES_SUCCESSFULLY,
    MONTHS_FOR_CHARGES_WITH_ERRORS,
    REPORT_MONTHS_IN_PROGRESS,
    REPORT_MONTHS_SUCCESSFULLY,
    REPORT_MONTHS_WITH_ERRORS,
    MONTHS_FOR_ACTS_IN_PROGRESS,
    MONTHS_FOR_ACTS_SUCCESSFULLY,
    MONTHS_FOR_ACTS_WITH_ERRORS,
    CHARGES_MONTH_CHANGE_DATE,
    EXPECTED_CHARGES_IN_PROGRESS,
    EXPECTED_CHARGES_SUCCESSFULLY,
    EXPECTED_CHARGES_WITH_ERRORS,
    SET_FORECAST_DATE,
    WS_USER_BALANCE_UPDATE,
    INVOICES_LIST_IN_PROGRESS,
    INVOICES_LIST_SUCCESSFULLY,
    INVOICES_LIST_WITH_ERRORS,
    SEND_INVOICE_TO_EMAIL_SUCCESSFULLY,
    AUTOPAYMENT_DETAIL_FETCH_IN_PROGRESS,
    AUTOPAYMENT_DETAIL_FETCH_SUCCESSFULLY,
    AUTOPAYMENT_DETAIL_FETCH_WITH_ERRORS,
    SEND_INVOICE_TO_EMAIL_IN_PROGRESS,
    SEND_INVOICE_TO_EMAIL_WITH_ERRORS,
    DOWNLOAD_ACT_IN_PROGRESS,
    DOWNLOAD_ACT_SUCCESSFULLY,
    DOWNLOAD_ACT_WITH_ERRORS,
    INIT_PAYMENT_IN_PROGRESS,
    INIT_PAYMENT_SUCCESSFULLY,
    INIT_PAYMENT_WITH_ERRORS,
} from './actionTypes';

import { isFunction, downloadFile } from '../../utils/general';
import FinancesResource from '../../gateway/resources/finances';
import PartnershipResource from '../../gateway/resources/partnership';
import FinancesDocumentsResource from '../../gateway/resources/finances/documents';
import FinancesAutopaymentResource from '../../gateway/resources/finances/autopayment';

export const couponsList = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: COUPONS_LIST_IN_PROGRESS });
    const resource = new FinancesResource(gateway);
    const response = await resource.couponsList();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: COUPONS_LIST_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: COUPONS_LIST_WITH_ERRORS });
    }
};

export const couponActivate = (formId, { token }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    dispatch({ type: COUPON_ACTIVATE_IN_PROGRESS });
    const resource = new FinancesResource(gateway);
    const response = await resource.couponActivate(token);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: COUPON_ACTIVATE_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: COUPON_ACTIVATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const chargesMonthGroups = date => async (dispatch, getState, { gateway }) => {
    dispatch({ type: CHARGES_MONTH_GROUP_IN_PROGRESS });
    const resource = new FinancesResource(gateway);
    const response = await resource.chargesMonthGroups(date);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CHARGES_MONTH_GROUP_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: CHARGES_MONTH_GROUP_WITH_ERRORS });
    }
};

export const awaitingPacketList = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: AWAITING_PACKET_LIST_IN_PROGRESS });
    const resource = new FinancesResource(gateway);
    const response = await resource.awaitingPacketList();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: AWAITING_PACKET_LIST_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: AWAITING_PACKET_LIST_WITH_ERRORS });
    }
};

export const awaitingPacketCancel = (service, resourceId) => async (dispatch, getState, { gateway }) => {
    const resource = new FinancesResource(gateway);
    const response = await resource.awaitingPacketCancel(service, resourceId);
    if (response.ok) {
        dispatch({
            type: AWAITING_PACKET_CANCEL_SUCCESSFULLY,
            data: { service, resourceId }
        });
    } else {
        dispatch({ type: AWAITING_PACKET_CANCEL_WITH_ERRORS });
    }
};

export const setPaymentAmount = (amount, callback) => async (dispatch) => {
    dispatch({
        type: SET_PAYMENT_AMOUNT,
        data: amount
    });
    if (isFunction(callback)) {
        callback();
    }
}

export const createInvoice = (formId, amount, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: CREATE_INVOICE_IN_PROGRESS });
    dispatch(flushErrors());
    const resource = new FinancesResource(gateway);
    const response = await resource.createInvoice(amount);
    const data = await response.json();
    if (response.ok) {
        callback(data, `/finance/invoices/${data.id}/`);
        dispatch({
            type: CREATE_INVOICE_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: CREATE_INVOICE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const getListOfInvoices = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: INVOICES_LIST_IN_PROGRESS });
    const resource = new FinancesResource(gateway);
    const response = await resource.listOfInvoices();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: INVOICES_LIST_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: INVOICES_LIST_WITH_ERRORS });
    }
};

export const initPayment = (paymentSystemType, amount, callback, formId) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: INIT_PAYMENT_IN_PROGRESS });
    const resource = new FinancesResource(gateway);
    const response = await resource.createPayment(paymentSystemType, amount);
    const data = await response.json();
    if (response.ok) {
        dispatch({ type: INIT_PAYMENT_SUCCESSFULLY });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({ type: INIT_PAYMENT_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const deferredPaymentSet = (formId, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: DEFERRED_PAYMENT_SET_IN_PROGRESS });
    dispatch(flushErrors());
    const resource = new FinancesResource(gateway);
    const response = await resource.deferredPaymentSet();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: DEFERRED_PAYMENT_SET_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: DEFERRED_PAYMENT_SET_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const downloadInvoice = (id, callback) => async (dispatch, getState, { gateway }) => {
    const resource = new FinancesResource(gateway);
    const response = await resource.downloadInvoice(id);
    if (response.ok) {
        const data = await response.blob();
        const disposition = response.headers.get('content-disposition');
        const { parameters: { filename } } = contentDisposition.parse(disposition);
        downloadFile(data, filename);
        if (isFunction(callback)) {
            callback();
        }
    }
};

export const sendInvoiceToEmail = (formId, { id, email }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: SEND_INVOICE_TO_EMAIL_IN_PROGRESS });
    const resource = new FinancesResource(gateway);
    const response = await resource.sendInvoiceToEmail(id, email);
    const data = await response.json();
    if (response.ok) {
        dispatch({ type: SEND_INVOICE_TO_EMAIL_SUCCESSFULLY, data });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: SEND_INVOICE_TO_EMAIL_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const downloadAct = (formId, date, callback) => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    dispatch({ type: DOWNLOAD_ACT_IN_PROGRESS });
    const resource = new FinancesDocumentsResource(gateway);
    const response = await resource.downloadAct(date);
    if (response.ok) {
        dispatch({ type: DOWNLOAD_ACT_SUCCESSFULLY });
        callback();
        const data = await response.blob();
        const disposition = response.headers.get('content-disposition');
        const { parameters: { filename } } = contentDisposition.parse(disposition);
        downloadFile(data, filename);
    } else {
        const data = await response.json();
        dispatch({ type: DOWNLOAD_ACT_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const downloadActSV = (formId, dateFrom, dateTo, callback) => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    dispatch({ type: DOWNLOAD_ACT_IN_PROGRESS });
    const resource = new FinancesDocumentsResource(gateway);
    const response = await resource.downloadActSV(dateFrom, dateTo);
    if (response.ok) {
        dispatch({ type: DOWNLOAD_ACT_SUCCESSFULLY });
        callback();
        const data = await response.blob();
        const disposition = response.headers.get('content-disposition');
        const { parameters: { filename } } = contentDisposition.parse(disposition);
        downloadFile(data, filename);
    } else {
        const data = await response.json();
        dispatch({ type: DOWNLOAD_ACT_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const downloadActAgent = (formId, date, callback) => async (dispatch, getState, { gateway }) => {
    dispatch(flushErrors());
    dispatch({ type: DOWNLOAD_ACT_IN_PROGRESS });
    const resource = new FinancesDocumentsResource(gateway);
    const response = await resource.downloadActAgent(date);
    if (response.ok) {
        dispatch({ type: DOWNLOAD_ACT_SUCCESSFULLY });
        callback();
        const data = await response.blob();
        const disposition = response.headers.get('content-disposition');
        const { parameters: { filename } } = contentDisposition.parse(disposition);
        downloadFile(data, filename);
    } else {
        dispatch({ type: DOWNLOAD_ACT_WITH_ERRORS });
        const data = await response.json();
        dispatch(formErrors(formId, data.errors));
    }
};

export const getMonthsForCharges = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: MONTHS_FOR_CHARGES_IN_PROGRESS });
    const resource = new FinancesResource(gateway);
    const response = await resource.monthsForCharges();
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: MONTHS_FOR_CHARGES_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: MONTHS_FOR_CHARGES_WITH_ERRORS
        });
    }
};

export const chargesChangeMonth = date => async (dispatch) => {
    dispatch({
        type: CHARGES_MONTH_CHANGE_DATE,
        data: {
            date
        }
    });
};

export const getMonthsForPayments = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: MONTHS_FOR_PAYMENTS_IN_PROGRESS });
    const resource = new FinancesResource(gateway);
    const response = await resource.monthsForPayments();
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: MONTHS_FOR_PAYMENTS_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: MONTHS_FOR_PAYMENTS_WITH_ERRORS
        });
    }
};

export const getMonthsForPartnerReport = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: REPORT_MONTHS_IN_PROGRESS });
    const resource = new PartnershipResource(gateway);
    const response = await resource.reportMonths();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: REPORT_MONTHS_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: REPORT_MONTHS_WITH_ERRORS });
    }
};


export const getMonthsForActs = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: MONTHS_FOR_ACTS_IN_PROGRESS });
    const resource = new FinancesResource(gateway);
    const response = await resource.monthsForActs();
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: MONTHS_FOR_ACTS_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: MONTHS_FOR_ACTS_WITH_ERRORS
        });
    }
};

export const expectedCharges = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: EXPECTED_CHARGES_IN_PROGRESS });
    const resource = new FinancesResource(gateway);
    const response = await resource.expectedCharges();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: EXPECTED_CHARGES_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: EXPECTED_CHARGES_WITH_ERRORS });
    }
};

export const setForecastDate = date => async (dispatch) => {
    dispatch({ type: SET_FORECAST_DATE, data: { date } });
};

export const wsDeferredPaymentUpdate = data => async (dispatch) => {
    dispatch({ type: DEFERRED_PAYMENT_SET_SUCCESSFULLY, data });
};

export const wsUserBalanceUpdate = data => async (dispatch) => {
    dispatch({ type: WS_USER_BALANCE_UPDATE, data });
};

export const wsCouponActivate = coupon => async (dispatch) => {
    dispatch({ type: COUPON_ACTIVATE_SUCCESSFULLY, data: coupon });
};

export const autopaymentFetch = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: AUTOPAYMENT_DETAIL_FETCH_IN_PROGRESS });
    const resource = new FinancesAutopaymentResource(gateway);
    const response = await resource.details();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: AUTOPAYMENT_DETAIL_FETCH_SUCCESSFULLY,
            data,
        });
    } else {
        dispatch({ type: AUTOPAYMENT_DETAIL_FETCH_WITH_ERRORS });
    }
};
