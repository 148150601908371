import React from 'react';
import punycode from 'punycode';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, } from 'react-native';

import { flushErrors } from '../../../actions/forms/actions';
import InputPassword from '../../General/Form/InputPassword';
import InputWithBlock from '../../General/Form/InputWithBlock';
import { ftpAddDatabaseAction } from '../../../actions/hosting/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'FTPAddForm';

function FTPAdd(props) {
    const {
        errors,
        onClose,
        inProgress,
        flushErrors,
        containerId,
        containerLogin,
        virtualhostName,
        ftpAddDatabaseAction,
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);

    const [login, setModalLogin] = React.useState('');
    const [password, setModalPassword] = React.useState('');
    const homedir = `/${punycode.toASCII(virtualhostName)}`;
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Новый FTP-аккаунт</Text>
        <Text style={styles.label}>Имя аккаунта</Text>
        <View style={styles.inputBlock}>
            <InputWithBlock
                additionalBlock={`${containerLogin}_`}
                errors={errors.login}
                style={styles.input}
                textInputAttrs={{
                    autoFocus: true,
                    numberOfLines: 1,
                    value: login,
                    onChange: (e) =>  setModalLogin(e.target.value)
                }}
            />
        </View>
        <Text style={styles.label}>Пароль</Text>
        <View style={styles.inputBlock}>
            <InputPassword
                errors={errors.password}
                value={password}
                autoFocus={false}
                onChange={(value) => setModalPassword(value)}
            />
        </View>
        <View>
            <Text style={styles.label}>Доступная папка</Text>
            <Text style={styles.staticInput}>{homedir}</Text>
        </View>
        {errors.__all__ && <Text style={styles.error}>{errors.__all__}</Text>}
        <View style={styles.buttons}>
            <AcceptButton
                onSubmit={() => ftpAddDatabaseAction(formId, containerId, {
                    login: login.length !== 0 ? `${containerLogin}_${login}` : '',
                    password,
                    homedir
                }, () => onClose())}
                title='Создать аккаунт'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

FTPAdd.propTypes = {
    onClose: PropTypes.func,
    inProgress: PropTypes.bool,
    errors: PropTypes.shape({}),
    containerId: PropTypes.number,
    ftpAddDatabaseAction: PropTypes.func,
    flushErrors: PropTypes.func,
    virtualhostName: PropTypes.string,
    containerLogin: PropTypes.string,
};

const mapStateToProps = (state) => ({
    inProgress: state.hosting.ftpAddInProgress,
    errors: state.forms.errors.FTPAddForm || {},
    containerId: state.hosting.container.id,
    containerLogin: state.hosting.container.login,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    ftpAddDatabaseAction,
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 44,
        paddingBottom: 42,
        paddingLeft: 25,
        paddingRight: 25,
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 10,
        color: '#455562',
    },
    buttons: {
        flex: 1,
        marginTop: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
    },
    input: {
        borderColor: '#dce2e9',
        borderWidth: 1,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
        marginBottom: 10,
    },
    label: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '400',
        marginBottom: 6,
        lineHeight: 14,
    },
    staticInput: {
        paddingRight: 10,
        paddingBottom: 10,
        marginBottom: 10,
        color: '#455562',
    },
    inputBlock: {
        marginBottom: 20,
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(FTPAdd);
