import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useFocusEffect } from '@react-navigation/native';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import {
    fetchDomain,
    fetchUserMailInfo,
    initMailboxes,
    fetchMailboxes,
} from '../actions/mail/actions';
import { screenHeaderStyles } from '../utils/styles';
import MailboxLaunchPage from '../components/Mail/MailboxLaunchPage';
import MailboxPage from '../components/Mail/MailboxPage';
import MailResourceLoader from '../components/Mail/MailResourceLoader';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const MailDomainStack = createStackNavigator();

function MailDomainStackScreen(props) {
    const {
        route,
        navigation,
    } = props;
    const dispatch = useDispatch();

    const {
        domain,
        mailboxes,
        mailboxesInProgress,
        userInfo,
        userInfoInProgress,
    } = useSelector((state) => state.mail);
    const insets = useSafeAreaInsets();
    const limit = 10;

    useFocusEffect(useCallback(() => {
        dispatch(fetchDomain(route.params.domainId));
        if (!userInfoInProgress && userInfo == null) {
            dispatch(fetchUserMailInfo());
        }
        dispatch(
            fetchMailboxes(
                route.params.domainId,
                limit,
                0,
            ),
        );
        return () => dispatch(initMailboxes());
    }, [route]));
    let currentComponent = null;
    if (mailboxes && mailboxes.count === 0 && domain) {
        currentComponent = MailboxLaunchPage;
    } else {
        currentComponent = MailboxPage;
    }
    return <MailDomainStack.Navigator>
        <MailDomainStack.Screen name="MailDomainStack" component={
            mailboxesInProgress ? MailResourceLoader : currentComponent
        }
        options={{
            headerTitle: () => <ScreenHeaderTitle title={(domain && domain.name) || 'Идет загрузка...'} />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => {
                    navigation.navigate('Mail');
                },
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            cardStyle: { flex: 1 },
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </MailDomainStack.Navigator>;
}

MailDomainStackScreen.propTypes = {
    route: PropTypes.shape({}),
    navigation: PropTypes.shape({}),
};

export default MailDomainStackScreen;
