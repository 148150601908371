import React, { useState } from 'react';
import * as dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    Image
} from 'react-native';

import {
    rightIcon
} from '../../../icons';
import RestoreEmptyStep from './RestoreEmptyStep';
import BackupDateSelect from '../../General/BackupDateSelect/BackupDateSelect';
import { restoreFromBackupUpdateParams, fetchDbms } from '../../../actions/hosting/actions';

function RestoreResourceStep() {
    const containerVirtualhosts = useSelector(
        (state) => state.hosting.virtualhosts,
    );
    const { backups } = useSelector((state) => state.hosting);
    const { currentContainer, restoreDate } = useSelector(
        (state) => state.hostingRestoreFromBackup,
    );
    const dispatch = useDispatch();
    const navigation = useNavigation();

    return (
        <React.Fragment>
            {backups && backups.entities.length === 0 && (
                <RestoreEmptyStep
                    description='Вероятно, контейнер создан недавно и у него пока отсутствуют доступные точки восстановления'
                />
            )}
            {backups && backups.entities.length !== 0 && (
                <View style={styles.wrapper}>
                    <Text style={styles.title}>
                        точка восстановления файлов (МСК+2)
                    </Text>
                    <View style={styles.dateSelectBlock}>
                        <BackupDateSelect
                            currentDate={restoreDate}
                            onChange={(value) => dispatch(
                                restoreFromBackupUpdateParams({
                                    restoreDate: value.date,
                                }),
                            )}
                            points={backups.entities}
                            dateFieldName="date"
                        />
                    </View>
                    <Text style={styles.title}>
                        что вы хотите восстановить?
                    </Text>
                    <View>
                        <TouchableOpacity
                            style={[styles.resourceBlock, { marginBottom: 10 }]}
                            onPress={() => navigation.navigate(
                                'HostingBackupContainer',
                                {
                                    containerId: currentContainer.id,
                                    restoreDate: dayjs(restoreDate).unix(),
                                }
                            )}
                        >
                            <View style={styles.resourceBlockTextBlock}>
                                <Text style={[styles.resourceBlockTitle, { fontWeight: 500 }]}>
                                    Контейнер целиком
                                </Text>
                            </View>
                            <Image source={rightIcon} style={styles.image}/>
                        </TouchableOpacity>
                    </View>
                    {containerVirtualhosts && containerVirtualhosts.entities.length > 0 && (
                        <View>
                            <View style={styles.resourceBlock}>
                                <View style={styles.resourceBlockTextBlock}>
                                    <Text style={[styles.resourceBlockTitle, { fontWeight: 500 }]}>
                                        Отдельный сайт
                                    </Text>
                                </View>
                            </View>
                            {containerVirtualhosts.entities.map(
                                (item, i) => (
                                    <View key={`hosting-backup-virtualhost-${i}`}>
                                        <TouchableOpacity
                                            style={styles.resourceBlock}
                                            onPress={() => dispatch(
                                                restoreFromBackupUpdateParams(
                                                    {
                                                        currentMethod:
                                                            'rewrite',
                                                        currentVirtualhost:
                                                            item.name,
                                                        currentVirtualhostObject:
                                                            item,
                                                        filesForArchive:
                                                            [
                                                                `/${item.name}`,
                                                            ],
                                                    },
                                                    () => {
                                                        dispatch(fetchDbms(item.id));
                                                        navigation.navigate(
                                                            'HostingBackupVirtualhost',
                                                            {
                                                                containerId: currentContainer.id,
                                                                restoreDate:
                                                                    dayjs(restoreDate).unix(),
                                                                virtualhost: item.name,
                                                            }
                                                        );
                                                    }
                                                ),
                                            )}
                                        >
                                            <View style={styles.resourceBlockTextBlock}>
                                                <Text style={styles.resourceBlockTitle}>
                                                    {item.name}
                                                </Text>
                                            </View>
                                            <Image source={rightIcon} style={styles.image}/>
                                        </TouchableOpacity>
                                    </View>
                                ),
                            )}
                        </View>
                    )}
                </View>
            )}
        </React.Fragment>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
    },
    title: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginBottom: 10,
        marginLeft: 20,
    },
    dateSelectBlock: {
        marginBottom: 30,
        marginLeft: 20,
    },
    resourceBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        paddingLeft: 20,
        paddingBottom: 20,
        paddingTop: 20,
        paddingRight: 20,
        marginBottom: 1,
    },
    resourceBlockTitle: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 14
    },
    image: {
        width: 7,
        height: 13,
    }
});

export default RestoreResourceStep;
