import React from 'react';
import PropTypes from 'prop-types';
import {
    Text, StyleSheet,
    TouchableOpacity
} from 'react-native';

const styles = StyleSheet.create({
    button: {
        width: '50%',
        height: 46,
        fontWeight: '400',
        fontSize: 14,
        textAlign: 'center',
        color: '#455562',
        paddingTop: 8,
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
    },
    blueText: {
        color: '#0f79d5',
        fontWeight: '500',
    },
});

function CancelButton({
    onClose,
    title = 'Отменить',
    disabled = false,
}) {
    return <TouchableOpacity style={styles.button}
        onPress={onClose} disabled={disabled}>
        <Text style={[styles.blueText, { opacity: disabled ? 0.5 : 1 }]}>{title}</Text>
    </TouchableOpacity>;
}

CancelButton.propTypes = {
    onClose: PropTypes.func,
    title: PropTypes.string,
    disabled: PropTypes.bool,
};

export default CancelButton;
