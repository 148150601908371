import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet } from 'react-native';

import { clamp, getPercent, isFunction } from '../../utils/general';
import ResourceFills from '../General/ResourceFills/ResourceFills';

const normalizeCurrent = v => v;
const normalizeSize = v => v * 1024;
const formatCurrent = v => (v / 1024).toFixed(1);

const diffPctThreshold = 90;

function getDataScale(data, percentThreshold, size) {
    const {
        fs_used,
        bs_total,
        fs_total
    } = data;
    const current = normalizeCurrent(fs_used);
    const percent = getPercent(bs_total, current);
    const isTooNarrow = percent < percentThreshold;

    let attrs = {};
    attrs = {
        ...attrs,
        current,
        min: 2,
        percent,
        max: size
    };
    if (fs_total === 0) {
        attrs.min = 2;
    }
    if (!isTooNarrow) {
        attrs.scaleChildren = <Text style={styles.text}>{formatCurrent(current)}&nbsp;ГБ</Text>;
    }
    return attrs;
}

function getFsScale(data) {
    const {
        fs_reserved,
        bs_reserved,
        bs_total,
    } = data;
    const fsCurrent = bs_reserved + fs_reserved;
    const fsPercent = getPercent(bs_total, fsCurrent);
    return {
        percent: fsPercent,
        current: formatCurrent(normalizeCurrent(fsCurrent)),
        color: 'tileGrayFill',
    };
}

function getEmptyScale(free, dataPercent, reservedPercent) {
    const freePercent = clamp(100 - (dataPercent + reservedPercent), 0, 100);
    return {
        percent: freePercent,
        current: formatCurrent(normalizeCurrent(free)),
        color: 'backgroundGray',
    };
}

function VolumeFill(props) {
    const {
        id, size,
        isLoaded = true,
        isLoading = false,
        isError = false,
        data, name,
        percentThreshold
    } = props;
    const scales = [];
    let isTotalError = false;
    let isBackgroundEnabled = false;
    if (data) {
        const { fs_total } = data;
        if (fs_total > 0) {
            const diffPct = (fs_total / normalizeSize(size)) * 100;
            if (diffPct >= diffPctThreshold) {
                const dataScale = getDataScale(data, percentThreshold, size);
                const fsScale = getFsScale(data)
                scales.push(fsScale);
                scales.push(dataScale);
                scales.push(getEmptyScale(data.fs_free, dataScale.percent, fsScale.percent));
            } else {
                isTotalError = true;
            }
        } else {
            isTotalError = true;
        }
    } else if (isLoaded) {
        isTotalError = true;
    }
    if (isTotalError) {
        isBackgroundEnabled = true;
    }
    return <ResourceFills
        format={formatCurrent}
        type={`volume-${id}`}
        isLoaded={!isTotalError && isLoaded}
        isLoading={!isTotalError && isLoading}
        isError={isTotalError || isError}
        errorMessage='Нет данных'
        additionalHeader={`${size} ГБ`}
        isBackgroundEnabled={isBackgroundEnabled}
        name={isFunction(name) ? (data && name(data)) : name}
        scales={scales}
    />;
}

VolumeFill.propTypes = {
    name: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.element,
    ]),
    min: PropTypes.number,
    size: PropTypes.number,
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    id: PropTypes.string,
    data: PropTypes.shape({
        bs_reserved: PropTypes.number,
        bs_total: PropTypes.number,
        fs_free: PropTypes.number,
        fs_free_pct: PropTypes.number,
        fs_total: PropTypes.number,
        fs_used: PropTypes.number,
        fs_used_pct: PropTypes.number,
        fs_reserved: PropTypes.number,
        fs_reserved_pct: PropTypes.number
    }),
    percentThreshold: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
    text: {
        color: '#ffffff',
        fontSize: 10,
        fontWeight: '600',
        lineHeight: 11
    }
});

export default VolumeFill;
