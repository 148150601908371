import {
    CONTAINERS_FETCHED_SUCCESSFULLY,
    CONTAINERS_FETCHED_IN_PROGRESS,
    CONTAINERS_FETCHED_WITH_ERRORS,
    CONTAINER_FETCHED_IN_PROGRESS,
    CONTAINER_FETCHED_SUCCESSFULLY,
    CONTAINER_FETCHED_WITH_ERRORS,
    CONTAINER_VIRTUALHOSTS_FETCHED_IN_PROGRESS,
    CONTAINER_VIRTUALHOSTS_FETCHED_SUCCESSFULLY,
    CONTAINER_VIRTUALHOSTS_FETCHED_WITH_ERRORS,
    CONTAINER_LIMITS_IN_PROGRESS,
    CONTAINER_LIMITS_SUCCESSFULLY,
    CONTAINER_LIMITS_WITH_ERRORS,
    CONTAINER_INITIAL,
    CONTAINER_DELETE,
    CONTAINER_DELETE_IN_PROGRESS,
    CONTAINER_DELETE_WITH_ERRORS,
    CONTAINER_UPDATE,
    CALCULATION_FETCH_IN_PROGRESS,
    CALCULATION_FETCH_SUCCESSFULLY,
    CALCULATION_PACKET_UPDATE_IN_PROGRESS,
    CALCULATION_PACKET_UPDATE_WITH_ERRORS,
    CALCULATION_FETCH_WITH_ERRORS,
    RECALCULATE_CONTAINER_PRICE,
    REDIS_DELETE,
    REDIS_UPDATE,
    MEMCACHE_UPDATE,
    MEMCACHE_DELETE,
    VIRTUALHOST_FETCHED_SUCCESSFULLY,
    VIRTUALHOST_FETCHED_IN_PROGRESS,
    VIRTUALHOST_FETCHED_WITH_ERRORS,
    VIRTUALHOST_CHANGE_STATE,
    VIRTUALHOST_UPDATE,
    VIRTUALHOST_UPDATE_IN_PROGRESS,
    VIRTUALHOST_UPDATE_WITH_ERRORS,
    DBMS_FETCHED_SUCCESSFULLY,
    DBMS_FETCHED_IN_PROGRESS,
    DBMS_FETCHED_WITH_ERRORS,
    VIRTUALHOST_DELETE,
    VIRTUALHOST_DELETE_IN_PROGRESS,
    VIRTUALHOST_DELETE_WITH_ERRORS,
    VIRTUALHOST_CREATE,
    DBMS_CHANGE_PASSWORD,
    FTP_ADD,
    FTP_ADD_IN_PROGRESS,
    FTP_ADD_WITH_ERRORS,
    FTPS_FETCHED_IN_PROGRESS,
    FTPS_FETCHED_SUCCESSFULLY,
    FTPS_FETCHED_WITH_ERRORS,
    FTPS_DELETE,
    FTPS_DELETE_IN_PROGRESS,
    FTPS_DELETE_WITH_ERRORS,
    CONTAINER_CREATE_SUCCESSFULLY,
    BAR_CHART_CPU_FETCH_IN_PROGRESS,
    BAR_CHART_CPU_FETCH_SUCCESSFULLY,
    BAR_CHART_CPU_FETCH_WITH_ERRORS,
    BAR_CHART_RAM_FETCH_IN_PROGRESS,
    BAR_CHART_RAM_FETCH_SUCCESSFULLY,
    BAR_CHART_RAM_FETCH_WITH_ERRORS,
    SET_PROBLEM_CPU,
    SET_PROBLEM_MEMORY,
    SET_PROBLEM_STORAGE,
    CONTAINER_CAPABILITIES,
    SSL_UPLOAD_ARCHIVE_SUCCESSFULLY,
    SSL_UPLOAD_ARCHIVE_WITH_ERROR,
    CALCULATION_PACKET_CREATE_SUCCESSFULLY,
    CALCULATION_PACKET_CREATE_IN_PROGRESS,
    CALCULATION_PACKET_CREATE_WITH_ERRORS,
    CALCULATION_PACKET_RENEWAL_CHANGE_SUCCESSFULLY,
    VIRTUALHOST_CREATE_IN_PROGRESS,
    VIRTUALHOST_CREATE_WITH_ERRORS,
    CONTAINER_UPDATE_IN_PROGRESS,
    CONTAINER_UPDATE_WITH_ERRORS,
    CONTAINER_SSH_SET_PASSWORD,
    CONTAINER_SSH_SET_PASSWORD_IN_PROGRESS,
    CONTAINER_SSH_SET_PASSWORD_WITH_ERRORS,
    DBMS_CHANGE_PASSWORD_IN_PROGRESS,
    DBMS_CHANGE_PASSWORD_WITH_ERRORS,
    FTPS_CHANGE_PASSWORD,
    FTPS_CHANGE_PASSWORD_IN_PROGRESS,
    FTPS_CHANGE_PASSWORD_WITH_ERRORS,
    VIRTUALHOST_RELOAD,
    VIRTUALHOSTS_FETCHED_SUCCESSFULLY,
    VIRTUALHOSTS_FETCHED_IN_PROGRESS,
    VIRTUALHOSTS_FETCHED_WITH_ERRORS,
    CONTAINER_BACKUPS_LIST_IN_PROGRESS,
    CONTAINER_BACKUPS_LIST_SUCCESSFULLY,
    CONTAINER_BACKUPS_LIST_WITH_ERRORS,
    DBMS_BACKUPS_LIST_IN_PROGRESS,
    DBMS_BACKUPS_LIST_SUCCESSFULLY,
    DBMS_BACKUPS_LIST_WITH_ERRORS,
    HOSTING_RESTORE_FROM_BACKUP_TO_INIT,
    GET_CONTAINER_CALCULATION_SUCCESSFULLY,
} from '../../actions/hosting/actionTypes';
import {
    FIND_CERTIFICATES_IN_PROGRESS,
    FIND_CERTIFICATES_SUCCESSFULLY,
    CERTIFICATE_DETAIL
} from '../../actions/certificates/actionTypes';
import { calculatePrice } from '../../components/Hosting/calculation';

const makeDefaultLoadableResource = fun => () => ({
    isLoaded: false,
    isLoading: false,
    isError: false,
    ...fun()
});

const makeDefaultBarChart = makeDefaultLoadableResource(() => ({
    datapoints: [],
    cumulative: 0
}));

const makeDefaultCalculation = makeDefaultLoadableResource(() => ({
    calculation: {}
}));

const defaultState = {
    containers: null,
    calculationData: null,
    container: null,
    virtualhosts: null,
    virtualhostsInProgress: false,
    virtualhost: null,
    virtualhostFetchedId: null,
    virtualhostInProgress: false,
    ftps: null,
    ftpsFetchInProgress: false,
    limits: null,
    limitsInProgress: false,
    limitsWithError: false,
    dbms: null,
    dbmsFetchInProgress: false,
    containerFetchInProgress: false,
    containerFetchId: null,
    containersFetchInProgress: false,
    barChartRam: makeDefaultBarChart(),
    barChartCPU: makeDefaultBarChart(),
    calculation: makeDefaultCalculation(),
    createVirtualhostInProgress: false,
    containerUpdateInProgress: false,
    containerDeleteInProgress: false,
    sshSetPasswordInProgress: false,
    dbmsChangePasswordInProgress: false,
    ftpAddInProgress: false,
    ftpChangePasswordInProgress: false,
    ftpDeleteInProgress: false,
    virtualhostUpdateInProgress: false,
    virtualhostDeleteInProgress: false,
    createPacketInProgress: false,
    updatePacketInProgress: false,
    backups: null,
    backupsFetchError: false,
    backupsFetchInProgress: false,
    dbmsBackups: null,
    dbmsBackupsFetchError: false,
    dbmsBackupsFetchInProgress: false,
    containerCalculation: {},
};

export default () => {
    const containerCreateState = { ...defaultState };

    return (state = containerCreateState, action) => {
        switch (action.type) {
        case CONTAINER_CREATE_SUCCESSFULLY: {
            if (state.containers == null) {
                return {
                    ...state,
                    containers: null,
                    container: null
                };
            }
            return {
                ...state,
                containers: {
                    count: state.containers.count + 1,
                    entities: [...state.containers.entities, action.data]
                },
                container: null
            };
        }
        case CONTAINERS_FETCHED_SUCCESSFULLY: {
            return {
                ...state,
                containers: action.data,
                containersFetchInProgress: false
            };
        }
        case CONTAINERS_FETCHED_IN_PROGRESS: {
            return {
                ...state,
                containersFetchInProgress: true
            };
        }
        case CONTAINERS_FETCHED_WITH_ERRORS: {
            return {
                ...state,
                containersFetchInProgress: false
            };
        }
        case CONTAINER_FETCHED_SUCCESSFULLY: {
            return {
                ...state,
                container: action.data,
                containerFetchInProgress: false,
                containerFetchId: null,
                virtualhosts: null,
                ftps: null,
                barChartCPU: makeDefaultBarChart(),
                barChartRam: makeDefaultBarChart(),
                limits: null,
            };
        }
        case CONTAINER_FETCHED_IN_PROGRESS: {
            return {
                ...state,
                containerFetchInProgress: true,
                containerFetchId: action.data.id,
                container: null,
                virtualhosts: null,
                ftps: null,
            };
        }
        case CONTAINER_FETCHED_WITH_ERRORS: {
            return {
                ...state,
                containerFetchInProgress: false,
                containerFetchId: null,
                container: null,
                virtualhosts: null,
                ftps: null,
            };
        }
        case CONTAINER_VIRTUALHOSTS_FETCHED_SUCCESSFULLY: {
            return {
                ...state,
                virtualhosts: action.data.virtualhosts,
                virtualhostsInProgress: false
            };
        }
        case CONTAINER_VIRTUALHOSTS_FETCHED_IN_PROGRESS: {
            return {
                ...state,
                virtualhostsInProgress: true
            };
        }
        case CONTAINER_VIRTUALHOSTS_FETCHED_WITH_ERRORS: {
            return {
                ...state,
                virtualhostsInProgress: false
            };
        }
        case CONTAINER_LIMITS_SUCCESSFULLY: {
            return {
                ...state,
                container: {
                    ...state.container,
                    limits: action.data,
                    limitsInProgress: false,
                    limitsWithError: false,
                }
            };
        }
        case CONTAINER_LIMITS_IN_PROGRESS: {
            return {
                ...state,
                container: {
                    ...state.container,
                    limitsInProgress: true,
                    limitsWithError: false,
                }
            };
        }
        case CONTAINER_LIMITS_WITH_ERRORS: {
            return {
                ...state,
                container: {
                    ...state.container,
                    limitsInProgress: false,
                    limitsWithError: true,
                }
            };
        }
        case CONTAINER_INITIAL: {
            return {
                ...state,
                container: null,
                virtualhosts: null,
                virtualhostsInProgress: false,
                virtualhost: null,
                virtualhostFetchedId: null,
                virtualhostInProgress: false,
                ftps: null,
                ftpsFetchInProgress: false,
                limits: null,
                dbms: null,
                dbmsFetchInProgress: false,
                limitsInProgress: false,
                limitsWithError: false,
                containersFetchInProgress: false,
                barChartRam: makeDefaultBarChart(),
                barChartCPU: makeDefaultBarChart(),
                calculation: makeDefaultCalculation(),
            };
        }
        case CONTAINER_DELETE_IN_PROGRESS: {
            return {
                ...state,
                containerDeleteInProgress: true,
            };
        }
        case CONTAINER_DELETE_WITH_ERRORS: {
            return {
                ...state,
                containerDeleteInProgress: false,
            };
        }
        case CONTAINER_DELETE: {
            const { containerId } = action.data;
            return {
                ...state,
                container: null,
                containers: {
                    count: state.containers.count - 1,
                    entities: [...state.containers.entities.filter(item => item.id !== containerId)]
                },
                containerDeleteInProgress: false,
            };
        }
        case CONTAINER_UPDATE: {
            if (state.containers) {
                return {
                    ...state,
                    container: action.data,
                    containers: {
                        ...state.containers,
                        entities: state.containers.entities.map((item) => {
                            if (action.data.id === item.id) {
                                return action.data;
                            }
                            return item;
                        })
                    }
                };
            }
            return {
                ...state,
                container: action.data,
                containerUpdateInProgress: false,
            };
        }
        case CONTAINER_UPDATE_IN_PROGRESS: {
            return {
                ...state,
                containerUpdateInProgress: true,
            };
        }
        case CONTAINER_UPDATE_WITH_ERRORS: {
            return {
                ...state,
                containerUpdateInProgress: false,
            };
        }
        case CALCULATION_FETCH_IN_PROGRESS: {
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    isLoading: true
                }
            };
        }
        case CALCULATION_PACKET_UPDATE_IN_PROGRESS: {
            return {
                ...state,
                updatePacketInProgress: true,
            };
        }
        case CALCULATION_PACKET_UPDATE_WITH_ERRORS: {
            return {
                ...state,
                updatePacketInProgress: false,
            };
        }
        case CALCULATION_FETCH_SUCCESSFULLY: {
            if (state.container == null) {
                return {
                    ...state,
                    calculation: {
                        ...state.calculation,
                        calculation: action.data,
                        isLoading: false,
                        isLoaded: true
                    }
                };
            }
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    calculation: action.data,
                    isLoading: false,
                    isLoaded: true
                },
                calculationData: {
                    ...state.calculationData,
                    [state.container.login]: {
                        container: state.container.login,
                        ...action.data
                    }
                },
                updatePacketInProgress: false
            };
        }
        case CALCULATION_FETCH_WITH_ERRORS: {
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    isLoading: false,
                    isError: true
                }
            };
        }
        case RECALCULATE_CONTAINER_PRICE: {
            const {
                memcached,
                redis,
                storage,
                memory,
                isPacket,
                login
            } = action.data;
            if (!isPacket) {
                return {
                    ...state,
                    calculationData: {
                        ...state.calculationData,
                        [login]: {
                            ...state.calculationData[login],
                            fullMonthPrice: calculatePrice(
                                memory, storage, redis, memcached, state.price
                            )
                        }
                    }
                };
            }
            return { ...state };
        }
        case REDIS_UPDATE: {
            return {
                ...state,
                container: {
                    ...state.container,
                    redis: action.data
                }
            };
        }
        case REDIS_DELETE: {
            return {
                ...state,
                container: {
                    ...state.container,
                    redis: null
                }
            };
        }
        case MEMCACHE_UPDATE: {
            return {
                ...state,
                container: {
                    ...state.container,
                    memcache: action.data
                }
            };
        }
        case MEMCACHE_DELETE: {
            return {
                ...state,
                container: {
                    ...state.container,
                    memcache: null
                }
            };
        }
        case VIRTUALHOST_FETCHED_SUCCESSFULLY: {
            return {
                ...state,
                virtualhostInProgress: false,
                virtualhostFetchedId: null,
                container: action.data.container || state.container,
                virtualhost: action.data,
                ftps: null,
                dbmsFetchInProgress: false,
                dbms: null,
                certificatesFetchInProgress: false
            };
        }
        case VIRTUALHOST_FETCHED_IN_PROGRESS: {
            return {
                ...state,
                virtualhostFetchedId: action.data.id,
                virtualhostInProgress: true,
                virtualhost: null
            };
        }
        case VIRTUALHOST_FETCHED_WITH_ERRORS: {
            return {
                ...state,
                virtualhostFetchedId: null,
                virtualhostInProgress: false
            };
        }
        case VIRTUALHOST_CHANGE_STATE: {
            if (state.virtualhosts) {
                const entities = state.virtualhosts.entities.map((vh) => {
                    if (vh.id === action.data.id) {
                        return action.data;
                    }
                    return vh;
                });
                return {
                    ...state,
                    virtualhost: {
                        ...state.virtualhost,
                        ...action.data
                    },
                    virtualhosts: {
                        ...state.virtualhosts,
                        entities
                    }
                };
            }
            return {
                ...state,
                virtualhost: {
                    ...state.virtualhost,
                    ...action.data
                },
                virtualhostUpdateInProgress: false,
            };
        }
        case VIRTUALHOST_UPDATE_IN_PROGRESS: {
            return {
                ...state,
                virtualhostUpdateInProgress: true
            };
        }
        case VIRTUALHOST_UPDATE_WITH_ERRORS: {
            return {
                ...state,
                virtualhostUpdateInProgress: false
            };
        }
        case VIRTUALHOST_UPDATE: {
            const currentVirtualhosts = state.virtualhosts;
            if (currentVirtualhosts) {
                currentVirtualhosts.entities = state.virtualhosts.entities.map((vh) => {
                    if (vh.id === action.data.id) {
                        return action.data;
                    }
                    return vh;
                });
            }
            return {
                ...state,
                virtualhost: {
                    ...state.virtualhost,
                    ...action.data
                },
                virtualhosts: currentVirtualhosts,
                virtualhostUpdateInProgress: false,
            };
        }
        case VIRTUALHOST_RELOAD: {
            return {
                ...state,
                virtualhostUpdateInProgress: false
            };
        }
        case DBMS_FETCHED_IN_PROGRESS: {
            return {
                ...state,
                dbmsFetchInProgress: true
            };
        }
        case DBMS_FETCHED_WITH_ERRORS: {
            return {
                ...state,
                dbmsFetchInProgress: false
            };
        }
        case DBMS_FETCHED_SUCCESSFULLY: {
            return {
                ...state,
                dbmsFetchInProgress: false,
                dbms: action.data
            };
        }
        case DBMS_CHANGE_PASSWORD: {
            return {
                ...state,
                virtualhost: {
                    ...state.virtualhost,
                    dbms_account: {
                        ...state.virtualhost.dbms_account,
                        password: action.data.password
                    }
                },
                dbmsChangePasswordInProgress: false,
            };
        }
        case DBMS_CHANGE_PASSWORD_IN_PROGRESS: {
            return {
                ...state,
                dbmsChangePasswordInProgress: true
            };
        }
        case DBMS_CHANGE_PASSWORD_WITH_ERRORS: {
            return {
                ...state,
                dbmsChangePasswordInProgress: false
            };
        }
        case VIRTUALHOST_DELETE_IN_PROGRESS: {
            return {
                ...state,
                virtualhostDeleteInProgress: true
            };
        }
        case VIRTUALHOST_DELETE_WITH_ERRORS: {
            return {
                ...state,
                virtualhostDeleteInProgress: false
            };
        }
        case VIRTUALHOST_DELETE: {
            const { virtualhostId } = action.data;
            let containerId = state.container && state.container.id;
            if (containerId == null) {
                containerId = state.virtualhost
                    && state.virtualhost.container && state.virtualhost.container.id;
            }
            let newState = state;
            if (newState.virtualhosts) {
                newState = {
                    ...state,
                    virtualhosts: {
                        count: state.virtualhosts.count - 1,
                        entities: [...state.virtualhosts.entities
                            .filter(item => item.id !== virtualhostId)]
                    },
                };
            }
            if (state.containers != null) {
                const newContainersEntities = state.containers.entities;
                const currentContainerIndex = newContainersEntities.findIndex(
                    el => el.id === containerId
                );
                const currentContainer = newContainersEntities[currentContainerIndex];
                newContainersEntities[currentContainerIndex].virtualhosts = {
                    count: currentContainer.virtualhosts.count - 1,
                    entities: [...currentContainer.virtualhosts.entities.filter(
                        item => item.id !== virtualhostId
                    )]
                };
                newState.containers = {
                    count: state.containers.count,
                    entities: newContainersEntities
                };
            }
            return {
                ...newState,
                virtualhostDeleteInProgress: false,
            };
        }
        case VIRTUALHOST_CREATE: {
            let containerId = state.container && state.container.id;
            if (containerId == null) {
                containerId = state.virtualhost
                    && state.virtualhost.container && state.virtualhost.container.id;
            }
            const { currentContainerId } = action.data;
            let newState = state;
            if (currentContainerId === action.data.container_id) {
                newState = {
                    ...state,
                    virtualhosts: {
                        count: state.virtualhosts.count + 1,
                        entities: [...state.virtualhosts.entities, action.data]
                    },
                    virtualhost: null,
                };
                if (state.containers != null) {
                    const newContainersEntities = state.containers.entities;
                    const currentContainerIndex = newContainersEntities.findIndex(
                        el => el.id === currentContainerId
                    );
                    const currentContainer = newContainersEntities[currentContainerIndex];
                    newContainersEntities[currentContainerIndex].virtualhosts = {
                        count: currentContainer.virtualhosts.count != null
                            ? currentContainer.virtualhosts.count + 1 : 1,
                        entities: [...currentContainer.virtualhosts.entities,
                            action.data]
                    };
                    newState.containers = {
                        count: state.containers.count,
                        entities: newContainersEntities
                    };
                }
            }
            return {
                ...newState,
                createVirtualhostInProgress: false,
            };
        }
        case VIRTUALHOST_CREATE_IN_PROGRESS: {
            return {
                ...state,
                createVirtualhostInProgress: true
            };
        }
        case VIRTUALHOST_CREATE_WITH_ERRORS: {
            return {
                ...state,
                createVirtualhostInProgress: false
            };
        }
        case FTPS_FETCHED_IN_PROGRESS: {
            return {
                ...state,
                ftpsFetchInProgress: true
            };
        }
        case FTPS_FETCHED_WITH_ERRORS: {
            return {
                ...state,
                ftpsFetchInProgress: false
            };
        }
        case FTPS_FETCHED_SUCCESSFULLY: {
            return {
                ...state,
                ftpsFetchInProgress: false,
                ftps: action.data
            };
        }
        case FTP_ADD_IN_PROGRESS: {
            return {
                ...state,
                ftpAddInProgress: true
            };
        }
        case FTP_ADD_WITH_ERRORS: {
            return {
                ...state,
                ftpAddInProgress: false
            };
        }
        case FTP_ADD: {
            return {
                ...state,
                ftps: {
                    ...state.ftps,
                    count: state.ftps.count + 1,
                    entities: [...state.ftps.entities, action.data]
                },
                ftpAddInProgress: false,
            };
        }
        case FTPS_CHANGE_PASSWORD_IN_PROGRESS: {
            return {
                ...state,
                ftpChangePasswordInProgress: true
            };
        }
        case FTPS_CHANGE_PASSWORD_WITH_ERRORS: {
            return {
                ...state,
                ftpChangePasswordInProgress: false
            };
        }
        case FTPS_CHANGE_PASSWORD: {
            return {
                ...state,
                ftps: {
                    ...state.ftps,
                    count: state.ftps.count + 1,
                    entities: [...state.ftps.entities, action.data]
                },
                ftpChangePasswordInProgress: false,
            };
        }
        case FTPS_DELETE: {
            const { ftps } = state;
            const { ftpsId } = action.data;
            return {
                ...state,
                ftps: {
                    count: ftps.count - 1,
                    entities: [...ftps.entities.filter(item => item.id !== ftpsId)]
                },
                ftpDeleteInProgress: false,
            };
        }
        case FTPS_DELETE_IN_PROGRESS: {
            return {
                ...state,
                ftpDeleteInProgress: true
            };
        }
        case FTPS_DELETE_WITH_ERRORS: {
            return {
                ...state,
                ftpDeleteInProgress: false
            };
        }
        case BAR_CHART_CPU_FETCH_IN_PROGRESS: {
            return {
                ...state,
                barChartCPU: {
                    ...state.barChartCPU,
                    isLoading: true
                }
            };
        }
        case BAR_CHART_CPU_FETCH_SUCCESSFULLY: {
            const { cumulative, cpu } = action.data;
            return {
                ...state,
                barChartCPU: {
                    ...state.barChartCPU,
                    datapoints: cpu,
                    cumulative,
                    isLoading: false,
                    isLoaded: true
                }
            };
        }
        case BAR_CHART_CPU_FETCH_WITH_ERRORS: {
            return {
                ...state,
                barChartCPU: {
                    ...state.barChartCPU,
                    ...action.data,
                    isLoading: false,
                    isLoaded: true,
                    isError: true
                }
            };
        }
        case BAR_CHART_RAM_FETCH_IN_PROGRESS: {
            return {
                ...state,
                barChartRam: {
                    ...state.barChartRam,
                    isLoading: true
                }
            };
        }
        case BAR_CHART_RAM_FETCH_SUCCESSFULLY: {
            const { cumulative, ram } = action.data;
            return {
                ...state,
                barChartRam: {
                    ...state.barChartRam,
                    datapoints: ram,
                    cumulative,
                    isLoading: false,
                    isLoaded: true
                }
            };
        }
        case BAR_CHART_RAM_FETCH_WITH_ERRORS: {
            return {
                ...state,
                barChartRam: {
                    ...state.barChartRam,
                    ...action.data,
                    isLoading: false,
                    isLoaded: true,
                    isError: true
                }
            };
        }
        case SET_PROBLEM_CPU: {
            const { problemCpu } = action.data;
            return { ...state, problemCpu };
        }
        case SET_PROBLEM_MEMORY: {
            const { problemMemory } = action.data;
            return { ...state, problemMemory };
        }
        case SET_PROBLEM_STORAGE: {
            const { problemStorage } = action.data;
            return { ...state, problemStorage };
        }
        case CONTAINER_CAPABILITIES: {
            return {
                ...state,
                capabilities: action.data
            };
        }
        case CERTIFICATE_DETAIL: {
            const certificate = action.data;
            if (state.virtualhost && state.virtualhost.certificate_id === certificate.id) {
                return {
                    ...state,
                    virtualhost: {
                        ...state.virtualhost,
                        certificate
                    }
                };
            }
            return state;
        }
        case FIND_CERTIFICATES_IN_PROGRESS: {
            return {
                ...state,
                certificatesFetchInProgress: true
            };
        }
        case FIND_CERTIFICATES_SUCCESSFULLY: {
            return {
                ...state,
                certificatesFetchInProgress: false,
                virtualhost: {
                    ...state.virtualhost,
                    certificates: action.data
                }
            };
        }
        case SSL_UPLOAD_ARCHIVE_SUCCESSFULLY: {
            return {
                ...state,
                sslUploadInProgress: false,
            };
        }
        case SSL_UPLOAD_ARCHIVE_WITH_ERROR: {
            return {
                ...state,
                sslUploadInProgress: false,
            };
        }
        case CALCULATION_PACKET_CREATE_IN_PROGRESS: {
            return {
                ...state,
                createPacketInProgress: true,
            };
        }
        case CALCULATION_PACKET_CREATE_WITH_ERRORS: {
            return {
                ...state,
                createPacketInProgress: false,
            };
        }
        case CALCULATION_PACKET_CREATE_SUCCESSFULLY: {
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    calculation: {
                        ...state.calculation.calculation,
                        packet: action.data
                    }
                },
                calculationData: {
                    ...state.calculationData,
                    [action.data.container]: {
                        ...state.calculationData[action.data.container],
                        container: action.data.container,
                        packet: action.data
                    }
                },
                createPacketInProgress: false,
            };
        }
        case CALCULATION_PACKET_RENEWAL_CHANGE_SUCCESSFULLY: {
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    calculation: {
                        ...state.calculation.calculation,
                        packet: action.data
                    }
                }
            };
        }
        case CONTAINER_SSH_SET_PASSWORD: {
            return {
                ...state,
                sshSetPasswordInProgress: false,
            };
        }
        case CONTAINER_SSH_SET_PASSWORD_IN_PROGRESS: {
            return {
                ...state,
                sshSetPasswordInProgress: true,
            };
        }
        case CONTAINER_SSH_SET_PASSWORD_WITH_ERRORS: {
            return {
                ...state,
                sshSetPasswordInProgress: false,
            };
        }
        case VIRTUALHOSTS_FETCHED_SUCCESSFULLY: {
            if (action.data == null || action.data.entities.length === 0) {
                return {
                    ...state,
                    virtualhosts: action.data,
                    virtualhostsInProgress: false,
                };
            }
            const { container } = action.data.entities[0];
            return {
                ...state,
                virtualhosts: {
                    ...action.data,
                    containerName: container.name,
                },
                virtualhostsInProgress: false,
                container,
            };
        }
        case VIRTUALHOSTS_FETCHED_IN_PROGRESS: {
            return {
                ...state,
                virtualhostsInProgress: true,
            };
        }
        case VIRTUALHOSTS_FETCHED_WITH_ERRORS: {
            return {
                ...state,
                virtualhostsInProgress: false,
            };
        }
        case CONTAINER_BACKUPS_LIST_SUCCESSFULLY: {
            return {
                ...state,
                backups: action.data,
                backupsFetchError: false,
                backupsFetchInProgress: false,
            };
        }
        case CONTAINER_BACKUPS_LIST_WITH_ERRORS: {
            return {
                ...state,
                backups: null,
                backupsFetchError: true,
                backupsFetchInProgress: false,
            };
        }
        case CONTAINER_BACKUPS_LIST_IN_PROGRESS: {
            return {
                ...state,
                backups: null,
                backupsFetchError: false,
                backupsFetchInProgress: true,
            };
        }
        case DBMS_BACKUPS_LIST_SUCCESSFULLY: {
            return {
                ...state,
                dbmsBackups: action.data,
                dbmsBackupsFetchError: false,
                dbmsBackupsFetchInProgress: false,
            };
        }
        case DBMS_BACKUPS_LIST_IN_PROGRESS: {
            return {
                ...state,
                dbmsBackups: null,
                dbmsBackupsFetchError: false,
                dbmsBackupsFetchInProgress: true,
            };
        }
        case DBMS_BACKUPS_LIST_WITH_ERRORS: {
            return {
                ...state,
                dbmsBackups: null,
                dbmsBackupsFetchError: true,
                dbmsBackupsFetchInProgress: false,
            };
        }
        case HOSTING_RESTORE_FROM_BACKUP_TO_INIT: {
            return {
                ...state,
                backups: null,
            };
        }
        case GET_CONTAINER_CALCULATION_SUCCESSFULLY: {
            return {
                ...state,
                containerCalculation: action.data,
            };
        }
        default: {
            return state;
        }
        }
    };
};
