const BaseResource = require('./resource');

class CertificatesResource extends BaseResource {
    list(limit, offset) {
        return this.makeRequest('get', null, { limit: limit || 100, offset: offset || 0 });
    }

    create(certificate, privateKey) {
        return this.makeRequest('post', null, { certificate, private_key: privateKey });
    }

    detail(id) {
        return this.makeRequest('get', `${id}/`);
    }

    find(domains) {
        return this.makeRequest('get', 'find/', {
            is_issued_only: true,
            domains
        });
    }

    order(data) {
        return this.makeRequest('post', 'order/', data);
    }

    update(id, data) {
        return this.makeRequest('put', `${id}/`, data);
    }

    delete(certificateId) {
        return this.makeRequest('delete', `${certificateId}/`);
    }
}

CertificatesResource.resource = null;
CertificatesResource.service = 'certificates';

module.exports = CertificatesResource;
