import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextInput, StyleSheet, View, Image } from 'react-native';

import Errors from './Errors';
import { searchIcon } from '../../../icons';
import { canNotStartSearch } from '../../../utils/general';

const searchTimeout = 300;
let searchTimeoutId = null;

function SearchInput(props) {
    const {
        textInputAttrs = {},
        errors = [],
        minQueryLength = 3,
        additionalErrorStyles = [],
        additionalInputStyles = [],
        additionalWrapperStyles = [],
        onSearch = () => {},
        initSearch = () => {},
        searchRequest = '',
        changeSearchRequest = () => {},
    } = props;

    const inputAttrs = {
        ...textInputAttrs,
        placeholder: 'Поиск',
    };
    const styles = StyleSheet.create({
        wrapper: {
            position: 'relative',
            marginBottom: 5
        },
        input: {
            flex: 1,
            fontSize: 12,
            lineHeight: 14,
            color: '#455562',
        },
        error: {
            borderColor: '#d06565',
        },
        searchIcon: {
            width: 12,
            paddingLeft: 10,
            height: 12,
        },
        inputBlock: {
            flex: 1,
            paddingTop: 9,
            paddingBottom: 9,
            paddingLeft: 12,
            paddingRight: 11,
            backgroundColor: '#ffffff',
            borderColor: 'rgba(69, 85, 98, 0.30)',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        }
    });
    const additionalInputClasses = [];
    if (errors != null && errors.length > 0) {
        additionalInputClasses.push('error');
    }
    const [request, setRequest] = useState(searchRequest);
    useEffect(() => {
        if (request !== searchRequest) {
            setRequest(searchRequest);
        }
    }, [searchRequest]);
    return <View style={[styles.wrapper, ...additionalWrapperStyles]}>
        <View style={styles.inputBlock}>
            <TextInput style={[styles.input, ...additionalInputStyles,
                ...additionalInputClasses.map((cls) => styles[cls])]}
            autoCompleteType='off'
            onChange={(e) => {
                const currentRequest = e.target.value;
                if (currentRequest === searchRequest && searchRequest.length !== 0) {
                    return;
                }
                setRequest(currentRequest);
                changeSearchRequest(currentRequest, minQueryLength);
                if (searchTimeoutId) {
                    clearTimeout(searchTimeoutId);
                    searchTimeoutId = null;
                }
                if (!canNotStartSearch(currentRequest, minQueryLength)) {
                    searchTimeoutId = setTimeout(() => {
                        onSearch(currentRequest);
                    }, searchTimeout);
                } else {
                    initSearch();
                }
            }}
            value={request}
            {...inputAttrs}>
            </TextInput>
            <Image source={searchIcon}
                style={styles.searchIcon} />
        </View>
        <View style={additionalErrorStyles}>
            <Errors errors={errors} />
        </View>
    </View>;
}

SearchInput.propTypes = {
    errors: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string
    ]),
    needBorder: PropTypes.bool,
    textInputAttrs: PropTypes.shape({}),
    additionalErrorStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ])),
    additionalInputStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ])),
    additionalWrapperStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ])),
    onSearch: PropTypes.func,
    changeSearchRequest: PropTypes.func,
    searchRequest: PropTypes.string,
    minQueryLength: PropTypes.number,
    initSearch: PropTypes.func,
};

export default SearchInput;
