import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    TouchableOpacity,
    StyleSheet,
    Text,
    View
} from 'react-native';

import LoadingCircle from '../Loading/LoadingCircle';
import { addCustomNotification } from '../../../actions/notifications/actions';

const styles = StyleSheet.create({
    button: {
        paddingTop: 17,
        paddingBottom: 17,
        paddingRight: 11.55,
        paddingLeft: 11.55,
        borderRadius: 4,
    },
    buttonText: {
        fontSize: 16,
        letterSpacing: 0,
        lineHeight: 19,
        minHeight: 19,
        textAlign: 'center',
        color: '#ffffff',
        fontWeight: '600',
    },
    blue: {
        backgroundColor: '#0F79D5'
    },
    white: {
        backgroundColor: '#ffffff',
        borderColor: 'rgba(69,85,98,0.5)',
        borderStyle: 'solid',
        borderWidth: 1,
    },
    dashed: {
        backgroundColor: '#ffffff',
        borderColor: '#bdbdbd',
        borderStyle: 'dashed',
        borderWidth: 1,
    },
    disabled: {
        opacity: 0.5,
        backgroundColor: '#888888'
    },
    loading: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingCircle: {
        marginRight: 4,
    }
});

function Button(props) {
    const {
        type = 'blue',
        title,
        disabled,
        component,
        disabledText,
        inProgress = false,
        onPress = () => {},
        addCustomNotification,
        additionalButtonStyles = [],
        additionalWrapperStyles = [],
        additionalButtonTextStyles = [],
        disabledBackgroundColor = '#888888',
    } = props;
    const buttonStyles = [styles.button, styles[type], ...additionalButtonStyles];
    if (disabled) {
        buttonStyles.push(styles.disabled);
        buttonStyles.push({ backgroundColor: disabledBackgroundColor });
    }
    return <React.Fragment>
        {!inProgress && <TouchableOpacity onPress={() => {
            if (!disabled) {
                onPress();
            } else if (disabledText != null && disabledText.length !== 0) {
                addCustomNotification(disabledText);
            }
        }} style={[...additionalWrapperStyles]}>
            {component
                ? <View style={buttonStyles}>{component}</View>
                : <View style={buttonStyles}>
                    <Text style={[styles.buttonText, ...additionalButtonTextStyles]}>{title}</Text>
                </View>}
        </TouchableOpacity>}
        {inProgress && <View style={[buttonStyles, ...additionalWrapperStyles]}>
            <View style={[styles.loading, styles.buttonText, ...additionalButtonTextStyles]}>
                <View style={styles.loadingCircle}>
                    <LoadingCircle
                        startingOpacity={1}
                        startFunc='fadeOut'
                        type={type === 'blue' ? 'white' : type }
                    />
                </View>
                <View style={styles.loadingCircle}>
                    <LoadingCircle startingOpacity={0.8} startingDuration={450}
                        type={type === 'blue' ? 'white' : type }
                    />
                </View>
                <LoadingCircle startingOpacity={0.6}
                    type={type === 'blue' ? 'white' : type }
                />
            </View>
        </View>}
    </React.Fragment>;
}

Button.propTypes = {
    inProgress: PropTypes.bool,
    type: PropTypes.string, // disabled, white, blue, transparent, dashed
    onPress: PropTypes.func,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    additionalButtonStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ])),
    disabled: PropTypes.bool,
    additionalWrapperStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ])),
    additionalButtonTextStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ])),
    disabledText: PropTypes.string,
    addCustomNotification: PropTypes.func,
    disabledBackgroundColor: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({ addCustomNotification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Button);
