const BaseResource = require('../resource');

class PartnershipResource extends BaseResource {
    async data() {
        return this.makeRequest('get', null);
    }

    async entries() {
        return this.makeRequest('get', 'entries/');
    }

    async statistics() {
        return this.makeRequest('get', 'statistics/');
    }

    async monthsForAccruals() {
        return this.makeRequest('get', 'entries/months/');
    }

    async chargesMonthGroups(date) {
        return this.makeRequest('get', `entries/${date}/`);
    }

    async clientDetail(limit, offset) {
        return this.makeRequest('get', 'clients/details/',
            { limit: limit || 20, offset: offset || 0 });
    }

    async getClient(id) {
        return this.makeRequest('get', `clients/${id}/details/`);
    }

    async register(data) {
        return this.makeRequest('post', 'clients/register/', data);
    }

    async createPartnership(isAccept) {
        return this.makeRequest('post', 'accept/', { is_accept: isAccept });
    }

    async reportMonths() {
        return this.makeRequest('get', 'report/months/');
    }

    async partnershipUpdate(rinv, bik) {
        return this.makeRequest('put', null, { rinv, bik });
    }

    async withdrawals(amount, type) {
        return this.makeRequest('post', 'withdrawals/', { amount, type });
    }
}

PartnershipResource.resource = null;
PartnershipResource.service = 'partnership';

module.exports = PartnershipResource;
