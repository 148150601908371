import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';

import {
    dbms,
    engines,
    normalizeCharsetMap,
} from '../../constants/hosting';
import { rightArrowLightIcon } from '../../icons/index';
import { blockedButtonTooltips, humanStateMap } from '../../constants/general';
import StateBlock from '../General/StateBlock/StateBlock';
import VirtualhostsEmptyPage from './VirtualhostsEmptyPage';
import ActionButton from '../General/ActionButton/ActionButton';
import ResourceLoader from '../General/ResourceLoader/ResourceLoader';

function getInfomation(engine, engineVersion, dbmsAccount, charset) {
    return `${engines[engine]} ${engineVersion}, ${dbms[dbmsAccount]}, ${normalizeCharsetMap[charset].label}`;
}

function VirtualhostsPage({ virtualhosts, navigation }) {
    return virtualhosts.map((virtualhost, v) => <TouchableOpacity key={`hosting-virtualhost-${v}`}
        onPress={() => navigation.navigate('HostingContainerVirtualhost', {
            screen: 'HostingVirtualhost',
            virtualhostId: virtualhost.id,
            virtualhostName: virtualhost.name
        })}
        style={styles.virtualhost}>
        <View style={styles.info}>
            <Text style={styles.virtualhostName}>{virtualhost.name}</Text>
            <Text style={styles.description}>
                {getInfomation(
                    virtualhost.engine,
                    virtualhost.engine_version,
                    virtualhost.dbms_account.type,
                    virtualhost.charset
                )}
            </Text>
            <StateBlock state={virtualhost.enabled ? 'enabled' : 'disabled' }
                title={humanStateMap[virtualhost.state]}/>
        </View>
        <Image source={rightArrowLightIcon} style={styles.icon} />
    </TouchableOpacity>);
}

function Virtualhosts(props) {
    const {
        container,
        virtualhosts,
        virtualhostsInProgress,
    } = props;
    const navigation = useNavigation();
    const [isActionButtonOpen, setIsActionButtonOpen] = React.useState(true);

    useFocusEffect(React.useCallback(() => {
        setIsActionButtonOpen(true);
        return () => {
            setIsActionButtonOpen(false);
        };
    }, [virtualhosts]));

    return <View style={styles.wrapperPage}>
        {!virtualhostsInProgress && virtualhosts && (
            virtualhosts.entities.length !== 0
                ? <VirtualhostsPage virtualhosts={virtualhosts.entities} navigation={navigation}/>
                : <VirtualhostsEmptyPage />
        )}
        {virtualhostsInProgress && virtualhosts == null && <ResourceLoader height={107} count={2}/>}
        {(container && container.enabled) && isActionButtonOpen
            && virtualhosts != null && virtualhosts.entities.length !== 0
            && <ActionButton isOpen={isActionButtonOpen}
                type='+'
                disabled={!container.enabled}
                disabledText={blockedButtonTooltips.hosting.containerDisabled}
                onPress={() => navigation.navigate('HostingCreateVirtualhost', {
                    containerId: container.id
                })}
            />}
    </View>;
}

Virtualhosts.propTypes = {
    container: PropTypes.shape({
        id: PropTypes.number,
        enabled: PropTypes.bool,
    }),
    containerId: PropTypes.number,
    virtualhosts: PropTypes.shape({
        count: PropTypes.number,
        entities: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    virtualhostsInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    container: state.hosting.container,
    virtualhosts: state.hosting.virtualhosts,
    virtualhostsInProgress: state.hosting.virtualhostsInProgress,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const styles = StyleSheet.create({
    wrapperPage: {
        height: '100%',
    },
    virtualhost: {
        width: '100%',
        maxHeight: 100,
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 20,
        paddingLeft: 18,
        backgroundColor: '#FFFFFF',
        borderBottomColor: '#F6F6F6',
        borderBottomWidth: 2,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    info: {
        flex: 1,
    },
    virtualhostName: {
        color: '#455562',
        fontSize: 16,
        letterSpacing: 0,
        lineHeight: 19,
        marginBottom: 4,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    description: {
        opacity: 0.8,
        color: '#455562',
        fontSize: 11,
        letterSpacing: 0,
        lineHeight: 13,
        marginBottom: 11,
    },
    icon: {
        width: 12,
        height: 22,
    },
    loading: {
        height: 107,
        backgroundColor: '#b0b0b0',
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Virtualhosts);
