export const USER_AUTHORIZE_IN_PROGRESS = 'USER_AUTHORIZE_IN_PROGRESS';
export const USER_AUTHORIZE_WITH_ERRORS = 'USER_AUTHORIZE_WITH_ERRORS';
export const USER_AUTHORIZE_SUCCESSFULLY = 'USER_AUTHORIZE_SUCCESSFULLY';

export const USER_FINANCE_INFO_IN_PROGRESS = 'USER_FINANCE_INFO_IN_PROGRESS';
export const USER_FINANCE_INFO_WITH_ERRORS = 'USER_FINANCE_INFO_WITH_ERRORS';
export const USER_FINANCE_INFO_SUCCESSFULLY = 'USER_FINANCE_INFO_SUCCESSFULLY';

export const USER_ACTIVE_SERVICES_FETCHED_IN_PROGRESS = 'USER_ACTIVE_SERVICES_FETCHED_IN_PROGRESS';
export const USER_ACTIVE_SERVICES_FETCHED_SUCCESSFULLY = 'USER_ACTIVE_SERVICES_FETCHED_SUCCESSFULLY';
export const USER_ACTIVE_SERVICES_FETCHED_WITH_ERRORS = 'USER_ACTIVE_SERVICES_FETCHED_WITH_ERRORS';

export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';

export const PASSWORD_RESTORE_IN_PROGRESS = 'PASSWORD_RESTORE_IN_PROGRESS';
export const PASSWORD_RESTORE_SUCCESSFULLY = 'PASSWORD_RESTORE_SUCCESSFULLY';
export const PASSWORD_RESTORE_WITH_ERRORS = 'PASSWORD_RESTORE_WITH_ERRORS';

export const PASSWORD_RESET_IN_PROGRESS = 'PASSWORD_RESET_IN_PROGRESS';
export const PASSWORD_RESET_SUCCESSFULLY = 'PASSWORD_RESET_SUCCESSFULLY';
export const PASSWORD_RESET_WITH_ERRORS = 'PASSWORD_RESET_WITH_ERRORS';
