import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';

import CreatePacketRow from './CreatePacketRow';

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 12,
        paddingBottom: 16
    }
});

function CreatePacketDescription(props) {
    const {
        parameters
    } = props;
    return <View style={styles.wrapper}>
        {parameters.filter(parameter => parameter.isDisplayed == null
            || parameter.isDisplayed).map((parameter, index) => <CreatePacketRow
            key={`packet-description-row-${index}`}
            title={parameter.title}
            isChange={parameter.isChange}
            format={parameter.format}
            value={parameter.value} />)}
    </View>;
}

CreatePacketDescription.propTypes = {
    parameters: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        isChange: PropTypes.bool,
        format: PropTypes.func,
        title: PropTypes.string,
        isDisplayed: PropTypes.bool
    }))
};

export default CreatePacketDescription;
