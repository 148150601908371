import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, StyleSheet } from 'react-native';

import GpuBlock from './VMCreate/GpuBlock';
import { makeDiscount, calculatePrice } from './utils';
import Errors from '../General/Form/Errors';
import Button from '../General/Button/Button';
import TariffBlock from './VMCreate/TariffBlock';
import StorageBlock from './VMCreate/StorageBlock';
import SwitcherCheckbox from '../General/Form/SwitcherCheckbox';
import DistributionsBlock from './VMCreate/DistributionsBlock';
import { createVMToInit, createVMUpdateParams, vmCreate } from '../../actions/cvds/actions';
import { formatPrice } from '../../utils/general';


const formId = 'VMCreatePageForm';

const styles = StyleSheet.create({
    packetBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 17,
        paddingRight: 15,
        paddingBottom: 19,
        paddingLeft: 16,
        backgroundColor: '#ffffff',
    },
    packetText: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '600',
        lineHeight: 18,
        flex: 1,
    },
    createButtonBlock: {
        marginLeft: 12,
        marginRight: 14,
        marginTop: 24,
    },
    createButtonWrapper: {
        paddingTop: 11,
        paddingBottom: 13,
    },
    createButton: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    createButtonText: {
        color: '#ffffff',
        fontSize: 14,
        lineHeight: 16,
    },
    createButtonPrice: {
        marginLeft: 10,
        borderLeftWidth: 1,
        borderLeftColor: '#ffffff',
        paddingLeft: 10,
    },
    errorsBlock: {
        marginLeft: 12,
        marginTop: 5,
        textAlign: 'center',
    },
});

function getPrice(backup, currentTariff, currentGpu, currentGpuCount) {
    const { id, type, features } = currentTariff;
    const price = calculatePrice(
        id,
        type,
        features.storage,
        backup,
        currentGpu,
        currentGpuCount
    );
    return price;
}


function VMCreatePage(props) {
    const { navigation } = props;

    const dispatch = useDispatch();

    const {
        currentGpu,
        currentGpuCount,
        packet,
        backup,
        currentTariff,
        currentDistribution,
    } = useSelector((state) => state.cvdsCreate);
    const errors = useSelector((state) => state.forms.errors[formId]) || {};
    const inProgress = useSelector(
        (state) => state.cvdsCreate.createVMInProgress
    );

    const price = getPrice(backup, currentTariff, currentGpu, currentGpuCount);
    const discount = packet ? 10 : 0;
    const dynamicDiscountPrice = makeDiscount(price, discount);

    let isDisabled = false;
    let disabledText = null;
    if (
        currentDistribution == null
    || (currentDistribution && currentDistribution.id == null)
    ) {
        isDisabled = true;
        disabledText = 'Выберите образ';
    }
    if (currentTariff.type === 'ultra' && currentGpu == null) {
        isDisabled = true;
        disabledText = 'Выберите видеокарту';
    }

    useEffect(() => () => {
        dispatch(createVMToInit());
    }, []);

    return (
        <View style={styles.wrapper}>
            <TariffBlock />
            {currentTariff.type === 'ultra' && <GpuBlock />}
            <StorageBlock />
            <DistributionsBlock
                onPress={() => navigation.navigate('CVDSDistributions')}
            />
            <View style={styles.packetBlock}>
                <Text style={styles.packetText}>Оплата за год со скидкой 10%</Text>
                <SwitcherCheckbox
                    value={packet}
                    onChange={(value) => dispatch(
                        createVMUpdateParams({
                            packet: value,
                        })
                    )
                    }
                />
            </View>
            <View style={styles.errorsBlock}>
                <Errors errors={Object.values(errors)} />
            </View>
            <Button
                additionalButtonStyles={[styles.createButtonWrapper]}
                additionalWrapperStyles={[styles.createButtonBlock]}
                additionalButtonTextStyles={[{ minHeight: 16 }]}
                inProgress={inProgress}
                disabled={isDisabled}
                disabledText={disabledText}
                onPress={() => {
                    const createData = {
                        tariff: currentTariff.id,
                        disk_size: currentTariff.features.storage,
                        is_backup_enabled: backup,
                        create_packet: packet,
                        image: currentDistribution.id,
                    };
                    if (currentTariff.type === 'ultra') {
                        createData.gpu_type = currentGpu.id;
                        createData.gpu_count = currentGpuCount;
                    }
                    dispatch(
                        vmCreate(formId, createData, (vm) => navigation.navigate('CVDSVM', { vmId: vm.id }))
                    );
                }}
                component={
                    <View style={styles.createButton}>
                        <Text style={styles.createButtonText}>Создать сервер</Text>
                        <Text style={[styles.createButtonText, styles.createButtonPrice]}>
                            {formatPrice(dynamicDiscountPrice)} ₽/мес
                        </Text>
                    </View>
                }
            ></Button>
        </View>
    );
}

export default VMCreatePage;
