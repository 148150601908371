import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../../icons';
import {
    fetchCVDSBackups,
    fetchCVDSBackupDates,
    restoreFromBackupUpdateParams
} from '../../actions/cvds/actions';
import { screenHeaderStyles } from '../../utils/styles';
import RestoreBackupListLoader from '../../components/General/RestoreBackupLoader/RestoreBackupListLoader';
import RestoreBackupDisksStep from '../../components/CVDS/CvdsBackup/RestoreBackupDisksStep';
import ScreenHeaderTitle from '../../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const CvdsBackupDisksStack = createStackNavigator();

function CvdsBackupDisksStackScreen(props) {
    const { route } = props;
    const { currentVM } = useSelector((state) => state.cvdsRestoreFromBackup);
    const { backups, backupsInProgress } = useSelector((state) => state.cvdsBackups);
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();
    const vmId = Number.parseInt(route.params.vmId, 10);

    useFocusEffect(React.useCallback(() => {
        dispatch(fetchCVDSBackups());
    }, []));

    useEffect(() => {
        if (currentVM == null && vmId != null && backups) {
            const vm = backups.entities.find((item) => item.id === vmId);
            if (vm.resources.length === 1) {
                const volume = vm.resources[0];
                dispatch(
                    fetchCVDSBackupDates(volume.resource_url, (backupDates) => {
                        if (backupDates.entities.length === 0) {
                            return;
                        }
                        const {
                            backup_date,
                            resource_name,
                            resource_type,
                            methods,
                        } = backupDates.entities[0];
                        dispatch(
                            restoreFromBackupUpdateParams({
                                backupDate: backup_date,
                                resourceName: resource_name,
                                resourceType: resource_type,
                                backupMethod: methods[0],
                                backupMethods: methods,
                            }),
                        );
                    }),
                );
                dispatch(
                    restoreFromBackupUpdateParams(
                        {
                            currentVolume: volume,
                            currentVM: vm,
                        },
                        () => navigation.navigate('CVDSBackupMethods', { vmId: vm.id, volumeId: volume.id }),
                    ),
                );
            } else {
                dispatch(
                    restoreFromBackupUpdateParams({
                        currentVM: vm,
                    }),
                );
            }
        }
    }, [backups]);

    return <CvdsBackupDisksStack.Navigator>
        <CvdsBackupDisksStack.Screen name="CVDSBackupDisksScreen"
            component={
                (backups == null || backupsInProgress)
                    ? RestoreBackupListLoader : RestoreBackupDisksStep}
            options={{
                headerTitle: () => <ScreenHeaderTitle
                    title={(currentVM && currentVM.name) || 'Идет загрузка...'} />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => {
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        } else {
                            navigation.navigate('CVDSBackup');
                        }
                    },
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </CvdsBackupDisksStack.Navigator>;
}

CvdsBackupDisksStackScreen.propTypes = {
    route: PropTypes.object,
};

export default CvdsBackupDisksStackScreen;
