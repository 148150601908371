import React from 'react';
import { useSelector } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';

import { bToMb } from '@netangels/core/utils/convert';

import { formatMemory } from '../../utils/general';
import { getPrice } from './utils';
import { defaultColors } from './constants';
import Separator from '../General/Separator/Separator';
import HorizontalBarChart from '../General/HorizontalBarChart/HorizontalBarChart';
import { formatPrice } from '../../utils/general';

function PaidStatisticsBlock() {
    const { userInfo, userStatisticsInfo, calculation } = useSelector(
        (state) => state.mail,
    );
    const resources = [];
    let header = '';
    let current = null;
    if (userStatisticsInfo && userInfo && userStatisticsInfo.domains) {
        current = bToMb(userStatisticsInfo.summary.used);
        header = `Всего занято ${formatMemory(current, true)}`;
        userStatisticsInfo.domains.forEach((domain, i) => {
            if (domain.used > 0) {
                const domainSize = bToMb(domain.used);
                const colorType = defaultColors[i];
                resources.push({
                    id: `mail:domain:${domainSize}`,
                    title: domain.domain,
                    colorType,
                    value: domainSize,
                    formatValue: (v) => formatMemory(v, true),
                });
            }
        });
        if (calculation.packet && !calculation.packet.is_awaiting) {
            const current = bToMb(userStatisticsInfo.summary.used);
            const restPacket = calculation.packet.limit * 1024 + userInfo.free_quota - current;
            resources.push({
                id: `packet:${calculation.packet.limit}`,
                title: 'остаток пакета',
                colorType: 'packet',
                value: restPacket < 0 ? 0 : restPacket,
                formatValue: (v) => formatMemory(v, true),
            });
        }
    }
    const packetLimit = calculation
        && calculation.packet != null && !calculation.packet.is_awaiting
        && calculation.packet.limit * 1024;
    let paidUsed = current && current - userInfo.free_quota;
    if (packetLimit) {
        paidUsed -= packetLimit;
    }

    return (
        <View style={styles.wrapper}>
            <Text style={styles.headerTitle}>{header}</Text>
            <HorizontalBarChart
                hideTotal={true}
                resources={resources}
                needLegendColorBox={true}
                needLegendBackground={false}
                horizontalBarChartLegendStyles={styles.horizontalBarChartLegend}
                horizontalBarChartWrapperStyles={styles.horizontalBarChartWrapper}
                horizontalBarChartStatisticStyles={styles.horizontalBarChartStatistic}
                horizontalBarChartLegendValueStyles={styles.horizontalBarChartLegendValue}
                horizontalBarChartLegendTitleStyles={styles.horizontalBarChartLegendTitle}
                horizontalBarChartLegendResourceStyles={styles.horizontalBarChartLegendResource}
            />
            <Separator />
            {userInfo && userStatisticsInfo && (
                <View style={styles.infoBlockWrapper}>
                    {userInfo.free_quota > 0 && (
                        <View style={styles.infoBlock}>
                            <Text style={styles.infoText}>
                                Бесплатно
                            </Text>
                            <View style={styles.infoSeparator} />
                            <Text style={styles.infoText}>
                                {formatMemory(userInfo.free_quota, true)}
                            </Text>
                        </View>
                    )}
                    {calculation && packetLimit && (
                        <View style={styles.infoBlock}>
                            <Text style={styles.infoText}>
                                Пакет
                            </Text>
                            <View style={styles.infoSeparator} />
                            <Text style={styles.infoText}>
                                {packetLimit / 1024} ГБ
                            </Text>
                        </View>
                    )}
                    {paidUsed > 0 && (
                        <View style={styles.infoBlock}>
                            <Text style={styles.infoText}>
                            Платно
                            </Text>
                            <View style={styles.infoSeparator} />
                            <Text style={styles.infoText}>
                                {formatMemory(paidUsed, true)} (
                                {formatPrice(getPrice(paidUsed))} ₽/мес)
                            </Text>
                        </View>
                    )}
                    {paidUsed <= 0 && userInfo.free_quota > 0 && !packetLimit && (
                        <Text style={styles.hint}>
                            Хранение свыше{' '}
                            {formatMemory(userInfo.free_quota, true)}{' '}
                            будет платным: {getPrice(1024)} ₽ за ГБ в
                            месяц
                        </Text>
                    )}
                    {paidUsed <= 0 && userInfo.free_quota > 0 && packetLimit && (
                        <Text style={styles.hint}>
                            Хранение свыше{' '}
                            {formatMemory(
                                userInfo.free_quota + packetLimit,
                                true,
                            )}{' '}
                            будет платным: {getPrice(1024)} ₽ за ГБ в
                            месяц
                        </Text>
                    )}
                    {paidUsed <= 0 && userInfo.free_quota === 0 && (
                        <Text style={styles.hint}>
                            Стоимость хранения: {getPrice(1024)} ₽ за ГБ в
                            месяц
                        </Text>
                    )}
                </View>
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 16,
        paddingBottom: 24,
        backgroundColor: '#FFFFFF',
    },
    headerTitle: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 18,
        marginBottom: 8,
        marginLeft: 21,
    },
    horizontalBarChartWrapper: {
        marginBottom: 16,
        marginLeft: 23,
        marginRight: 25,
        flex: 1,
        flexDirection: 'column-reverse',
    },
    horizontalBarChartLegend: {
        flexDirection: 'column',
    },
    horizontalBarChartLegendValue: {
        color: '#455562',
        fontSize: 11,
        lineHeight: 13,
        fontWeight: 400,
    },
    horizontalBarChartLegendTitle: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 15,
        fontWeight: 400,
        flex: 1,
    },
    horizontalBarChartLegendResource: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 0,
        marginRight: 0,
        marginTop: 12,
        alignItems: 'center',
    },
    horizontalBarChartStatistic: {
        height: 20,
    },
    infoBlockWrapper: {
        marginTop: 7,
    },
    infoBlock: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 9,
        marginLeft: 24,
        marginRight: 25,
    },
    infoText: {
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 14,
        color: '#455562',
    },
    infoSeparator: {
        borderBottomWidth: 1,
        position: 'relative',
        top: -3,
        flex: 1,
        borderStyle: 'dashed',
        borderColor: 'rgba(69, 85, 98, 0.15)',
    },
    hint: {
        marginTop: 16,
        marginLeft: 24,
        marginRight: 25,
        fontSize: 12,
        color: 'rgba(69, 85, 98, 0.8)',
        fontWeight: '400',
    }
});

export default PaidStatisticsBlock;
