import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

function PaymentForm(props) {
    const {
        action,
        fields,
        method,
        innerRef
    } = props;
    return <form action={action} method={method} ref={innerRef}>
        <View>
            {Object.keys(fields).map((key) => <input
                key={`payment-form-${key}-field`}
                type='hidden'
                name={key} value={fields[key]} />)}
        </View>
    </form>;
}

PaymentForm.propTypes = {
    innerRef: PropTypes.object,
    action: PropTypes.string,
    fields: PropTypes.shape({}),
    method: PropTypes.string
};

export default PaymentForm;