import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    View,
    StyleSheet,
    Text,
    Image,
    ScrollView,
} from 'react-native';

import { thinspaceNumber } from './utils';

import { mailProIcon } from '../../icons';
import Button from '../General/Button/Button';
import Modal from '../General/Modal/Modal';
import FreeStatisticsBlock from './FreeStatisticsBlock';
import PaidStatisticsBlock from './PaidStatisticsBlock';
import OrderPacketModal from './Modals/OrderPacketModal';
import CurrentPacketBlock from './CurrentPacketBlock';
import CancelMailPaidQuotaModal from './Modals/CancelMailPaidQuotaModal';
import ActivateMailPaidQuotaModal from './Modals/ActivateMailPaidQuotaModal';
import MailWebsocket from './MailWebsocket';

function StatisticsTab(props) {
    const { navigation } = props;
    const { isBudget } = useSelector((state) => state.user.user);
    const {
        userInfo,
        calculation,
        userStatisticsInfo,
        preferredPacket,
    } = useSelector((state) => state.mail);

    const [activatePaidQuotaModalShowed, setActivatePaidQuotaModalShowed] = React.useState(false);
    const [cancelPaidQuotaModalShowed, setCancelPaidQuotaModalShowed] = React.useState(false);
    const [orderPacketModalShowed, setOrderPacketModalShowed] = React.useState(false);

    const calculateFullPrice = (price, discount) => (price * 100) / (100 - discount);
    const hasPacket = calculation && calculation.packet != null;
    const needShowPacket = userInfo && calculation
        && calculation.packet == null && preferredPacket != null;
    const packetDescription = calculation && preferredPacket
        && `Мы заметили, что объем вашей почты ${calculation.used_gb < preferredPacket.limit
            ? 'приближается к'
            : 'превышает'} ${preferredPacket.limit / 1000} ТБ и подготовили специальное предожение со скидкой`;
    return <ScrollView>
        <View style={styles.headerBlock}>
            <Text style={styles.headerText}>
                статистика и лимиты
            </Text>
        </View>
        <View>
            {userInfo && !userInfo.paid_quota_enabled && userInfo.free_quota > 0
                && <FreeStatisticsBlock />}
            {userInfo && userInfo.paid_quota_enabled && (
                <PaidStatisticsBlock />
            )}
        </View>
        {userInfo && !userInfo.paid_quota_enabled
            && <View style={styles.bannerBlock}>
                <View style={styles.bannerHeaderBlock}>
                    <Image source={mailProIcon} style={styles.icon} />
                    <Text style={styles.bannerHeaderTitle}>Почта.</Text>
                    <Text style={styles.bannerHeaderTitleBlue}>
                        про
                    </Text>
                </View>
                <Text style={styles.bannerDescription}>
                    Больше места для вашей почты и настройка индивидуальных
                    лимитов для доменов и ящиков
                </Text>
                <Button
                    onPress={() => {
                        if (userInfo.paid_quota_enabled) {
                            setCancelPaidQuotaModalShowed(true);
                        } else {
                            setActivatePaidQuotaModalShowed(true);
                        }
                    }}
                    title='Подробнее'
                    additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 13 }]}
                    additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15, fontWeight: '400' }]}
                />
            </View>
        }
        {needShowPacket && !isBudget && <View style={styles.packetBlock}>
            <View style={styles.packetHeader}>
                <Text style={styles.packetHeaderTitle}>
                    Пакет почты на {preferredPacket.limit} ГБ
                </Text>
                <Text style={styles.packetHeaderDiscount}>
                    Скидка {100 - preferredPacket.percent * 100}%
                </Text>
            </View>
            <Text style={styles.packetDescription}>
                {packetDescription}
            </Text>
            <View style={styles.packetPriceBlock}>
                <Text style={styles.packetPrice}>
                    {thinspaceNumber(preferredPacket.cost)} ₽/мес
                </Text>
                <Text style={styles.packetPriceWithoutDiscount}>
                    {thinspaceNumber(calculateFullPrice(
                        preferredPacket.cost,
                        100 - preferredPacket.percent * 100,
                    ))} ₽
                </Text>
            </View>
            <Button
                onPress={() => navigation.navigate('MailPacket')}
                title='Выбрать пакет'
                additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 13 }]}
                additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15, fontWeight: '400' }]}
            />
        </View>}
        {needShowPacket && isBudget && <View style={styles.packetBlock}>
            <View style={styles.packetHeader}>
                <Text style={styles.packetHeaderTitle}>
                    Пакет почты на {preferredPacket.limit} ГБ
                </Text>
            </View>
            <View style={styles.packetPriceBlock}>
                <Text style={styles.packetPrice}>
                    {thinspaceNumber(preferredPacket.cost)} ₽/мес
                </Text>
            </View>
            <Button
                onPress={() => setOrderPacketModalShowed(true)}
                title='Подключить пакет'
                additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 13 }]}
                additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15, fontWeight: '400' }]}
            />
        </View>}
        {hasPacket && userStatisticsInfo && <View style={[
            styles.packetBlock, { marginTop: 8, backgroundColor: '#ffffff' }]}>
            <View style={styles.packetHeader}>
                <Text style={styles.packetHeaderTitle}>
                    Пакет почты на {calculation.packet.limit} ГБ
                </Text>
                {calculation.packet.percent < 1 && <Text style={styles.packetHeaderDiscount}>
                    Скидка {100 - calculation.packet.percent * 100}%
                </Text>}
            </View>
            <CurrentPacketBlock
                needHeader={false}
                additionalWrapperStyles={[styles.resourceFillsWrapper]}
                packet={calculation.packet}
                freeQuota={userInfo.free_quota}
                used={userStatisticsInfo.summary.used}
            />
            <View style={styles.packetPriceBlock}>
                <Text style={styles.packetHint}>
                    {calculation.packet.prolong
                        ? `Пакет обновится ${dayjs(calculation.packet.paid_till).format('D MMMM')}`
                        : `Пакет оплачен до ${dayjs(calculation.packet.paid_till).format('D MMMM')}`}
                </Text>
                <View style={styles.spring} />
                <Text style={styles.packetHint}>
                    {thinspaceNumber(calculation.packet.cost)} ₽/мес
                </Text>
            </View>
            <Button
                onPress={() => navigation.navigate('MailPacket')}
                title='Управлять пакетом'
                type='white'
                additionalButtonStyles={[{ paddingTop: 6, paddingBottom: 5 }]}
                additionalButtonTextStyles={[{
                    fontSize: 13, lineHeight: 15, fontWeight: '400', color: '#455562'
                }]}
            />
        </View>}
        <Modal isModalShowed={cancelPaidQuotaModalShowed}
            component={<CancelMailPaidQuotaModal
                onClose={() => setCancelPaidQuotaModalShowed(false)}/>}
            onClose={() => setCancelPaidQuotaModalShowed(false)} />
        <Modal isModalShowed={activatePaidQuotaModalShowed}
            component={<ActivateMailPaidQuotaModal
                onClose={() => setActivatePaidQuotaModalShowed(false)}/>}
            onClose={() => setActivatePaidQuotaModalShowed(false)} />
        <Modal isModalShowed={orderPacketModalShowed}
            component={<OrderPacketModal
                limit={preferredPacket && preferredPacket.limit}
                onClose={() => setOrderPacketModalShowed(false)}/>}
            onClose={() => setOrderPacketModalShowed(false)} />
        <MailWebsocket />
    </ScrollView>;
}

const styles = StyleSheet.create({
    headerBlock: {
        paddingBottom: 16,
        paddingTop: 14,
        paddingLeft: 20,
        paddingRight: 21,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        color: '#455562',
    },
    bannerBlock: {
        marginTop: 12,
        paddingBottom: 20,
        paddingTop: 16,
        paddingLeft: 24,
        paddingRight: 25,
        backgroundColor: '#eaf5ff',
    },
    bannerDescription: {
        marginTop: 12,
        marginBottom: 12,
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 16,
        color: '#455562',
        opacity: 0.8,
    },
    bannerHeaderBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    bannerHeaderTitle: {
        color: '#455562',
        lineHeight: 19,
        fontSize: 16,
        fontWeight: '500',
    },
    bannerHeaderTitleBlue: {
        color: '#0f79d5',
        lineHeight: 19,
        fontSize: 16,
        fontWeight: '500',
    },
    icon: {
        width: 24,
        height: 24,
        marginRight: 16,
    },
    packetBlock: {
        backgroundColor: '#eaf5ff',
        paddingTop: 17,
        paddingLeft: 25,
        paddingRight: 23,
        paddingBottom: 25,
    },
    packetHeader: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 22,
    },
    packetHeaderTitle: {
        color: '#455562',
        fontSize: 14,
        fontWeight: '500',
    },
    packetHeaderDiscount: {
        borderRadius: 4,
        backgroundColor: '#0f79d5',
        color: '#ffffff',
        fontSize: 10,
        fontWeight: '500',
        lineHeight: 16,
        paddingTop: 3,
        paddingLeft: 8,
        paddingRight: 9,
        paddingBottom: 3,
    },
    packetDescription: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '400',
        lineHeight: 14,
        marginTop: 8,
    },
    packetPriceBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginTop: 12,
        marginBottom: 16,
    },
    packetPrice: {
        color: '#0f79d5',
        fontSize: 16,
        fontWeight: '500',
        marginRight: 16,
    },
    packetPriceWithoutDiscount: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        opacity: 0.8,
        marginBottom: 1,
        textDecorationLine: 'line-through',
    },
    resourceFillsWrapper: {
        paddingTop: 13,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    },
    packetHint: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        lineHeight: 11,
    },
    spring: {
        flex: 1,
    }
});

StatisticsTab.propTypes = {
    navigation: PropTypes.shape({}),
};

export default StatisticsTab;
