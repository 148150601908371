import React from 'react';
import * as dayjs from 'dayjs'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as dayjsDuration from 'dayjs/plugin/duration';
import { View, StyleSheet, Text, Image } from 'react-native';

import TimerBlock from '../../General/Timer/TimerBlock';
import { promisedPaymentBannerImage } from '../../../icons';

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 18,
        paddingTop: 21,
        paddingLeft: 20,
        paddingBottom: 16,
        position: 'relative',
        backgroundColor: '#ffffff'
    },
    title: {
        fontWeight: '600',
        color: '#455562',
        fontSize: 12,
        lineHeight: 20,
        marginBottom: 3
    },
    textBlock: {
        marginBottom: 12
    },
    description: {
        color: '#455562',
        fontSize: 10,
        lineHeight: 13
    },
    boldText: {
        color: '#455562',
        fontWeight: '600',
        fontSize: 10,
        lineHeight: 13
    },
    image: {
        height: 110,
        width: 146,
        right: 0,
        bottom: 0,
        position: 'absolute'
    }
})

function getTimerList(duration) {
    return [
        {
            amount: duration.hours().toString().padStart(2, '0'),
            variants: ['час', 'часа', 'часов']
        }, {
            amount: duration.minutes().toString().padStart(2, '0'),
            variants: ['минута', 'минуты', 'минут']
        }, {
            amount: duration.seconds().toString().padStart(2, '0'),
            variants: ['секунда', 'секунды', 'секунд']
        }
    ];
}

function PromisedPaymentBanner(props) {
    const {
        creditInfo
    } = props;

    const currentTime = new Date();
    const duration = dayjsDuration;
    dayjs.extend(duration);
    const diff = new Date(creditInfo.enabled_to) - currentTime;
    const [currentDuration, setDuration] = React.useState(dayjs.duration(diff));
    React.useEffect(() => {
        const interval = setInterval(() => {
            setDuration(currentDuration.subtract(1000, 'ms'));
        }, 1000);
        return () => clearInterval(interval);
    }, [currentDuration]);
    return <View style={[styles.wrapper]}>
        <Text style={[styles.title]}>Подключен обещанный платеж</Text>
        <View style={[styles.textBlock]}>
            <Text style={[styles.description]}>Вам необходимо пополнить баланс&nbsp;</Text>
            <Text style={[styles.boldText]}>
                до {dayjs(creditInfo.enabled_to).format('DD MMMM HH:mm')}
            </Text>
        </View>
        <TimerBlock timerList={getTimerList(currentDuration)}/>
        <Image source={promisedPaymentBannerImage} style={styles.image} />
    </View>
};

PromisedPaymentBanner.propTypes = {
    creditInfo: PropTypes.shape({
        enabled_to: PropTypes.string
    })
};

const mapStateToProps = (state) => ({
    creditInfo: state.user.financeInfo.credit_info,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PromisedPaymentBanner);
