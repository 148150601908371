import React, { useState } from 'react';

export const ModalContext = React.createContext({
    currentModal: null,
    checkModal: (modalType) => {},
    openModal: (modalType) => {},
    closeModal: () => {}
});

export const ModalContextProvider = ({ children }) => {
    const [currentModal, setCurrentModal] = useState(null);

    return <ModalContext.Provider value={{
        currentModal,
        checkModal: (modalType) => currentModal === modalType,
        openModal: (modalType) => { setCurrentModal(modalType); },
        closeModal: () => { setCurrentModal(null); }
    }}>
        {children}
    </ModalContext.Provider>;
};
