import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, Image } from 'react-native';

import Errors from '../../General/Form/Errors';
import Button from '../../General/Button/Button';
import { promisedPaymentImage } from '../../../icons';
import { flushErrors } from '../../../actions/forms/actions';
import { deferredPaymentSet } from '../../../actions/finances/actions';

const formId = 'deferredPaymentFormId';

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
        backgroundColor: '#ffffff',
        flex: 1,
        alignItems: 'center'
    },
    textBlock: {
        marginTop: 15,
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: 20,
        marginRight: 20
    },
    textStyle: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 18,
    },
    description: {
        color: '#455562',
        fontSize: 13,
        maxWidth: 343,
        lineHeight: 18,
        marginLeft: 20,
        marginRight: 20
    },
    boldText: {
        color: '#455562',
        fontWeight: '600',
        fontSize: 13,
        lineHeight: 18
    },
    image: {
        marginTop: 22,
        marginBottom: 27,
        height: 158,
        width: 160
    },
    button: {
        paddingRight: 30,
        paddingLeft: 30
    },
    buttonWrapper: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20
    },
    buttonText: {
        fontSize: 13,
        lineHeight: 15,
        minHeight: 15,
    },
    buttonBlock: {
        width: '100%',
        maxWidth: 385
    },
    errorBlock: {
        marginTop: 10,
        maxWidth: 343,
        marginLeft: 20,
        marginRight: 20
    }
});

function PromisedPaymentScreen(props) {
    const {
        errors,
        inProgress,
        navigation,
        flushErrors,
        deferredPaymentSet
    } = props;

    const currentTime = new Date();

    React.useEffect(() => {
        flushErrors();
    }, []);

    return <View style={[styles.wrapper]}>
        <Image source={promisedPaymentImage} style={styles.image} />
        <Text style={[styles.description]}>После активации обещанного платежа ваш аккаунт будет включен,
            а услуги будут доступны в течение 24 часов</Text>
        <View>
            <View style={[styles.textBlock]}>
                <Text style={[styles.textStyle]}>Вам необходимо пополнить баланс&nbsp;</Text>
                <Text style={[styles.boldText]}>
                    до {dayjs(currentTime).add(1, 'day').format('DD MMMM HH:mm')}
                </Text>
            </View>
        </View>
        <View style={[styles.buttonBlock]}>
            <Button additionalButtonTextStyles={[styles.buttonText]}
                additionalWrapperStyles={[styles.buttonWrapper]}
                additionalButtonStyles={[styles.button]}
                inProgress={inProgress}
                onPress={() => deferredPaymentSet(formId, () => navigation.goBack())}
                title="Активировать обещанный платеж" />
        </View>
        <View style={[styles.errorBlock]}>
            <Errors errors={errors.__all__} />
        </View>
    </View>
};

PromisedPaymentScreen.propTypes = {
    errors: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    inProgress: PropTypes.bool,
    deferredPaymentSet: PropTypes.func
};

const mapStateToProps = (state) => ({
    inProgress: state.user.deferredPaymentSetInProgress,
    errors: state.forms.errors[formId] || {}
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors, deferredPaymentSet
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PromisedPaymentScreen);
