import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet } from 'react-native';

import VMList from './VMList';
import WelcomePage from './WelcomePage';
import PaymentPage from './PaymentPage';
import CVDSWebsockets from './CVDSWebsockets';
import ResourceLoader from '../General/ResourceLoader/ResourceLoader';
import { fetchUserVMList, fetchUserVMsInfo } from '../../actions/cvds/actions';

function isNeedPayment(vmsInfo) {
    return vmsInfo && !vmsInfo.service_is_activate && vmsInfo.vm_active_count === 0;
}

function CVDSPage(props) {
    const {
        vmList,
        vmsInfo,
        fetchUserVMList,
        vmListInProgress,
        fetchUserVMsInfo
    } = props;
    React.useEffect(() => {
        if (!vmListInProgress && vmList == null) {
            fetchUserVMList();
        }
        if (vmsInfo == null) {
            fetchUserVMsInfo();
        }
    }, []);

    const needPayment = isNeedPayment(vmsInfo);
    return <View style={styles.wrapper}>
        {vmList && vmList.count === 0 && <React.Fragment>
            {!needPayment && <WelcomePage/>}
            {needPayment && <PaymentPage/>}
        </React.Fragment>}
        {vmListInProgress && vmList == null && <ResourceLoader height={107} count={3}/>}
        {vmList && vmList.count !== 0 && <VMList />}
        <CVDSWebsockets />
    </View>;
}

CVDSPage.propTypes = {
    vmList: PropTypes.shape({
        count: PropTypes.number,
    }),
    vmsInfo: PropTypes.shape({}),
    fetchUserVMList: PropTypes.func,
    vmListInProgress: PropTypes.bool,
    fetchUserVMsInfo: PropTypes.func
};

const mapStateToProps = (state) => ({
    vmList: state.cvds.vmList,
    vmsInfo: state.cvds.vmsInfo,
    vmListInProgress: state.cvds.vmListInProgress
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserVMList, fetchUserVMsInfo
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        flex: 1
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CVDSPage);
