import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigation } from '@react-navigation/native';
import {
    View, Text, Image, StyleSheet, TouchableOpacity
} from 'react-native';

import {
    ddosGuardBGIcon,
    rightIcon
} from '../../icons';
import Switch from '../General/Switch/Switch';
import Modal from '../General/Modal/Modal';
import Button from '../General/Button/Button';
import AddAntiDDoSModal from './Modals/AddAntiDDoSModal';
import AddProtectedIPModal from './Modals/AddProtectedIPModal';
import { calculateIPPrice, calculateBandwidthPrice, isActiveVM } from './utils';
import { IPv4, IP_PROTECTED_POOL } from '../../constants/cvds';
import DisableProtectionModal from './Modals/DisableProtectionModal';
import { ModalContext } from '../../contexts/ModalContext';
import { blockedButtonTooltips } from '../../constants/general';
import { addCustomNotification } from '../../actions/notifications/actions';
import { pluralize } from '../../utils/general';

function getIps(ips) {
    return ips.filter((ip) => ip.pool === IP_PROTECTED_POOL);
}

function getEmptyBlock(setAddAntiDDoSModalShowed, isEnabledActions, disabledText) {
    return <View style={styles.emptyBlock}>
        <Text style={styles.title}>Защита от DDOS</Text>
        <Text style={styles.description}>Полная фильтрация входящего
            трафика с полосой до 50 Мбит/сек</Text>
        <Button title='Подключить'
            disabled={!isEnabledActions}
            disabledText={disabledText}
            additionalWrapperStyles={[{ width: 157 }]}
            additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 13 }]}
            additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, minHeight: 16, }]}
            onPress={() => setAddAntiDDoSModalShowed()}/>
        <Image source={ddosGuardBGIcon} style={styles.ddosGuardBGIcon}/>
    </View>;
}

const addIPModalId = 'add-protected-ip-modal';
const deleteIPModalId = 'delete-protected-ip-modal';
const addAntiDDoSModalId = 'add-antiddos-modal';
const disableProtectionModalId = 'disable-protection-modal';

function AntiDDoS(props) {
    const { vm, protectedWebsites } = props;
    const isEnabledActions = isActiveVM(vm.state) && vm.is_managed;

    const navigation = useNavigation();
    const { openModal, closeModal, checkModal } = useContext(ModalContext);
    const disabledText = (isActiveVM(vm.state) && !vm.is_managed)
        ? blockedButtonTooltips.cvds.DisableGuestAgent
        : blockedButtonTooltips.cvds[vm.state];
    const ipsCount = getIps(vm.ips).length;
    return <View style={styles.wrapper}>
        {!vm.is_protected && getEmptyBlock(() => openModal(addAntiDDoSModalId),
            isEnabledActions, disabledText)}
        {vm.is_protected && <View style={styles.antiDDoSBlock}>
            <View style={styles.antiDDoSHeader}>
                <Text style={styles.title}>Защита от DDOS</Text>
                <View>
                    <Switch
                        isEnable={vm.is_protected}
                        disabled={!isEnabledActions}
                        disabledText={disabledText}
                        onChange={() => openModal(disableProtectionModalId)}/>
                </View>
            </View>
            <TouchableOpacity
                style={styles.antiDDoSField}
                onPress={() => navigation.navigate('CVDSVMEditBandwidth', { vmId: vm.id })}
            >
                <View style={styles.antiDDoSFieldTextBlock}>
                    <Text style={styles.antiDDoSFieldTitle}>
                        Полоса для трафика {vm.bandwidth} Мбит/c
                    </Text>
                    <Text style={styles.antiDDoSFieldText}>
                        {calculateBandwidthPrice(vm.bandwidth).toFixed(0)} ₽/мес
                    </Text>
                </View>
                <Image source={rightIcon} style={styles.image}/>
            </TouchableOpacity>
            <TouchableOpacity
                style={styles.antiDDoSField}
                onPress={() => navigation.navigate('CVDSVMProtectedIps', { vmId: vm.id })}
            >
                <View style={styles.antiDDoSFieldTextBlock}>
                    <Text style={styles.antiDDoSFieldTitle}>
                        IP-адреса с защитой от DDOS
                    </Text>
                    <Text style={styles.antiDDoSFieldText}>
                        {ipsCount} {pluralize(ipsCount, ['адрес', 'адреса', 'адресов'])} — {calculateIPPrice(ipsCount, IPv4).toFixed(0)} ₽/мес
                    </Text>
                </View>
                <Image source={rightIcon} style={styles.image}/>
            </TouchableOpacity>
            <TouchableOpacity
                style={styles.antiDDoSField}
                onPress={() => navigation.navigate('CVDSVMProtectedWebsites', { vmId: vm.id })}
            >
                <View style={styles.antiDDoSFieldTextBlock}>
                    <Text style={styles.antiDDoSFieldTitle}>
                        Домены для L7-фильтрации
                    </Text>
                    {protectedWebsites && <Text style={styles.antiDDoSFieldText}>
                        {protectedWebsites.count} {pluralize(protectedWebsites.count, ['домен', 'домена', 'доменов'])}
                    </Text>}
                </View>
                <Image source={rightIcon} style={styles.image}/>
            </TouchableOpacity>
        </View>}
        <Modal isModalShowed={checkModal(addAntiDDoSModalId)}
            component={<AddAntiDDoSModal bandwidth={vm.bandwidth}
                isChangeBandwidth={Boolean(vm.bandwidth)}
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
        <Modal isModalShowed={checkModal(addIPModalId)}
            component={<AddProtectedIPModal
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
        <Modal isModalShowed={checkModal(disableProtectionModalId)}
            component={<DisableProtectionModal
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
    </View>;
}

AntiDDoS.propTypes = {
    vm: PropTypes.shape({}),
    protectedWebsites: PropTypes.shape({}),
    addCustomNotification: PropTypes.func
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    protectedWebsites: state.cvds.protectedWebsites,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ addCustomNotification }, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 24
    },
    antiDDoSHeader: {
        paddingTop: 17,
        paddingLeft: 20,
        paddingRight: 16,
        paddingBottom: 17,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 1,
        backgroundColor: '#ffffff'
    },
    emptyBlock: {
        paddingTop: 24,
        paddingLeft: 20,
        paddingBottom: 21,
        position: 'relative',
        backgroundColor: '#ffffff'
    },
    ddosGuardBGIcon: {
        height: 144,
        width: 151,
        right: 0,
        bottom: 0,
        position: 'absolute'
    },
    title: {
        color: '#455562',
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 16
    },
    description: {
        color: '#455562',
        fontSize: 11,
        lineHeight: 14,
        marginTop: 16,
        marginBottom: 20,
        maxWidth: 185
    },
    antiDDoSField: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        paddingLeft: 20,
        paddingBottom: 16,
        paddingTop: 16,
        paddingRight: 21,
        marginBottom: 1,
    },
    antiDDoSFieldTitle: {
        marginBottom: 4,
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 16
    },
    antiDDoSFieldText: {
        color: '#455562',
        fontSize: 11,
        lineHeight: 14
    },
    image: {
        width: 7,
        height: 12,
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AntiDDoS);
