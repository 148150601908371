import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, StyleSheet } from 'react-native';

import { getPriceForPacket, getValueFromPercent } from '../utils';
import { disksIds } from '../../../constants/cvds';
import cvdsTariffData from '../../../../data/cvds/tariffs.json';
import cvdsDiskData from '../../../../data/cvds/disk-types.json';
import { createPacket } from '../../../actions/cvds/actions';
import { flushErrors } from '../../../actions/forms/actions';
import CreatePacketModal from '../../General/CreatePacketModal/CreatePacketModal';

const formId = 'CreatePacketModalForm';

function CVDSCreatePacketModal(props) {
    const {
        vm,
        errors,
        onClose,
        inProgress,
        createPacket,
        flushErrors,
        calculation
    } = props;

    const {
        resources,
        cost
    } = calculation;

    React.useEffect(() => {
        flushErrors();
    }, []);

    const { tariff } = resources.vm;
    const currentTariff = cvdsTariffData.find(cvdsTariff => cvdsTariff.id === tariff);
    const disksInfo = disksIds.map(diskId => ({
        value: resources.storage[diskId].count,
        isChange: false,
        format: v => `${v} ГБ`,
        title: cvdsDiskData.find(diskData => diskData.id === diskId).name,
        isDisplayed: resources.storage[diskId].count !== 0
    }));
    const descriptionParameters = [
        {
            value: currentTariff.name,
            isChange: false,
            format: v => v,
            title: 'Тариф'
        }, ...disksInfo, {
            value: resources.ips.count,
            isChange: false,
            format: v => `${v} шт`,
            title: 'IP',
            isDisplayed: resources.ips.count !== 0
        }, {
            value: resources.backup.count,
            isChange: false,
            title: 'Бэкапы',
            format: v => `${v} ГБ`,
            isDisplayed: resources.backup.count !== 0
        }, {
            value: resources.bandwidth.count,
            isChange: false,
            title: 'Защита от DDoS',
            format: v => `${v} Мбит/с`,
            isDisplayed: resources.bandwidth.count !== 0
        }
    ];
    const fullCost = getValueFromPercent(cost.packet, 10);
    return <View style={styles.wrapper}>
        <Text style={[styles.title]}>Заказ годового пакета</Text>
        <CreatePacketModal
            errors={errors}
            inProgress={inProgress}
            descriptionParameters={descriptionParameters}
            priceForPacket={getPriceForPacket(cost.packet)}
            onClose={() => onClose()}
            onAccept={() => createPacket(formId, vm.id, onClose)}
            priceForPacketAdvantage={getPriceForPacket(fullCost - cost.packet)} />
    </View>;
}

CVDSCreatePacketModal.propTypes = {
    vm: PropTypes.shape({}),
    onClose: PropTypes.func,
    createPacket: PropTypes.func,
    errors: PropTypes.shape({}),
    inProgress: PropTypes.bool,
    calculation: PropTypes.shape({
        resources: PropTypes.shape({
            backup: PropTypes.shape({
                count: PropTypes.number,
                cost: PropTypes.number
            }),
            bandwidth: PropTypes.shape({
                count: PropTypes.number,
                cost: PropTypes.number
            }),
            ips: PropTypes.shape({
                count: PropTypes.number,
                cost: PropTypes.number
            }),
            storage: PropTypes.shape({
                fast: PropTypes.shape({
                    count: PropTypes.number,
                    cost: PropTypes.number
                }),
                normal: PropTypes.shape({
                    count: PropTypes.number,
                    cost: PropTypes.number
                }),
                slow: PropTypes.shape({
                    count: PropTypes.number,
                    cost: PropTypes.number
                })
            }),
            vm: PropTypes.shape({
                tariff: PropTypes.string,
                cost: PropTypes.number
            })
        }),
        cost: PropTypes.shape({
            packet: PropTypes.number
        })
    })
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    inProgress: state.cvdsFinanceInfo.createPacketInProgress,
    errors: state.forms.errors[formId] || {},
    calculation: state.cvdsFinanceInfo.calculation
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    createPacket, flushErrors
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 30,
        paddingLeft: 24,
        paddingRight: 21,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        textAlign: 'center',
        fontSize: 16,
        lineHeight: 18
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CVDSCreatePacketModal);
