import React, { useContext } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';

import Modal from '../../General/Modal/Modal';
import InvoiceActionsModal from './InvoiceActionsModal';
import { ModalContext } from '../../../contexts/ModalContext';
import { formatPrice } from '../../../utils/general';

const invoiceActionsModalId = 'invoice-action-modal';

function InvoicesList(props) {
    const {
        financeInvoices
    } = props;
    const { openModal, closeModal, checkModal } = useContext(ModalContext);
    const [currentInvoice, setCurrentInvoice] = React.useState(null);

    return <View>
        <Text style={styles.title}>Выставленные счета</Text>
        {financeInvoices.entities && <View style={styles.listBlock}>
            {financeInvoices.entities.map((invoice, i) => <TouchableOpacity style={styles.invoice}
                key={`invoices-list-item-${i}`}
                onPress={() => {
                    setCurrentInvoice(invoice);
                    openModal(invoiceActionsModalId);
                }}>
                <Text style={styles.invoiceTitle}>
                    №{invoice.number} от {dayjs(invoice.created_at,
                        'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Text>
                <Text style={styles.invoicePrice}>{formatPrice(invoice.total.toFixed(2))} ₽</Text>
            </TouchableOpacity>)}
        </View>}
        <Modal isModalShowed={checkModal(invoiceActionsModalId)}
            component={<InvoiceActionsModal invoice={currentInvoice}
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
    </View>;
}

InvoicesList.propTypes = {
    financeInvoices: PropTypes.shape({
        entities: PropTypes.arrayOf(PropTypes.shape({})),
        inProgress: PropTypes.bool
    })
};

const mapStateToProps = (state) => ({
    financeInvoices: state.invoices
});
const mapDispatchToProps = () => ({});

const styles = StyleSheet.create({
    title: {
        marginTop: 29,
        marginLeft: 20,
        marginBottom: 12,
        fontWeight: '600',
        color: '#455562',
        fontSize: 11,
        lineHeight: 13,
        letterSpacing: 1.38,
        opacity: 0.75,
        textTransform: 'uppercase'
    },
    invoice: {
        flex: 1,
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 19,
        paddingBottom: 16,
        borderBottomWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderBottomColor: '#e4e4e4',
        justifyContent: 'space-between'
    },
    invoiceTitle: {
        color: '#0f79d5',
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 16
    },
    invoicePrice: {
        color: '#455562',
        fontSize: 14,
        lineHeight: 16
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesList);
