import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';

import {
    clamp,
    getPercent,
    isFunction,
} from '../../../utils/general';
import ResourceFillScale from './ResourceFillScale';

const styles = StyleSheet.create({
    nameBlock: {
        flex: 1,
        marginBottom: 7,
        flexDirection: 'row',
        justifyContent: 'space-between',
        minHeight: 14
    },
    nameText: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 14
    },
    resourceFillScaleBackground: {
        borderRadius: 2,
        height: 15,
        width: '100%',
        textAlign: 'center',
        backgroundColor: '#eaeaea',
        position: 'absolute',
        zIndex: -1,
    },
    resourceScales: {
        flex: 1,
        height: 15,
        flexDirection: 'row'
    },
    resourceFills: {
        flex: 1
    },
    resourceFillsScales: {
        flex: 1,
        flexDirection: 'row',
        position: 'relative',
        borderWidth: 1,
        borderRadius: 4,
        borderColor: 'rgba(69,85,98,0.05)'
    },
    resourceFillScaleText: {
        fontSize: 10,
        lineHeight: 15
    },
    resourceFillScaleError: {
        color: '#d06565'
    },
    spring: {
        flex: 1,
        minHeight: 15
    }
});

function getNormalizedScales(scales) {
    return scales.map(scale => ({
        ...scale,
        percent: scale.percent != null
            ? clamp(scale.percent)
            : clamp(getPercent(scale.max, scale.current))
    }));
}

function ResourceFills(props) {
    const {
        name,
        type,
        scales,
        height = 15,
        bottom,
        isError,
        isLoaded,
        isLoading,
        errorMessage,
        loadingMessage,
        additionalHeader = '',
        needBorderRadius = false,
        backgroundAdditionalStyles,
        isBackgroundEnabled = true,
        additionalHeaderTextStyles = [],
    } = props;
    const normalizeScales = getNormalizedScales(scales);
    const resourceFillsScalesStyle = [
        styles.resourceFillsScales,
        { borderRadius: needBorderRadius ? 4 : 0 }
    ];

    return <View style={styles.resourceFills}>
        {name && <View style={styles.nameBlock}>
            <Text style={[styles.nameText, ...additionalHeaderTextStyles]}>{name}</Text>
            {additionalHeader && <Text style={[styles.nameText, ...additionalHeaderTextStyles]}>
                {additionalHeader}
            </Text>}
        </View>}
        {isLoaded && <View style={resourceFillsScalesStyle}>
            {isBackgroundEnabled && <View
                style={[
                    styles.resourceFillScaleBackground,
                    { height, ...backgroundAdditionalStyles }
                ]}
            />}
            <View style={[styles.resourceScales, { height }]}>
                {normalizeScales.map((scale, i) => <ResourceFillScale key={`scale-${type}-${i}`}
                    {...scale} needBorderRadius={needBorderRadius}>
                    {scale.scaleChildren}
                </ResourceFillScale>)}
            </View>
        </View>}
        {(isError || isLoading) && <View style={resourceFillsScalesStyle}>
            <View style={[styles.resourceFillScaleBackground,
                { height, ...backgroundAdditionalStyles }
            ]}>
                {isLoading && <Text style={styles.resourceFillScaleText}>
                    {loadingMessage || 'Загрузка...'}
                </Text>}
                {isError && <Text style={[styles.resourceFillScaleText,
                    styles.resourceFillScaleError]}>{errorMessage || 'Ошибка'}
                </Text>}
            </View>
        </View>}
        {(isError || isLoading) && <View style={styles.spring}/>}
        {isLoaded && bottom && bottom(normalizeScales)}
    </View>;
}

ResourceFills.propTypes = {
    id: PropTypes.string,
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    height: PropTypes.number,
    format: PropTypes.func,
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    additionalHeader: PropTypes.string,
    bottom: PropTypes.func,
    type: PropTypes.string,
    errorMessage: PropTypes.string,
    loadingMessage: PropTypes.string,
    isBackgroundEnabled: PropTypes.bool,
    scales: PropTypes.arrayOf(PropTypes.shape({
        scaleChildren: PropTypes.element,
        unit: PropTypes.func,
        formatValue: PropTypes.func,
        percent: PropTypes.number,
        current: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number,
    })),
    backgroundAdditionalStyles: PropTypes.shape({}),
    additionalHeaderTextStyles: PropTypes.arrayOf(),
    needBorderRadius: PropTypes.bool,
};

export default ResourceFills;
