import React from 'react';
import { useNavigation } from '@react-navigation/native';
import {
    View, StyleSheet, Text, Image
} from 'react-native';

import Button from '../General/Button/Button';
import { launchHostingIcon } from '../../icons';

function HostingLaunchPage() {
    const navigation = useNavigation();

    return <View style={styles.wrapper}>
        <Image source={launchHostingIcon} style={styles.image} />
        <View style={styles.content}>
            <Text style={styles.title}>Создайте свой первый контейнер</Text>
            <Text style={styles.description}>
                Внутри каждого контейнера вы сможете создать неограниченное количество сайтов с персональными настройками
            </Text>
        </View>
        <Button title='Создать контейнер'
            onPress={() => navigation.navigate('HostingCreateContainer')}
            additionalWrapperStyles={[{ width: '100%' }]}
            additionalButtonStyles={[{ paddingTop: 15, paddingBottom: 15 }]}
            additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15 }]}/>
    </View>;
}

HostingLaunchPage.propTypes = {};

const styles = StyleSheet.create({
    wrapper: {
        margin: 0,
        padding: 28,
        flex: 1,
        width: '100%',
        flexDirection: 'colume',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        minHeight: 188,
        minWidth: 260,
        marginBottom: 32,
    },
    title: {
        maxWidth: 320,
        textAlign: 'center',
        fontSize: 16,
        color: '#455562',
        letterSpacing: 0,
        lineHeight: 19,
        marginBottom: 19,
    },
    description: {
        maxWidth: 258,
        fontSize: 12,
        textAlign: 'center',
        letterSpacing: 0,
        color: '#455562',
        lineHeight: 18,
        marginBottom: 31,
    },
});

export default HostingLaunchPage;