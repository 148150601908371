import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';

import Modal from '../../General/Modal/Modal';
import { humanStateMap } from '../../../constants/general';
import StateBlock from '../../General/StateBlock/StateBlock';
import { turnOffBlueIcon, turnOffDisabledIcon } from '../../../icons';
import VirtualhostSwitchState from '../HostingModal/VirtualhostSwitchState';

function State(props) {
    const {
        virtualhost,
    } = props;
    const canChange = virtualhost.state === "DISABLED_BY_USER" || virtualhost.state === "ENABLED";
    const [isModalSwitchState, setModalSwitchState] = React.useState(false);
    return <View style={styles.itemBlock}>
        <View style={styles.stateWrapper}>
            <StateBlock state={virtualhost.enabled ? 'enabled' : 'disabled' }
                modifiersTitle={{
                    fontSize: 14,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    paddingRight: 10,
                    fontWeight: '500',
                    textOverflow: 'ellipsis',
                }}
                title={humanStateMap[virtualhost.state]}/>
        </View>
        <View style={styles.switchWrapper}>
            <TouchableOpacity style={styles.switchState}
                onPress={() => {
                    if (canChange) {
                        setModalSwitchState(true);
                    }
                }}>
                <Image source={canChange ? turnOffBlueIcon : turnOffDisabledIcon} style={styles.image} />
                <Text style={styles.switchStateText, { color: canChange ? '#0F79D5' : '#888888'}}>
                    {virtualhost.enabled ? 'Отключить' : 'Включить'}
                </Text>
            </TouchableOpacity>
        </View>
        <Modal isModalShowed={isModalSwitchState}
            component={<VirtualhostSwitchState
                virtualhostId={virtualhost.id}
                enabled={virtualhost.enabled}
                onClose={() => setModalSwitchState(false)}/>}
            onClose={() => setModalSwitchState(false)} />
    </View>;
}

State.propTypes = {
    virtualhost: PropTypes.shape({
        state: PropTypes.string,
        enabled: PropTypes.bool,
    })
};

const styles = StyleSheet.create({
    switchState: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'end',
    },
    switchWrapper: {
        paddingTop: 21,
        paddingBottom: 21,
        paddingRight: 18,
    },
    stateWrapper: {
        paddingTop: 21,
        paddingBottom: 21,
        paddingLeft: 18,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    itemBlock: {
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        backgroundColor: '#ffffff',
        
    },
    image: {
        width: 18,
        height: 16,
        marginRight: 10,
    },
    switchStateText: {
        fontSize: 14,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 13,
    },
})

export default State;