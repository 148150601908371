import React from 'react';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import CVDSCreateScreen from '../components/CVDS/VMCreatePage';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const CVDSCreateStack = createStackNavigator();

function CVDSCreateStackScreen(props) {
    const { navigation } = props;
    const insets = useSafeAreaInsets();

    return <CVDSCreateStack.Navigator>
        <CVDSCreateStack.Screen name="CVDSCreateStack" component={CVDSCreateScreen} options={{
            headerTitle: () => <ScreenHeaderTitle title='Новая виртуальная машина' />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => {
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                    } else {
                        navigation.navigate('CVDS');
                    }
                },
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </CVDSCreateStack.Navigator>;
}

export default CVDSCreateStackScreen;
