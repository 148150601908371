import { flushErrors, formErrors } from '../forms/actions';

import {
    CONTAINER_CREATE_IN_PROGRESS,
    CONTAINER_CREATE_SUCCESSFULLY,
    CONTAINER_CREATE_SET_PARAMS,
    CONTAINER_CREATE_FLUSH_PARAMS,
    CONTAINERS_FETCHED_IN_PROGRESS,
    CONTAINERS_FETCHED_SUCCESSFULLY,
    CONTAINERS_FETCHED_WITH_ERRORS,
    CONTAINER_FETCHED_IN_PROGRESS,
    CONTAINER_FETCHED_SUCCESSFULLY,
    CONTAINER_FETCHED_WITH_ERRORS,
    CONTAINER_VIRTUALHOSTS_FETCHED_IN_PROGRESS,
    CONTAINER_VIRTUALHOSTS_FETCHED_SUCCESSFULLY,
    CONTAINER_VIRTUALHOSTS_FETCHED_WITH_ERRORS,
    CONTAINER_LIMITS_IN_PROGRESS,
    CONTAINER_LIMITS_SUCCESSFULLY,
    CONTAINER_LIMITS_WITH_ERRORS,
    CONTAINER_INITIAL,
    CONTAINER_SSH_SET_PASSWORD,
    CONTAINER_SSH_SET_PASSWORD_IN_PROGRESS,
    CONTAINER_SSH_SET_PASSWORD_WITH_ERRORS,
    CONTAINER_DELETE,
    CONTAINER_DELETE_IN_PROGRESS,
    CONTAINER_DELETE_WITH_ERRORS,
    CONTAINER_UPDATE,
    CALCULATION_FETCH_IN_PROGRESS,
    CALCULATION_FETCH_SUCCESSFULLY,
    CALCULATION_FETCH_WITH_ERRORS,
    RECALCULATE_CONTAINER_PRICE,
    REDIS_UPDATE,
    REDIS_UPDATE_WITH_ERRORS,
    REDIS_DELETE,
    REDIS_DELETE_WITH_ERRORS,
    MEMCACHE_UPDATE,
    MEMCACHE_UPDATE_WITH_ERRORS,
    MEMCACHE_DELETE,
    MEMCACHE_DELETE_WITH_ERRORS,
    VIRTUALHOST_FETCHED_IN_PROGRESS,
    VIRTUALHOST_FETCHED_SUCCESSFULLY,
    VIRTUALHOST_FETCHED_WITH_ERRORS,
    VIRTUALHOST_CHANGE_STATE,
    VIRTUALHOST_UPDATE,
    VIRTUALHOST_UPDATE_IN_PROGRESS,
    VIRTUALHOST_UPDATE_WITH_ERRORS,
    DBMS_FETCHED_IN_PROGRESS,
    DBMS_FETCHED_SUCCESSFULLY,
    DBMS_FETCHED_WITH_ERRORS,
    VIRTUALHOST_DELETE,
    VIRTUALHOST_DELETE_IN_PROGRESS,
    VIRTUALHOST_DELETE_WITH_ERRORS,
    DBMS_CHANGE_PASSWORD,
    DBMS_CHANGE_PASSWORD_IN_PROGRESS,
    DBMS_CHANGE_PASSWORD_WITH_ERRORS,
    FTPS_FETCHED_IN_PROGRESS,
    FTPS_FETCHED_SUCCESSFULLY,
    FTPS_FETCHED_WITH_ERRORS,
    FTPS_CHANGE_PASSWORD,
    FTPS_CHANGE_PASSWORD_IN_PROGRESS,
    FTPS_CHANGE_PASSWORD_WITH_ERRORS,
    FTPS_DELETE,
    FTPS_DELETE_IN_PROGRESS,
    FTPS_DELETE_WITH_ERRORS,
    FTP_ADD,
    FTP_ADD_IN_PROGRESS,
    FTP_ADD_WITH_ERRORS,
    VIRTUALHOST_CREATE,
    BAR_CHART_CPU_FETCH_IN_PROGRESS,
    BAR_CHART_CPU_FETCH_SUCCESSFULLY,
    BAR_CHART_CPU_FETCH_WITH_ERRORS,
    BAR_CHART_RAM_FETCH_IN_PROGRESS,
    BAR_CHART_RAM_FETCH_SUCCESSFULLY,
    BAR_CHART_RAM_FETCH_WITH_ERRORS,
    SET_PROBLEM_CPU,
    SET_PROBLEM_MEMORY,
    SET_PROBLEM_STORAGE,
    CONTAINER_CAPABILITIES,
    SSL_UPLOAD_ARCHIVE_SUCCESSFULLY,
    SSL_UPLOAD_ARCHIVE_WITH_ERROR,
    VIRTUALHOST_RELOAD,
    CALCULATION_PACKET_CREATE_IN_PROGRESS,
    CALCULATION_PACKET_CREATE_SUCCESSFULLY,
    CALCULATION_PACKET_CREATE_WITH_ERRORS,
    CALCULATION_PACKET_UPDATE_IN_PROGRESS,
    CALCULATION_PACKET_UPDATE_WITH_ERRORS,
    CALCULATION_PACKET_RENEWAL_CHANGE_IN_PROGRESS,
    CALCULATION_PACKET_RENEWAL_CHANGE_SUCCESSFULLY,
    VIRTUALHOST_CREATE_IN_PROGRESS,
    VIRTUALHOST_CREATE_WITH_ERRORS,
    CONTAINER_UPDATE_IN_PROGRESS,
    CONTAINER_UPDATE_WITH_ERRORS,
    CONTAINER_CREATE_WITH_ERRORS,
    VIRTUALHOSTS_FETCHED_SUCCESSFULLY,
    VIRTUALHOSTS_FETCHED_IN_PROGRESS,
    VIRTUALHOSTS_FETCHED_WITH_ERRORS,
    HOSTING_RESTORE_FROM_BACKUP_TO_INIT,
    HOSTING_RESTORE_FROM_BACKUP_UPDATE_PARAMS,
    CONTAINER_BACKUPS_LIST_IN_PROGRESS,
    CONTAINER_BACKUPS_LIST_SUCCESSFULLY,
    CONTAINER_BACKUPS_LIST_WITH_ERRORS,
    CONTAINER_BACKUPS_RESTORE_SUCCESSFULLY,
    CONTAINER_BACKUPS_RESTORE_IN_PROGRESS,
    CONTAINER_BACKUPS_RESTORE_WITH_ERRORS,
    DBMS_BACKUPS_LIST_IN_PROGRESS,
    DBMS_BACKUPS_LIST_SUCCESSFULLY,
    DBMS_BACKUPS_LIST_WITH_ERRORS,
    DBMS_BACKUPS_RESTORE,
    GET_CONTAINER_CALCULATION_SUCCESSFULLY,
} from './actionTypes';
import { isFunction } from '../../utils/general';
import { certificateDetail } from '../certificates/actions';
import DbmsResource from '../../gateway/resources/hosting/dbms';
import FTPSResource from '../../gateway/resources/hosting/ftps';
import ContainerResource from '../../gateway/resources/hosting/containers';
import VirtualhostsResource from '../../gateway/resources/hosting/virtualhosts';

export const createContainerAction = (formId, name, memory, storage, environment, redis, memcached, createPacket, onSuccess, onError) => async (dispatch, params, { gateway }) => {
    dispatch({ type: CONTAINER_CREATE_IN_PROGRESS });
    dispatch(flushErrors());
    const resource = new ContainerResource(gateway);
    const createData = {
        name,
        memory_limit: memory,
        quota: storage,
        environment,
        create_packet: createPacket
    };
    if (redis) {
        createData.redis = redis;
    }
    if (memcached) {
        createData.memcache = memcached;
    }
    const response = await resource.create(createData);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CONTAINER_CREATE_SUCCESSFULLY,
            data
        });
        if (isFunction(onSuccess)) {
            onSuccess(data);
        }
    } else {
        dispatch({ type: CONTAINER_CREATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
        if (isFunction(onError)) {
            onError(data.errors);
        }
    }
};

export const createContainerSetParams = (params, callback) => async (dispatch) => {
    dispatch({
        type: CONTAINER_CREATE_SET_PARAMS,
        data: params
    });
    if (isFunction(callback)) {
        callback();
    }
};

export const createContainerFlushParams = () => async (dispatch) => {
    dispatch({ type: CONTAINER_CREATE_FLUSH_PARAMS });
};

export const fetchUserContainers = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: CONTAINERS_FETCHED_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.listWithVirtualhost();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CONTAINERS_FETCHED_SUCCESSFULLY,
            data
        });
    } else {
        if ([403, 404].includes(response.status)) {
            dispatch({
                type: CONTAINERS_FETCHED_SUCCESSFULLY,
                data: {
                    entities: [],
                    count: 0
                }
            });
            return;
        }
        dispatch({
            type: CONTAINERS_FETCHED_WITH_ERRORS
        });
    }
};

export const fetchUserContainer = (containerId, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: CONTAINER_FETCHED_IN_PROGRESS, data: { id: containerId } });
    const resource = new ContainerResource(gateway);
    const response = await resource.detail(containerId);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: CONTAINER_FETCHED_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({
            type: CONTAINER_FETCHED_WITH_ERRORS
        });
    }
};

export const fetchUserVirtualhost = (virtualhostId, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VIRTUALHOST_FETCHED_IN_PROGRESS, data: { id: virtualhostId } });
    const resource = new VirtualhostsResource(gateway);
    const response = await resource.detail(virtualhostId);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: VIRTUALHOST_FETCHED_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else if (response.status === 404) {
        dispatch({
            type: VIRTUALHOST_FETCHED_WITH_ERRORS
        });
    } else {
        dispatch({
            type: VIRTUALHOST_FETCHED_WITH_ERRORS
        });
    }
};

export const containerInitial = () => async (dispatch) => {
    dispatch({
        type: CONTAINER_INITIAL,
    });
};

export const fetchContainerVirtualhosts = containerId => async (dispatch, params, { gateway }) => {
    dispatch({ type: CONTAINER_VIRTUALHOSTS_FETCHED_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.virtualhosts(containerId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CONTAINER_VIRTUALHOSTS_FETCHED_SUCCESSFULLY,
            data: { containerId, virtualhosts: data }
        });
    } else {
        dispatch({
            type: CONTAINER_VIRTUALHOSTS_FETCHED_WITH_ERRORS
        });
    }
};

export const containerLimits = containerId => async (dispatch, params, { gateway }) => {
    dispatch({ type: CONTAINER_LIMITS_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.limits(containerId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CONTAINER_LIMITS_SUCCESSFULLY,
            data: { containerId, limits: data }
        });
    } else {
        dispatch({
            type: CONTAINER_LIMITS_WITH_ERRORS
        });
    }
};

export const containerChangeSSHPassword = (formId, containerId, password, callback) => async (dispatch, params, { gateway }) => {
    dispatch(flushErrors());
    dispatch({ type: CONTAINER_SSH_SET_PASSWORD_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.setPassword(containerId, password);
    const data = await response.json();
    if (response.ok) {
        dispatch({ type: CONTAINER_SSH_SET_PASSWORD, data });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: CONTAINER_SSH_SET_PASSWORD_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const containerDelete = (formId, containerId, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: CONTAINER_DELETE_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.delete(containerId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CONTAINER_DELETE,
            data: { containerId }
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: CONTAINER_DELETE_WITH_ERRORS });
        dispatch(formErrors(formId, data));
    }
};

export const fetchContainerCalculation = (containerId) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: CALCULATION_FETCH_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.calculation(containerId);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: CALCULATION_FETCH_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: CALCULATION_FETCH_WITH_ERRORS
        });
    }
};

export const containerChangeQuota = (formId, containerId, quota, callback) => async (dispatch, params, { gateway }) => {
    dispatch(containerUpdate(formId, containerId, { quota }, callback));
};

export const containerChangeMemory = (formId, containerId, memoryLimit, callback) => async (dispatch, params, { gateway }) => {
    dispatch(containerUpdate(formId, containerId, { memory_limit: memoryLimit }, callback));
};

export const recalculateContainerPrice = (login, memory, storage, redis, memcached, isPacket) => async (dispatch) => {
    dispatch({
        type: RECALCULATE_CONTAINER_PRICE,
        data: {
            login,
            isPacket,
            memory,
            storage,
            redis,
            memcached
        }
    });
};

export const calculationPacketCreate = (formId, containerId, callback) => async (dispatch, params, { gateway }) => {
    dispatch({ type: CALCULATION_PACKET_CREATE_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.packetCreate(containerId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CALCULATION_PACKET_CREATE_SUCCESSFULLY,
            data
        });
        dispatch(fetchContainerCalculation(containerId));
        if (callback) {
            callback();
        }
    } else {
        dispatch({ type: CALCULATION_PACKET_CREATE_WITH_ERRORS });
        dispatch(formErrors(formId, data));
    }
};

export const calculationPacketRenewal = (formId, containerId, renewal, callback) => async (dispatch, params, { gateway }) => {
    dispatch({ type: CALCULATION_PACKET_RENEWAL_CHANGE_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.packetUpdate(containerId, { prolong: renewal });
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CALCULATION_PACKET_RENEWAL_CHANGE_SUCCESSFULLY,
            data
        });
        if (callback) {
            callback();
        }
    } else {
        dispatch(formErrors(formId, data));
    }
};

export const calculationPacketRefresh = (formId, containerId, callback) => async (dispatch, params, { gateway }) => {
    dispatch(flushErrors());
    dispatch({ type: CALCULATION_PACKET_UPDATE_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.packetRefresh(containerId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CALCULATION_FETCH_SUCCESSFULLY,
            data
        });
        if (callback) {
            callback(data);
        }
    } else {
        dispatch({ type: CALCULATION_PACKET_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const rawCalculate = (login, memory, storage, redis, memcached) => async (dispatch, getParams, { gateway }) => {
    dispatch({
        type: CALCULATION_FETCH_IN_PROGRESS
    });
    const resource = new ContainerResource(gateway);
    const response = await resource.rawCalculation(login, memory, storage, redis, memcached);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CALCULATION_FETCH_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: CALCULATION_FETCH_WITH_ERRORS,
            data
        });
    }
};


export const redisUpdate = (containerId, memory, callback) => async (dispatch, params, { gateway }) => {
    const resource = new ContainerResource(gateway);
    const response = await resource.redisUpdate(containerId, memory);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: REDIS_UPDATE,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({
            type: REDIS_UPDATE_WITH_ERRORS
        });
    }
};

export const redisDelete = (containerId, callback) => async (dispatch, params, { gateway }) => {
    const resource = new ContainerResource(gateway);
    const response = await resource.redisDelete(containerId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: REDIS_DELETE,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({
            type: REDIS_DELETE_WITH_ERRORS
        });
    }
};

export const memcacheUpdate = (containerId, memory = 256, callback) => async (dispatch, params, { gateway }) => {
    const resource = new ContainerResource(gateway);
    const response = await resource.memcacheUpdate(containerId, memory);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: MEMCACHE_UPDATE,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({
            type: MEMCACHE_UPDATE_WITH_ERRORS
        });
    }
};

export const memcacheDelete = containerId => async (dispatch, params, { gateway }) => {
    const resource = new ContainerResource(gateway);
    const response = await resource.memcacheDelete(containerId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: MEMCACHE_DELETE,
            data
        });
    } else {
        dispatch({
            type: MEMCACHE_DELETE_WITH_ERRORS
        });
    }
};

export const containerUpdate = (formId, containerId, updateResource, callback) => async (dispatch, params, { gateway }) => {
    dispatch({ type: CONTAINER_UPDATE_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.update(containerId, updateResource);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CONTAINER_UPDATE,
            data
        });
        dispatch(fetchContainerVirtualhosts(containerId));
        dispatch(containerLimits(containerId));
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: CONTAINER_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data));
    }
};

export const containerRename = (formId, containerId, { name }, callback) => async (dispatch, params, { gateway }) => {
    dispatch(containerUpdate(formId, containerId, { name }, callback));
};

export const virtualhostChangeState = (formId, virtualhostId, enabled, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VIRTUALHOST_UPDATE_IN_PROGRESS });
    const resource = new VirtualhostsResource(gateway);
    const response = enabled ? await resource.disable(virtualhostId) : await resource.enable(virtualhostId);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: VIRTUALHOST_CHANGE_STATE,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VIRTUALHOST_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId,  data));
    }
};

export const virtualhostDelete = (formId, virtualhostId, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VIRTUALHOST_DELETE_IN_PROGRESS });
    const resource = new VirtualhostsResource(gateway);
    const response = await resource.delete(virtualhostId);
    if (response.ok) {
        dispatch({
            type: VIRTUALHOST_DELETE,
            data: { virtualhostId }
        });
        if (isFunction(callback)) {
            callback({ virtualhostId });
        }
    } else {
        dispatch({ type: VIRTUALHOST_DELETE_WITH_ERRORS });
        dispatch(formErrors(formId,  data));
    }
};

export const aliasDeleteAction = (formId, virtualhostId, alias) => async (dispatch, getState, { gateway }) => {
    const resource = new VirtualhostsResource(gateway);
    const response = await resource.deleteAlias(virtualhostId, alias);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VIRTUALHOST_UPDATE,
            data
        });
    } else {
        dispatch(formErrors(formId, data));
    }
};

export const aliasAddAction = (formId, virtualhostId, alias, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VIRTUALHOST_UPDATE_IN_PROGRESS });
    const resource = new VirtualhostsResource(gateway);
    const response = await resource.addAlias(virtualhostId, alias);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VIRTUALHOST_UPDATE,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VIRTUALHOST_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data));
    }
};

export const virtualhostRestart = (formId, virtualhostId, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VIRTUALHOST_UPDATE_IN_PROGRESS });
    const resource = new VirtualhostsResource(gateway);
    const response = await resource.virtualhostRestart(virtualhostId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VIRTUALHOST_RELOAD,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VIRTUALHOST_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data));
    }
};

export const fetchDbms = (virtualhostId) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: DBMS_FETCHED_IN_PROGRESS });
    const resource = new DbmsResource(gateway);
    const response = await resource.databases(virtualhostId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: DBMS_FETCHED_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: DBMS_FETCHED_WITH_ERRORS
        });
    }
};

export const dbmsChangePassword = (formId, virtualhostId, password, callback) => async (dispatch, params, { gateway }) => {
    dispatch(flushErrors());
    dispatch({ type: DBMS_CHANGE_PASSWORD_IN_PROGRESS });
    const resource = new DbmsResource(gateway);
    const response = await resource.changePassword(virtualhostId, password);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: DBMS_CHANGE_PASSWORD,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: DBMS_CHANGE_PASSWORD_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const fetchVirtualhostFTPs = virtualhostId => async (dispatch, _, { gateway }) => {
    dispatch({ type: FTPS_FETCHED_IN_PROGRESS });
    const resource = new VirtualhostsResource(gateway);
    const response = await resource.ftps(virtualhostId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FTPS_FETCHED_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: FTPS_FETCHED_WITH_ERRORS
        });
    }
};

export const ftpChangePassword = (formId, ftpId, password, callback) => async (dispatch, params, { gateway }) => {
    dispatch(flushErrors());
    dispatch({ type: FTPS_CHANGE_PASSWORD_IN_PROGRESS });
    const resource = new FTPSResource(gateway);
    const response = await resource.changePassword(ftpId, password);
    const data = await response.json();
    if (response.ok) {
        dispatch({ type: FTPS_CHANGE_PASSWORD, data });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: FTPS_CHANGE_PASSWORD_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const ftpDeleteAction = (formId, ftpsId, callback) => async (dispatch, params, { gateway }) => {
    dispatch({ type: FTPS_DELETE_IN_PROGRESS });
    const resource = new FTPSResource(gateway);
    const response = await resource.delete(ftpsId);
    if (response.ok) {
        dispatch({
            type: FTPS_DELETE,
            data: { ftpsId }
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: FTPS_DELETE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const ftpAddDatabaseAction = (formId, contaiterId, { login, password, homedir }, callback) => async (dispatch, _, { gateway }) => {
    dispatch(flushErrors());
    dispatch({ type: FTP_ADD_IN_PROGRESS });
    const resource = new FTPSResource(gateway);
    const response = await resource.create({
        login, password, homedir, container_id: contaiterId
    });
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FTP_ADD,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: FTP_ADD_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const virtualhostCreateEngine = (formId, { container, name, engine, db }, currentContainerId, callback) => async (dispatch, _, { gateway }) => {
    dispatch({ type: VIRTUALHOST_CREATE_IN_PROGRESS });
    const resource = new VirtualhostsResource(gateway);
    const response = await resource.create({ container_id: container, name, engine, dbms_type: db });
    const data = await response.json();
    if (response.ok) {
        callback(data);
        dispatch({
            type: VIRTUALHOST_CREATE,
            data: { ...data, currentContainerId }
        });
    } else {
        dispatch({ type: VIRTUALHOST_CREATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const virtualhostCreateCms = (formId, { container, name, cms }, currentContainerId, callback) => async (dispatch, _, { gateway }) => {
    dispatch({ type: VIRTUALHOST_CREATE_IN_PROGRESS });
    const resource = new VirtualhostsResource(gateway);
    const response = await resource.create({ container_id: container, name, image: cms });
    const data = await response.json();
    if (response.ok) {
        callback(data);
        dispatch({
            type: VIRTUALHOST_CREATE,
            data: { ...data, currentContainerId }
        });
    } else {
        dispatch({ type: VIRTUALHOST_CREATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const fetchBarChartCPU = containerId => async (dispatch, _, { gateway }) => {
    dispatch({ type: BAR_CHART_CPU_FETCH_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.barChartCPU(containerId);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: BAR_CHART_CPU_FETCH_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: BAR_CHART_CPU_FETCH_WITH_ERRORS
        });
    }
};

export const fetchBarChartRAM = containerId => async (dispatch, _, { gateway }) => {
    dispatch({ type: BAR_CHART_RAM_FETCH_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.barChartRAM(containerId);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: BAR_CHART_RAM_FETCH_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: BAR_CHART_RAM_FETCH_WITH_ERRORS
        });
    }
};

export const setProblemCPU = state => async (dispatch) => {
    dispatch({ type: SET_PROBLEM_CPU, data: { problemCPU: state } });
};

export const setProblemMemory = state => async (dispatch) => {
    dispatch({ type: SET_PROBLEM_MEMORY, data: { problemMemory: state } });
};

export const setProblemStorage = state => async (dispatch) => {
    dispatch({ type: SET_PROBLEM_STORAGE, data: { problemStorage: state } });
};

export const containerListCapabilities = (formId, containerId, callback) => async (dispatch, _, { gateway }) => {
    const resource = new ContainerResource(gateway);
    const response = await resource.capabilities(containerId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CONTAINER_CAPABILITIES,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch(formErrors(formId, data.errors));
    }
};

export const certificatesCreate = (formId, virtualhostId, certificate, privateKey, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: SSL_UPLOAD_ARCHIVE_IN_PROGRESS });
    dispatch(flushErrors());
    const resource = new VirtualhostsResource(gateway);
    const formData = new FormData();
    if (certificate) {
        formData.append('certificate', certificate);
    }
    if (privateKey) {
        formData.append('private_key', privateKey);
    }
    const response = await resource.certificateUpload(virtualhostId, formData);
    const data = await response.json();
    if (response.ok) {
        dispatch({ type: VIRTUALHOST_UPDATE, data });
        dispatch({ type: SSL_UPLOAD_ARCHIVE_SUCCESSFULLY });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: SSL_UPLOAD_ARCHIVE_WITH_ERROR });
        dispatch(formErrors(formId, data.errors));
    }
};

export const certificatesUpdate = (virtualhostId, certificateId) => async (dispatch, params, { gateway }) => {
    const formId = 'certificatesUpdate';
    const resource = new VirtualhostsResource(gateway);
    const response = await resource.update(virtualhostId, { certificate_id: certificateId });
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VIRTUALHOST_UPDATE,
            data
        });
        if (data.certificate_id != null && data.certificate_id > 0) {
            dispatch(certificateDetail(data.certificate_id));
        }
    } else {
        dispatch(formErrors(formId, data.errors));
    }
};

export const certificateOrder = (formId, virtualhostId, callback) => async (dispatch, _, { gateway }) => {
    dispatch({ type: VIRTUALHOST_UPDATE_IN_PROGRESS });
    dispatch(flushErrors());
    const resource = new VirtualhostsResource(gateway);
    const response = await resource.certificateOrder(virtualhostId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VIRTUALHOST_UPDATE,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VIRTUALHOST_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const forceHTTP = (formId, virtualhostId, forceHttps) => async (dispatch, _, { gateway }) => {
    const resource = new VirtualhostsResource(gateway);
    const response = await resource.update(virtualhostId, { force_https: forceHttps });
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: VIRTUALHOST_UPDATE,
            data
        });
    } else {
        dispatch(formErrors(formId, data.errors));
    }
};

export const restoreFromBackupToInit = () => async (dispatch) => {
    dispatch({ type: HOSTING_RESTORE_FROM_BACKUP_TO_INIT });
    dispatch({
        type: VIRTUALHOSTS_FETCHED_SUCCESSFULLY,
        data: null,
    });
};

export const restoreFromBackupUpdateParams = (data, callback) => async (dispatch) => {
    dispatch({ type: HOSTING_RESTORE_FROM_BACKUP_UPDATE_PARAMS, data });
    if (isFunction(callback)) {
        callback();
    }
};

export const containerBackupsList = (
    containerId, callback
) => async (dispatch, params, { gateway }) => {
    dispatch({ type: CONTAINER_BACKUPS_LIST_IN_PROGRESS });
    const resource = new ContainerResource(gateway);
    const response = await resource.backupsList(containerId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CONTAINER_BACKUPS_LIST_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({
            type: CONTAINER_BACKUPS_LIST_WITH_ERRORS,
        });
    }
};
export const virtualhostBackupsList = (
    virtualhostId, callback
) => async (dispatch, params, { gateway }) => {
    dispatch({ type: CONTAINER_BACKUPS_LIST_IN_PROGRESS });
    const resource = new VirtualhostsResource(gateway);
    const response = await resource.backupsList(virtualhostId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CONTAINER_BACKUPS_LIST_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({
            type: CONTAINER_BACKUPS_LIST_WITH_ERRORS,
        });
    }
};

export const fetchUserVirtualhosts = (
    containerId, callback
) => async (dispatch, _getState, { gateway }) => {
    dispatch({ type: VIRTUALHOSTS_FETCHED_IN_PROGRESS });
    const client = new ContainerResource(gateway);
    const response = await client.virtualhosts(containerId);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: VIRTUALHOSTS_FETCHED_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({
            type: VIRTUALHOSTS_FETCHED_WITH_ERRORS,
        });
    }
};

export const containerBackupRestore = (
    formId, containerId, { rewrite, files, date }, callback
) => async (dispatch, params, { gateway }) => {
    dispatch({ type: CONTAINER_BACKUPS_RESTORE_IN_PROGRESS });
    dispatch(flushErrors());
    const resource = new ContainerResource(gateway);
    const response = await resource.backupRestore(containerId, {
        rewrite,
        files,
        date,
    });
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CONTAINER_BACKUPS_RESTORE_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: CONTAINER_BACKUPS_RESTORE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const dbmsBackupsList = (dbmsId, callback) => async (dispatch, params, { gateway }) => {
    dispatch({ type: DBMS_BACKUPS_LIST_IN_PROGRESS });
    const resource = new DbmsResource(gateway);
    const response = await resource.backupsList(dbmsId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: DBMS_BACKUPS_LIST_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({
            type: DBMS_BACKUPS_LIST_WITH_ERRORS,
        });
    }
};

export const dbmsBackupRestore = (
    formId, sourceDbId, { databaseId, date }, callback
) => async (dispatch, params, { gateway }) => {
    dispatch({ type: CONTAINER_BACKUPS_RESTORE_IN_PROGRESS });
    dispatch(flushErrors());
    const resource = new DbmsResource(gateway);
    const response = await resource.backupRestore(sourceDbId, date, {
        databaseId,
    });
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CONTAINER_BACKUPS_RESTORE_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: CONTAINER_BACKUPS_RESTORE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const getContainerCalculation = (configuration) => async (dispatch, params, { gateway }) => {
    const resource = new ContainerResource(gateway);
    const response = await resource.getContainerCalculation(configuration);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: GET_CONTAINER_CALCULATION_SUCCESSFULLY,
            data,
        });
    }
};
