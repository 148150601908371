import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import {
    fetchPreferredPacket,
    fetchMailStatisticInfo,
    fetchUserMailInfo,
    fetchMailDomainsInfo,
    fetchMailCalculation,
    fetchDomains,
    initMailboxes
} from '../actions/mail/actions';
import { screenHeaderStyles } from '../utils/styles';
import MailLaunchPage from '../components/Mail/MailLaunchPage';
import MailTabs from '../components/Mail/MailTabs';
import MailErrorPage from '../components/Mail/MailErrorPage';
import MailResourceLoader from '../components/Mail/MailResourceLoader';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const MailStack = createStackNavigator();

function MailStackScreen(props) {
    const { navigation } = props;
    const limit = 10;
    const formId = 'FetchDomains';
    const insets = useSafeAreaInsets();
    const {
        domains,
        userInfo,
        domainsInProgress,
        userInfoInProgress,
        domainsInfo,
        preferredPacket,
        domainsInfoInProgress,
    } = useSelector((state) => state.mail);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!userInfoInProgress && userInfo == null) {
            dispatch(fetchUserMailInfo());
        }
        dispatch(fetchMailStatisticInfo());
        dispatch(fetchMailCalculation());
        if (!domainsInfoInProgress && domainsInfo == null) {
            dispatch(fetchMailDomainsInfo());
        }
        dispatch(fetchDomains(formId, limit, 0));
        if (preferredPacket == null) {
            dispatch(fetchPreferredPacket());
        }
        return () => dispatch(initMailboxes());
    }, []);
    const isDisabled = userInfo && !userInfo.paid_quota_enabled && userInfo.free_quota <= 0;
    let currentComponent = null;
    if (!isDisabled && (domains && domains.entities.length !== 0)) {
        currentComponent = MailTabs;
    } else if (isDisabled) {
        currentComponent = MailErrorPage;
    } else {
        currentComponent = MailLaunchPage;
    }
    return <MailStack.Navigator>
        <MailStack.Screen name="MailTabs"
            component={
                domainsInProgress ? MailResourceLoader : currentComponent
            }
            initialParams={{ height: 77, count: 3 }}
            options={{
                headerTitle: () => <ScreenHeaderTitle title='Почта' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.navigate('MainStack'),
                    backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                cardStyle: { flex: 1 },
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }}/>
    </MailStack.Navigator>;
}

MailStackScreen.propTypes = {
    navigation: PropTypes.object,
};

export default MailStackScreen;
