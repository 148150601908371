const BaseResource = require('../resource');

class CVDSResource extends BaseResource {
    async vmsInfo() {
        return this.makeRequest('get', 'service-details/');
    }
}

CVDSResource.resource = null;
CVDSResource.service = 'cloud';

module.exports = CVDSResource;
