import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, Linking } from 'react-native';

import { flushErrors } from '../../../actions/forms/actions';
import { certificateOrder } from '../../../actions/hosting/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'VitrualhostOrderFreeSSL';

function SSLFreeOrder(props) {
    const {
        errors,
        onClose,
        inProgress,
        flushErrors,
        virtualhostId,
        virtualhostName,
        certificateOrder,
    } = props;

    React.useEffect(() => {
        flushErrors()
    }, []);
    let errorText;
    if (errors.domains) {
        if (errors.domains[0].indexOf('сторонние NS сервера') !== -1) {
            errorText = <View>
                <Text>
                    Домен {virtualhostName}, для которого выпускается сертификат делегирован на сторонние NS сервера.
                    Для выпуска сертификата делегируйте домен на ns1.netangels.ru, ns2.netangels.ru, ns3.netangels.ru,
                     ns4.netangels.ru, воспользовавшись нашей инструкцией в <Text style={styles.link}
                     onPress={() => Linking.openURL('https://www.netangels.ru/support/domains/transfer/')}>статье</Text>
                </Text>
            </View>;
        }
        else {
            errorText = errors.domains;
        }
    }
    return <View style={styles.wrapper}>
        <Text style={styles.description}>
            Сейчас будет выпущен бесплатный SSL-сертификат от Let's Encrypt
             и подключен к этому сайту. Выпуск сертификата может занять до 30 минут.<br/><br/>
            Сертификат выпускается на 3 месяца. Мы автоматически продлим
             сертификат, когда срок его действия будет заканчиваться.
        </Text>
        {errors.domains && <Text style={styles.error}>{errorText}</Text>}
        <View style={styles.buttons}>
            <AcceptButton
                onSubmit={() => certificateOrder(formId, virtualhostId, () => onClose())}
                title='Подключить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

SSLFreeOrder.propTypes = {
    onClose: PropTypes.func,
    inProgress: PropTypes.bool,
    errors: PropTypes.shape({}),
    virtualhostName: PropTypes.string,
    certificateOrder: PropTypes.func,
    flushErrors: PropTypes.func,
    virtualhostId: PropTypes.number,
};

const mapStateToProps = (state) => ({
    inProgress: state.hosting.virtualhostUpdateInProgress,
    errors: state.forms.errors[formId] || {},
    virtualhostName: state.hosting.virtualhost.name,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    certificateOrder
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 44,
        paddingBottom: 42,
        paddingLeft: 40,
        paddingRight: 40,
    },
    description: {
        fontSize: 12,
        lineHeight: 18,
        textAlign: 'start',
        marginBottom: 10,
        color: '#455562',
    },
    buttons: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        fontSize: 11,
        color: '#d06565',
        marginBottom: 10,
    },
    input: {
        borderColor: '#dce2e9',
        borderWidth: 1,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
        marginBottom: 10,
    },
    link: {
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
        textDecorationColor: '#d06565',
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SSLFreeOrder);
