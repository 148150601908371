const BaseResource = require('../resource');

class FinancesAutopaymentResource extends BaseResource {
    async details() {
        return this.makeRequest('get', null);
    }
}

FinancesAutopaymentResource.resource = 'autopayment';
FinancesAutopaymentResource.service = 'finances';

module.exports = FinancesAutopaymentResource;
