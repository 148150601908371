import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';

import Errors from '../../General/Form/Errors';
import { flushErrors } from '../../../actions/forms/actions';
import { disableOtp } from '../../../actions/account/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';
import Input from '../../General/Form/Input';

const formId = 'EnableOtpForm';

function DisableOtpModal(props) {
    const {
        onClose,
    } = props;
    const { disableOtpInProgress } = useSelector(
        (state) => state.account,
    );
    const { errors } = useSelector((state) => state.forms);
    const [password, setPassword] = useState('');

    const currentErrors = errors[formId] || {};
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);

    return <View style={styles.wrapper}>
        <Text style={styles.title}>Двухфакторная авторизация</Text>
        <Text style={styles.text}>
            Отключение двухфакторной авторизации необходимо подтвердить паролем от вашего аккаунта
        </Text>
        <Input
            errors={currentErrors.password}
            needHideText={true}
            textInputAttrs={{
                autoFocus: true,
                numberOfLines: 1,
                value: password,
                onChange: (e) => setPassword(e.target.value)
            }}
        />
        {currentErrors.__all__ && <Errors errors={currentErrors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => dispatch(
                    disableOtp(formId, password, () => {
                        onClose();
                    }),
                )}
                type="danger"
                title="Отключить"
                inProgress={disableOtpInProgress}/>
            <CancelButton onClose={onClose} disabled={disableOtpInProgress} />
        </View>
    </View>;
}

DisableOtpModal.propTypes = {
    onClose: PropTypes.func,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 30,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20
    },
    title: {
        marginBottom: 16,
        color: '#455562',
        fontSize: 16,
        lineHeight: 19
    },
    text: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 16,
        marginBottom: 20
    },
    buttonBlock: {
        flex: 1,
        marginTop: 32,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default DisableOtpModal;
