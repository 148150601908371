import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { View, Image } from 'react-native';
import { ModalContext } from '../../../contexts/ModalContext';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

import { actionIcon } from '../../../icons';

function Actions(props) {
    const {
        icon = actionIcon,
        children,
        iconWidth = 17,
        iconHeight = 4,
        additionalButtonStyles,
        modalId = 'unknown',
    } = props;
    const { checkModal, openModal, closeModal } = useContext(ModalContext);

    return <View>
        <Button type='white'
            onPress={() => openModal(modalId)}
            additionalButtonStyles={additionalButtonStyles}
            component={<Image source={icon} style={{ width: iconWidth, height: iconHeight }} />}
        />
        <Modal isModalShowed={checkModal(modalId)}
            component={children}
            onClose={() => closeModal()} />
    </View>
}

Actions.propTypes = {
    additionalButtonStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number
    ]))
};

export default Actions;
