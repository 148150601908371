import React from 'react';
import {
    View, StyleSheet, Text, Image
} from 'react-native';

import Button from '../General/Button/Button';
import Modal from '../General/Modal/Modal';
import { launchSslIcon } from '../../icons';
import OrderCertificateModal from './Modals/OrderCertificateModal';

function SslLaunchPage() {
    const [isCreateModalShowed, setCreateModalShowed] = React.useState(false);

    return <View style={styles.wrapper}>
        <Image source={launchSslIcon} style={styles.image} />
        <View style={styles.content}>
            <Text style={styles.title}>Безопасность ваших сайтов</Text>
            <Text style={styles.description}>
                Выгодные цены на годовые сертификаты SECTIGO,
                а также бесплатные сертификаты Let’s Encrypt с автопродлением
            </Text>
        </View>
        <Button title='Заказать SSL-сертификат'
            onPress={() => setCreateModalShowed(true)}
            additionalWrapperStyles={[{ width: '100%' }]}
            additionalButtonStyles={[{
                paddingTop: 12,
                paddingBottom: 12,
                marginLeft: 22,
                marginRight: 22,
            }]}
            additionalButtonTextStyles={[{
                fontSize: 14,
                lineHeight: 16,
                fontWeight: '400',
            }]}/>
        <Modal isModalShowed={isCreateModalShowed}
            component={<OrderCertificateModal
                onClose={() => setCreateModalShowed(false)}/>}
            onClose={() => setCreateModalShowed(false)} />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 54,
        paddingLeft: 10,
        paddingRight: 10,
        flex: 1,
        width: '100%',
        flexDirection: 'colume',
        alignItems: 'center',
    },
    image: {
        minHeight: 190,
        minWidth: 300,
        marginBottom: 35,
    },
    title: {
        width: 220,
        textAlign: 'center',
        fontSize: 16,
        color: '#455562',
        letterSpacing: 0,
        lineHeight: 19,
        marginBottom: 12,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    description: {
        width: 268,
        fontSize: 12,
        textAlign: 'center',
        letterSpacing: 0,
        color: '#455562',
        lineHeight: 16,
        marginBottom: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

export default SslLaunchPage;
