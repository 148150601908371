import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

import { calculateBandwidthPrice, isActiveVM } from '../utils';
import { blockedButtonTooltips } from '../../../constants/general';
import { vmChangeBandwidth } from '../../../actions/cvds/actions';
import Button from '../../General/Button/Button';

function BandwidthResource({ bandwidth, setCurrentBandwidth, currentBandwidth }) {
    const isActive = currentBandwidth === bandwidth;
    return <View>
        <TouchableOpacity
            style={isActive ? styles.activeBandwidthBlock
                : styles.bandwidthBlock}
            onPress={() => setCurrentBandwidth(bandwidth)}
        >
            <View style={isActive ? styles.activeOuterCircle
                : styles.outerCircle}>
                {isActive && <View style={styles.innerCircle}/>}
            </View>
            <Text style={isActive ? styles.activeBandwidthLimit
                : styles.bandwidthLimit}>{bandwidth} Мбит/c</Text>
            <View style={styles.spring}/>
            <Text style={styles.bandwidthPrice}>
                {calculateBandwidthPrice(bandwidth).toFixed(0)} ₽/мес
            </Text>
        </TouchableOpacity>
    </View>;
}

const formId = 'EditBandwidthForm';

function EditBandwidthScreen() {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const { currentVM, antiDDoSInProgress } = useSelector((state) => state.cvds);
    const [currentBandwidth, setCurrentBandwidth] = React.useState(currentVM.bandwidth);
    const isEnabledActions = isActiveVM(currentVM.state) && currentVM.is_managed;
    const isChanged = currentBandwidth !== currentVM.bandwidth;
    const disabledText = (isActiveVM(currentVM.state) && !currentVM.is_managed)
        ? blockedButtonTooltips.cvds.DisableGuestAgent
        : blockedButtonTooltips.cvds[currentVM.state];
    return <View style={styles.wrapper}>
        <BandwidthResource
            bandwidth={10}
            setCurrentBandwidth={setCurrentBandwidth}
            currentBandwidth={currentBandwidth}
        />
        <BandwidthResource
            bandwidth={20}
            setCurrentBandwidth={setCurrentBandwidth}
            currentBandwidth={currentBandwidth}
        />
        <BandwidthResource
            bandwidth={50}
            setCurrentBandwidth={setCurrentBandwidth}
            currentBandwidth={currentBandwidth}
        />
        <View style={styles.spring}/>
        {isChanged && <View style={styles.buttonsBlock}>
            <Button
                onPress={() => dispatch(
                    vmChangeBandwidth(formId, currentVM.id, currentBandwidth, () => {
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        } else {
                            navigation.navigate('CVDSVM', {
                                screen: 'VMNetwork',
                                vmId: currentVM.id
                            });
                        }
                    })
                )}
                title="Сохранить"
                disabledText={disabledText}
                disabled={!isEnabledActions}
                inProgress={antiDDoSInProgress}
                additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 13 }]}
                additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, fontWeight: '400' }]}
            />
            <Button
                onPress={() => setCurrentBandwidth(currentVM.bandwidth)}
                type="white"
                title="Отменить"
                additionalButtonStyles={[{ marginTop: 10, paddingTop: 11, paddingBottom: 13 }]}
                additionalButtonTextStyles={[{
                    fontSize: 14,
                    lineHeight: 16,
                    fontWeight: '400',
                    color: '#455562'
                }]}
            />
        </View>}
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
    },
    bandwidthBlock: {
        flex: 1,
        flexDirection: 'row',
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 25,
        paddingRight: 28,
        marginTop: 1,
        backgroundColor: '#ffffff',
        alignItems: 'center',
    },
    activeBandwidthBlock: {
        flex: 1,
        flexDirection: 'row',
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 25,
        paddingRight: 28,
        backgroundColor: 'rgba(15, 121, 213, 0.07)',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#0f79d5',
        alignItems: 'center',
    },
    outerCircle: {
        height: 18,
        width: 18,
        borderTopWidth: 1,
        borderTopColor: 'rgba(161, 170, 177, 0.5)',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(161, 170, 177, 0.5)',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(161, 170, 177, 0.5)',
        borderRightWidth: 1,
        borderRightColor: 'rgba(161, 170, 177, 0.5)',
        borderRadius: 9,
        backgroundColor: '#f5f5f5'
    },
    activeOuterCircle: {
        height: 18,
        width: 18,
        borderTopWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderLeftWidth: 1,
        borderLeftColor: '#0f79d5',
        borderRightWidth: 1,
        borderRightColor: '#0f79d5',
        borderRadius: 9,
        backgroundColor: '#ffffff',
        paddingTop: 4,
        paddingLeft: 4
    },
    innerCircle: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0f79d5'
    },
    activeBandwidthLimit: {
        marginLeft: 16,
        marginRight: 19,
        color: '#0f79d5',
        fontSize: 13,
        fontWeight: 500,
    },
    bandwidthLimit: {
        marginLeft: 16,
        marginRight: 19,
        color: '#455562',
        fontSize: 13,
        fontWeight: 500,
    },
    spring: {
        flex: 1,
    },
    bandwidthPrice: {
        color: '#455562',
        fontSize: 10,
        fontWeight: '400',
    },
    buttonsBlock: {
        paddingBottom: 40,
        paddingRight: 23,
        paddingLeft: 26,
    },
});

export default EditBandwidthScreen;
