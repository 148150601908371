import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';

import {
    resetPhone,
    verifyPhone,
    resendPinPhone,
} from '../../../actions/account/actions';
import { pluralize } from '../../../utils/general';
import PinInput from '../../General/Form/PinInput';
import { flushErrors } from '../../../actions/forms/actions';

const CODE_TIMEOUT = 5;
const formId = 'ChangePincodeConfirmForm';

function formatPartTime(time) {
    const normalizeTime = time.toFixed(0);
    if (normalizeTime.length > 1) {
        return normalizeTime;
    }
    return `0${normalizeTime}`;
}

function formatTime(minutes, seconds) {
    const normalizeMinutes = `${minutes} ${pluralize(minutes, ['минуту', 'минуты', 'минут'])}`;
    const normalizeSeconds = `${formatPartTime(seconds)} ${pluralize(seconds, ['секунд', 'секунду', 'секунд'])}`
    return `${normalizeMinutes} ${normalizeSeconds}`;
}

function AccountChangePhoneStep2(props) {
    const { onClose } = props;
    const phoneVerificationData = useSelector(
        (state) => state.account.account.phone_verification_data,
    );
    const { phone, send_time } = phoneVerificationData;
    let timerId = null;
    const dispatch = useDispatch();
    const [restTime, setRestTime] = React.useState(null);
    const [sendTime, setSendTime] = React.useState(null);
    const { errors } = useSelector((state) => state.forms);

    const currentErrors = errors[formId] || {};
    React.useEffect(() => {
        const sendTimeDate = new Date(send_time);
        sendTimeDate.setHours(
            sendTimeDate.getHours(),
            sendTimeDate.getMinutes() + CODE_TIMEOUT, sendTimeDate.getSeconds(),
            0,
        );
        timerId = setInterval(() => {
            setRestTime(sendTimeDate - new Date());
        }, 1000);
        dispatch(flushErrors());
        setSendTime(sendTimeDate);
        return () => {
            if (timerId != null) {
                clearInterval(timerId);
                timerId = null;
            }
        };
    }, [phoneVerificationData]);

    const minutes = Math.floor(restTime / (1000 * 60));
    const seconds = Math.floor((restTime / 1000) - minutes * 60);

    function isTimeOver() {
        const currentTime = new Date();
        return (sendTime - currentTime) < 0;
    }
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Введите код подтверждения</Text>
        <View style={styles.description}>
            <Text style={styles.descriptionText}>Код отправлен в SMS на номер</Text>
            <Text style={styles.highlight}>{phone}</Text>
        </View>
        <PinInput
            onFill={(pin) => dispatch(verifyPhone(
                formId,
                { pin: pin.join('') },
                () => onClose(),
            ))}
            onChange={() => dispatch(flushErrors())}
            errors={currentErrors.pin}
        />
        {currentErrors.__all__ && <Text style={styles.error}>{currentErrors.__all__}</Text>}
        {sendTime != null && <View>
            {isTimeOver() && <TouchableOpacity onPress={() => {
                setRestTime(null);
                setSendTime(null);
                dispatch(resendPinPhone());
            }}>
                <Text style={[styles.blueText, { marginBottom: 15 }]}>Отправить код повторно</Text>
            </TouchableOpacity>}
            {!isTimeOver() && <Text style={styles.repeatSend}>
                Отправить код повторно можно через
                <br/>
                {restTime != null && restTime > 0 && formatTime(minutes, seconds)}
            </Text>}
        </View>}
        <TouchableOpacity onPress={() => dispatch(resetPhone())}>
            <Text style={styles.blueText}>Изменить номер телефона</Text>
        </TouchableOpacity>
    </View>;
}

AccountChangePhoneStep2.propTypes = {
    onClose: PropTypes.func,
};

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 44,
        paddingBottom: 42,
        paddingLeft: 40,
        paddingRight: 40,
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 10,
        color: '#455562',
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
    },
    blueText: {
        textAlign: 'center',
        lineHeight: 18,
        fontSize: 14,
        color: '#0f79d5',
    },
    repeatSend: {
        textAlign: 'center',
        lineHeight: 18,
        fontSize: 14,
        color: '#455562',
        marginBottom: 15,
    },
    description: {
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 24,
        marginBottom: 12,
    },
    descriptionText: {
        color: '#455562',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 24,
    },
    highlight: {
        fontWeight: '500',
        color: '#455562',
        paddingTop: 4,
    },
    input: {
        borderColor: '#dce2e9',
        borderWidth: 1,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
        marginTop: 28,
        marginBottom: 14,
    },
});

export default AccountChangePhoneStep2;