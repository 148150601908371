import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import {
    Image,
    Text,
    View,
    Linking,
    StyleSheet,
    Platform,
    TouchableOpacity
} from 'react-native';
import Input from '../components/General/Form/Input';
import Errors from '../components/General/Form/Errors';
import Button from '../components/General/Button/Button';
import { authorizeUser, financeInfo } from '../actions/user/actions';
import { flushErrors } from '../actions/forms/actions';
import { netangelsLogoIcon } from '../icons';

const formId = 'authorizeUserFormId';

const styles = StyleSheet.create({
    logoWrapper: {
        flex: 0.1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    authScreen: {
        marginTop: '11%',
        padding: 22,
        flex: 1,
        flexDirection: 'column'
    },
    headerText: {
        fontSize: 22,
        marginBottom: 31,
        textAlign: 'center',
        lineHeight: 25,
        color: '#455562'
    },
    form: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 4,
    },
    button: {
        borderRadius: 4,
    },
    linkButton: {
        fontSize: 11,
        lineHeight: 13,
        letterSpacing: 1.38,
        textAlign: 'center',
        opacity: 0.75,
        marginBottom: 24,
        textTransform: 'uppercase',
    },
    registerButton: {
        color: '#455562',
    },
    toDesktopButton: {
        color: '#455562',
    },
    restoreButton: {
        color: '#4E5F6E',
    },
    allErrorWrapper: {
        textAlign: 'center',
        marginBottom: 10
    }
});

function AuthScreen(props) {
    const {
        authorizeUser,
        navigation,
        authData,
        authorizeInProgress,
        financeInfo,
        flushErrors,
        errors,
    } = props;

    return <View style={styles.authScreen}>
        <View style={styles.logoWrapper}>
            <Image source={netangelsLogoIcon} style={{ width: 162, height: 30 }} />
        </View>
        <View style={styles.form}>
            <Text style={styles.headerText}>Вход в личный кабинет</Text>
            {errors.__all__ && <View style={styles.allErrorWrapper}>
                <Errors errors={errors.__all__} />
            </View>}
            <View>
                <Formik
                    initialValues={{
                        login: '',
                        password: ''
                    }}
                    onSubmit={(values) => {
                        authorizeUser(
                            formId,
                            values,
                            (action) => {
                                if (action === 'auth') {
                                    financeInfo(() => {
                                        navigation.navigate('MainStack');
                                    });
                                }
                                if (action === 'otp') {
                                    navigation.navigate('OtpStack');
                                }
                            },
                            () => navigation.navigate('AuthFail'),
                        );
                    }}>
                    {({ setFieldValue, handleBlur, handleSubmit, values }) => (
                        <View>
                            <Input
                                additionalWrapperStyles={[{ marginBottom: 20 }]}
                                needBorder={true}
                                errors={errors.login}
                                label='Логин'
                                textInputAttrs={{
                                    onChangeText: (e) => {
                                        flushErrors();
                                        setFieldValue('login', e);
                                    },
                                    onBlur: handleBlur('login'),
                                    value: values.login,
                                    placeholder: 'uXXXXXX'
                                }}
                            />
                            <Input
                                needBorder={true}
                                additionalWrapperStyles={[{ marginBottom: 20 }]}
                                errors={errors.password}
                                label='Пароль'
                                needHideText={true}
                                textInputAttrs={{
                                    onChangeText: (e) => {
                                        flushErrors();
                                        setFieldValue('password', e);
                                    },
                                    onBlur: handleBlur('password'),
                                    value: values.password
                                }}
                            />
                            <Button
                                style={styles.button}
                                onPress={handleSubmit}
                                title="Войти"
                                inProgress={authorizeInProgress || authData.inProgress}
                            />
                        </View>
                    )}
                </Formik>
            </View>
        </View>
        <View>
            <Text style={[styles.linkButton, styles.registerButton]}
                onPress={() => Linking.openURL('https://netangels.ru/register/')}>
                Зарегистрироваться
            </Text>
        </View>
        <TouchableOpacity onPress={() => navigation.navigate('RestorePassword')}>
            <Text style={[styles.linkButton, styles.registerButton]}>
                Восстановить пароль
            </Text>
        </TouchableOpacity>
    </View>;
}

AuthScreen.propTypes = {
    authorizeInProgress: PropTypes.bool,
    authorizeUser: PropTypes.func,
    financeInfo: PropTypes.func,
    errors: PropTypes.shape({}),
    authData: PropTypes.shape({
        inProgress: PropTypes.bool,
    }),
    financeInfoData: PropTypes.shape({}),
    flushErrors: PropTypes.func,
};

const mapStateToProps = (state) => ({
    errors: state.forms.errors[formId] || {},
    authData: state.user.auth,
    authorizeInProgress: state.user.authorizeInProgress,
    financeInfoData: state.user.financeInfo,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    authorizeUser,
    financeInfo,
    flushErrors,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuthScreen);
