import React from 'react';
import PropTypes from 'prop-types';
import {
    View,
    Text,
    Image,
    StyleSheet,
    TouchableOpacity,
} from 'react-native';

import ImagesList from './ImagesList';
import { rightIcon } from '../../icons';
import DistributionIcon from './DistributionIcon/DistributionIcon';

function DistributionsGroups(props) {
    const {
        groups,
        currentDistribution,
        onChange
    } = props;
    const currentGroup = groups.find((group) => group.type === currentDistribution.groupType);
    const [newGroup, setCurrentGroup] = React.useState(currentGroup || {});
    return groups.map((group, i) => {
        let additionalDistributionsGroupStyles = {
            backgroundColor: '#ffffff'
        };
        if (group.versions.length === 1 && newGroup.type === group.type) {
            additionalDistributionsGroupStyles = styles.currentImageBlock;
        } else if (newGroup.type === group.type) {
            additionalDistributionsGroupStyles.backgroundColor = '#f6f6f6';
        }
        const additionalTextBlockStyle = group.versions.length === 1
            && newGroup.type === group.type ? { color: '#0f79d5' } : {};
        return <View style={styles.distributionsGroupBlock}
            key={`distributions-group-${i}`}>
            <TouchableOpacity
                onPress={() => {
                    if (group.versions.length === 1) {
                        const image = group.versions[0].images[0];
                        onChange(image, group);
                        return;
                    }
                    if (group.versions.length !== 1 && newGroup.type !== group.type) {
                        setCurrentGroup(group);
                    } else if (newGroup.type === group.type) {
                        setCurrentGroup({});
                    }
                }} style={[styles.distributionsGroup, additionalDistributionsGroupStyles]}>
                <DistributionIcon distribution={group.type} iconWidth='24px' iconHeight='24px'/>
                <Text style={[styles.textBlock, additionalTextBlockStyle]}>{group.name}</Text>
                {group.versions.length !== 1 && <Image source={rightIcon}
                    style={newGroup.type === group.type ? styles.bottomIcon : styles.rightIcon}/>}
            </TouchableOpacity>
            {(newGroup.type === group.type && group.versions.length !== 1)
                && <ImagesList group={group} currentDistribution={currentDistribution}
                    onChange={onChange}/>}
        </View>;
    });
}

DistributionsGroups.propTypes = {
    groups: PropTypes.arrayOf(PropTypes.shape({})),
    currentDistribution: PropTypes.shape({}),
    onChange: PropTypes.func
};

const styles = StyleSheet.create({
    distributionsGroupBlock: {
        marginBottom: 1
    },
    distributionsGroup: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 15,
        paddingRight: 20,
        paddingBottom: 17,
        paddingLeft: 24
    },
    textBlock: {
        flex: 1,
        color: '#455562',
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 16,
        marginLeft: 12,
    },
    rightIcon: {
        height: 12.55,
        width: 7
    },
    bottomIcon: {
        transform: 'rotate(90deg)',
        height: 12.55,
        width: 7
    }
});

export default DistributionsGroups;
