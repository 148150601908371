import { combineReducers } from 'redux';
import { upperFirst } from '../utils/general';

import ui from './ui';
import ips from './ips';
import user from './user';
import cvds from './cvds';
import mail from './mail';
import forms from './forms';
import context from './context';
import account from './account';
import finance from './finance';
import transfer from './transfer';
import monitoring from './monitoring';
import certificates from './certificates';
import hosting from './hosting/index';
import cvdsCreate from './cvds/create';
import partnership from './partnership';
import invoices from './finance/invoices';
import notifications from './notifications';
import cvdsFinanceInfo from './cvds/financeInfo';
import cvdsBackups from './cvds/backups';
import cvdsRestoreFromBackup from './cvds/restoreFromBackup';
import cvdsDistributions from './cvds/distributions';
import expectedCharges from './finance/expectedCharges';
import partnershipStatistics from './partnership/statistics';
import hostingCreateContainer from './hosting/createContainer';
import hostingRestoreFromBackup from './hosting/restoreFromBackup';

const attachedReducers = {
    ui,
    ips,
    user,
    cvds,
    mail,
    forms,
    context,
    hosting,
    account,
    finance,
    transfer,
    invoices,
    monitoring,
    certificates,
    cvdsCreate,
    partnership,
    partnershipStatistics,
    notifications,
    cvdsBackups,
    cvdsRestoreFromBackup,
    cvdsDistributions,
    expectedCharges,
    cvdsFinanceInfo,
    hostingCreateContainer,
    hostingRestoreFromBackup,
};

const normalizeModuleName = (moduleName) => moduleName.split('/').map((item, i) => {
    if (i === 0) {
        return item;
    }
    return upperFirst(item);
}).join('');

export default (initialState) => {
    const reducersMap = {};
    Object.keys(attachedReducers).forEach((reducerName) => {
        const reducerKey = normalizeModuleName(reducerName);
        const reducerModule = attachedReducers[reducerKey] // eslint-disable-line global-require, import/no-dynamic-require
        reducersMap[reducerKey] = reducerModule(initialState);
    });
    return combineReducers(reducersMap);
};
