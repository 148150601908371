import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { bindActionCreators } from 'redux';

import Errors from '../../General/Form/Errors';
import { vmReload } from '../../../actions/cvds/actions';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'ReloadModalForm';

function ReloadModal(props) {
    const {
        vm,
        errors,
        onClose,
        vmReload,
        inProgress,
        flushErrors
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);
    const [force, setForce] = React.useState(false);
    return <View style={styles.wrapper}>
        <Text style={[styles.title]}>Перезагрузка ВМ {vm.name} ({vm.uid})</Text>
        <TouchableOpacity style={[styles.methodBlock]}
            onPress={() => setForce(false)}>
            <View style={!force ? styles.activeOuterCircle : styles.outerCircle}>
                {!force && <View style={styles.innerCircle}/>}
            </View>
            <View>
                <Text style={[styles.methodTitle]}>Нормальная перезагрузка</Text>
                <Text style={[styles.methodDescription]}>Завершение процессов и перезагрузка</Text>
            </View>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.methodBlock]}
            onPress={() => setForce(true)}>
            <View style={force ? styles.activeOuterCircle : styles.outerCircle}>
                {force && <View style={styles.innerCircle}/>}
            </View>
            <View>
                <Text style={[styles.methodTitle]}>Принудительная перезагрузка</Text>
                <Text style={[styles.methodDescription]}>
                    Эквивалентно отключению питания, несохраненные данные могут быть потеряны
                </Text>
            </View>
        </TouchableOpacity>
        {errors.__all__ && <Errors errors={errors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => vmReload(formId, vm.id, { force }, onClose)}
                title='Перезагрузить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

ReloadModal.propTypes = {
    vm: PropTypes.shape({}),
    onClose: PropTypes.func,
    vmReload: PropTypes.func,
    inProgress: PropTypes.bool,
    errors: PropTypes.shape({})
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    inProgress: state.cvds.vmUpdateInProgress,
    errors: state.forms.errors[formId] || {}
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    vmReload, flushErrors
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    methodBlock: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 18
    },
    methodTitle: {
        fontWeight: '600',
        color: '#455562',
        fontSize: 14,
        marginBottom: 5
    },
    methodDescription: {
        fontWeight: '400',
        color: '#455562',
        fontSize: 12,
        fontHeight: 16,
        maxWidth: 250
    },
    outerCircle: {
        height: 18,
        width: 18,
        marginRight: 9,
        borderTopWidth: 1,
        borderTopColor: 'rgba(161, 170, 177, 0.5)',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(161, 170, 177, 0.5)',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(161, 170, 177, 0.5)',
        borderRightWidth: 1,
        borderRightColor: 'rgba(161, 170, 177, 0.5)',
        borderRadius: 9,
        backgroundColor: '#f5f5f5'
    },
    activeOuterCircle: {
        height: 18,
        width: 18,
        marginRight: 9,
        borderTopWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderLeftWidth: 1,
        borderLeftColor: '#0f79d5',
        borderRightWidth: 1,
        borderRightColor: '#0f79d5',
        borderRadius: 9,
        backgroundColor: '#ffffff',
        paddingTop: 4,
        paddingLeft: 4
    },
    innerCircle: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0f79d5'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReloadModal);
