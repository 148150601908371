import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import { flushErrors } from '../../../actions/forms/actions';
import InputPassword from '../../General/Form/InputPassword';
import { dbmsChangePassword } from '../../../actions/hosting/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'DbmsChangePasswordForm';

function DbmsChangePassword(props) {
    const {
        errors,
        onClose,
        inProgress,
        flushErrors,
        virtualhostId,
        dbmsChangePassword
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);

    const [password, setChangedNewPass] = React.useState('');
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Смена пароля БД</Text>
        <InputPassword
            errors={errors.password}
            value={password}
            autoFocus={false}
            onChange={(value) => setChangedNewPass(value)}
        />
        {errors.__all__ && <Text style={styles.error}>{errors.__all__}</Text>}
        <View style={styles.buttons}>
            <AcceptButton
                onSubmit={() => dbmsChangePassword(formId, virtualhostId, password,
                    () => onClose())}
                title='Сохранить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

DbmsChangePassword.propTypes = {
    onClose: PropTypes.func,
    flushErrors: PropTypes.func,
    errors: PropTypes.shape({}),
    virtualhostId: PropTypes.number,
    dbmsChangePassword: PropTypes.func,
    inProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    inProgress: state.hosting.dbmsChangePasswordInProgress,
    virtualhostId: state.hosting.virtualhost.id,
    errors: state.forms.errors[formId] || {}
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    dbmsChangePassword
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 20,
        color: '#455562',
    },
    buttons: {
        flex: 1,
        marginTop: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DbmsChangePassword);