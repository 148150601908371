import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import Button from '../../General/Button/Button';
import { packetKey } from '../../../utils/general';
import Separator from '../../General/Separator/Separator';
import FinanceAwaitingPacket from './FinanceAwaitingPacket';
import { awaitingPacketCancel, setPaymentAmount } from '../../../actions/finances/actions';
import { blockedButtonTooltips } from '../../../constants/general';
import { formatPrice } from '../../../utils/general';

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 24,
        borderRadius: 4,
        backgroundColor: '#ffffff',
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 20 },
        shadowOpacity: 0.05,
        shadowRadius: 40
    },
    titleBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 8.5,
        paddingLeft: 20,
        paddingBottom: 8
    },
    title: {
        fontWeight: '500',
        marginRight: 7,
        color: '#455562',
        fontSize: 9,
        lineHeight: 11,
        letterSpacing: 1.13,
        opacity: 0.75,
        textTransform: 'uppercase'
    },
    circle: {
        fontWeight: '600',
        height: 15,
        width: 15,
        borderRadius: '50%',
        color: '#333333',
        opacity: 0.75,
        backgroundColor: '#d8d8d8',
        fontSize: 8,
        lineHeight: 15,
        textAlign: 'center'
    },
    footerBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 20,
        paddingBottom: 22,
        paddingLeft: 20,
        paddingRight: 18
    }
})

function getTotalCost(awaitingPackets, checkedAwaitingPackets) {
    let totalCost = 0;
    checkedAwaitingPackets.forEach((item) => {
        const awaitingPacket = awaitingPackets.find(p => item === packetKey(p));
        if (awaitingPacket) {
            totalCost += awaitingPacket.cost;
        }
    });
    return totalCost.toFixed(2);
}

function toggleCheckedAwaitingPacketHandler(packet, checkedAwaitingPackets, setCheckedAwaitingPackets) {
    const checkedList = [...checkedAwaitingPackets];
    const indexInCheckedList = checkedList.findIndex(ch => ch === packetKey(packet));
    if (indexInCheckedList !== -1) {
        checkedList.splice(indexInCheckedList, 1);
    } else {
        checkedList.push(packetKey(packet));
    }
    setCheckedAwaitingPackets(checkedList);
}

function FinanceAwaitingPackets(props) {
    const {
        awaitingPackets = [],
        setPaymentAmount,
        awaitingPacketCancel
    } = props;

    const [checkedAwaitingPackets, setCheckedAwaitingPackets] = React.useState(
        awaitingPackets.map(p => packetKey(p))
    );

    const navigation = useNavigation();

    React.useEffect(() => {
        setCheckedAwaitingPackets(awaitingPackets.map(p => packetKey(p)));
    }, [awaitingPackets]);
    const totalCost = getTotalCost(awaitingPackets, checkedAwaitingPackets);
    const buttonTitle = checkedAwaitingPackets.length === 0 ? 'Оплатить'
        : `Оплатить ${formatPrice(totalCost)} ₽`;

    return <View style={styles.wrapper}>
        <View style={styles.titleBlock}>
            <Text style={styles.title}>
                Неоплаченные заказы
            </Text>
            <Text style={styles.circle}>{awaitingPackets.length}</Text>
        </View>
        <View>
            {awaitingPackets.map((packet, p) => <View key={`packet-${p}`}>
                <Separator />
                <FinanceAwaitingPacket packet={packet}
                    awaitingPacketCancel={awaitingPacketCancel}
                    checkedAwaitingPackets={checkedAwaitingPackets}
                    onToggle={packet => toggleCheckedAwaitingPacketHandler(packet,
                        checkedAwaitingPackets, setCheckedAwaitingPackets)}
                />
            </View>)}
        </View>
        <Separator />
        <View style={styles.footerBlock}>
            <Button title={buttonTitle}
                disabled={checkedAwaitingPackets.length === 0}
                disabledText={blockedButtonTooltips.finance.emptyAwaitingPacketList}
                onPress={() => setPaymentAmount(totalCost, () => navigation.navigate('Payment'))}
                additionalWrapperStyles={[{ width: '100%' }]}
                additionalButtonStyles={[{ paddingTop: 9, paddingBottom: 10 }]}
                additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15, minHeight: 15, }]}/>
        </View>
    </View>;
}

FinanceAwaitingPackets.propTypes = {
    awaitingPackets: PropTypes.arrayOf(PropTypes.shape({
        cost: PropTypes.number,
        service: PropTypes.string,
        resource_id: PropTypes.string,
    })),
    setPaymentAmount: PropTypes.func,
    awaitingPacketCancel: PropTypes.func,
    awaitingPacketsInProgress: PropTypes.bool
};

const mapStateToProps = state => ({
    awaitingPackets: state.finance.awaitingPackets,
    awaitingPacketsInProgress: state.finance.awaitingPacketsInProgress
});

const mapDispatchToProps = dispatch => bindActionCreators({
    awaitingPacketCancel, setPaymentAmount
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinanceAwaitingPackets);
