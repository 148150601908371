export const tabBarOptions = {
    upperCaseLabel: false,
    activeTintColor: 'rgba(15,121,213,1)',
    inactiveTintColor: 'rgba(69,85,98,0.7)',
    tabStyle: {
        padding: 0,
    },
    labelStyle: {
        fontSize: 14,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 16,
        textTransform: 'none',
    },
    indicatorStyle: {
        backgroundColor: '#0F79D5',
        height: 3,
    },
    style: {
        width: 'auto',
        marginBottom: 10,
    }
};
