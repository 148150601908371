import React from 'react';
import { StyleSheet, View, Image } from 'react-native';

import { netangelsLogoIcon } from '../icons';

const styles = StyleSheet.create({
    loadingScreen: {
        flex: 1,
        flexDirection: 'column'
    },
    logoWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
});

function LoadingScreen() {
    return <View style={styles.loadingScreen}>
        <View style={styles.logoWrapper}>
            <Image source={netangelsLogoIcon} style={{ width: 162, height: 30 }} />
        </View>
    </View>;
}

export default LoadingScreen;
