const BaseResource = require('./resource');

class TransferResource extends BaseResource {
    async startTask(recipient, resources) {
        return this.makeRequest('post', 'start/', { recipient, resources });
    }

    async checkResources(resources) {
        return this.makeRequest('post', 'resources/check/', { resources });
    }

    async cancelTaskByResource(resourceId, resourceType) {
        return this.makeRequest('post', 'cancel/resource/', {
            resource_id: resourceId,
            resource_type: resourceType,
        });
    }

    async checkKey(key) {
        return this.makeRequest('post', 'check-key/', { key });
    }

    async finishTask(id) {
        return this.makeRequest('post', `finish/${id}/`);
    }

    async fetchVmResources(id) {
        return this.makeRequest('get', `vms/${id}/`);
    }

    async fetchContainerResources(id) {
        return this.makeRequest('get', `containers/${id}/`);
    }

    async fetchMailDomainResources(id) {
        return this.makeRequest('get', `mail-domain/${id}/`);
    }
}

TransferResource.resource = null;
TransferResource.service = 'transfer';

module.exports = TransferResource;
