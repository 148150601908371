import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { View, Text, Image, StyleSheet } from 'react-native';

import Button from '../General/Button/Button';
import { cvdsWelcomePageIcon } from '../../icons';

function WelcomePage(props) {
    const navigation = useNavigation();
    return <View style={styles.wrapper}>
        <Image source={cvdsWelcomePageIcon} style={styles.image} />
        <Text style={styles.title}>Создайте вашу первую виртуальную машину</Text>
        <Text style={styles.text}>Облачные VDS на быстрых SSD-дисках
            от 225 ₽/мес, легко масштабируются под любые задачи</Text>
        <Button title='Создать виртуальную машину'
            onPress={() => navigation.navigate('CVDSCreate')}
            additionalWrapperStyles={[styles.buttonWrapper]}
            additionalButtonTextStyles={[styles.buttonText]}
            additionalButtonStyles={[styles.button]} />
    </View>
};

const styles = StyleSheet.create({
    wrapper: {
        paddingLeft:13,
        paddingRight: 7,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        width: 220,
        marginTop: 46,
        marginBottom: 12,
        color: '#455562',
        color: '#4E5F6E',
        textAlign: 'center',
        fontSize: 16,
        lineHeight: 19
    },
    text: {
        width: 235,
        textAlign: 'center',
        color: '#627687',
        fontSize: 12,
        lineHeight: 16
    },
    image: {
        width: 300,
        height: 182
    },
    buttonWrapper: {
        marginTop: 24,
        width: 256
    },
    button: {
        paddingTop: 11,
        paddingBottom: 13
    },
    buttonText: {
        fontSize: 14,
        lineHeight: 16,
        minHeight: 16,
        fontWeight: '500'
    }
});

export default WelcomePage;
