import {
    CREATE_INVOICE_SUCCESSFULLY,
    INVOICES_LIST_IN_PROGRESS,
    INVOICES_LIST_SUCCESSFULLY,
    INVOICES_LIST_WITH_ERRORS,
    SEND_INVOICE_TO_EMAIL_SUCCESSFULLY,
    SEND_INVOICE_TO_EMAIL_IN_PROGRESS,
    SEND_INVOICE_TO_EMAIL_WITH_ERRORS,
    CREATE_INVOICE_IN_PROGRESS,
    CREATE_INVOICE_WITH_ERRORS,
} from '../../actions/finances/actionTypes';

export default () => {
    const defaultState = {
        entities: null,
        inProgress: false,
        sendInvoiceInProgress: false,
        createInvoiceInProgress: false,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case CREATE_INVOICE_SUCCESSFULLY: {
            const invoice = action.data;
            if (state.entities && !state.entities.find(inv => inv.id === invoice.id)) {
                return {
                    ...state,
                    entities: [action.data, ...state.entities],
                    createInvoiceInProgress: false,
                };
            }
            return state;
        }
        case CREATE_INVOICE_IN_PROGRESS: {
            return {
                ...state,
                createInvoiceInProgress: true
            };
        }
        case CREATE_INVOICE_WITH_ERRORS: {
            return {
                ...state,
                createInvoiceInProgress: false
            };
        }
        case INVOICES_LIST_IN_PROGRESS: {
            return {
                ...state,
                inProgress: true
            };
        }
        case INVOICES_LIST_SUCCESSFULLY: {
            return {
                ...state,
                inProgress: false,
                entities: action.data
            };
        }
        case INVOICES_LIST_WITH_ERRORS: {
            return {
                ...state,
                inProgress: false
            };
        }
        case SEND_INVOICE_TO_EMAIL_IN_PROGRESS: {
            return {
                ...state,
                sendInvoiceInProgress: true
            };
        }
        case SEND_INVOICE_TO_EMAIL_SUCCESSFULLY: {
            return {
                ...state,
                sendInvoiceInProgress: false,
            };
        }
        case SEND_INVOICE_TO_EMAIL_WITH_ERRORS: {
            return {
                ...state,
                sendInvoiceInProgress: false
            };
        }
        default:
            return state;
        }
    };
};
