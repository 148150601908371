const BaseResource = require('../resource');

class FinancesDocumentsResource extends BaseResource {
    async downloadActSV(dateFrom, dateTo) {
        return this.makeRequest('get', `act-sv/${dateFrom}/${dateTo}/`);
    }

    async downloadAct(date) {
        return this.makeRequest('get', `act/${date}/`);
    }

    async downloadActAgent(date) {
        return this.makeRequest('get', `act-agent/${date}/`);
    }
}

FinancesDocumentsResource.resource = 'documents';
FinancesDocumentsResource.service = 'finances';

module.exports = FinancesDocumentsResource;
