import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-native';

import { isEmpty } from '../../../utils/general';
import { distributionsIconsMap } from '../../../icons';

function DistributionIcon(props) {
    const {
        iconWidth = 30,
        iconHeight = 30,
        distribution,
    } = props;
    if (distribution != null && !isEmpty(distribution) && distributionsIconsMap[distribution]) {
        return <Image source={distributionsIconsMap[distribution]}
            style={{ width: iconWidth, height: iconHeight }} />;
    }
    return <Image source={distributionsIconsMap.default_os}
        style={{ width: iconWidth, height: iconHeight }} />;
}

DistributionIcon.propTypes = {
    iconWidth: PropTypes.string,
    iconHeight: PropTypes.string,
    distribution: PropTypes.string
};

export default DistributionIcon; 
