import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, Image } from 'react-native';

import Errors from '../Form/Errors';
import { cursorDownIcon } from '../../../icons';
import Separator from '../Separator/Separator';
import CreatePacketRow from './CreatePacketRow';
import { getValueFromPercent } from '../../CVDS/utils';
import { flushErrors } from '../../../actions/forms/actions';
import CreatePacketDescription from './CreatePacketDescription';
import AcceptButton from '../Modal/AcceptButton';
import CancelButton from '../Modal/CancelButton';

const styles = StyleSheet.create({
    title: {
        color: '#455562',
        fontSize: 14,
        marginBottom: 12,
        fontWeight: '600',
        lineHeight: 16,
        textAlign: 'center'
    },
    packetInfo: {
        paddingLeft: 17,
        paddingRight: 23,
        backgroundColor: '#f4f4f4'
    },
    packetInfoTotal: {
        paddingTop: 14,
        paddingBottom: 12
    },
    warningBlock: {
        marginTop: 14,
        marginLeft: 17,
        marginRight: 23,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    warningText: {
        color: '#E17334',
        fontSize: 13,
        fontWeight: '600',
        lineHeight: 20,
    },
    iconBlock: {
        flex: 1,
        alignItems: 'center',
        marginTop: 16,
        marginBottom: 16,
    },
    icon: {
        height: 19.13,
        width: 17
    },
    packetInfoHint: {
        maxWidth: 270,
        color: '#455562',
        fontSize: 13,
        marginTop: 16,
        marginBottom: 38,
        marginLeft: 'auto',
        marginRight: 'auto',
        fontWeight: '600',
        lineHeight: 15,
        textAlign: 'center'
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorBlock: {
        position: 'absolute',
        textAlign: 'center',
        bottom: 50,
        width: '100%'
    }
});

function formatPrice(price) {
    return price.toFixed(2);
}

function UpdatePacketConfirm(props) {
    const {
        errors,
        flushErrors,
        timeTo,
        packetDescriptionParameters,
        newPacketDescriptionParameters,
        currentPacketCost,
        monthPrice,
        restAmount,
        onAccept,
        onClose,
        inProgress,
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);

    const fullCost = getValueFromPercent(currentPacketCost, 10);
    return <View>
        <Text style={styles.title}>
            Текущий пакет до {dayjs(timeTo).format('DD.MM.YYYY')} г.
        </Text>
        <View style={styles.packetInfo}>
            <CreatePacketDescription parameters={packetDescriptionParameters} />
            <Separator />
            <View style={styles.packetInfoTotal}>
                <CreatePacketRow title='Остаток средств' type='total'
                    value={restAmount} format={v => `${v} ₽`}/>
            </View>
        </View>
        <View style={styles.warningBlock}>
            <Text style={styles.warningText}>Доп. ресурсы</Text>
            <Text style={styles.warningText}>+{formatPrice(monthPrice)} ₽/мес</Text>
        </View>
        <View style={styles.iconBlock}>
            <Image source={cursorDownIcon} style={styles.icon}/>
        </View>
        <Text style={styles.title}>
            Новый годовой пакет до {dayjs().add(1, 'years')
                .format('DD.MM.YYYY')} г.
        </Text>
        <View style={styles.packetInfo}>
            <CreatePacketDescription parameters={newPacketDescriptionParameters} />
            <Separator />
            <View style={styles.packetInfoTotal}>
                <CreatePacketRow title='Стоимость пакета'
                    type='total'
                    value={formatPrice(currentPacketCost)}
                    format={v => `${v} ₽`}/>
            </View>
        </View>
        <Text style={styles.packetInfoHint}>
            При обновлении пакета вы сэкономите {formatPrice(fullCost - currentPacketCost)} ₽ в год
        </Text>
        <View style={styles.errorBlock}>
            { errors.__all__ && <Errors errors={errors.__all__}/> }
        </View>
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => onAccept()}
                title='Обновить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

UpdatePacketConfirm.propTypes = {
    newPacketDescriptionParameters: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        isChange: PropTypes.bool,
        format: PropTypes.func,
        title: PropTypes.string,
        condition: PropTypes.bool
    })),
    packetDescriptionParameters: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        isChange: PropTypes.bool,
        format: PropTypes.func,
        title: PropTypes.string,
        condition: PropTypes.bool
    })),
    monthPrice: PropTypes.number,
    currentPacketCost: PropTypes.number,
    restAmount: PropTypes.number,
    timeTo: PropTypes.string,
    onClose: PropTypes.func,
    onAccept: PropTypes.func,
    errors: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    inProgress: PropTypes.bool,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
    flushErrors
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePacketConfirm);
