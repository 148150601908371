import {
    FETCH_MAIL_DOMAINS_IN_PROGRESS,
    FETCH_MAIL_DOMAINS_SUCCESSFULLY,
    FETCH_MAIL_DOMAINS_WITH_ERRORS,
    FETCH_MORE_MAIL_DOMAINS_SUCCESSFULLY,
    FETCH_MORE_MAIL_DOMAINS_WITH_ERRORS,
    FETCH_MORE_MAIL_DOMAINS_IN_PROGRESS,
    FETCH_DOMAIN_IN_PROGRESS,
    FETCH_DOMAIN_SUCCESSFULLY,
    FETCH_DOMAIN_WITH_ERRORS,
    UPDATE_DOMAIN_SUCCESSFULLY,
    DELETE_DOMAIN_SUCCESSFULLY,
    FETCH_USER_MAIL_INFO_IN_PROGRESS,
    FETCH_USER_MAIL_INFO_SUCCESSFULLY,
    FETCH_USER_MAIL_INFO_WITH_ERRORS,
    FETCH_MAILBOXES_IN_PROGRESS,
    FETCH_MAILBOXES_SUCCESSFULLY,
    FETCH_MAILBOXES_WITH_ERRORS,
    SEARCH_MAIL_DOMAINS_IN_PROGRESS,
    SEARCH_MAIL_DOMAINS_SUCCESSFULLY,
    SEARCH_MAIL_DOMAINS_WITH_ERRORS,
    SEARCH_MAIL_DOMAINS_INIT,
    CHANGE_MAIL_DOMAINS_SEARCH_QUERY_SUCCESSFULLY,
    CREATE_DOMAIN_SUCCESSFULLY,
    CREATE_MAILBOX_SUCCESSFULLY,
    CHANGE_MAILBOX_SUCCESSFULLY,
    DELETE_MAILBOX_SUCCESSFULLY,
    DELETE_MAILBOXES_SUCCESSFULLY,
    SEARCH_MAILBOXES_INIT,
    SEARCH_MAILBOXES_IN_PROGRESS,
    SEARCH_MAILBOXES_SUCCESSFULLY,
    SEARCH_MAILBOXES_WITH_ERRORS,
    CHANGE_MAILBOXES_SEARCH_QUERY_SUCCESSFULLY,
    FETCH_USER_MAIL_STATISTICS_IN_PROGRESS,
    FETCH_USER_MAIL_STATISTICS_SUCCESSFULLY,
    FETCH_USER_MAIL_STATISTICS_WITH_ERRORS,
    FETCH_DOMAINS_INFO_IN_PROGRESS,
    FETCH_DOMAINS_INFO_SUCCESSFULLY,
    FETCH_MAIL_PACKET_LIST_IN_PROGRESS,
    FETCH_MAIL_PACKET_LIST_SUCCESSFULLY,
    FETCH_MAIL_PACKET_LIST_WITH_ERRORS,
    FETCH_MAIL_PREFERRED_PACKET_SUCCESSFULLY,
    FETCH_MAIL_CALCULATION_SUCCESSFULLY,
    CREATE_MAIL_PACKET_SUCCESSFULLY,
    CANCEL_MAIL_PACKET_SUCCESSFULLY,
    FETCH_MAILBOXES_INIT,
    FETCH_MAILBOX_IN_PROGRESS,
    FETCH_MAILBOX_SUCCESSFULLY,
    FETCH_MAILBOX_WITH_ERRORS,
    FETCH_MORE_MAILBOXES_IN_PROGRESS,
    FETCH_MORE_MAILBOXES_SUCCESSFULLY,
    FETCH_MORE_MAILBOXES_WITH_ERRORS,
    UPDATE_MAIL_QUOTA_IN_PROGRESS,
    UPDATE_MAIL_QUOTA_SUCCESSFULLY,
    UPDATE_MAIL_QUOTA_WITH_ERRORS,
    CHANGE_MAILBOX_IN_PROGRESS,
    CHANGE_MAILBOX_WITH_ERRORS,
    UPDATE_DOMAIN_IN_PROGRESS,
    UPDATE_DOMAIN_WITH_ERRORS,
    CREATE_DOMAIN_IN_PROGRESS,
    CREATE_DOMAIN_WITH_ERRORS,
    CREATE_MAILBOX_IN_PROGRESS,
    CREATE_MAILBOX_WITH_ERRORS,
    CREATE_MAIL_PACKET_IN_PROGRESS,
    CREATE_MAIL_PACKET_WITH_ERRORS,
} from '../../actions/mail/actionTypes';

export default () => {
    const defaultState = {
        domains: null,
        domain: null,
        mailboxes: null,
        domainsInProgress: false,
        domainsError: false,
        userInfo: null,
        userInfoError: false,
        userInfoInProgress: false,
        userStatisticsInfo: null,
        userStatisticsInfoError: false,
        userStatisticsInfoInProgress: false,
        domainInfoInProgress: false,
        mailboxesInProgress: false,
        searchDomainsQuery: '',
        searchDomainsResult: null,
        searchDomainInProgress: false,
        searchMailboxesInProgress: false,
        searchMailboxesQuery: '',
        searchMailboxesResult: null,
        domainsInfo: null,
        domainsInfoInProgress: false,
        packetsList: null,
        packetsListInProgress: null,
        packetsListWithErrors: null,
        preferredPacket: null,
        calculation: null,
        mailbox: null,
        mailboxInProgress: false,
        moreDomainsInProgress: false,
        moreMailboxesInProgress: false,
        updateMailQuotaInProgress: false,
        changeMailboxInProgress: false,
        updateDomainInProgress: false,
        createDomainInProgress: false,
        createMailboxInProgress: false,
        orderPacketInProgress: false,
        changeMailboxQuotaInProgress: false,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case FETCH_MAIL_DOMAINS_SUCCESSFULLY: {
            return {
                ...state,
                domains: action.data,
                domainsInProgress: false,
                domainsError: false,
            };
        }
        case FETCH_MAIL_DOMAINS_IN_PROGRESS: {
            return {
                ...state,
                domains: null,
                domainsInProgress: true,
                domainsError: false,
            };
        }
        case FETCH_MAIL_DOMAINS_WITH_ERRORS: {
            return {
                ...state,
                domainsInProgress: false,
                domainsError: true,
            };
        }
        case FETCH_MORE_MAIL_DOMAINS_SUCCESSFULLY: {
            return {
                ...state,
                domains: {
                    count: action.data.count,
                    entities: [...state.domains.entities, ...action.data.entities],
                },
                moreDomainsInProgress: false,
            };
        }
        case FETCH_MORE_MAIL_DOMAINS_IN_PROGRESS: {
            return {
                ...state,
                moreDomainsInProgress: true,
            };
        }
        case FETCH_MORE_MAIL_DOMAINS_WITH_ERRORS: {
            return {
                ...state,
                moreDomainsInProgress: false,
            };
        }
        case SEARCH_MAIL_DOMAINS_IN_PROGRESS: {
            return {
                ...state,
                searchDomainsInProgress: true,
            };
        }
        case SEARCH_MAIL_DOMAINS_WITH_ERRORS: {
            return {
                ...state,
                searchDomainsInProgress: false,
            };
        }
        case SEARCH_MAIL_DOMAINS_SUCCESSFULLY: {
            return {
                ...state,
                searchDomainsInProgress: false,
                searchDomainsResult: action.data,
            };
        }
        case CHANGE_MAIL_DOMAINS_SEARCH_QUERY_SUCCESSFULLY: {
            return {
                ...state,
                searchDomainsQuery: action.data.query,
            };
        }
        case SEARCH_MAIL_DOMAINS_INIT: {
            return {
                ...state,
                searchDomainsInProgress: false,
                searchDomainsResult: null,
            };
        }
        case CREATE_DOMAIN_IN_PROGRESS: {
            return {
                ...state,
                createDomainInProgress: true,
            };
        }
        case CREATE_DOMAIN_WITH_ERRORS: {
            return {
                ...state,
                createDomainInProgress: false,
            };
        }
        case CREATE_DOMAIN_SUCCESSFULLY: {
            const { id } = action.data;
            if (state.domains == null) {
                return state;
            }
            const foundNewDomain = state.domains.entities.find(
                (domain) => domain.id === id,
            );
            if (!foundNewDomain) {
                const newDomains = {
                    entities: [...state.domains.entities, action.data],
                    count: state.domains.count + 1,
                };
                return {
                    ...state,
                    createDomainInProgress: false,
                    domain: action.data,
                    domains: newDomains,
                };
            }
            return state;
        }
        case FETCH_DOMAIN_SUCCESSFULLY: {
            return {
                ...state,
                domain: action.data,
                domainInfoInProgress: false,
            };
        }
        case FETCH_DOMAIN_IN_PROGRESS: {
            return {
                ...state,
                domain: null,
                domainInfoInProgress: true,
            };
        }
        case FETCH_DOMAIN_WITH_ERRORS: {
            return {
                ...state,
                domainInfoInProgress: false,
            };
        }
        case FETCH_USER_MAIL_INFO_SUCCESSFULLY: {
            return {
                ...state,
                userInfo: action.data,
                userInfoError: false,
                userInfoInProgress: false,
            };
        }
        case FETCH_USER_MAIL_INFO_IN_PROGRESS: {
            return {
                ...state,
                userInfo: null,
                userInfoInProgress: true,
                userInfoError: false,
            };
        }
        case FETCH_USER_MAIL_INFO_WITH_ERRORS: {
            return {
                ...state,
                userInfoInProgress: false,
                userInfoError: true,
            };
        }
        case FETCH_USER_MAIL_STATISTICS_SUCCESSFULLY: {
            return {
                ...state,
                userStatisticsInfo: action.data,
                userStatisticsInfoError: false,
                userStatisticsInfoInProgress: false,
            };
        }
        case FETCH_USER_MAIL_STATISTICS_IN_PROGRESS: {
            return {
                ...state,
                userStatisticsInfo: null,
                userStatisticsInfoInProgress: true,
                userStatisticsInfoError: false,
            };
        }
        case FETCH_USER_MAIL_STATISTICS_WITH_ERRORS: {
            return {
                ...state,
                userStatisticsInfoInProgress: false,
                userStatisticsInfoError: true,
            };
        }
        case DELETE_DOMAIN_SUCCESSFULLY: {
            const { id } = action.data;
            if (
                state.domains == null
                || state.domains.entities.find((domain) => domain.id === id) == null
            ) {
                return state;
            }
            return {
                ...state,
                domains: {
                    count: state.domains.count - 1,
                    entities: state.domains.entities.filter(
                        (domain) => domain.id !== id,
                    ),
                },
            };
        }
        case UPDATE_DOMAIN_IN_PROGRESS: {
            return {
                ...state,
                updateDomainInProgress: true,
            };
        }
        case UPDATE_DOMAIN_WITH_ERRORS: {
            return {
                ...state,
                updateDomainInProgress: false,
            };
        }
        case UPDATE_DOMAIN_SUCCESSFULLY: {
            const { id } = action.data;
            let newDomain = state.domain;
            if (newDomain != null) {
                newDomain = {
                    ...newDomain,
                    quota: action.data.quota,
                };
            }
            if (state.domains != null) {
                const newDomainsEntities = state.domains.entities;
                const changedDomainIndex = newDomainsEntities.findIndex(
                    (domain) => domain.id === id,
                );
                if (changedDomainIndex !== -1) {
                    newDomainsEntities[changedDomainIndex] = {
                        ...newDomainsEntities[changedDomainIndex],
                        quota: action.data.quota,
                    };
                }
                return {
                    ...state,
                    domain: newDomain,
                    domains: {
                        count: state.domains.count,
                        entities: newDomainsEntities,
                    },
                    updateDomainInProgress: false,
                };
            }
            return {
                ...state,
                domain: newDomain,
                updateDomainInProgress: false,
            };
        }
        case CREATE_MAILBOX_IN_PROGRESS: {
            return {
                ...state,
                createMailboxInProgress: true,
            };
        }
        case CREATE_MAILBOX_WITH_ERRORS: {
            return {
                ...state,
                createMailboxInProgress: false,
            };
        }
        case CREATE_MAILBOX_SUCCESSFULLY: {
            let { mailboxes } = state;
            if (mailboxes != null) {
                const changedMailbox = mailboxes.entities.find(
                    (mailbox) => mailbox.id === action.data.id,
                );
                if (changedMailbox) {
                    return state;
                }
                mailboxes = {
                    count: mailboxes.count + 1,
                    entities: [...mailboxes.entities, action.data],
                };
            }
            if (state.domains == null) {
                return {
                    ...state,
                    mailboxes,
                    createMailboxInProgress: false,
                };
            }
            const newDomainsEntities = state.domains.entities;
            const changedDomain = newDomainsEntities.find(
                (domain) => domain.id === action.data.domain_id,
            );
            if (changedDomain) {
                changedDomain.mailboxes_count += 1;
                return {
                    ...state,
                    mailboxes,
                    domains: {
                        ...state.domains,
                        entities: [...newDomainsEntities],
                    },
                    createMailboxInProgress: false,
                };
            }
            return {
                ...state,
                mailboxes,
                createMailboxInProgress: false,
            };
        }
        case CHANGE_MAILBOX_IN_PROGRESS: {
            return {
                ...state,
                changeMailboxInProgress: true,
            };
        }
        case CHANGE_MAILBOX_WITH_ERRORS: {
            return {
                ...state,
                changeMailboxInProgress: false,
            };
        }
        case CHANGE_MAILBOX_SUCCESSFULLY: {
            const { id } = action.data;
            let { mailboxes } = state;
            if (mailboxes != null) {
                const changedMailboxIndex = mailboxes.entities.findIndex(
                    (mailbox) => mailbox.id === id,
                );
                if (changedMailboxIndex !== -1) {
                    mailboxes.entities[changedMailboxIndex] = {
                        ...action.data,
                    };
                }
            }
            let newMailbox = state.mailbox;
            if (state.mailbox != null && state.mailbox.id === id) {
                newMailbox = action.data;
            }
            return {
                ...state,
                mailboxes,
                mailbox: newMailbox,
                changeMailboxInProgress: false,
            };
        }
        case DELETE_MAILBOX_SUCCESSFULLY: {
            let newSearchResult =
                state.searchMailboxesResult?.entities?.filter(
                    (mailbox) => mailbox.id !== action.data.mailboxId,
                );
            if (newSearchResult?.length === 0) {
                newSearchResult = null;
            }
            let { mailboxes } = state;
            if (mailboxes != null) {
                mailboxes = {
                    count: mailboxes.entities.length - 1,
                    entities: [
                        ...mailboxes.entities.filter(
                            (mailbox) =>
                                mailbox.id !== action.data.mailboxId,
                        ),
                    ],
                };
            }
            if (state.domains == null) {
                return {
                    ...state,
                    mailboxes,
                    searchMailboxesResult: newSearchResult && {
                        count: newSearchResult.length,
                        entities: newSearchResult,
                    },
                };
            }
            const newDomainsEntities = state.domains.entities;
            const changedDomain = newDomainsEntities.find(
                (domain) => domain.id === action.data.domainId,
            );
            if (changedDomain) {
                changedDomain.mailboxes_count -= 1;
                return {
                    ...state,
                    mailboxes,
                    domains: {
                        ...state.domains,
                        entities: [...newDomainsEntities],
                        searchMailboxesResult: newSearchResult && {
                            count: newSearchResult.length,
                            entities: newSearchResult,
                        },
                    },
                };
            }
            return {
                ...state,
                mailboxes,
                searchMailboxesResult: newSearchResult && {
                    count: newSearchResult.length,
                    entities: newSearchResult,
                },
            };
        }
        case DELETE_MAILBOXES_SUCCESSFULLY: {
            const { responses } = action.data;
            const deletedMailboxesIds = Object.keys(responses);
            const newMailboxes = state.mailboxes.entities.filter(
                (mailbox) =>
                    !deletedMailboxesIds.includes(mailbox.id.toString()),
            );
            let newSearchResult =
                state.searchMailboxesResult?.entities?.filter(
                    (mailbox) =>
                        !deletedMailboxesIds.includes(
                            mailbox.id.toString(),
                        ),
                );
            if (newSearchResult?.length === 0) {
                newSearchResult = null;
            }
            return {
                ...state,
                mailboxes: {
                    count: newMailboxes.length,
                    entities: newMailboxes,
                },
                searchMailboxesResult: newSearchResult && {
                    count: newSearchResult.length,
                    entities: newSearchResult,
                },
            };
        }
        case SEARCH_MAILBOXES_INIT: {
            return {
                ...state,
                searchMailboxesResult: null,
                searchMailboxesInProgress: false,
            };
        }
        case SEARCH_MAILBOXES_SUCCESSFULLY: {
            return {
                ...state,
                searchMailboxesResult: action.data,
                searchMailboxesInProgress: false,
            };
        }
        case SEARCH_MAILBOXES_IN_PROGRESS: {
            return {
                ...state,
                searchMailboxesInProgress: true,
            };
        }
        case SEARCH_MAILBOXES_WITH_ERRORS: {
            return {
                ...state,
                searchMailboxesInProgress: false,
            };
        }
        case CHANGE_MAILBOXES_SEARCH_QUERY_SUCCESSFULLY: {
            return {
                ...state,
                searchMailboxesQuery: action.data.query,
            };
        }
        case FETCH_MAILBOXES_SUCCESSFULLY: {
            return {
                ...state,
                mailboxes: action.data,
                mailboxesInProgress: false,
            };
        }
        case FETCH_MAILBOXES_IN_PROGRESS: {
            return {
                ...state,
                mailboxes: null,
                mailboxesInProgress: true,
            };
        }
        case FETCH_MAILBOXES_INIT: {
            return {
                ...state,
                domain: null,
                mailbox: null,
                mailboxes: null,
            };
        }
        case FETCH_MAILBOXES_WITH_ERRORS: {
            return {
                ...state,
                mailboxesInProgress: false,
            };
        }
        case FETCH_MORE_MAILBOXES_SUCCESSFULLY: {
            return {
                ...state,
                mailboxes: {
                    count: action.data.count,
                    entities: [...state.mailboxes.entities, ...action.data.entities],
                },
                moreMailboxesInProgress: false,
            };
        }
        case FETCH_MORE_MAILBOXES_WITH_ERRORS: {
            return {
                ...state,
                moreMailboxesInProgress: false,
            };
        }
        case FETCH_MORE_MAILBOXES_IN_PROGRESS: {
            return {
                ...state,
                moreMailboxesInProgress: true,
            };
        }
        case FETCH_DOMAINS_INFO_SUCCESSFULLY: {
            return {
                ...state,
                domainsInfo: action.data,
                domainsInfoInProgress: false,
            };
        }
        case FETCH_DOMAINS_INFO_IN_PROGRESS: {
            return {
                ...state,
                domainsInfo: null,
                domainsInfoInProgress: true,
            };
        }
        case FETCH_MAIL_PACKET_LIST_SUCCESSFULLY: {
            return {
                ...state,
                packetsList: action.data.entities,
                packetsListInProgress: false,
                packetsListError: false,
            };
        }
        case FETCH_MAIL_PACKET_LIST_IN_PROGRESS: {
            return {
                ...state,
                packetsList: null,
                packetsListInProgress: true,
                packetsListError: false,
            };
        }
        case FETCH_MAIL_PACKET_LIST_WITH_ERRORS: {
            return {
                ...state,
                packetsListInProgress: false,
                packetsListError: true,
            };
        }
        case FETCH_MAIL_PREFERRED_PACKET_SUCCESSFULLY: {
            return {
                ...state,
                preferredPacket: action.data,
            };
        }
        case FETCH_MAIL_CALCULATION_SUCCESSFULLY: {
            return {
                ...state,
                calculation: action.data,
            };
        }
        case CREATE_MAIL_PACKET_IN_PROGRESS: {
            return {
                ...state,
                orderPacketInProgress: true
            };
        }
        case CREATE_MAIL_PACKET_WITH_ERRORS: {
            return {
                ...state,
                orderPacketInProgress: false
            };
        }
        case CREATE_MAIL_PACKET_SUCCESSFULLY: {
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    packet: action.data,
                },
                orderPacketInProgress: false
            };
        }
        case CANCEL_MAIL_PACKET_SUCCESSFULLY: {
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    packet: null,
                },
            };
        }
        case FETCH_MAILBOX_IN_PROGRESS: {
            return {
                ...state,
                mailboxInProgress: true,
            };
        }
        case FETCH_MAILBOX_SUCCESSFULLY: {
            return {
                ...state,
                mailboxInProgress: false,
                mailbox: action.data,
            };
        }
        case FETCH_MAILBOX_WITH_ERRORS: {
            return {
                ...state,
                mailboxInProgress: false,
            };
        }
        case UPDATE_MAIL_QUOTA_IN_PROGRESS: {
            return {
                ...state,
                updateMailQuotaInProgress: false,
            };
        }
        case UPDATE_MAIL_QUOTA_SUCCESSFULLY: {
            return {
                ...state,
                updateMailQuotaInProgress: false,
            };
        }
        case UPDATE_MAIL_QUOTA_WITH_ERRORS: {
            return {
                ...state,
                updateMailQuotaInProgress: false,
            };
        }
        default:
            return state;
        }
    };
};
