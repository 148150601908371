import { formErrors, flushErrors } from '../forms/actions';
import {
    VM_LIST_FETCHED_IN_PROGRESS,
    VM_LIST_FETCHED_SUCCESSFULLY,
    VM_LIST_FETCHED_WITH_ERRORS,
    CVDS_CREATE_VM_UPDATE_PARAMS,
    VM_CREATE_SUCCESSFULLY,
    VM_CREATE_IN_PROGRESS,
    VM_CREATE_TO_INIT,
    VM_CREATE_WITH_ERRORS,
    DISTRIBUTIONS_FETCHED_IN_PROGRESS,
    DISTRIBUTIONS_FETCHED_SUCCESSFULLY,
    DISTRIBUTIONS_FETCHED_WITH_ERRORS,
    CVDS_CHANGE_DISTRIBUTION,
    VM_RELOAD_SUCCESSFULLY,
    VM_CHANGE_STATE_SUCCESSFULLY,
    VM_FETCHED_IN_PROGRESS,
    VM_FETCHED_SUCCESSFULLY,
    VM_FETCHED_WITH_ERRORS,
    VM_GET_FINANCE_INFO_SUCCESSFULLY,
    VM_GET_FINANCE_INFO_WITH_ERRORS,
    VM_CREATE_PACKET_SUCCESSFULLY,
    VM_CANCEL_PACKET_SUCCESSFULLY,
    VM_UPDATE_PACKET_SUCCESSFULLY,
    VM_UPDATE_PACKET_WITH_ERRORS,
    VM_UPDATE_PACKET_IN_PROGRESS,
    VM_CHANGE_TARIFF_SUCCESSFULLY,
    VM_CHANGE_TARIFF_IN_PROGRESS,
    VM_CHANGE_TARIFF_WITH_ERRORS,
    FETCH_TARIFF_CALCULATION_IN_PROGRESS,
    FETCH_TARIFF_CALCULATION_SUCCESSFULLY,
    FETCH_TARIFF_CALCULATION_WITH_ERRORS,
    VM_RESET_PASSWORD_SUCCESSFULLY,
    VM_SUPPORT_ACCESS_SUCCESSFULLY,
    VOLUMES_USED_SIZE_SUCCESSFULLY,
    VOLUMES_USED_SIZE_IN_PROGRESS,
    VOLUMES_USED_SIZE_WITH_ERROR,
    VM_BACKUP_SWITCH_SUCCESSFULLY,
    VM_BAR_CHART_CPU_FETCH_IN_PROGRESS,
    VM_BAR_CHART_CPU_FETCH_SUCCESSFULLY,
    VM_BAR_CHART_CPU_FETCH_WITH_ERRORS,
    VM_BAR_CHART_RAM_FETCH_IN_PROGRESS,
    VM_BAR_CHART_RAM_FETCH_SUCCESSFULLY,
    VM_BAR_CHART_RAM_FETCH_WITH_ERRORS,
    VM_PRIVATE_NETWORK_SWITCH_SUCCESSFULLY,
    VM_VOLUME_ADD_SUCCESSFULLY,
    VM_RENAME_SUCCESSFULLY,
    SET_CURRENT_VOLUME,
    VM_VOLUME_BACKUP_SWITCH_SUCCESSFULLY,
    VM_VOLUME_BACKUP_SWITCH_IN_PROGRESS,
    VM_VOLUME_BACKUP_SWITCH_WITH_ERRORS,
    VM_DISK_CHANGE_INFO_SUCCESSFULLY,
    VM_VOLUME_CHANGE_INFO_SUCCESSFULLY,
    VM_CHANGE_BANDWIDTH_SUCCESSFULLY,
    VM_CHANGE_BANDWIDTH_IN_PROGRESS,
    VM_CHANGE_BANDWIDTH_WITH_ERRORS,
    VM_REMOVE_IP_SUCCESSFULLY,
    VM_DISABLE_PROTECTION_IN_PROGRESS,
    VM_DISABLE_PROTECTION_SUCCESSFULLY,
    VM_DISABLE_PROTECTION_WITH_ERRORS,
    VM_DELETE_SUCCESSFULLY,
    VMS_INFO_SUCCESSFULLY,
    WS_VM_CREATE_SUCCESSFULLY,
    WS_VM_UPDATE,
    WS_VM_UPDATE_IN_LIST,
    WS_VM_VOLUME_CREATE_SUCCESSFULLY,
    WS_VM_UPDATE_FINANCE_INFO,
    VM_ADD_IP_IN_PROGRESS,
    VM_ADD_IP_SUCCESSFULLY,
    VM_ADD_IP_WITH_ERRORS,
    VM_TO_INIT,
    VM_BACKUP_SWITCH_IN_PROGRESS,
    VM_BACKUP_SWITCH_WITH_ERRORS,
    VM_UPDATE_IN_PROGRESS,
    VM_UPDATE_WITH_ERRORS,
    VM_CREATE_PACKET_IN_PROGRESS,
    VM_CREATE_PACKET_WITH_ERRORS,
    VM_SUPPORT_ACCESS_WITH_ERRORS,
    VM_SUPPORT_ACCESS_IN_PROGRESS,
    VM_FETCH_PROTECTED_WEBSITES_IN_PROGRESS,
    VM_FETCH_PROTECTED_WEBSITES_SUCCESSFULLY,
    VM_FETCH_PROTECTED_WEBSITES_WITH_ERRORS,
    VM_ADD_PROTECTED_WEBSITE_SUCCESSFULLY,
    VM_ADD_PROTECTED_WEBSITE_IN_PROGRESS,
    VM_ADD_PROTECTED_WEBSITE_WITH_ERRORS,
    VM_CHANGE_PROTECTED_WEBSITE_IN_PROGRESS,
    VM_CHANGE_PROTECTED_WEBSITE_WITH_ERRORS,
    VM_CHANGE_PROTECTED_WEBSITE_SUCCESSFULLY,
    VM_DELETE_PROTECTED_WEBSITE_SUCCESSFULLY,
    VM_DELETE_PROTECTED_WEBSITE_IN_PROGRESS,
    VM_DELETE_PROTECTED_WEBSITE_WITH_ERRORS,
    BACKUPS_FETCHED_IN_PROGRESS,
    BACKUPS_FETCHED_SUCCESSFULLY,
    BACKUPS_FETCHED_WITH_ERRORS,
    BACKUP_DATES_FETCHED_IN_PROGRESS,
    BACKUP_DATES_FETCHED_SUCCESSFULLY,
    BACKUP_DATES_FETCHED_WITH_ERRORS,
    RESTORE_FROM_BACKUP_TO_INIT,
    RESTORE_FROM_BACKUP_UPDATE_PARAMS,
    RESTORE_FROM_BACKUP_SUCCESSFULLY,
    RESTORE_FROM_BACKUP_IN_PROGRESS,
    RESTORE_FROM_BACKUP_WITH_ERRORS,
} from './actionTypes';
import { isFunction } from '../../utils/general';
import RawResource from '../../gateway/resources/raw';
import CVDSVMResource from '../../gateway/resources/cvds/vm';
import CVDSBackupsResource from '../../gateway/resources/cvds/backups';
import CVDSResource from '../../gateway/resources/cvds';
import CVDSImagesResource from '../../gateway/resources/cvds/images';

export const fetchUserVMList = (needInProgress = true) => async (dispatch, getState, { gateway }) => {
    if (needInProgress) {
        dispatch({ type: VM_LIST_FETCHED_IN_PROGRESS });
    }
    const resource = new CVDSVMResource(gateway);
    const response = await resource.list();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_LIST_FETCHED_SUCCESSFULLY,
            data
        });
    } else {
        if ([403, 404].includes(response.status)) {
            dispatch({
                type: VM_LIST_FETCHED_SUCCESSFULLY,
                data: {
                    entities: [],
                    count: 0
                }
            });
            return;
        }
        dispatch({
            type: VM_LIST_FETCHED_WITH_ERRORS
        });
    }
};

export const fetchUserVMsInfo = () => async (dispatch, getState, { gateway }) => {
    const resource = new CVDSResource(gateway);
    const response = await resource.vmsInfo();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VMS_INFO_SUCCESSFULLY,
            data
        });
    }
};

export const createVMUpdateParams = (data, callback) => async (dispatch) => {
    dispatch({ type: CVDS_CREATE_VM_UPDATE_PARAMS, data });
    if (isFunction(callback)) {
        callback();
    }
};

export const cvdsChangeDistributions = (distribution, callback) => async (dispatch) => {
    dispatch({ type: CVDS_CHANGE_DISTRIBUTION, data: distribution });
    if (isFunction(callback)) {
        callback();
    }
};

export const createVMToInit = () => async (dispatch) => {
    dispatch({ type: VM_CREATE_TO_INIT });
};

export const vmCreate = (formId, createData, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_CREATE_IN_PROGRESS });
    dispatch(flushErrors());
    const resource = new CVDSVMResource(gateway);
    const response = await resource.create(createData);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_CREATE_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch(formErrors(formId, data.errors));
        dispatch({
            type: VM_CREATE_WITH_ERRORS,
            data
        });
    }
};

export const fetchCVDSDistributions = (callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: DISTRIBUTIONS_FETCHED_IN_PROGRESS });
    const resource = new CVDSImagesResource(gateway);
    const response = await resource.distributionsList();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: DISTRIBUTIONS_FETCHED_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: DISTRIBUTIONS_FETCHED_WITH_ERRORS });
    }
};

export const vmRename = (formId, { vmId, vmName }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_UPDATE_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.rename(vmId, vmName);
    const data = await response.json();
    if (response.ok) {
        callback();
        dispatch({
            type: VM_RENAME_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: VM_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const vmReload = (formId, vmId, { force }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_UPDATE_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.reload(vmId, force);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_RELOAD_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const vmChangeState = (formId, vmId, enabled, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_UPDATE_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = enabled ? await resource.stop(vmId) : await resource.start(vmId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_CHANGE_STATE_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const vmToInit = () => async (dispatch) => {
    dispatch({
        type: VM_TO_INIT
    });
};

export const fetchUserVM = (vmId, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_FETCHED_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.get(vmId);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: VM_FETCHED_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({
            type: VM_FETCHED_WITH_ERRORS
        });
    }
};

export const getVolumesUsedSize = (vmId) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VOLUMES_USED_SIZE_IN_PROGRESS, data: { vmId } });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.volumesUsedSize(vmId);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: VOLUMES_USED_SIZE_SUCCESSFULLY,
            data: {
                vmId,
                volumesUsedSize: data
            }
        });
    } else {
        dispatch({ type: VOLUMES_USED_SIZE_WITH_ERROR, data: { vmId } });
    }
};

export const vmDiskChangeBackup = (
    formId, { vmId, isBackupEnabled }, callback
) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_BACKUP_SWITCH_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.enableBackup(vmId, isBackupEnabled);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_BACKUP_SWITCH_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_BACKUP_SWITCH_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const fetchBarChartCPU = (vmId) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_BAR_CHART_CPU_FETCH_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.vmCPUstat(vmId);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: VM_BAR_CHART_CPU_FETCH_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: VM_BAR_CHART_CPU_FETCH_WITH_ERRORS
        });
    }
};

export const vmFinanceInfo = (vmId) => async (dispatch, params, { gateway }) => {
    const resource = new CVDSVMResource(gateway);
    const response = await resource.financeInfo(vmId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_GET_FINANCE_INFO_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: VM_GET_FINANCE_INFO_WITH_ERRORS
        });
    }
};

export const fetchBarChartRAM = (vmId) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_BAR_CHART_RAM_FETCH_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.vmRAMstat(vmId);
    if (response.ok) {
        const data = await response.json();
        dispatch({
            type: VM_BAR_CHART_RAM_FETCH_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: VM_BAR_CHART_RAM_FETCH_WITH_ERRORS
        });
    }
};

export const createPacket = (formId, vmId, callback) => async (dispatch, params, { gateway }) => {
    dispatch({ type: VM_CREATE_PACKET_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.createPacket(vmId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_CREATE_PACKET_SUCCESSFULLY,
            data
        });
        dispatch(vmFinanceInfo(vmId));
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_CREATE_PACKET_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const changeTariff = (formId, { vmId, tariffId }, callback) => async (dispatch, params, { gateway }) => {
    dispatch({ type: VM_CHANGE_TARIFF_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.changeTariff(vmId, tariffId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_CHANGE_TARIFF_SUCCESSFULLY,
            data: {
                tariffId
            }
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_CHANGE_TARIFF_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const vmResetPassword = (formId, { vmId, password }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_UPDATE_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    let params = { vmId };
    if (password.length > 0) {
        params = { ...params, password };
    }
    const response = await resource.resetPassword(params);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_RESET_PASSWORD_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({ type: VM_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const cancelPacket = (vmId) => async (dispatch, params, { gateway }) => {
    const resource = new CVDSVMResource(gateway);
    const response = await resource.cancelPacket(vmId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_CANCEL_PACKET_SUCCESSFULLY,
            data
        });
        dispatch(vmFinanceInfo(vmId));
    }
};

export const updatePacket = (vmId, parameters) => async (dispatch, params, { gateway }) => {
    dispatch({
        type: VM_UPDATE_PACKET_IN_PROGRESS
    });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.updatePacket(vmId, parameters);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_UPDATE_PACKET_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: VM_UPDATE_PACKET_WITH_ERRORS
        });
    }
};

export const refreshPacket = (formId, vmId, callback) => async (dispatch, params, { gateway }) => {
    dispatch({
        type: VM_UPDATE_PACKET_IN_PROGRESS
    });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.refreshPacket(vmId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_GET_FINANCE_INFO_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({
            type: VM_UPDATE_PACKET_WITH_ERRORS
        });
        dispatch(formErrors(formId, data.errors));
    }
};

export const changeTariffCalculation = (vmId, tariffId) => async (dispatch, params, { gateway }) => {
    dispatch({ type: FETCH_TARIFF_CALCULATION_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.changeTariffCalculation(vmId, tariffId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_TARIFF_CALCULATION_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({
            type: FETCH_TARIFF_CALCULATION_WITH_ERRORS
        });
    }
};

export const switchPrivateNetwork = (formId, vm, hasPrivateNetwork) => async (dispatch, getState, { gateway }) => {
    const resource = new CVDSVMResource(gateway);
    const resourceFunction = hasPrivateNetwork ? resource.disableLan : resource.enableLan;
    const response = await resourceFunction.call(resource, vm.id);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_PRIVATE_NETWORK_SWITCH_SUCCESSFULLY,
            data
        });
    } else {
        dispatch(formErrors(formId, data.errors));
    }
};

export const vmSupportAccess = (formId, vmId) => async (dispatch, params, { gateway }) => {
    dispatch({ type: VM_SUPPORT_ACCESS_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.supportAccess(vmId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_SUPPORT_ACCESS_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: VM_SUPPORT_ACCESS_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const vmVolumeAdd = (formId, { vmId, size, backup }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_UPDATE_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.volumeAdd(vmId, size, backup);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_VOLUME_ADD_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const setCurrentVolume = (volume, callback) => async (dispatch) => {
    dispatch({
        type: SET_CURRENT_VOLUME,
        data: volume
    });
    if (isFunction(callback)) {
        callback();
    }
};

export const vmVolumeChangeBackup = (formId, { vmId, isBackupEnabled, volumeId }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_VOLUME_BACKUP_SWITCH_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.volumeChangeBackup(vmId, volumeId, isBackupEnabled);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_VOLUME_BACKUP_SWITCH_SUCCESSFULLY,
            data: {
                ...data,
                volumeId
            }
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_VOLUME_BACKUP_SWITCH_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const vmProtectedIPAdd = (formId, vmId, bandwidth, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_CHANGE_BANDWIDTH_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.protectedIPAdd(vmId, bandwidth);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_CHANGE_BANDWIDTH_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_CHANGE_BANDWIDTH_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const removeIp = (formId, vm, ip, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_UPDATE_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.removeIp(vm.id, ip);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_REMOVE_IP_SUCCESSFULLY,
            data: {
                vm,
                ip
            }
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const vmDiskResize = (formId, { vmId, size }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_UPDATE_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.diskResize(vmId, size);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_DISK_CHANGE_INFO_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const vmDisableProtection = (formId, vmId, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_DISABLE_PROTECTION_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.disableProtection(vmId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_DISABLE_PROTECTION_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_DISABLE_PROTECTION_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const vmVolumeResize = (formId, { vmId, size, volumeId }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_UPDATE_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.volumeResize(vmId, volumeId, size);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_VOLUME_CHANGE_INFO_SUCCESSFULLY,
            data: {
                volumeId,
                vm: data
            }
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const vmChangeBandwidth = (formId, vmId, bandwidth, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_CHANGE_BANDWIDTH_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.changeBandwidth(vmId, bandwidth);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_CHANGE_BANDWIDTH_SUCCESSFULLY,
            data
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_CHANGE_BANDWIDTH_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const vmDelete = (formId, { vmId, archived }, closeCallback, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_UPDATE_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.delete(vmId, archived);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_DELETE_SUCCESSFULLY,
            data
        });
        if (isFunction(closeCallback)) {
            closeCallback();
        }
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: VM_UPDATE_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const wsVmDelete = (data, callback) => async (dispatch, getState) => {
    const {
        cvds: { currentVM }
    } = getState();
    dispatch({
        type: VM_DELETE_SUCCESSFULLY,
        data
    });
    if (currentVM != null && currentVM.id === data.id && isFunction(callback)) {
        callback();
    }
};

export const wsVmCreate = (data) => async (dispatch) => {
    dispatch({
        type: WS_VM_CREATE_SUCCESSFULLY,
        data
    });
    dispatch(fetchUserVMList(false));
};

export const wsVmUpdate = (data) => async (dispatch, getState) => {
    const {
        cvds: { currentVM }
    } = getState();
    if (currentVM && currentVM.id === data.id) {
        dispatch({
            type: WS_VM_UPDATE,
            data
        });
    }
    dispatch({
        type: WS_VM_UPDATE_IN_LIST,
        data
    });
};

export const wsVmVolumeCreate = (data) => async (dispatch, getState) => {
    const {
        cvds: { currentVM }
    } = getState();
    if (currentVM && currentVM.id === data.vm_id) {
        dispatch({
            type: WS_VM_VOLUME_CREATE_SUCCESSFULLY,
            data
        });
    } else {
        dispatch(fetchUserVMList(false));
    }
};

export const wsVmRecalculated = (data) => async (dispatch, getState) => {
    const {
        cvds: { currentVM }
    } = getState();
    if (currentVM && currentVM.id === data.id) {
        dispatch({
            type: WS_VM_UPDATE_FINANCE_INFO,
            data
        });
    } else {
        dispatch(fetchUserVMList(false));
    }
};

export const addIp = (formId, vm, { version, currentIp, createType }, callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: VM_ADD_IP_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const isNeedReserved = createType === 'reserved';
    const response = isNeedReserved
        ? await resource.addReservedIp(vm.id, currentIp)
        : await resource.addIp(vm.id, version);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_ADD_IP_SUCCESSFULLY,
            data: {
                vm,
                version,
                ips: data.ips.filter((ip) => version === ip.version)
            }
        });
        if (isFunction(callback)) {
            callback(version);
        }
    } else {
        dispatch({ type: VM_ADD_IP_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const fetchProtectedWebsites = (
    id, limit, offset
) => async (dispatch, params, { gateway }) => {
    dispatch({ type: VM_FETCH_PROTECTED_WEBSITES_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.vmProtectedWebsites(id, limit, offset);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_FETCH_PROTECTED_WEBSITES_SUCCESSFULLY,
            data,
        });
    } else {
        dispatch({
            type: VM_FETCH_PROTECTED_WEBSITES_WITH_ERRORS,
        });
    }
};

export const addProtectedWebsite = (formId, id, { domain, certificate }, callback) => async (dispatch, params, { gateway }) => {
    dispatch({ type: VM_ADD_PROTECTED_WEBSITE_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.addProtectedWebsite(id, {
        domain,
        certificate,
    });
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_ADD_PROTECTED_WEBSITE_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({
            type: VM_ADD_PROTECTED_WEBSITE_WITH_ERRORS,
        });
        dispatch(formErrors(formId, data.errors));
    }
};

export const changeProtectedWebsite = (formId, id, domainId, certificate, callback) => async (dispatch, params, { gateway }) => {
    dispatch({ type: VM_CHANGE_PROTECTED_WEBSITE_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.changeProtectedWebsite(
        id,
        domainId,
        certificate,
    );
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_CHANGE_PROTECTED_WEBSITE_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({
            type: VM_CHANGE_PROTECTED_WEBSITE_WITH_ERRORS,
        });
        dispatch(formErrors(formId, data.errors));
    }
};

export const deleteProtectedWebsite = (formId, id, domainId, callback) => async (dispatch, params, { gateway }) => {
    dispatch({ type: VM_DELETE_PROTECTED_WEBSITE_IN_PROGRESS });
    const resource = new CVDSVMResource(gateway);
    const response = await resource.deleteProtectedWebsite(id, domainId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_DELETE_PROTECTED_WEBSITE_SUCCESSFULLY,
            data: {
                domainId,
            },
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({
            type: VM_DELETE_PROTECTED_WEBSITE_WITH_ERRORS,
        });
        dispatch(formErrors(formId, data.errors));
    }
};

export const fetchCVDSBackups = (callback) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: BACKUPS_FETCHED_IN_PROGRESS });
    const resource = new CVDSBackupsResource(gateway);
    const response = await resource.backupList();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: BACKUPS_FETCHED_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: BACKUPS_FETCHED_WITH_ERRORS });
    }
};

export const fetchCVDSBackupDates = (
    resourceUrl, callback, formId
) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: BACKUP_DATES_FETCHED_IN_PROGRESS });
    const resource = new RawResource(gateway);
    const response = await resource.makeRequest(
        'get',
        gateway.url + resourceUrl,
    );
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: BACKUP_DATES_FETCHED_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        if (formId) {
            dispatch(formErrors(formId, data.errors));
        }
        dispatch({ type: BACKUP_DATES_FETCHED_WITH_ERRORS });
    }
};

export const restoreFromBackupToInit = () => async (dispatch) => {
    dispatch({ type: RESTORE_FROM_BACKUP_TO_INIT });
};

export const restoreFromBackupUpdateParams = (data, callback) => async (dispatch) => {
    dispatch({ type: RESTORE_FROM_BACKUP_UPDATE_PARAMS, data });
    if (isFunction(callback)) {
        callback();
    }
};

export const makeBackup = (
    formId,
    resourceUrl,
    callback,
) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: RESTORE_FROM_BACKUP_IN_PROGRESS });
    const resource = new RawResource(gateway);
    const response = await resource.makeRequest(
        'post',
        gateway.url + resourceUrl,
    );
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: RESTORE_FROM_BACKUP_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({ type: RESTORE_FROM_BACKUP_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const financeInfoByConfiguration = (configuration) => async (
    dispatch, params, { gateway }
) => {
    const resource = new CVDSVMResource(gateway);
    const response = await resource.financeInfoByConfiguration(
        configuration,
    );
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: VM_GET_FINANCE_INFO_SUCCESSFULLY,
            data,
        });
    } else {
        dispatch({
            type: VM_GET_FINANCE_INFO_WITH_ERRORS,
        });
    }
};
