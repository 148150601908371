import {
    VM_LIST_FETCHED_IN_PROGRESS,
    VM_LIST_FETCHED_WITH_ERRORS,
    VM_LIST_FETCHED_SUCCESSFULLY,
    VM_RELOAD_SUCCESSFULLY,
    VM_CHANGE_STATE_SUCCESSFULLY,
    VM_FETCHED_IN_PROGRESS,
    VM_FETCHED_SUCCESSFULLY,
    VM_FETCHED_WITH_ERRORS,
    VM_CHANGE_TARIFF_SUCCESSFULLY,
    VM_CHANGE_TARIFF_IN_PROGRESS,
    VM_CHANGE_TARIFF_WITH_ERRORS,
    VM_SUPPORT_ACCESS_SUCCESSFULLY,
    VOLUMES_USED_SIZE_WITH_ERROR,
    VOLUMES_USED_SIZE_IN_PROGRESS,
    VOLUMES_USED_SIZE_SUCCESSFULLY,
    VM_BACKUP_SWITCH_SUCCESSFULLY,
    VM_BACKUP_SWITCH_IN_PROGRESS,
    VM_BACKUP_SWITCH_WITH_ERRORS,
    VM_BAR_CHART_CPU_FETCH_IN_PROGRESS,
    VM_BAR_CHART_CPU_FETCH_SUCCESSFULLY,
    VM_BAR_CHART_CPU_FETCH_WITH_ERRORS,
    VM_BAR_CHART_RAM_FETCH_IN_PROGRESS,
    VM_BAR_CHART_RAM_FETCH_SUCCESSFULLY,
    VM_BAR_CHART_RAM_FETCH_WITH_ERRORS,
    VM_PRIVATE_NETWORK_SWITCH_SUCCESSFULLY,
    VM_VOLUME_ADD_SUCCESSFULLY,
    VM_RENAME_SUCCESSFULLY,
    SET_CURRENT_VOLUME,
    VM_VOLUME_BACKUP_SWITCH_SUCCESSFULLY,
    VM_VOLUME_BACKUP_SWITCH_WITH_ERRORS,
    VM_VOLUME_BACKUP_SWITCH_IN_PROGRESS,
    VM_DISK_CHANGE_INFO_SUCCESSFULLY,
    VM_VOLUME_CHANGE_INFO_SUCCESSFULLY,
    VM_CHANGE_BANDWIDTH_SUCCESSFULLY,
    VM_CHANGE_BANDWIDTH_IN_PROGRESS,
    VM_CHANGE_BANDWIDTH_WITH_ERRORS,
    VM_REMOVE_IP_SUCCESSFULLY,
    VM_DISABLE_PROTECTION_IN_PROGRESS,
    VM_DISABLE_PROTECTION_SUCCESSFULLY,
    VM_DISABLE_PROTECTION_WITH_ERRORS,
    VM_DELETE_SUCCESSFULLY,
    VMS_INFO_SUCCESSFULLY,
    WS_VM_CREATE_SUCCESSFULLY,
    WS_VM_UPDATE_IN_LIST,
    WS_VM_UPDATE,
    WS_VM_VOLUME_CREATE_SUCCESSFULLY,
    WS_VM_VOLUME_DELETE_SUCCESSFULLY,
    VM_ADD_IP_IN_PROGRESS,
    VM_ADD_IP_SUCCESSFULLY,
    VM_ADD_IP_WITH_ERRORS,
    VM_TO_INIT,
    VM_UPDATE_IN_PROGRESS,
    VM_UPDATE_WITH_ERRORS,
    VM_RESET_PASSWORD_SUCCESSFULLY,
    VM_SUPPORT_ACCESS_IN_PROGRESS,
    VM_SUPPORT_ACCESS_WITH_ERRORS,
    VM_FETCH_PROTECTED_WEBSITES_IN_PROGRESS,
    VM_FETCH_PROTECTED_WEBSITES_SUCCESSFULLY,
    VM_FETCH_PROTECTED_WEBSITES_WITH_ERRORS,
    VM_ADD_PROTECTED_WEBSITE_SUCCESSFULLY,
    VM_ADD_PROTECTED_WEBSITE_IN_PROGRESS,
    VM_ADD_PROTECTED_WEBSITE_WITH_ERRORS,
    VM_CHANGE_PROTECTED_WEBSITE_IN_PROGRESS,
    VM_CHANGE_PROTECTED_WEBSITE_WITH_ERRORS,
    VM_CHANGE_PROTECTED_WEBSITE_SUCCESSFULLY,
    VM_DELETE_PROTECTED_WEBSITE_SUCCESSFULLY,
    VM_DELETE_PROTECTED_WEBSITE_IN_PROGRESS,
    VM_DELETE_PROTECTED_WEBSITE_WITH_ERRORS,
} from '../../actions/cvds/actionTypes';
import { uniqBy } from '../../utils/general';

export default () => (
    state = {
        vmList: null,
        vmsInfo: null,
        vmListInProgress: false,
        vmListError: false,
        addIpInProgress: false,
        currentVM: {},
        currentVolume: {},
        barChartCPU: null,
        barChartRAM: null,
        vmInProgress: false,
        vmUpdateInProgress: false,
        antiDDoSInProgress: false,
        backupSwitchInProgress: false,
        antiDDoSDisableInProgress: false,
        supportAccessInProgress: false,
        changeTariffInProgress: false,
        protectedWebsites: null,
        protectedWebsitesError: false,
        protectedWebsitesInProgress: false,
        addProtectedWebsiteInProgress: false,
        changeProtectedWebsiteInProgress: false,
        deleteProtectedWebsiteInProgress: false,
    },
    action
) => {
    switch (action.type) {
    case VM_LIST_FETCHED_SUCCESSFULLY: {
        const vmList = action.data;
        vmList.entities.sort((a, b) => {
            if (a.lan_ip != null && b.lan_ip != null) {
                return 0;
            }
            if (a.lan_ip != null) {
                return -1;
            }
            return 1;
        });
        return {
            ...state,
            vmList,
            vmListInProgress: false,
            vmListError: false,
        };
    }
    case VMS_INFO_SUCCESSFULLY: {
        return {
            ...state,
            vmsInfo: action.data,
        };
    }
    case VM_LIST_FETCHED_IN_PROGRESS: {
        return {
            ...state,
            vmListInProgress: true,
            vmListError: false,
        };
    }
    case VM_LIST_FETCHED_WITH_ERRORS: {
        return {
            ...state,
            vmListInProgress: false,
            vmListError: true,
        };
    }
    case VM_RELOAD_SUCCESSFULLY: {
        return {
            ...state,
            vmUpdateInProgress: false,
            currentVM: {
                ...state.currentVM,
                ...action.data,
            },
        };
    }
    case VM_CHANGE_STATE_SUCCESSFULLY: {
        return {
            ...state,
            vmUpdateInProgress: false,
            currentVM: {
                ...state.currentVM,
                ...action.data,
            },
        };
    }
    case VOLUMES_USED_SIZE_SUCCESSFULLY: {
        if (action.data.vmId !== state.currentVM.id) {
            return state;
        }
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                volumesUsedSizeIsLoading: false,
                volumesUsedSizeIsError: false,
                volumesUsedSize: action.data.volumesUsedSize,
            },
        };
    }
    case VOLUMES_USED_SIZE_IN_PROGRESS: {
        if (action.data.vmId !== state.currentVM.id) {
            return state;
        }
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                volumesUsedSizeIsLoading: true,
                volumesUsedSizeIsError: false,
                volumesUsedSize: null,
            },
        };
    }
    case VOLUMES_USED_SIZE_WITH_ERROR: {
        if (action.data.vmId !== state.currentVM.id) {
            return state;
        }
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                volumesUsedSizeIsLoading: false,
                volumesUsedSizeIsError: true,
                volumesUsedSize: null,
            },
        };
    }
    case VM_BACKUP_SWITCH_IN_PROGRESS: {
        return {
            ...state,
            backupSwitchInProgress: true,
        };
    }
    case VM_BACKUP_SWITCH_WITH_ERRORS: {
        return {
            ...state,
            backupSwitchInProgress: false,
        };
    }
    case VM_BACKUP_SWITCH_SUCCESSFULLY: {
        const { disk, id } = action.data;
        if (id !== state.currentVM.id) {
            return state;
        }
        let volume = state.currentVolume;
        if (volume !== null && volume.uid === disk.uid) {
            volume = {
                ...state.currentVM.disk,
                is_backup_enabled: disk.is_backup_enabled,
            };
        }
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                disk: {
                    ...state.currentVM.disk,
                    is_backup_enabled: disk.is_backup_enabled,
                },
            },
            currentVolume: volume,
            backupSwitchInProgress: false,
        };
    }
    case VM_BAR_CHART_CPU_FETCH_IN_PROGRESS: {
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                barChartCPU: {
                    ...state.currentVM.barChartCPU,
                    isLoading: true,
                    isError: false,
                },
            },
        };
    }
    case VM_BAR_CHART_CPU_FETCH_WITH_ERRORS: {
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                barChartCPU: {
                    ...state.currentVM.barChartCPU,
                    isLoading: false,
                    isError: true,
                },
            },
        };
    }
    case VM_BAR_CHART_CPU_FETCH_SUCCESSFULLY: {
        const { cumulative, cpu } = action.data;
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                barChartCPU: {
                    ...state.currentVM.barChartCPU,
                    isError: false,
                    isLoading: false,
                    datapoints: cpu,
                    cumulative,
                },
            },
        };
    }
    case VM_BAR_CHART_RAM_FETCH_IN_PROGRESS: {
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                barChartRAM: {
                    ...state.currentVM.barChartRAM,
                    isLoading: true,
                    isError: false,
                },
            },
        };
    }
    case VM_BAR_CHART_RAM_FETCH_WITH_ERRORS: {
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                barChartRAM: {
                    ...state.currentVM.barChartRAM,
                    isLoading: false,
                    isError: true,
                },
            },
        };
    }
    case VM_BAR_CHART_RAM_FETCH_SUCCESSFULLY: {
        const { cumulative, ram } = action.data;
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                barChartRAM: {
                    ...state.currentVM.barChartRAM,
                    isError: false,
                    isLoading: false,
                    datapoints: ram,
                    cumulative,
                },
            },
        };
    }
    case VM_FETCHED_IN_PROGRESS: {
        return {
            ...state,
            vmInProgress: true,
        };
    }
    case VM_FETCHED_SUCCESSFULLY: {
        const vm = action.data;
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                ...vm,
                volumesUsedSizeIsLoading: false,
                volumesUsedSizeIsError: false,
                volumesUsedSize: null,
            },
            vmInProgress: false,
        };
    }
    case VM_FETCHED_WITH_ERRORS: {
        return {
            ...state,
            vmInProgress: false,
        };
    }
    case VM_CHANGE_TARIFF_IN_PROGRESS: {
        return {
            ...state,
            changeTariffInProgress: true,
        };
    }
    case VM_CHANGE_TARIFF_WITH_ERRORS: {
        return {
            ...state,
            changeTariffInProgress: false,
        };
    }
    case VM_CHANGE_TARIFF_SUCCESSFULLY: {
        const { tariffId } = action.data;
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                tariff: tariffId,
            },
        };
    }
    case VM_SUPPORT_ACCESS_IN_PROGRESS: {
        return {
            ...state,
            supportAccessInProgress: true,
        };
    }
    case VM_SUPPORT_ACCESS_WITH_ERRORS: {
        return {
            ...state,
            supportAccessInProgress: false,
        };
    }
    case VM_SUPPORT_ACCESS_SUCCESSFULLY: {
        if (action.data.id !== state.currentVM.id) {
            return state;
        }
        return {
            ...state,
            supportAccessInProgress: false,
            currentVM: {
                ...state.currentVM,
                support_access: action.data.support_access,
            },
        };
    }
    case VM_PRIVATE_NETWORK_SWITCH_SUCCESSFULLY: {
        if (action.data.id !== state.currentVM.id) {
            return state;
        }
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                lan_ip: action.data.lan_ip,
            },
        };
    }
    case VM_VOLUME_ADD_SUCCESSFULLY: {
        if (action.data.id !== state.currentVM.id) {
            return state;
        }
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                volumes: action.data.volumes,
            },
            vmUpdateInProgress: false,
        };
    }
    case VM_RENAME_SUCCESSFULLY: {
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                name: action.data.name,
            },
            vmUpdateInProgress: false,
        };
    }
    case SET_CURRENT_VOLUME: {
        return {
            ...state,
            currentVolume: action.data,
        };
    }
    case VM_VOLUME_BACKUP_SWITCH_IN_PROGRESS: {
        return {
            ...state,
            backupSwitchInProgress: true,
        };
    }
    case VM_VOLUME_BACKUP_SWITCH_WITH_ERRORS: {
        return {
            ...state,
            backupSwitchInProgress: false,
        };
    }
    case VM_VOLUME_BACKUP_SWITCH_SUCCESSFULLY: {
        if (action.data.id !== state.currentVM.id) {
            return state;
        }
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                volumes: action.data.volumes,
            },
            currentVolume: action.data.volumes.find(
                (v) => v.id === action.data.volumeId
            ),
            backupSwitchInProgress: false,
        };
    }
    case VM_VOLUME_CHANGE_INFO_SUCCESSFULLY: {
        if (action.data.vm.id !== state.currentVM.id) {
            return state;
        }
        return {
            ...state,
            currentVM: {
                ...action.data.vm,
            },
            currentVolume: action.data.vm.volumes.find(
                (v) => v.id === action.data.volumeId
            ),
            vmUpdateInProgress: false,
        };
    }
    case VM_DISK_CHANGE_INFO_SUCCESSFULLY: {
        if (action.data.id !== state.currentVM.id) {
            return state;
        }
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                disk: action.data.disk,
            },
            currentVolume: action.data.disk,
            vmUpdateInProgress: false,
        };
    }
    case VM_CHANGE_BANDWIDTH_SUCCESSFULLY: {
        if (action.data.id !== state.currentVM.id) {
            return state;
        }
        const { ips, bandwidth, is_protected } = action.data;
        return {
            ...state,
            antiDDoSInProgress: false,
            currentVM: {
                ...state.currentVM,
                is_protected,
                bandwidth,
                ips: uniqBy([...state.currentVM.ips, ...ips], 'ip'),
            },
        };
    }
    case VM_CHANGE_BANDWIDTH_IN_PROGRESS: {
        return {
            ...state,
            antiDDoSInProgress: true,
        };
    }
    case VM_CHANGE_BANDWIDTH_WITH_ERRORS: {
        return {
            ...state,
            antiDDoSInProgress: false,
        };
    }
    case VM_REMOVE_IP_SUCCESSFULLY: {
        if (action.data.vm.id !== state.currentVM.id) {
            return state;
        }
        const { ip, is_protected } = action.data;
        const ips = state.currentVM.ips.filter(
            (ipInstance) => ipInstance.ip !== ip
        );
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                is_protected,
                ips,
            },
            vmUpdateInProgress: false,
        };
    }
    case VM_DISABLE_PROTECTION_IN_PROGRESS: {
        return {
            ...state,
            antiDDoSDisableInProgress: true,
        };
    }
    case VM_DISABLE_PROTECTION_WITH_ERRORS: {
        return {
            ...state,
            antiDDoSDisableInProgress: false,
        };
    }
    case VM_DISABLE_PROTECTION_SUCCESSFULLY: {
        return {
            ...state,
            antiDDoSDisableInProgress: false,
            currentVM: {
                ...state.currentVM,
                ...action.data,
            },
        };
    }
    case VM_DELETE_SUCCESSFULLY: {
        const { id } = action.data;
        if (state.vmList == null) {
            return state;
        }
        let newVmList = { ...state.vmList };
        if (newVmList.entities.find((el) => el.id === id)) {
            newVmList = {
                count: state.vmList.count - 1,
                entities: state.vmList.entities.filter((item) => item.id !== id),
            };
        }
        return {
            ...state,
            vmList: newVmList,
            vmUpdateInProgress: false,
        };
    }
    case VM_ADD_IP_IN_PROGRESS: {
        return {
            ...state,
            addIpInProgress: true,
        };
    }
    case VM_ADD_IP_WITH_ERRORS: {
        return {
            ...state,
            addIpInProgress: false,
        };
    }
    case VM_ADD_IP_SUCCESSFULLY: {
        if (action.data.vm.id !== state.currentVM.id) {
            return state;
        }
        const { ips } = action.data;
        return {
            ...state,
            addIpInProgress: false,
            currentVM: {
                ...state.currentVM,
                ips: uniqBy([...state.currentVM.ips, ...ips], 'ip'),
            },
        };
    }
    case WS_VM_UPDATE_IN_LIST: {
        if (state.vmList) {
            const vmListEntities = state.vmList.entities.map((vm) => {
                if (vm.id === action.data.id) {
                    return {
                        ...vm,
                        ...action.data,
                    };
                }
                return vm;
            });
            return {
                ...state,
                vmList: {
                    ...state.vmList,
                    entities: vmListEntities,
                },
            };
        }
        return state;
    }
    case WS_VM_CREATE_SUCCESSFULLY: {
        if (state.vmList) {
            return {
                ...state,
                vmList: {
                    entities: [...state.vmList.entities, action.data],
                    count: state.vmList.count + 1,
                },
                currentVM: {
                    ...action.data,
                },
            };
        }
        return state;
    }
    case WS_VM_VOLUME_CREATE_SUCCESSFULLY: {
        if (state.currentVM && action.data.vm_id === state.currentVM.id) {
            const foundNewVolume = state.currentVM.volumes.find(
                (volume) => volume.id === action.data.id
            );
            if (!foundNewVolume) {
                const newVolumes = [...state.currentVM.volumes, action.data];
                return {
                    ...state,
                    currentVM: {
                        ...state.currentVM,
                        volumes: newVolumes,
                    },
                };
            }
        }
        return state;
    }
    case WS_VM_UPDATE: {
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                ...action.data,
            },
        };
    }
    case WS_VM_VOLUME_DELETE_SUCCESSFULLY: {
        if (action.data.vm_id !== state.currentVM.id) {
            return state;
        }
        const volumes = state.currentVM.volumes.filter(
            (volume) => volume.id !== action.data.id
        );
        return {
            ...state,
            currentVM: {
                ...state.currentVM,
                volumes,
            },
        };
    }
    case VM_TO_INIT: {
        return {
            ...state,
            currentVM: {},
            currentVolume: {},
            barChartCPU: null,
            barChartRAM: null,
            vmInProgress: false,
        };
    }
    case VM_RESET_PASSWORD_SUCCESSFULLY: {
        return {
            ...state,
            vmUpdateInProgress: false,
        };
    }
    case VM_UPDATE_IN_PROGRESS: {
        return {
            ...state,
            vmUpdateInProgress: true,
        };
    }
    case VM_UPDATE_WITH_ERRORS: {
        return {
            ...state,
            vmUpdateInProgress: false,
        };
    }
    case VM_FETCH_PROTECTED_WEBSITES_SUCCESSFULLY: {
        return {
            ...state,
            protectedWebsites: action.data,
            protectedWebsitesError: false,
            protectedWebsitesInProgress: false,
        };
    }
    case VM_FETCH_PROTECTED_WEBSITES_IN_PROGRESS: {
        return {
            ...state,
            protectedWebsites: null,
            protectedWebsitesError: false,
            protectedWebsitesInProgress: true,
        };
    }
    case VM_FETCH_PROTECTED_WEBSITES_WITH_ERRORS: {
        return {
            ...state,
            protectedWebsites: null,
            protectedWebsitesError: true,
            protectedWebsitesInProgress: false,
        };
    }
    case VM_ADD_PROTECTED_WEBSITE_IN_PROGRESS: {
        return {
            ...state,
            addProtectedWebsiteInProgress: true,
        };
    }
    case VM_ADD_PROTECTED_WEBSITE_WITH_ERRORS: {
        return {
            ...state,
            addProtectedWebsiteInProgress: false,
        };
    }
    case VM_ADD_PROTECTED_WEBSITE_SUCCESSFULLY: {
        const { protectedWebsites } = state;
        const website = action.data;
        if (
            protectedWebsites
          && protectedWebsites.entities.find((w) => w.id === website.id)
        ) {
            return state;
        }
        return {
            ...state,
            addProtectedWebsiteInProgress: false,
            protectedWebsites: {
                count: protectedWebsites.count + 1,
                entities: [...protectedWebsites.entities, action.data],
            },
        };
    }
    case VM_CHANGE_PROTECTED_WEBSITE_IN_PROGRESS: {
        return {
            ...state,
            changeProtectedWebsiteInProgress: true,
        };
    }
    case VM_CHANGE_PROTECTED_WEBSITE_WITH_ERRORS: {
        return {
            ...state,
            changeProtectedWebsiteInProgress: false,
        };
    }
    case VM_CHANGE_PROTECTED_WEBSITE_SUCCESSFULLY: {
        const { protectedWebsites } = state;
        if (protectedWebsites) {
            const websiteIndex = protectedWebsites.entities.findIndex(
                (w) => w.id === action.data.id
            );
            if (websiteIndex !== -1) {
                protectedWebsites.entities[websiteIndex] = action.data;
                return {
                    ...state,
                    protectedWebsites,
                };
            }
            return {
                ...state,
                changeProtectedWebsiteInProgress: false,
                protectedWebsites: [...protectedWebsites, action.data],
            };
        }
        return state;
    }
    case VM_DELETE_PROTECTED_WEBSITE_IN_PROGRESS: {
        return {
            ...state,
            deleteProtectedWebsiteInProgress: true,
        };
    }
    case VM_DELETE_PROTECTED_WEBSITE_WITH_ERRORS: {
        return {
            ...state,
            deleteProtectedWebsiteInProgress: false,
        };
    }
    case VM_DELETE_PROTECTED_WEBSITE_SUCCESSFULLY: {
        const { protectedWebsites } = state;
        if (protectedWebsites) {
            return {
                ...state,
                deleteProtectedWebsiteInProgress: false,
                protectedWebsites: {
                    count: protectedWebsites.count - 1,
                    entities: protectedWebsites.entities.filter(
                        (w) => w.id !== action.data.domainId
                    ),
                },
            };
        }
        return state;
    }
    default:
        return state;
    }
};
