import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';
import { bindActionCreators } from 'redux';
import { useNavigation } from '@react-navigation/native';

import Errors from '../../General/Form/Errors';
import { flushErrors } from '../../../actions/forms/actions';
import { removeIp } from '../../../actions/cvds/actions';
import WarningCancelPacketBlock from '../WarningCancelPacketBlock';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'DeleteProtectedIPForm';

function DeleteProtectedIPModal(props) {
    const {
        vm,
        ip,
        ips,
        errors,
        onClose,
        inProgress,
        flushErrors,
        awaitingPacket,
        removeIp
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);
    const navigation = useNavigation();
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Подтвердите удаление IP‑адреса {ip}</Text>
        {ips && ips.length === 1 && <Text style={styles.lastIpWarning}>
            При удалении последнего защищенного IP-адреса
            <Text style={styles.lastIpWarningBold}>
                защита от DDoS будет отключена и список доменов для фильтрации
                будет очищен
            </Text>
        </Text>}
        {awaitingPacket && <View style={styles.packetWarning}>
            <WarningCancelPacketBlock mainText='У вас имеется незавершенный заказ
                на годовой пакет для виртуальной машины. Удаление платного IP-адреса'
            warningText='отменит заказ годового пакета'/>
        </View>}
        {errors.__all__ && <Errors errors={errors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => removeIp(formId, vm, ip, () => {
                    onClose();
                    if (ips.length === 1) {
                        navigation.navigate('CVDSVM', {
                            screen: 'VMNetwork',
                            vmId: vm.id,
                        });
                    }
                })}
                title='Удалить'
                type='danger'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

DeleteProtectedIPModal.propTypes = {
    vm: PropTypes.shape({}),
    ip: PropTypes.string,
    onClose: PropTypes.func,
    errors: PropTypes.shape({}),
    removeIp: PropTypes.func,
    awaitingPacket: PropTypes.bool,
    inProgress: PropTypes.bool,
    ips: PropTypes.arrayOf(),
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    inProgress: state.cvds.vmUpdateInProgress,
    errors: state.forms.errors[formId] || {},
    awaitingPacket: state.cvdsFinanceInfo.calculation.packet == null ? false
        : state.cvdsFinanceInfo.calculation.packet.is_awaiting
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors, removeIp
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 12,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    lastIpWarning: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '400',
        lineHeight: 18
    },
    lastIpWarningBold: {
        color: '#d51010',
        fontWeight: '500',
        marginLeft: 3,
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProtectedIPModal);
