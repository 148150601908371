import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    View, Text, StyleSheet, TouchableOpacity
} from 'react-native';

import { formatMemory } from '../../../utils/general';
import { createVMUpdateParams } from '../../../actions/cvds/actions';
import { tariffsByCategory, tariffsDict } from '../../../constants/cvds';

function TariffBlock() {
    const [currentTariffsCategory, setCurrentTariffsCategory] = React.useState('small');

    const currentTariff = useSelector((state) => state.cvdsCreate.currentTariff);
    const dispatch = useDispatch();

    return (
        <View>
            <View style={styles.tariffsGroupBlock}>
                {tariffsByCategory.map((tariffs, i) => (
                    <View key={`tariffs-category-${i}`}>
                        <TouchableOpacity
                            onPress={() => setCurrentTariffsCategory(tariffs.category)}
                            style={styles.tariffsGroup}
                        >
                            <Text
                                style={
                                    currentTariffsCategory === tariffs.category
                                        ? styles.currentTariffsGroupTitle
                                        : styles.tariffsGroupTitle
                                }
                            >
                                {tariffs.categoryName}
                            </Text>
                        </TouchableOpacity>
                    </View>
                ))}
            </View>
            <View>
                {tariffsDict[currentTariffsCategory].map((tariff, j) => (
                    <TouchableOpacity
                        key={`tariff-${j}`}
                        style={
                            currentTariff.id === tariff.id
                                ? styles.currentTariffBlock
                                : styles.tariffBlock
                        }
                        onPress={() => dispatch(
                            createVMUpdateParams({
                                currentTariff: tariff,
                            })
                        )
                        }
                    >
                        <View
                            style={
                                currentTariff.id === tariff.id
                                    ? styles.activeOuterCircle
                                    : styles.outerCircle
                            }
                        >
                            {currentTariff.id === tariff.id && (
                                <View style={styles.innerCircle} />
                            )}
                        </View>
                        <Text
                            style={
                                currentTariff.id === tariff.id
                                    ? styles.currentTariffTitle
                                    : styles.tariffTitle
                            }
                        >
                            {tariff.name}
                        </Text>
                        <Text style={styles.tariffInfoText}>
              CPUx{tariff.features.cpu},
                        </Text>
                        <Text style={styles.tariffInfoText}>
              RAM {formatMemory(tariff.features.memory)}
                        </Text>
                    </TouchableOpacity>
                ))}
            </View>
        </View>
    );
}

TariffBlock.propTypes = {
    currentTariff: PropTypes.shape({}),
    createVMUpdateParams: PropTypes.func,
};

const styles = StyleSheet.create({
    tariffsGroupBlock: {
        flex: 1,
        flexDirection: 'row',
        paddingLeft: 16,
        overflow: 'auto',
        paddingBottom: 9,
    },
    tariffsGroup: {
        paddingTop: 20,
        marginRight: 24,
    },
    tariffsGroupTitle: {
        opacity: 0.8,
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 15,
        paddingBottom: 11.5,
    },
    currentTariffsGroupTitle: {
        color: '#0f79d5',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 15,
        paddingBottom: 11.5,
        paddingLeft: 2,
        paddingRight: 2,
        borderBottomWidth: 2,
        borderBottomColor: '#0f79d5',
    },
    tariffBlock: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 25,
        paddingLeft: 25,
        backgroundColor: '#ffffff',
        marginBottom: 1,
    },
    currentTariffBlock: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 25,
        paddingLeft: 25,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomColor: '#0f79d5',
        backgroundColor: 'rgba(15,121,213,0.07)',
        marginBottom: 0,
    },
    tariffTitle: {
        flex: 1,
        marginLeft: 12,
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 15,
    },
    currentTariffTitle: {
        flex: 1,
        marginLeft: 12,
        color: '#0f79d5',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 15,
    },
    tariffInfoText: {
        color: '#455562',
        fontSize: 10,
        lineHeight: 11,
        marginRight: 3,
    },
    outerCircle: {
        height: 18,
        width: 18,
        borderTopWidth: 1,
        borderTopColor: 'rgba(161,170,177,0.5)',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(161,170,177,0.5)',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(161,170,177,0.5)',
        borderRightWidth: 1,
        borderRightColor: 'rgba(161,170,177,0.5)',
        borderRadius: 9,
        backgroundColor: '#f5f5f5',
    },
    activeOuterCircle: {
        height: 18,
        width: 18,
        borderTopWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderLeftWidth: 1,
        borderLeftColor: '#0f79d5',
        borderRightWidth: 1,
        borderRightColor: '#0f79d5',
        borderRadius: 9,
        backgroundColor: '#ffffff',
        paddingTop: 4,
        paddingLeft: 4,
    },
    innerCircle: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0f79d5',
    },
});

export default TariffBlock;
