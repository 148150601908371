import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useFocusEffect } from '@react-navigation/native';

import DbmsBlock from './DbmsBlock';
import EngineBlock from './EngineBlock';
import { fetchDbms } from '../../../actions/hosting/actions';

function ApplicationTab(props) {
    const {
        dbms,
        fetchDbms,
        virtualhost,
        dbmsInProgress,
        virtualhostInProgress
    } = props;

    useFocusEffect(React.useCallback(() => {
        if (virtualhost && !dbmsInProgress && dbms == null) {
            fetchDbms(virtualhost.id);
        }
    }, [virtualhost]));
    return <View>
        {!virtualhostInProgress && virtualhost && <EngineBlock />}
        {!virtualhostInProgress && virtualhost && dbms
            && <DbmsBlock />}
    </View>;
}

ApplicationTab.propTypes = {
    dbms: PropTypes.shape({}),
    dbmsInProgress: PropTypes.bool,
    virtualhost: PropTypes.shape({}),
    virtualhostInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    dbms: state.hosting.dbms,
    dbmsInProgress: state.hosting.dbmsInProgress,
    virtualhost: state.hosting.virtualhost,
    virtualhostInProgress: state.hosting.virtualhostInProgress,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchDbms
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationTab);