export class DynamicComponentService {
    constructor() {
        this.repository = [];
    }

    add(name, component) {
        this.repository.push({
            name,
            component
        });
    }

    get(name) {
        const componentData = this.repository.find((c) => name === c.name);
        if (componentData != null) {
            return componentData.component;
        }
        return null;
    }
}
