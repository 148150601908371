export const CONTAINER_CREATE_IN_PROGRESS = 'CONTAINER_CREATE_IN_PROGRESS';
export const CONTAINER_CREATE_WITH_ERRORS = 'CONTAINER_CREATE_WITH_ERRORS';
export const CONTAINER_CREATE_SUCCESSFULLY = 'CONTAINER_CREATE_SUCCESSFULLY';
export const CONTAINER_CREATE_SET_PARAM = 'CONTAINER_CREATE_SET_PARAM';
export const CONTAINER_CREATE_FLUSH_PARAMS = 'CONTAINER_CREATE_FLUSH_PARAMS';
export const CONTAINER_CREATE_SET_PARAMS = 'CONTAINER_CREATE_SET_PARAMS';

export const CONTAINERS_FETCHED_IN_PROGRESS = 'CONTAINERS_FETCHED_IN_PROGRESS';
export const CONTAINERS_FETCHED_SUCCESSFULLY = 'CONTAINERS_FETCHED_SUCCESSFULLY';
export const CONTAINERS_FETCHED_WITH_ERRORS = 'CONTAINERS_FETCHED_WITH_ERRORS';

export const CONTAINER_FETCHED_IN_PROGRESS = 'CONTAINER_FETCHED_IN_PROGRESS';
export const CONTAINER_FETCHED_SUCCESSFULLY = 'CONTAINER_FETCHED_SUCCESSFULLY';
export const CONTAINER_FETCHED_WITH_ERRORS = 'CONTAINER_FETCHED_WITH_ERRORS';

export const CONTAINER_VIRTUALHOSTS_FETCHED_IN_PROGRESS = 'CONTAINER_VIRTUALHOSTS_FETCHED_IN_PROGRESS';
export const CONTAINER_VIRTUALHOSTS_FETCHED_SUCCESSFULLY = 'CONTAINER_VIRTUALHOSTS_FETCHED_SUCCESSFULLY';
export const CONTAINER_VIRTUALHOSTS_FETCHED_WITH_ERRORS = 'CONTAINER_VIRTUALHOSTS_FETCHED_WITH_ERRORS';

export const CONTAINER_LIMITS_IN_PROGRESS = 'CONTAINER_LIMITS_IN_PROGRESS';
export const CONTAINER_LIMITS_SUCCESSFULLY = 'CONTAINER_LIMITS_SUCCESSFULLY';
export const CONTAINER_LIMITS_WITH_ERRORS = 'CONTAINER_LIMITS_WITH_ERRORS';

export const CONTAINER_INITIAL = 'CONTAINER_INITIAL';
export const CONTAINER_SSH_SET_PASSWORD = 'CONTAINER_SSH_SET_PASSWORD';
export const CONTAINER_SSH_SET_PASSWORD_IN_PROGRESS = 'CONTAINER_SSH_SET_PASSWORD_IN_PROGRESS';
export const CONTAINER_SSH_SET_PASSWORD_WITH_ERRORS = 'CONTAINER_SSH_SET_PASSWORD_WITH_ERRORS';
export const CONTAINER_DELETE = 'CONTAINER_DELETE';
export const CONTAINER_DELETE_IN_PROGRESS = 'CONTAINER_DELETE_IN_PROGRESS';
export const CONTAINER_DELETE_WITH_ERRORS = 'CONTAINER_DELETE_WITH_ERRORS';

export const CONTAINER_UPDATE = 'CONTAINER_UPDATE';
export const CONTAINER_UPDATE_IN_PROGRESS = 'CONTAINER_UPDATE_IN_PROGRESS';
export const CONTAINER_UPDATE_WITH_ERRORS = 'CONTAINER_UPDATE_WITH_ERRORS';

export const CALCULATION_FETCH_IN_PROGRESS = 'CALCULATION_FETCH_IN_PROGRESS';
export const CALCULATION_FETCH_SUCCESSFULLY = 'CALCULATION_FETCH_SUCCESSFULLY';
export const CALCULATION_FETCH_WITH_ERRORS = 'CALCULATION_FETCH_WITH_ERRORS';

export const RECALCULATE_CONTAINER_PRICE = 'RECALCULATE_CONTAINER_PRICE';

export const REDIS_UPDATE = 'REDIS_UPDATE';
export const REDIS_UPDATE_WITH_ERRORS = 'REDIS_UPDATE_WITH_ERRORS';
export const REDIS_DELETE = 'REDIS_DELETE';
export const REDIS_DELETE_WITH_ERRORS = 'REDIS_DELETE_WITH_ERRORS';
export const MEMCACHE_UPDATE = 'MEMCACHE_UPDATE';
export const MEMCACHE_UPDATE_WITH_ERRORS = 'MEMCACHE_UPDATE_WITH_ERRORS';
export const MEMCACHE_DELETE = 'MEMCACHE_DELETE';
export const MEMCACHE_DELETE_WITH_ERRORS = 'MEMCACHE_DELETE_WITH_ERRORS';

export const VIRTUALHOST_FETCHED_SUCCESSFULLY = 'VIRTUALHOST_FETCHED_SUCCESSFULLY';
export const VIRTUALHOST_FETCHED_IN_PROGRESS = 'VIRTUALHOST_FETCHED_IN_PROGRESS';
export const VIRTUALHOST_FETCHED_WITH_ERRORS = 'VIRTUALHOST_FETCHED_WITH_ERRORS';

export const VIRTUALHOST_CHANGE_STATE = 'VIRTUALHOST_CHANGE_STATE';

export const VIRTUALHOST_UPDATE = 'VIRTUALHOST_UPDATE';
export const VIRTUALHOST_UPDATE_IN_PROGRESS = 'VIRTUALHOST_UPDATE_IN_PROGRESS';
export const VIRTUALHOST_UPDATE_WITH_ERRORS = 'VIRTUALHOST_UPDATE_WITH_ERRORS';

export const VIRTUALHOST_DELETE = 'VIRTUALHOST_DELETE';
export const VIRTUALHOST_DELETE_IN_PROGRESS = 'VIRTUALHOST_DELETE_IN_PROGRESS';
export const VIRTUALHOST_DELETE_WITH_ERRORS = 'VIRTUALHOST_DELETE_WITH_ERRORS';

export const DBMS_FETCHED_IN_PROGRESS = 'DBMS_FETCHED_IN_PROGRESS';
export const DBMS_FETCHED_SUCCESSFULLY = 'DBMS_FETCHED_SUCCESSFULLY';
export const DBMS_FETCHED_WITH_ERRORS = 'DBMS_FETCHED_WITH_ERRORS';

export const DBMS_CHANGE_PASSWORD = 'DBMS_CHANGE_PASSWORD';
export const DBMS_CHANGE_PASSWORD_IN_PROGRESS = 'DBMS_CHANGE_PASSWORD_IN_PROGRESS';
export const DBMS_CHANGE_PASSWORD_WITH_ERRORS = 'DBMS_CHANGE_PASSWORD_WITH_ERRORS';

export const FTPS_FETCHED_SUCCESSFULLY = 'FTPS_FETCHED_SUCCESSFULLY';
export const FTPS_FETCHED_WITH_ERRORS = 'FTPS_FETCHED_WITH_ERRORS';
export const FTPS_FETCHED_IN_PROGRESS = 'FTPS_FETCHED_IN_PROGRESS';

export const FTP_ADD = 'FTP_ADD';
export const FTP_ADD_IN_PROGRESS = 'FTP_ADD_IN_PROGRESS';
export const FTP_ADD_WITH_ERRORS = 'FTP_ADD_WITH_ERRORS';

export const FTPS_CHANGE_PASSWORD = 'FTPS_CHANGE_PASSWORD';
export const FTPS_CHANGE_PASSWORD_IN_PROGRESS = 'FTPS_CHANGE_PASSWORD_IN_PROGRESS';
export const FTPS_CHANGE_PASSWORD_WITH_ERRORS = 'FTPS_CHANGE_PASSWORD_WITH_ERRORS';
export const FTPS_DELETE = 'FTPS_DELETE';
export const FTPS_DELETE_IN_PROGRESS = 'FTPS_DELETE_IN_PROGRESS';
export const FTPS_DELETE_WITH_ERRORS = 'FTPS_DELETE_WITH_ERRORS';

export const VIRTUALHOST_CREATE = 'VIRTUALHOST_CREATE';
export const VIRTUALHOST_CREATE_IN_PROGRESS = 'VIRTUALHOST_CREATE_IN_PROGRESS';
export const VIRTUALHOST_CREATE_WITH_ERRORS = 'VIRTUALHOST_CREATE_WITH_ERRORS';

export const BAR_CHART_CPU_FETCH_IN_PROGRESS = 'BAR_CHART_CPU_FETCH_IN_PROGRESS';
export const BAR_CHART_CPU_FETCH_SUCCESSFULLY = 'BAR_CHART_CPU_FETCH_SUCCESSFULLY';
export const BAR_CHART_CPU_FETCH_WITH_ERRORS = 'BAR_CHART_CPU_FETCH_WITH_ERRORS';

export const BAR_CHART_RAM_FETCH_IN_PROGRESS = 'BAR_CHART_RAM_FETCH_IN_PROGRESS';
export const BAR_CHART_RAM_FETCH_SUCCESSFULLY = 'BAR_CHART_RAM_FETCH_SUCCESSFULLY';
export const BAR_CHART_RAM_FETCH_WITH_ERRORS = 'BAR_CHART_RAM_FETCH_WITH_ERRORS';

export const SET_PROBLEM_CPU = 'SET_PROBLEM_CPU';
export const SET_PROBLEM_MEMORY = 'SET_PROBLEM_MEMORY';
export const SET_PROBLEM_STORAGE = 'SET_PROBLEM_STORAGE';

export const CONTAINER_CAPABILITIES = 'CONTAINER_CAPABILITIES';

export const SSL_UPLOAD_ARCHIVE_SUCCESSFULLY = 'SSL_UPLOAD_ARCHIVE_SUCCESSFULLY';
export const SSL_UPLOAD_ARCHIVE_WITH_ERROR = 'SSL_UPLOAD_ARCHIVE_WITH_ERROR';

export const VIRTUALHOST_RELOAD = 'VIRTUALHOST_RELOAD';

export const CALCULATION_PACKET_CREATE_IN_PROGRESS = 'CALCULATION_PACKET_CREATE_IN_PROGRESS';
export const CALCULATION_PACKET_CREATE_SUCCESSFULLY = 'CALCULATION_PACKET_CREATE_SUCCESSFULLY';
export const CALCULATION_PACKET_CREATE_WITH_ERRORS = 'CALCULATION_PACKET_CREATE_WITH_ERRORS';
export const CALCULATION_PACKET_UPDATE_IN_PROGRESS = 'CALCULATION_PACKET_UPDATE_IN_PROGRESS';
export const CALCULATION_PACKET_UPDATE_WITH_ERRORS = 'CALCULATION_PACKET_UPDATE_WITH_ERRORS';

export const CALCULATION_PACKET_RENEWAL_CHANGE_IN_PROGRESS = 'CALCULATION_PACKET_RENEWAL_CHANGE_IN_PROGRESS';
export const CALCULATION_PACKET_RENEWAL_CHANGE_SUCCESSFULLY = 'CALCULATION_PACKET_RENEWAL_CHANGE_SUCCESSFULLY';

export const VIRTUALHOSTS_FETCHED_SUCCESSFULLY = 'VIRTUALHOSTS_FETCHED_SUCCESSFULLY';
export const VIRTUALHOSTS_FETCHED_IN_PROGRESS = 'VIRTUALHOSTS_FETCHED_IN_PROGRESS';
export const VIRTUALHOSTS_FETCHED_WITH_ERRORS = 'VIRTUALHOSTS_FETCHED_WITH_ERRORS';

export const HOSTING_RESTORE_FROM_BACKUP_TO_INIT = 'HOSTING_RESTORE_FROM_BACKUP_TO_INIT';
export const HOSTING_RESTORE_FROM_BACKUP_UPDATE_PARAMS = 'HOSTING_RESTORE_FROM_BACKUP_UPDATE_PARAMS';

export const CONTAINER_BACKUPS_LIST_SUCCESSFULLY = 'CONTAINER_BACKUPS_LIST_SUCCESSFULLY';
export const CONTAINER_BACKUPS_LIST_IN_PROGRESS = 'CONTAINER_BACKUPS_LIST_IN_PROGRESS';
export const CONTAINER_BACKUPS_LIST_WITH_ERRORS = 'CONTAINER_BACKUPS_LIST_WITH_ERRORS';

export const CONTAINER_BACKUPS_RESTORE_SUCCESSFULLY = 'CONTAINER_BACKUPS_RESTORE_SUCCESSFULLY';
export const CONTAINER_BACKUPS_RESTORE_IN_PROGRESS = 'CONTAINER_BACKUPS_RESTORE_IN_PROGRESS';
export const CONTAINER_BACKUPS_RESTORE_WITH_ERRORS = 'CONTAINER_BACKUPS_RESTORE_WITH_ERRORS';

export const DBMS_BACKUPS_LIST_SUCCESSFULLY = 'DBMS_BACKUPS_LIST_SUCCESSFULLY';
export const DBMS_BACKUPS_LIST_IN_PROGRESS = 'DBMS_BACKUPS_LIST_IN_PROGRESS';
export const DBMS_BACKUPS_LIST_WITH_ERRORS = 'DBMS_BACKUPS_LIST_WITH_ERRORS';

export const DBMS_BACKUPS_RESTORE = 'DBMS_BACKUPS_RESTORE';

export const GET_CONTAINER_CALCULATION_SUCCESSFULLY = 'GET_CONTAINER_CALCULATION_SUCCESSFULLY';
