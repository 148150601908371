import React from 'react';
import PropTypes from 'prop-types';
import {
    Text,
    View,
    Image,
    StyleSheet,
    TouchableOpacity
} from 'react-native';

import { editBlueIcon, editGreyIcon } from '../../icons';

function WithdrawalTypeButton(props) {
    const {
        withdrawalType,
        setWithdrawalType,
        buttonType,
        buttonTitle,
        buttonSubtitle,
        buttonHint,
        onPress,
        needEditIcon = false,
        additionalButtonStyles = []
    } = props;
    return <TouchableOpacity style={[styles.withdrawalTypeBlock, {
        borderColor: withdrawalType === buttonType ? '#0f79d5' : '#e5e5e5',
        backgroundColor: withdrawalType === buttonType
            ? 'rgba(15, 121, 213, 0.07)' : '#ffffff'
    }, ...additionalButtonStyles]} onPress={() => setWithdrawalType(buttonType)}>
        <View style={styles.withdrawalTypeBlockWrapper}>
            <View style={withdrawalType === buttonType ? styles.activeOuterCircle
                : styles.outerCircle}>
                {withdrawalType === buttonType && <View style={styles.innerCircle}/>}
            </View>
            <View style={styles.withdrawalTypeBlockInner}>
                <Text style={[styles.withdrawalTypeBlockTitle,
                    { color: withdrawalType === buttonType ? '#0f79d5' : '#455562' }]}>
                    {buttonTitle}
                </Text>
                {buttonSubtitle && <Text style={[styles.withdrawalTypeBlockSubtitle,
                    { color: withdrawalType === buttonType ? '#0f79d5' : '#455562' }]}>
                    {buttonSubtitle}
                </Text>}
                {buttonHint && <Text style={[styles.withdrawalTypeBlockHint,
                    { color: withdrawalType === buttonType ? '#0f79d5' : '#455562' }]}>
                    {buttonHint}
                </Text>}
            </View>
            {needEditIcon && <TouchableOpacity onPress={onPress}>
                <Image source={withdrawalType === buttonType ? editBlueIcon : editGreyIcon}
                    style={[styles.icon, { opacity: withdrawalType === buttonType ? 1 : 0.7 }]}
                />
            </TouchableOpacity>}
        </View>
    </TouchableOpacity>;
}

WithdrawalTypeButton.propTypes = {
    withdrawalType: PropTypes.string,
    setWithdrawalType: PropTypes.func,
    buttonType: PropTypes.string,
    buttonTitle: PropTypes.string,
    buttonSubtitle: PropTypes.string,
    buttonHint: PropTypes.string,
    onPress: PropTypes.func,
    needEditIcon: PropTypes.bool,
    additionalButtonStyles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.number,
    ]))
};

const styles = StyleSheet.create({
    withdrawalTypeBlock: {
        paddingLeft: 20,
        paddingRight: 18,
        paddingTop: 15,
        paddingBottom: 15,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#e5e5e5'
    },
    withdrawalTypeBlockWrapper: {
        flex: 1,
        flexDirection: 'row',
    },
    withdrawalTypeBlockInner: {
        flex: 1
    },
    withdrawalTypeBlockTitle: {
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 16
    },
    withdrawalTypeBlockSubtitle: {
        fontSize: 11,
        lineHeight: 13,
        marginTop: 6
    },
    withdrawalTypeBlockHint: {
        fontSize: 10,
        lineHeight: 12,
        opacity: 0.7,
        marginTop: 5
    },
    icon: {
        width: 16,
        height: 16
    },
    outerCircle: {
        height: 18,
        width: 18,
        marginRight: 16,
        borderTopWidth: 1,
        borderTopColor: 'rgba(161, 170, 177, 0.5)',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(161, 170, 177, 0.5)',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(161, 170, 177, 0.5)',
        borderRightWidth: 1,
        borderRightColor: 'rgba(161, 170, 177, 0.5)',
        borderRadius: 9,
        backgroundColor: '#f5f5f5'
    },
    activeOuterCircle: {
        height: 18,
        width: 18,
        marginRight: 16,
        borderTopWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderLeftWidth: 1,
        borderLeftColor: '#0f79d5',
        borderRightWidth: 1,
        borderRightColor: '#0f79d5',
        borderRadius: 9,
        backgroundColor: '#ffffff',
        paddingTop: 4,
        paddingLeft: 4
    },
    innerCircle: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0f79d5'
    }
});

export default WithdrawalTypeButton;
