import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Image, StyleSheet, Text, TouchableOpacity, View
} from 'react-native';

import { addCustomNotification } from '../../../actions/notifications/actions';

const styles = StyleSheet.create({
    wrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 15,
        paddingBottom: 16,
        paddingTop: 16,
        marginLeft: 6,
        marginRight: 6
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 16,
    },
    disabledIcon: {
        opacity: 0.5
    },
    title: {
        fontWeight: '400',
        fontSize: 14,
        lineHeight: 16,
        color: '#0F79D5'
    },
    disabledTitle: {
        fontWeight: '400',
        fontSize: 14,
        lineHeight: 16,
        color: '#888888',
        opacity: 0.5
    }
});

function Action(props) {
    const {
        title, icon, onPress = () => {},
        iconStyles = [],
        enabled = true,
        textStyles = [],
        disabledText,
        addCustomNotification
    } = props;
    return <TouchableOpacity activeOpacity={0.6} onPress={() => {
        if (enabled) {
            onPress();
        } else if (disabledText != null && disabledText.length !== 0) {
            addCustomNotification(disabledText);
        }
    }}>
        <View style={styles.wrapper}>
            {icon && <Image source={icon}
                style={[styles.icon, enabled ? {} : styles.disabledIcon, ...iconStyles]} />}
            <Text style={[enabled ? styles.title : styles.disabledTitle, ...textStyles]}>
                {title}
            </Text>
        </View>
    </TouchableOpacity>;
}

Action.propTypes = {
    title: PropTypes.string,
    onPress: PropTypes.func,
    enabled: PropTypes.bool,
    icon: PropTypes.string,
    disabledText: PropTypes.string,
    addCustomNotification: PropTypes.func,
    iconStyles: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]))
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({ addCustomNotification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Action);
