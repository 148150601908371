import { useCallback } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getChannel } from '@netangels/core/utils/websockets';
import { userChannelName } from '@netangels/core/constants/websockets';

import useWebsockets from '../../hooks/useWebsockets';
import {
    wsVmDelete,
    wsVmCreate,
    wsVmRecalculated,
    wsVmUpdate,
    wsVmVolumeCreate
} from '../../actions/cvds/actions';
import { connectToWebsocketWithDelay } from '../../utils/websockets';

function CVDSWebsockets(props) {
    const {
        user,
        wsVmDelete,
        wsVmCreate,
        wsVmRecalculated,
        wsVmUpdate,
        wsVmVolumeCreate
    } = props;
    const ws = useWebsockets();
    let cvdsSubscriber = null;
    const navigation = useNavigation();

    useFocusEffect(useCallback(() => {
        const connectionState = connectToWebsocketWithDelay(() => {
            if (cvdsSubscriber == null) {
                const channelName = getChannel(userChannelName, user.login, true);

                cvdsSubscriber = ws.subscriber('cvds', channelName);
                cvdsSubscriber.on('vm.delete', (data) => {
                    wsVmDelete(data, () => navigation.navigate('CVDS', { screen: 'CVDSStack' }));
                })
                    .on('vm.create', (data) => {
                        wsVmCreate(data);
                    })
                    .on('vm.update', (data) => {
                        wsVmUpdate(data);
                    })
                    .on('vm.recalculated', (data) => {
                        wsVmRecalculated(data);
                    })
                    .on('volume.create', (data) => {
                        wsVmVolumeCreate(data);
                    });
            }
        });
        return () => {
            connectionState.cancel();
            if (cvdsSubscriber != null) {
                ws.unsubscribe(cvdsSubscriber);
                cvdsSubscriber = null;
            }
        };
    }, []));

    return null;
}

CVDSWebsockets.propTypes = {
    user: PropTypes.shape({}),
    wsVmDelete: PropTypes.func,
    wsVmCreate: PropTypes.func,
    wsVmRecalculated: PropTypes.func,
    wsVmUpdate: PropTypes.func,
    wsVmVolumeCreate: PropTypes.func,
    wsVmVolumeRemove: PropTypes.func
};

const mapStateToProps = (state) => ({
    user: state.user.user
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    wsVmDelete,
    wsVmCreate,
    wsVmUpdate,
    wsVmRecalculated,
    wsVmVolumeCreate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CVDSWebsockets);
