const BaseResource = require('../resource');

class IPsResource extends BaseResource {
    async ipsReservedFreeList(limit, offset) {
        const data = { limit: limit || 100, offset: offset || 0 };
        return this.makeRequest('get', 'free/', data);
    }
}

IPsResource.resource = 'reserved';
IPsResource.service = 'ips';

module.exports = IPsResource;
