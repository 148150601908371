import React from 'react';
import { StyleSheet, Image, TouchableOpacity } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import AccountStackScreen from './account';
import GeneralStackScreen from './general';
import FinanceStackScreen from './finance';
import PartnershipStackScreen from './partnership';

import {
    generalBottomTabIcon, generalBottomTabActiveIcon,
    financeBottomTabIcon, financeBottomTabActiveIcon,
    accountBottomTabIcon, accountBottomTabActiveIcon,
    partnershipBottomTabIcon, partnershipBottomTabActiveIcon,
} from '../icons';

const Tab = createBottomTabNavigator();

const styles = StyleSheet.create({
    loadingScreen: {
        flex: 1,
        flexDirection: 'column'
    },
    logoWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tabIcon: {
        flex: 1,
        marginTop: 10,
        alignItems: 'end',
    }
});

function TabsScreen(props) {
    const { navigation } = props;

    return <Tab.Navigator
        tabBarOptions={{
            style: {
                height: 60,
            },
            labelStyle: {
                fontSize: 9,
                letterSpacing: 0,
                lineHeight: 11,
                marginBottom: 10
            },
        }}>
        <Tab.Screen name="General" component={GeneralStackScreen} options={{
            tabBarIcon: ({ focused }) => <Image resizeMode='contain'
                source={focused ? generalBottomTabActiveIcon : generalBottomTabIcon}
                style={[styles.tabIcon, { height: 22, width: 34 }]}
            />,
            title: 'Главная',
        }} />
        <Tab.Screen name="Finance" component={FinanceStackScreen} options={{
            tabBarIcon: ({ focused }) => <Image resizeMode='contain'
                source={focused ? financeBottomTabActiveIcon : financeBottomTabIcon}
                style={[styles.tabIcon, { height: 22, width: 30 }]}
            />,
            tabBarButton: (props) => (<TouchableOpacity {...props}
                onPress={() => navigation.navigate('Finance', {
                    screen: 'FinanceTabs',
                    params: { screen: 'FinanceBalance' }
                })}/>),
            title: 'Финансы'
        }}/>
        <Tab.Screen name="Partnership" component={PartnershipStackScreen} options={{
            tabBarIcon: ({ focused }) => <Image resizeMode='contain'
                source={focused ? partnershipBottomTabActiveIcon : partnershipBottomTabIcon}
                style={[styles.tabIcon, { height: 25, width: 24 }]}
            />,
            tabBarButton: (props) => (<TouchableOpacity {...props}
                onPress={() => navigation.navigate('Partnership', {
                    screen: 'PartnershipTabs',
                    params: { screen: 'PartnershipInfo' }
                })}/>),
            title: 'Партнерка'
        }}></Tab.Screen>
        <Tab.Screen name="Account" component={AccountStackScreen} options={{
            tabBarIcon: ({ focused }) => <Image resizeMode='contain'
                source={focused ? accountBottomTabActiveIcon : accountBottomTabIcon}
                style={[styles.tabIcon, { height: 23, width: 26 }]}
            />,
            title: 'Аккаунт'
        }}/>
    </Tab.Navigator>;
}

export default TabsScreen;
