import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, StyleSheet } from 'react-native';

import { deleteAliasMailbox } from '../../../actions/mail/actions';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';
import Errors from '../../General/Form/Errors';

const formId = 'DeleteAliasModalForm';

function DeleteAliasModal(props) {
    const {
        alias,
        mailboxId,
        onClose,
    } = props;
    const dispatch = useDispatch();
    const { changeMailboxInProgress } = useSelector((state) => state.mail);
    const { errors } = useSelector((state) => state.forms);
    const currentErrors = errors[formId] || {};
    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Удаление {alias}</Text>
        <View>
            {currentErrors.alias && <Errors errors={currentErrors.alias}/>}
            <View style={styles.buttonBlock}>
                <AcceptButton
                    onSubmit={() => dispatch(
                        deleteAliasMailbox(formId, mailboxId, alias, () => onClose())
                    )}
                    type='danger'
                    title='Удалить'
                    inProgress={changeMailboxInProgress}/>
                <CancelButton onClose={onClose} disabled={changeMailboxInProgress} />
            </View>
        </View>
    </View>;
}

DeleteAliasModal.propTypes = {
    onClose: PropTypes.func,
    alias: PropTypes.string,
    mailboxId: PropTypes.number,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
    },
});

export default DeleteAliasModal;
