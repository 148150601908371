import { isEmpty } from '../../utils/general';
import { disksIds, blockedButtonTooltips } from '../../constants/general';
import diskData from '../../../data/cvds/disk-types.json';
import tariffsData from '../../../data/cvds/tariffs.json';
import resourcesPrice from '../../../data/cvds/prices.json';
import resourcesData from '../../../data/cvds/resources.json';
import datastorageData from '../../../data/datastorage/prices.json';

export const normalizePrice = (price, normalizePower = 3) => {
    const tenPower = 10 ** normalizePower;
    return Math.floor(price * tenPower) / tenPower;
};

const monthPrice = (price) => price * 24 * 30;

export const calculateBackupPrice = (storage, backup) => monthPrice(resourcesPrice.backup.price * storage * Number(backup));

export const calculateStoragePrice = (
    diskType,
    storage,
    backup,
    id,
    isAdditionalVolume
) => {
    let freeStorage = 0;
    let factor = 1;
    if (id != null && !isAdditionalVolume) {
        freeStorage = resourcesPrice.vm[id].free_storage || 0;
    }
    const factorData = resourcesPrice.vm[id].factor;
    if (factorData) {
        const diskFactorData = factorData.disk && factorData.disk[diskType];
        if (diskFactorData) {
            factor = diskFactorData;
        }
    }
    const storagePrice = resourcesPrice.storage[diskType].price;
    let price = 0;

    price += monthPrice(storagePrice * (storage - freeStorage) * factor);
    price += calculateBackupPrice(storage, backup);
    return price;
};

export const calculateTariffPrice = (id) => monthPrice(normalizePrice(resourcesPrice.vm[id].price, 4));

export const calculateGpuPrice = (gpu, count) => monthPrice(normalizePrice(resourcesPrice.gpu[gpu.id].price)) * count;

export const calculatePrice = (
    id,
    type,
    storage,
    backup,
    currentGpu,
    gpuCount
) => {
    let price = 0;
    const tariff = tariffsData.find((t) => t.type === type);

    price += calculateTariffPrice(id);
    price += monthPrice(resourcesPrice.ip['4'].price);
    price += calculateStoragePrice(tariff.disk.type, storage, backup, id);

    if (tariff.type === 'ultra' && currentGpu && gpuCount) {
        price += calculateGpuPrice(currentGpu, gpuCount);
    }

    return price;
};

export const calculateDatastoragePrice = () => monthPrice(datastorageData.storage['1gb']);

export const isActiveVM = (state) => {
    const vmState = state.toLowerCase();
    return vmState === 'active';
};

export const isActiveOrStoppedVM = (state) => {
    const vmState = state.toLowerCase();
    return vmState === 'active' || vmState === 'stopped';
};

export const isNotWorkingVM = (state) => {
    const vmState = state.toLowerCase();
    return (
        vmState === 'error'
    || vmState === 'stopped'
    || vmState === 'stoppedbyadmin'
    || vmState === 'stoppedbyservice'
    );
};

export const isEnabledFinanceFunctions = (state) => {
    const vmState = state.toLowerCase();
    return vmState !== 'error' && vmState !== 'stoppedbyadmin';
};

export const isTransferStateVM = (state) => {
    const vmState = state.toLowerCase();
    return vmState === 'transfer';
};

export const getTariffName = (tariff) => {
    const currentTariff = tariffsData.find(
        (cvdsTariff) => cvdsTariff.id === tariff
    );
    return currentTariff.name;
};

export const getTariffInfo = (calculation, isDisplayedTariff, needPlusIcon) => {
    const { resources } = calculation;
    const { tariff } = resources.vm;
    const disksInfo = disksIds.map((diskId) => {
        const currentDiskData = diskData.find((diskData) => diskData.id === diskId);
        let diskInfoName = `Диск ${currentDiskData.short} `;
        if (needPlusIcon) {
            diskInfoName += `(+${resources.storage[diskId].count} ГБ)`;
        } else if (tariff === 'tiny' && diskId !== 'slow') {
            diskInfoName += `(${
                resources.storage[diskId].count !== 0
                    ? `${resources.storage[diskId].count} ГБ + `
                    : ''
            }5 ГБ бесплатно)`;
        } else {
            diskInfoName += `(${resources.storage[diskId].count} ГБ)`;
        }
        return {
            name: diskInfoName,
            price: resources.storage[diskId].cost,
            isDisplayed: resources.storage[diskId].count !== 0,
        };
    });
    return [
        {
            name: `Тариф «${getTariffName(tariff)}»`,
            price: resources.vm.cost,
            isDisplayed: isDisplayedTariff == null ? true : isDisplayedTariff,
        },
        {
            name: `IP-адрес (${needPlusIcon ? '+' : ''}${resources.ips.count} шт)`,
            price: resources.ips.cost,
            isDisplayed: resources.ips.count !== 0,
        },
        ...disksInfo,
        {
            name: `Бэкапы (${needPlusIcon ? '+' : ''}${resources.backup.count} ГБ)`,
            price: resources.backup.cost,
            isDisplayed: resources.backup.count !== 0,
        },
        {
            name: `Защита от DDoS (${resources.bandwidth.count} Мбит/с)`,
            price: resources.bandwidth.cost,
            isDisplayed: resources.bandwidth.count !== 0,
        },
    ];
};

export const roundPrice = (value, power = 2) => {
    const factor = 10 ** power;
    return Math.round(value * factor) / factor;
};

export const getValueFromPercent = (value, percent) => roundPrice((value * 100) / (100 - percent));

export const getPriceForPacket = (price) => roundPrice(price).toFixed(2);

export const getDiskNameById = (id) => diskData.find((diskData) => diskData.id === id).name;

export const getPacketInfo = (calculation) => {
    const { packet } = calculation;
    const { resources } = packet;
    const tariff = resources.vm;
    const currentTariff = tariffsData.find(
        (cvdsTariff) => cvdsTariff.id === tariff
    );
    let packetInfo = `Тариф «${currentTariff.name}», `;
    disksIds.forEach((diskId) => {
        if (resources.storage[diskId] !== 0) {
            packetInfo += `${getDiskNameById(diskId)} ${
                resources.storage[diskId]
            } ГБ, `;
        }
    });
    if (resources.ips) {
        packetInfo += `${resources.ips} IP`;
    }
    if (resources.backup) {
        packetInfo += ', бэкап VM';
    }
    if (resources.bandwidth) {
        packetInfo += `, защита от DDoS ${resources.bandwidth} Мбит/с`;
    }
    return packetInfo;
};

export const calculateIPPrice = (count, type) => monthPrice(resourcesPrice.ip[type.toString()].price * count);

export const calculateBandwidthPrice = (bandwidth) => {
    if (!bandwidth) {
        return 0;
    }
    return monthPrice(resourcesPrice.bandwidth[bandwidth.toString()].price);
};

export const findStep = (steps, current) => {
    for (let i = 0; i < steps.length - 1; i += 1) {
        const [currentLimit, currentStep] = steps[i];
        const [nextLimit] = steps[i + 1];
        if (current >= currentLimit && current < nextLimit) {
            return currentStep;
        }
    }
    return steps[steps.length - 1][1];
};

export const getStorageScale = (tariff) => {
    const diskInfo = diskData.find((disk) => disk.id === tariff.disk.type);
    const [min, max] = diskInfo.limits[tariff.type];
    let step = findStep(resourcesData.disk.steps, min);
    const values = [];
    for (let value = min; value <= max; value += step) {
        step = findStep(resourcesData.disk.steps, value);
        values.push(value);
    }
    if (values[values.length - 1] < max) {
        values.push(max);
    }
    return values;
};

export const getCurrentTariffObject = (tariff, currentTariff, imageSize) => {
    let minStorageSize = currentTariff != null ? currentTariff.minStorageSize : 0;
    if (imageSize != null) {
        const diskInfo = diskData.find(
            (disk) => disk.id === currentTariff.disk.type
        );
        const storageScale = getStorageScale(currentTariff, diskInfo);
        for (let i = 0; i < storageScale.length; i += 1) {
            if (storageScale[i] >= imageSize) {
                minStorageSize = storageScale[i];
                break;
            }
        }
    }
    const isChangedStorageSize = minStorageSize && tariff.features.storage < minStorageSize;
    return {
        ...tariff,
        minStorageSize,
        features: {
            ...tariff.features,
            storage: isChangedStorageSize ? minStorageSize : tariff.features.storage,
        },
    };
};

export const isErrorStateVM = (state) => {
    const vmState = state.toLowerCase();
    return vmState === 'error';
};

export const makeDiscount = (price, discount) => price * ((100 - discount) / 100);

export const canDeleteVM = (state) => {
    const vmState = state.toLowerCase();
    return (
        vmState === 'active'
    || vmState === 'stopped'
    || vmState === 'stoppedbyservice'
    );
};

export const calculateTotalPercent = ({ fs_used_pct, fs_reserved_pct }) => fs_used_pct + fs_reserved_pct;

export const getNormalizedIPOptions = (ips, version) => {
    const options = [];
    if (ips && !isEmpty(ips.entities)) {
        ips.entities.forEach((item, i) => {
            if (item.version === version) {
                options.push({
                    value: item.ip,
                    label: item.ip,
                    index: options.length,
                });
            }
        });
    }
    return options;
};

export const getButtonTooltip = (state) => blockedButtonTooltips.cvds[state];
