import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';
import { bindActionCreators } from 'redux';

import Errors from '../../General/Form/Errors';
import { flushErrors } from '../../../actions/forms/actions';
import WarningCancelPacketBlock from '../WarningCancelPacketBlock';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'VolumeChangeBackupForm';

function BackupModal(props) {
    const {
        errors,
        onAccept,
        onClose,
        flushErrors,
        inProgress,
        awaitingPacket
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Отключение резервного копирования</Text>
        <Text style={styles.text}>
            При отключении резервного копирования диска&nbsp;
            <Text style={[styles.text, styles.warningText]}>
                все существующие резервные копии будут удалены
            </Text>
        </Text>
        {awaitingPacket && <View style={styles.packetWarning}>
            <WarningCancelPacketBlock mainText='У вас заказан годовой пакет для
                ВМ с резервным копированием. Выключение опции приведет к'
            warningText='отмене заказа годового пакета' />
        </View>}
        {errors.__all__ && <Errors errors={errors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                type='danger'
                onSubmit={onAccept}
                title="Отключить"
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
};

BackupModal.propTypes = {
    onClose: PropTypes.func,
    onAccept: PropTypes.func,
    errors: PropTypes.shape({}),
    inProgress: PropTypes.bool,
    awaitingPacket: PropTypes.bool,
    flushErrors: PropTypes.func,
};

const mapStateToProps = (state) => ({
    errors: state.forms.errors[formId] || {},
    inProgress: state.cvds.backupSwitchInProgress,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    text: {
        fontSize: 12,
        lineHeight: 16,
        color: '#455562',
        minWidth: 270
    },
    warningText: {
        color: '#c80502',
        fontWeight: '600'
    },
    buttonBlock: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    packetWarning: {
        marginTop: 10
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(BackupModal);
