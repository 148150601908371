import { roundPrice } from '@netangels/core/utils/price';

import { tariffs } from '../../constants/hosting';
import {
    phpIcon,
    rubyIcon,
    execIcon,
    nodeIcon,
    pythonIcon,
} from '../../icons/index';
import envData from '../../data/hosting/environments.json';

export const normalizeContainerPrice = value => Math.ceil(value).toFixed(0);

const unwrapApplicationMemory = (entity, applicationName, defaultValue = 0) => (
    entity[applicationName] && entity[applicationName].memory_limit
) || defaultValue;

export const unwrapRedisApplicationMemory = (container, defaultValue = 0) => unwrapApplicationMemory(container, 'redis', defaultValue);
export const unwrapMemcachedApplicationMemory = (container, defaultValue = 0) => unwrapApplicationMemory(container, 'memcache', defaultValue);

export const getPriceForPacket = price => roundPrice(price).toFixed(2);

export const getValueFromPercent = (value, percent) => roundPrice((value * 100) / (100 - percent));

export const getEnginesList = (env) => {
    const normalizedEnv = env.toLowerCase();
    const data = envData[normalizedEnv];
    return [
        {
            type: 'php',
            name: 'PHP',
            versions: `${data.php.min}–${data.php.max}`,
            icon: phpIcon,
        }, {
            type: 'python',
            name: 'Python',
            versions: data.python
                ? `${data.python['3'].min}-${data.python['3'].max}, ${data.python['2'].max}`
                : '3.5–3.8, 2.7',
            icon: pythonIcon,
        }, {
            type: 'node',
            name: 'NodeJS',
            versions: data.nodejs ? `${data.nodejs.min}-версии` : 'LTS-версии',
            icon: nodeIcon,
        }, {
            type: 'ruby',
            name: 'Ruby',
            versions: data.ruby ? `${data.ruby['2'].min}-${data.ruby['2'].max}, ${data.ruby['1'].min}` : '2.1–2.5, 1.9',
            icon: rubyIcon,
        }, {
            type: 'exec',
            name: 'EXE',
            versions: 'Go, C#, и др.',
            icon: execIcon,
        }
    ];
};

export const detectTariffByRam = (memory) => {
    for (const tariff of tariffs) {
        if (memory <= tariff.ram) {
            return tariff;
        }
    }
    return tariffs[tariffs.length - 1];
};