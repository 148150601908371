import React from 'react';
import { useSelector } from 'react-redux';
import {
    View, StyleSheet, Text, Image
} from 'react-native';

import Button from '../General/Button/Button';
import Modal from '../General/Modal/Modal';
import { launchMailIcon } from '../../icons';
import CreateDomainModal from './Modals/CreateDomainModal';

function MailLaunchPage() {
    const { userInfo, domainsInfo } = useSelector((state) => state.mail);
    const [isCreateModalShowed, setCreateModalShowed] = React.useState(false);

    return <View style={styles.wrapper}>
        <Image source={launchMailIcon} style={styles.image} />
        <View style={styles.content}>
            <Text style={styles.title}>Управляйте почтой на своем домене</Text>
            <Text style={styles.description}>
                {userInfo && userInfo.free_quota !== 0
                    ? `Вам доступно ${
                        userInfo.free_quota / 1024
                    } Гб бесплатного места для хранения почты и неограниченное количество почтовых ящиков.`
                    : 'Услуга почты предоставляется только при заказе услуги Хостинг или VDS'}
            </Text>
        </View>
        <Button title='Создать почтовый домен'
            disabled={!(domainsInfo && domainsInfo.can_activate)}
            onPress={() => setCreateModalShowed(true)}
            additionalWrapperStyles={[{ width: '100%' }]}
            additionalButtonStyles={[{
                paddingTop: 11,
                paddingBottom: 13,
                marginLeft: 22,
                marginRight: 22,
            }]}
            additionalButtonTextStyles={[{
                fontSize: 14,
                lineHeight: 16,
                fontWeight: '400',
            }]}/>
        <Modal isModalShowed={isCreateModalShowed}
            component={<CreateDomainModal
                onClose={() => setCreateModalShowed(false)}/>}
            onClose={() => setCreateModalShowed(false)} />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        margin: 0,
        paddingTop: 51,
        paddingLeft: 10,
        paddingRight: 10,
        flex: 1,
        width: '100%',
        flexDirection: 'colume',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        minHeight: 195,
        minWidth: 300,
        marginBottom: 32,
    },
    title: {
        width: 220,
        textAlign: 'center',
        fontSize: 16,
        color: '#455562',
        letterSpacing: 0,
        lineHeight: 19,
        marginBottom: 12,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    description: {
        width: 268,
        fontSize: 12,
        textAlign: 'center',
        letterSpacing: 0,
        color: '#455562',
        lineHeight: 18,
        marginBottom: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

export default MailLaunchPage;
