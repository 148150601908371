import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

import { ResourcePropType } from './types';
import { backgroundColorsMap } from './styles';
import { isFunction, getColorType } from '../../../utils/general';

const styles = StyleSheet.create({
    resourcesBlock: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    resource: {
        flex: 1,
        flexDirection: 'row',
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        paddingRight: 9,
        marginBottom: 7,
        marginRight: 8
    },
    resourceTitle: {
        fontSize: 11,
        fontWeight: '500',
        lineHeight: 13,
        color: '#ffffff'
    },
    resourcePrice: {
        fontSize: 11,
        fontWeight: '500',
        lineHeight: 13,
        color: '#ffffff',
        marginLeft: 8
    },
    resourcesTitle: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '600',
        lineHeight: 14,
        marginBottom: 9
    },
    resourceColorBox: {
        width: 8,
        height: 8,
        marginRight: 8,
    },
});

function getResourceColorType(resource, index) {
    return resource.colorType || getColorType(index);
}

function getResources({
    resources,
    current,
    titleStyle,
    valueStyle,
    needColorBox,
    resourceStyle,
    needBackground,
}) {
    return resources.map((resource, i) => {
        const { onClick } = resource;
        const isClickable = isFunction(onClick);
        if (resource.value === 0) {
            return <View />;
        }
        const resourceStyles = [
            styles.resource,
            resourceStyle,
        ];
        const colorType = getResourceColorType(resource, i);
        let backgroundColor = '';
        if (colorType.includes('#')) {
            backgroundColor = colorType;
            resourceStyles.push({ backgroundColor });
        } else {
            backgroundColor = backgroundColorsMap[colorType].backgroundColor;
            resourceStyles.push({ backgroundColor });
        }
        if (current && resource.id !== current) {
            resourceStyles.push(backgroundColorsMap.darkened);
        }
        if (!needBackground) {
            resourceStyles.push({ backgroundColor: 'transparent' });
        }
        return <TouchableOpacity activeOpacity={0.6} onPress={(e) => {
            if (isClickable) {
                onClick(resource, e);
            }}} key={`resource-item-${i}`}>
            <View style={resourceStyles}>
                {needColorBox && <Text style={[styles.resourceColorBox, { backgroundColor }]}/>}
                <Text style={[styles.resourceTitle, titleStyle]}>{resource.title}</Text>
                <Text style={[styles.resourcePrice, valueStyle]}>{
                    resource.formatValue(resource.value)
                }</Text>
            </View>
        </TouchableOpacity>;
    });
}

function HorizontalBarChartLegend(props) {
    const {
        total,
        current,
        resources = [],
        withLegend = true,
        hideTotal = false,
        formatTotal = v => `Всего ${v}`,
        additionalStyles = {},
        needColorBox = false,
        needBackground = true,
        titleStyle = {},
        valueStyle = {},
        resourceStyle = {},
    } = props;

    return <View>
        <View style={[styles.resourcesBlock, additionalStyles]}>{withLegend
            && getResources({
                resources,
                current,
                needColorBox,
                needBackground,
                titleStyle,
                valueStyle,
                resourceStyle,
            })}</View>
        {!hideTotal && <Text style={styles.resourcesTitle}>{formatTotal(total)}</Text>}
    </View>;
}

HorizontalBarChartLegend.propTypes = {
    resources: PropTypes.arrayOf(ResourcePropType),
    current: PropTypes.string,
    formatTotal: PropTypes.func,
    withLegend: PropTypes.bool,
    hideTotal: PropTypes.bool,
    total: PropTypes.number,
    needColorBox: PropTypes.bool,
    needBackground: PropTypes.bool,
    titleStyle: PropTypes.shape({}),
    valueStyle: PropTypes.shape({}),
    resourceStyle: PropTypes.shape({}),
    additionalStyles: PropTypes.shape({}),
};

export default HorizontalBarChartLegend;
