import React from 'react';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet, Text, View } from 'react-native';

import { makeMonitoringSolutionLink } from '../../utils/monitoring';

function MonitoringEventLink(props) {
    const {
        link,
        title,
        metadata
    } = props;
    const navigation = useNavigation();

    const navigationParams = makeMonitoringSolutionLink(
        link,
        metadata,
    );
    return (
        <View
            // onPress={() => navigation.navigate(navigationParams.link,
            //     navigationParams.params)}
        >
            <Text style={styles.text}>{title}</Text>
        </View>
    );
}

MonitoringEventLink.dynamicName = 'MonitoringEventLink';

MonitoringEventLink.propTypes = {
    link: PropTypes.string,
    title: PropTypes.string,
    metadata: PropTypes.shape({}),
};

const styles = StyleSheet.create({
    text: {
        color: '#455562',
        fontSize: 10,
        fontWeight: '400',
        lineHeight: 14,
        opacity: 0.8
    },
});

export default MonitoringEventLink;
