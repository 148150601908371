import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Animated, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    circle: {
        width: 6,
        borderRadius: '50%',
        height: 6,
    },
    white: {
        backgroundColor: '#ffffff',
    },
    blue: {
        backgroundColor: '#0f79d5',
    },
    danger: {
        backgroundColor: '#c60502',
    },
});

function LoadingCircle({
    startingOpacity,
    startingDuration = 1000,
    startFunc = 'fadeIn',
    type = 'blue',
}) {
    const fadeAnim = useRef(new Animated.Value(startingOpacity)).current;
    let fadeOutTimeoutId = null;
    let fadeInTimeoutId = null;
    const duration = 700;
    const [isStartingAnimation, setIsStartingAnimation] = useState(true);

    const fadeIn = (isStarting) => {
        const currentDuration = isStarting ? startingDuration : duration;
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: currentDuration,
            useNativeDriver: true,
        }).start();
        fadeOutTimeoutId = setTimeout(() => {
            fadeOut();
        }, currentDuration);
    };

    const fadeOut = (isStarting) => {
        const currentDuration = isStarting ? startingDuration : duration;
        Animated.timing(fadeAnim, {
            toValue: 0.6,
            duration: currentDuration,
            useNativeDriver: true,
        }).start();
        fadeInTimeoutId = setTimeout(() => {
            fadeIn();
        }, currentDuration);
    };
    useEffect(() => {
        if (startFunc === 'fadeIn') {
            fadeIn(isStartingAnimation);
        } else {
            fadeOut(isStartingAnimation);
        }
        setIsStartingAnimation(false);
        return () => {
            if (fadeOutTimeoutId != null) {
                clearTimeout(fadeOutTimeoutId);
                fadeOutTimeoutId = null;
            }
            if (fadeInTimeoutId != null) {
                clearTimeout(fadeInTimeoutId);
                fadeInTimeoutId = null;
            }
        };
    }, []);
    return <Animated.View style={[
        styles.circle, styles[type], { opacity: fadeAnim }
    ]} />;
}

LoadingCircle.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    startingOpacity: PropTypes.number,
    startFunc: PropTypes.string,
    startingDuration: PropTypes.number,
};

export default LoadingCircle;
