import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { getValueFromPercent } from '@netangels/core/utils/price';
import { View, StyleSheet, Text, ImageBackground } from 'react-native';

import { annualIcon } from '../../../icons';
import { detectTariffByRam } from '../utils';
import Modal from '../../General/Modal/Modal';
import Button from '../../General/Button/Button';
import { blockedButtonTooltips } from '../../../constants/general';
import HostingCreateAnnualModal from '../HostingModal/HostingCreateAnnualModal';
import HostingUpdateAnnualModal from '../HostingModal/HostingUpdateAnnualModal';
import { formatPrice } from '../../../utils/general';


function packetDescription(packet) {
    const transformValue = v => parseInt(v, 10);
    const tariff = detectTariffByRam(transformValue(packet.memory));
    const storageSize = transformValue(packet.disk) / 1024;
    let tariffDescription = `Тариф «${tariff.title}», Диск ${storageSize} ГБ`;
    if (packet.redis > 0) {
        tariffDescription = `${tariffDescription}, Redis ${packet.redis} МБ`;
    }
    if (packet.memcached > 0) {
        tariffDescription = `${tariffDescription}, Memcached ${packet.memcached} МБ`;
    }
    return tariffDescription;
}

function ContainerAnnual(props) {
    const { calculationData, isEnabled } = props;

    const annual = calculationData && calculationData.packet;
    const isOverPacket = calculationData && calculationData.monthPrice > 0;
    const [isCreateAnnual, setIsCreateAnnual] = React.useState(false);
    const [isUpdateAnnual, setIsUpdateAnnual] = React.useState(false);
    const fullCost = getValueFromPercent(calculationData.currentPacketCost, 10);
    const paidTill = annual ? `${dayjs(annual.timeTo).format('DD MMMM YYYY')} г.` : '';

    return <View style={styles.annualBlock}>
        {annual && <View>
            <Text style={styles.title}>Годовой пакет подключен</Text>
            <Text style={styles.discountPrice}>{formatPrice(calculationData) && formatPrice(calculationData.packet.cost)} ₽/год</Text>
            {!isOverPacket && <Text style={styles.hint}>Пакет для текущей конфигурации<br/>контейнера&nbsp;
                <Text style={styles.greenText}>
                    оплачен до&nbsp;{paidTill}
                </Text>
            </Text>}
            {isOverPacket && <Text style={styles.hint}>
                {packetDescription(calculationData.packet)}
                <Text style={styles.greenText}>
                    &nbsp;оплачен до&nbsp;{paidTill}
                </Text>
            </Text>}
            {isOverPacket && <Button title='Обновить годовой пакет'
                disabled={!isEnabled}
                disabledText={blockedButtonTooltips.hosting.containerDisabled}
                additionalWrapperStyles={[{ paddingLeft: 18, marginBottom: 20, marginRight: 18 }]}
                additionalButtonStyles={[{ paddingTop: 8, paddingBottom: 9, paddingRight: 20, paddingLeft: 20 }]}
                additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15 }]}
                onPress={() => setIsUpdateAnnual(true)}/>}
        </View>}
        {!annual && <ImageBackground source={annualIcon} style={styles.icon}>
            <Text style={styles.title}>
                <Text style={styles.blueText}>Скидка 10%</Text>&nbsp;на текущую конфигурацию
                контейнера при оплате за год</Text>
            <Text style={styles.discountPrice}>{formatPrice(calculationData.currentPacketCost)} ₽/мес</Text>
            <Text style={styles.hint}>
                Вместо {formatPrice(fullCost)} ₽/год при помесячной оплате
            </Text>
            <Button title='Заказать годовой пакет'
                disabled={!isEnabled}
                disabledText={blockedButtonTooltips.hosting.containerDisabled}
                additionalWrapperStyles={[{ width: 220, paddingLeft: 18, }]}
                additionalButtonStyles={[{ paddingTop: 8, paddingBottom: 9, paddingRight: 20, paddingLeft: 20 }]}
                additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15 }]}
                onPress={() => setIsCreateAnnual(true)}/>
        </ImageBackground>}
        <Modal isModalShowed={isUpdateAnnual}
            component={<HostingUpdateAnnualModal
                calculation={calculationData}
                onClose={() => setIsUpdateAnnual(false)}/>}
            onClose={() => setIsUpdateAnnual(false)} />
        <Modal isModalShowed={isCreateAnnual}
            component={<HostingCreateAnnualModal
                calculation={calculationData}
                onClose={() => setIsCreateAnnual(false)}/>}
            onClose={() => setIsCreateAnnual(false)} />
    </View>;
}

ContainerAnnual.propTypes = {
    isEnabled: PropTypes.bool,
    calculationData: PropTypes.shape({}),
};

const styles = StyleSheet.create({
    annualBlock: {
        paddingTop: 24,
        paddingBottom: 0,
        backgroundColor: '#ffffff',
        marginTop: 14,
    },
    blueText: {
        fontWeight: '500',
        color: '#0F79D5',
    },
    greenText: {
        fontWeight: '500',
        color: '#11ab70',
    },
    title: {
        fontSize: 12,
        lineHeight: 16,
        paddingLeft: 18,
        paddingRight: 18,
    },
    discountPrice: {
        color: '#455562',
        fontWeight: '500',
        fontSize: 18,
        lineHeight: 21,
        marginTop: 10,
        marginBottom: 4,
        paddingLeft: 18,
        paddingRight: 18,
    },
    hint: {
        fontSize: 11,
        lineHeight: 14,
        color: '#455562',
        marginBottom: 20,
        paddingLeft: 18,
        paddingRight: 18,
    },
    icon: {
        height: 211,
    }
})

export default ContainerAnnual;