import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, Platform, Image, TouchableOpacity } from 'react-native';

import {
    arrowDownIcon
} from '../../../icons/index';
import {
    containerListCapabilities
} from '../../../actions/hosting/actions';
import Modal from '../../General/Modal/Modal';
import WebContainerSelectModal from './WebContainerSelectModal';
import MobileContainerSelectModal from './MobileContainerSelectModal';


function ContainerSelect(props) {
    const {
        currentContainer,
    } = props;
    const [isModalChangeContainer, setModalChangeContainerVisible] = React.useState(false);
    return <View style={styles.wrapper}>
        <Text style={styles.label}>Контейнер</Text>
        <TouchableOpacity style={styles.selectBlock}
            onPress={() => setModalChangeContainerVisible(true)}>
            <Text style={styles.selectLabel}>{currentContainer.label}</Text>
            <Image source={arrowDownIcon} style={styles.icon} />
        </TouchableOpacity>
        <Modal isModalShowed={isModalChangeContainer}
            component={Platform.select({
                ios: <MobileContainerSelectModal {...props}
                    onClose={() => setModalChangeContainerVisible(false)}/>,
                android: <MobileContainerSelectModal {...props} 
                    onClose={() => setModalChangeContainerVisible(false)}/>,
                web: <WebContainerSelectModal {...props}
                    onClose={() => setModalChangeContainerVisible(false)}/>,
                default: <WebContainerSelectModal {...props}
                    onClose={() => setModalChangeContainerVisible(false)}/>,
            })}
            onClose={() => setModalChangeContainerVisible(false)} />
    </View>;
}

ContainerSelect.propTypes = {
    containers: PropTypes.shape({}),
    currentContainer: PropTypes.shape({}),
    onChange: PropTypes.func,
    containerListCapabilities: PropTypes.func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    containerListCapabilities
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 24,
    },
    label: {
        fontSize: 11,
        lineHeight: 14,
        marginBottom: 6,
        color: '#455562',
    },
    error: {
        color: '#d06565',
        marginTop: 4,
    },
    blueText: {
        color: '#0f79d5',
    },
    grayText: {
        color: '#455562',
    },
    icon: {
        width: 13,
        height: 7,
        marginRight: 20,
    },
    selectBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'baseline',
        borderBottomColor: '#dce2e9',
        borderBottomWidth: 1,
        marginBottom: 29,
    },
    selectLabel: {
        flex: 1,
        fontSize: 12,
        lineHeight: 19,
        paddingBottom: 10,
        color: '#455562',
    },
})


export default connect(mapStateToProps, mapDispatchToProps)(ContainerSelect);
