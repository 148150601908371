import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';  
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import VirtualhostFilesTab from './VirtualhostFilesTab';
import Virtualhost from './Virtualhost/VirtualhostPage';
import ApplicationTab from './Virtualhost/ApplicationTab';

const VirtualhostTab = createMaterialTopTabNavigator();

function VirtualhostTabs(props) {
    const { route } = props;
    return <VirtualhostTab.Navigator
        tabBarOptions={{
            upperCaseLabel: false,
            activeTintColor: 'rgba(15,121,213,1)',
            inactiveTintColor: 'rgba(69,85,98,0.7)',
            tabStyle: {
                width: 80,
                padding: 0,
            },
            labelStyle: {
                letterSpacing: 1.13,
                fontSize: 14, 
                fontWeight: '500',
                letterSpacing: 0,
                lineHeight: 16,
                textTransform: 'none',
            },
            indicatorStyle: {
                backgroundColor: '#0F79D5',
                height: 3,
            },
            style: {
                marginBottom: 10,
            }
        }}>
        <VirtualhostTab.Screen name="HostingVirtualhost" component={Virtualhost}
            options={{ tabBarLabel: 'Сайт' }}
            initialParams={{ virtualhostId: route.params.virtualhostId, virtualhostName: route.params.virtualhostName }}/>
        <VirtualhostTab.Screen name="HostingVirtualhostAccess" component={VirtualhostFilesTab} options={{
            tabBarLabel: 'Файлы'
        }} />
        <VirtualhostTab.Screen name="HostingVirtualhostApplications" component={ApplicationTab} options={{
            tabBarLabel: 'Приложения'
        }} />
    </VirtualhostTab.Navigator>;
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VirtualhostTabs);
