import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import Input from '../../General/Form/Input';
import { flushErrors } from '../../../actions/forms/actions';
import InvoiceActionsModal from '../FinanceDocumentsTab/InvoiceActionsModal';
import { createInvoice } from '../../../actions/finances/actions';
import AcceptButton from '../../General/Modal/AcceptButton';

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        marginTop: 30,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    acceptButton: {
        width: '100%',
        borderRightWidth: 0,
    },
    blueText: {
        color: '#0f79d5',
        fontWeight: '500',
    },
});

const formId = 'InvoiceModalForm';

function InvoiceModal(props) {
    const {
        onClose,
        inProgress,
        flushErrors,
        createInvoice,
        errors
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);
    const [amount, setAmount] = React.useState('');
    const [currentInvoice, setCurrentInvoice] = React.useState(null);

    return <React.Fragment>
        {currentInvoice == null && <View style={[styles.wrapper]}>
            <Text style={[styles.title]}>Новый счет</Text>
            <Input
                label='Введите сумму платежа'
                errors={errors.amount}
                textInputAttrs={{
                    onChangeText: (text) => {
                        setAmount(text);
                    },
                    value: amount
                }}
            />
            <View style={styles.buttonBlock}>
                <AcceptButton
                    additionalButtonStyles={[styles.acceptButton]}
                    onSubmit={() => createInvoice(formId, Number.parseInt(amount, 10), (data) => {
                        setCurrentInvoice(data);
                    })}
                    title='Сформировать счет'
                    inProgress={inProgress}/>
            </View>
        </View>}
        {currentInvoice != null && <InvoiceActionsModal invoice={currentInvoice}
            onClose={() => {
                onClose();
            }}/>}
    </React.Fragment>;
}

InvoiceModal.propTypes = {
    flushErrors: PropTypes.func,
    createInvoice: PropTypes.func,
    errors: PropTypes.shape({}),
    onClose: PropTypes.func,
    inProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    inProgress: state.invoices.createInvoiceInProgress,
    errors: state.forms.errors[formId] || {}
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    createInvoice, flushErrors
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceModal);