import React, { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import Input from '../../General/Form/Input';
import RadioButton from '../../General/Form/RadioButton';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';
import { flushErrors } from '../../../actions/forms/actions';
import pricesMap from '../../../../data/certificates/prices.json';
import { orderCertificate } from '../../../actions/certificates/actions';

const formId = 'DeleteAliasModalForm';

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18,
    },
    title: {
        marginBottom: 24,
        color: '#455562',
        fontSize: 18,
        lineHeight: 20,
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 32,
    },
    radioButtonBlock: {
        paddingTop: 16,
        paddingLeft: 15,
        paddingBottom: 14,
        alignItems: 'center',
    },
    radioButtonLabel: {
        fontWeight: '500',
        marginBottom: 4,
        marginLeft: 4,
    }
});

function OrderCertificateModal(props) {
    const { defaultPublisher = 'LetsEncrypt', onClose } = props;
    const { orderCertificateInProgress } = useSelector(
        (state) => state.certificates
    );
    const { errors } = useSelector((state) => state.forms);
    const currentErrors = errors[formId] || {};

    const dispatch = useDispatch();
    const navigation = useNavigation();

    const [publisher, setPublisher] = useState(defaultPublisher);

    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);

    return (
        <View style={styles.wrapper}>
            <Text style={styles.title}>Заказ SSL-сертификата</Text>
            <Formik
                initialValues={{
                    domain: '',
                }}
                onSubmit={(values) => {
                    const { domain } = values;
                    const requestData = {
                        domains: [domain],
                        publisher,
                    };
                    dispatch(
                        orderCertificate(formId, requestData, (certificate) => {
                            onClose();
                            navigation.navigate('SSLDetail', {
                                certificateId: certificate.id,
                            });
                        })
                    );
                }}
            >
                {({
                    handleChange, handleBlur, handleSubmit, values
                }) => (
                    <View>
                        <Input
                            errors={currentErrors.domains}
                            additionalWrapperStyles={[{ marginBottom: 22 }]}
                            additionalInputStyles={[{ fontSize: 12 }]}
                            label="Домен, для которого заказывается сертификат"
                            textInputAttrs={{
                                onChangeText: handleChange('domain'),
                                onBlur: handleBlur('domain'),
                                value: values.domain,
                                placeholder: 'mydomain.com',
                            }}
                        />
                        <RadioButton
                            needBorder={true}
                            additionalLabelStyles={[styles.radioButtonLabel]}
                            additionalWrapperStyles={[{ marginBottom: 8 }]}
                            additionalRadioButtonBlockStyles={[styles.radioButtonBlock]}
                            onChange={() => setPublisher('AlphaSSL')}
                            isActive={publisher === 'AlphaSSL'}
                            label="AlphaSSL на 1 год"
                            sublabel={`${pricesMap.AlphaSSL.single} ₽`}
                        />
                        <RadioButton
                            needBorder={true}
                            additionalLabelStyles={[styles.radioButtonLabel]}
                            additionalRadioButtonBlockStyles={[styles.radioButtonBlock]}
                            onChange={() => setPublisher('LetsEncrypt')}
                            isActive={publisher === 'LetsEncrypt'}
                            label="Let’s Encrypt на 3 месяца"
                            sublabel='Бесплатно'
                        />
                        <View style={styles.buttonBlock}>
                            <AcceptButton
                                onSubmit={handleSubmit}
                                title="Заказать"
                                type="blue"
                                disabled={values.domain.length === 0}
                                inProgress={orderCertificateInProgress}
                            />
                            <CancelButton
                                onClose={onClose}
                                disabled={orderCertificateInProgress}
                            />
                        </View>
                    </View>
                )}
            </Formik>
        </View>
    );
}

OrderCertificateModal.propTypes = {
    onClose: PropTypes.func,
    defaultPublisher: PropTypes.string,
};

export default OrderCertificateModal;
