export const NOTIFICATIONS_IN_PROGRESS = 'NOTIFICATIONS_IN_PROGRESS';
export const NOTIFICATIONS_SUCCESSFULLY = 'NOTIFICATIONS_SUCCESSFULLY';
export const NOTIFICATIONS_WITH_ERRORS = 'NOTIFICATIONS_WITH_ERRORS';

export const NOTIFICATIONS_MARK_SUCCESSFULLY = 'NOTIFICATIONS_MARK_SUCCESSFULLY';
export const NOTIFICATIONS_MARK_WITH_ERRORS = 'NOTIFICATIONS_MARK_WITH_ERRORS';

export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE';

export const NOTIFICATION_MOMENT = 'NOTIFICATION_MOMENT';
export const NOTIFICATION_MOMENT_DELETE = 'NOTIFICATION_MOMENT_DELETE';

export const CUSTOM_NOTIFICATION = 'CUSTOM_NOTIFICATION';
export const CUSTOM_NOTIFICATION_DELETE = 'CUSTOM_NOTIFICATION_DELETE';

export const ALL_NOTIFICATIONS_MARKED_SUCCESSFULLY = 'ALL_NOTIFICATIONS_MARKED_SUCCESSFULLY';
