import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, Image } from 'react-native';


import Modal from '../../General/Modal/Modal';
import {
    forceHTTP,
    certificateOrder,
    certificatesCreate,
    certificatesUpdate
} from '../../../actions/hosting/actions';
import {
    certificatesFind,
    certificateDetail,
} from '../../../actions/certificates/actions';
import Switch from '../../General/Switch/Switch';
import SelectModal from '../../General/SelectModal/SelectModal';
import SSLFreeOrder from '../HostingModal/SSLFreeOrder';
import { blockedButtonTooltips } from '../../../constants/general';

function getCertificates(certificate, certificates) {
    if (certificates && certificates.entities) {
        if (certificate != null) {
            const isCertificateInList = certificates.entities.find(
                cert => cert.id === certificate.id
            );
            if (!isCertificateInList) {
                return certificates.entities.concat(certificate);
            }
        }
        return certificates.entities;
    }
    if (certificate != null) {
        return [certificate];
    }
    return null;
}

function normalizedCertificatesList(certificatesList, setModalAddSSL, certificatesUpdate, virtualhostId) {
    const certificateNormalize = [
        {
            value: -1,
            label: 'Подключить бесплатный',
            index: 0,
            onSelected: () => setModalAddSSL(true)
        }, 
        // {
        //     value: -2,
        //     label: 'Загрузить свой',
        //     index: 1,
        //     onSelected: () => {}
        // }
    ];
    if (certificatesList) {
        certificatesList.map((item, i) => {
            certificateNormalize.push({
                label: item.name,
                value: item.id,
                index: i + 1,
                onSelected: () => certificatesUpdate(virtualhostId, item.id)
            })
        });
    }
    return certificateNormalize;
}

function SSLBlock(props) {
    const {
        domains,
        forceHTTP,
        isForceHTTP,
        certificate,
        certificates,
        virtualhostId,
        certificateId,
        certificatesFind,
        certificateDetail,
        certificatesUpdate,
        virtualhostEnabled,
        certificatesFetchInProgress,
    } = props;
    const [currentSelectItem, setChangeCurrentSelectItem] = React.useState({
        value: '',
        label: '',
        index: 1
    });
    React.useEffect(() => {
        if (!certificatesFetchInProgress && (certificates == null) && domains != null) {
            certificatesFind(domains);
        }
        if (certificate == null) {
            certificateDetail(certificateId, (data) => setChangeCurrentSelectItem({
                value: data.id,
                label: data.name,
                index: 1
            }));
        }
        return () => {}
    }, [certificates]);
    const [isModalAddSSL, setModalAddSSL] = React.useState(false);
    const certificatesList = getCertificates(certificate, certificates);
    const normalizeCertificatesList = normalizedCertificatesList(certificatesList, setModalAddSSL, certificatesUpdate, virtualhostId);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>SSL-сертификат</Text>
        <View style={styles.ssl}>
            <SelectModal label='Сертификат'
                onChange={() => {}}
                disabled={!virtualhostEnabled}
                disabledText={blockedButtonTooltips.hosting.virtualhostDisabled}
                currentItem={currentSelectItem}
                itemList={normalizeCertificatesList}/>
            <Switch label='Переадресация на HTTPS'
                isEnable={isForceHTTP}
                disabled={!virtualhostEnabled}
                disabledText={blockedButtonTooltips.hosting.virtualhostDisabled}
                onChange={() => forceHTTP('ForceHTTPAction', virtualhostId, !isForceHTTP)}/>
        </View>
        <Modal isModalShowed={isModalAddSSL}
            component={<SSLFreeOrder
                virtualhostId={virtualhostId}
                onClose={() => setModalAddSSL(false)}/>}
            onClose={() => setModalAddSSL(false)} />
    </View>;
}

SSLBlock.propTypes = {
    domains: PropTypes.arrayOf(PropTypes.oneOfType(
        [PropTypes.shape({}), PropTypes.string]
    )),
    forceHTTP: PropTypes.func,
    certificatesUpdate: PropTypes.func,
    certificatesCreate: PropTypes.func,
    certificateOrder: PropTypes.func,
    isForceHTTP: PropTypes.bool,
    virtualhostId: PropTypes.number,
    virtualhostEnabled: PropTypes.bool,
    certificatesFind: PropTypes.func,
    certificateId: PropTypes.number,
    certificates: PropTypes.shape({}),
    certificate: PropTypes.shape({}),
    certificatesFetchInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    errors: state.forms.errors,
    certificate: state.hosting.virtualhost.certificate,
    certificatesFetchInProgress: state.hosting.certificatesFetchInProgress
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    forceHTTP,
    certificateOrder,
    certificatesFind,
    certificateDetail,
    certificatesUpdate,
    certificatesCreate,
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        backgroundColor: '#ffffff',
        marginTop: 10,
        marginBottom: 10,
    },
    ssl: {
        width: '100%',
        paddingTop: 16,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 21,
    },
    description: {
        color: '#455562',
        fontSize: 11,
        letterSpacing: 0,
        lineHeight: 14,
        paddingBottom: 19,
    },
    title: {
        opacity: '0.75',
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: '1.38px',
        lineHeight: 13,
        textTransform: 'uppercase',
        paddingTop: 21,
        paddingBottom: 21,
        paddingRight: 18,
        paddingLeft: 18,
        borderBottomColor: '#E4E4E4',
        borderBottomWidth: 1,
    },
    button: {
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
    },
    buttonText: {
        color: '#0F79D5',
    },
    disableButton: {
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
        opacity: '0.5',
    },
    disableButtonText: {
        color: '#888',
    },
    image: {
        width: 115,
        height: 143,
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SSLBlock);