import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Text,
    View,
    StyleSheet,
} from 'react-native';

import Modal from '../General/Modal/Modal';
import { ModalContext } from '../../contexts/ModalContext';
import { servicesResourceNameMap, resourceTypesList } from '../../constants/general';
import TransferPageContainer from './TransferPageContainer';
import TransferInProgress from './TransferInProgress';
import Spring from '../General/Spring/Spring';
import Button from '../General/Button/Button';
import StartTransferModal from './Modals/StartTransferModal';
import CancelTransferModal from './Modals/CancelTransferModal';

const startTransferModalId = 'start-transfer-modal';
const cancelTransferModalId = 'cancel-transfer-modal';

function checkTask(currentTask, currentResources) {
    let currentResource = currentTask.resources.find(
        (r) => r.resource_type === 'Container'
            || r.resource_type === 'VM' || r.resource_type === 'MailDomain',
    );
    if (currentResource) {
        const currentService = resourceTypesList.find(
            (rt) => rt.resource_type === currentResource.resource_type,
        ).service;
        let instance = currentResources.container;
        if (currentService === 'vms') {
            instance = currentResources.vm;
        } else if (currentService === 'mail_domains') {
            instance = currentResources.mail_domain;
        }
        return {
            recipient: currentTask.recipient,
            inProgressService: currentService,
            instance,
        };
    }
    currentResource = currentTask.resources[0];
    const currentService = resourceTypesList.find(
        (rt) => rt.resource_type === currentResource.resource_type,
    ).service;
    return {
        recipient: currentTask.recipient,
        inProgressService: currentService,
        instance: currentResources[currentService][0],
    };
}

function TransferPage() {
    const {
        service,
        resourcesArray,
        currentResources,
    } = useSelector((state) => state.transfer);
    const { openModal, closeModal, checkModal } = useContext(ModalContext);
    const [recipient, setRecipient] = useState(null);
    const [instance, setInstance] = useState(null);
    if (!instance && service === 'vms' && currentResources.resources.vm) {
        setInstance(currentResources.resources.vm);
    }
    if (!instance && service === 'containers' && currentResources.resources.container) {
        setInstance(currentResources.resources.container);
    }
    if (!instance && service === 'mail_domains' && currentResources.resources.mail_domain) {
        setInstance(currentResources.resources.mail_domain);
    }
    if (!instance && service === 'certificates' && currentResources.resources.certificates) {
        setInstance(currentResources.resources.certificates[0]);
    }

    return <View style={styles.wrapper}>
        <View>
            <View style={styles.headerBlock}>
                <Text style={styles.title}>{servicesResourceNameMap[service]}</Text>
                {currentResources.tasks.length !== 0 && <Text
                    style={styles.inProgressText}
                >
                    В процессе передачи
                </Text>}
            </View>
        </View>
        {currentResources.tasks.length === 0 && <TransferPageContainer
            service={service}
            instance={instance}
        />}
        {currentResources.tasks.map((task, i) => {
            const taskInfo = checkTask(
                task.task,
                task.resources,
            );
            if (recipient == null) {
                setRecipient(taskInfo.recipient);
            }
            if (instance == null) {
                setInstance(taskInfo.instance);
            }
            return (
                <div
                    className="transfer-page-progress-block"
                    key={`transfer-task-${i}`}
                >
                    <TransferInProgress
                        resources={resourcesArray}
                        service={taskInfo.inProgressService}
                        recipient={taskInfo.recipient}
                        instance={taskInfo.instance}
                        currentResources={task.resources}
                    />
                </div>
            );
        })}
        <Spring />
        {currentResources.tasks.length === 0 && <Button
            onPress={() => openModal(startTransferModalId)}
            title="Передать на другой аккаунт"
            additionalWrapperStyles={[{ marginLeft: 20, marginRight: 20 }]}
            additionalButtonStyles={[{ paddingTop: 12, paddingBottom: 12 }]}
            additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, fontWeight: '400' }]}
        />}
        {recipient && <Button
            type='white'
            onPress={() => openModal(cancelTransferModalId)}
            title={`Отменить передачу ${recipient}`}
            additionalWrapperStyles={[{ marginLeft: 20, marginRight: 20 }]}
            additionalButtonStyles={[{ paddingTop: 12, paddingBottom: 12 }]}
            additionalButtonTextStyles={[{
                fontSize: 14,
                lineHeight: 16,
                fontWeight: '400',
                color: '#455562'
            }]}
        />}
        <Modal isModalShowed={checkModal(startTransferModalId)}
            component={<StartTransferModal
                service={service}
                instance={instance}
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
        <Modal isModalShowed={checkModal(cancelTransferModalId)}
            component={<CancelTransferModal
                recipient={recipient}
                service={service}
                instance={instance}
                resources={resourcesArray}
                onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
        backgroundColor: '#f6f6f6',
        flex: 1,
        paddingTop: 24,
        paddingBottom: 30
    },
    title: {
        opacity: 0.75,
        paddingTop: 2,
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.38,
        lineHeight: 13,
        textTransform: 'uppercase'
    },
    headerBlock: {
        flex: 1,
        marginBottom: 7,
        paddingLeft: 20,
        paddingRight: 17,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inProgressText: {
        color: '#ffffff',
        fontSize: 9,
        lineHeight: 11,
        paddingTop: 4,
        paddingBottom: 3,
        paddingLeft: 9,
        paddingRight: 9,
        backgroundColor: '#e28a58',
        borderRadius: 50,
    }
});

export default TransferPage;
