import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

import Separator from '../../General/Separator/Separator';
import PartnershipEntriesBlock from './PartnershipEntriesBlock';

function formatDate(date) {
    return dayjs(date).format('DD MMMM');
}

function getAccruals(filters, entries) {
    let filteredEntries = entries;
    const filterStatistic = {};
    if (filters.length > 0) {
        filteredEntries = filteredEntries.filter((g) => filters.find((f) => f.kind === g.kind));
    }
    filteredEntries.forEach((element) => {
        const date = dayjs(element.added, 'YYYY-MM-DD');
        if (filterStatistic[date] == null) {
            filterStatistic[date] = [];
        }
        filterStatistic[date].push(element);
    });
    const dateList = Object.keys(filterStatistic).sort((a, b) => {
        const normalizedA = dayjs(a, 'YYYY-MM-DD');
        const normalizedB = dayjs(b, 'YYYY-MM-DD');
        if (normalizedA < normalizedB) {
            return 1;
        }
        if (normalizedA > normalizedB) {
            return -1;
        }
        return 0;
    });

    return dateList.map((date, d) => <View key={`group-date-${d}`}
        style={d !== dateList.length - 1 ? styles.groupDate : { marginTop: 12 }}>
        <Text style={styles.financeDate}>{formatDate(date).toUpperCase()}</Text>
        <View>
            {getAccrualsDateList(filterStatistic[date])}
            <Separator />
        </View>
    </View>);
}

function getAccrualsDateList(dateList) {
    return dateList.map((item, i) => <React.Fragment key={`partnership-entry-${i}`}>
        <Separator />
        <PartnershipEntriesBlock element={item}/>
    </React.Fragment>);
}

function PartnershipEntriesList(props) {
    const {
        filters = [],
        entries
    } = props;

    return <View>
        {getAccruals(filters, entries)}
    </View>;
}

PartnershipEntriesList.propTypes = {
    entries: PropTypes.arrayOf(PropTypes.shape({
        added: PropTypes.string,
        amount: PropTypes.number,
        kind: PropTypes.string,
        client: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            login: PropTypes.string,
        }),
        colorType: PropTypes.string
    })),
    filters: PropTypes.arrayOf(PropTypes.shape({}))
};

const styles = StyleSheet.create({
    financeDate: {
        color: '#455562',
        fontSize: 9,
        letterSpacing: 1.13,
        lineHeight: 10,
        marginBottom: 12,
        marginLeft: 20
    },
    groupDate: {
        marginBottom: 12,
        marginTop: 12
    }
});

export default PartnershipEntriesList;
