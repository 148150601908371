import React, { useEffect } from 'react';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Platform, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import MainScreen from './screens/tabs';
import AuthScreen from './screens/auth';
import OtpScreen from './screens/otp';
import OtpFail from './screens/otpFail';
import CVDSStackScreen from './screens/cvds';
import AuthFailScreen from './screens/authFail';

import MailScreen from './screens/mail';
import LoadingScreen from './screens/loading';
import HostingScreen from './screens/hosting';
import CVDSVMStackScreen from './screens/cvdsVm';
import Notifications from './screens/notifications';
import MailDomainScreen from './screens/mailDomain';
import MailPacketScreen from './screens/mailPacket';
import PartnershipScreen from './screens/partnership';
import CVDSCreateStackScreen from './screens/cvdsCreate';
import MailboxSettingsScreen from './screens/mailboxSettings';
import ChangePasswordScreen from './screens/changePassword';
import FinancePaymentScreen from './screens/financePayment';
import ChangeRequisitesScreen from './screens/changeRequisites';
import FinanceForecastScreen from './screens/financeForecast';
import CVDSVMTariffsStackScreen from './screens/cvdsVmTariffs';
import HostingContainerScreen from './screens/hostingContainer';
import CVDSVMEditVolumeStackScreen from './screens/cvdsEditVolume';
import SendInvoiceToEmailScreen from './screens/sendInvoiceToEmail';
import HostingVirtualhostScreen from './screens/hostingVirtualhost';
import HostingChangeTariffScreen from './screens/hostingChangeTariff';
import HostingDbmsAccessStackScreen from './screens/hostingDbmsAccess';
import CVDSDistributionsStackScreen from './screens/cvdsDistributions';
import CVDSVMEditBandwidthStackScreen from './screens/cvdsVmEditBandwidth';
import CVDSVMProtectedIpsStackScreen from './screens/cvdsVmProtectedIps';
import CVDSVMProtectedWebsitesStackScreen from './screens/cvdsVmProtectedWebsites';
import CVDSBackupScreen from './screens/CvdsBackups/cvdsBackup';
import CVDSBackupDisksScreen from './screens/CvdsBackups/cvdsBackupDisks';
import CVDSBackupMethodsScreen from './screens/CvdsBackups/cvdsBackupMethods';
import CVDSBackupConfigurationScreen from './screens/CvdsBackups/cvdsBackupConfiguration';
import PartnershipWithdrawalScreen from './screens/partnershipWithdrawal';
import FinancePromisedPaymentScreen from './screens/financePromisedPayment';
import HostingCreateVirtualhost from './screens/hostingVirtualhostCreate';
import HostingCreateContainerStackScreen from './screens/hostingCreateContainer';
import RestorePasswordScreen from './screens/restorePassword';
import RestorePasswordDoneScreen from './screens/restorePasswordDone';
import RestorePasswordConfirmScreen from './screens/restorePasswordConfirm';
import RestorePasswordFailScreen from './screens/restorePasswordFail';
import HostingBackupScreen from './screens/HostingBackups/hostingBackup';
import HostingBackupContainerScreen from './screens/HostingBackups/hostingBackupContainer';
import HostingBackupResourcesScreen from './screens/HostingBackups/hostingBackupResources';
import HostingBackupVirtualhostScreen from './screens/HostingBackups/hostingBackupVirtualhost';
import HostingBackupConfigurationScreen from './screens/HostingBackups/hostingBackupConfiguration';
import HostingBackupDbmsScreen from './screens/HostingBackups/hostingBackupDbms';
import TransferScreen from './screens/transfer';
import TransferAcceptScreen from './screens/transferAccept';
import MonitoringStackScreen from './screens/Monitoring/monitoring';
import MonitoringHostingStackScreen from './screens/Monitoring/monitoringHosting';
import MonitoringCvdsStackScreen from './screens/Monitoring/monitoringCvds';
import MonitoringMailStackScreen from './screens/Monitoring/monitoringMail';
import SslScreen from './screens/ssl';
import SSLDetailScreen from './screens/sslDetail';

import { linking } from './linking';
import { financeInfo } from './actions/user/actions';

import NotificationMomentWS from './components/Notifications/NotificationMomentWS';
import NotificationMomentBlock from './components/Notifications/NotificationMomentBlock';

// eslint-disable-next-line react/display-name
const checkAuth = (Component, withRedirectToAuth = true, onAuthorized = () => {}) => (props) => {
    if (Platform.OS === 'web') {
        const authData = useSelector((state) => state.user.auth);
        const financeInfoData = useSelector((state) => state.user.financeInfo);
        const { navigation } = props;

        if (authData.inProgress || (financeInfoData == null && !authData.isError)) {
            return <LoadingScreen />;
        }
        if (withRedirectToAuth && authData.isError) {
            navigation.navigate('Auth');
            return null;
        }
        if (authData.isAuthorized) {
            onAuthorized(navigation);
        }
    }
    return <React.Fragment>
        <Component {...props} />
        <NotificationMomentWS />
        <View style={{
            position: 'fixed',
            bottom: 88,
            width: '100%'
        }}>
            <NotificationMomentBlock />
        </View>
    </React.Fragment>;
};

const Stack = createStackNavigator();

function AppNavigation() {
    const authData = useSelector((state) => state.user.auth);
    const financeInfoData = useSelector((state) => state.user.financeInfo);
    const dispatch = useDispatch();

    if (Platform.OS === 'web') {
        useEffect(() => {
            if (
                financeInfoData == null
                && !authData.isError
                && !authData.inProgress
                && !authData.isAuthorized
            ) {
                dispatch(financeInfo(null, null, true));
            }
        }, [financeInfoData]);
    }

    return <NavigationContainer linking={linking}>
        <Stack.Navigator screenOptions={{ cardStyle: { backgroundColor: '#ffffff' } }}>
            <Stack.Screen name="MainStack" component={checkAuth(MainScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDS" component={checkAuth(CVDSStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDSVM" component={checkAuth(CVDSVMStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDSCreate" component={checkAuth(CVDSCreateStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDSVMTariffs" component={checkAuth(CVDSVMTariffsStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDSDistributions" component={checkAuth(CVDSDistributionsStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDSVMEditVolume" component={checkAuth(CVDSVMEditVolumeStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDSVMEditBandwidth" component={checkAuth(CVDSVMEditBandwidthStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDSVMProtectedIps" component={checkAuth(CVDSVMProtectedIpsStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDSVMProtectedWebsites" component={checkAuth(CVDSVMProtectedWebsitesStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDSBackup" component={checkAuth(CVDSBackupScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDSBackupDisks" component={checkAuth(CVDSBackupDisksScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDSBackupMethods" component={checkAuth(CVDSBackupMethodsScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="CVDSBackupConfiguration" component={checkAuth(CVDSBackupConfigurationScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="Notifications" component={checkAuth(Notifications)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="Payment" component={checkAuth(FinancePaymentScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="Forecast" component={checkAuth(FinanceForecastScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="PromisedPayment" component={checkAuth(FinancePromisedPaymentScreen)}
                options={{
                    header: () => null
                }}
            />
            <Stack.Screen name='SendInvoiceToEmail' component={checkAuth(SendInvoiceToEmailScreen)}
                options={{
                    header: () => null
                }}
            />
            <Stack.Screen name="Hosting" component={checkAuth(HostingScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="HostingBackup" component={checkAuth(HostingBackupScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="HostingBackupResources" component={checkAuth(HostingBackupResourcesScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="HostingBackupContainer" component={checkAuth(HostingBackupContainerScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="HostingBackupVirtualhost" component={checkAuth(HostingBackupVirtualhostScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="HostingBackupDbms" component={checkAuth(HostingBackupDbmsScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="HostingBackupConfiguration" component={checkAuth(HostingBackupConfigurationScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="HostingCreateContainer" component={checkAuth(HostingCreateContainerStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="HostingContainer" component={checkAuth(HostingContainerScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="HostingCreateVirtualhost" component={checkAuth(HostingCreateVirtualhost)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="HostingChangeTariff" component={checkAuth(HostingChangeTariffScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="HostingContainerVirtualhost" component={checkAuth(HostingVirtualhostScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="HostingDbmsAccess" component={checkAuth(HostingDbmsAccessStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="RestorePassword" component={checkAuth(RestorePasswordScreen, false)} options={{
                header: () => null,
                title: 'Восстановление пароля'
            }} />
            <Stack.Screen name="RestorePasswordDone" component={checkAuth(RestorePasswordDoneScreen, false)} options={{
                header: () => null,
                title: 'Восстановление пароля'
            }} />
            <Stack.Screen name="RestorePasswordConfirm" component={checkAuth(RestorePasswordConfirmScreen, false)} options={{
                header: () => null,
                title: 'Восстановление пароля. Новый пароль'
            }} />
            <Stack.Screen name="RestorePasswordFail" component={checkAuth(RestorePasswordFailScreen, false)} options={{
                header: () => null,
                title: 'Восстановление пароля. Ссылка устарела'
            }} />
            <Stack.Screen name="AuthFail" component={AuthFailScreen} options={{
                header: () => null,
                title: 'Вход. Личный кабинет'
            }} />
            <Stack.Screen name="Auth" component={checkAuth(AuthScreen, false, (navigation) => {
                navigation.navigate('MainStack');
            })} options={{
                header: () => null,
                title: 'Вход. Личный кабинет'
            }} />
            <Stack.Screen name="OtpStack" component={OtpScreen} options={{
                header: () => null,
                title: 'Вход, проверка двухфакторной авторизации. Личный кабинет'
            }} />
            <Stack.Screen name="OtpFail" component={OtpFail} options={{
                header: () => null,
                title: 'Вход, проверка двухфакторной авторизации. Личный кабинет'
            }} />
            <Stack.Screen name="ChangePassword" component={checkAuth(ChangePasswordScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="PartnershipWithdrawal" component={checkAuth(PartnershipWithdrawalScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="ChangeRequisites" component={checkAuth(ChangeRequisitesScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="Mail" component={checkAuth(MailScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="MailPacket" component={checkAuth(MailPacketScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="MailDomain" component={checkAuth(MailDomainScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="MailboxSettings" component={checkAuth(MailboxSettingsScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="Transfer" component={checkAuth(TransferScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="TransferAccept" component={checkAuth(TransferAcceptScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="Monitoring" component={checkAuth(MonitoringStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="MonitoringHosting" component={checkAuth(MonitoringHostingStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="MonitoringCvds" component={checkAuth(MonitoringCvdsStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="MonitoringMail" component={checkAuth(MonitoringMailStackScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="SSL" component={checkAuth(SslScreen)} options={{
                header: () => null
            }}/>
            <Stack.Screen name="SSLDetail" component={checkAuth(SSLDetailScreen)} options={{
                header: () => null
            }}/>
        </Stack.Navigator>
    </NavigationContainer>;
}

export default AppNavigation;
