import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
} from 'react-native';

import Modal from '../General/Modal/Modal';
import { thinspaceNumber } from './utils';
import { createPacket, updatePacket } from '../../actions/mail/actions';
import Button from '../General/Button/Button';
import SliderDynamic from '../General/Slider/SliderDynamic';
import CurrentPacketBlock from './CurrentPacketBlock';
import ChangeDomainQuotaModal from './Modals/ChangeDomainQuotaModal';

function PacketResource({ packet, setCurrentPacket, currentPacket }) {
    const {
        limit,
        cost,
        percent,
    } = packet;
    const discount = 100 - percent * 100;
    const isActive = currentPacket === limit;
    return <View>
        <TouchableOpacity
            style={isActive ? styles.activePacketBlock
                : styles.packetBlock}
            onPress={() => setCurrentPacket(limit)}
        >
            <View style={isActive ? styles.activeOuterCircle
                : styles.outerCircle}>
                {isActive && <View style={styles.innerCircle}/>}
            </View>
            <Text style={isActive ? styles.activePacketLimit
                : styles.packetLimit}>{limit} ГБ</Text>
            <Text style={isActive ? styles.activePacketDiscount
                : styles.packetDiscount}>–{discount}%</Text>
            <View style={styles.spring}/>
            <Text style={styles.packetPrice}>{thinspaceNumber(cost)} ₽/мес</Text>
        </TouchableOpacity>
    </View>;
}

PacketResource.propTypes = {
    packet: PropTypes.shape({
        cost: PropTypes.number,
        limit: PropTypes.number,
        percent: PropTypes.number,
    }),
    currentPacket: PropTypes.number,
    setCurrentPacket: PropTypes.func,
};

function getQuotaScale(min, max, step) {
    const values = [];
    for (let value = min; value <= max; value += step) {
        values.push(value);
    }
    return values;
}

function MailPacketPage(props) {
    const maxQuota = 4096;
    const dispatch = useDispatch();
    const { navigation } = props;
    const {
        userInfo,
        preferredPacket,
        packetsList,
        calculation,
        userStatisticsInfo,
        orderPacketInProgress,
    } = useSelector((state) => state.mail);
    const { isBudget } = useSelector((state) => state.user.user);
    const [isChangeQuotaModalShowed, setChangeQuotaModalShowed] = React.useState(false);
    const [prolong, setProlong] = React.useState(
        calculation && calculation.packet && calculation.packet.prolong
    );

    const hasPacket = calculation && calculation.packet != null;
    let packet = 0;
    if (hasPacket) {
        packet = calculation.packet.next_limit;
    } else if (preferredPacket) {
        packet = preferredPacket.limit;
    }
    const [currentPacket, setCurrentPacket] = React.useState(packet);
    const isChanged = calculation && calculation.packet && (
        prolong !== calculation.packet.prolong || currentPacket !== calculation.packet.next_limit
    );
    return <View style={styles.wrapper}>
        <Text style={styles.headerText}>{hasPacket ? 'ваш пакет' : 'выберите подходящий пакет'}</Text>
        {hasPacket && <CurrentPacketBlock
            packet={calculation.packet}
            freeQuota={userInfo.free_quota}
            used={userStatisticsInfo.summary.used}
            prolong={prolong}
            setProlong={setProlong}
            needProlong={true}
        />}
        {!isBudget && packetsList
            && (!hasPacket || prolong) && packetsList.map((packet, i) => <PacketResource
            packet={packet}
            setCurrentPacket={setCurrentPacket}
            currentPacket={currentPacket}
            key={`packet-resource-${i}`} />)}
        {isBudget && hasPacket && prolong && <View style={styles.sliderBlock}>
            <SliderDynamic
                title='Пакет на следующий месяц'
                items={getQuotaScale(calculation.packet.limit, maxQuota, 5)}
                value={currentPacket}
                unit='ГБ'
                onChange={(changedQuota) => {
                    setCurrentPacket(changedQuota);
                }}/>
        </View>}
        {calculation && hasPacket && <Text style={styles.packetHint}>
            {calculation.packet.prolong
                ? `Пакет обновится ${dayjs(calculation.packet.paid_till).format('D MMMM')}`
                : `Пакет оплачен до ${dayjs(calculation.packet.paid_till).format('D MMMM')}`}
        </Text>}
        <View style={styles.spring}/>
        <View style={styles.buttonsBlock}>
            {!hasPacket && <Button
                onPress={() => dispatch(
                    createPacket('order-mail-packet', currentPacket, () => navigation.navigate('Mail', {
                        screen: 'MailTabs',
                        params: { screen: 'MailStatistics' }
                    }))
                )}
                inProgress={orderPacketInProgress}
                title={`Заказать пакет ${currentPacket} ГБ`}
                additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 13 }]}
                additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15, fontWeight: '400' }]}
            />}
            {hasPacket && isChanged && <Button
                onPress={() => dispatch(updatePacket('update-mail-packet', {
                    prolong,
                    next_limit: currentPacket
                }))}
                title="Сохранить"
                inProgress={orderPacketInProgress}
                additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 13 }]}
                additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, fontWeight: '400' }]}
            />}
            {hasPacket && isChanged && <Button
                onPress={() => {
                    setCurrentPacket(calculation.packet.next_limit);
                    setProlong(calculation.packet.prolong);
                }}
                type="white"
                title="Отменить"
                additionalButtonStyles={[{ marginTop: 10, paddingTop: 11, paddingBottom: 13 }]}
                additionalButtonTextStyles={[{
                    fontSize: 14,
                    lineHeight: 16,
                    fontWeight: '400',
                    color: '#455562'
                }]}
            />}
        </View>
        <Modal isModalShowed={isChangeQuotaModalShowed}
            component={<ChangeDomainQuotaModal
                onClose={() => setChangeQuotaModalShowed(false)}/>}
            onClose={() => setChangeQuotaModalShowed(false)} />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
    },
    headerText: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        paddingTop: 24,
        paddingBottom: 18,
        paddingLeft: 20,
    },
    packetBlock: {
        flex: 1,
        flexDirection: 'row',
        paddingTop: 14,
        paddingBottom: 15,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 1,
        backgroundColor: '#ffffff',
        alignItems: 'center',
    },
    activePacketBlock: {
        flex: 1,
        flexDirection: 'row',
        paddingTop: 14,
        paddingBottom: 16,
        paddingLeft: 21,
        paddingRight: 20,
        backgroundColor: 'rgba(15, 121, 213, 0.07)',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#0f79d5',
        alignItems: 'center',
    },
    outerCircle: {
        height: 18,
        width: 18,
        borderTopWidth: 1,
        borderTopColor: 'rgba(161, 170, 177, 0.5)',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(161, 170, 177, 0.5)',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(161, 170, 177, 0.5)',
        borderRightWidth: 1,
        borderRightColor: 'rgba(161, 170, 177, 0.5)',
        borderRadius: 9,
        backgroundColor: '#f5f5f5'
    },
    activeOuterCircle: {
        height: 18,
        width: 18,
        borderTopWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderLeftWidth: 1,
        borderLeftColor: '#0f79d5',
        borderRightWidth: 1,
        borderRightColor: '#0f79d5',
        borderRadius: 9,
        backgroundColor: '#ffffff',
        paddingTop: 4,
        paddingLeft: 4
    },
    innerCircle: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0f79d5'
    },
    activePacketLimit: {
        marginLeft: 16,
        marginRight: 19,
        color: '#0f79d5',
        fontSize: 13,
        fontWeight: 500,
    },
    packetLimit: {
        marginLeft: 16,
        marginRight: 19,
        color: '#455562',
        fontSize: 13,
        fontWeight: 500,
    },
    activePacketDiscount: {
        borderRadius: 40,
        backgroundColor: '#0f79d5',
        color: '#ffffff',
        paddingTop: 4,
        paddingLeft: 8,
        paddingBottom: 4,
        paddingRight: 8,
        fontSize: 11,
        fontWeight: '400',
        lineHeight: 11,
    },
    packetDiscount: {
        borderRadius: 40,
        backgroundColor: '#a2b0ba',
        color: '#ffffff',
        paddingTop: 4,
        paddingLeft: 8,
        paddingBottom: 4,
        paddingRight: 8,
        fontSize: 11,
        fontWeight: '400',
        lineHeight: 11,
    },
    spring: {
        flex: 1,
    },
    packetPrice: {
        color: '#0f79d5',
        fontSize: 13,
        fontWeight: '500',
    },
    buttonsBlock: {
        paddingBottom: 40,
        paddingRight: 23,
        paddingLeft: 26,
    },
    packetHint: {
        paddingTop: 19,
        paddingLeft: 20,
        paddingBottom: 19,
        paddingRight: 20,
        color: '#455562',
        fontSize: 11,
        fontWeight: '400',
        lineHeight: 14,
        backgroundColor: '#ffffff',
        marginTop: 1,
    },
    sliderBlock: {
        backgroundColor: '#ffffff',
        paddingTop: 19,
        paddingLeft: 20,
        paddingBottom: 19,
        paddingRight: 20,
        marginTop: 1,
    }
});

export default MailPacketPage;
