import React from 'react';
import * as dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
} from 'react-native';

import Button from '../../General/Button/Button';
import Spring from '../../General/Spring/Spring';
import RestoreEmptyStep from './RestoreEmptyStep';
import RadioButton from '../../General/Form/RadioButton';
import BackupDateSelect from '../../General/BackupDateSelect/BackupDateSelect';
import { restoreFromBackupUpdateParams } from '../../../actions/hosting/actions';

function RestoreVirtualhostStep() {
    const {
        currentContainer,
        currentVirtualhost,
        restoreDate,
        currentDbms,
        currentMethod,
        restoreDateFromParams,
    } = useSelector((state) => state.hostingRestoreFromBackup);
    const {
        dbms,
        backups
    } = useSelector((state) => state.hosting);
    const dispatch = useDispatch();
    const navigation = useNavigation();

    return <React.Fragment>
        {backups && backups.entities.length === 0 && (
            <RestoreEmptyStep
                description='Вероятно, сайт создан недавно и у него пока отсутствуют доступные точки восстановления'
            />
        )}
        {backups && backups.entities.length !== 0 && <View style={styles.wrapper}>
            {!restoreDateFromParams && (
                <View>
                    <Text style={styles.title}>
                        точка восстановления файлов (МСК+2)
                    </Text>
                    <View style={styles.dateSelectBlock}>
                        <BackupDateSelect
                            currentDate={restoreDate}
                            onChange={(value) => dispatch(
                                restoreFromBackupUpdateParams({
                                    restoreDate: value.date,
                                    currentMethod: 'rewrite',
                                    filesForArchive: [
                                        `/${currentVirtualhost}`,
                                    ],
                                }),
                            )}
                            points={backups.entities}
                            dateFieldName="date"
                        />
                    </View>
                </View>
            )}
            <Text style={styles.title}>
                восстановление файлов
            </Text>
            <RadioButton
                additionalWrapperStyles={[{ marginBottom: 1 }]}
                onChange={() => dispatch(
                    restoreFromBackupUpdateParams(
                        {
                            currentMethod: 'rewrite',
                            filesForArchive: [`/${currentVirtualhost}`],
                        },
                    ),
                )}
                isActive={currentMethod === 'rewrite'}
                label="Восстановить поверх"
            />
            <RadioButton
                additionalWrapperStyles={[{ marginBottom: 1 }]}
                onChange={() => dispatch(
                    restoreFromBackupUpdateParams(
                        {
                            currentMethod: 'restoreFile',
                            filesForArchive: [`/${currentVirtualhost}`],
                        },
                    ),
                )}
                isActive={currentMethod === 'restoreFile'}
                label="Восстановить в отдельный каталог"
            />
            <RadioButton
                additionalWrapperStyles={[{ marginBottom: 30 }]}
                onChange={() => dispatch(
                    restoreFromBackupUpdateParams(
                        {
                            currentMethod: null,
                            filesForArchive: [],
                        },
                    ),
                )}
                isActive={currentMethod == null}
                label="Не восстанавливать файлы"
            />
            <Text style={styles.title}>
                восстановление базы данных
            </Text>
            <RadioButton
                additionalWrapperStyles={[{ marginBottom: 1 }]}
                onChange={() => dispatch(
                    restoreFromBackupUpdateParams(
                        {
                            currentDbms: null,
                        },
                    ),
                )}
                isActive={currentDbms == null}
                label="Не восстанавливать базу данных"
            />
            {dbms && dbms.entities.map((db, index) => <RadioButton
                key={`restore-hosting-db-${index}`}
                additionalWrapperStyles={[{ marginBottom: 1 }]}
                onChange={() => dispatch(
                    restoreFromBackupUpdateParams(
                        {
                            currentDbms: db,
                            currentDbmsMethod: 'rewrite',
                            restoreDbmsDate: null,
                        },
                    ),
                )}
                isActive={currentDbms && currentDbms.id === db.id}
                label={db.name}
            />)}
            <Spring />
            <Button
                onPress={() => {
                    if (currentDbms == null) {
                        navigation.navigate(
                            'HostingBackupConfiguration',
                            {
                                containerId: currentContainer.id,
                                restoreDate: dayjs(restoreDate).unix(),
                                virtualhost: currentVirtualhost
                            }
                        );
                    } else {
                        navigation.navigate(
                            'HostingBackupDbms',
                            {
                                containerId: currentContainer.id,
                                restoreDate: dayjs(restoreDate).unix(),
                                virtualhost: currentVirtualhost,
                                dbmsId: currentDbms.id,
                            }
                        );
                    }
                }}
                title="Продолжить"
                additionalWrapperStyles={[{ marginLeft: 20, marginRight: 20, marginBottom: 30 }]}
                additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 10 }]}
                additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, fontWeight: '400' }]}
            />
        </View>}
    </React.Fragment>;
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
    },
    title: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginBottom: 10,
        marginLeft: 20,
    },
    dateSelectBlock: {
        marginBottom: 30,
        marginLeft: 20,
    },
});

export default RestoreVirtualhostStep;
