import React from 'react';
import * as dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';

import Spring from '../../General/Spring/Spring';

function getEnough(date) {
    const momentPaidTill = dayjs(date, 'YYYY-MM-DD');
    const current = dayjs();
    let formatDate = null;
    if (current.year() !== momentPaidTill.year()) {
        formatDate = momentPaidTill.format('D MMMM YYYY г.');
    } else {
        formatDate = momentPaidTill.format('D MMMM');
    }
    return `до ${formatDate}`;
}

function FinanceBalanceMainScreen() {
    const navigation = useNavigation();
    const financeInfoData = useSelector((state) => state.user.financeInfo);

    const currentBalance = financeInfoData.balance.balance;
    const balanceTextColor = currentBalance < 0 ? '#d06565' : '#455562';

    return <View style={styles.wrapper}>
        <Text style={styles.title}>Текущий баланс</Text>
        <Text style={[styles.balance, { color: balanceTextColor }]}>{currentBalance} ₽</Text>
        {financeInfoData.paid_till
            && <Text style={styles.prediction}>{getEnough(financeInfoData.paid_till)}</Text>
        }
        <Spring />
        <TouchableOpacity
            onPress={() => navigation.navigate('Payment')}
            style={styles.buttonsWrapper}>
            <Text style={styles.buttonTitle}>Пополнить</Text>
        </TouchableOpacity>
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 17,
        paddingLeft: 12,
        paddingRight: 18,
        paddingBottom: 15,
        flex: 1,
        flexDirection: 'column',
        minHeight: 110,
        backgroundColor: '#ffffff',
        marginRight: 16,
        borderRadius: 8,
    },
    title: {
        fontWeight: '500',
        marginBottom: 8,
        color: '#455562',
        fontSize: 8,
        lineHeight: 9,
        letterSpacing: 1,
        opacity: 0.75,
        textTransform: 'uppercase'
    },
    balance: {
        fontSize: 16,
        lineHeight: 18,
        fontWeight: '500',
        marginBottom: 4
    },
    prediction: {
        fontSize: 10,
        lineHeight: 12,
        color: '#455562',
        marginLeft: 2,
        opacity: 0.7,
        marginBottom: 15,
    },
    buttonTitle: {
        fontSize: 12,
        lineHeight: 14,
        color: '#0F79D5',
        fontWeight: '500',
    }
});

export default FinanceBalanceMainScreen;
