import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { View, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';

import { tariffs } from '../../constants/hosting';
import { formatMemory } from '../../utils/general';
import { rightArrowLightIcon } from '../../icons';
import ActionButton from '../General/ActionButton/ActionButton';

function ContainerResource({ container, navigation }) {
    const {
        id,
        name,
        quota,
        memory_limit,
        virtualhosts,
    } = container;

    return <TouchableOpacity style={styles.wrapper}
        onPress={() => {
            navigation.navigate('HostingContainer', {
                screen: 'HostingVirtualhosts',
                containerId: id,
                containerName: name
            });
        }}>
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.headerTitle}>{name}</Text>
                <View style={styles.headerCircle}>
                    <Text style={styles.headerCircleText}>{virtualhosts.entities.length}</Text>
                </View>
            </View>
            <View style={styles.info}>
                <View style={{ marginRight: 23 }}>
                    <Text style={styles.progressBarLabel}>
                        Тариф &#171;{tariffs.find((t) => t.ram === memory_limit).title}&#187;
                    </Text>
                </View>
                <View>
                    <Text style={styles.progressBarLabel}>
                        Диск: {formatMemory(quota)}
                    </Text>
                </View>
            </View>
        </View>
        <Image source={rightArrowLightIcon} style={styles.icon} />
    </TouchableOpacity>;
}

ContainerResource.propTypes = {
    container: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        quota: PropTypes.number,
        memory_limit: PropTypes.number,
        virtualhosts: PropTypes.shape({
            entities: PropTypes.arrayOf(PropTypes.shape({}))
        })
    })
};

function ContainersList(props) {
    const { containers } = props;
    const navigation = useNavigation();

    const [isActionButtonOpen, setIsActionButtonOpen] = React.useState(true);
    useFocusEffect(React.useCallback(() => {
        setIsActionButtonOpen(true);
        return () => {
            setIsActionButtonOpen(false);
        };
    }));

    return <View>
        {containers.entities.map((container, c) => <ContainerResource
            key={`hosting-container-${c}`}
            container={container}
            navigation={navigation}
        />)}
        {isActionButtonOpen && <ActionButton isOpen={isActionButtonOpen}
            type='+'
            onPress={() => navigation.navigate('HostingCreateContainer')}
        />}
    </View>;
}

ContainersList.propTypes = {
    containers: PropTypes.shape({
        count: PropTypes.number,
        entities: PropTypes.arrayOf(PropTypes.shape({}))
    }),
};

const styles = StyleSheet.create({
    wrapper: {
        margin: 0,
        paddingTop: 18,
        paddingBottom: 19,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        width: '100%',
        color: '#455562',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        backgroundColor: '#FFFFFF',
        borderBottomColor: '#F6F6F6',
        borderBottomWidth: 2,
    },
    container: {
        flex: 1
    },
    icon: {
        width: 7,
        height: 13,
    },
    header: {
        flex: 1,
        color: '#455562',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        marginBottom: 4,
    },
    headerTitle: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
        marginRight: 8,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    headerCircle: {
        height: 16,
        minWidth: 16,
        maxWidth: 16,
        borderWidth: 1,
        marginRight: 12,
        borderColor: 'rgba(161,170,177,0.5)',
        borderRadius: 9,
        opacity: '0.75',
        backgroundColor: '#D8D8D8',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerCircleText: {
        color: '#333333',
        fontSize: 9,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 12,
    },
    progressBar: {
        width: 94,
        height: 2,
    },
    progressBarLabel: {
        opacity: 0.8,
        color: '#455562',
        fontSize: 11,
        letterSpacing: 0,
        lineHeight: 13,
    },
    info: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'start',
    }
});

export default ContainersList;
