import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';

import { thresholdColors } from '../../../constants/general';
import { clamp, getThresholdColorClass } from '../../../utils/general';


const dumbDays = [0, 0, 0, 0, 0, 0, 0];

function StatisticsSevenDays(props) {
    const {
        days,
        title,
        isError = false,
        isLoading = false,
        formatGeneralValue = value => `~${value}%`,
        generalValue
    } = props;

    const actualDays = (isLoading || isError) ? dumbDays : days;
    return <View style={styles.wrapper}>
        <View style={styles.graph}>
            {actualDays.map((day, i) => {
                const formatedValue = clamp(day.value, 2);
                return <View key={`statistic-ten-day-${title}-${i}`} style={styles.dayBlock}>
                    <View style={styles.dayBackground}/>
                    <View style={[{ height: `${formatedValue}%`,
                        backgroundColor: thresholdColors[getThresholdColorClass(formatedValue)] },
                        styles.dayFill]}/>
                </View>;
            })}
        </View>
        {(!isLoading && !isError) && <Text
            style={[{ color: thresholdColors[getThresholdColorClass(generalValue)] },
            styles.title]}>
            {formatGeneralValue(generalValue)}
        </Text>}
    </View>;
}

StatisticsSevenDays.propTypes = {
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    errors: PropTypes.shape({})
};

const styles = StyleSheet.create({
    wrapper: {
        flex: 2,
        flexDirection: 'row',
        maxWidth: 135,
        alignItems: 'center'
    },
    graph: {
        flex: 1,
        marginRight: 7.12,
        flexDirection: 'row',
        alignItems: 'center'
    },
    dayBlock: {
        height: 24,
        width: 10,
        marginRight: 4,
        position: 'relative'
    },
    dayBackground: {
        height: 24,
        width: '100%',
        backgroundColor: '#EAEAEA'
    },
    dayFill: {
        bottom: 0,
        width: '100%',
        position: 'absolute'
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    title: {
        fontSize: 12,
        fontHeight: 16,
        fontWeight: '600'
    }
});

export default StatisticsSevenDays;