import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
    View,
    Image,
    StyleSheet,
    Text,
    TouchableOpacity,
} from 'react-native';

import { getChannel } from '@netangels/core/utils/websockets';
import { userChannelName } from '@netangels/core/constants/websockets';

import { wsUserBalanceUpdate } from '../actions/finances/actions';
import {
    updateNotification,
    fetchUserNotifications,
} from '../actions/notifications/actions';
import useWebsockets from '../hooks/useWebsockets';
import { screenHeaderStyles } from '../utils/styles';
import { userBlueIcon, bellBlueIcon } from '../icons';
import ServicesList from '../components/General/ServicesList/ServicesList';
import FinanceBalanceMainScreen from '../components/Finance/FinanceBalanceMainScreen/FinanceBalanceMainScreen';
import { connectToWebsocketWithDelay } from '../utils/websockets';
import { fetchMonitoringEvents, wsMonitoringEventUpdate } from '../actions/monitoring/actions';
import MonitoringMainScreen from '../components/Monitoring/MonitoringMainScreen';

const GeneralStack = createStackNavigator();

function GeneralScreen() {
    return (
        <View>
            <View style={styles.mainInfoBlock}>
                <FinanceBalanceMainScreen />
                <MonitoringMainScreen />
            </View>
            <ServicesList />
        </View>
    );
}

function GeneralStackScreen(props) {
    const { navigation } = props;
    const { user } = useSelector((state) => state.user);
    const notifications = useSelector((state) => state.notifications);
    const insets = useSafeAreaInsets();
    const ws = useWebsockets();
    const dispatch = useDispatch();

    let panelSubscriber = null;
    let notificationsSubscriber = null;
    let monitoringSubscriber = null;

    useFocusEffect(
        React.useCallback(() => {
            if (
                notifications
        && !notifications.isLoading
        && !notifications.notifications
            ) {
                dispatch(fetchUserNotifications());
            }
            dispatch(fetchMonitoringEvents());
        }, [])
    );

    useFocusEffect(
        useCallback(() => {
            const connectionState = connectToWebsocketWithDelay(() => {
                const channelName = getChannel(userChannelName, user.login, true);
                if (panelSubscriber == null) {
                    panelSubscriber = ws.subscriber('panel', channelName);
                    panelSubscriber.on('user.balance_changed', (data) => {
                        dispatch(wsUserBalanceUpdate(data));
                    });
                }
                if (notificationsSubscriber == null) {
                    notificationsSubscriber = ws.subscriber('notification', channelName);
                    notificationsSubscriber.on('notification', (data) => {
                        dispatch(updateNotification(data));
                    });
                }
                if (monitoringSubscriber == null) {
                    monitoringSubscriber = ws.subscriber('monitoring', channelName);
                    monitoringSubscriber.on('event.update', (data) => {
                        dispatch(wsMonitoringEventUpdate(data));
                    });
                }
            });

            return () => {
                connectionState.cancel();
                if (panelSubscriber != null) {
                    ws.unsubscribe(panelSubscriber);
                    panelSubscriber = null;
                }
                if (notificationsSubscriber != null) {
                    ws.unsubscribe(notificationsSubscriber);
                    notificationsSubscriber = null;
                }
                if (monitoringSubscriber != null) {
                    ws.unsubscribe(monitoringSubscriber);
                    monitoringSubscriber = null;
                }
            };
        }, [])
    );

    return (
        <GeneralStack.Navigator>
            <GeneralStack.Screen
                name="GeneralScreen"
                component={GeneralScreen}
                options={{
                    headerLeft: () => (
                        <View style={styles.headerLeftTitleWrapper}>
                            <Image source={userBlueIcon} style={styles.headerIcon} />
                            <Text style={styles.headerLeftTitle}>{user.login}</Text>
                        </View>
                    ),
                    headerRight: () => (
                        <View style={styles.rightWrapper}>
                            <TouchableOpacity
                                onPress={() => navigation.navigate('Notifications')}
                                style={styles.bellTouchableWrapper}
                            >
                                <Image source={bellBlueIcon} style={styles.bellIcon} />
                                {notifications
                  && notifications.notifications
                  && notifications.notifications.unread > 0 && (
                                    <Text style={styles.circle}>
                                        {notifications.notifications.unread > 99
                                            ? '99+'
                                            : notifications.notifications.unread}
                                    </Text>
                                )}
                            </TouchableOpacity>
                        </View>
                    ),
                    title: 'Рабочий стол',
                    headerTitle: null,
                    headerStatusBarHeight: insets.top,
                    headerShadowVisible: false,
                    headerStyle: {
                        backgroundColor: 'transparent',
                        elevation: 0,
                        shadowOpacity: 0,
                        borderBottomWidth: 0,
                    }
                    // ...screenHeaderStyles,
                }}
            />
        </GeneralStack.Navigator>
    );
}

GeneralStackScreen.propTypes = {
    navigation: PropTypes.shape({}),
};

const styles = StyleSheet.create({
    headerLeftTitleWrapper: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerLeftTitle: {
        fontSize: 14,
        lineHeight: 16,
        color: '#455562',
        marginLeft: 9,
        fontWeight: '500',
    },
    headerIcon: {
        width: 18,
        height: 20,
        marginLeft: 18,
    },
    logoutIcon: {
        width: 16,
        height: 18,
    },
    bellIcon: {
        width: 18,
        height: 17,
    },
    rightWrapper: {
        flexDirection: 'row',
    },
    bellTouchableWrapper: {
        marginRight: 16,
        width: 28,
        height: 19,
        position: 'relative',
    },
    circle: {
        width: 17,
        height: 12,
        fontSize: 8,
        position: 'absolute',
        left: 9,
        top: -2,
        color: '#ffffff',
        lineHeight: '1.5',
        textAlign: 'center',
        backgroundColor: '#0f79d5',
        borderRadius: 50,
    },
    logoutTouchableWrapper: {
        marginRight: 20,
    },
    mainInfoBlock: {
        flex: 1,
        flexDirection: 'row',
        marginLeft: 18,
        marginRight: 18,
        marginTop: 16,
    }
});

export default GeneralStackScreen;
