import React, { useEffect } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../../icons';
import {
    fetchUserContainers,
    fetchDbms,
    fetchUserVirtualhosts,
    dbmsBackupsList,
    restoreFromBackupUpdateParams,
} from '../../actions/hosting/actions';
import { screenHeaderStyles } from '../../utils/styles';
import RestoreBackupLoader from '../../components/General/RestoreBackupLoader/RestoreBackupWithDatesLoader';
import RestoreDbmsStep from '../../components/Hosting/HostingBackup/RestoreDbmsStep';
import ScreenHeaderTitle from '../../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const HostingBackupDbmsStack = createStackNavigator();

function HostingBackupDbmsStackScreen(props) {
    const { route } = props;
    const {
        dbms,
        dbmsBackups,
        virtualhosts,
        dbmsBackupsFetchInProgress,
        containers,
        containersFetchInProgress,
    } = useSelector(
        (state) => state.hosting,
    );
    const { restoreDate, currentDbms, currentVirtualhostObject } = useSelector(
        (state) => state.hostingRestoreFromBackup,
    );
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();

    const containerId = Number.parseInt(route.params.containerId, 10);
    const dbmsId = Number.parseInt(route.params.dbmsId, 10);
    const currentRestoreDate = route.params.restoreDate
        && dayjs.unix(route.params.restoreDate).format('YYYY-MM-DD HH:mm');
    const { virtualhost } = route.params;
    useFocusEffect(React.useCallback(() => {
        if (currentVirtualhostObject) {
            dispatch(fetchDbms(currentVirtualhostObject.id));
        }
        if (containers == null && !containersFetchInProgress) {
            dispatch(fetchUserContainers());
        }
        dispatch(
            restoreFromBackupUpdateParams({
                restoreDateFromParams: currentRestoreDate,
            }),
        );
        if (restoreDate == null) {
            dispatch(
                restoreFromBackupUpdateParams({
                    restoreDate: currentRestoreDate,
                }),
            );
        }
    }, []));

    useEffect(() => {
        if (containers) {
            const container = containers.entities.find(
                (c) => c.id === containerId,
            );
            dispatch(
                restoreFromBackupUpdateParams(
                    {
                        currentContainer: container,
                    },
                    () => {
                        dispatch(fetchUserVirtualhosts(container.id));
                    },
                ),
            );
        }
    }, [containers]);
    useEffect(() => {
        if (
            currentVirtualhostObject == null && virtualhosts
            && virtualhosts.entities.length > 0
        ) {
            const virtualhostObject = virtualhosts.entities.find(
                (c) => c.name === virtualhost,
            );
            dispatch(
                restoreFromBackupUpdateParams(
                    {
                        currentVirtualhostObject: virtualhostObject,
                    },
                    () => dispatch(fetchDbms(virtualhostObject.id)),
                ),
            );
        }
    }, [virtualhosts]);
    useEffect(() => {
        if (dbms && dbms.entities && dbms.entities.length > 0) {
            dispatch(
                restoreFromBackupUpdateParams(
                    {
                        currentDbms: dbms.entities.find((db) => db.id === dbmsId),
                        currentDbmsMethod: 'rewrite',
                    },
                    () => {
                        dispatch(dbmsBackupsList(dbmsId));
                    },
                ),
            );
        }
    }, [dbms]);
    useEffect(() => {
        if (dbmsBackups && dbmsBackups.entities && dbmsBackups.entities.length > 0) {
            dispatch(
                restoreFromBackupUpdateParams({
                    restoreDbmsDate: dbmsBackups.entities[0].date,
                }),
            );
        }
    }, [dbmsBackups]);
    const inProgress = dbms == null || dbmsBackups == null || dbmsBackupsFetchInProgress || currentDbms == null;
    return <HostingBackupDbmsStack.Navigator>
        <HostingBackupDbmsStack.Screen name="HostingBackupDbmsScreen"
            component={inProgress ? RestoreBackupLoader : RestoreDbmsStep}
            options={{
                headerTitle: () => <ScreenHeaderTitle
                    title={(currentDbms && currentDbms.name) || 'Идет загрузка...'} />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => {
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        } else {
                            navigation.navigate('HostingBackupVirtualhost', {
                                containerId,
                                virtualhost
                            });
                        }
                    },
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </HostingBackupDbmsStack.Navigator>;
}

HostingBackupDbmsStackScreen.propTypes = {
    route: PropTypes.object,
};

export default HostingBackupDbmsStackScreen;
