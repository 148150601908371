import React from 'react';
import PropTypes from 'prop-types';
import {
    View,
    Text, StyleSheet,
    TouchableOpacity
} from 'react-native';

import LoadingCircle from '../Loading/LoadingCircle';

const styles = StyleSheet.create({
    acceptButton: {
        width: '50%',
        height: 46,
        fontSize: 14,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
        borderRightWidth: 1,
        borderRightColor: '#cccccc',
    },
    blue: {
        color: '#0f79d5',
        fontWeight: '500',
    },
    danger: {
        color: '#c60502',
        fontWeight: '500',
    },
    loading: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingCircle: {
        marginRight: 4,
    }
});

function AcceptButton({
    onSubmit,
    title,
    type = 'blue',
    disabled = false,
    inProgress = false,
    additionalButtonStyles = [],
}) {
    return <TouchableOpacity style={[styles.acceptButton, ...additionalButtonStyles]}
        disabled={disabled}
        onPress={onSubmit}>
        {!inProgress && <Text
            style={[styles[type], { opacity: disabled ? 0.5 : 1 }]}
        >{title}</Text>}
        {inProgress && <View style={styles.loading}>
            <View style={styles.loadingCircle}>
                <LoadingCircle startingOpacity={1} startFunc='fadeOut' type={type}/>
            </View>
            <View style={styles.loadingCircle}>
                <LoadingCircle startingOpacity={0.8} startingDuration={450} type={type}/>
            </View>
            <LoadingCircle startingOpacity={0.6} type={type} />
        </View>}
    </TouchableOpacity>;
}

AcceptButton.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    onSubmit: PropTypes.func,
    inProgress: PropTypes.bool,
    disabled: PropTypes.bool,
    additionalButtonStyles: PropTypes.arrayOf(),
};

export default AcceptButton;
