import React from 'react';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import CVDSDistributionsScreen from '../components/CVDS/DistributionsPage';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const CVDSDistributionsStack = createStackNavigator();


function CVDSDistributionsStackScreen(props) {
    const { navigation } = props;
    const insets = useSafeAreaInsets();

    return <CVDSDistributionsStack.Navigator>
        <CVDSDistributionsStack.Screen name="CVDSDistributions"
            component={CVDSDistributionsScreen} options={{
                headerTitle: () => <ScreenHeaderTitle title='Конфигурация ВМ' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.goBack(),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </CVDSDistributionsStack.Navigator>;
}

export default CVDSDistributionsStackScreen;
