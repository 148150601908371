import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    View,
    Text,
    Image,
    StyleSheet,
    ScrollView
} from 'react-native';

import { hashCode, getColorType } from '../../utils/general';
import { partnershipEntriesMap, partnershipEntriesColorMap } from '../../constants/partnership';
import MonthPickerSelector from '../General/MonthPicker/MonthPickerSelector';
import { monthsForAccruals, chargesMonthGroups } from '../../actions/partnership/actions';

import PartnershipFilter from './PartnershipFilter';
import HorizontalBarChart from '../General/HorizontalBarChart/HorizontalBarChart';

import PartnershipEntriesList from './PartnershipEntries/PartnershipEntriesList';
import { emptySectionFinance } from '../../icons';

function getResourceHashCode(resource) {
    const resourceName = resource.added || 'other';
    const resourceHashName = `${resource.kind}:${resourceName}`;
    return Math.abs(hashCode(resourceHashName));
}

function statisticsSummarizeResources(accruals, statisticFilters, setStatisticFilters) {
    if (statisticFilters.length > 0) {
        return accruals.entries
            .filter((resource) => statisticFilters.find((s) => s.kind === resource.kind))
            .map((resource) => {
                let title = '';
                if (resource.kind === 'Accrual') {
                    title = resource.client.name;
                }
                if (resource.kind === 'Withdrawal') {
                    title = resource.withdrawal_type === 'Balance' ? 'На баланс' : 'На счет';
                }
                return {
                    id: resource.kind,
                    colorType: getColorType(getResourceHashCode(resource)),
                    title,
                    value: resource.amount,
                    formatValue: (v) => `${v.toFixed(2)} ₽`
                };
            });
    }
    return accruals.summarize.map((service) => ({
        id: service.kind,
        title: partnershipEntriesMap[service.kind.toLowerCase()],
        colorType: partnershipEntriesColorMap[service.kind.toLowerCase()] || 'gray',
        value: service.total,
        onClick: () => {
            if (!statisticFilters.find((s) => s.kind !== service.kind)) {
                setStatisticFilters(statisticFilters.concat(service));
            }
        },
        formatValue: (v) => `${v.toFixed(2)} ₽`
    }));
}

function getAccruals(accruals, statisticFilters) {
    if (statisticFilters.length > 0) {
        return {
            ...accruals,
            entries: accruals.entries.map((resource) => ({
                ...resource,
                colorType: getColorType(getResourceHashCode(resource))
            }))
        };
    }
    return accruals;
}

let changeChargesMonthDateTimeout = null;

function PartnershipDetailTab(props) {
    const {
        accruals,
        accrualsMonths,
        monthsForAccruals,
        chargesMonthGroups,
        accrualsCurrentDate
    } = props;
    const [statisticFilters, setStatisticFilters] = React.useState([]);
    const [currentDate, setCurrentDate] = React.useState(dayjs(accrualsCurrentDate, 'YYYY-MM-DD'));
    React.useEffect(() => {
        monthsForAccruals();
        if (accruals == null) {
            chargesMonthGroups(accrualsCurrentDate);
        }
    }, []);

    const hasCharges = Boolean(accruals && accruals.summarize && accruals.entries.length > 0);
    const hasSummarize = Boolean(accruals && accruals.summarize && accruals.summarize.length > 0);
    return <ScrollView>
        <Text style={styles.titleText}>Начисления и выплаты</Text>
        <View style={styles.costsInner}>
            <MonthPickerSelector
                dates={accrualsMonths}
                onNextButtonClick={(date, nextDate) => {
                    if (changeChargesMonthDateTimeout != null) {
                        clearTimeout(changeChargesMonthDateTimeout);
                        changeChargesMonthDateTimeout = null;
                    }
                    setCurrentDate(nextDate);
                    changeChargesMonthDateTimeout = setTimeout(() => {
                        chargesMonthGroups(nextDate.format('YYYY-MM-DD'));
                    }, 500);
                }}
                onPrevButtonClick={(date, prevDate) => {
                    if (changeChargesMonthDateTimeout != null) {
                        clearTimeout(changeChargesMonthDateTimeout);
                        changeChargesMonthDateTimeout = null;
                    }
                    setCurrentDate(prevDate);
                    changeChargesMonthDateTimeout = setTimeout(() => {
                        chargesMonthGroups(prevDate.format('YYYY-MM-DD'));
                    }, 500);
                }}
                onChange={(value) => {
                    if (changeChargesMonthDateTimeout != null) {
                        clearTimeout(changeChargesMonthDateTimeout);
                        changeChargesMonthDateTimeout = null;
                    }
                    const date = value.format('YYYY-MM-DD');
                    setCurrentDate(value);
                    chargesMonthGroups(date);
                }}
                currentDate={currentDate} />
            {statisticFilters.length !== 0 && <View style={styles.filtersInner}>
                <View style={styles.filters}>
                    {statisticFilters.map((item, i) => <PartnershipFilter
                        key={`partnership-filter-cost-${i}`}
                        title={partnershipEntriesMap[item.kind.toLowerCase()] || item.kind}
                        onClose={() => setStatisticFilters(statisticFilters.filter(
                            (r) => r.kind !== item.kind
                        ))}
                    />)}
                </View>
            </View>}
            {hasSummarize && <View style={styles.barChartInner}>
                <HorizontalBarChart
                    hideTotal={true}
                    resources={statisticsSummarizeResources(accruals,
                        statisticFilters, setStatisticFilters)}
                    formatTotal={(v) => `Всего: ${v.toFixed(2)} ₽`}/>
            </View>}
            {hasCharges && <PartnershipEntriesList
                filters={statisticFilters}
                {...getAccruals(accruals, statisticFilters)}/>}
            {!hasCharges && <View style={styles.emptyWrapper}>
                <Image source={emptySectionFinance} style={styles.image}/>
                <Text style={styles.emptyText}>
                    В выбранном месяце не производилось начислений или выводов
                </Text>
            </View>}
        </View>
    </ScrollView>;
}

PartnershipDetailTab.propTypes = {
    accruals: PropTypes.shape({
        entries: PropTypes.arrayOf(PropTypes.shape({})),
        summarize: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    accrualsCurrentDate: PropTypes.string,
    monthsForAccruals: PropTypes.func,
    chargesMonthGroups: PropTypes.func,
    accrualsMonths: PropTypes.arrayOf(PropTypes.string)
};

const mapStateToProps = (state) => ({
    ...state.partnership
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    monthsForAccruals,
    chargesMonthGroups
}, dispatch);

const styles = StyleSheet.create({
    titleText: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '600',
        textTransform: 'uppercase',
        lineHeight: 13,
        opacity: 0.75,
        letterSpacing: 1.38,
        marginTop: 14,
        marginBottom: 13,
        marginLeft: 20
    },
    costsInner: {
        backgroundColor: '#ffffff'
    },
    barChartInner: {
        marginTop: 20,
        marginLeft: 20,
        marginRight: 16,
        marginBottom: 14
    },
    filtersInner: {
        paddingTop: 14,
        paddingLeft: 20,
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    image: {
        height: 140,
        width: 260,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    emptyWrapper: {
        marginTop: 14,
        paddingTop: 20,
        paddingBottom: 35,
        paddingLeft: 20,
        paddingRight: 20
    },
    emptyText: {
        marginTop: 20,
        fontSize: 14,
        color: '#455562',
        textAlign: 'center'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnershipDetailTab);
