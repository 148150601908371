import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    View,
    StyleSheet,
    Text,
} from 'react-native';

import { eventsMapByService } from '../../utils/monitoring';
import { initServiceEvents } from '../../actions/monitoring/actions';
import MonitoringItem from './MonitoringItem';
import ResourceLoader from '../General/ResourceLoader/ResourceLoader';

function MonitoringServiceScreen() {
    const { serviceEvents, serviceEventsInProgress } = useSelector(
        (state) => state.monitoring,
    );
    const dispatch = useDispatch();
    const eventsMap = eventsMapByService(serviceEvents);
    useEffect(() => {
        return () => dispatch(initServiceEvents());
    }, []);
    return <View>
        {serviceEventsInProgress && <React.Fragment>
            <ResourceLoader height={130} count={1} additionalStyles={[styles.loaderItemBlock]}/>
            <ResourceLoader height={130} count={1} additionalStyles={[styles.loaderItemBlock]}/>
        </React.Fragment>}
        {eventsMap.criticals.length !== 0 && <React.Fragment>
            <View>
                {eventsMap.criticals.map((e, i) => (
                    <MonitoringItem monitoringEvent={e}
                        key={`ui-kit-monitoring-event-${e.resource_id}-${i}`}
                    />
                ))}
            </View>
        </React.Fragment>}
        {eventsMap.warnings.length !== 0 && <React.Fragment>
            <View>
                {eventsMap.warnings.map((e, i) => (
                    <MonitoringItem monitoringEvent={e}
                        key={`ui-kit-monitoring-event-${e.resource_id}-${i}`}
                    />
                ))}
            </View>
        </React.Fragment>}
    </View>;
}

const styles = StyleSheet.create({
    title: {
        color: '#455562',
        paddingTop: 24,
        paddingBottom: 10,
        paddingLeft: 21,
        fontSize: 11,
        fontWeight: '500',
        lineHeight: 13,
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
    },
    loaderItemBlock: {
        marginBottom: 12,
        borderRadius: 16,
        marginLeft: 18,
        marginRight: 18,
    },
    icon: {
        minWidth: 20,
        maxWidth: 24,
        height: 24,
        marginRight: 17
    },
    serviceItem: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#ffffff',
        borderRadius: 8,
        paddingLeft: 12,
        paddingRight: 18,
        paddingTop: 10,
        paddingBottom: 12,
        marginLeft: 18,
        marginRight: 18,
        marginBottom: 8,
    },
    serviceItemTitle: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 14,
    },
    eventsBlock: {
        marginLeft: 4,
        paddingBottom: 5,
        paddingTop: 5,
        paddingLeft: 5,
        paddingRight: 5,
        minWidth: 24,
        textAlign: 'center',
        borderRadius: 2,
        backgroundColor: '#45ad85'
    },
    eventsWarning: {
        backgroundColor: '#e28a58'
    },
    eventsCritical: {
        backgroundColor: '#e07474'
    },
    eventsText: {
        color: '#ffffff',
        fontSize: 10,
        fontWeight: '500',
        lineHeight: 10,
    }
});

export default MonitoringServiceScreen;
