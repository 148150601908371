import diskData from '../../data/cvds/disk-types.json';

export const getDiskTypeShort = (disk) => diskData.find(
    (diskData) => diskData.id === disk.type.toLowerCase()
).short;

export const isSasDisk = (disk) => getDiskTypeShort(disk) === 'SAS';

export const isSsdDisk = (disk) => getDiskTypeShort(disk) === 'SSD';

export const isNvmeDisk = (disk) => getDiskTypeShort(disk) === 'NVMe';

export const getVolumesSizeByShortType = (vm, typeFunction) => {
    const volumes = [vm.disk, ...vm.volumes];
    const filteredVolumes = volumes.filter((v) => typeFunction(v));
    return filteredVolumes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.size,
        0,
    );
};

export const getBackupsSize = (vm) => {
    const volumes = [vm.disk, ...vm.volumes];
    const filteredVolumes = volumes.filter((v) => v.is_backup_enabled);
    return filteredVolumes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.size,
        0,
    );
};
