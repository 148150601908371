import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    FlatList,
    View,
    Text,
    StyleSheet,
    ScrollView,
    Image
} from 'react-native';
import { useFocusEffect } from '@react-navigation/native';

import Modal from '../General/Modal/Modal';
import AddClientModal from './Modals/AddClientModal';
import Separator from '../General/Separator/Separator';
import { partnershipClientsEmptyIcon } from '../../icons';
import { ModalContext } from '../../contexts/ModalContext';
import PartnershipClientBlock from './PartnershipClientBlock';
import ActionButton from '../General/ActionButton/ActionButton';
import { blockedButtonTooltips } from '../../constants/general';
import { partnershipClients } from '../../actions/partnership/actions';
import { backgroundColorsMap } from '../General/HorizontalBarChart/styles';
import { partnershipClientsTypes, partnershipClientsColorMap } from '../../constants/partnership';

const limit = 20;
let onEndReachedCalledDuringMomentum = true;
const addClientModalId = 'add-client-modal';

function getSummarize(clients) {
    const summarize = {
        deleted: 0,
        hasresources: 0,
        hasnotresources: 0,
    };

    clients.entities.forEach((client) => {
        summarize[client.status.toLowerCase()] += 1;
    });
    const resultSummarize = [];
    Object.keys(summarize).forEach((s) => {
        if (summarize[s] !== 0) {
            resultSummarize.push({
                kind: s,
                count: summarize[s],
                colorType: partnershipClientsColorMap[s]
            });
        }
    });
    return resultSummarize;
}

function PartnershipClientsTab(props) {
    const {
        clients,
        partnership,
        partnershipClients
    } = props;
    const [offset, setOffset] = React.useState(0);
    const [forcePage, setForcePage] = React.useState(0);
    const [isActionButtonOpen, setIsActionButtonOpen] = React.useState(false);

    const { closeModal, openModal, checkModal } = useContext(ModalContext);

    React.useEffect(() => {
        if (clients == null) {
            partnershipClients(limit, offset);
        }
    }, []);

    useFocusEffect(React.useCallback(() => {
        setIsActionButtonOpen(true);
        return () => {
            setIsActionButtonOpen(false);
        };
    }));

    return <View style={styles.wrapper}>
        {clients && clients.count !== 0 && <View style={{ flex: 1 }}>
            <View>
                <ScrollView style={styles.summarizeWrapper} horizontal={true}>
                    <View style={styles.summarizeInner}>
                        {getSummarize(clients).map((item, i) => <View
                            key={`partnership-filter-clients-${i}`}>
                            <View style={styles.summarizeBlock}>
                                <View style={[styles.summarize,
                                    backgroundColorsMap[item.colorType]]}/>
                                <Text style={styles.summarizeText}>
                                    {partnershipClientsTypes[item.kind.toLowerCase()] || item.kind}
                                </Text>
                            </View>
                        </View>)}
                    </View>
                </ScrollView>
            </View>
            <FlatList style={{ flex: 1 }}
                data={clients.entities}
                refreshing={true}
                onEndReachedThreshold={0.5}
                onScroll={() => {
                    onEndReachedCalledDuringMomentum = false;
                }}
                onEndReached={() => {
                    if (clients.entities.length !== clients.count
                        && !onEndReachedCalledDuringMomentum) {
                        const page = forcePage + 1;
                        setOffset(limit * page);
                        setForcePage(page);
                        partnershipClients(limit, limit * page);
                        onEndReachedCalledDuringMomentum = true;
                    }
                }}
                renderItem={({ item }) => <React.Fragment>
                    <PartnershipClientBlock client={item} />
                    <Separator />
                </React.Fragment>}
            />
        </View>}
        {clients && clients.count === 0 && <View style={styles.emptyWrapper}>
            <Image source={partnershipClientsEmptyIcon} style={styles.image}/>
            <Text style={styles.emptyText}>
                Вы еще не добавляли клиентов
            </Text>
        </View>}
        {isActionButtonOpen && <ActionButton isOpen={isActionButtonOpen}
            type='+'
            disabledText={blockedButtonTooltips.partnership.registerUser}
            disabled={!(partnership && partnership.can_register_users)}
            onPress={() => openModal(addClientModalId)}
            additionalIconStyles={[{ bottom: 70, right: 19 }]}
        />}
        <Modal isModalShowed={checkModal(addClientModalId)}
            component={<AddClientModal onClose={() => closeModal()}
                updateList={() => {
                    setOffset(0);
                    setForcePage(0);
                    partnershipClients(limit, 0, true);
                }}/>}
            onClose={() => closeModal()} />

    </View>;
}

PartnershipClientsTab.propTypes = {
    partnershipClients: PropTypes.func,
    clients: PropTypes.shape({
        count: PropTypes.number,
        entities: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            login: PropTypes.string
        }))
    }),
    partnership: PropTypes.shape({
        can_register_users: PropTypes.bool
    })
};

const mapStateToProps = (state) => ({
    clients: state.partnership.clients,
    partnership: state.partnership.partnership
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ partnershipClients }, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 9,
        flex: 1
    },
    image: {
        height: 160,
        width: 260,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    emptyWrapper: {
        marginTop: 14,
        paddingTop: 20,
        paddingBottom: 35,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: '#ffffff'
    },
    emptyText: {
        marginTop: 20,
        fontSize: 14,
        color: '#455562',
        textAlign: 'center'
    },
    summarizeInner: {
        flex: 1,
        flexDirection: 'row'
    },
    summarizeWrapper: {
        marginLeft: 20,
        marginBottom: 18
    },
    summarizeBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24
    },
    summarizeText: {
        fontSize: 11,
        lineHeight: 13,
        color: '#455562',
        opacity: 0.7
    },
    summarize: {
        width: 8,
        height: 8,
        marginRight: 8
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnershipClientsTab);
