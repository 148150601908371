import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, Image, StyleSheet } from 'react-native';

import {
    fetchBarChartRAM,
} from '../../../actions/hosting/actions';
import { ramIcon } from '../../../icons';
import { formatMemory } from '../../../utils/general';
import StatisticsSevenDays from '../../General/Statistics/StatisticsSevenDays';

function normalizeMemory(memory) {
    return memory / 1024 / 1024;
}

function normalizeDatapointsRegardingTariff(datapoints, memoryLimit) {
    if (datapoints) {
        return datapoints.map(datapoint => ({
            value: (normalizeMemory(datapoint.value) * 100) / memoryLimit,
            date: datapoint.date
        }));
    }
    return [];
}

function getMemoryPercent(memoryLimit, cumulative) {
    const normalizedValue = normalizeMemory(cumulative);
    return (normalizedValue * 100) / memoryLimit;
}

function normalizedCumulative(cumulative, memoryLimit) {
    return Math.round(getMemoryPercent(memoryLimit, cumulative));
}


function StatisticsSevenDaysRAM(props) {
    const {
        isError,
        isLoaded,
        isLoading,
        container,
        cumulative,
        datapoints,
        memoryLimit,
        fetchBarChartRAM
    } = props;

    React.useEffect(() => {
        if (!isLoaded && !isError && !isLoading) {
            fetchBarChartRAM(container.id);
        }
    }, []);
    return <View style={styles.wrapper}>
        <Image source={ramIcon} style={styles.icon}/>
        <View style={styles.textBlock}>
            <Text style={styles.title}>RAM</Text>
            <Text style={styles.subTitle}>
                {formatMemory(memoryLimit)}
            </Text>
        </View>
        <StatisticsSevenDays
            isLoading={datapoints == null && isLoading}
            isError={isError}
            generalValue={normalizedCumulative(cumulative, memoryLimit)}
            days={normalizeDatapointsRegardingTariff(datapoints, memoryLimit)}
            formatGeneralValue={value => `~${value && value.toFixed(0)}%`}/>
    </View>;
}

StatisticsSevenDaysRAM.propTypes = {
    container: PropTypes.shape({}),
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    cumulative: PropTypes.number,
    memoryLimit: PropTypes.number,
    fetchBarChartRAM: PropTypes.func.isRequired,
    datapoints: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
    }))
};

const mapStateToProps = state => ({
    ...state.hosting.barChartRam,
    container: state.hosting.container,
    memoryLimit: state.hosting.container.memory_limit
});
const mapDispatchToProps = dispatch => bindActionCreators({
    fetchBarChartRAM
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 16,
        paddingRight: 19,
        paddingBottom: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 1,
        backgroundColor: '#ffffff'
    },
    textBlock: {
        flex: 1
    },
    title: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14,
        fontWeight: '600'
    },
    subTitle: {
        color: '#455562',
        fontSize: 10,
        lineHeight: 14
    },
    icon: {
        height: 24,
        width: 24,
        marginRight: 12
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsSevenDaysRAM);
