import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text } from 'react-native';

import { flushErrors } from '../../../actions/forms/actions';
import { virtualhostDelete } from '../../../actions/hosting/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'VirtualhosDeleteConfirmForm';

function VirtualhostDelete(props) {
    const {
        errors,
        onClose,
        inProgress,
        containerId,
        flushErrors,
        virtualhostId,
        virtualhostName,
        virtualhostDelete,
    } = props;
    const navigation = useNavigation();
    React.useEffect(() => {
        flushErrors()
    }, []);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Подтвердите удаление сайта &laquo;{virtualhostName}&raquo;</Text>
        <Text style={styles.text}>При удалении web-сайта будут удалены всe папки и файлы, а также базы данных</Text>
        {errors.__all__ && <Text style={styles.error}>{errors.__all__}</Text>}
        <View style={styles.buttons}>
            <AcceptButton
                type='danger'
                onSubmit={() => virtualhostDelete(formId, virtualhostId, () => {
                    onClose();
                    navigation.navigate('HostingContainer', { containerId });
                })}
                title='Удалить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

VirtualhostDelete.propTypes = {
    onClose: PropTypes.func,
    inProgress: PropTypes.bool,
    errors: PropTypes.shape({}),
    virtualhostDelete: PropTypes.func,
    flushErrors: PropTypes.func,
    virtualhostId: PropTypes.number,
    virtualhostName: PropTypes.string,
    containerId: PropTypes.number,
};

const mapStateToProps = (state) => ({
    inProgress: state.hosting.virtualhostDeleteInProgress,
    errors: state.forms.errors[formId] || {},
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    virtualhostDelete
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 44,
        paddingBottom: 42,
        paddingLeft: 40,
        paddingRight: 40,
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 10,
        color: '#455562',
    },
    buttons: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
        fontSize: 13,
    },
    text: {
        color: '#888888',
        fontSize: 14,
        lineHeight: 20,
        marginBottom: 10,
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(VirtualhostDelete);