import { FORM_ERRORS, FLUSH_ERRORS, FLUSH_ERROR } from '../actions/forms/actionTypes';

export default () => (state = { errors: {} }, action) => {
    switch (action.type) {
    case FORM_ERRORS: {
        const { formId, errors } = action.data;
        const newErrors = { ...state.errors, [formId]: { ...errors } };
        return { ...state, errors: newErrors };
    }
    case FLUSH_ERRORS: {
        return { ...state, errors: {} };
    }
    case FLUSH_ERROR: {
        const { formId, errorType } = action.data;
        if (formId && errorType) {
            const newErrors = { ...state.errors[formId] };
            delete newErrors[errorType];
            return { ...state, errors: { ...state.errors, [formId]: { ...newErrors } } };
        }
        return state;
    }
    default:
        return state;
    }
};
