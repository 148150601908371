import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { bindActionCreators } from 'redux';
import { useNavigation } from '@react-navigation/native';

import Errors from '../../General/Form/Errors';
import { calculateDatastoragePrice } from '../utils';
import { flushErrors } from '../../../actions/forms/actions';
import { vmDelete } from '../../../actions/cvds/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'DeleteVMModalForm';

function methodButton(currentMethod, methodName, setMethod, text) {
    const isCurrentMethod = currentMethod === methodName;
    return <TouchableOpacity onPress={() => setMethod(methodName)}
        style={[styles.methodButtonBlock, isCurrentMethod ? styles.activeMethod : {}]}>
        <View style={isCurrentMethod ? styles.activeOuterCircle : styles.outerCircle}>
            {isCurrentMethod && <View style={styles.innerCircle}/>}
        </View>
        <Text style={[styles.methodButtonTitle, { color: isCurrentMethod ? '#0884DE'
            : '#455562'}]}>{text}</Text>
    </TouchableOpacity>;
}

function DeleteVMModal(props) {
    const {
        vm,
        errors,
        onClose,
        inProgress,
        flushErrors,
        vmDelete
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);
    const navigation = useNavigation();
    const [method, setMethod] = React.useState(null);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Удаление ВМ {vm.name}</Text>
        <Text style={styles.subtitle}>Выберите способ удаления виртуальной машины</Text>
        <View style={styles.methodsBlock}>
            {methodButton(method, 'completely', setMethod, 'Удалить полностью')}
            {methodButton(method, 'archived', setMethod,
                `Удалить с сохранением копии в хранилище (${
                    calculateDatastoragePrice().toFixed(2)} ₽/ГБ в мес)`)}
        </View>
        {errors.__all__ && <Errors errors={errors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => vmDelete(formId,
                    { vmId: vm.id, archived: method === 'archived' }, onClose,
                    () => navigation.navigate('CVDSStack'))}
                type='danger'
                title='Удалить'
                disabled={method == null}
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
};

DeleteVMModal.propTypes = {
    onClose: PropTypes.func,
    vm: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    vmDelete: PropTypes.func,
    inProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    inProgress: state.cvds.vmUpdateInProgress,
    errors: state.forms.errors[formId] || {}
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors, vmDelete
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    subtitle: {
        color: '#4E5F6E',
        marginBottom: 15,
        fontSize: 12,
        lineHeight: 16
    },
    methodsBlock: {
        marginBottom: 32
    },
    methodButtonTitle: {
        fontSize: 12,
        lineHeight: 16
    },
    methodButtonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 15,
        marginBottom: 8,
        borderWidth: 1,
        borderColor: 'rgba(69,85,98,0.25)',
        backgroundColor: '#ffffff'
    },
    activeMethod: {
        backgroundColor: '#F5FAFD',
        borderColor: '#0F79D5'
    },
    outerCircle: {
        height: 18,
        width: 18,
        marginRight: 13,
        borderTopWidth: 1,
        borderTopColor: 'rgba(161, 170, 177, 0.5)',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(161, 170, 177, 0.5)',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(161, 170, 177, 0.5)',
        borderRightWidth: 1,
        borderRightColor: 'rgba(161, 170, 177, 0.5)',
        borderRadius: 9,
        backgroundColor: '#f5f5f5'
    },
    activeOuterCircle: {
        height: 18,
        width: 18,
        marginRight: 13,
        borderTopWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderLeftWidth: 1,
        borderLeftColor: '#0f79d5',
        borderRightWidth: 1,
        borderRightColor: '#0f79d5',
        borderRadius: 9,
        backgroundColor: '#ffffff',
        paddingTop: 4,
        paddingLeft: 4
    },
    innerCircle: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0f79d5'
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteVMModal);
