import {
    IPS_RESERVED_FREE_FETCHED_SUCCESSFULLY,
    IPS_RESERVED_FREE_FETCHED_IN_PROGRESS,
    IPS_RESERVED_FREE_FETCHED_WITH_ERRORS,
} from '../../actions/ips/actionTypes';

export default () => {
    const defaultState = {
        ipsReservedFree: null,
        ipsReservedFreeWihtError: false,
        ipsReservedFreeInProgress: false,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case IPS_RESERVED_FREE_FETCHED_SUCCESSFULLY: {
            return {
                ...state,
                ipsReservedFree: action.data,
                ipsReservedFreeInProgress: false,
            };
        }
        case IPS_RESERVED_FREE_FETCHED_IN_PROGRESS: {
            return {
                ...state,
                ipsReservedFree: null,
                ipsReservedFreeInProgress: true,
            };
        }
        case IPS_RESERVED_FREE_FETCHED_WITH_ERRORS: {
            return {
                ...state,
                ipsReservedFreeInProgress: false,
                ipsReservedFreeWihtError: true,
            };
        }
        default:
            return state;
        }
    };
};
