import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useFocusEffect } from '@react-navigation/native';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import {
    fetchUserMailInfo,
    fetchPacketList,
    fetchMailCalculation,
    fetchPreferredPacket,
    fetchMailStatisticInfo,
} from '../actions/mail/actions';
import { screenHeaderStyles } from '../utils/styles';
import MailPacketPage from '../components/Mail/MailPacketPage';
import MailResourceLoader from '../components/Mail/MailResourceLoader';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const MailPacketStack = createStackNavigator();

function MailPacketStackScreen(props) {
    const {
        navigation,
    } = props;
    const dispatch = useDispatch();

    const {
        calculation,
        preferredPacket,
        packetsList,
        userInfo,
        packetsListInProgress,
        userInfoInProgress,
        userStatisticsInfo
    } = useSelector((state) => state.mail);
    const insets = useSafeAreaInsets();

    useFocusEffect(useCallback(() => {
        dispatch(fetchMailCalculation());
        dispatch(fetchMailStatisticInfo());
        if (!userInfoInProgress && userInfo == null) {
            dispatch(fetchUserMailInfo());
        }
        if (packetsList == null && !packetsListInProgress) {
            dispatch(fetchPacketList());
        }
        if (preferredPacket == null) {
            dispatch(fetchPreferredPacket());
        }
    }, []));
    return <MailPacketStack.Navigator>
        <MailPacketStack.Screen name="MailPacketStack" component={
            (!packetsListInProgress && userInfo != null
                && calculation != null && userStatisticsInfo != null)
                ? MailPacketPage : MailResourceLoader
        } options={{
            headerTitle: () => <ScreenHeaderTitle title='Пакеты почты' />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => {
                    navigation.navigate('Mail', {
                        screen: 'MailTabs',
                        params: { screen: 'MailStatistics' }
                    });
                },
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </MailPacketStack.Navigator>;
}

MailPacketStackScreen.propTypes = {
    navigation: PropTypes.shape({}),
};

export default MailPacketStackScreen;
