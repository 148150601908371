import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';

import { reservedIcon, closedIcon } from '../../icons';
import {
    IPv4,
    IPv6,
    IP_PUBLIC_POOL,
    IP_PRIVATE_POOL
} from '../../constants/cvds';
import { isActiveVM } from './utils';
import { isEmpty } from '../../utils/general';
import CVDSWebsockets from './CVDSWebsockets';
import { switchPrivateNetwork, fetchProtectedWebsites } from '../../actions/cvds/actions';
import Switch from '../General/Switch/Switch';
import AntiDDoS from './AntiDDoS';
import { ModalContext } from '../../contexts/ModalContext';
import Modal from '../General/Modal/Modal';
import AddIPModal from './Modals/AddIPModal';
import DeleteProtectedIPModal from './Modals/DeleteProtectedIPModal';
import CopypableText from '../General/CopypableText/CopypableText';
import { addCustomNotification } from '../../actions/notifications/actions';
import { blockedButtonTooltips } from '../../constants/general';
import Errors from '../General/Form/Errors';

const formId = 'SwitchPrivateNetworkForm';

function isShowIp(ip) {
    return ip.pool === IP_PUBLIC_POOL || ip.pool === IP_PRIVATE_POOL;
}

function getIpV4ips(ips) {
    return ips.filter(ip => isShowIp(ip) && ip.version === IPv4);
}

function getIpV6ips(ips) {
    return ips.filter(ip => isShowIp(ip) && ip.version === IPv6);
}

function getIps(ips) {
    return [...getIpV4ips(ips), ...getIpV6ips(ips)];
}

function getLanSwitchLabel(lan_ip) {
    if (lan_ip == null) {
        return <View>
            <Text style={styles.lanTitle}>Включить ВМ в приватную сеть</Text>
            <Text style={styles.lanText}>
                Объедините свои VDS в локальную сеть бесплатно
            </Text>
        </View>;
    }
    return <View>
        <Text style={styles.lanTitle}>ВМ включена в приватную сеть</Text>
        <View style={styles.lanTextBlock}>
            <Text style={styles.lanText}>
                Адрес в приватной сети:
            </Text>
            <CopypableText text={lan_ip} additionalTextStyles={[styles.lanText, { marginLeft: 5 }]}/>
        </View>
    </View>;
}

function getIpsList(vm, isEnabledActions, onClickDelete, setDeletedIP,
    addCustomNotification, disabledText) {
    return <View>
        {getIps(vm.ips).map(item => <View key={`ip-list-${item.ip}`}
            style={styles.ipItem}>
            <CopypableText text={item.ip}/>
            <View>
                <View style={styles.ipActionsBlock}>
                    {item.is_reserved && <Image source={reservedIcon} style={styles.reservedIcon} />}
                    <TouchableOpacity onPress={() => {
                        if (isEnabledActions) {
                            setDeletedIP(item.ip);
                            onClickDelete(true);
                        } else {
                            addCustomNotification(disabledText);
                        }
                    }} style={[{ opacity: isEnabledActions ? 1 : 0.5 }]}>
                        <Image source={closedIcon} style={styles.closedIcon} />
                    </TouchableOpacity>
                </View>
            </View>
        </View>)}
    </View>
}

const addIPModalId = 'add-ip-modal';
const deleteIPModalId = 'delete-ip-modal';

function VMNetwork(props) {
    const {
        vm,
        errors,
        protectedWebsites,
        switchPrivateNetwork,
        addCustomNotification,
        fetchProtectedWebsites,
        protectedWebsitesInProgress,
    } = props;
    const isEnabledActions = !isEmpty(vm) && isActiveVM(vm.state) && vm.is_managed;
    const [deletedIP, setDeletedIP] = React.useState(null);
    const { openModal, closeModal, checkModal } = useContext(ModalContext);
    const disabledText = (!isEmpty(vm) && isActiveVM(vm.state) && !vm.is_managed)
        ? blockedButtonTooltips.cvds.DisableGuestAgent
        : blockedButtonTooltips.cvds[vm.state];
    useEffect(() => {
        if (
            !isEmpty(vm) && vm.is_protected
            && protectedWebsites == null
            && !protectedWebsitesInProgress
        ) {
            fetchProtectedWebsites(vm.id);
        }
        return () => {};
    }, [vm]);

    return <View style={styles.wrapper}>
        {!isEmpty(vm) && <React.Fragment>
            <View style={styles.ipListHeader}>
                <Text style={styles.title}>IP-адреса</Text>
                <TouchableOpacity style={styles.addButton}
                    onPress={() => {
                        if (isEnabledActions) {
                            openModal(addIPModalId);
                        } else {
                            addCustomNotification(disabledText);
                        }
                    }}>
                    <Text style={[styles.addButtonText,
                        { color: isEnabledActions ? '#0F79D5' : '#888888' }
                    ]}>Добавить</Text>
                </TouchableOpacity>
            </View>
            {getIpsList(vm, isEnabledActions, () => openModal(deleteIPModalId),
                ip => setDeletedIP(ip), addCustomNotification, disabledText)}
            <Text style={styles.title}>приватная сеть</Text>
            <View style={styles.lanBlock}>
                <Switch
                    label={getLanSwitchLabel(vm.lan_ip)}
                    isEnable={vm.lan_ip != null}
                    disabled={!isEnabledActions}
                    disabledText={disabledText}
                    onChange={() => switchPrivateNetwork(formId, vm, vm.lan_ip != null)}/>
                {errors.__all__ && <Errors errors={errors.__all__}/>}
            </View>
            <AntiDDoS />
            <Modal isModalShowed={checkModal(addIPModalId)}
                component={<AddIPModal onClose={() => closeModal()}/>}
                onClose={() => closeModal()} />
            <Modal isModalShowed={checkModal(deleteIPModalId)}
                component={<DeleteProtectedIPModal ip={deletedIP}
                    onClose={() => closeModal()}/>}
                onClose={() => closeModal()} />
        </React.Fragment>}
        <CVDSWebsockets />
    </View>
};

VMNetwork.propTypes = {
    vm: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    protectedWebsites: PropTypes.shape({}),
    switchPrivateNetwork: PropTypes.func,
    addCustomNotification: PropTypes.func,
    fetchProtectedWebsites: PropTypes.func,
    protectedWebsitesInProgress: PropTypes.bool
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    protectedWebsitesInProgress: state.cvds.protectedWebsitesInProgress,
    protectedWebsites: state.cvds.protectedWebsites,
    errors: state.forms.errors[formId] || {}
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    switchPrivateNetwork, addCustomNotification, fetchProtectedWebsites
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 8
    },
    title: {
        marginTop: 16,
        marginBottom: 10,
        marginLeft: 20,
        color: '#455562',
        opacity: 0.75,
        fontSize: 11,
        lineHeight: 13,
        fontWeight: '600',
        letterSpacing: 1.38,
        textTransform: 'uppercase'
    },
    ipItem: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 15,
        paddingLeft: 18,
        paddingRight: 15,
        paddingBottom: 17,
        marginBottom: 1,
        backgroundColor: '#ffffff'
    },
    reservedIcon: {
        height: 13,
        width: 10
    },
    lanBlock: {
        backgroundColor: '#ffffff',
        paddingTop: 16,
        paddingLeft: 19,
        paddingRight: 16,
        paddingBottom: 20
    },
    lanTitle: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '600',
        lineHeight: 21,
        marginBottom: 4
    },
    lanTextBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    lanText: {
        color: '#455562',
        fontSize: 11,
        maxWidth: 200,
        lineHeight: 14
    },
    lanIp: {
        marginLeft: 3,
        color: '#455562',
        fontSize: 11,
        lineHeight: 14,
        fontWeight: '600',
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        borderBottomColor: 'rgba(69, 85, 98, 0.7)'
    },
    ipListHeader: {
        flex: 1,
        paddingRight: 20,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    addButtonText: {
        color: '#0f79d5',
        marginTop: 16,
        marginBottom: 10,
        fontSize: 11,
        lineHeight: 13,
        fontWeight: '500'
    },
    closedIcon: {
        width: 13,
        height: 13,
        marginLeft: 15
    },
    ipActionsBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(VMNetwork);
