import {
    SET_SWIPE_STATE
} from "../actions/ui/actionTypes";

export default () => (state = {
    swipeState: false,
}, action) => {
    switch (action.type) {
    case SET_SWIPE_STATE: {
        return {
            ...state,
            swipeState: action.data.state
        }
    }
    default:
        return state;
    }
};
