import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
    View,
    StyleSheet,
    Text,
    Image,
    TouchableOpacity
} from 'react-native';

import { ModalContext } from '../../../contexts/ModalContext';
import Modal from '../../General/Modal/Modal';
import { mainWrapperStyles } from '../../../utils/styles';
import { rightArrowBoldIcon } from '../../../icons';
import AccountChangePostAddr from '../AccountModal/AccountChangePostAddr';

function getInnOgrnInfo(orgData) {
    const innOgrnInfo = [];
    if (orgData.inn.length !== 0) {
        innOgrnInfo.push(`ИНН ${orgData.inn}`);
    }
    if (orgData.ogrn.length !== 0) {
        innOgrnInfo.push(`ОГРН ${orgData.ogrn}`);
    }
    return innOgrnInfo.join(', ');
}

const changePostAddrModalId = 'change-addr-modal';

function AccountRequisites() {
    const { closeModal, openModal, checkModal } = useContext(ModalContext);
    const { account } = useSelector((state) => state.account);
    const isPrivate = account.user_type === 'Private';
    const orgData = account.org_data;
    return <View>
        {account.is_master && <View style={styles.wrapper}>
            <Text style={styles.title}>Реквизиты</Text>
            <View>
                <Text style={styles.subTitle}>{account.name}</Text>
                {!isPrivate && (orgData.inn.length !== 0 || orgData.ogrn.length !== 0)
                    && <Text style={styles.innOgrnStyle}>{getInnOgrnInfo(orgData)}</Text>}
            </View>
            <View style={styles.additionalInfo}>
                <View style={styles.postAddrBlock}>
                    <Text style={styles.subTitle}>Почтовый адрес</Text>
                    <TouchableOpacity
                        onPress={() => openModal(changePostAddrModalId)}
                        style={styles.changeBlock}
                    >
                        <Text style={styles.changeText}>Изменить</Text>
                        <Image source={rightArrowBoldIcon} style={styles.rightIcon} />
                    </TouchableOpacity>
                </View>
                <Text style={styles.innOgrnStyle}>
                    {account.post_addr != null && account.post_addr.length !== 0
                        ? account.post_addr : 'Адрес не указан'}
                </Text>
            </View>
            {!isPrivate && <View style={styles.additionalInfo}>
                <Text style={styles.subTitle}>Расчетный счет</Text>
                {orgData && orgData.rinv
                    && <Text style={styles.additionalInfoBank}>{orgData.rinv}</Text>}
                {orgData && orgData.bank != null && orgData.bank.length > 0
                    && <Text style={styles.additionalInfoBank}>{orgData.bank}</Text>}
            </View>}
        </View>}
        <Modal isModalShowed={checkModal(changePostAddrModalId)}
            component={<AccountChangePostAddr onClose={() => closeModal()}/>}
            onClose={() => closeModal()} />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        ...mainWrapperStyles,
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 24,
        paddingTop: 19,
    },
    title: {
        color: '#455562',
        marginBottom: 12,
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 14,
    },
    subTitle: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 14,
        marginBottom: 4,
    },
    additionalInfo: {
        marginTop: 24,
    },
    additionalInfoLink: {
        fontWeight: '500',
        display: 'inline',
    },
    additionalInfoBank: {
        opacity: 0.7,
        fontSize: 11,
        marginBottom: 5,
        lineHeight: 16,
        color: '#455562',
        maxWidth: 175,
    },
    innOgrnStyle: {
        fontSize: 11,
        opacity: 0.7,
        lineHeight: 16,
        color: '#455562',
        maxWidth: 175,
    },
    blueText: {
        color: '#0f79d5',
    },
    grayText: {
        color: '#455562',
        opacity: 0.7,
    },
    postAddrBlock: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    changeBlock: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    changeText: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 12,
        opacity: 0.7,
    },
    rightIcon: {
        width: 4.7,
        height: 8,
        marginLeft: 8,
    },
});

export default AccountRequisites;
