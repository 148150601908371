import React from 'react';
import PropTypes from 'prop-types';
import {
    View, StyleSheet, TouchableWithoutFeedback, TouchableOpacity,
    ScrollView
} from 'react-native';
import Modal from 'modal-react-native-web';

const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    modalView: {
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: "white",
        borderRadius: 6,
        width: '100%'
    },
});

function WebModal({
    isModalShowed = false,
    containerStyles = [],
    modalViewStyles = [],
    onClose = () => {},
    component
}) {
    Modal.setAppElement('body');

    return <View>
        <Modal visible={isModalShowed}
            transparent={true}
            onRequestClose={() => onClose()}>
            <TouchableOpacity style={[styles.centeredView, ...containerStyles]}
                activeOpacity={1}
                onPressOut={(e) => {
                    e.preventDefault();
                    onClose();
                }}>
                <View style={{ paddingRight: 5, paddingLeft: 5, width: '100%' }}>
                    <TouchableWithoutFeedback>
                        <View style={[styles.modalView, ...modalViewStyles]}>
                            <ScrollView>
                                {isModalShowed && component}
                            </ScrollView>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </TouchableOpacity>
        </Modal>
    </View>;
}

WebModal.propTypes = {
    isModalShowed: PropTypes.bool,
    containerStyles: PropTypes.arrayOf(PropTypes.shape({})),
    modalViewStyles: PropTypes.arrayOf(PropTypes.shape({})),
    onClose: PropTypes.func,
    component: PropTypes.element
};

export default WebModal;
