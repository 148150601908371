import {
    BACKUPS_FETCHED_IN_PROGRESS,
    BACKUPS_FETCHED_SUCCESSFULLY,
    BACKUPS_FETCHED_WITH_ERRORS,
    BACKUP_DATES_FETCHED_IN_PROGRESS,
    BACKUP_DATES_FETCHED_SUCCESSFULLY,
    BACKUP_DATES_FETCHED_WITH_ERRORS,
} from '../../actions/cvds/actionTypes';

export default () => {
    const defaultState = {
        backups: null,
        backupsInProgress: false,
        error: false,
        backupDates: null,
        backupDatesInProgress: false,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case BACKUPS_FETCHED_IN_PROGRESS: {
            return {
                ...state,
                error: false,
                backupsInProgress: true,
            };
        }
        case BACKUPS_FETCHED_SUCCESSFULLY: {
            return {
                ...state,
                backups: action.data,
                error: false,
                backupsInProgress: false,
            };
        }
        case BACKUPS_FETCHED_WITH_ERRORS: {
            return {
                ...state,
                error: true,
                backupsInProgress: false,
            };
        }
        case BACKUP_DATES_FETCHED_IN_PROGRESS: {
            return {
                ...state,
                error: false,
                backupDates: null,
                backupDatesInProgress: true,
            };
        }
        case BACKUP_DATES_FETCHED_SUCCESSFULLY: {
            return {
                ...state,
                backupDates: action.data,
                error: false,
                backupDatesInProgress: false,
            };
        }
        case BACKUP_DATES_FETCHED_WITH_ERRORS: {
            return {
                ...state,
                error: true,
                backupDatesInProgress: false,
            };
        }
        default:
            return state;
        }
    };
};
