import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text } from 'react-native';
import { packetsMap } from '../constants';

import Checkbox from '../../General/Form/Checkbox';
import { packetKey } from '../../../utils/general';
import { formatPrice } from '../../../utils/general';

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 15,
        paddingBottom: 17,
        paddingLeft: 20,
        paddingRight: 18,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    packetInfo: {
        width: 186
    },
    packetTitle: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 16
    },
    packetDescription: {
        opacity: 0.75,
        color: '#455562',
        fontSize: 10,
        lineHeight: 20
    },
    packetCost: {
        fontWeight: '600',
        color: '#455562',
        fontSize: 12,
        lineHeight: 14
    }
});

function FinanceAwaitingPacket(props) {
    const {
        packet,
        onToggle,
        checkedAwaitingPackets
    } = props;
    return <View style={styles.wrapper}>
        <View style={styles.checkboxContainer}>
            <Checkbox style={styles.checkbox}
                value={checkedAwaitingPackets.find((ch) => ch === packetKey(packet)) != null}
                onValueChange={() => onToggle(packet)}>
                <View style={styles.packetInfo}>
                    <Text style={styles.packetTitle}>
                        {packetsMap[packet.service].title(packet)}
                    </Text>
                    <Text style={styles.packetDescription}>
                        {packetsMap[packet.service].description(packet)}
                    </Text>
                </View>
            </Checkbox>
        </View>
        <Text style={styles.packetCost}>{formatPrice(packet.cost.toFixed(2))} ₽</Text>
    </View>;
}

FinanceAwaitingPacket.propTypes = {
    packet: PropTypes.shape({
        cost: PropTypes.number,
        service: PropTypes.string,
        resource_id: PropTypes.string,
    }),
    onToggle: PropTypes.func,
    checkedAwaitingPackets: PropTypes.arrayOf(PropTypes.string)
};

export default FinanceAwaitingPacket;
