import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    Text,
    View,
    StyleSheet,
    TouchableOpacity
} from 'react-native';

import Input from '../General/Form/Input';
import Button from '../General/Button/Button';
import WithdrawalTypeButton from './WithdrawalTypeButton';
import { flushErrors } from '../../actions/forms/actions';
import { withdrawals } from '../../actions/partnership/actions';
import { blockedButtonTooltips } from '../../constants/general';
import { partnershipWithdrawalTypes } from '../../constants/partnership';

const formId = 'WithdrawalModalForm';

function PartnershipWithdrawal() {
    const dispatch = useDispatch();
    const [amount, setAmount] = React.useState('');
    const errors = useSelector((state) => state.forms.errors[formId]) || {};
    const { partnership, withdrawalInProgress } = useSelector((state) => state.partnership);
    const [withdrawalType, setWithdrawalType] = React.useState('balance');

    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);
    const navigation = useNavigation();
    return <View style={styles.wrapper}>
        {partnership && <React.Fragment>
            <View style={styles.form}>
                <Text style={styles.title}>Сумма</Text>
                <View style={styles.amountInput}>
                    <Input textInputAttrs={{
                        onChangeText: (v) => setAmount(v),
                        placeholder: withdrawalType === 'balance' ? 'от 10 ₽' : 'от 500 ₽',
                        value: amount
                    }} errors={errors.amount}
                    additionalWrapperStyles={[{ flex: 1, flexDirection: 'row', alignItems: 'center' }]}
                    additionalInputStyles={[{ width: 123, marginRight: 12 }]}
                    additionalErrorStyles={[{ flex: 1 }]} />
                    <View style={styles.hint}>
                        <Text style={styles.hintText}>Доступно:</Text>
                        <TouchableOpacity onPress={() => setAmount(partnership.balance)}>
                            <Text style={styles.hintAmount}>{partnership.balance} ₽</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <Text style={styles.subtitle}>куда вывести средства</Text>
                <View>
                    <WithdrawalTypeButton withdrawalType={withdrawalType}
                        setWithdrawalType={setWithdrawalType}
                        buttonType='balance'
                        buttonTitle='На баланс аккаунта'
                        additionalButtonStyles={[{
                            borderBottomWidth: withdrawalType === 'balance' ? 1 : 0
                        }]}/>
                    <WithdrawalTypeButton withdrawalType={withdrawalType}
                        setWithdrawalType={setWithdrawalType}
                        buttonType='bank'
                        buttonTitle='На банковский счет'
                        buttonSubtitle={partnership.rinv}
                        buttonHint={partnership.bank}
                        needEditIcon={true}
                        onPress={() => navigation.navigate('ChangeRequisites')}
                        additionalButtonStyles={[{
                            borderTopWidth: withdrawalType === 'bank' ? 1 : 0
                        }]}/>
                </View>
            </View>
            <Button additionalWrapperStyles={[styles.button]}
                inProgress={withdrawalInProgress}
                disabled={partnership.has_untreated_withdrawal}
                disabledText={blockedButtonTooltips.partnership.hasUntreatedWithdrawal}
                onPress={() => dispatch(withdrawals(formId,
                    { amount, type: partnershipWithdrawalTypes[withdrawalType] },
                    () => {
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        } else {
                            navigation.navigate('MainStack', {
                                screen: 'Partnership',
                                params: {
                                    screen: 'PartnershipTabs',
                                    params: {
                                        screen: 'PartnershipInfo'
                                    }
                                }
                            });
                        }
                    }))}
                title="Вывести средства" />
        </React.Fragment>}
    </View>;
}

PartnershipWithdrawal.propTypes = {
    partnership: PropTypes.shape({
        rinv: PropTypes.string,
        bank: PropTypes.string,
        balance: PropTypes.number,
        has_untreated_withdrawal: PropTypes.bool
    }),
    errors: PropTypes.shape({})
};

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
        backgroundColor: '#ffffff',
        flex: 1,
        paddingTop: 24,
        paddingBottom: 24
    },
    form: {
        flex: 1
    },
    title: {
        opacity: 0.75,
        marginLeft: 20,
        marginBottom: 14,
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.38,
        lineHeight: 13,
        textTransform: 'uppercase'
    },
    amountInput: {
        marginLeft: 20,
        marginRight: 18,
        marginBottom: 32
    },
    hint: {
        flex: 1,
        marginTop: 8,
        flexDirection: 'row'
    },
    hintText: {
        fontSize: 12,
        lineHeight: 14,
        color: 'rgba(69, 85, 98, 0.7)'
    },
    hintAmount: {
        fontSize: 12,
        lineHeight: 14,
        marginLeft: 4,
        fontWeight: '600',
        color: '#0f79d5'
    },
    subtitle: {
        opacity: 0.75,
        marginLeft: 20,
        marginBottom: 16,
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.38,
        lineHeight: 13,
        textTransform: 'uppercase'
    },
    button: {
        marginLeft: 20,
        marginRight: 18
    }
});

export default PartnershipWithdrawal;
