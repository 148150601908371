const BaseResource = require('./resource');

class PanelResource extends BaseResource {
    async financeInfo() {
        return this.makeRequest('get', 'user-finance-info/');
    }

    async userActiveServices() {
        return this.makeRequest('get', 'list-active-services/');
    }
}

PanelResource.resource = null;
PanelResource.service = 'panel';

module.exports = PanelResource;
