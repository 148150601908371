import { isFunction } from '@netangels/core/utils/functions';
import {
    flushErrors,
    formErrors,
} from '../forms/actions';

import MailResource from '../../gateway/resources/mail';
import MailDomainsResource from '../../gateway/resources/mail/domains';
import MailboxesResource from '../../gateway/resources/mail/mailboxes';

const {
    FETCH_MAIL_DOMAINS_IN_PROGRESS,
    FETCH_MAIL_DOMAINS_SUCCESSFULLY,
    FETCH_MAIL_DOMAINS_WITH_ERRORS,
    FETCH_DOMAIN_IN_PROGRESS,
    FETCH_DOMAIN_SUCCESSFULLY,
    FETCH_DOMAIN_WITH_ERRORS,
    UPDATE_DOMAIN_SUCCESSFULLY,
    DELETE_DOMAIN_SUCCESSFULLY,
    FETCH_USER_MAIL_INFO_IN_PROGRESS,
    FETCH_USER_MAIL_INFO_SUCCESSFULLY,
    FETCH_USER_MAIL_INFO_WITH_ERRORS,
    FETCH_MAILBOXES_IN_PROGRESS,
    FETCH_MAILBOXES_SUCCESSFULLY,
    FETCH_MAILBOXES_WITH_ERRORS,
    FETCH_MORE_MAILBOXES_IN_PROGRESS,
    FETCH_MORE_MAILBOXES_SUCCESSFULLY,
    FETCH_MORE_MAILBOXES_WITH_ERRORS,
    CREATE_MAILBOX_SUCCESSFULLY,
    SEARCH_MAIL_DOMAINS_IN_PROGRESS,
    SEARCH_MAIL_DOMAINS_SUCCESSFULLY,
    SEARCH_MAIL_DOMAINS_WITH_ERRORS,
    SEARCH_MAIL_DOMAINS_INIT,
    CHANGE_MAIL_DOMAINS_SEARCH_QUERY_SUCCESSFULLY,
    CREATE_DOMAIN_SUCCESSFULLY,
    CHANGE_MAILBOX_SUCCESSFULLY,
    DELETE_MAILBOX_SUCCESSFULLY,
    DELETE_MAILBOXES_SUCCESSFULLY,
    SEARCH_MAILBOXES_INIT,
    SEARCH_MAILBOXES_IN_PROGRESS,
    SEARCH_MAILBOXES_SUCCESSFULLY,
    SEARCH_MAILBOXES_WITH_ERRORS,
    CHANGE_MAILBOXES_SEARCH_QUERY_SUCCESSFULLY,
    FETCH_USER_MAIL_STATISTICS_IN_PROGRESS,
    FETCH_USER_MAIL_STATISTICS_SUCCESSFULLY,
    FETCH_USER_MAIL_STATISTICS_WITH_ERRORS,
    FETCH_DOMAINS_INFO_IN_PROGRESS,
    FETCH_DOMAINS_INFO_SUCCESSFULLY,
    FETCH_MAIL_PACKET_LIST_IN_PROGRESS,
    FETCH_MAIL_PACKET_LIST_SUCCESSFULLY,
    FETCH_MAIL_PACKET_LIST_WITH_ERRORS,
    FETCH_MAIL_PREFERRED_PACKET_SUCCESSFULLY,
    CREATE_MAIL_PACKET_SUCCESSFULLY,
    FETCH_MAIL_CALCULATION_SUCCESSFULLY,
    CANCEL_MAIL_PACKET_SUCCESSFULLY,
    FETCH_MAILBOXES_INIT,
    FETCH_MAILBOX_IN_PROGRESS,
    FETCH_MAILBOX_SUCCESSFULLY,
    FETCH_MAILBOX_WITH_ERRORS,
    FETCH_MORE_MAIL_DOMAINS_SUCCESSFULLY,
    FETCH_MORE_MAIL_DOMAINS_WITH_ERRORS,
    FETCH_MORE_MAIL_DOMAINS_IN_PROGRESS,
    UPDATE_MAIL_QUOTA_IN_PROGRESS,
    UPDATE_MAIL_QUOTA_SUCCESSFULLY,
    UPDATE_MAIL_QUOTA_WITH_ERRORS,
    CHANGE_MAILBOX_IN_PROGRESS,
    CHANGE_MAILBOX_WITH_ERRORS,
    UPDATE_DOMAIN_IN_PROGRESS,
    UPDATE_DOMAIN_WITH_ERRORS,
    CREATE_DOMAIN_IN_PROGRESS,
    CREATE_DOMAIN_WITH_ERRORS,
    CREATE_MAILBOX_IN_PROGRESS,
    CREATE_MAILBOX_WITH_ERRORS,
    CREATE_MAIL_PACKET_IN_PROGRESS,
    CREATE_MAIL_PACKET_WITH_ERRORS,
} = require('./actionTypes');

export const fetchMailStatisticInfo =
    () =>
    async (dispatch, _getState, { gateway }) => {
        dispatch({ type: FETCH_USER_MAIL_STATISTICS_IN_PROGRESS });
        const resource = new MailResource(gateway);
        const response = await resource.userStatisticInfo();
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: FETCH_USER_MAIL_STATISTICS_SUCCESSFULLY,
                data,
            });
        } else {
            dispatch({ type: FETCH_USER_MAIL_STATISTICS_WITH_ERRORS });
        }
    };

export const fetchUserMailInfo =
    () =>
    async (dispatch, _getState, { gateway }) => {
        dispatch({ type: FETCH_USER_MAIL_INFO_IN_PROGRESS });
        const resource = new MailResource(gateway);
        const response = await resource.userMailInfo();
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: FETCH_USER_MAIL_INFO_SUCCESSFULLY,
                data,
            });
        } else {
            dispatch({ type: FETCH_USER_MAIL_INFO_WITH_ERRORS });
        }
    };

export const updateMailQuota =
    (formId, paidQuotaEnabled, callback) =>
    async (dispatch, _getState, { gateway }) => {
        dispatch({ type: UPDATE_MAIL_QUOTA_IN_PROGRESS });
        const resource = new MailResource(gateway);
        const response = await resource.updateMailQuota(paidQuotaEnabled);
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: UPDATE_MAIL_QUOTA_SUCCESSFULLY,
                data,
            });
            if (isFunction(callback)) {
                callback();
            }
            dispatch(fetchDomains());
            dispatch(fetchUserMailInfo());
            dispatch(fetchMailStatisticInfo());
        } else {
            dispatch({ type: UPDATE_MAIL_QUOTA_WITH_ERRORS });
            dispatch(formErrors(formId, data.errors));
        }
    };

export const fetchMailDomainsInfo =
    () =>
    async (dispatch, _getState, { gateway }) => {
        dispatch({ type: FETCH_DOMAINS_INFO_IN_PROGRESS });
        const resource = new MailResource(gateway);
        const response = await resource.mailDomainsInfo();
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: FETCH_DOMAINS_INFO_SUCCESSFULLY,
                data,
            });
        }
    };

export const fetchDomains = (formId, limit) => async (dispatch, _getState, { gateway }) => {
    const resource = new MailDomainsResource(gateway);
    dispatch({ type: FETCH_MAIL_DOMAINS_IN_PROGRESS });
    const response = await resource.list(limit, 0);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_MAIL_DOMAINS_SUCCESSFULLY,
            data,
        });
    } else {
        if ([403, 404].includes(response.status)) {
            dispatch({
                type: FETCH_MAIL_DOMAINS_SUCCESSFULLY,
                data: {
                    entities: [],
                    count: 0,
                },
            });
            return;
        }
        dispatch({ type: FETCH_MAIL_DOMAINS_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const fetchMoreDomains = (
    formId, limit, offset
) => async (dispatch, _getState, { gateway }) => {
    dispatch({ type: FETCH_MORE_MAIL_DOMAINS_IN_PROGRESS });
    const resource = new MailDomainsResource(gateway);
    const response = await resource.list(limit, offset);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_MORE_MAIL_DOMAINS_SUCCESSFULLY,
            data,
        });
    } else {
        dispatch({ type: FETCH_MORE_MAIL_DOMAINS_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const searchDomains = (filterObject) => async (dispatch, getState, { gateway }) => {
    dispatch({ type: SEARCH_MAIL_DOMAINS_IN_PROGRESS });
    const resource = new MailDomainsResource(gateway);
    const response = await resource.searchDomains(filterObject);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: SEARCH_MAIL_DOMAINS_SUCCESSFULLY,
            data,
        });
    } else {
        dispatch({ type: SEARCH_MAIL_DOMAINS_WITH_ERRORS });
    }
};

export const changeDomainsSearchQuery =
    (query, minQueryLength) => async (dispatch) => {
        dispatch({
            type: CHANGE_MAIL_DOMAINS_SEARCH_QUERY_SUCCESSFULLY,
            data: { query },
        });
        if (query.length < minQueryLength) {
            dispatch({ type: SEARCH_MAIL_DOMAINS_INIT });
        }
    };

export const initSearchDomains = () => async (dispatch) => {
    dispatch({
        type: CHANGE_MAIL_DOMAINS_SEARCH_QUERY_SUCCESSFULLY,
        data: { query: '' },
    });
    dispatch({ type: SEARCH_MAIL_DOMAINS_INIT });
};

export const fetchDomain =
    (domainId) =>
    async (dispatch, _getState, { gateway }) => {
        const resource = new MailDomainsResource(gateway);
        dispatch({ type: FETCH_DOMAIN_IN_PROGRESS });
        const response = await resource.domainInfo(domainId);
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: FETCH_DOMAIN_SUCCESSFULLY,
                data,
            });
        } else {
            dispatch({ type: FETCH_DOMAIN_WITH_ERRORS });
            window.location.href = '/mail/v2/';
        }
    };

export const createDomain =
    (formId, { name }, callback) =>
    async (dispatch, _getState, { gateway }) => {
        dispatch({ type: CREATE_DOMAIN_IN_PROGRESS });
        const resource = new MailDomainsResource(gateway);
        const response = await resource.createDomain(name);
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: CREATE_DOMAIN_SUCCESSFULLY,
                data,
            });
            if (isFunction(callback)) {
                callback(data);
            }
        } else {
            dispatch({ type: CREATE_DOMAIN_WITH_ERRORS });
            dispatch(formErrors(formId, data.errors));
        }
    };

export const initMailboxes = () => async (dispatch) => {
    dispatch({ type: FETCH_MAILBOXES_INIT });
};

export const fetchMailboxes = (
    domainId,
    limit,
    offset = 0,
) => async (dispatch, _getState, { gateway }) => {
    const resource = new MailDomainsResource(gateway);
    dispatch({ type: FETCH_MAILBOXES_IN_PROGRESS });
    const response = await resource.mailboxesList(
        domainId,
        limit,
        offset,
    );
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_MAILBOXES_SUCCESSFULLY,
            data,
        });
    } else {
        dispatch({ type: FETCH_MAILBOXES_WITH_ERRORS });
    }
};

export const fetchMoreMailboxes = (
    domainId, limit, offset
) => async (dispatch, _getState, { gateway }) => {
    dispatch({ type: FETCH_MORE_MAILBOXES_IN_PROGRESS });
    const resource = new MailDomainsResource(gateway);
    const response = await resource.mailboxesList(
        domainId,
        limit,
        offset,
    );
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_MORE_MAILBOXES_SUCCESSFULLY,
            data,
        });
    } else {
        dispatch({ type: FETCH_MORE_MAILBOXES_WITH_ERRORS });
    }
};

export const fetchMailboxInfo = (
    mailboxId, callback
) => async (dispatch, _getState, { gateway }) => {
    dispatch({ type: FETCH_MAILBOX_IN_PROGRESS });
    const resource = new MailboxesResource(gateway);
    const response = await resource.info(mailboxId);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_MAILBOX_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({ type: FETCH_MAILBOX_WITH_ERRORS });
    }
};

export const createMailbox = (
    formId, domainId, mailboxData, callback
) => async (dispatch, _getState, { gateway }) => {
    dispatch({ type: CREATE_MAILBOX_IN_PROGRESS });
    const resource = new MailboxesResource(gateway);
    const response = await resource.createMailbox(
        domainId,
        mailboxData,
    );
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CREATE_MAILBOX_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback(data);
        }
    } else {
        dispatch({ type: CREATE_MAILBOX_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const changeMailboxQuota = (
    formId, mailboxId, quota, callback
) => async (dispatch, _getState, { gateway }) => {
    dispatch({ type: CHANGE_MAILBOX_IN_PROGRESS });
    const resource = new MailboxesResource(gateway);
    const response = await resource.updateMailbox(mailboxId, { quota });
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: CHANGE_MAILBOX_SUCCESSFULLY,
            data,
        });
        if (isFunction(callback)) {
            callback();
        }
    } else {
        dispatch({ type: CHANGE_MAILBOX_WITH_ERRORS });
        dispatch(formErrors(formId, data.errors));
    }
};

export const switchMailboxLocal =
    (formId, mailboxId, isLocal, callback) =>
    async (dispatch, _getState, { gateway }) => {
        dispatch({ type: CHANGE_MAILBOX_IN_PROGRESS });
        const resource = new MailboxesResource(gateway);
        const response = await resource.switchMailboxLocal(mailboxId, isLocal);
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: CHANGE_MAILBOX_SUCCESSFULLY,
                data,
            });
            if (isFunction(callback)) {
                callback();
            }
        } else {
            dispatch({ type: CHANGE_MAILBOX_WITH_ERRORS });
            dispatch(formErrors(formId, data.errors));
        }
    };

export const addAliasMailbox =
    (formId, mailboxId, alias, callback) =>
    async (dispatch, _getState, { gateway }) => {
        dispatch({ type: CHANGE_MAILBOX_IN_PROGRESS });
        const resource = new MailboxesResource(gateway);
        const responce = await resource.addAlias(mailboxId, alias);
        const data = await responce.json();
        if (responce.ok) {
            dispatch({
                type: CHANGE_MAILBOX_SUCCESSFULLY,
                data,
            });
            if (isFunction(callback)) {
                callback();
            }
        } else {
            dispatch({ type: CHANGE_MAILBOX_WITH_ERRORS });
            dispatch(formErrors(formId, data.errors));
        }
    };

export const deleteAliasMailbox =
    (formId, mailboxId, alias, callback) =>
    async (dispatch, _getState, { gateway }) => {
        dispatch({ type: CHANGE_MAILBOX_IN_PROGRESS });
        const resource = new MailboxesResource(gateway);
        const responce = await resource.deleteAlias(mailboxId, alias);
        const data = await responce.json();
        if (responce.ok) {
            dispatch({
                type: CHANGE_MAILBOX_SUCCESSFULLY,
                data,
            });
            if (isFunction(callback)) {
                callback();
            }
        } else {
            dispatch({ type: CHANGE_MAILBOX_WITH_ERRORS });
            dispatch(formErrors(formId, data.errors));
        }
    };

export const deleteMailbox =
    (formId, mailboxId, callback) =>
    async (dispatch, _getState, { gateway }) => {
        const resource = new MailboxesResource(gateway);
        const response = await resource.deleteMailbox(mailboxId);
        if (response.ok) {
            dispatch({
                type: DELETE_MAILBOX_SUCCESSFULLY,
                data: { mailboxId },
            });
            if (isFunction(callback)) {
                callback();
            }
        } else {
            const data = await response.json();
            dispatch(formErrors(formId, data.errors));
        }
    };

export const deleteMailboxes =
    (formId, domainId, mailboxesIds, callback) =>
    async (dispatch, _getState, { gateway }) => {
        const resource = new MailDomainsResource(gateway);
        const response = await resource.deleteMailboxes(domainId, mailboxesIds);
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: DELETE_MAILBOXES_SUCCESSFULLY,
                data,
            });
            if (isFunction(callback)) {
                callback();
            }
        } else {
            dispatch(formErrors(formId, data.errors));
        }
    };

export const changeDomainQuota =
    (formId, domainId, quota, callback) =>
    async (dispatch, _getState, { gateway }) => {
        dispatch({ type: UPDATE_DOMAIN_IN_PROGRESS });
        const resource = new MailDomainsResource(gateway);
        const response = await resource.changeQuota(domainId, quota);
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: UPDATE_DOMAIN_SUCCESSFULLY,
                data,
            });
            if (isFunction(callback)) {
                callback();
            }
        } else {
            dispatch({ type: UPDATE_DOMAIN_WITH_ERRORS });
            dispatch(formErrors(formId, data.errors));
        }
    };

export const deleteDomain =
    (formId, domainId, callback) =>
    async (dispatch, _getState, { gateway }) => {
        const resource = new MailDomainsResource(gateway);
        const response = await resource.deleteDomain(domainId);
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: DELETE_DOMAIN_SUCCESSFULLY,
                data: { id: domainId },
            });
            if (isFunction(callback)) {
                callback();
            }
        } else {
            dispatch(formErrors(formId, data.errors));
        }
    };

export const searchMailboxes =
    (domainId, filterObject, limit = 100, offset = 0) =>
    async (dispatch, _getState, { gateway }) => {
        const resource = new MailDomainsResource(gateway);
        dispatch({ type: SEARCH_MAILBOXES_IN_PROGRESS });
        const response = await resource.searchMailboxes(
            domainId,
            filterObject,
            limit,
            offset,
        );
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: SEARCH_MAILBOXES_SUCCESSFULLY,
                data,
            });
        } else {
            dispatch({ type: SEARCH_MAILBOXES_WITH_ERRORS });
        }
    };

export const changeSearchMailboxesQuery =
    (query, minQueryLength) => async (dispatch) => {
        dispatch({
            type: CHANGE_MAILBOXES_SEARCH_QUERY_SUCCESSFULLY,
            data: { query },
        });
        if (query.length < minQueryLength) {
            dispatch({ type: SEARCH_MAILBOXES_INIT });
        }
    };

export const initSearchMailboxes = () => async (dispatch) => {
    dispatch({
        type: CHANGE_MAILBOXES_SEARCH_QUERY_SUCCESSFULLY,
        data: { query: '' },
    });
    dispatch({ type: SEARCH_MAILBOXES_INIT });
};

export const fetchMailCalculation =
    () =>
    async (dispatch, _getState, { gateway }) => {
        const resource = new MailResource(gateway);
        const response = await resource.mailCalculation();
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: FETCH_MAIL_CALCULATION_SUCCESSFULLY,
                data,
            });
        }
    };

export const fetchPacketList =
    () =>
    async (dispatch, _getState, { gateway }) => {
        dispatch({ type: FETCH_MAIL_PACKET_LIST_IN_PROGRESS });
        const resource = new MailResource(gateway);
        const response = await resource.mailPacketList();
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: FETCH_MAIL_PACKET_LIST_SUCCESSFULLY,
                data,
            });
        } else {
            dispatch({ type: FETCH_MAIL_PACKET_LIST_WITH_ERRORS });
        }
    };

export const fetchPreferredPacket =
    () =>
    async (dispatch, _getState, { gateway }) => {
        const resource = new MailResource(gateway);
        const response = await resource.preferredPacket();
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: FETCH_MAIL_PREFERRED_PACKET_SUCCESSFULLY,
                data,
            });
        }
    };

export const createPacket =
    (formId, limit, callback) =>
    async (dispatch, _getState, { gateway }) => {
        dispatch({ type: CREATE_MAIL_PACKET_IN_PROGRESS });
        const resource = new MailResource(gateway);
        const response = await resource.createPacket(limit);
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: CREATE_MAIL_PACKET_SUCCESSFULLY,
                data,
            });
            if (isFunction(callback)) {
                callback();
            }
        } else {
            dispatch({ type: CREATE_MAIL_PACKET_WITH_ERRORS });
            dispatch(formErrors(formId, data.errors));
        }
    };

export const updatePacket =
    (formId, newPacketData, callback) =>
    async (dispatch, _getState, { gateway }) => {
        dispatch({ type: CREATE_MAIL_PACKET_IN_PROGRESS });
        const resource = new MailResource(gateway);
        const response = await resource.updatePacket(newPacketData);
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: CREATE_MAIL_PACKET_SUCCESSFULLY,
                data,
            });
            if (isFunction(callback)) {
                callback();
            }
        } else {
            dispatch({ type: CREATE_MAIL_PACKET_WITH_ERRORS });
            dispatch(formErrors(formId, data.errors));
        }
    };

export const cancelPacket =
    (formId, callback) =>
    async (dispatch, params, { gateway }) => {
        const resource = new MailResource(gateway);
        const response = await resource.cancelPacket();
        const data = await response.json();
        if (response.ok) {
            dispatch({
                type: CANCEL_MAIL_PACKET_SUCCESSFULLY,
                data,
            });
            if (isFunction(callback)) {
                callback();
            }
        } else {
            dispatch(formErrors(formId, data.errors));
        }
    };

export const wsCreateDomain = (data) => async (dispatch) => {
    dispatch({
        type: CREATE_DOMAIN_SUCCESSFULLY,
        data,
    });
};

export const wsUpdateDomain = (data) => async (dispatch) => {
    dispatch({
        type: UPDATE_DOMAIN_SUCCESSFULLY,
        data,
    });
};

export const wsDeleteDomain = (data) => async (dispatch) => {
    dispatch({
        type: DELETE_DOMAIN_SUCCESSFULLY,
        data,
    });
};

export const wsCreateMailbox = (data) => async (dispatch) => {
    dispatch({
        type: CREATE_MAILBOX_SUCCESSFULLY,
        data,
    });
};

export const wsUpdateMailbox = (data) => async (dispatch) => {
    dispatch({
        type: CHANGE_MAILBOX_SUCCESSFULLY,
        data,
    });
};

export const wsDeleteMailbox = (data) => async (dispatch) => {
    dispatch({
        type: DELETE_MAILBOX_SUCCESSFULLY,
        data: { mailboxId: data.id, domainId: data.domain_id },
    });
};

export const wsChangePaidQuotaEnabled = (data) => async (dispatch) => {
    dispatch({
        type: FETCH_USER_MAIL_INFO_SUCCESSFULLY,
        data,
    });
};
