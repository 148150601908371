import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet } from 'react-native';

import {
    createVMUpdateParams,
    fetchCVDSDistributions,
    cvdsChangeDistributions
} from '../../actions/cvds/actions';
import { getCurrentTariffObject } from './utils';
import DistributionsGroups from './DistributionsGroups';


function DistributionsPage(props) {
    const {
        currentDistribution,
        distributions,
        currentTariff,
        navigation,
        createVMUpdateParams,
        cvdsChangeDistributions,
        distributionsInProgress,
        fetchCVDSDistributions
    } = props;

    React.useEffect(() => {
        if (distributions == null && !distributionsInProgress) {
            fetchCVDSDistributions();
        }
    }, []);
    const newTariff = getCurrentTariffObject(currentTariff);
    return <View style={styles.wrapper}>
        <DistributionsGroups groups={distributions.groups}
            currentDistribution={currentDistribution}
            onChange={(image, group) => {
                createVMUpdateParams({
                    currentDistribution: {
                        id: image.id,
                        name: image.name,
                        fullname: image.fullname,
                        type: group.name,
                        groupType: group.type,
                        groupName: group.name,
                        requirements: image.requirements
                    },
                    currentTariff: newTariff
                });
                cvdsChangeDistributions(image, () => navigation.goBack());
            }}/>
    </View>;
}

DistributionsPage.propTypes = {
    navigation: PropTypes.shape({}),
    currentTariff: PropTypes.shape({}),
    distributions: PropTypes.shape({}),
    createVMUpdateParams: PropTypes.func,
    fetchCVDSDistributions: PropTypes.func,
    cvdsChangeDistributions: PropTypes.func,
    distributionsInProgress: PropTypes.bool
};
const mapStateToProps = state => ({
    currentTariff: state.cvdsCreate.currentTariff,
    distributions: state.cvdsDistributions.distributions,
    currentDistribution: state.cvdsCreate.currentDistribution,
    distributionsInProgress: state.cvdsDistributions.distributionsInProgress
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchCVDSDistributions,
    cvdsChangeDistributions,
    createVMUpdateParams
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        flex: 1
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DistributionsPage);
