import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';
import { bindActionCreators } from 'redux';

import { isActiveVM } from '../utils';
import Errors from '../../General/Form/Errors';
import { flushErrors } from '../../../actions/forms/actions';
import { vmChangeState } from '../../../actions/cvds/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'ReloadModalForm';

function ChangeStateModal(props) {
    const {
        vm,
        errors,
        onClose,
        inProgress,
        flushErrors,
        vmChangeState
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);
    const isActiveVm = isActiveVM(vm.state);
    return <View style={styles.wrapper}>
        <Text style={[styles.title]}>{isActiveVm ? 'Отключить'
            : 'Включить'} ВМ {vm.name} ({vm.uid})</Text>
        {isActiveVm && <View>
            <Text style={[styles.text]}>Виртуальная машина будет остановлена.</Text>
            <Text style={[styles.text]}>Действительно хотите выключить ее?</Text>
        </View>}
        {errors.__all__ && <Errors errors={errors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => vmChangeState(formId, vm.id, isActiveVm, onClose)}
                title={isActiveVm ? 'Отключить' : 'Включить'}
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

ChangeStateModal.propTypes = {
    onClose: PropTypes.func,
    vm: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    vmChangeState: PropTypes.func,
    inProgress: PropTypes.bool,
    flushErrors: PropTypes.func,
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    inProgress: state.cvds.vmUpdateInProgress,
    errors: state.forms.errors[formId] || {}
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors, vmChangeState
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    text: {
        color: '#455562'
    },
    buttonBlock: {
        flex: 1,
        marginTop: 18,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStateModal);
