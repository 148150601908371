const BaseResource = require('../resource');
const { makePaginateParams } = require('../../utils');

class DbmsResource extends BaseResource {
    async databases(virtualhostId, limit, offset) {
        return this.makeRequest('get', `${virtualhostId}/databases/`, makePaginateParams(limit, offset));
    }

    async changePassword(virtualhostId, password) {
        return this.makeRequest('put', `${virtualhostId}/set-password/`, { password });
    }

    async backupsList(dbmsId) {
        return this.makeRequest('get', `databases/${dbmsId}/backups/`);
    }

    async backupRestore(sourceDbId, date, { databaseId }) {
        return this.makeRequest(
            'post',
            `databases/${sourceDbId}/backups/${date}/restore/`,
            { database_id: databaseId },
        );
    }
}

DbmsResource.resource = 'dbms';
DbmsResource.service = 'hosting';

module.exports = DbmsResource;
