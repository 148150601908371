import {
    vdsIcon,
    cloudHostingIcon,
    cloudMailIcon,
    domainsIcon,
    dnsIcon,
    sslIcon,
} from '../icons';

export const mainServicesList = [{
    service: 'cloud_vds',
    screenName: 'CVDS'
}, {
    service: 'cloud_hosting',
    screenName: 'Hosting',
}, {
    service: 'cloud_mail',
    screenName: 'Mail'
}, {
    service: 'ssl',
    screenName: 'SSL'
}];

export const mainServices = {
    cloud_vds: {
        title: 'Виртуальные машины',
        image: vdsIcon,
    },
    cloud_hosting: {
        title: 'Хостинг',
        image: cloudHostingIcon,
    },
    cloud_mail: {
        title: 'Почта',
        image: cloudMailIcon,
    },
    ssl: {
        title: 'SSL-сертификаты',
        image: sslIcon,
    },
};

export const disksIds = ['fast', 'normal', 'slow'];

export const thresholdColorsClass = [
    [55, 'safe'],
    [80, 'warning'],
    [100, 'danger']
];

export const thresholdColors = {
    safe: '#0b8b5a',
    warning: '#e17334',
    danger: '#d55a5a',
    gray: '#a1aab1',
    backgroundGray: '#eaeaea'
}

export const IPv4 = 4;
export const IPv6 = 6;

export const ENABLED = 'ENABLED';
export const DISABLED_BY_USER = 'DISABLED_BY_USER';
export const DISABLED_BY_ADMIN = 'DISABLED_BY_ADMIN';
export const DISABLED_BY_QUOTA = 'DISABLED_BY_QUOTA';
export const DISABLED_BY_SERVICE = 'DISABLED_BY_SERVICE';

export const humanStateMap = {
    [ENABLED]: 'Включен',
    [DISABLED_BY_USER]: 'Выключен пользователем',
    [DISABLED_BY_ADMIN]: 'Выключен администратором',
    [DISABLED_BY_QUOTA]: 'Отключен по превышению квоты',
    [DISABLED_BY_SERVICE]: 'Отключен за неуплату'
};

export const notificationState = {
    Info: 'gray',
    Progress: 'progress',
    Error: 'error',
    Warning: 'warning',
    Success: 'green'
};

export const servicesPromocodeMap = {
    cloud_hosting: 'Хостинг',
    cloud: 'VDS'
};

export const momentNotificationTimeout = 5 * 1000;

export const blockedButtonTooltips = {
    cvds: {
        Error: 'Возникла непредвиденная ошибка. Пожалуйста, обратитесь в техподдержку.',
        Stopped: 'Виртуальная машина отключена.',
        StoppedByAdmin: 'Виртуальная машина заблокирована администратором. Пожалуйста, обратитесь в техподдержку.',
        StoppedByService: 'Недостаточно денежных средств. Пополните баланс.',
        Building: 'Виртуальная машина еще создается. Пожалуйста, подождите.',
        Starting: 'Виртуальная машина еще запускается. Пожалуйста, подождите.',
        Stopping: 'Виртуальная машина остановлена.',
        OfflineMigrating: 'Происходит миграция виртуальной машины. Пожалуйста, подождите.',
        LiveMigrating: 'Происходит миграция виртуальной машины. Пожалуйста, подождите.',
        Backuping: 'Происходит резервное копирование. Пожалуйста, подождите.',
        Restoring: 'Происходит восстановление из резервной копии. Пожалуйста, подождите.',
        Restarting: 'Происходит перезагрузка. Пожалуйста, подождите.',
        Archiving: 'Происходит создание образа. Пожалуйста, подождите.',
        TariffUpdate: 'Происходит смена тарифа. Пожалуйста, подождите.',
        DiskUpdating: 'Происходит изменение размера диска. Пожалуйста, подождите.',
        RescueMode: 'Режим восстановления виртуальной машины с VNC-консолью.',
        Transfer: 'Происходит передача виртуальной машины на другой аккаунт.',
        DisableGuestAgent: 'На виртуальной машине не запущен гостевой агент.',
        HasNotBackups: 'У виртуальной машины нет резервных копий.',
        IsNotBackupsEnabled: 'Резервное копирование отключено.',
        HasExternalKernel: 'Эта ВМ использует внешнее ядро. Для создания её образа обратитесь в техподдержку',
        CanNotCreateBackup: 'Создать резервную копию возможно один раз в день.',
        MaintenanceByAdmin: 'Производятся технические работы над ВМ.'
    },
    finance: {
        emptyAwaitingPacketList: 'Не выбраны неоплаченные платежи для оплаты',
        hasMonthsCharges: 'Не производилось списаний',
        hasMonthsChargesAndPayments: 'Не производилось списаний и платежей',
        enablePartnerReport: 'Не производилось выплат и начислений по партнерской программе'
    },
    hosting: {
        virtualhostDisabled: 'Сайт отключен',
        containerDisabled: 'Контейнер отключен'
    },
    partnership: {
        registerUser: 'Опция доступна партнерам с 10 и более активными клиентами',
        hasUntreatedWithdrawal: 'В данный момент у вас имеется необработанная заявка на вывод средств. Следующий вывод средств будет доступен после обработки текущей заявки.'
    }
};

export const resourceTypesList = [
    {
        resource_type: 'VM',
        service: 'vms',
    },
    {
        resource_type: 'DNSZone',
        service: 'zones',
    },
    {
        resource_type: 'Domain',
        service: 'domains',
    },
    {
        resource_type: 'Certificate',
        service: 'certificates',
    },
    {
        resource_type: 'Container',
        service: 'containers',
    },
    {
        resource_type: 'MailDomain',
        service: 'mail_domains',
    },
];

export const servicesResourceNameForTransferMap = {
    containers: 'контейнера',
    vms: 'виртуальной машины',
    zones: 'DNS-зоны',
    domains: 'домена',
    certificates: 'сертификата',
    mail_domains: 'почтового домена',
};

export const servicesResourceNameMap = {
    containers: 'Контейнер',
    vms: 'Виртуальная машина',
    zones: 'DNS-зона',
    domains: 'Домен',
    certificates: 'Cертификат',
    mail_domains: 'Почтовый домен',
};

export const servicesResourceNameForServiceIconsMap = {
    containers: cloudHostingIcon,
    vms: vdsIcon,
    zones: dnsIcon,
    domains: domainsIcon,
    certificates: sslIcon,
    mail_domains: cloudMailIcon,
};

export const servicesResourceBatchNameMap = {
    containers: 'Контейнеры',
    vms: 'Виртуальные машины',
    zones: 'DNS-зоны',
    domains: 'Домены',
    certificates: 'Cертификаты',
    mail_domains: 'Почтовые домены',
};

export const mainMonitoringServicesList = [{
    service: 'cloud_vds',
    screenName: 'MonitoringCvds'
}, {
    service: 'cloud_hosting',
    screenName: 'MonitoringHosting',
}, {
    service: 'cloud_mail',
    screenName: 'MonitoringMail'
}];
