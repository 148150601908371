import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { deleteCertificate, initCertificate } from '../../../actions/certificates/actions';
import { flushErrors } from '../../../actions/forms/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'DeleteSslModalForm';

function DeleteSslModal(props) {
    const {
        certificate,
        onClose,
    } = props;
    const dispatch = useDispatch();
    const navigation = useNavigation();
    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);
    const { updateCertificateInProgress } = useSelector((state) => state.certificates);

    return <View style={styles.wrapper}>
        <Text style={styles.title}>
            Удалить сертификат
        </Text>
        <Text style={styles.text}>
            Подтвердите удаление сертификата {certificate.name}
        </Text>
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => dispatch(
                    deleteCertificate(
                        formId,
                        certificate.id,
                        () => {
                            onClose();
                            navigation.navigate('SSL');
                            dispatch(initCertificate());
                        },
                    ),
                )}
                type='danger'
                title='Удалить'
                inProgress={updateCertificateInProgress}/>
            <CancelButton onClose={onClose} disabled={updateCertificateInProgress} />
        </View>
    </View>;
}

DeleteSslModal.propTypes = {
    onClose: PropTypes.func,
    name: PropTypes.string,
    certificate: PropTypes.shape({
        is_renewal: PropTypes.bool,
        id: PropTypes.number,
    }),
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 20,
        color: '#455562',
        fontSize: 18,
        lineHeight: 20,
    },
    buttonBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    text: {
        color: '#455562',
        fontWeight: '400',
        fontSize: 12,
        lineHeight: 16,
    }
});

export default DeleteSslModal;
