import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';

import Modal from '../../General/Modal/Modal';
import { passwordBlueIcon, passwordGreyIcon } from '../../../icons';
import { dbms } from '../../../constants/hosting';
import CopypableText from '../../General/CopypableText/CopypableText';
import DbmsChangePasswordModal from '../HostingModal/DbmsChangePassword';

function DbmsAccess(props) {
    const {
        type,
        login,
        password,
        serviceAddress,
        virtualhostName,
        virtualhostEnabled,
    } = props;
    const [isChangePasswordModalShowed, setChangePasswordModalShowed] = React.useState(false);
    return <View>
        <View style={styles.infoBlock}>
            <Text style={styles.title}>{virtualhostName}</Text>
            <View style={styles.infoItem}>
                <Text style={styles.infoItemLabel}>Логин</Text>
                <View style={styles.infoItemValue}>
                    <CopypableText text={login}/>
                </View>
            </View>
            <View style={styles.infoItem}>
                <Text style={styles.infoItemLabel}>Пароль</Text>
                <View style={styles.infoItemValue}>
                    <CopypableText text={password}/>
                </View>
            </View>
            <View style={styles.infoItem}>
                <Text style={styles.infoItemLabel}>Адрес сервера</Text>
                <View style={styles.infoItemValue}>
                    <CopypableText text={serviceAddress}/>
                </View>
            </View>
        </View>
        <TouchableOpacity onPress={() => {
                if (virtualhostEnabled) {
                    setChangePasswordModalShowed(true);
                }
            }}
            style={virtualhostEnabled ? styles.changePasswordButton :  [styles.changePasswordButton, styles.changePasswordButtonDisabled]}>
            <Image source={virtualhostEnabled ? passwordBlueIcon : passwordGreyIcon} style={styles.changePasswordButtonIcon}/>
            <Text style={virtualhostEnabled ? styles.changePasswordButtonText : styles.changePasswordButtonTextDisabled}>Сменить пароль {dbms[type]}</Text>
        </TouchableOpacity>
        <Modal isModalShowed={isChangePasswordModalShowed}
            component={<DbmsChangePasswordModal
                onClose={() => setChangePasswordModalShowed(false)}/>}
            onClose={() => setChangePasswordModalShowed(false)} />
    </View>;
}

DbmsAccess.propTypes = {
    dbmsAccount: PropTypes.shape({}),
    virtualhostName: PropTypes.string,
};

const mapStateToProps = (state) => {
    const {
        name,
        enabled,
        dbms_account
    } = state.hosting.virtualhost;
    return {
        virtualhostEnabled: enabled,
        virtualhostName: name,
        login: dbms_account.login,
        password: dbms_account.password,
        serviceAddress: dbms_account.db_servers_string
    };
};
const mapDispatchToProps = () => ({});

const styles = StyleSheet.create({
    infoBlock: {
        marginTop: 15,
        marginBottom: 9,
        backgroundColor: '#ffffff'
    },
    title: {
        textTransform: 'uppercase',
        opacity: 0.75,
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.38,
        lineHeight: 13,
        paddingTop: 17,
        paddingLeft: 21,
        paddingBottom: 14,
        borderBottomWidth: 1,
        borderBottomColor: '#E4E4E4'
    },
    infoItem: {
        paddingTop: 18,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: '#E4E4E4'
    },
    infoItemLabel: {
        color: '#999999',
        fontSize: 13,
    },
    infoItemValue: {
        color: '#455562',
        fontSize: 13,
        width: 170
    },
    changePasswordButton: {
        paddingTop: 16,
        paddingBottom: 18,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff'
    },
    changePasswordButtonDisabled: {
        backgroundColor: 'rgba(136, 136, 136, 0.2)',
    },
    changePasswordButtonIcon: {
        height: 16,
        width: 16,
        marginRight: 17
    },
    changePasswordButtonText: {
        color: '#0F79D5',
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 14
    },
    changePasswordButtonTextDisabled: {
        color: '#455562',
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 14
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(DbmsAccess);