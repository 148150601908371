import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';

import {
    rightIcon,
    returnIcon,
    actionReverseGrayIcon,
    returnDisabledIcon
} from '../../../icons';
import Action from '../../General/Actions/Action';
import Actions from '../../General/Actions/Actions';
import { ModalContext } from '../../../contexts/ModalContext';
import { DISABLED_BY_SERVICE } from '../../../constants/general';
import { dbms, normalizeCharsetMap } from '../../../constants/hosting';
import Spring from '../../General/Spring/Spring';

function getDbmsList(dbmsList, virtualhost, closeModal, navigation) {
    const isEnabled = virtualhost && virtualhost.enabled;
    return dbmsList.map((db, i) => <View key={`dbms-${i}`} style={styles.bdBlock}>
        <Text style={styles.bdBlockName}>{db.name}</Text>
        <Spring />
        <Text style={styles.bdBlockCharset}>{normalizeCharsetMap[db.charset].label}</Text>
        <Actions modalId='virtualhost-ftp-actions' icon={actionReverseGrayIcon}
            iconWidth={4} iconHeight={16}
            additionalButtonStyles={[styles.actions]}>
            <Action icon={isEnabled && virtualhost.state !== DISABLED_BY_SERVICE
                ? returnIcon : returnDisabledIcon}
            title='Восстановить содержимое'
            enabled={isEnabled && virtualhost.state !== DISABLED_BY_SERVICE}
            onPress={() => {
                closeModal();
                navigation.navigate(
                    'HostingBackupDbms',
                    {
                        containerId: virtualhost.container.id,
                        virtualhost: virtualhost.name,
                        dbmsId: db.id,
                    }
                );
            }} />
        </Actions>
    </View>);
}

function DbmsBlock(props) {
    const {
        dbmsList,
        virtualhost,
        dbmsAccount
    } = props;
    const navigation = useNavigation();
    const { closeModal } = useContext(ModalContext);

    return <View>
        <Text style={styles.title}>{dbms[dbmsAccount.type]}</Text>
        <TouchableOpacity onPress={() => navigation.navigate('HostingDbmsAccess')}
            style={styles.accessButton}>
            <Text style={styles.accessButtonText}>Реквизиты доступа</Text>
            <Image source={rightIcon} style={styles.accessButtonIcon}/>
        </TouchableOpacity>
        <Text style={styles.bdmsTitle}>Базы данных</Text>
        {getDbmsList(dbmsList.entities, virtualhost, closeModal, navigation)}
    </View>;
}

DbmsBlock.propTypes = {
    dbmsAccount: PropTypes.shape({}),
    dbmsList: PropTypes.shape({
        entities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            charset: PropTypes.string,
            name: PropTypes.string
        }))
    }),
    virtualhost: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
    const {
        dbms,
        virtualhost
    } = state.hosting;

    return {
        virtualhost,
        dbmsAccount: virtualhost.dbms_account,
        dbmsList: dbms
    };
};
const mapDispatchToProps = () => ({});

const styles = StyleSheet.create({
    title: {
        textTransform: 'uppercase',
        opacity: 0.75,
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.38,
        lineHeight: 13,
        marginTop: 23,
        marginLeft: 21,
        marginBottom: 13
    },
    accessButton: {
        paddingTop: 17,
        paddingBottom: 18,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        marginBottom: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff'
    },
    accessButtonIcon: {
        height: 13,
        width: 7
    },
    accessButtonText: {
        color: '#455562',
        fontSize: 14,
        lineHeight: 18
    },
    bdmsTitle: {
        color: 'rgba(69, 85, 98, 0.75)',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.38,
        lineHeight: 13,
        paddingTop: 17,
        paddingBottom: 14,
        paddingLeft: 21,
        textTransform: 'uppercase',
        backgroundColor: '#ffffff',
        borderBottomWidth: 1,
        borderBottomColor: '#E4E4E4'
    },
    bdBlock: {
        paddingTop: 18,
        paddingBottom: 20,
        paddingLeft: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        borderBottomWidth: 1,
        borderBottomColor: '#E4E4E4'
    },
    bdBlockName: {
        color: '#455562',
        fontSize: 13
    },
    bdBlockCharset: {
        color: '#A1AAB1',
        fontSize: 12,
        lineHeight: 14
    },
    actions: {
        backgroundColor: 'transparent',
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        paddingRight: 18,
        paddingLeft: 18,
        paddingBottom: 0,
        paddingTop: 0,
        marginLeft: 18
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DbmsBlock);