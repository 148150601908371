import React from 'react';

export const WebsocketContext = React.createContext({
    ws: null
});

export const WebsocketContextProvider = ({ children, ws }) => <WebsocketContext.Provider
    value={{ ws }}>
    {children}
</WebsocketContext.Provider>;
