import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import Input from '../General/Form/Input';
import Button from '../General/Button/Button';
import { partnershipUpdate } from '../../actions/partnership/actions';
import { flushErrors } from '../../actions/forms/actions';

const formId = 'EditRequisitesModalForm';

function PartnershipEditRequisites(props) {
    const {
        errors,
        inProgress,
        partnership,
        flushErrors,
        partnershipUpdate
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);

    const navigation = useNavigation();
    const [rinv, setRinv] = React.useState(partnership && partnership.rinv);
    const [bik, setBik] = React.useState(partnership && partnership.bik);

    return <View style={styles.wrapper}>
        {partnership && <View>
            <Input label='Наименование получателя'
                additionalWrapperStyles={[{ marginBottom: 20 }]}
                textInputAttrs={{
                    disabled: true,
                    value: partnership.name
                }}/>
            <Input label='Номер счета'
                errors={errors.rinv}
                additionalWrapperStyles={[{ marginBottom: 20 }]}
                textInputAttrs={{
                    onChange: (e) => {
                        flushErrors();
                        setRinv(e.target.value);
                    },
                    defaultValue: partnership.rinv
                }}/>
            <Input label='БИК'
                errors={errors.bik}
                textInputAttrs={{
                    onChange: (e) => {
                        flushErrors();
                        setBik(e.target.value);
                    },
                    defaultValue: partnership.bik
                }}/>
            <Text style={styles.bank}>{partnership.bank}</Text>
        </View>}
        <View>
            {errors.__all__ && <Text style={styles.error}>{errors.__all__}</Text>}
            <Button
                title='Сохранить'
                inProgress={inProgress}
                onPress={() => partnershipUpdate(formId, {
                    rinv: rinv || partnership.rinv, bik: bik || partnership.bik
                },
                () => {
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                    } else {
                        navigation.navigate('PartnershipWithdrawal');
                    }
                })} />
        </View>
    </View>;
}

PartnershipEditRequisites.propTypes = {
    inProgress: PropTypes.bool,
    errors: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    partnershipUpdate: PropTypes.func,
    partnership: PropTypes.shape({
        rinv: PropTypes.string,
        bank: PropTypes.string,
        bik: PropTypes.string,
        name: PropTypes.string
    })
};

const mapStateToProps = (state) => ({
    inProgress: state.partnership.editRequisitesInProgress,
    errors: state.forms.errors[formId] || {},
    partnership: state.partnership.partnership
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    partnershipUpdate
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 24,
        paddingBottom: 24,
        paddingRight: 18,
        paddingLeft: 20,
        flex: 1,
        height: '100%',
        backgroundColor: '#ffffff',
        justifyContent: 'space-between'
    },
    description: {
        fontSize: 14,
        lineHeight: 20,
        color: '#455562',
        paddingBottom: 20,
    },
    bank: {
        fontSize: 10,
        lineHeight: 12,
        color: '#455562',
        opacity: 0.7,
        marginBottom: 24
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
    },
    input: {
        borderColor: '#dce2e9',
        borderWidth: 1,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
        marginBottom: 10,
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnershipEditRequisites);