import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import Errors from '../../General/Form/Errors';
import { isEmpty } from '../../../utils/general';
import { IPv4 } from '../../../constants/cvds';
import { getNormalizedIPOptions } from '../utils';
import AddIPModalSwitcher from './AddIPModalSwitcher';
import { addIp } from '../../../actions/cvds/actions';
import { flushErrors } from '../../../actions/forms/actions';
import { ipsReservedFreeList } from '../../../actions/ips/actions';
import WarningCancelPacketBlock from '../WarningCancelPacketBlock';
import AddIPModalRadioButtonsBlock from './AddIPModalRadioButtonsBlock';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'AddIPForm';

function AddIPModal(props) {
    const {
        vm,
        ips,
        errors,
        onClose,
        flushErrors,
        ipsInProgress,
        awaitingPacket,
        ipsReservedFreeList,
        inProgress,
        addIp
    } = props;

    const [version, setVersion] = React.useState(IPv4);
    const [currentIp, setCurrentIp] = React.useState(null);
    const [createType, setСreateType] = React.useState('new');
    React.useEffect(() => {
        flushErrors();
        if (ips && !ipsInProgress && currentIp == null) {
            ipsReservedFreeList(() => {
                if (!isEmpty(ips.entities)) {
                    setCurrentIp(getNormalizedIPOptions(ips, version)[0]);
                }
            });
        }
    }, []);
    const normalizeIPOptions = getNormalizedIPOptions(ips, version);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Добавление IP-адреса</Text>
        <AddIPModalSwitcher ips={ips} version={version} setVersion={setVersion}
            createType={createType} setСreateType={setСreateType} setCurrentIp={setCurrentIp}/>
        {ips && !isEmpty(ips.entities) > 0 && !ipsInProgress && !isEmpty(normalizeIPOptions)
            && <AddIPModalRadioButtonsBlock ips={ips} version={version} createType={createType}
                currentIp={currentIp}
                setСreateType={setСreateType} setCurrentIp={setCurrentIp}/>}
        {awaitingPacket && <View style={styles.packetWarning}>
            <WarningCancelPacketBlock mainText='У вас имеется незавершенный заказ
                на годовой пакет для виртуальной машины. Подключение платного IP-адреса'
            warningText='отменит заказ годового пакета'/>
        </View>}
        {errors.ip && <Errors errors={errors.ip}/>}
        {errors.__all__ && <Errors errors={errors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton onSubmit={() => {
                if (currentIp) {
                    addIp(
                        formId,
                        vm,
                        { version, currentIp: currentIp.value, createType },
                        () => onClose(),
                    );
                } else {
                    addIp(
                        formId,
                        vm,
                        { version, createType },
                        () => onClose(),
                    );
                }
            }}
            title="Подключить"
            inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
};

AddIPModal.propTypes = {
    vm: PropTypes.shape({}),
    onClose: PropTypes.func,
    errors: PropTypes.shape({}),
    awaitingPacket: PropTypes.bool,
    inProgress: PropTypes.bool,
    addIp: PropTypes.func,
    ips: PropTypes.shape({
        entities: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    ipsReservedFreeList: PropTypes.func,
    ipsInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    ips: state.ips.ipsReservedFree || {},
    ipsInProgress: state.ips.ipsReservedFreeInProgress,
    inProgress: state.cvds.addIpInProgress,
    errors: state.forms.errors[formId] || {},
    awaitingPacket: state.cvdsFinanceInfo.calculation.packet == null ? false
        : state.cvdsFinanceInfo.calculation.packet.is_awaiting
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors, addIp, ipsReservedFreeList
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 16,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    packetWarning: {
        marginTop: 16
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddIPModal);
