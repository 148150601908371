import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import { ResourcePropType } from './types';
import { sumBy } from '../../../utils/general';
import HorizontalBarChartLegend from './HorizontalBarChartLegend';
import HorizontalBarChartStatistic from './HorizontalBarChartStatistic';

function total(resources) {
    return sumBy(resources, (r) => r.value);
}

function HorizontalBarChart(props) {
    const {
        resources,
        withLegend = true,
        withResource = true,
        hideTotal = false,
        formatTotal,
        needLegendColorBox,
        needLegendBackground,
        horizontalBarChartLegendStyles = {},
        horizontalBarChartWrapperStyles = {},
        horizontalBarChartStatisticStyles = {},
        horizontalBarChartLegendValueStyles = {},
        horizontalBarChartLegendTitleStyles = {},
        horizontalBarChartLegendResourceStyles = {},
    } = props;

    const resourceTotal = total(resources);
    return resourceTotal > 0 && <View style={horizontalBarChartWrapperStyles}>
        {withResource && <HorizontalBarChartLegend resources={resources}
            needColorBox={needLegendColorBox}
            needBackground={needLegendBackground}
            total={resourceTotal}
            hideTotal={hideTotal}
            withLegend={withLegend}
            resourceStyle={horizontalBarChartLegendResourceStyles}
            titleStyle={horizontalBarChartLegendTitleStyles}
            valueStyle={horizontalBarChartLegendValueStyles}
            additionalStyles={horizontalBarChartLegendStyles}
            formatTotal={formatTotal} />}
        {withLegend && <HorizontalBarChartStatistic
            resources={resources}
            total={resourceTotal}
            additionalStyles={horizontalBarChartStatisticStyles}
        />}
    </View>;
}

HorizontalBarChart.propTypes = {
    resources: PropTypes.arrayOf(ResourcePropType),
    formatTotal: PropTypes.func,
    withResource: PropTypes.bool,
    withLegend: PropTypes.bool,
    hideTotal: PropTypes.bool,
    needLegendColorBox: PropTypes.bool,
    needLegendBackground: PropTypes.bool,
    horizontalBarChartLegendStyles: PropTypes.shape({}),
    horizontalBarChartWrapperStyles: PropTypes.shape({}),
    horizontalBarChartStatisticStyles: PropTypes.shape({}),
    horizontalBarChartLegendValueStyles: PropTypes.shape({}),
    horizontalBarChartLegendTitleStyles: PropTypes.shape({}),
    horizontalBarChartLegendResourceStyles: PropTypes.shape({}),
};

export default HorizontalBarChart;
