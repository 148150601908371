import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';

import Errors from '../../General/Form/Errors';
import { flushErrors } from '../../../actions/forms/actions';
import { deleteProtectedWebsite } from '../../../actions/cvds/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'DeleteProtectedIPForm';

function DeleteProtectedWebsiteModal(props) {
    const {
        website,
        onClose,
    } = props;
    const dispatch = useDispatch();
    const { currentVM, deleteProtectedWebsiteInProgress } = useSelector((state) => state.cvds);
    const { errors } = useSelector((state) => state.forms);
    const formErrors = errors[formId] || {};

    React.useEffect(() => {
        dispatch(flushErrors());
    }, []);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Подтвердите удаление {website.domain}</Text>
        {formErrors.__all__ && <Errors errors={formErrors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => dispatch(
                    deleteProtectedWebsite(formId, currentVM.id, website.id, () => onClose())
                )}
                title='Удалить'
                type='danger'
                inProgress={deleteProtectedWebsiteInProgress}/>
            <CancelButton onClose={onClose} disabled={deleteProtectedWebsiteInProgress} />
        </View>
    </View>;
}

DeleteProtectedWebsiteModal.propTypes = {
    domain: PropTypes.string,
    onClose: PropTypes.func,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    title: {
        marginBottom: 12,
        color: '#455562',
        fontSize: 16,
        lineHeight: 18
    },
    buttonBlock: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default DeleteProtectedWebsiteModal;
