import React from 'react';
import PropTypes from 'prop-types';
import {
    View,
    Text,
    Image,
    StyleSheet,
    TouchableOpacity
} from 'react-native';

import { closedIcon } from '../../icons';

const styles = StyleSheet.create({
    filterInner: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        paddingRight: 9,
        marginRight: 8,
        backgroundColor: '#f5f5f5',
        borderRadius: 4
    },
    filterTitle: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '600',
        lineHeight: 14
    },
    closedIcon: {
        height: 10,
        width: 10,
        marginLeft: 10
    }
});

function PartnershipFilter(props) {
    const {
        title,
        onClose = () => {}
    } = props;

    return <View style={styles.filterInner}>
        <Text style={styles.filterTitle}>{title}</Text>
        <TouchableOpacity activeOpacity={0.6} onPress={(e) => onClose(e)}>
            <Image source={closedIcon} style={styles.closedIcon} />
        </TouchableOpacity>
    </View>;
}

PartnershipFilter.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func
};

export default PartnershipFilter;
