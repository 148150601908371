import {
    DISTRIBUTIONS_FETCHED_IN_PROGRESS,
    DISTRIBUTIONS_FETCHED_SUCCESSFULLY,
    DISTRIBUTIONS_FETCHED_WITH_ERRORS,
} from '../../actions/cvds/actionTypes';

export default () => {
    const defaultState = {
        distributions: null,
        distributionsInProgress: false,
        error: false
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case DISTRIBUTIONS_FETCHED_IN_PROGRESS: {
            return {
                ...state,
                error: false,
                distributionsInProgress: true,
            };
        }
        case DISTRIBUTIONS_FETCHED_SUCCESSFULLY: {
            return {
                ...state,
                distributions: action.data,
                error: false,
                distributionsInProgress: false
            };
        }
        case DISTRIBUTIONS_FETCHED_WITH_ERRORS: {
            return {
                ...state,
                error: true,
                distributionsInProgress: false,
            };
        }
        default:
            return state;
        }
    };
};
