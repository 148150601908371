import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    Image
} from 'react-native';

import {
    rightIcon,
    deletedVmIcon,
} from '../../../icons';
import DistributionIcon from '../DistributionIcon/DistributionIcon';
import {
    fetchCVDSBackupDates,
    restoreFromBackupUpdateParams
} from '../../../actions/cvds/actions';
import Spring from '../../General/Spring/Spring';

function RestoreBackupBlock() {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const { backups } = useSelector((state) => state.cvdsBackups);

    const activeList = backups.entities.filter(
        (entity) => entity.deleted == null,
    );
    const deletedList = backups.entities.filter(
        (entity) => entity.deleted != null,
    );
    const onClickFunction = (item) => {
        if (item.resources.length === 1) {
            const volume = item.resources[0];
            dispatch(
                fetchCVDSBackupDates(
                    volume.resource_url,
                    (backupDates) => {
                        if (
                            backupDates.entities.length === 0
                        ) {
                            return;
                        }
                        const {
                            backup_date,
                            resource_name,
                            resource_type,
                            methods,
                        } = backupDates.entities[0];
                        dispatch(
                            restoreFromBackupUpdateParams({
                                backupDate: backup_date,
                                resourceName: resource_name,
                                resourceType: resource_type,
                                backupMethod: methods[0],
                                backupMethods: methods,
                            }),
                        );
                    },
                ),
            );
            dispatch(
                restoreFromBackupUpdateParams(
                    {
                        currentVolume: volume,
                        currentVM: item,
                    },
                    () => navigation.navigate('CVDSBackupMethods', {
                        vmId: item.id,
                        volumeId: volume.id,
                    }),
                ),
            );
        } else {
            dispatch(
                restoreFromBackupUpdateParams(
                    {
                        currentVM: item,
                    },
                    () => navigation.navigate('CVDSBackupDisks', { vmId: item.id }),
                ),
            );
        }
    };

    return (
        <View style={styles.wrapper}>
            {activeList && activeList.length > 0 && <Text style={styles.title}>
                активные виртуальные машины
            </Text>}
            {activeList.map((item) => (
                <View key={`vm-active-backup-${item.uid}`}>
                    <TouchableOpacity
                        style={styles.resourceBlock}
                        onPress={() => onClickFunction(item)}
                    >
                        <DistributionIcon distribution={item.image.uid}
                            iconWidth='24px' iconHeight='24px'/>
                        <View style={styles.resourceBlockTextBlock}>
                            <Text style={styles.resourceBlockTitle}>
                                {item.name}
                            </Text>
                            <Text style={styles.resourceBlockText}>
                                {item.uid}
                            </Text>
                        </View>
                        <Spring />
                        <Image source={rightIcon} style={styles.image}/>
                    </TouchableOpacity>
                </View>
            ))}
            {deletedList && deletedList.length > 0 && <Text
                style={[styles.title, { marginTop: activeList && activeList.length > 0 ? 30 : 0 }]}
            >
                удаленные виртуальные машины
            </Text>}
            {deletedList.map((item) => (
                <View key={`vm-deleted-backup-${item.uid}`}>
                    <TouchableOpacity
                        style={styles.resourceBlock}
                        onPress={() => onClickFunction(item)}
                    >
                        <Image source={deletedVmIcon}
                            style={{ width: 24, height: 24 }} />
                        <View style={styles.resourceBlockTextBlock}>
                            <Text style={styles.resourceBlockTitle}>
                                {item.name}
                            </Text>
                            <Text style={styles.resourceBlockText}>
                                {item.uid}
                            </Text>
                        </View>
                        <Spring />
                        <Image source={rightIcon} style={styles.image}/>
                    </TouchableOpacity>
                </View>
            ))}
        </View>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
    },
    title: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginBottom: 10,
        marginLeft: 20,
    },
    resourceBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        paddingLeft: 20,
        paddingBottom: 12,
        paddingTop: 12,
        paddingRight: 20,
        marginBottom: 1,
    },
    resourceBlockTextBlock: {
        marginLeft: 8,
    },
    resourceBlockTitle: {
        marginBottom: 2,
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 16
    },
    resourceBlockText: {
        color: '#586A7B',
        fontSize: 11,
        lineHeight: 14
    },
    image: {
        width: 7,
        height: 13,
    }
});

export default RestoreBackupBlock;
