import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import {
    Image,
    Text,
    View,
    Linking,
    StyleSheet,
    Platform,
    TouchableOpacity,
} from 'react-native';
import PinInput from '../General/Form/PinInput';
import Errors from '../General/Form/Errors';
import { flushErrors } from '../../actions/forms/actions';
import { authorizeOtp, financeInfo } from '../../actions/user/actions';
import { netangelsLogoIcon } from '../../icons';

const styles = StyleSheet.create({
    logoWrapper: {
        flex: 0.1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    authScreen: {
        marginTop: '11%',
        padding: 22,
        flex: 1,
        flexDirection: 'column'
    },
    headerText: {
        fontSize: 22,
        marginBottom: 16,
        textAlign: 'center',
        lineHeight: 25,
        color: '#455562'
    },
    form: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 4,
    },
    button: {
        marginTop: 30,
    },
    buttonText: {
        textAlign: 'center',
        fontSize: 12,
        color: '#0f79d5',
    },
    descriptionText: {
        fontSize: 12,
        marginBottom: 12,
        textAlign: 'center',
        color: '#455562'
    },
    linkButton: {
        fontSize: 11,
        lineHeight: 13,
        letterSpacing: 1.38,
        textAlign: 'center',
        opacity: 0.75,
        marginBottom: 24,
        textTransform: 'uppercase',
    },
    registerButton: {
        color: '#455562',
    },
    toDesktopButton: {
        color: '#455562',
    },
    restoreButton: {
        color: '#4E5F6E',
    },
    allErrorWrapper: {
        textAlign: 'center',
        marginBottom: 10
    }
});

function OtpScreen() {
    const navigation = useNavigation();
    const { scopeId } = useSelector((state) => state.user);
    const { errors } = useSelector((state) => state.forms);
    const pageProps = {
        title: 'Вход, проверка двухфакторной авторизации Netangels. Панель управления хостингом NetAngels',
    };
    const formId = 'otp-auth-form';

    const dispatch = useDispatch();
    const currentErrors = errors[formId] || {};
    const [currentTab, setCurrentTab] = useState('application');
    useEffect(() => {
        document.title = pageProps.title;
    }, []);
    return <View style={styles.authScreen}>
        <View style={styles.logoWrapper}>
            <Image source={netangelsLogoIcon} style={{ width: 162, height: 30 }} />
        </View>
        <View style={styles.form}>
            <Text style={styles.headerText}>Вход в личный кабинет</Text>
            {errors.__all__ && <View style={styles.allErrorWrapper}>
                <Errors errors={errors.__all__} />
            </View>}
            <View>
                <Text style={styles.descriptionText}>
                    {currentTab === 'application'
                        ? 'Введите код из приложения-аутентификатора'
                        : 'Введите резервный код'}
                </Text>
                <View>
                    <PinInput
                        count={currentTab === 'application' ? 6 : 10}
                        onFill={(pin) => dispatch(
                            authorizeOtp(
                                formId,
                                {
                                    scopeId,
                                    token: pin.join(''),
                                },
                                () => dispatch(financeInfo(() => {
                                    navigation.navigate('MainStack');
                                })),
                                () => navigation.navigate('OtpFail'),
                            ),
                        )}
                        onChange={() => dispatch(flushErrors())}
                        errors={currentErrors.token}
                    />
                </View>
                <TouchableOpacity
                    onPress={() => setCurrentTab(currentTab === 'application' ? 'codes' : 'application')}
                    style={styles.button}
                >
                    <Text style={styles.buttonText}>
                        {currentTab === 'application'
                            ? 'Войти с помощью резервного кода'
                            : 'Войти с помощью приложения-аутентификатора'}
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
        <View>
            <Text style={[styles.linkButton, styles.registerButton]}
                onPress={() => Linking.openURL('https://netangels.ru/register/')}>
                Зарегистрироваться
            </Text>
        </View>
        {Platform.OS === 'web' && <View>
            <Text style={[styles.linkButton, styles.toDesktopButton]}
                onPress={() => Linking.openURL('/to-desktop')}>
                Перейти в версию для ПК
            </Text>
        </View>}
    </View>;
}

export default OtpScreen;
