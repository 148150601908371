import React, { useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';

import { useDispatch, useSelector } from 'react-redux';
import RadioButton from '../../General/Form/RadioButton';

import gpuTypes from '../../../../data/cvds/gpu-types.json';
import { createVMUpdateParams } from '../../../actions/cvds/actions';
import InputHorizontalNumber from '../../General/Form/InputHorizontalNumber';

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: '#ffffff',
        marginBottom: 8,
    },
    title: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 18,
        marginLeft: 15,
        marginTop: 9,
        marginBottom: 5,
        flex: 1,
    },
    radioButtonlabelWrapperStyle: {
        flex: 1,
        marginBottom: 10,
    },
    radioButtonGpuWrapper: {
        marginRight: 20
    }
});

function GpuBlock() {
    const dispatch = useDispatch();
    const currentGpu = useSelector((state) => state.cvdsCreate.currentGpu);

    const [selectedGpuCount, setSelectedGpuCount] = useState((() => {
        const gpuCountMap = {};
        for (const gpu of gpuTypes) {
            gpuCountMap[gpu.id] = 1;
        }
        return gpuCountMap;
    })());

    return (
        <View>
            <Text style={styles.title}>Видеокарта</Text>
            <View style={styles.wrapper}>
                {gpuTypes.map((gpu) => (
                    <View
                        key={`vm-wizard-gpu-${gpu.id}`}
                        style={styles.radioButtonGpuWrapper}
                    >
                        <RadioButton
                            isActive={Boolean(currentGpu && currentGpu.id === gpu.id)}
                            label={`${gpu.name}, ${gpu.features.arch}, ${
                                gpu.features.memory / 1024
                            } GB`}
                            onChange={() => {
                                dispatch(
                                    createVMUpdateParams({
                                        currentGpu: gpu,
                                        currentGpuCount: selectedGpuCount[gpu.id]
                                    })
                                );
                            }}
                            labelWrapperStyle={styles.radioButtonlabelWrapperStyle}
                        >
                            {currentGpu && currentGpu.id === gpu.id && <InputHorizontalNumber
                                min={1}
                                max={2}
                                value={selectedGpuCount[gpu.id]}
                                onChange={(value) => {
                                    setSelectedGpuCount({
                                        ...selectedGpuCount,
                                        [gpu.id]: value
                                    });
                                    dispatch(createVMUpdateParams({
                                        currentGpuCount: value
                                    }));
                                }}
                            />}
                        </RadioButton>
                    </View>
                ))}
            </View>
        </View>
    );
}

export default GpuBlock;
