import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';

function StateBlock(props) {
    const {
        state,
        title,
        modifiersTitle
    } = props;
    return <View style={[styles.wrapper]}>
        <View style={[styles.state, styles[`${state}State`]]}/>
        <Text style={[styles.title, styles[`${state}Text`], modifiersTitle]}>{title}</Text>
    </View>;
}

StateBlock.propTypes = {
    title: PropTypes.string,
    state: PropTypes.string,
    modifiersTitle: PropTypes.shape({})
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    state: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        marginRight: 5,
    },
    title: {
        fontSize: 12,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 14,
    },
    enabledText: {
        color: '#5eae4f',
    },
    enabledState: {
        backgroundColor: '#5eae4f',
    },
    disabledText: {
        color: '#d06565',
    },
    disabledState: {
        backgroundColor: '#d06565',
    },
});

export default StateBlock;
