import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';

import { getChannel } from '@netangels/core/utils/websockets';
import { userChannelName } from '@netangels/core/constants/websockets';

import useWebsockets from '../hooks/useWebsockets';
import { connectToWebsocketWithDelay } from '../utils/websockets';

import {
    fetchCertificates,
    wsCertificateCreate,
    wsCertificateDelete,
    wsCertificateUpdate,
} from '../actions/certificates/actions';
import { screenHeaderStyles } from '../utils/styles';
import SslList from '../components/SSL/SslList';
import SslLaunchPage from '../components/SSL/SslLaunchPage';
import SslResourceLoader from '../components/SSL/SslResourceLoader';

const SslStack = createStackNavigator();

function SslStackScreen(props) {
    const { navigation } = props;
    const limit = 10;
    const insets = useSafeAreaInsets();
    const {
        certificates,
        certificatesInProgress,
    } = useSelector((state) => state.certificates);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const ws = useWebsockets();
    let sslSubscriber = null;

    useEffect(() => {
        if (!certificatesInProgress) {
            dispatch(fetchCertificates(limit, 0));
        }
        const connectionState = connectToWebsocketWithDelay(() => {
            if (sslSubscriber == null) {
                const channelName = getChannel(userChannelName, user.login, true);
                sslSubscriber = ws.subscriber('certificates', channelName);
                sslSubscriber
                    .on('certificate.create', (data) => {
                        dispatch(wsCertificateCreate(data));
                    })
                    .on('certificate.update', (data) => {
                        dispatch(wsCertificateUpdate(data));
                    })
                    .on('certificate.refresh', (data) => {
                        dispatch(wsCertificateUpdate(data));
                    })
                    .on('certificate.delete', (data) => {
                        dispatch(wsCertificateDelete(data));
                    });
            }
        });
        return () => {
            connectionState.cancel();
            if (sslSubscriber != null) {
                ws.unsubscribe(sslSubscriber);
                sslSubscriber = null;
            }
        };
    }, []);
    let currentComponent = null;
    if (certificates && certificates.entities.length !== 0) {
        currentComponent = SslList;
    } else {
        currentComponent = SslLaunchPage;
    }

    return <SslStack.Navigator>
        <SslStack.Screen name="SslScreen"
            component={
                certificatesInProgress ? SslResourceLoader : currentComponent
            }
            initialParams={{ height: 77, count: 3 }}
            options={{
                title: 'SSL-сертификаты',
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.navigate('MainStack')
                }} />,
                cardStyle: { flex: 1 },
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }}/>
    </SslStack.Navigator>;
}

SslStackScreen.propTypes = {
    navigation: PropTypes.object,
};

export default SslStackScreen;
