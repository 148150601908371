import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getChannel } from '@netangels/core/utils/websockets';
import { userChannelName } from '@netangels/core/constants/websockets';

import {
    wsUpdateDomain,
    wsDeleteDomain,
    wsCreateDomain,
    wsUpdateMailbox,
    wsDeleteMailbox,
    wsCreateMailbox,
    wsChangePaidQuotaEnabled,
} from '../../actions/mail/actions';
import useWebsockets from '../../hooks/useWebsockets';
import { connectToWebsocketWithDelay } from '../../utils/websockets';

function MailWebsocket() {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const ws = useWebsockets();
    let mailSubscriber = null;

    useEffect(() => {
        const connectionState = connectToWebsocketWithDelay(() => {
            if (mailSubscriber == null) {
                const channelName = getChannel(userChannelName, user.login, true);
                mailSubscriber = ws.subscriber('mail', channelName);
                mailSubscriber
                    .on('domain.create', (data) => {
                        dispatch(wsCreateDomain(data));
                    })
                    .on('domain.update', (data) => {
                        dispatch(wsUpdateDomain(data));
                    })
                    .on('domain.delete', (data) => {
                        dispatch(wsDeleteDomain(data));
                    })
                    .on('mailbox.create', (data) => {
                        dispatch(wsCreateMailbox(data));
                    })
                    .on('mailbox.update', (data) => {
                        dispatch(wsUpdateMailbox(data));
                    })
                    .on('mailbox.delete', (data) => {
                        dispatch(wsDeleteMailbox(data));
                    })
                    .on('user.update', (data) => {
                        dispatch(wsChangePaidQuotaEnabled(data));
                    });
            }
        });
        return () => {
            connectionState.cancel();
            if (mailSubscriber != null) {
                ws.unsubscribe(mailSubscriber);
                mailSubscriber = null;
            }
        };
    }, []);
    return null;
}

export default MailWebsocket;
