import {
    formatMemory,
    detectTariffByRam,
    normalizeContainerPrice,
} from './general';
import cvdsTariffData from '../../data/cvds/tariffs.json';

export const getVmTariffInfo = (vm) => {
    const tariffData = cvdsTariffData.find((tariff) => tariff.id === vm.tariff);
    let result = `Тариф «${tariffData.name}»`;
    let diskSizeInfo = vm.disk.size;
    if (vm.volumes.length !== 0) {
        diskSizeInfo += vm.volumes.reduce(
            (accumulator, currentValue) => accumulator + currentValue.size,
            0,
        );
    }
    result += `, Диски: ${diskSizeInfo} ГБ`;
    result += `, ${vm.image.display_name}`;
    return result;
};

export const getContainerTariffInfo = (container) => {
    const currentTariff = detectTariffByRam(container.memory_limit);
    let result = `Тариф «${currentTariff.title}»`;
    const diskFormat = formatMemory(container.quota);
    result += `, Диски: ${diskFormat}`;
    if (container.redis) {
        result += ', Redis';
    }
    if (container.memcache) {
        result += ', Memcached';
    }
    return result;
};

export const isInProgressTask = (task) => task.state.toLowerCase() === 'in_progress';

export const isRejectedTask = (task) => task.state.toLowerCase() === 'rejected';

export const checkItem = (onCheck, checkedItems, entry, dispatch) => {
    const newCheckedItems = [...checkedItems];
    if (entry.is_in_transfer) {
        return;
    }
    if (newCheckedItems.indexOf(entry) === -1) {
        newCheckedItems.push(entry);
    } else {
        newCheckedItems.splice(newCheckedItems.indexOf(entry), 1);
    }
    dispatch(onCheck(newCheckedItems));
};

export const navigationHelper = (service, navigation, instance) => {
    if (instance == null) {
        navigation.navigate('MainStack');
        return;
    }
    if (service === 'vms') {
        navigation.navigate('CVDSVM', { vmId: instance.id });
        return;
    }
    if (service === 'containers') {
        navigation.navigate('HostingContainer', { containerId: instance.id });
        return;
    }
    if (service === 'mail_domains') {
        navigation.navigate('MailDomain', { domainId: instance.id });
        return;
    }
    if (navigation.canGoBack()) {
        navigation.goBack();
        return;
    }
    navigation.navigate('MainStack');
};
