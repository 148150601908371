import React from 'react';
import { DynamicComponentService } from './DynamicComponentService';

export class DynamicComponentGenerator {
    constructor() {
        this.service = new DynamicComponentService();
    }

    register(component) {
        if (component.dynamicName == null) {
            throw new Error(`Component ${component.name} hasn't dynamicName field`);
        }
        const existedComponent = this.service.get(component.dynamicName);
        if (existedComponent != null) {
            throw new Error(`Component with name ${component.dynamicName} already registered`);
        }
        this.service.add(component.dynamicName, component);
    }

    generate(name, props, children = null) {
        const component = this.service.get(name);
        if (component == null) {
            // eslint-disable-next-line no-console
            console.error(`Can't find component ${name} in generator`);
            return null;
        }
        return React.createElement(component, props, children);
    }
}
