import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, TextInput, StyleSheet } from 'react-native';

import Errors from '../General/Form/Errors';
import Button from '../General/Button/Button';
import { couponActivate } from '../../actions/finances/actions';

const formId = 'CVDSPaymentForm';

function PaymentPagePromoForm(props) {
    const {
        errors,
        inProgress,
        couponActivate
    } = props;
    const [promo, setPromo] = React.useState(null);
    return <View style={styles.promoBlock}>
        <TextInput placeholder='Ваш код'
            style={styles.textInput}
            value={promo}
            onChange={(e) => setPromo(e.target.value)}/>
        <View style={styles.errorBlock}>
            {errors.token && <Errors errors={errors.token} />}
        </View>
        <Button title='Активировать'
            disabled={promo == null}
            inProgress={inProgress}
            onPress={() => couponActivate(formId, { token: promo })}
            additionalButtonTextStyles={[styles.buttonText]}
            additionalButtonStyles={[styles.button]} />
    </View>;
};

PaymentPagePromoForm.propTypes = {
    errors: PropTypes.shape({}),
    inProgress: PropTypes.bool,
    couponActivate: PropTypes.func
};

const mapStateToProps = (state) => ({
    inProgress: state.finance.couponActivateInProgress,
    errors: state.forms.errors[formId] || {}
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    couponActivate
}, dispatch);

const styles = StyleSheet.create({
    promoBlock: {
        width: 274
    },
    textInput: {
        borderWidth: 1,
        borderColor: '#D8D8D8',
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        paddingBottom: 12,
        paddingLeft: 11,
        paddingTop: 10,
        marginBottom: 40
    },
    errorBlock: {
        top: 40,
        position: 'absolute'
    },
    button: {
        paddingTop: 11,
        paddingBottom: 13
    },
    buttonText: {
        fontSize: 14,
        lineHeight: 16,
        minHeight: 16,
        fontWeight: '500'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPagePromoForm);
