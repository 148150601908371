import React from 'react';
import PropTypes from 'prop-types';
import OtpScreen from '../components/Auth/Otp';

function OtpScreenStack() {
    return <OtpScreen />;
}

OtpScreenStack.propTypes = {
    authorizeUser: PropTypes.func,
    financeInfo: PropTypes.func,
    errors: PropTypes.shape({}),
    authData: PropTypes.shape({}),
    financeInfoData: PropTypes.shape({}),
};

export default OtpScreenStack;
