import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StyleSheet, View } from 'react-native';

import PaymentSystems from './PaymentSystems';
import PaymentAmountForm from './PaymentAmountForm';
import PaymentForm from '../../General/Payments/PaymentForm';


const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
        backgroundColor: '#ffffff',
        flex: 1
    }
});

function FinancePayment(props) {
    const { navigation } = props;
    const [amount, setAmount] = React.useState(props.paymentAmount);
    const [paymentForm, setPaymentForm] = React.useState(null);
    const paymentFormRef = useRef(null);

    useLayoutEffect(() => {
        if (paymentForm != null && paymentFormRef.current != null) {
            paymentFormRef.current.submit();
        }
    }, [paymentForm])

    return <View style={styles.wrapper}>
        {paymentForm}
        {amount == null && <PaymentAmountForm onChange={setAmount}/>}
        {amount != null && <PaymentSystems
            onActionDone={() => {
                if (navigation.canGoBack()) {
                    navigation.goBack();
                } else {
                    navigation.navigate('MainStack');
                }
            }}
            setPaymentForm={(data) => {
                setPaymentForm(<PaymentForm {...data} innerRef={paymentFormRef} />);
            }}
            amount={amount}/>}
    </View>
};

FinancePayment.propTypes = {
    paymentAmount: PropTypes.string,
    errors: PropTypes.shape({})
};

const mapStateToProps = (state) => ({
    paymentAmount: state.finance.paymentAmount
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinancePayment);
