import React from 'react';
import PropTypes from 'prop-types';
import Clipboard from 'expo-clipboard';
import { useDispatch } from 'react-redux';
import {
    Text,
    StyleSheet,
    TouchableOpacity,
    View,
    Image
} from 'react-native';

import { copyBlueIcon } from '../../../icons';
import { addCustomNotification } from '../../../actions/notifications/actions';

function CopypableText(props) {
    const {
        text,
        title,
        needIcon = false,
        additionalTextStyles = [],
        additionalBlockStyles = [],
    } = props;
    const dispatch = useDispatch();
    return <View style={styles.wrapper}>
        <TouchableOpacity onPress={() => {
            Clipboard.setString(text);
            dispatch(addCustomNotification('Скопировано'));
        }} style={[styles.copyBlock, ...additionalBlockStyles]}>
            {needIcon && <Image source={copyBlueIcon} style={styles.image} />}
            <Text style={[styles.text, ...additionalTextStyles]}>{title || text}</Text>
        </TouchableOpacity>
    </View>;
}

CopypableText.propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
    needIcon: PropTypes.bool,
    additionalTextStyles: PropTypes.arrayOf(PropTypes.shape()),
    additionalBlockStyles: PropTypes.arrayOf(PropTypes.shape()),
};

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        flexDirection: 'row'
    },
    text: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 20,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(69, 85, 98, 0.7)',
        borderBottomStyle: 'dashed'
    },
    image: {
        width: 16,
        height: 16,
        marginRight: 12,
    },
});

export default CopypableText;
