import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import cvdsTariffData from '../../data/cvds/tariffs.json';
import { changeTariffCalculation } from '../actions/cvds/actions';
import CVDSVMTariffsScreen from '../components/CVDS/VMTariffsPage';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const CVDSVMTariffsStack = createStackNavigator();


function CVDSVMTariffsStackScreen(props) {
    const { navigation, vm, changeTariffCalculation } = props;
    const insets = useSafeAreaInsets();

    React.useEffect(() => {
        const currentTariff = cvdsTariffData.find(cvdsTariff => cvdsTariff.id === vm.tariff);
        changeTariffCalculation(vm.id, currentTariff.id);
    }, []);
    return <CVDSVMTariffsStack.Navigator>
        <CVDSVMTariffsStack.Screen name="CVDSVMTariffs"
            component={CVDSVMTariffsScreen} options={{
                headerTitle: () => <ScreenHeaderTitle title='Изменение тарифа' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.goBack(),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </CVDSVMTariffsStack.Navigator>;
}

CVDSVMTariffsStackScreen.propTypes = {
    vm: PropTypes.shape({}),
    changeTariffCalculation: PropTypes.func
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        changeTariffCalculation
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CVDSVMTariffsStackScreen);
