import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dayjsDuration from 'dayjs/plugin/duration';
import * as dayjsUTC from 'dayjs/plugin/utc';
import {
    View, Image, Text, StyleSheet, TouchableOpacity
} from 'react-native';

import { isActiveVM } from './utils';
import Errors from '../General/Form/Errors';
import Button from '../General/Button/Button';
import CVDSWebsockets from './CVDSWebsockets';
import Modal from '../General/Modal/Modal';
import { isEmpty } from '../../utils/general';
import TimerBlock from '../General/Timer/TimerBlock';
import ChangeRootPasswordModal from './Modals/ChangeRootPasswordModal';
import { passwordBlueIcon, supportIcon, passwordIcon } from '../../icons';
import { vmSupportAccess } from '../../actions/cvds/actions';
import { flushErrors } from '../../actions/forms/actions';
import { addCustomNotification } from '../../actions/notifications/actions';
import { blockedButtonTooltips } from '../../constants/general';

const formId = 'CVDSSupportAccessForm';

function isTimeOver(duration) {
    return duration.asSeconds() < 1 && duration.asSeconds() > -1;
}

function getRevokeDate(date) {
    const utc = dayjsUTC;
    dayjs.extend(utc);
    return dayjs.utc(date).local();
}

function getTimerList(duration) {
    return [
        {
            amount: duration.hours().toString().padStart(2, '0'),
            variants: ['час', 'часа', 'часов'],
        },
        {
            amount: duration.minutes().toString().padStart(2, '0'),
            variants: ['минута', 'минуты', 'минут'],
        },
        {
            amount: duration.seconds().toString().padStart(2, '0'),
            variants: ['секунда', 'секунды', 'секунд'],
        },
    ];
}

function getSupportBlock(
    vm,
    actionEnable,
    supportAccess,
    currentDuration,
    vmSupportAccess,
    errors,
    disabledText,
    inProgress
) {
    const supportAccessEnabled = supportAccess == null
    || !supportAccess.is_granted
    || isTimeOver(currentDuration);
    return (
        <View style={styles.supportWrapper}>
            <View style={styles.supportBlock}>
                <View style={styles.supportTextBlock}>
                    <Text style={styles.supportTitle}>Доступ для техподдержки</Text>
                    {supportAccessEnabled && (
                        <Text style={styles.supportDescription}>
              Для проведения работ на вашем сервере СТП NetAngels потребуется
              доступ
                        </Text>
                    )}
                    {!supportAccessEnabled && (
                        <Text style={styles.supportRevokeDateBlock}>
              Открыт до{' '}
                            {getRevokeDate(supportAccess.revoke_date).format('DD MMMM HH:mm')}
                        </Text>
                    )}
                </View>
                <Image source={supportIcon} style={styles.supportBlockIcon} />
            </View>
            {!supportAccessEnabled && (
                <View style={styles.timerBlock}>
                    <TimerBlock timerList={getTimerList(currentDuration)} />
                </View>
            )}
            {supportAccessEnabled && (
                <View style={styles.supportButtonBlock}>
                    <Button
                        title="Предоставить доступ"
                        disabled={!actionEnable}
                        disabledText={disabledText}
                        inProgress={inProgress}
                        additionalButtonTextStyles={[styles.supportButtonTextStyles]}
                        additionalButtonStyles={[styles.supportButtonStyles]}
                        onPress={() => vmSupportAccess(formId, vm.id)}
                    />
                    {errors.__all__ && <Errors errors={errors.__all__} />}
                </View>
            )}
        </View>
    );
}

function VMAccess(props) {
    const {
        vm,
        errors,
        inProgress,
        flushErrors,
        vmSupportAccess,
        addCustomNotification,
    } = props;

    let diff = 0;
    const supportAccess = vm.support_access;
    const currentTime = new Date();
    const duration = dayjsDuration;
    dayjs.extend(duration);
    if (supportAccess && supportAccess.is_granted) {
        diff = getRevokeDate(supportAccess.revoke_date) - currentTime;
    }
    const [currentDuration, setDuration] = React.useState(dayjs.duration(diff));
    React.useEffect(() => {
        flushErrors();
        if (supportAccess && supportAccess.is_granted) {
            diff = getRevokeDate(supportAccess.revoke_date) - currentTime;
        }
        setDuration(dayjs.duration(diff));
    }, [supportAccess]);
    React.useEffect(() => {
        let interval = null;
        if (supportAccess && supportAccess.is_granted) {
            interval = setInterval(() => {
                setDuration(currentDuration.subtract(1000, 'ms'));
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [currentDuration]);
    const actionEnable = !isEmpty(vm) && isActiveVM(vm.state) && vm.is_managed;
    const [isModalChangePassword, setModalChangePasswordVisible] = React.useState(false);
    const disabledText = !isEmpty(vm) && isActiveVM(vm.state) && !vm.is_managed
        ? blockedButtonTooltips.cvds.DisableGuestAgent
        : blockedButtonTooltips.cvds[vm.state];
    return (
        <View style={styles.wrapper}>
            {!isEmpty(vm) && (
                <React.Fragment>
                    <TouchableOpacity
                        style={styles.actionButton}
                        enabled={actionEnable}
                        onPress={() => {
                            if (actionEnable) {
                                setModalChangePasswordVisible(true);
                            } else {
                                addCustomNotification(disabledText);
                            }
                        }}
                    >
                        <Image
                            source={actionEnable ? passwordBlueIcon : passwordIcon}
                            style={styles.actionButtonIcon}
                        />
                        <Text
                            style={[
                                styles.actionButtonText,
                                { color: actionEnable ? '#0F79D5' : '#888888' },
                            ]}
                        >
              Сбросить пароль root
                        </Text>
                    </TouchableOpacity>
                    {getSupportBlock(
                        vm,
                        actionEnable,
                        supportAccess,
                        currentDuration,
                        vmSupportAccess,
                        errors,
                        disabledText,
                        inProgress
                    )}
                </React.Fragment>
            )}
            <Modal
                isModalShowed={isModalChangePassword}
                component={
                    <ChangeRootPasswordModal
                        onClose={() => setModalChangePasswordVisible(false)}
                    />
                }
                onClose={() => setModalChangePasswordVisible(false)}
            />
            <CVDSWebsockets />
        </View>
    );
}

VMAccess.propTypes = {
    inProgress: PropTypes.bool,
    vm: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    vmSupportAccess: PropTypes.func,
    addCustomNotification: PropTypes.func,
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    inProgress: state.cvds.supportAccessInProgress,
    errors: state.forms.errors[formId] || {},
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        flushErrors,
        vmSupportAccess,
        addCustomNotification,
    },
    dispatch
);

const styles = StyleSheet.create({
    actionButton: {
        marginTop: 12,
        marginBottom: 8,
        paddingTop: 16,
        paddingBottom: 18,
        paddingLeft: 25,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff',
    },
    actionButtonIcon: {
        height: 20,
        width: 20,
        marginRight: 20,
    },
    actionButtonText: {
        color: '#0F79D5',
        fontSize: 14,
        fontWeight: '600',
        lineHeight: 14,
    },
    supportWrapper: {
        paddingTop: 21,
        paddingLeft: 20,
        backgroundColor: '#ffffff',
    },
    supportBlock: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 14,
    },
    supportTextBlock: {
        width: 185,
    },
    supportTitle: {
        color: '#455562',
        fontSize: 14,
        fontWeight: '600',
        lineHeight: 16,
    },
    supportDescription: {
        color: '#455562',
        fontSize: 11,
        lineHeight: 14,
        marginTop: 16,
    },
    supportRevokeDateBlock: {
        color: '#455562',
        fontSize: 11,
        lineHeight: 14,
        marginTop: 7,
    },
    supportBlockIcon: {
        height: 83,
        width: 131,
        right: 30,
        position: 'absolute',
    },
    supportButtonBlock: {
        marginBottom: 27,
        marginTop: 9,
    },
    supportButtonStyles: {
        paddingTop: 11,
        paddingBottom: 13,
        marginRight: 21,
    },
    supportButtonTextStyles: {
        fontSize: 14,
        lineHeight: 16,
        minHeight: 16,
        fontWeight: '400',
    },
    timerBlock: {
        position: 'relative',
        top: -5,
        marginBottom: 25,
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(VMAccess);
