import {
    ON_CHECK_TRANSFER_SSL,
    ON_CHECK_TRANSFER_DOMAINS,
    ON_CHECK_TRANSFER_DNS_ZONE,
    TRANSFER_RESOURCES_ON_INIT,
    TRANSFER_ACCEPT_IN_PROGRESS,
    TRANSFER_ACCEPT_WITH_ERRORS,
    TRANSFER_ACCEPT_SUCCESSFULLY,
    TRANSFER_STATUS_SUCCESSFULLY,
    TRANSFER_START_WITH_ERRORS,
    TRANSFER_START_IN_PROGRESS,
    TRANSFER_START_SUCCESSFULLY,
    TRANSFER_CANCEL_BY_RESOURCE_IN_PROGRESS,
    TRANSFER_CANCEL_BY_RESOURCE_SUCCESSFULLY,
    TRANSFER_CANCEL_BY_RESOURCE_WITH_ERRORS,
    TRANSFER_SEARCH_ON_INIT,
    TRANSFER_CHANGE_SEARCH_REQUEST,
    CHECK_RESOURCES_IN_PROGRESS,
    CHECK_RESOURCES_SUCCESSFULLY,
    CHECK_RESOURCES_WITH_ERRORS,
    FETCH_INSTANCE_RESOURCES_IN_PROGRESS,
    FETCH_INSTANCE_RESOURCES_SUCCESSFULLY,
    FETCH_INSTANCE_RESOURCES_WITH_ERRORS,
    CHANGE_TRANSFER_QUERY_SUCCESSFULLY,
} from '../actions/transfer/actionTypes';

export default () => {
    const defaultState = {
        checkedDNSZones: [],
        checkedSSL: [],
        checkedDomains: [],
        transferCancelInProgress: false,
        transferAcceptInProgress: false,
        transferStartInProgress: false,
        transferData: null,
        currentResources: null,
        searchZonesInProgress: false,
        searchDomainsInProgress: false,
        searchCertificatesInProgress: false,
        searchRequest: '',
        checkResourcesInProgress: false,
        checkResourcesIsErrors: false,
        relatedResources: {
            certificates: [],
            domains: [],
            zones: [],
        },
        service: null,
        instance: null,
        resourcesArray: null,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case ON_CHECK_TRANSFER_SSL: {
            return {
                ...state,
                checkedSSL: [...action.data.checkedEntities],
            };
        }
        case ON_CHECK_TRANSFER_DNS_ZONE: {
            return {
                ...state,
                checkedDNSZones: [...action.data.checkedEntities],
            };
        }
        case ON_CHECK_TRANSFER_DOMAINS: {
            return {
                ...state,
                checkedDomains: [...action.data.checkedEntities],
            };
        }
        case CHECK_RESOURCES_IN_PROGRESS: {
            return {
                ...state,
                currentResources: null,
                checkResourcesInProgress: true,
                checkResourcesIsErrors: false,
            };
        }
        case CHECK_RESOURCES_SUCCESSFULLY: {
            return {
                ...state,
                currentResources: action.data,
                checkResourcesInProgress: false,
                checkResourcesIsErrors: false,
            };
        }
        case CHECK_RESOURCES_WITH_ERRORS: {
            return {
                ...state,
                checkResourcesInProgress: false,
                checkResourcesIsErrors: true,
            };
        }
        case TRANSFER_STATUS_SUCCESSFULLY: {
            return {
                ...state,
                transferData: action.data,
            };
        }
        case TRANSFER_ACCEPT_IN_PROGRESS: {
            return {
                ...state,
                transferAcceptInProgress: true,
            };
        }
        case TRANSFER_ACCEPT_WITH_ERRORS: {
            return {
                ...state,
                transferAcceptInProgress: false,
            };
        }
        case TRANSFER_ACCEPT_SUCCESSFULLY: {
            return {
                ...state,
                transferAcceptInProgress: false,
            };
        }
        case TRANSFER_START_IN_PROGRESS: {
            return {
                ...state,
                transferStartInProgress: true,
            };
        }
        case TRANSFER_START_WITH_ERRORS: {
            return {
                ...state,
                transferStartInProgress: false,
            };
        }
        case TRANSFER_START_SUCCESSFULLY: {
            return {
                ...state,
                checkedSSL: [],
                searchRequest: '',
                checkedDomains: [],
                checkedDNSZones: [],
                foundResources: {
                    certificates: [],
                    domains: [],
                    zones: [],
                },
                relatedResources: {
                    certificates: [],
                    domains: [],
                    zones: [],
                },
                currentResources: null,
                transferStartInProgress: false,
            };
        }
        case TRANSFER_CANCEL_BY_RESOURCE_IN_PROGRESS: {
            return {
                ...state,
                transferCancelInProgress: true,
            };
        }
        case TRANSFER_CANCEL_BY_RESOURCE_WITH_ERRORS: {
            return {
                ...state,
                transferCancelInProgress: false,
            };
        }
        case TRANSFER_CANCEL_BY_RESOURCE_SUCCESSFULLY: {
            return {
                ...state,
                transferCancelInProgress: false,
            };
        }
        case TRANSFER_RESOURCES_ON_INIT: {
            return {
                ...state,
                checkedSSL: [],
                checkedDomains: [],
                checkedDNSZones: [],
                foundResources: {
                    zones: [],
                    domains: [],
                    certificates: [],
                },
                currentResources: null,
                relatedResources: {
                    certificates: [],
                    domains: [],
                    zones: [],
                },
            };
        }
        case TRANSFER_SEARCH_ON_INIT: {
            return {
                ...state,
                searchRequest: '',
                foundResources: {
                    zones: [],
                    domains: [],
                    certificates: [],
                },
            };
        }
        case TRANSFER_CHANGE_SEARCH_REQUEST: {
            return {
                ...state,
                searchRequest: action.data.searchRequest,
            };
        }
        case FETCH_INSTANCE_RESOURCES_IN_PROGRESS: {
            return {
                ...state,
                relatedResources: {
                    zones: [],
                    domains: [],
                    certificates: [],
                },
                checkedDNSZones: [],
                checkedSSL: [],
                checkedDomains: [],
            };
        }
        case FETCH_INSTANCE_RESOURCES_WITH_ERRORS: {
            return {
                ...state,
                relatedResources: {
                    zones: [],
                    domains: [],
                    certificates: [],
                },
                checkedDNSZones: [],
                checkedSSL: [],
                checkedDomains: [],
            };
        }
        case FETCH_INSTANCE_RESOURCES_SUCCESSFULLY: {
            const { resources } = action.data;
            const { zones, certificates, domains } = resources;
            return {
                ...state,
                relatedResources: resources,
                checkedDNSZones: zones
                    ? zones.filter((z) => !z.is_in_transfer)
                    : [],
                checkedSSL: certificates
                    ? certificates.filter((c) => !c.is_in_transfer)
                    : [],
                checkedDomains: domains
                    ? domains.filter((d) => !d.is_in_transfer)
                    : [],
            };
        }
        case CHANGE_TRANSFER_QUERY_SUCCESSFULLY: {
            return {
                ...state,
                ...action.data,
            };
        }
        default:
            return state;
        }
    };
};
