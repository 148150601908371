import {
    FETCH_MONITORING_IN_PROGRESS,
    FETCH_MONITORING_SUCCESSFULLY,
    FETCH_MONITORING_WITH_ERRORS,
    WS_MONITORING_EVENT_UPDATE,
    INIT_MONITORING_EVENT,
    INIT_MONITORING_SERVICE_EVENT,
    FETCH_MONITORING_BY_SERVICE_IN_PROGRESS,
    FETCH_MONITORING_BY_SERVICE_SUCCESSFULLY,
    FETCH_MONITORING_BY_SERVICE_WITH_ERRORS,
} from './actionTypes';

import MonitoringResource from '../../gateway/resources/monitoring';

export const fetchMonitoringEvents = () => async (dispatch, getState, { gateway }) => {
    dispatch({ type: FETCH_MONITORING_IN_PROGRESS });
    const resource = new MonitoringResource(gateway);
    const response = await resource.resources();
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_MONITORING_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: FETCH_MONITORING_WITH_ERRORS });
    }
};

export const fetchMonitoringEventsByService = (service) => async (
    dispatch, getState, { gateway }
) => {
    dispatch({ type: FETCH_MONITORING_BY_SERVICE_IN_PROGRESS });
    const resource = new MonitoringResource(gateway);
    const response = await resource.resourcesByService(service);
    const data = await response.json();
    if (response.ok) {
        dispatch({
            type: FETCH_MONITORING_BY_SERVICE_SUCCESSFULLY,
            data
        });
    } else {
        dispatch({ type: FETCH_MONITORING_BY_SERVICE_WITH_ERRORS });
    }
};

export const wsMonitoringEventUpdate = (data) => async (dispatch) => {
    dispatch({ type: WS_MONITORING_EVENT_UPDATE, data });
};

export const initEvents = () => async (dispatch) => {
    dispatch({ type: INIT_MONITORING_EVENT });
};

export const initServiceEvents = () => async (dispatch) => {
    dispatch({ type: INIT_MONITORING_SERVICE_EVENT });
};
