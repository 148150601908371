import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    View, Text, Image, StyleSheet, TouchableOpacity
} from 'react-native';

import {
    fetchCVDSDistributions,
    createVMUpdateParams,
} from '../../../actions/cvds/actions';
import { rightIcon } from '../../../icons';
import DistributionIcon from '../DistributionIcon/DistributionIcon';

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        paddingTop: 14,
        paddingRight: 20,
        paddingBottom: 16,
        paddingLeft: 16,
        marginBottom: 8,
    },
    textBlock: {
        flex: 1,
        marginLeft: 12,
    },
    title: {
        color: '#0f79d5',
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 15,
    },
    subTitle: {
        color: '#788c9f',
        fontSize: 10,
        lineHeight: 12,
    },
    emptyTitleWrapper: {
        flex: 1,
    },
    emptyTitle: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        lineHeight: 13,
        opacity: 0.7
    },
    rightIcon: {
        height: 12.55,
        width: 7,
    },
});

function DistributionsBlock(props) {
    const {
        onPress,
        distributions,
        currentDistribution,
        distributionsInProgress,
        fetchCVDSDistributions,
    } = props;

    React.useEffect(() => {
        if (!distributionsInProgress && distributions == null) {
            fetchCVDSDistributions();
        }
    }, []);
    return (
        <TouchableOpacity onPress={onPress} style={styles.wrapper}>
            {currentDistribution && currentDistribution.id && (
                <React.Fragment>
                    <DistributionIcon
                        distribution={currentDistribution.groupType}
                        iconWidth="24px"
                        iconHeight="24px"
                    />
                    <View style={styles.textBlock}>
                        <Text style={styles.title}>{currentDistribution.groupName}</Text>
                        <Text style={styles.subTitle}>{currentDistribution.name}</Text>
                    </View>
                </React.Fragment>
            )}
            {currentDistribution == null
        || (currentDistribution && currentDistribution.id == null && (
            <View style={styles.emptyTitleWrapper}>
                <Text style={styles.emptyTitle}>Выберите образ</Text>
            </View>
        ))}
            <Image source={rightIcon} style={styles.rightIcon} />
        </TouchableOpacity>
    );
}

DistributionsBlock.propTypes = {
    onPress: PropTypes.func,
    distributions: PropTypes.shape({}),
    createVMUpdateParams: PropTypes.func,
    fetchCVDSDistributions: PropTypes.func,
    distributionsInProgress: PropTypes.bool,
};
const mapStateToProps = (state) => ({
    distributions: state.cvdsDistributions.distributions,
    currentDistribution: state.cvdsCreate.currentDistribution,
    distributionsInProgress: state.cvdsDistributions.distributionsInProgress,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        fetchCVDSDistributions,
        createVMUpdateParams,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(DistributionsBlock);
