import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

const styles = StyleSheet.create({
    switcher: {
        height: 20,
        width: 37,
        flex: 1,
        flexDirection: 'row',
        borderRadius: 10,
        backgroundColor: 'rgba(161, 170, 177, 0.5)'
    },
    activeSwitcher: {
        height: 20,
        width: 37,
        flex: 1,
        flexDirection: 'row-reverse',
        borderRadius: 10,
        backgroundColor: '#107ad5'
    },
    innerCircle: {
        height: 18,
        width: 18,
        borderRadius: '50%',
        marginTop: 1,
        marginRight: 1,
        marginBottom: 1,
        marginLeft: 1,
        backgroundColor: '#ffffff'
    }
})

function SwitcherCheckbox(props) {
    const {
        value,
        onChange
    } = props;
    return <View>
        <TouchableOpacity onPress={() => onChange(!value)}
            style={value ? styles.activeSwitcher : styles.switcher}>
            <View style={styles.innerCircle}/>
        </TouchableOpacity>
    </View>;
};

SwitcherCheckbox.propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func
};

export default SwitcherCheckbox;
