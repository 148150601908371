import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import Input from '../../General/Form/Input';
import { flushErrors } from '../../../actions/forms/actions';
import { changeMobilePhone } from '../../../actions/account/actions';
import Button from '../../General/Button/Button';

const formId = 'ChangeMobilePhoneConfirmForm';

function isDisabled(changedPhone) {
    return changedPhone.length === 0;
}

function AccountChangePhoneStep1(props) {
    const {
        phone,
        errors,
        changeMobilePhone,
        flushErrors,
        changePhoneInProgress,
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);
    const [changedPhone, setModalChangePhone] = React.useState(phone);

    return <View style={styles.wrapper}>
        <Text style={styles.title}>Мобильный телефон</Text>
        <View>
            <Input
                errors={errors.phone}
                textInputAttrs={{
                    autoFocus: true,
                    numberOfLines: 1,
                    value: changedPhone,
                    dataDetectorTypes: 'phoneNumber',
                    placeholder: '+7 9XX XXX-XX-XX',
                    onChange: (e) => {
                        flushErrors();
                        setModalChangePhone(e.target.value);
                    }
                }}
            />
        </View>
        {errors.__all__ && <Text style={styles.error}>{errors.__all__}</Text>}
        <Button
            title='Подтвердить телефон'
            disabled={isDisabled(changedPhone)}
            onPress={() => changeMobilePhone(formId, { phone: changedPhone })}
            inProgress={changePhoneInProgress}
            additionalButtonStyles={[{ paddingTop: 14, paddingBottom: 13 }]}
            additionalButtonTextStyles={[styles.buttonText]}
        />
        <Text style={styles.hint}>Отправим SMS для подтверждения номера</Text>
    </View>;
}

AccountChangePhoneStep1.propTypes = {
    phone: PropTypes.string,
    errors: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    onClose: PropTypes.func,
    changeMobilePhone: PropTypes.func,
    changePhoneInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    phone: state.account.account.phone,
    changePhoneInProgress: state.account.changePhoneInProgress,
    phoneVerificationData: state.account.account.phone_verification_data,
    errors: state.forms.errors.ChangeMobilePhoneConfirmForm || {}
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    changeMobilePhone,
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 44,
        paddingBottom: 42,
        paddingLeft: 40,
        paddingRight: 40,
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 20,
        color: '#455562',
    },
    buttonText: {
        fontWeight: '400',
        fontSize: 14,
    },
    error: {
        color: '#d06565',
        marginTop: 10,
    },
    hint: {
        opacity: 0.8,
        fontSize: 12,
        marginTop: 20,
        color: '#455562',
        textAlign: 'center',
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountChangePhoneStep1);