import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useFocusEffect } from '@react-navigation/native';

import { getChannel } from '@netangels/core/utils/websockets';
import { userChannelName } from '@netangels/core/constants/websockets';

import useWebsockets from '../../hooks/useWebsockets';
import { connectToWebsocketWithDelay } from '../../utils/websockets';
import { momentNotification } from '../../actions/notifications/actions';

function NotificationMomentWS(props) {
    const { user, momentNotification } = props;

    let notificationsSubscriber = null;
    const ws = useWebsockets();
    useFocusEffect(useCallback(() => {
        let connectionState = null;
        if (user) {
            connectionState = connectToWebsocketWithDelay(() => {
                const channelName = getChannel(userChannelName, user.login, true);
                if (notificationsSubscriber == null) {
                    notificationsSubscriber = ws.subscriber('notification', channelName);
                    notificationsSubscriber.on('notification', (data) => {
                        momentNotification(data);
                    });
                }
            });
        }
        return () => {
            if (connectionState != null) {
                connectionState.cancel();
            }
            if (notificationsSubscriber != null) {
                ws.unsubscribe(notificationsSubscriber);
                notificationsSubscriber = null;
            }
        };
    }, []));
    return null;
}

NotificationMomentWS.propTypes = {
    user: PropTypes.shape({
        login: PropTypes.string
    }),
    momentNotification: PropTypes.func
};

const mapStateToProps = (state) => ({
    user: state.user.user,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({ momentNotification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMomentWS);
