import * as dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';

import Errors from '../../General/Form/Errors';
import { changeProtectedWebsite } from '../../../actions/cvds/actions';
import { certificatesFind, initFindCertificates } from '../../../actions/certificates/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';
import SelectModal from '../../General/SelectModal/SelectModal';

const formId = 'ChangeProtectedWebsiteModalForm';

function ChangeProtectedWebsiteModal(props) {
    const {
        onClose,
        website,
    } = props;
    const dispatch = useDispatch();
    const { currentVM, changeProtectedWebsiteInProgress } = useSelector((state) => state.cvds);
    const { errors } = useSelector((state) => state.forms);

    const { foundCertificates, foundCertificatesInProgress } = useSelector(
        (state) => state.certificates,
    );

    const [normalizedCertificates, setNormalizedCertificates] = React.useState([
        {
            value: -1,
            index: 0,
            label: 'без сертификата',
        },
    ]);
    const [certificate, setCertificate] = useState(normalizedCertificates[0]);
    useEffect(() => {
        if (foundCertificates) {
            const optionsList = [
                {
                    value: -1,
                    index: 0,
                    label: 'без сертификата',
                },
            ];
            foundCertificates.entities.forEach((item, i) => {
                let label = item.name;
                if (item.not_before) {
                    const parsedTime = dayjs(
                        item.not_before,
                        'YYYY-MM-DD HH:mm:ss',
                    );
                    label = `${label} c ${parsedTime.format('DD MMMM YYYY')}`;
                }
                optionsList.push({
                    value: item.id,
                    index: i + 1,
                    label,
                });
                if (website.certificate_id === item.id) {
                    setCertificate({
                        value: item.id,
                        index: i + 1,
                        label,
                    });
                }
            });
            setNormalizedCertificates(optionsList);
        }
    }, [foundCertificates]);

    useEffect(() => {
        dispatch(certificatesFind(website.domain));
        return () => dispatch(initFindCertificates());
    }, []);
    const formErrors = errors[formId] || {};
    return <View style={styles.wrapper}>
        <Text style={styles.title}>SSL-сертификат для {website.domain}</Text>
        <View style={styles.inputBlock}>
            <SelectModal label='Сертификат'
                initialSelectedIndex={certificate.index}
                onChange={(value) => setCertificate(value)}
                currentItem={certificate}
                itemList={normalizedCertificates}/>
        </View>
        {formErrors.__all__ && <Errors errors={formErrors.__all__}/>}
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={() => {
                    dispatch(
                        changeProtectedWebsite(
                            formId,
                            currentVM.id,
                            website.id,
                            Number.parseInt(certificate, 10),
                            () => onClose(),
                        ),
                    );
                }}
                title="Изменить"
                inProgress={changeProtectedWebsiteInProgress}/>
            <CancelButton onClose={onClose} disabled={changeProtectedWebsiteInProgress} />
        </View>
    </View>;
}

ChangeProtectedWebsiteModal.propTypes = {
    onClose: PropTypes.func,
    website: PropTypes.shape({
        domain: PropTypes.string,
        id: PropTypes.number,
    })
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 32,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 28
    },
    title: {
        color: '#455562',
        fontSize: 16,
        lineHeight: 19
    },
    text: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 18
    },
    price: {
        color: '#627687',
        fontSize: 14,
        lineHeight: 20,
        fontWeight: '500',
        marginTop: 16,
        paddingBottom: 16,
        borderBottomColor: 'rgba(69, 85, 98, 0.3)',
        borderBottomWidth: 1,
        borderStyle: 'dashed'
    },
    buttonBlock: {
        flex: 1,
        marginTop: 26,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    packetWarning: {
        marginTop: 16
    },
    inputBlock: {
        marginTop: 24,
    },
    inputBlockText: {
        color: '#455562',
        fontSize: 11,
        lineHeight: 14,
        marginBottom: 4,
    }
});

export default ChangeProtectedWebsiteModal;
