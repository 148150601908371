import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';

import { rightIcon, checkedRadioButton } from '../../icons';
import { tariffsByCategory } from '../../constants/cvds';
import cvdsTariffs from '../../../data/cvds/tariffs.json';
import { formatMemory } from '../../utils/general';


function getTariffsByCategory({ category, categoryName,
    currentCategory, setCurrentCategory, onChange, vmTariff,
    currentTariffName, setCurrentTariffName }) {
    return <View>
        <TouchableOpacity onPress = {() => {
            if (currentCategory !== category) {
                setCurrentCategory(category);
            } else {
                setCurrentCategory('');
            }
        }} style={styles.category}>
            <Text style={styles.categoryName}>{categoryName}</Text>
            <Image source={rightIcon}
                style={currentCategory === category ? styles.bottomIcon : styles.rightIcon}/>
        </TouchableOpacity>
        {currentCategory === category && getTariffsListByCategory(category, onChange, vmTariff,
            currentTariffName, setCurrentTariffName)}
    </View>;
}

function getTariffsListByCategory(category, onChange, vmTariff,
    currentTariffName, setCurrentTariffName) {
    const tariffs = cvdsTariffs.filter(tariff => tariff.enabled && tariff.type === category);
    return tariffs.map((tariff, i) => {
        const tariffStyles = [styles.tariff];
        if (currentTariffName === tariff.name) {
            tariffStyles.push(styles.activeTariff);
        }
        if (vmTariff === tariff.name) {
            tariffStyles.push(styles.currentTariff);
        }
        return <TouchableOpacity key={`tariff-${category}-${i}`}
            onPress={() => {
                setCurrentTariffName(tariff.name);
                onChange(tariff);
            }} style={[...tariffStyles]}>
            {vmTariff !== tariff.name
                && <View style={currentTariffName === tariff.name ? styles.activeOuterCircle
                    : styles.outerCircle}>
                    {currentTariffName === tariff.name && <View style={styles.innerCircle}/>}
                </View>}
            {vmTariff === tariff.name && <Image source={checkedRadioButton} style={styles.icon} />}
            <Text style={[styles.tariffName,
                { color: currentTariffName === tariff.name ? '#0f79d5' : '#455562' }]}>
                {tariff.name}
            </Text>
            <View>
                <View style={styles.tariffInfo}>
                    <Text style={styles.tariffInfoText}>
                        CPUx{tariff.features.cpu},
                    </Text>
                    <Text style={[styles.tariffInfoText, { marginLeft: 3 }]}>
                        RAM: {formatMemory(tariff.features.memory)}
                    </Text>
                </View>
            </View>
        </TouchableOpacity>;
    });
}


function TariffsList(props) {
    const { vm, onChange } = props;
    const currentTariff = cvdsTariffs.find(tariff => tariff.id === vm.tariff);
    const [vmTariff, setVmTariff] = React.useState(currentTariff.name);
    const [currentTariffName, setCurrentTariffName] = React.useState(currentTariff.name);
    const [currentCategory, setCurrentCategory] = React.useState(currentTariff.type);

    return tariffsByCategory.map(tariff => getTariffsByCategory(
        {
            ...tariff,
            currentCategory,
            setCurrentCategory,
            onChange, vmTariff,
            currentTariffName, setCurrentTariffName
        }
    ));
}

TariffsList.propTypes = {
    vm: PropTypes.shape({
        id: PropTypes.number,
        tariff: PropTypes.string,
        disk: PropTypes.shape({
            is_backup_enabled: PropTypes.bool
        })
    }),
    onChange: PropTypes.func
};

const styles = StyleSheet.create({
    category: {
        paddingTop: 18,
        paddingLeft: 24,
        paddingRight: 32,
        paddingBottom: 18,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(69,85,98,0.15)'
    },
    categoryName: {
        color: '#0F79D5',
        fontSize: 16,
        fontWeight: '600',
        lineHeight: 19
    },
    rightIcon: {
        width: 9,
        height: 16
    },
    bottomIcon: {
        transform: 'rotate(90deg)',
        width: 9,
        height: 16
    },
    tariff: {
        paddingTop: 15,
        paddingLeft: 25,
        paddingRight: 27,
        paddingBottom: 15,
        marginBottom: 1,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff'
    },
    currentTariff: {
        paddingTop: 13,
        paddingLeft: 20,
        paddingRight: 27,
        paddingBottom: 11,
    },
    activeTariff: {
        backgroundColor: 'rgba(15, 121, 213, 0.07)',
        borderBottomWidth: 1,
        borderBottomColor: '#0F79D5',
        borderTopWidth: 1,
        borderTopColor: '#0F79D5',
        marginBottom: 0
    },
    tariffName: {
        flex: 1,
        color: '#455562',
        fontSize: 13,
        lineHeight: 15,
        fontWeight: '500'
    },
    tariffInfo: {
        flex: 1,
        flexDirection: 'row'
    },
    tariffInfoText: {
        color: '#455562',
        fontSize: 10,
        lineHeight: 11
    },
    outerCircle: {
        height: 18,
        width: 18,
        marginRight: 12,
        borderTopWidth: 1,
        borderTopColor: 'rgba(161, 170, 177, 0.5)',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(161, 170, 177, 0.5)',
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(161, 170, 177, 0.5)',
        borderRightWidth: 1,
        borderRightColor: 'rgba(161, 170, 177, 0.5)',
        borderRadius: 9,
        backgroundColor: '#f5f5f5'
    },
    activeOuterCircle: {
        height: 18,
        width: 18,
        marginRight: 12,
        borderTopWidth: 1,
        borderTopColor: '#0f79d5',
        borderBottomWidth: 1,
        borderBottomColor: '#0f79d5',
        borderLeftWidth: 1,
        borderLeftColor: '#0f79d5',
        borderRightWidth: 1,
        borderRightColor: '#0f79d5',
        borderRadius: 9,
        backgroundColor: '#ffffff',
        paddingTop: 4,
        paddingLeft: 4
    },
    innerCircle: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0f79d5'
    },
    icon: {
        width: 24,
        height: 24,
        marginRight: 10,
    },
});

export default TariffsList;