import React, { useState } from 'react';
import * as dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    Image
} from 'react-native';

import {
    rightIcon
} from '../../../icons';
import { isFunction } from '../../../utils/general';
import RadioButton from '../../General/Form/RadioButton';
import Button from '../../General/Button/Button';
import Spring from '../../General/Spring/Spring';
import BackupDateSelect from '../../General/BackupDateSelect/BackupDateSelect';
import { restoreFromBackupUpdateParams } from '../../../actions/cvds/actions';

function RestoreBackupMethodsStep() {
    const dispatch = useDispatch();
    const {
        currentVM,
        backupDate,
        vmForBackup,
        backupMethod,
        resourceType,
        backupMethods,
    } = useSelector((state) => state.cvdsRestoreFromBackup);
    const { backups, backupDates, backupDatesInProgress } = useSelector(
        (state) => state.cvdsBackups,
    );
    const navigation = useNavigation();

    const getMethodsTitleFuncs = {
        rollback: () => `Откатить состояние ${
            resourceType === 'VM' ? 'виртуальной машины' : 'диска'
        }`,
        // create_volume: () => (
        //     <CreateVolumeTitleComponent
        //         onChange={onChange}
        //         currentVM={currentVM}
        //         vmForBackup={vmForBackup}
        //         vmList={vmList}
        //     />
        // ),
        create_vm: () => 'Восстановить в новую ВМ',
        move_backup: () => 'Положить копию в файловое хранилище',
    };
    return (
        <React.Fragment>
            {backups && backups.entities.length !== 0 && (
                <View style={styles.wrapper}>
                    <Text style={styles.title}>
                        точка восстановления
                    </Text>
                    <View style={styles.dateSelectBlock}>
                        <BackupDateSelect
                            onChange={(value) => {
                                const { methods } = value;
                                const currentMethod = methods.find(
                                    (m) => m.id === backupMethod.id,
                                );
                                dispatch(
                                    restoreFromBackupUpdateParams({
                                        backupDate: value.backup_date,
                                        backupMethod:
                                            currentMethod != null
                                                ? currentMethod
                                                : methods[0],
                                        backupMethods: methods,
                                        filesForArchive: [],
                                    }),
                                );
                            }}
                            currentDate={backupDate}
                            dateFieldName="backup_date"
                            points={backupDates.entities}
                            isPointsTimeInUTC={false}
                        />
                    </View>
                    <Text style={styles.title}>
                        что вы хотите сделать?
                    </Text>
                    {backupMethods.map((method, index) => {
                        if (isFunction(getMethodsTitleFuncs[method.id])) {
                            return (
                                <RadioButton
                                    key={`backup-method-${index}`}
                                    additionalWrapperStyles={[{ marginBottom: 1 }]}
                                    onChange={() => dispatch(
                                        restoreFromBackupUpdateParams(
                                            {
                                                backupMethod: method,
                                            },
                                        ),
                                    )}
                                    isActive={backupMethod.id === method.id}
                                    label={getMethodsTitleFuncs[method.id]()}
                                />
                            );
                        }
                        return <React.Fragment key={index} />;
                    })}
                    <Spring />
                    <Button
                        onPress={() => navigation.navigate('CVDSBackupConfiguration')}
                        title="Продолжить"
                        additionalWrapperStyles={[{
                            marginLeft: 20, marginRight: 20, marginBottom: 30
                        }]}
                        additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 10 }]}
                        additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16, fontWeight: '400' }]}
                    />
                </View>
            )}
        </React.Fragment>
    );
}

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
    },
    title: {
        color: '#455562',
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        marginBottom: 10,
        marginLeft: 20,
    },
    dateSelectBlock: {
        marginBottom: 30,
        marginLeft: 20,
    },
    resourceBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        paddingLeft: 20,
        paddingBottom: 20,
        paddingTop: 20,
        paddingRight: 20,
        marginBottom: 1,
    },
    resourceBlockTitle: {
        color: '#455562',
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 14
    },
    image: {
        width: 7,
        height: 13,
    }
});

export default RestoreBackupMethodsStep;
