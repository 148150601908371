import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, Text, StyleSheet } from 'react-native';

import { disksIds } from '../../../constants/cvds';
import cvdsTariffData from '../../../../data/cvds/tariffs.json';
import cvdsDiskData from '../../../../data/cvds/disk-types.json';
import { refreshPacket } from '../../../actions/cvds/actions';
import UpdatePacketModal from '../../General/CreatePacketModal/UpdatePacketModal';

const formId = 'UpdatePacketModalForm';

function getPacketInfo(packet) {
    const {
        vm,
        storage,
        ips,
        backup,
        bandwidth
    } = packet.resources;
    const tariff = vm;
    const currentTariff = cvdsTariffData.find(cvdsTariff => cvdsTariff.id === tariff);
    const disksInfo = disksIds.map(diskId => ({
        value: storage[diskId],
        isChange: false,
        format: v => `${v} ГБ`,
        title: cvdsDiskData.find(diskData => diskData.id === diskId).name,
        isDisplayed: storage[diskId] !== 0
    }));
    return [
        {
            value: currentTariff.name,
            isChange: false,
            format: v => v,
            title: 'Тариф'
        }, ...disksInfo, {
            value: ips,
            isChange: false,
            format: v => `${v} шт`,
            title: 'IP',
            isDisplayed: ips !== 0
        }, {
            value: backup,
            isChange: false,
            title: 'Бэкапы',
            format: v => `${v} ГБ`,
            isDisplayed: backup !== 0
        }, {
            value: bandwidth,
            isChange: false,
            title: 'Защита от DDoS',
            format: v => `${v} Мбит/с`,
            isDisplayed: bandwidth !== 0
        }
    ];
}

function getNewPacketInfo(resources, packetResoures) {
    const {
        vm,
        storage,
        ips,
        backup,
        bandwidth
    } = packetResoures;
    const currentTariff = cvdsTariffData.find(cvdsTariff => cvdsTariff.id
        === resources.vm.tariff);
    const disksInfo = disksIds.map(diskId => ({
        value: storage[diskId] + resources.storage[diskId].count,
        isChange: resources.storage[diskId].count !== 0,
        format: v => `${v} ГБ`,
        title: cvdsDiskData.find(diskData => diskData.id === diskId).name,
        isDisplayed: storage[diskId] !== 0
            || resources.storage[diskId].cost !== 0
    }));
    return [
        {
            value: currentTariff.name,
            isChange: vm !== resources.vm.tariff,
            format: v => v,
            title: 'Тариф'
        }, ...disksInfo, {
            value: ips + resources.ips.count,
            isChange: resources.ips.count !== 0,
            format: v => `${v} шт`,
            title: 'IP',
            isDisplayed: ips !== 0 || resources.ips.count !== 0
        }, {
            value: backup + resources.backup.count,
            isChange: resources.backup.count !== 0,
            title: 'Бэкапы',
            format: v => `${v} ГБ`,
            isDisplayed: backup !== 0 || resources.backup.count !== 0
        }, {
            value: resources.bandwidth.count,
            isChange: resources.bandwidth.count !== bandwidth,
            title: 'Защита от DDoS',
            format: v => `${v} Мбит/с`,
            isDisplayed: bandwidth !== 0 || resources.bandwidth.count !== 0
        }
    ];
}

function CVDSUpdatePacketModal(props) {
    const {
        vm,
        errors,
        refreshPacket,
        onClose,
        inProgress,
        calculation
    } = props;
    const {
        cost,
        packet,
        resources,
        recalculation
    } = calculation;
    return <View style={styles.wrapper}>
        <Text style={[styles.title]}>Обновление годового пакета</Text>
        <UpdatePacketModal
            inProgress={inProgress}
            packetDescriptionParameters={getPacketInfo(packet)}
            newPacketDescriptionParameters={getNewPacketInfo(resources, packet.resources)}
            errors={errors}
            timeTo={packet.time_to}
            monthPrice={cost.month}
            currentPacketCost={cost.packet}
            onClose={onClose}
            onAccept={() => refreshPacket(formId, vm.id, () => onClose())}
            restAmount={recalculation.packet_rest}
            recalculationCharge={recalculation.charge} />
    </View>;
}

const PacketType = PropTypes.shape({
    cost: PropTypes.number,
    id: PropTypes.number,
    resources: PropTypes.shape({
        backup: PropTypes.number,
        bandwidth: PropTypes.number,
        ips: PropTypes.number,
        storage: PropTypes.shape({
            fast: PropTypes.number,
            normal: PropTypes.number,
            slow: PropTypes.number
        }),
        vm: PropTypes.string
    }),
    is_active: PropTypes.bool,
    is_awaiting: PropTypes.bool,
    prolong: PropTypes.bool,
    paid: PropTypes.bool,
    time_to: PropTypes.string,
    time_from: PropTypes.string
});

CVDSUpdatePacketModal.propTypes = {
    vm: PropTypes.shape({}),
    calculation: PropTypes.shape({
        resources: PropTypes.shape({
            backup: PropTypes.shape({
                count: PropTypes.number,
                cost: PropTypes.number
            }),
            ips: PropTypes.shape({
                count: PropTypes.number,
                cost: PropTypes.number
            }),
            storage: PropTypes.shape({
                fast: PropTypes.shape({
                    count: PropTypes.number,
                    cost: PropTypes.number
                }),
                normal: PropTypes.shape({
                    count: PropTypes.number,
                    cost: PropTypes.number
                }),
                slow: PropTypes.shape({
                    count: PropTypes.number,
                    cost: PropTypes.number
                })
            }),
            vm: PropTypes.shape({
                tariff: PropTypes.string,
                cost: PropTypes.number
            })
        }),
        cost: PropTypes.shape({
            month: PropTypes.number,
            packet: PropTypes.number
        }),
        recalculation: PropTypes.shape({
            charge: PropTypes.number,
            packet_rest: PropTypes.number
        }),
        packet: PacketType
    }),
    onClose: PropTypes.func,
    refreshPacket: PropTypes.func,
    inProgress: PropTypes.bool,
    errors: PropTypes.shape({})
};

const mapStateToProps = state => ({
    vm: state.cvds.currentVM,
    calculation: state.cvdsFinanceInfo.calculation,
    inProgress: state.cvdsFinanceInfo.updatePacketInProgress,
    errors: state.forms.errors[formId] || {}
});
const mapDispatchToProps = dispatch => bindActionCreators({ refreshPacket }, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 30,
        paddingLeft: 24,
        paddingRight: 21,
        paddingBottom: 18
    },
    title: {
        marginBottom: 16,
        color: '#455562',
        textAlign: 'center',
        fontSize: 16,
        lineHeight: 18
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CVDSUpdatePacketModal);
