import cvdsMonitoringEvents from '../../data/frontend/monitoring/cvds.json';
import mailMonitoringEvents from '../../data/frontend/monitoring/mail.json';
import hostingMonitoringEvents from '../../data/frontend/monitoring/hosting.json';
import domainsMonitoringEvents from '../../data/frontend/monitoring/domain.json';
import financesMonitoringEvents from '../../data/frontend/monitoring/finances.json';

export const eventsData = [
    cvdsMonitoringEvents,
    hostingMonitoringEvents,
    domainsMonitoringEvents,
    mailMonitoringEvents,
    financesMonitoringEvents,
];

export const monitoringColorsClassMap = {
    OK: 'safe',
    Warning: 'warning',
    Critical: 'danger'
};
