import React, { useContext } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';
import { useRoute, useNavigation } from '@react-navigation/native';

import { ModalContext } from '../../../contexts/ModalContext';

import Button from '../../General/Button/Button';
import Actions from '../../General/Actions/Actions';
import Action from '../../General/Actions/Action';
import Separator from '../../General/Separator/Separator';
import {
    promisedPaymentIcon, promisedPaymentDisabledIcon,
    forecastIcon, detailIcon
} from '../../../icons';
import { formatPrice } from '../../../utils/general';

const promisedPaymentTooltipText = 'Обещанный платеж активирует кредит на 24 часа работы, если средств на балансе остается менее, чем на сутки. Сервис доступен не чаще одного раза в месяц и при условии, что ваш первый платеж за услуги совершен не менее полугода назад.';

function getEnough(date) {
    const momentPaidTill = dayjs(date, 'YYYY-MM-DD');
    const current = dayjs();
    let formatDate = null;
    if (current.year() !== momentPaidTill.year()) {
        formatDate = momentPaidTill.format('D MMMM YYYY г.');
    } else {
        formatDate = momentPaidTill.format('D MMMM');
    }
    return `Хватит примерно до ${formatDate}`;
}

function promisedPaymentDisabledText(creditInfo) {
    const promisedPaymentNextEnabledDate = dayjs(
        creditInfo.enabled_to,
        'YYYY-MM-DD HH:mm:ss',
    ).add(30, 'days');
    const promisedPaymentTooltipVisible = creditInfo.enabled_to != null
        && promisedPaymentNextEnabledDate > dayjs();
    if (promisedPaymentTooltipVisible) {
        return `В этом месяце вы уже использовали обещанный платеж. Воспользоваться им снова вы сможете с ${promisedPaymentNextEnabledDate.format('DD MMMM')}`;
    }
    return promisedPaymentTooltipText;
}

function FinanceBalance(props) {
    const {
        financeInfo,
        additionalStyles = [],
    } = props;

    const route = useRoute();
    const navigation = useNavigation();

    const creditInfo = financeInfo.credit_info;
    const canActivatePromisedPayment = creditInfo.can_activate;
    const currentBalance = financeInfo.balance.balance;
    const balanceTextColor = currentBalance < 0 ? '#d06565' : '#455562';

    const { closeModal } = useContext(ModalContext);

    return <View style={[styles.wrapper, ...additionalStyles]}>
        <Text style={styles.title}>Текущий баланс</Text>
        <Text style={[styles.balance, { color: balanceTextColor }]}>{formatPrice(currentBalance)} ₽</Text>
        {financeInfo.paid_till && <Text style={styles.prediction}>{getEnough(financeInfo.paid_till)}</Text>}
        <View style={styles.buttonsWrapper}>
            <Button title='Пополнить баланс'
                onPress={() => navigation.navigate('Payment')}
                additionalWrapperStyles={[{ marginRight: 12, flex: 1 }]}
                additionalButtonStyles={[{ paddingTop: 12, paddingBottom: 13 }]}
                additionalButtonTextStyles={[{
                    fontSize: 13,
                    lineHeight: 15,
                    minHeight: 15,
                    fontWeight: '400'
                }]}
            />
            <Actions modalId='finance-balance-actions' >
                <Action icon={canActivatePromisedPayment ? promisedPaymentIcon
                    : promisedPaymentDisabledIcon} title='Обещанный платеж'
                enabled={canActivatePromisedPayment}
                disabledText={promisedPaymentDisabledText(creditInfo)}
                onPress={() => {
                    closeModal();
                    navigation.navigate('PromisedPayment');
                }}/>
                <Separator />
                <Action icon={forecastIcon} title='Ожидаемые списания'
                    onPress={() => {
                        closeModal();
                        navigation.navigate('Forecast')
                    }}
                />
                <Separator />
                <Action icon={detailIcon} title='Детализация, счета и акты'
                    onPress={() => {
                        closeModal();
                        if (route.name !== 'FinanceBalance') {
                            navigation.navigate('Finance', { currentTab: 'FinanceDocuments'});
                        } else {
                            navigation.navigate('FinanceDocuments');
                        }
                    }} />
            </Actions>
        </View>
    </View>;
};

FinanceBalance.propTypes = {
    financeInfo: PropTypes.shape({}),
    onPressPaymentButton: PropTypes.func,
    styles: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
    financeInfo: state.user.financeInfo
});
const mapDispatchToProps = () => ({});

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 23,
        paddingLeft: 20,
        paddingRight: 18,
        paddingBottom: 18
    },
    title: {
        fontWeight: '500',
        marginBottom: 6,
        color: '#455562',
        fontSize: 11,
        lineHeight: 13,
        letterSpacing: 1.38,
        opacity: 0.75,
        textTransform: 'uppercase'
    },
    balance: {
        fontSize: 30,
        lineHeight: 35,
        fontWeight: '600',
        marginBottom: 6
    },
    prediction: {
        fontSize: 10,
        lineHeight: 11,
        color: '#455562',
        marginBottom: 10
    },
    buttonsWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(FinanceBalance);
