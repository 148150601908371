import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';

import WarningCancelPacketBlock from '../WarningCancelPacketBlock';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

function WarningCancelPacketModal(props) {
    const {
        onClose,
        onAccept,
        mainText,
        inProgress,
        warningText,
    } = props;

    return <View style={styles.wrapper}>
        <WarningCancelPacketBlock mainText={mainText} warningText={warningText} />
        <View style={styles.buttonBlock}>
            <AcceptButton
                onSubmit={onAccept}
                title='Продолжить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

WarningCancelPacketModal.propTypes = {
    onClose: PropTypes.func,
    onAccept: PropTypes.func,
    mainText: PropTypes.string,
    warningText: PropTypes.string,
    inProgress: PropTypes.bool,
};

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 18
    },
    buttonBlock: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default WarningCancelPacketModal;
