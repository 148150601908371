import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, TextInput } from 'react-native';

import { flushErrors } from '../../../actions/forms/actions';
import { changeRequisites } from '../../../actions/account/actions';
import AcceptButton from '../../General/Modal/AcceptButton';
import CancelButton from '../../General/Modal/CancelButton';

const formId = 'ChangePostAddrFormId';

function AccountChangePostAddr(props) {
    const {
        errors,
        onClose,
        postAddr,
        flushErrors,
        changeRequisites,
        inProgress
    } = props;

    React.useEffect(() => {
        flushErrors();
    }, []);

    const [changedPostAddr, setModalChangePostAddr] = React.useState(postAddr);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Почтовый адрес</Text>
        <View>
            <TextInput
                style={styles.textInput}
                autoFocus={true}
                multiline={true}
                numberOfLines={5}
                value={changedPostAddr}
                onChange={(e) => setModalChangePostAddr(e.target.value)}
            />
            {errors.postAddr && <Text style={styles.error}>{errors.postAddr}</Text>}
        </View>
        {errors.__all__ && <Text style={styles.error}>{errors.__all__}</Text>}
        <View style={styles.buttons}>
            <AcceptButton
                onSubmit={() => changeRequisites(formId, { postAddr: changedPostAddr },
                    () => onClose())}
                title='Сохранить'
                inProgress={inProgress}/>
            <CancelButton onClose={onClose} disabled={inProgress} />
        </View>
    </View>;
}

AccountChangePostAddr.propTypes = {
    onClose: PropTypes.func,
    postAddr: PropTypes.string,
    errors: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    changeRequisites: PropTypes.func,
    inProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    postAddr: state.account.account.post_addr,
    inProgress: state.account.changeRequisitesInProgress,
    errors: state.forms.errors.ChangePostAddrConfirmForm || {}
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    changeRequisites
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        paddingTop: 44,
        paddingBottom: 42,
        paddingLeft: 40,
        paddingRight: 40,
    },
    title: {
        fontSize: 20,
        lineHeight: 29,
        textAlign: 'center',
        marginBottom: 10,
        color: '#455562',
    },
    buttons: {
        flex: 1,
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        color: '#d06565',
        marginBottom: 10,
    },
    textInput: {
        backgroundColor: '#fff',
        borderColor: '#dce2e9',
        borderWidth: 1,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
        marginBottom: 10,
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountChangePostAddr);