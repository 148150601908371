import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';
import { bindActionCreators } from 'redux';

import Modal from '../../General/Modal/Modal';
import { cvdsPacketIcon } from '../../../icons';
import Button from '../../General/Button/Button';
import CreatePacketModal from '../Modals/CreatePacketModal';
import VMFinanceBlockWithPacket from './FinanceBlockWithPacket';
import { cancelPacket } from '../../../actions/cvds/actions';
import VMFinanceBlockWithoutPacket from './FinanceBlockWithoutPacket';
import {
    getValueFromPercent,
    getPriceForPacket,
    getPacketInfo,
    isEnabledFinanceFunctions
} from '../utils';
import { blockedButtonTooltips } from '../../../constants/general';
import { formatPrice } from '../../../utils/general';

function packetAdvantage(cost) {
    const fullCost = getValueFromPercent(cost.packet, 10);
    return getPriceForPacket(fullCost - cost.packet);
}

function blockWithoutPacket(setCreatePacketModalVisible, vmState, cost) {
    return <View style={styles.packetBlock}>
        <View>
            <Text style={styles.packetTitle}>
                Получите скидку 10%
            </Text>
            <View style={styles.packetDescriptionBlock}>
                <Text style={styles.packetDescription}>
                    Оплатите за год и экономьте
                </Text>
                <Text style={styles.packetDescriptionPrice}>
                    {formatPrice(packetAdvantage(cost))} ₽
                </Text>
            </View>
            <Button title='Заказать годовой пакет' type='white'
                disabled={!isEnabledFinanceFunctions(vmState)}
                disabledText={blockedButtonTooltips.cvds[vmState]}
                additionalButtonTextStyles={[styles.packetButtonTextStyles]}
                additionalButtonStyles={[styles.packetButtonStyles]}
                onPress={() => setCreatePacketModalVisible(true)} />
        </View>
        <Image source={cvdsPacketIcon} style={styles.packetIcon} />
    </View>;
}

function blockWithAwaitingPacket(vmState, vmId, packet, cancelPacket) {
    const navigation = useNavigation();
    return <View style={styles.packetBlock}>
        <View style={{ flex: 1 }}>
            <View style={styles.packetTitleBlock}>
                <Text style={styles.packetTitle}>Годовой пакет</Text>
                <Text style={styles.packetTitleWarning}>ожидает оплаты</Text>
            </View>
            <View style={styles.packetDescriptionBlock}>
                <Text style={styles.packetDescription}>Оплата должна быть произведена</Text>
                <Text style={styles.packetDescriptionPrice}>
                    до {dayjs(packet.time_from).format('DD MMMM')}
                </Text>
            </View>
            <Button title={`Оплатить ${formatPrice(packet.cost)} ₽`}
                onPress={() => navigation.navigate('Payment')}
                disabled={!isEnabledFinanceFunctions(vmState)}
                disabledText={blockedButtonTooltips.cvds[vmState]}
                additionalButtonTextStyles={[styles.packetButtonTextStyles, { color: '#ffffff' }]}
                additionalWrapperStyles={[{ marginRight: 12, flex: 1 }]}
                additionalButtonStyles={[{ paddingTop: 9, paddingBottom: 12 }]}
            />
            <TouchableOpacity onPress={() => {
                if (isEnabledFinanceFunctions(vmState)) {
                    cancelPacket(vmId);
                }
            }}>
                <Text style={[styles.cancelPacketButton,
                    { color: isEnabledFinanceFunctions(vmState) ? '#0f79d5' : '#888888'}]}>
                    Отказаться от пакета
                </Text>
            </TouchableOpacity>
        </View>
    </View>;
}
function blockWithPacket(calculation) {
    const tariffInfo = getPacketInfo(calculation);
    return <View style={styles.packetBlock}>
        <View>
            <Text style={styles.packetTitle}>Действующий годовой пакет</Text>
            <Text style={styles.packetSubtitle}>{tariffInfo}</Text>
            {calculation.packet.paid && <Text style={styles.packetPaidText}>
                оплачен до {dayjs(calculation.packet.time_to).format('DD MMMM YYYY')} г.
            </Text>}
        </View>
    </View>;
}

function FinanceBlock(props) {
    const {
        vm,
        financeInfo,
        cancelPacket
    } = props;

    const [isCreatePacketModalShowed, setCreatePacketModalVisible] = React.useState(false);
    return <View style={styles.wrapper}>
        <Text style={styles.title}>Конфигурация и стоимость</Text>
        {financeInfo.calculation && <View>
            {financeInfo.calculation.packet != null && !financeInfo.calculation.packet.is_awaiting
                && blockWithPacket(financeInfo.calculation)}
            {financeInfo.calculation.packet != null
                && financeInfo.calculation.packet.is_awaiting && blockWithAwaitingPacket(vm.state,
                vm.id, financeInfo.calculation.packet, cancelPacket)}
            {financeInfo.calculation.packet == null && <View>
                {blockWithoutPacket(setCreatePacketModalVisible, vm.state,
                    financeInfo.calculation.cost)}
                <VMFinanceBlockWithoutPacket />
            </View>}
            {financeInfo.calculation.packet != null && <VMFinanceBlockWithPacket />}
        </View>}
        <Modal isModalShowed={isCreatePacketModalShowed}
            component={<CreatePacketModal onClose={() => setCreatePacketModalVisible(false)}/>}
            onClose={() => setCreatePacketModalVisible(false)} />
    </View>;
};

FinanceBlock.propTypes = {
    vm: PropTypes.shape({}),
    financeInfo: PropTypes.shape({
        calculation: PropTypes.shape()
    }),
    cancelPacket: PropTypes.func
};

const mapStateToProps = (state) => ({
    vm: state.cvds.currentVM,
    financeInfo: state.cvdsFinanceInfo
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cancelPacket
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 30
    },
    title: {
        marginLeft: 16,
        marginBottom: 14,
        color: '#455562',
        fontSize: 14,
        fontWeight: '600',
        lineHeight: 18
    },
    packetBlock: {
        backgroundColor: '#C3E9F5',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 12,
        paddingLeft: 19,
        paddingBottom: 26,
        paddingRight: 24
    },
    packetTitle: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '600',
        lineHeight: 18,
        marginTop: 6,
        marginBottom: 4
    },
    packetSubtitle: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 16,
        marginTop: 6,
        marginBottom: 14,
        maxWidth: 300
    },
    packetDescriptionBlock: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 16
    },
    packetDescription: {
        color: '#627687',
        fontSize: 11,
        lineHeight: 18
    },
    packetDescriptionPrice: {
        color: '#627687',
        fontSize: 11,
        fontWeight: '600',
        lineHeight: 18,
        marginLeft: 3
    },
    packetIcon: {
        height: 92,
        width: 59
    },
    packetButtonTextStyles: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14,
        minHeight: 14,
        fontWeight: '400'
    },
    packetButtonStyles: {
        paddingTop: 10,
        paddingBottom: 10
    },
    packetTitleBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    packetTitleWarning: {
        marginLeft: 5,
        color: '#e17334',
        fontSize: 13,
        fontWeight: '600',
        lineHeight: 18,
        marginTop: 6,
        marginBottom: 4
    },
    cancelPacketButton: {
        color: '#0f79d5',
        marginTop: 16,
        fontSize: 13,
        fontWeight: '600',
        lineHeight: 15
    },
    packetPaidText: {
        color: '#29ac7a',
        fontWeight: '600',
        fontSize: 12,
        lineHeight: 16
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(FinanceBlock);
