import React, { useEffect } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ResourceCard from './ResourceCard';
import ResourcesTables from './ResourcesTables';
import { formatMemory, normalizeContainerPrice } from '../../utils/general';
import { vmFinanceInfo } from '../../actions/cvds/actions';
import { fetchContainerCalculation } from '../../actions/hosting/actions';
import { getContainerTariffInfo, getVmTariffInfo } from '../../utils/transfer';

function resourceDescription(service, instance) {
    if (service === 'vms') {
        return getVmTariffInfo(instance);
    }
    if (service === 'containers') {
        return getContainerTariffInfo(instance);
    }
    if (service === 'mail_domains') {
        return `Количество ящиков: ${
            instance.mailboxes_count
        }, занято ${formatMemory(instance.used, true)}`;
    }
    if (service === 'zones') {
        return `Количество записей: ${instance.records_count}`;
    }
    if (service === 'domains') {
        const prolongTime = dayjs(
            instance.prolong_time,
            'YYYY-MM-DD HH:mm',
        ).format('DD.MM.YYYY');
        return `Действует до ${prolongTime}`;
    }
    if (service === 'certificates') {
        const notBefore = dayjs(
            instance.not_after,
            'YYYY-MM-DD HH:mm:ss',
        ).format('DD.MM.YYYY');
        return `Действует до ${notBefore}`;
    }
    return '';
}

function TransferInProgress(props) {
    const {
        service,
        instance,
        currentResources
    } = props;
    const dispatch = useDispatch();
    const containerCalculation = useSelector((state) => state.hosting.calculation);
    const vmCalculation = useSelector((state) => state.cvdsFinanceInfo.calculation);

    useEffect(() => {
        if (service === 'vms') {
            dispatch(vmFinanceInfo(instance.id));
        } else if (service === 'containers') {
            dispatch(fetchContainerCalculation(instance.id));
        }
    }, []);
    let uidText = '';
    let price = null;
    if (service === 'vms' && vmCalculation) {
        uidText = `ID: ${instance.uid}`;
        let monthCost = vmCalculation.cost.month.toFixed(2);
        if (vmCalculation.cost_without_packet) {
            monthCost = vmCalculation.cost_without_packet.month.toFixed(2);
        }
        price = `${monthCost} ₽/мес`;
    } else if (service === 'containers' && containerCalculation) {
        uidText = `Логин: ${instance.login}`;
        price = `${normalizeContainerPrice(
            containerCalculation.calculation.fullMonthPrice,
        )} ₽/мес`;
    }
    return (
        <React.Fragment>
            <ResourceCard
                service={service}
                name={instance.name}
                uidText={uidText}
                price={price}
                description={resourceDescription(service, instance)}
            />
            {(currentResources.zones.length !== 0
            || currentResources.certificates.length !== 0
            || currentResources.domains.length !== 0)
            && (service === 'vms' || service === 'containers') && <ResourcesTables
                service={service}
                instance={instance}
                resources={currentResources}
                transferInProgress={true}
                description="дополнительно передаются"
            />}
        </React.Fragment>
    );
}

TransferInProgress.propTypes = {
    service: PropTypes.string,
    instance: PropTypes.shape({
        id: PropTypes.number,
        uid: PropTypes.string,
        name: PropTypes.string,
        login: PropTypes.string,
    }),
    currentResources: PropTypes.shape({
        zones: PropTypes.arrayOf(PropTypes.shape({})),
        domains: PropTypes.arrayOf(PropTypes.shape({})),
        certificates: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    resources: PropTypes.arrayOf(
        PropTypes.shape({
            resource_id: PropTypes.string,
            resource_type: PropTypes.string,
        }),
    ),
};

export default TransferInProgress;
