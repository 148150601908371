import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text } from 'react-native';

import { getTariffInfo, isEnabledFinanceFunctions } from '../utils';
import Modal from '../../General/Modal/Modal';
import Switch from '../../General/Switch/Switch';
import Button from '../../General/Button/Button';
import { updatePacket } from '../../../actions/cvds/actions';
import UpdatePacketModal from '../Modals/UpdatePacketModal';
import { blockedButtonTooltips } from '../../../constants/general';

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: '#ffffff'
    },
    blockTitle: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '600',
        lineHeight: 18,
        paddingTop: 14.5,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 14.5,
    },
    entry: {
        paddingTop: 12.5,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 14.5,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTopWidth: 1,
        borderTopColor: '#e4e4e4'
    },
    entryLabel: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 20,
        fontWeight: '600'
    },
    entryValue: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 20
    },
    totalBlock: {
        paddingTop: 17.5,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 17.5,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTopWidth: 1,
        borderTopColor: '#e4e4e4'
    },
    totalText: {
        color: '#455562',
        fontSize: 13,
        lineHeight: 15,
        fontWeight: '600'
    },
    prolongBlock: {
        paddingTop: 17.5,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 17.5,
        borderTopWidth: 1,
        borderStyle: 'dashed',
        borderTopColor: 'rgba(69, 85, 98, 0.3)'
    },
    prolongTitle: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '600',
        lineHeight: 21,
        marginBottom: 11
    },
    prolongTextBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    prolongText: {
        color: '#455562',
        fontSize: 11,
        maxWidth: 200,
        lineHeight: 14
    },
    prolongBold: {
        marginLeft: 3,
        color: '#455562',
        fontSize: 11,
        lineHeight: 14,
        fontWeight: '600',
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        borderBottomColor: 'rgba(69, 85, 98, 0.7)'
    },
    packetButtonTextStyles: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14,
        minHeight: 14,
        fontWeight: '400'
    },
    packetButtonStyles: {
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 20,
        marginLeft: 20,
        marginRight: 20
    }
});

function getProlongSwitchLabel(cost) {
    return <View>
        <Text style={styles.prolongTitle}>Автопродление годового пакета</Text>
        <View style={styles.prolongTextBlock}>
            <Text style={styles.prolongBold}>
                {cost.packet} ₽/год
            </Text>
            <Text style={styles.prolongText}>, включая ресурсы сверх пакета</Text>
        </View>
    </View>;
}

function FinanceBlockWithPacket(props) {
    const { vm, calculation, updatePacket } = props;
    const { resources, cost, packet } = calculation;
    const [isUpdatePacketModalShowed, setUpdatePacketModalVisible] = React.useState(false);
    const tariffInfo = getTariffInfo(calculation,
        packet.resources.vm !== resources.vm.tariff, true);
    return (cost.month !== 0 && packet.is_active) && <View style={styles.wrapper}>
        <Text style={styles.blockTitle}>
            Заказано сверх пакета
        </Text>
        {tariffInfo.filter(tariffInfo => tariffInfo.isDisplayed)
            .map((item, index) => <View style={styles.entry}
                key={`tariff-info-${index}`}>
                <Text style={styles.entryLabel}>
                    {item.name}
                </Text>
                <Text style={styles.entryValue}>
                    {item.price.toFixed(6)} ₽/мес
                </Text>
            </View>)}
        <View style={styles.totalBlock}>
            <Text style={styles.totalText}>
                Итого
            </Text>
            <Text style={styles.totalText}>
                {cost.month} ₽/мес
            </Text>
        </View>
        <Button title='Обновить годовой пакет' type='white'
            disabled={!isEnabledFinanceFunctions(vm.state)}
            disabledText={blockedButtonTooltips.cvds[vm.state]}
            additionalButtonTextStyles={[styles.packetButtonTextStyles]}
            additionalButtonStyles={[styles.packetButtonStyles]}
            onPress={() => setUpdatePacketModalVisible(true)}/>
        <Modal isModalShowed={isUpdatePacketModalShowed}
            component={<UpdatePacketModal onClose={() => setUpdatePacketModalVisible(false)}/>}
            onClose={() => setUpdatePacketModalVisible(false)} />
        <View style={styles.prolongBlock}>
            <Switch
                label={getProlongSwitchLabel(cost)}
                disabled={!isEnabledFinanceFunctions(vm.state)}
                disabledText={blockedButtonTooltips.cvds[vm.state]}
                isEnable={packet.prolong}
                onChange={() => updatePacket(vm.id, { prolong: !packet.prolong })}/>
        </View>
    </View>;
}

FinanceBlockWithPacket.propTypes = {
    vm: PropTypes.shape({}),
    updatePacket: PropTypes.func,
    calculation: PropTypes.shape({
        resources: PropTypes.shape({
            backup: PropTypes.shape({
                count: PropTypes.number,
                cost: PropTypes.number
            }),
            ips: PropTypes.shape({
                count: PropTypes.number,
                cost: PropTypes.number
            }),
            storage: PropTypes.shape({
                fast: PropTypes.shape({
                    count: PropTypes.number,
                    cost: PropTypes.number
                }),
                normal: PropTypes.shape({
                    count: PropTypes.number,
                    cost: PropTypes.number
                }),
                slow: PropTypes.shape({
                    count: PropTypes.number,
                    cost: PropTypes.number
                })
            }),
            vm: PropTypes.shape({
                tariff: PropTypes.string,
                cost: PropTypes.number
            })
        }),
        cost: PropTypes.shape({
            month: PropTypes.number,
            packet: PropTypes.number
        }),
        packet: PropTypes.shape({
            prolong: PropTypes.bool,
            resources: PropTypes.shape({
                vm: PropTypes.string
            })
        })
    })
};

const mapStateToProps = state => ({
    vm: state.cvds.currentVM,
    calculation: state.cvdsFinanceInfo.calculation
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updatePacket
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinanceBlockWithPacket);
