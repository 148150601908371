export const CERTIFICATE_CREATE = 'CERTIFICATE_CREATE';
export const CERTIFICATE_DETAIL = 'CERTIFICATE_DETAIL';
export const INIT_CERTIFICATE = 'INIT_CERTIFICATE';
export const CERTIFICATE_DETAIL_IN_PROGRESS = 'CERTIFICATE_DETAIL_IN_PROGRESS';
export const CERTIFICATE_DETAIL_WITH_ERRORS = 'CERTIFICATE_DETAIL_WITH_ERRORS';

export const FIND_CERTIFICATES_SUCCESSFULLY = 'FIND_CERTIFICATES_SUCCESSFULLY';
export const FIND_CERTIFICATES_IN_PROGRESS = 'FIND_CERTIFICATES_IN_PROGRESS';
export const FIND_CERTIFICATES_WITH_ERRORS = 'FIND_CERTIFICATES_WITH_ERRORS';

export const INIT_FIND_CERTIFICATES = 'INIT_FIND_CERTIFICATES';

export const FETCH_CERTIFICATES_IN_PROGRESS = 'FETCH_CERTIFICATES_IN_PROGRESS';
export const FETCH_CERTIFICATES_SUCCESSFULLY = 'FETCH_CERTIFICATES_SUCCESSFULLY';
export const FETCH_CERTIFICATES_WITH_ERRORS = 'FETCH_CERTIFICATES_WITH_ERRORS';
export const UPDATE_CERTIFICATE_SUCCESSFULLY = 'UPDATE_CERTIFICATE_SUCCESSFULLY';
export const UPDATE_CERTIFICATE_IN_PROGRESS = 'UPDATE_CERTIFICATE_IN_PROGRESS';
export const UPDATE_CERTIFICATE_WITH_ERRORS = 'UPDATE_CERTIFICATE_WITH_ERRORS';
export const DELETE_CERTIFICATE_SUCCESSFULLY = 'DELETE_CERTIFICATE_SUCCESSFULLY';

export const ORDER_CERTIFICATE_IN_PROGRESS = 'ORDER_CERTIFICATE_IN_PROGRESS';
export const ORDER_CERTIFICATE_SUCCESSFULLY = 'ORDER_CERTIFICATE_SUCCESSFULLY';
export const ORDER_CERTIFICATE_WITH_ERRORS = 'ORDER_CERTIFICATE_WITH_ERRORS';

export const FETCH_MORE_CERTIFICATES_IN_PROGRESS = 'FETCH_MORE_CERTIFICATES_IN_PROGRESS';
export const FETCH_MORE_CERTIFICATES_SUCCESSFULLY = 'FETCH_MORE_CERTIFICATES_SUCCESSFULLY';
export const FETCH_MORE_CERTIFICATES_WITH_ERRORS = 'FETCH_MORE_CERTIFICATES_WITH_ERRORS';
