import {
    RESTORE_FROM_BACKUP_TO_INIT,
    RESTORE_FROM_BACKUP_UPDATE_PARAMS,
} from '../../actions/cvds/actionTypes';

export default () => {
    const defaultState = {
        currentVM: null,
        backupDate: null,
        vmForBackup: {},
        resourceName: null,
        resourceType: null,
        backupMethod: null,
        currentVolume: null,
        backupMethods: null,
        filesForArchive: [],
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case RESTORE_FROM_BACKUP_UPDATE_PARAMS: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESTORE_FROM_BACKUP_TO_INIT: {
            return defaultState;
        }
        default:
            return state;
        }
    };
};
