import {
    USER_LOGOUT,
    SET_AUTH_DATA,
    USER_FINANCE_INFO_SUCCESSFULLY,
    USER_FINANCE_INFO_WITH_ERRORS,
    USER_FINANCE_INFO_IN_PROGRESS,
    USER_ACTIVE_SERVICES_FETCHED_IN_PROGRESS,
    USER_ACTIVE_SERVICES_FETCHED_SUCCESSFULLY,
    USER_ACTIVE_SERVICES_FETCHED_WITH_ERRORS,
    USER_AUTHORIZE_SUCCESSFULLY,
    PASSWORD_RESTORE_IN_PROGRESS,
    PASSWORD_RESTORE_SUCCESSFULLY,
    PASSWORD_RESTORE_WITH_ERRORS,
    USER_AUTHORIZE_IN_PROGRESS,
    USER_AUTHORIZE_WITH_ERRORS,
    PASSWORD_RESET_IN_PROGRESS,
    PASSWORD_RESET_SUCCESSFULLY,
    PASSWORD_RESET_WITH_ERRORS,
} from "../actions/user/actionTypes";
import {
    DEFERRED_PAYMENT_SET_SUCCESSFULLY,
    DEFERRED_PAYMENT_SET_IN_PROGRESS,
    DEFERRED_PAYMENT_SET_WITH_ERRORS,
    WS_USER_BALANCE_UPDATE
} from '../actions/finances/actionTypes';
import { PARTNERSHIP_CREATE_SUCCESSFULLY } from '../actions/partnership/actionTypes';
import { from } from "rxjs";

export default () => (state = {
    auth: {
        inProgress: false,
        isAuthorized: false,
        isError: false,
    },
    user: null,
    scopeId: null,
    financeInfo: null,
    activeServicesList: null,
    passwordRestoreTransport: null,
    deferredPaymentSetInProgress: false,
    activeServicesListInProgress: false,
    authorizeInProgress: false,
    passwordRestoreInProgress: false,
    passwordResetInProgress: false,
}, action) => {
    switch (action.type) {
    case DEFERRED_PAYMENT_SET_IN_PROGRESS: {
        return {
            ...state,
            deferredPaymentSetInProgress: true
        };
    }
    case DEFERRED_PAYMENT_SET_WITH_ERRORS: {
        return {
            ...state,
            deferredPaymentSetInProgress: false
        };
    }
    case DEFERRED_PAYMENT_SET_SUCCESSFULLY: {
        return {
            ...state,
            user: {
                ...state.user,
                financeInfo: {
                    ...state.user.financeInfo,
                    credit_info: action.data
                }
            },
            deferredPaymentSetInProgress: false
        };
    }
    case USER_FINANCE_INFO_IN_PROGRESS: {
        return {
            ...state,
            auth: {
                inProgress: true,
                isAuthorized: false,
                isError: false,
            },
            financeInfo: null,
        }
    }
    case USER_FINANCE_INFO_WITH_ERRORS: {
        return {
            ...state,
            auth: {
                inProgress: false,
                isAuthorized: false,
                isError: true,
            },
            financeInfo: null
        }
    }
    case USER_FINANCE_INFO_SUCCESSFULLY: {
        return {
            ...state,
            auth: {
                inProgress: false,
                isAuthorized: true,
                isError: false,
            },
            financeInfo: action.data,
            user: action.data.user
        }
    }
    case USER_LOGOUT: {
        return {
            ...state,
            auth: {
                inProgress: false,
                isAuthorized: false
            },
            financeInfo: action.data,
        };
    }
    case SET_AUTH_DATA: {
        return {
            ...state,
            auth: {
                ...action.data
            }
        };
    }
    case USER_AUTHORIZE_IN_PROGRESS: {
        return {
            ...state,
            authorizeInProgress: true
        };
    }
    case USER_AUTHORIZE_WITH_ERRORS: {
        return {
            ...state,
            authorizeInProgress: false,
        };
    }
    case USER_AUTHORIZE_SUCCESSFULLY: {
        return {
            ...state,
            scopeId: action.data.scope_id,
            authorizeInProgress: false,
        };
    }
    case USER_ACTIVE_SERVICES_FETCHED_SUCCESSFULLY: {
        return {
            ...state,
            activeServicesList: action.data,
            activeServicesListInProgress: false
        };
    }
    case USER_ACTIVE_SERVICES_FETCHED_IN_PROGRESS: {
        return {
            ...state,
            activeServicesListInProgress: true
        };
    }
    case USER_ACTIVE_SERVICES_FETCHED_WITH_ERRORS: {
        return {
            ...state,
            activeServicesListInProgress: false
        };
    }
    case WS_USER_BALANCE_UPDATE: {
        return {
            ...state,
            financeInfo: {
                ...state.financeInfo,
                balance: {
                    ...state.financeInfo.balance,
                    balance: action.data.balance,
                }
            }
        };
    }
    case PARTNERSHIP_CREATE_SUCCESSFULLY: {
        return {
            ...state,
            financeInfo: {
                ...state.financeInfo,
                is_partner: true,
                partner_v2: true,
            }
        };
    }
    case PASSWORD_RESTORE_IN_PROGRESS: {
        return {
            ...state,
            passwordRestoreInProgress: true
        };
    }
    case PASSWORD_RESTORE_WITH_ERRORS: {
        return {
            ...state,
            passwordRestoreInProgress: false
        };
    }
    case PASSWORD_RESTORE_SUCCESSFULLY: {
        return {
            ...state,
            passwordRestoreTransport: action.data.transport,
            passwordRestoreInProgress: false
        };
    }
    case PASSWORD_RESET_IN_PROGRESS: {
        return {
            ...state,
            passwordResetInProgress: true
        };
    }
    case PASSWORD_RESET_WITH_ERRORS: {
        return {
            ...state,
            passwordResetInProgress: false
        };
    }
    case PASSWORD_RESET_SUCCESSFULLY: {
        return {
            ...state,
            passwordResetInProgress: false
        };
    }
    default:
        return state;
    }
};
