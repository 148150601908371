import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ScrollView } from 'react-native';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, TextInput, TouchableOpacity } from 'react-native';

import {
    virtualhostCreateCms,
    virtualhostCreateEngine
} from '../../actions/hosting/actions';
import Button from '../General/Button/Button';
import CmsListBlock from './VirtualhostCreate/CMSBlock';
import { flushErrors } from '../../actions/forms/actions';
import ContainerSelect from './VirtualhostCreate/ContainerSelect';
import ConfigurationBlock from './VirtualhostCreate/ConfigurationBlock';

const formId = 'virtualhostCreate';

function HostingCreateVirtualhost(props) {
    const {
        errors,
        inProgress,
        container,
        containers,
        navigation,
        flushErrors,
        capabilities,
        virtualhostCreateCms,
        virtualhostCreateEngine,
    } = props;
    const [name, setModalVirtualhostName] = React.useState('');

    React.useEffect(() => {
        flushErrors();
    }, [name]);

    const [testDomain, setModalTestDomain] = React.useState(false);
    const [isChoiceConfiguration, setModalChoiceConfiguration] = React.useState(true);
    const [engine, setModalChangeEngine] = React.useState('php');
    const [db, setModalChangeDB] = React.useState('mysql');
    const [cms, setModalChangeCMS] = React.useState('bitrix_standart');
    const [containerChange, setModalChangeContainer] = React.useState({});
    React.useEffect(() => {
        if (container) {
            setModalChangeContainer({
                label: container.name,
                value: container.id,
            });
        }
    }, [container]);
    const formErrors = errors[formId] || {};
    return <ScrollView style={styles.scroll}>
        {containers && container && capabilities && <View style={styles.wrapper}>
            <Text style={styles.label}>Имя сайта</Text>
            <View style={styles.domain}>
                <TextInput
                    style={styles.input}
                    autoFocus={true}
                    numberOfLines={1}
                    value={name}
                    underlineColorAndroid='#222'
                    placeholder={!testDomain ? 'mydomain.com' : 'mydomain'}
                    onChange={(e) => setModalVirtualhostName(e.target.value)}
                />
                {testDomain && <Text style={styles.testDomain}>.na4u.ru</Text>}
            </View>
            <TouchableOpacity onPress={() => setModalTestDomain(!testDomain)}>
                <Text style={styles.testDomainHint}>
                    {!testDomain ? 'Использовать тестовый домен' : 'Подключить свой домен'}
                </Text>
            </TouchableOpacity>
            {formErrors && formErrors.name && <Text style={styles.error}>{formErrors.name}</Text>}
            {formErrors && formErrors.aliases && <Text style={styles.error}>{formErrors.aliases}</Text>}
            <ContainerSelect containers={containers}
                currentContainer={containerChange}
                onChange={setModalChangeContainer}/>
            <View style={styles.tabs}>
                <TouchableOpacity
                    onPress={() => setModalChoiceConfiguration(true)}>
                    <Text style={isChoiceConfiguration ? styles.currentTab : styles.itemTab}>
                        Указать конфигурацию
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => setModalChoiceConfiguration(false)}>
                    <Text style={!isChoiceConfiguration ? styles.currentTab : styles.itemTab}>
                        Установить CMS
                    </Text>
                </TouchableOpacity>
            </View>
            {isChoiceConfiguration && <ConfigurationBlock
                db={db} changeDB={setModalChangeDB}
                capabilities={capabilities}
                engine={engine}
                changeEngine={setModalChangeEngine}
                containerId={containerChange.value || container.id}
                containers={containers}
            />}
            {!isChoiceConfiguration && <CmsListBlock
                cms={cms} capabilities={capabilities} changeCMS={setModalChangeCMS}/>}
            {formErrors && formErrors.__all__
                && <Text style={styles.error}>{formErrors.__all__}</Text>}
            <Button title='Создать сайт'
                inProgress={inProgress}
                onPress={() => {
                    let fullName = name;
                    if (testDomain) {
                        fullName = `${name}.na4u.ru`;
                    }
                    if (isChoiceConfiguration) {
                        virtualhostCreateEngine(formId, { container: containerChange.value,  name: fullName, engine, db },
                            container.id, (data) => navigation.navigate('HostingContainerVirtualhost', { virtualhostId: data.id, virtualhostName: data.name }));
                    } else {
                        virtualhostCreateCms(formId, { container: containerChange.value,  name: fullName, cms },
                            container.id, (data) => navigation.navigate('HostingContainerVirtualhost', { virtualhostId: data.id, virtualhostName: data.name }));
                    }
                }}
                additionalWrapperStyles={[{ width: '100%', marginBottom: 14, marginTop: 30, }]}
                additionalButtonStyles={[{ paddingTop: 15, paddingBottom: 15 }]}
                additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15 }]}/>
        </View>}
    </ScrollView>;
}

HostingCreateVirtualhost.propTypes = {
    errors: PropTypes.shape({}),
    container: PropTypes.shape({}),
    containers: PropTypes.shape({}),
    capabilities: PropTypes.shape({}),
    flushErrors: PropTypes.func,
    virtualhostCreateCms: PropTypes.func,
    virtualhostCreateEngine: PropTypes.func,
    inProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    inProgress: state.hosting.createVirtualhostInProgress,
    container: state.hosting.container,
    containers: state.hosting.containers,
    capabilities: state.hosting.capabilities,
    errors: state.forms.errors || {},
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    flushErrors,
    virtualhostCreateCms,
    virtualhostCreateEngine
}, dispatch);

const styles = StyleSheet.create({
    scroll: {
        backgroundColor: '#ffffff',
    },
    wrapper: {
        display: 'flex',
        paddingTop: 20,
        paddingLeft: 12,
        paddingRight: 12,
    },
    label: {
        fontSize: 11,
        lineHeight: 14,
        marginBottom: 6,
        color: '#455562',
    },
    error: {
        color: '#d06565',
        marginTop: 4,
        fontSize: 11,
    },
    domain: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'baseline',
        borderBottomColor: '#dce2e9',
        borderBottomWidth: 1,
        borderBottomRadius: 5,
        marginBottom: 4,
    },
    input: {
        flex: 1,
        fontSize: 12,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
    },
    inputFocus: {
        flex: 1,
        fontSize: 12,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
    },
    blueText: {
        color: '#0f79d5',
    },
    grayText: {
        color: '#455562',
    },
    testDomainHint: {
        color: '#0F79D5',
        fontSize: 11,
        lineHeight: 14,
        width: 'max-content',
        borderBottomColor: '#0F79D5',
        borderBottomWidth: 1,
        borderBottomStyle: 'dashed',
    },
    testDomain: {
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 10,
        paddingTop: 6,
        color: '#455562',
    },
    tabs: {
        flex: 1,
        maxHeight: 28,
        flexDirection: 'row',
        alignItems: 'baseline',
        borderBottomColor: '#dce2e9',
        borderBottomWidth: 2,
    },
    currentTab: {
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 14,
        color: '#0F79D5',
        paddingBottom: 12,
        marginRight: 25,
        borderBottomWidth: 2,
        borderBottomColor: '#0F79D5',
    },
    itemTab: {
        opacity: 0.7,
        fontSize: 12,
        marginRight: 25,
        fontWeight: '500',
        lineHeight: 14,
        color: '#455562',
        paddingBottom: 12,
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(HostingCreateVirtualhost);
