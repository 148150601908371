import React from 'react';
import { View, Platform } from 'react-native';

import MobileCurrentMonthModal from './MobileCurrentMonthModal';
import WebCurrentMonthModal from './WebCurrentMonthModal';


function CurrentMonthModal(props) {
    return <View>
        {Platform.select({
            ios: <MobileCurrentMonthModal {...props} />,
            android: <MobileCurrentMonthModal {...props} />,
            web: <WebCurrentMonthModal {...props} />,
            default: <WebCurrentMonthModal {...props} />,
        })}
    </View>;
}

export default CurrentMonthModal;
