import {
    FIND_CERTIFICATES_SUCCESSFULLY,
    FIND_CERTIFICATES_IN_PROGRESS,
    FIND_CERTIFICATES_WITH_ERRORS,
    INIT_FIND_CERTIFICATES,
    FETCH_CERTIFICATES_IN_PROGRESS,
    FETCH_CERTIFICATES_SUCCESSFULLY,
    FETCH_CERTIFICATES_WITH_ERRORS,
    UPDATE_CERTIFICATE_SUCCESSFULLY,
    UPDATE_CERTIFICATE_IN_PROGRESS,
    UPDATE_CERTIFICATE_WITH_ERRORS,
    DELETE_CERTIFICATE_SUCCESSFULLY,
    ORDER_CERTIFICATE_SUCCESSFULLY,
    ORDER_CERTIFICATE_IN_PROGRESS,
    ORDER_CERTIFICATE_WITH_ERRORS,
    CERTIFICATE_DETAIL,
    CERTIFICATE_DETAIL_IN_PROGRESS,
    CERTIFICATE_DETAIL_WITH_ERRORS,
    INIT_CERTIFICATE,
} from '../../actions/certificates/actionTypes';

export default () => {
    const defaultState = {
        foundCertificates: null,
        foundCertificatesInProgress: false,
        foundCertificatesWithError: false,
        certificate: null,
        certificateInProgress: false,
        certificates: null,
        certificatesError: false,
        certificatesInProgress: false,
        updateCertificateInProgress: false,
        orderCertificateInProgress: false,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case FIND_CERTIFICATES_SUCCESSFULLY: {
            return {
                ...state,
                foundCertificates: action.data,
                foundCertificatesError: false,
                foundCertificatesInProgress: false,
            };
        }
        case FIND_CERTIFICATES_IN_PROGRESS: {
            return {
                ...state,
                foundCertificates: null,
                foundCertificatesError: false,
                foundCertificatesInProgress: true,
            };
        }
        case FIND_CERTIFICATES_WITH_ERRORS: {
            return {
                ...state,
                foundCertificatesError: true,
                foundCertificatesInProgress: false,
            };
        }
        case INIT_FIND_CERTIFICATES: {
            return {
                ...state,
                foundCertificates: null,
                foundCertificatesError: false,
                foundCertificatesInProgress: false,
            };
        }
        case FETCH_CERTIFICATES_SUCCESSFULLY: {
            return {
                ...state,
                certificates: action.data,
                certificatesError: false,
                certificatesInProgress: false,
            };
        }
        case FETCH_CERTIFICATES_IN_PROGRESS: {
            return {
                ...state,
                certificatesError: false,
                certificatesInProgress: true,
            };
        }
        case FETCH_CERTIFICATES_WITH_ERRORS: {
            return {
                ...state,
                certificatesError: true,
                certificatesInProgress: false,
            };
        }
        case UPDATE_CERTIFICATE_IN_PROGRESS: {
            return {
                ...state,
                updateCertificateInProgress: true,
            };
        }
        case UPDATE_CERTIFICATE_WITH_ERRORS: {
            return {
                ...state,
                updateCertificateInProgress: false,
            };
        }
        case UPDATE_CERTIFICATE_SUCCESSFULLY: {
            const { id } = action.data;
            if (state.certificates == null) {
                return {
                    ...state,
                    updateCertificateInProgress: false,
                    certificate: action.data,
                };
            }
            const newCertificatesEntities = state.certificates.entities;
            const changedCertificateIndex =
                newCertificatesEntities.findIndex(
                    (certificate) => certificate.id === id,
                );
            if (changedCertificateIndex === -1) {
                return state;
            }
            newCertificatesEntities[changedCertificateIndex] = action.data;
            return {
                ...state,
                certificate: action.data,
                updateCertificateInProgress: false,
                certificates: {
                    count: state.certificates.count,
                    entities: newCertificatesEntities,
                },
            };
        }
        case DELETE_CERTIFICATE_SUCCESSFULLY: {
            let newCertificates = { ...state.certificates };
            if (state.certificates == null) {
                return {
                    ...state,
                    updateCertificateInProgress: false,
                };
            }
            if (
                newCertificates.entities.find(
                    (el) => el.id === action.data.id,
                )
            ) {
                newCertificates = {
                    count: state.certificates.count - 1,
                    entities: state.certificates.entities.filter(
                        (item) => item.id !== action.data.id,
                    ),
                };
            }
            return {
                ...state,
                updateCertificateInProgress: false,
                certificates: newCertificates,
            };
        }
        case ORDER_CERTIFICATE_SUCCESSFULLY: {
            if (
                state.certificates.entities.find(
                    (c) => c.id === action.data.id,
                )
            ) {
                return state;
            }
            return {
                ...state,
                orderCertificateInProgress: false,
                certificate: action.data,
                certificates: {
                    count: state.certificates.count + 1,
                    entities: [...state.certificates.entities, action.data],
                },
            };
        }
        case ORDER_CERTIFICATE_IN_PROGRESS: {
            return {
                ...state,
                orderCertificateInProgress: true,
            };
        }
        case ORDER_CERTIFICATE_WITH_ERRORS: {
            return {
                ...state,
                orderCertificateInProgress: false,
            };
        }
        case CERTIFICATE_DETAIL: {
            return {
                ...state,
                certificate: action.data,
                certificateInProgress: false,
            };
        }
        case CERTIFICATE_DETAIL_IN_PROGRESS: {
            return {
                ...state,
                certificateInProgress: true,
            };
        }
        case CERTIFICATE_DETAIL_WITH_ERRORS: {
            return {
                ...state,
                certificateInProgress: false,
            };
        }
        case INIT_CERTIFICATE: {
            return {
                ...state,
                certificate: null,
            };
        }
        default:
            return state;
        }
    };
};
