import { merge } from '@netangels/core/utils/functions';

import { dynamicComponentGenerator } from './dynamic-component-generator';

const applyFieldMetadataMapper = (data, fieldMapperData) => {
    if (fieldMapperData != null) {
        const copiedData = { ...data };
        const fieldMapperKeys = Object.keys(fieldMapperData);
        for (const key of fieldMapperKeys) {
            const dataKey = fieldMapperData[key];
            copiedData.metadata[dataKey] = data.metadata[key];
        }
        return copiedData;
    }
    return data;
};

export const makeMonitoringEventComponent = (
    eventsData,
    service,
    eventType,
    viewType,
    data,
    useDefault = false,
) => {
    if (!data.metadata.resource_id && viewType === 'solution') {
        return null;
    }
    const eventData = eventsData.find((ev) => ev.service === service);
    if (eventData == null) {
        return null;
    }
    const eventViewData = eventData.events[eventType];
    let componentData = null;
    if (eventViewData != null) {
        componentData = eventViewData[viewType];
    } else if (useDefault) {
        componentData = eventData.defaults[viewType];
    }
    if (componentData != null) {
        const { component, metadata } = componentData;
        const fieldMapperData = eventViewData != null ? eventViewData.fieldMapperData : {};
        const componentProps = merge(
            {},
            applyFieldMetadataMapper(data, fieldMapperData),
            metadata,
        );
        return dynamicComponentGenerator.generate(component, componentProps);
    }
    return null;
};

export const makeMonitoringResourceLink = (
    service,
    { resource_id, resource_name },
) => {
    switch (service) {
    case 'cloud_vds': {
        if (resource_id) {
            return {
                link: 'CVDSVM',
                params: { vmId: resource_id }
            };
        }
        return {
            link: 'CVDS'
        };
    }
    case 'cloud_hosting': {
        if (resource_id) {
            return {
                link: 'HostingContainer',
                params: { containerId: resource_id }
            };
        }
        return {
            link: 'Hosting'
        };
    }
    case 'cloud_mail': {
        if (resource_id) {
            return {
                link: 'MailDomain',
                params: { domainId: resource_id }
            };
        }
        return {
            link: 'Mail'
        };
    }
    default: {
        return `/${service}/`;
    }
    }
};

export const makeMonitoringSolutionLink = (
    link, metadata,
) => {
    if (link.includes('cloud')) {
        if (link.includes('vm_id')) {
            return {
                link: 'CVDSVM',
                params: { vmId: metadata.resource_id }
            };
        }
        return {
            link: 'CVDS'
        };
    }
    if (link.includes('hosting')) {
        if (link.includes('container_id')) {
            return {
                link: 'HostingContainer',
                params: { containerId: metadata.resource_id }
            };
        }
        return {
            link: 'Hosting'
        };
    }
    if (link.includes('mail')) {
        if (link.includes('domain_id')) {
            return {
                link: 'MailDomain',
                params: { domainId: metadata.resource_id }
            };
        }
        return {
            link: 'Mail'
        };
    }
    // if (link.includes('requests')) {
    // }
};

export const eventsMapByService = (events, service) => {
    let filteredEvents = events;
    if (service) {
        filteredEvents = events.filter((e) => e.service === service);
    }
    const warnings = filteredEvents.filter((e) => e.severity === 'Warning');
    const criticals = filteredEvents.filter((e) => e.severity === 'Critical');
    return { warnings, criticals };
};
