import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    StyleSheet,
    Text,
    Image,
    TouchableOpacity,
    Linking,
    ScrollView,
} from 'react-native';

import Modal from '../General/Modal/Modal';
import { formatMemory, canNotStartSearch } from '../../utils/general';
import { forwardIcon, rightArrowLightIcon, nothingFoundIcon } from '../../icons';
import { mailboxStatesMap } from './constants';
import CreateMailboxModal from './Modals/CreateMailboxModal';
import {
    fetchMoreMailboxes,
    searchMailboxes,
    initSearchMailboxes,
    changeSearchMailboxesQuery,
} from '../../actions/mail/actions';
import MailResourceLoader from './MailResourceLoader';
import ChangeDomainQuotaModal from './Modals/ChangeDomainQuotaModal';
import MultiStateBlock from '../General/MultiStateBlock/MultiStateBlock';
import Circle from '../General/Circle/Circle';
import Spring from '../General/Spring/Spring';
import SearchInput from '../General/Form/SearchInput';
import MailWebsocket from './MailWebsocket';

function MailboxResource({ mailbox, navigation }) {
    const {
        id,
        name,
        state,
        aliases,
        domain_id,
    } = mailbox;
    return <TouchableOpacity style={styles.wrapper}
        onPress={() => {
            navigation.navigate('MailboxSettings', {
                mailboxId: id,
                domainId: domain_id,
                mailboxName: name
            });
        }}>
        <View style={styles.mailbox}>
            <MultiStateBlock state={mailboxStatesMap[state] || 'stopped'}
                additionalTitleStyles={styles.mailboxName}
                title={name} />
        </View>
        {aliases && <View style={styles.mailboxInfo}>
            <Image source={forwardIcon} style={styles.forwardIcon} />
            <Circle count={aliases.length} />
        </View>}
        <Image source={rightArrowLightIcon} style={styles.icon} />
    </TouchableOpacity>;
}

MailboxResource.propTypes = {
    mailbox: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        aliases: PropTypes.arrayOf(PropTypes.string),
        state: PropTypes.string,
    }),
};

function isCloseToBottom({ layoutMeasurement, contentOffset, contentSize }) {
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - 114;
}

function MailboxesList({ mailboxes, navigation }) {
    return mailboxes.map((mailbox, i) => <MailboxResource
        mailbox={mailbox}
        navigation={navigation}
        key={`mailbox-list-${i}`}
    />);
}

MailboxesList.propTypes = {
    mailboxes: PropTypes.arrayOf({
        id: PropTypes.number,
        name: PropTypes.string,
        aliases: PropTypes.arrayOf(PropTypes.string),
        state: PropTypes.string,
    }),
};

function MailboxPage() {
    const limit = 10;
    const dispatch = useDispatch();
    const {
        domain,
        mailboxes,
        userInfo,
        moreMailboxesInProgress,
        searchMailboxesQuery,
        searchMailboxesInProgress,
        searchMailboxesResult,
    } = useSelector((state) => state.mail);
    const navigation = useNavigation();
    const [isCreateModalShowed, setCreateModalShowed] = React.useState(false);
    const [isChangeQuotaModalShowed, setChangeQuotaModalShowed] = React.useState(false);
    const [isStartingPagination, setIsStartingPagination] = React.useState(false);

    let domainLimit = 0;
    if (domain != null) {
        domainLimit = domain.quota === 0 ? domain.max_quota : domain.quota;
    }
    let currentsMailboxes = mailboxes;
    if (searchMailboxesResult && !canNotStartSearch(searchMailboxesQuery)) {
        currentsMailboxes = searchMailboxesResult;
    }

    useEffect(() => {
        return () => dispatch(initSearchMailboxes());
    }, []);
    return <ScrollView
        style={{ flex: 1 }}
        scrollEventThrottle={0}
        onScroll={(e) => {
            if (
                isCloseToBottom(e.nativeEvent)
                && isStartingPagination
                && !searchMailboxesResult
                && !moreMailboxesInProgress
                && mailboxes.count !== mailboxes.entities.length
            ) {
                dispatch(
                    fetchMoreMailboxes(domain.id, limit, mailboxes.entities.length)
                );
            }
        }}>
        <View>
            {domain != null && !domain.validated && <View style={styles.validatedBlock}>
                <Text style={styles.validatedText}>Не подтвержден</Text>
                <TouchableOpacity
                    onPress={() => Linking.openURL('https://www.netangels.ru/support/e-mail/mail/')}
                >
                    <Text style={styles.validatedLink}>Как подтвердить домен?</Text>
                </TouchableOpacity>
            </View>}
            {userInfo && userInfo.paid_quota_enabled && <View style={styles.quotaBlock}>
                <Text style={styles.quotaText}>Лимит на домен</Text>
                <TouchableOpacity onPress={() => setChangeQuotaModalShowed(true)}>
                    <Text style={styles.blueText}>{formatMemory(domainLimit, true)}</Text>
                </TouchableOpacity>
            </View>}
            <View style={styles.searchInputBlock}>
                <SearchInput
                    onSearch={(request) => dispatch(searchMailboxes(domain.id,
                        { filter: `name % "${request}"` },))}
                    searchRequest={searchMailboxesQuery}
                    changeSearchRequest={(query, limit) => dispatch(
                        changeSearchMailboxesQuery(query, limit)
                    )}
                />
            </View>
            <View style={styles.headerBlock}>
                <Text style={styles.headerText}>
                    почтовые ящики
                </Text>
                {currentsMailboxes && <Circle count={currentsMailboxes.count} />}
                <Spring />
                <TouchableOpacity onPress={() => setCreateModalShowed(true)}>
                    <Text style={styles.blueText}>Добавить</Text>
                </TouchableOpacity>
            </View>
        </View>
        {!canNotStartSearch(searchMailboxesQuery)
            && searchMailboxesResult && searchMailboxesResult.count === 0
            && <View>
                <Image source={nothingFoundIcon} style={styles.nothingFound} />
                <Text style={styles.nothingFoundText}>
                    Ничего не найдено
                </Text>
            </View>
        }
        {!searchMailboxesInProgress && currentsMailboxes && <MailboxesList
            mailboxes={currentsMailboxes.entities} navigation={navigation} />}
        {(moreMailboxesInProgress || searchMailboxesInProgress) && <MailResourceLoader />}
        {!isStartingPagination && domain
            && !searchMailboxesResult
            && mailboxes && mailboxes.count !== mailboxes.entities.length
            && <View style={styles.whiteButtonBlock}>
                <TouchableOpacity style={styles.whiteButton}
                    onPress={() => {
                        setIsStartingPagination(true);
                        dispatch(
                            fetchMoreMailboxes(domain.id, limit, mailboxes.entities.length)
                        );
                    }}>
                    <Text style={styles.whiteButtonText}>Показать еще {
                        mailboxes.count - mailboxes.entities.length > 10
                            ? 10
                            : mailboxes.count - mailboxes.entities.length
                    }</Text>
                </TouchableOpacity>
            </View>}
        <Modal isModalShowed={isCreateModalShowed}
            component={<CreateMailboxModal
                domainId={domain && domain.id}
                onClose={() => {
                    setCreateModalShowed(false);
                    dispatch(initSearchMailboxes());
                }}/>}
            onClose={() => setCreateModalShowed(false)} />
        <Modal isModalShowed={isChangeQuotaModalShowed}
            component={<ChangeDomainQuotaModal
                onClose={() => setChangeQuotaModalShowed(false)}/>}
            onClose={() => setChangeQuotaModalShowed(false)} />
        <MailWebsocket />
    </ScrollView>;
}

const styles = StyleSheet.create({
    wrapper: {
        margin: 0,
        paddingTop: 18,
        paddingBottom: 19,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        width: '100%',
        color: '#455562',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
        borderBottomColor: '#F6F6F6',
        borderBottomWidth: 2,
    },
    validatedBlock: {
        flex: 1,
        backgroundColor: '#e28a58',
        paddingTop: 15,
        paddingBottom: 16,
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    validatedText: {
        fontSize: 13,
        fontWeight: '500',
        color: '#ffffff',
    },
    validatedLink: {
        fontSize: 12,
        color: '#ffffff',
        fontWeight: '400',
        textDecorationLine: 'underline',
    },
    quotaBlock: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        paddingTop: 20,
        paddingBottom: 21,
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    quotaText: {
        fontSize: 13,
        fontWeight: '500',
        color: '#455562',
    },
    headerBlock: {
        paddingBottom: 16,
        paddingTop: 16,
        paddingLeft: 20,
        paddingRight: 21,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        color: '#455562',
        marginRight: 8,
    },
    blueText: {
        fontSize: 12,
        fontWeight: '500',
        color: '#0f79d5',
    },
    mailbox: {
        flex: 1,
        flexDirection: 'row',
    },
    mailboxName: {
        fontSize: 13,
        lineHeight: 15,
        marginLeft: 8,
        fontWeight: '500',
        color: '#455562',
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    icon: {
        width: 7,
        height: 13,
    },
    nothingFound: {
        width: 40,
        height: 40,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 25,
    },
    nothingFoundText: {
        marginTop: 18,
        textAlign: 'center',
        color: '#455562',
        opacity: 0.5,
        fontSize: 13,
        lineHeight: 15,
    },
    mailboxInfo: {
        flex: 1,
        flexDirection: 'row',
        maxWidth: 30,
        marginRight: 20,
    },
    forwardIcon: {
        width: 16,
        height: 16,
        marginRight: 2,
    },
    header: {
        flex: 1,
        color: '#455562',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        marginBottom: 4,
    },
    whiteButtonBlock: {
        paddingTop: 15,
        paddingRight: 20,
        paddingLeft: 20,
        marginBottom: 20,
    },
    whiteButton: {
        borderRadius: 4,
        borderWidth: 1,
        borderColor: 'rgba(69, 85, 98, 0.50)',
        backgroundColor: '#ffffff',
        borderStyle: 'solid',
        paddingTop: 12,
        paddingBottom: 13,
        width: '100%',
    },
    whiteButtonText: {
        color: '#455562',
        fontSize: 13,
        fontWeight: '400',
        textAlign: 'center',
    },
    searchInputBlock: {
        marginTop: 16,
        marginLeft: 20,
        marginRight: 20,
    }
});

export default MailboxPage;
