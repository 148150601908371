const monthPrice = price => price * 24 * 30;
const yearPrice = price => price * 24 * 30 * 12;

const calculateMonthStoragePrice = (price, storage) => monthPrice(price * storage);
const calculateMonthApplicationPrice = (price, count) => monthPrice((count / 256) * price);
const calculateMonthMemoryPrice = (price, count) => monthPrice((count / 512) * price);

const calculateYearStoragePrice = (price, storage) => yearPrice(price * storage);
const calculateYearApplicationPrice = (price, count) => yearPrice((count / 256) * price);
const calculateYearMemoryPrice = (price, count) => yearPrice((count / 512) * price);

export const calculatePrice = (memory, storage, redis, memcached, priceData) => {
    const storagePrice = calculateMonthStoragePrice(priceData.disk.price, storage);
    const memoryPrice = calculateMonthMemoryPrice(priceData.memory.price, memory);
    const redisPrice = calculateMonthApplicationPrice(priceData.redis.price, redis || 0);
    const memcachedPrice = calculateMonthApplicationPrice(
        priceData.memcached.price, memcached || 0
    );
    return storagePrice + memoryPrice + redisPrice + memcachedPrice;
};

export const calculateYearPrice = (memory, storage, redis, memcached, priceData) => {
    const storagePrice = calculateYearStoragePrice(priceData.disk.price, storage);
    const memoryPrice = calculateYearMemoryPrice(priceData.memory.price, memory);
    const redisPrice = calculateYearApplicationPrice(priceData.redis.price, redis || 0);
    const memcachedPrice = calculateYearApplicationPrice(priceData.memcached.price, memcached || 0);
    return storagePrice + memoryPrice + redisPrice + memcachedPrice;
};
