import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    View,
    StyleSheet,
    Text,
    Image,
    TouchableOpacity,
} from 'react-native';

import { mbToGb } from '@netangels/core/utils/convert';

import { closedIcon } from '../../icons';
import Modal from '../General/Modal/Modal';
import Errors from '../General/Form/Errors';
import Switch from '../General/Switch/Switch';
import Button from '../General/Button/Button';
import AddAliasModal from './Modals/AddAliasModal';
import DeleteAliasModal from './Modals/DeleteAliasModal';
import SliderDynamic from '../General/Slider/SliderDynamic';
import MailboxLocalWarningModal from './Modals/MailboxLocalWarningModal';
import { changeMailboxQuota, switchMailboxLocal } from '../../actions/mail/actions';
import MailWebsocket from './MailWebsocket';

const formId = 'MailboxSettingsPageForm';

function getQuotaScale(max) {
    const values = [];
    for (let value = 1; value <= max; value += 1) {
        values.push(value);
    }
    return values;
}

function MailboxSettingsPage() {
    const dispatch = useDispatch();
    const { errors } = useSelector((state) => state.forms);
    const { mailbox, userInfo, changeMailboxInProgress } = useSelector((state) => state.mail);
    const [mailboxLocalModalShowed, setMailboxLocalModalShowed] = React.useState(false);
    const [addAliasModalShowed, setAddAliasModalShowed] = React.useState(false);
    const [deleteAliasModalShowed, setDeleteAliasModalShowed] = React.useState(false);
    const [deletedAlias, setDeletedAlias] = React.useState(false);
    const currentQuota = mbToGb(mailbox.quota === 0 ? mailbox.max_quota : mailbox.quota);
    const [quota, setQuota] = React.useState(currentQuota);
    const currentErrors = errors[formId] || {};

    return <View style={styles.wrapper}>
        <View>
            <View style={styles.headerBlock}>
                <Text style={styles.headerText}>
                    настройки ящика
                </Text>
            </View>
            <View style={styles.switch}>
                <Switch label='Сбор почты в ящик'
                    isEnable={mailbox.is_local}
                    disabled={mailbox.aliases.length === 0}
                    disabledText="Сначала добавьте адрес для пересылки"
                    onChange={() => {
                        if (mailbox.is_local) {
                            setMailboxLocalModalShowed(true);
                        } else {
                            dispatch(
                                switchMailboxLocal(
                                    formId,
                                    mailbox.id,
                                    true,
                                ),
                            );
                        }
                    }}/>
            </View>
            {userInfo && userInfo.paid_quota_enabled && <View style={styles.switch}>
                <SliderDynamic
                    title='Лимит ящика'
                    items={getQuotaScale(mbToGb(mailbox.max_quota))}
                    value={quota}
                    unit='ГБ'
                    hideBounds={true}
                    onChange={(changedQuota) => {
                        setQuota(changedQuota);
                    }}/>
            </View>}
            <View style={styles.headerBlock}>
                <Text style={styles.headerText}>
                    адреса для пересылки
                </Text>
                <TouchableOpacity style={styles.button}
                    onPress={() => setAddAliasModalShowed(true)}>
                    <Text style={styles.blueText}>Добавить</Text>
                </TouchableOpacity>
            </View>
            {mailbox.aliases.map((alias, i) => <View
                key={`mail-alias-${i}`}
                style={styles.aliasBlock}
            >
                <Text style={styles.alias}>
                    {alias}
                </Text>
                <TouchableOpacity
                    onPress={() => {
                        setDeleteAliasModalShowed(true);
                        setDeletedAlias(alias);
                    }}
                >
                    <Image source={closedIcon} style={styles.icon} />
                </TouchableOpacity>
            </View>)}
        </View>
        <Modal isModalShowed={mailboxLocalModalShowed}
            component={<MailboxLocalWarningModal
                mailboxId={mailbox.id}
                mailboxName={mailbox.name}
                onClose={() => setMailboxLocalModalShowed(false)}/>}
            onClose={() => setMailboxLocalModalShowed(false)} />
        <Modal isModalShowed={addAliasModalShowed}
            component={<AddAliasModal
                mailboxId={mailbox.id}
                onClose={() => setAddAliasModalShowed(false)}/>}
            onClose={() => setAddAliasModalShowed(false)} />
        <Modal isModalShowed={deleteAliasModalShowed}
            component={<DeleteAliasModal
                alias={deletedAlias}
                mailboxId={mailbox.id}
                onClose={() => setDeleteAliasModalShowed(false)}/>}
            onClose={() => setDeleteAliasModalShowed(false)} />
        <View style={styles.bottomBlock}>
            {currentErrors.__all__ && <Errors errors={currentErrors.__all__}/>}
            {currentQuota !== quota && <Button onPress={() => dispatch(
                changeMailboxQuota(formId, mailbox.id, quota * 1024)
            )}
            inProgress={changeMailboxInProgress}
            title='Сохранить изменения'
            additionalButtonStyles={[{ paddingTop: 11, paddingBottom: 13 }]}
            additionalButtonTextStyles={[{ fontSize: 14, lineHeight: 16 }]}
            />}
        </View>
        <MailWebsocket />
    </View>;
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    headerBlock: {
        paddingBottom: 19,
        paddingTop: 24,
        paddingLeft: 20,
        paddingRight: 21,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: 11,
        fontWeight: '500',
        letterSpacing: 1.375,
        textTransform: 'uppercase',
        opacity: 0.75,
        color: '#455562',
    },
    blueText: {
        fontSize: 12,
        fontWeight: '500',
        color: '#0f79d5',
    },
    switch: {
        paddingBottom: 16,
        paddingTop: 16,
        paddingLeft: 22,
        paddingRight: 20,
        backgroundColor: '#FFFFFF',
        marginBottom: 4,
    },
    aliasBlock: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 17,
        paddingTop: 16,
        paddingLeft: 20,
        paddingRight: 21,
        backgroundColor: '#FFFFFF',
    },
    alias: {
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 14,
        color: '#455562',
    },
    icon: {
        width: 12,
        height: 12,
    },
    bottomBlock: {
        paddingLeft: 22,
        paddingRight: 20,
        paddingBottom: 32,
    }
});

export default MailboxSettingsPage;
