import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { View, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';

import Button from '../../General/Button/Button';
import { tariffs } from '../../../constants/hosting';
import { containerChangeMemory } from '../../../actions/hosting/actions';

import { checkedRadioButton } from '../../../icons/index';
const formId = 'HostingContainerChangeTariff';

function HostingChangeTariff(props) {
    const { navigation, inProgress, container, containerChangeMemory } = props;
    const tariffChecked = tariffs.filter(tariff => tariff.ram === container.memory_limit)[0].name;
    const [tariffChanged, setTariffChanged] = React.useState(tariffChecked);
    return <View style={styles.wrapper}>
        <View style={styles.tariffs}>
            {tariffs.map((tariff, t) => {
                const isCurrentTariff = tariffChecked === tariff.name;
                const isChangedTariff = tariffChanged === tariff.name;
                return <TouchableOpacity
                    onPress={() => setTariffChanged(tariff.name)}
                    key={`hosting-change-tariff-${t}`}
                    style={isChangedTariff ? [styles.tariffBlock, styles.checkedTariff] : [styles.tariffBlock, styles.uncheckedTariff]}>
                    {isCurrentTariff && <Image source={checkedRadioButton} style={styles.icon} />}
                    {!isCurrentTariff && <View style={isChangedTariff ? styles.typeCircleChecked : styles.typeCircle}>
                        {isChangedTariff && <View style={styles.typeCircleSmall}></View>}
                    </View>}
                    <Text style={isCurrentTariff ? [styles.titleCurrentColor, styles.tariffTitle] : [styles.tariffTitle, styles.titleColor]}>
                        {tariff.title}
                    </Text>
                    <Text style={styles.description}>RAM {tariff.ram} МБ, CPU {tariff.cpu} CP</Text>
                </TouchableOpacity>;
            })}
        </View>
        <Button title='Изменить тариф'
            inProgress={inProgress}
            onPress={() => {
                const memoryLimit = tariffs.filter(tariff => tariff.name === tariffChanged)[0].ram;
                containerChangeMemory(formId, container.id, memoryLimit, () => navigation.navigate('HostingContainer'));
            }}
            additionalWrapperStyles={[{ maxWidth: '100%', marginBottom: 19, marginTop: 40, marginLeft: 12, marginRight: 12 }]}
            additionalButtonStyles={[{ paddingTop: 15, paddingBottom: 15 }]}
            additionalButtonTextStyles={[{ fontSize: 13, lineHeight: 15 }]}/>
    </View>;
}

HostingChangeTariff.propTypes = {
    inProgress: PropTypes.bool,
    container: PropTypes.shape({
        memory_limit: PropTypes.number,
    }),
    containerChangeMemory: PropTypes.func,
};

const mapStateToProps = (state) => ({
    container: state.hosting.container,
    inProgress: state.hosting.containerUpdateInProgress,
    errors: state.forms.errors[formId]
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    containerChangeMemory
}, dispatch);

const styles = StyleSheet.create({
    wrapper: {
        height: '100%',
    },
    tariffs: {
        flex: 1,
    },
    tariffBlock: {
        maxHeight: 48, 
        paddingTop: 14,
        paddingLeft: 19,
        paddingBottom: 14,
        paddingRight: 19,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    uncheckedTariff: {
        backgroundColor: '#ffffff',
        borderTopWidth: 1,
        borderTopColor: '#F6F6F6',
        borderBottomWidth: 1,
        borderBottomColor: '#F6F6F6',
    },
    checkedTariff: {
        backgroundColor: 'rgba(15,121,213,0.07)',
        borderTopWidth: 1,
        borderTopColor: '#0F79D5',
        borderBottomWidth: 1,
        borderBottomColor: '#0F79D5',
    },
    tariffTitle: {
        flex: 1,
        fontSize: 13,
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: 15,
    },
    titleColor: {
        color: '#455562',
    },
    titleCurrentColor: {
        color: '#0F79D5',
        fontWeight: '500',
    },
    description: {
        color: '#455562',
        fontSize: 10,
        letterSpacing: 0,
        lineHeight: 11,
        textAlign: 'right',
    },
    icon: {
        width: 24,
        height: 24,
        marginRight: 10,
    },
    typeCircle: {
        height: 18,
        width: 18,
        borderWidth: 1,
        marginRight: 12,
        marginLeft: 3,
        borderColor: 'rgba(161,170,177,0.5)',
        borderRadius: 9,
        backgroundColor: '#F5F5F5',
    },
    typeCircleChecked: {
        height: 18,
        width: 18,
        borderWidth: 1,
        marginRight: 12,
        marginLeft: 3,
        borderColor: '#0F79D5',
        borderRadius: 9,
        backgroundColor: '#FFFFFF',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    typeCircleSmall: {
        height: 8,
        width: 8,
        borderRadius: 9,
        backgroundColor: '#0F79D5'
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(HostingChangeTariff);