import {
    VM_GET_FINANCE_INFO_SUCCESSFULLY,
    VM_GET_FINANCE_INFO_WITH_ERRORS,
    VM_CREATE_PACKET_SUCCESSFULLY,
    VM_UPDATE_PACKET_SUCCESSFULLY,
    VM_UPDATE_PACKET_WITH_ERRORS,
    FETCH_TARIFF_CALCULATION_IN_PROGRESS,
    FETCH_TARIFF_CALCULATION_WITH_ERRORS,
    FETCH_TARIFF_CALCULATION_SUCCESSFULLY,
    WS_VM_UPDATE_FINANCE_INFO,
    VM_CREATE_PACKET_IN_PROGRESS,
    VM_CREATE_PACKET_WITH_ERRORS,
    VM_UPDATE_PACKET_IN_PROGRESS,
} from '../../actions/cvds/actionTypes';

export default () => {
    const defaultState = {
        calculation: null,
        updatePacketInProgress: false,
        calculationError: false,
        tariffCalculation: null,
        tariffCalculationInProgress: false,
        createPacketInProgress: false,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
        case VM_GET_FINANCE_INFO_SUCCESSFULLY: {
            return {
                calculation: action.data,
                calculationError: false
            };
        }
        case VM_GET_FINANCE_INFO_WITH_ERRORS: {
            return {
                ...state,
                calculationError: true
            };
        }
        case VM_CREATE_PACKET_IN_PROGRESS: {
            return {
                ...state,
                createPacketInProgress: true
            };
        }
        case VM_CREATE_PACKET_WITH_ERRORS: {
            return {
                ...state,
                createPacketInProgress: false
            };
        }
        case VM_CREATE_PACKET_SUCCESSFULLY: {
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    packet: action.data
                },
                createPacketInProgress: false
            };
        }
        case VM_UPDATE_PACKET_IN_PROGRESS: {
            return {
                ...state,
                updatePacketInProgress: true
            };
        }
        case VM_UPDATE_PACKET_SUCCESSFULLY: {
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    packet: action.data
                },
                updatePacketInProgress: false
            };
        }
        case VM_UPDATE_PACKET_WITH_ERRORS: {
            return {
                ...state,
                updatePacketInProgress: false
            };
        }
        case FETCH_TARIFF_CALCULATION_IN_PROGRESS: {
            return {
                ...state,
                tariffCalculationInProgress: true
            };
        }
        case FETCH_TARIFF_CALCULATION_WITH_ERRORS: {
            return {
                ...state,
                tariffCalculationInProgress: false
            };
        }
        case FETCH_TARIFF_CALCULATION_SUCCESSFULLY: {
            return {
                ...state,
                tariffCalculationInProgress: false,
                tariffCalculation: action.data
            };
        }
        case WS_VM_UPDATE_FINANCE_INFO: {
            return {
                ...state,
                calculation: {
                    ...state.calculation,
                    ...action.data
                }
            };
        }
        default:
            return state;
        }
    };
};
