const BaseResource = require('./resource');

class AccountResource extends BaseResource {
    async getAccount() {
        return this.makeRequest('get');
    }

    async changeCodeword(codeword, password) {
        return this.makeRequest('put', 'codeword/', { password, codeword });
    }

    async changePassword(oldPassword, password, repeatedPassword) {
        return this.makeRequest('put', 'password/',
            {
                password,
                old_password: oldPassword,
                repeated_password: repeatedPassword
            });
    }

    async changeRequisites(data) {
        return this.makeRequest('put', 'requisites/', data);
    }

    async changePhone({ phone }) {
        return this.makeRequest('put', 'phone/', {
            phone,
        });
    }

    async changePhoneNotifications({ phoneNotificationEnabled }) {
        return this.makeRequest('put', 'phone/notification/', {
            phone_notification_enabled: phoneNotificationEnabled,
        });
    }

    async verifyPhone(pin) {
        return this.makeRequest('post', 'phone/verify/', { pin });
    }

    async resetPhone() {
        return this.makeRequest('post', 'phone/reset/');
    }

    async resendPinPhone() {
        return this.makeRequest('post', 'phone/resend/');
    }

    async regenerateAPIKey() {
        return this.makeRequest('post', 'api-key/');
    }

    async addEmail(emailType, email) {
        return this.makeRequest('put', `emails/${emailType}/`, { email });
    }

    async deleteEmail(emailType, email) {
        return this.makeRequest('delete', `emails/${emailType}/`, { email });
    }

    async verifyMail(key) {
        return this.makeRequest('post', 'emails/verify/', { key });
    }

    async ipRestrictions(ips, enabled, onlyRussian) {
        return this.makeRequest('put', 'ip-restrictions/', {
            ips,
            enabled,
            only_russian: onlyRussian
        });
    }

    async downloadLogs(dateFrom, dateTo) {
        return this.makeRequest('get', `logs/${dateFrom}/${dateTo}/`);
    }

    async monthsForLogs() {
        return this.makeRequest('get', 'logs/months/');
    }

    async authorize(login, password) {
        return this.makeRequest('post', 'login/', { login, password });
    }

    async logout() {
        return this.makeRequest('post', 'logout/');
    }

    async authorizeOtp(scopeId, token) {
        return this.makeRequest('post', 'login/otp/', {
            scope_id: scopeId,
            token,
        });
    }

    async authorizeWithSecret(key, next) {
        return this.makeRequest('post', 'login/secret/', { key, next });
    }

    async passwordRestore(domain, transport) {
        return this.makeRequest('post', 'password/restore/', {
            domain,
            transport,
        });
    }

    async passwordReset(key, password) {
        return this.makeRequest('post', 'password/reset/', {
            key,
            password,
        });
    }

    async getOtpKey() {
        return this.makeRequest('get', 'otp/secret/');
    }

    async enableOtp(token) {
        return this.makeRequest('post', 'otp/enable/', { token });
    }

    async disableOtp(password) {
        return this.makeRequest('post', 'otp/disable/', { password });
    }
}

AccountResource.resource = null;
AccountResource.service = 'account';

module.exports = AccountResource;
