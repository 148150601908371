import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

import { partnershipClientsColorMap } from '../../constants/partnership';
import { backgroundColorsMap } from '../General/HorizontalBarChart/styles';

const styles = StyleSheet.create({
    clientDescription: {
        marginRight: 24,
        flex: 1
    },
    clientName: {
        color: '#455562',
        fontSize: 12,
        lineHeight: 14
    },
    clientWrapper: {
        paddingTop: 16,
        paddingLeft: 20,
        paddingRight: 18,
        paddingBottom: 16.5,
        height: 60,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff'
    },
    group: {
        width: 8,
        height: 8,
        marginRight: 13
    },
    clientLogin: {
        color: '#455562',
        fontSize: 11,
        lineHeight: 13,
        opacity: 0.7
    }
});

function PartnershipClientBlock(props) {
    const { client } = props;
    const colorType = client.colorType || partnershipClientsColorMap[client.status.toLowerCase()];
    const groupStyles = [
        styles.group,
        backgroundColorsMap[colorType]
    ];
    return <View style={styles.clientWrapper}>
        <View style={groupStyles} />
        <View style={styles.clientDescription}>
            <Text style={styles.clientName}>
                {client.name}
            </Text>
        </View>
        <Text style={styles.clientLogin}>{client.login}</Text>
    </View>;
}

PartnershipClientBlock.propTypes = {
    client: PropTypes.shape({
        name: PropTypes.string,
        login: PropTypes.string,
        colorType: PropTypes.string,
        status: PropTypes.string,
    }),
    colorType: PropTypes.string,
};

export default PartnershipClientBlock;
