import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Picker } from '@react-native-picker/picker';
import { View, TextInput, StyleSheet } from 'react-native';

import Errors from '../General/Form/Errors';
import Button from '../General/Button/Button';
import { initPayment } from '../../actions/finances/actions';

const formId = 'CVDSPaymentForm';

function normalizePickerOptions(allowElectronicPayment) {
    const selectOptions = [{
        value: 'Card',
        label: 'Банковской картой'
    }];
    selectOptions.push({
        value: 'SberbankOnline',
        label: 'Сбербанк онлайн'
    });
    if (allowElectronicPayment) {
        selectOptions.push({
            value: 'WebMoney',
            label: 'WebMoney'
        });
        selectOptions.push({
            value: 'YandexMoney',
            label: 'Яндекс.Деньги'
        });
    }
    return selectOptions;
}

function PaymentPageMoneyForm(props) {
    const {
        errors,
        inProgress,
        initPayment,
        setPaymentForm,
        allowElectronicPayment
    } = props;
    const [amount, setAmount] = React.useState(200);
    const [payment, setPayment] = React.useState('Card');
    const normalizedOptions = normalizePickerOptions(allowElectronicPayment);
    return <View>
        <View style={styles.moneyBlock}>
            <TextInput placeholder='Сумма'
                style={[styles.textInput, { width: 79 }]}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}/>
            <Picker
                style={styles.pickerInput}
                onValueChange={(itemValue) => setPayment(itemValue)}>
                {normalizedOptions.map((opt, i) => <Picker.Item key={`picker-item-${i}`}
                    label={opt.label} value={opt.value} />)}
            </Picker>
        </View>
        <View style={styles.errorBlock}>
            {errors.amount && <Errors errors={errors.amount} />}
        </View>
        <Button title='Оплатить'
            inProgress={inProgress}
            onPress={() => initPayment(payment, amount, (data) => {
                setPaymentForm(data);
            }, formId)}
            additionalButtonTextStyles={[styles.buttonText]}
            additionalButtonStyles={[styles.button]} />
    </View>;
};

PaymentPageMoneyForm.propTypes = {
    errors: PropTypes.shape({}),
    initPayment: PropTypes.func,
    setPaymentForm: PropTypes.func,
    inProgress: PropTypes.bool,
    allowElectronicPayment: PropTypes.bool
};

const mapStateToProps = (state) => ({
    errors: state.forms.errors[formId] || {},
    inProgress: state.finance.initPaymentInProgress,
    allowElectronicPayment: state.user.financeInfo
        && state.user.financeInfo.allow_electronic_payment
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    initPayment
}, dispatch);

const styles = StyleSheet.create({
    textInput: {
        borderWidth: 1,
        color: '#455562',
        borderColor: '#D8D8D8',
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        paddingBottom: 12,
        paddingLeft: 11,
        paddingTop: 10
    },
    moneyBlock: {
        flex: 1,
        marginBottom: 40,
        flexDirection: 'row'
    },
    errorBlock: {
        top: 40,
        position: 'absolute'
    },
    pickerInput: {
        width: 188,
        marginLeft: 7,
        borderWidth: 1,
        borderColor: '#D8D8D8',
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 14,
        paddingRight: 14,
        color: '#455562',
        fontSize: 12,
        fontWeight: '500',
        lineHeight: 32
    },
    button: {
        paddingTop: 11,
        paddingBottom: 13
    },
    buttonText: {
        fontSize: 14,
        lineHeight: 16,
        minHeight: 16,
        fontWeight: '500'
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPageMoneyForm);
